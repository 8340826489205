<template>
  <button 
    type="button"
    :class="size"
    @click="$emit('click')"
  >
    <div class="flex items-center justify-center w-full">
      <template v-if="loading">
        <LoadingIcon class="cursor-wait font-bold w-4 h-4" />
      </template>
      <template v-else>
        <div class="-ml-0.5 mr-2">
          <slot name="before" />
        </div>
        {{ text }}
        <div class="-mr-0.5 ml-2" >
          <slot name="after" />
        </div>
      </template>
    </div>
  </button>
</template>
<script>
import LoadingIcon from '@assets/loading-square.svg' 
export default {
  components: {
    LoadingIcon,
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.xs {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.sm {
  @apply inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.normal {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.lg {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.xl {
  @apply inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
</style>
