import MasterApp from './MasterApp.vue'
import SubTaskMaster from './pages/SubTaskMaster.vue'
import SubTaskSetMaster from './pages/SubTaskSetMaster.vue'

export default {
  path: '/master',
  component: MasterApp,
  children: [
    {
      path: '/SubTaskMaster',
      name: 'SubTaskMaster',
      component: SubTaskMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - サブタスクマスタ' },
      props: true
    },
    {
      path: '/SubTaskSetMaster',
      name: 'SubTaskSetMaster',
      component: SubTaskSetMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - タスク依頼セットマスタ' },
      props: true
    }
  ]
}
