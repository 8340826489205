<template>
  <div class="quill-editor">
    <slot name="toolbar">
      <!-- <div id="toolbar" class="flex">
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-size" style="width: 80px;">
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
        </span>
        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
      </div> -->
    </slot>
    <div ref="editor"></div>
  </div>
</template>

<script>
  // require sources
  import 'quill2/dist/quill.core.css'
  import 'quill2/dist/quill.snow.css'
  import 'quill2/dist/quill.bubble.css'
  import _Quill from 'quill2'
  // import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
  // import SecondaryButton from '../common/SecondaryButton.vue'

  const Quill = window.Quill || _Quill

  const defaultOptions = {
    theme: 'snow',
    boundary: document.body,
    modules: {
      toolbar: '#toolbar'
      // toolbar: [
      //   ['bold', 'italic', 'underline', 'strike'],
      //   // ['blockquote', 'code-block'],
      //   // [{ 'header': 1 }, { 'header': 2 }],
      //   // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //   // [{ 'script': 'sub' }, { 'script': 'super' }],
      //   // [{ 'indent': '-1' }, { 'indent': '+1' }],
      //   // [{ 'direction': 'rtl' }],
      //   [{ 'size': ['small', false, 'large', 'huge'] }],
      //   // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      //   [{ 'color': [] }, { 'background': [] }],
      //   // [{ 'font': [] }],
      //   // [{ 'align': [] }],
      //   ['clean'],
      //   ['custom']
      //   // ['link', 'image', 'video']
      // ]
    },
    // placeholder: 'Insert text here ...',
    readOnly: false
  }

  // pollfill
  if (typeof Object.assign != 'function') {
    Object.defineProperty(Object, 'assign', {
      // eslint-disable-next-line no-unused-vars
      value(target, varArgs) {
        if (target == null) {
          throw new TypeError('Cannot convert undefined or null to object')
        }
        const to = Object(target)
        for (let index = 1; index < arguments.length; index++) {
          const nextSource = arguments[index]
          if (nextSource != null) {
            for (const nextKey in nextSource) {
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey]
              }
            }
          }
        }
        return to
      },
      writable: true,
      configurable: true
    })
  }

  class PlainClipboard extends _Quill.import('modules/clipboard') {
    onCapturePaste(e) {
        if (e.defaultPrevented || !this.quill.isEnabled()) return;
        e.preventDefault();
        const range = this.quill.getSelection(true);
        if (range == null) return;

        let text = e.clipboardData?.getData('text/plain');
        let html = e.clipboardData?.getData('text/html');

        if (!text) {
            const urlList = e.clipboardData?.getData('text/uri-list');
            if (urlList) {
                text = this.normalizeURIList(urlList);
            }
        }
        const files = Array.from(e.clipboardData?.files || []);
        if (files.length > 0) {
            this.quill.uploader.upload(range, files);
            return;
        }

        // HTML無効ではnullに設定
        if (!this.quill.pasteHtml) html = null

        this.onPaste(range, {
            html: html,
            text
        });
    }
  }

  _Quill.register({
    'modules/clipboard': PlainClipboard,
  });

  // export
  export default {
    name: 'quill-editor',
    components: {
      // SecondaryButton
    },
    data() {
      return {
        options_: {},
        content_: '',
        defaultOptions,
        inlineStyleOption: {
          size: {
            'small': 'font-size: 70%;',
            'large': 'font-size: 120%;',
            'huge': 'font-size: 140%;'
          }
        }
      }
    },
    props: {
      content: String,
      value: String,
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        required: false,
        default: () => ({})
      },
      globalOptions: {
        type: Object,
        required: false,
        default: () => ({})
      },
      pasteHtml: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.initialize()
    },
    beforeDestroy() {
      this.quill = null
      delete this.quill
    },
    methods: {
      // Init Quill instance
      initialize() {
        if (this.$el) {

          // var DirectionAttribute = Quill.import('attributors/attribute/direction');
          // Quill.register(DirectionAttribute,true);

          // var AlignClass = Quill.import('attributors/class/align');
          // Quill.register(AlignClass,true);

          // var BackgroundClass = Quill.import('attributors/class/background');
          // Quill.register(BackgroundClass,true);

          // var ColorClass = Quill.import('attributors/class/color');
          // Quill.register(ColorClass,true);

          // var DirectionClass = Quill.import('attributors/class/direction');
          // Quill.register(DirectionClass,true);

          // var FontClass = Quill.import('attributors/class/font');
          // Quill.register(FontClass,true);

          // var SizeClass = Quill.import('attributors/class/size');
          // Quill.register(SizeClass,true);

          // var AlignStyle = Quill.import('attributors/style/align');
          // Quill.register(AlignStyle,true);

          // var BackgroundStyle = Quill.import('attributors/style/background');
          // Quill.register(BackgroundStyle,true);

          // var ColorStyle = Quill.import('attributors/style/color');
          // Quill.register(ColorStyle,true);

          // var DirectionStyle = Quill.import('attributors/style/direction');
          // Quill.register(DirectionStyle,true);

          // var FontStyle = Quill.import('attributors/style/font');
          // Quill.register(FontStyle,true);

          // var SizeStyle = Quill.import('attributors/style/size');
          // Quill.register(SizeStyle,true);

          // Options
          this.options_ = Object.assign({}, this.defaultOptions, this.globalOptions, this.options)

          // Instance
          this.quill = new Quill(this.$refs.editor, this.options_)
          
          this.quill.enable(false)

          // Set editor content
          if (this.value || this.content) {
            this.quill.clipboard.dangerouslyPasteHTML(this.value || this.content)
          }

          // Disabled editor
          if (!this.disabled) {
            this.quill.enable(true)
          }

          // Mark model as touched if editor lost focus
          this.quill.on('selection-change', range => {
            if (!range) {
              this.$emit('blur', this.quill)
            } else {
              this.$emit('focus', this.quill)
            }
          })

          // Update model if text changes
          this.quill.on('text-change', () => {
            const delta = this.quill.getContents();
            console.log(delta)
            // var converter = new QuillDeltaToHtmlConverter(delta.ops, {
            //   // inlineStyles: this.inlineStyleOption
            // });
            // let html = converter.convert() // this.$refs.editor.children[0].innerHTML
            let html = this.$refs.editor.children[0].innerHTML
            const quill = this.quill
            const text = this.quill.getText()
            if (html === '<p><br></p>') html = ''
            this.content_ = html
            this.$emit('input', this.content_)
            this.$emit('change', { html, text, delta, quill })
          })

          // Emit ready event
          this.$emit('ready', this.quill)
        }
      }
    },
    watch: {
      // Watch content change
      // eslint-disable-next-line no-unused-vars
      content(newVal, oldVal) {
        if (this.quill) {
          if (newVal && newVal !== this.content_) {
            this.content_ = newVal
            this.quill.clipboard.dangerouslyPasteHTML(newVal)
          } else if(!newVal) {
            this.quill.setText('')
          }
        }
      },
      // Watch content change
      // eslint-disable-next-line no-unused-vars
      value(newVal, oldVal) {
        if (this.quill) {
          if (newVal && newVal !== this.content_) {
            this.content_ = newVal
            // this.quill.disable()
            this.quill.clipboard.dangerouslyPasteHTML(newVal)
            // this.quill.enable(true)
          } else if(!newVal) {
            this.quill.setText('')
          }
        }
      },
      // Watch disabled change
      // eslint-disable-next-line no-unused-vars
      disabled(newVal, oldVal) {
        if (this.quill) {
          this.quill.enable(!newVal)
        }
      },
      pasteHtml(newVal) {
        if (this.quill) {
          this.quill.pasteHtml = newVal
        }
      }
    }
  }
</script>
<style scoped>
.ql-container {
  @apply text-sm bg-white rounded-b-md border-gray-300 border h-80;
}

.ql-toolbar.ql-snow {
  font-family: inherit;
}
</style>