<template>
  <div>
    <v-date-picker :value="date" :masks="masks" v-bind="datePickerAttrs" @input="onChange" :min-date="min" :max-date="max" :available-dates="availableDate">
      <template v-slot="{ inputValue, inputEvents }">
        <input
          type="text"
          :readonly="readonly"
          class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
          :class="disabled ? ['bg-gray-200'] : []" 
          :value="inputValue"
          v-on="inputEvents"
          v-bind="$attrs"
        />
      </template>
    </v-date-picker>
    <div class="absolute inset-y-0 flex items-center right-2">
      <Icon
        v-if="clearable && value !== ''"
        iconName="X" 
        :clickable="true" 
        class="h-4 w-4 text-gray-400"
        @click="clear"
      />
    </div>
  </div>
</template>
<script>
import Icon from '@components/Icon.vue'
import * as utils from '@libs/utils'

export default {
  inheritAttrs: false,

  components: {
    Icon
  },

  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    availableDate: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      date: null,
      masks: {
        input: 'YYYY-MM-DD',
        title: 'YYYY年 MMMM'
      },
      datePickerAttrs: [
        {
          inputDebounce: 500,
          popover: {
            visibility: 'focus',
            positionFixed: true
          }
        }
      ]
    }
  },

  watch: {
    value(val) {
      this.setValue(val)
    }
  },

  created() {
    this.setValue(this.value)
  },

  methods: {
    setValue(value) {
      if (!value) {
        this.date = null
      } else {
        this.date = utils.stringToDate(value)
      }
    },

    onChange(value) {
      this.date = value
      const dateString = utils.dateToString(value)
      this.$emit('change', dateString)
    },

    clear() {
      this.onChange(null)
    }
  }
}
</script>

<style scoped>
.error input[type=text] {
  padding-left: 32px !important;
}
</style>

