<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app" :class="isMobileDevice?'mobile-content':'pc-content'">
          <div class="">
            <div class="text-sm">※「床面清掃」のみ作業工程が必要です。</div>
            <!-- 清掃箇所 -->
            <div class="text-lg font-bold text-indigo-700">{{classification}}</div>

            <!-- 工程 -->
            <div class="flex flex-wrap mb-5 mt-2">
              <div v-for="(p, i) in process" :key="'p' + i" class="flex">
                <div class="text-sm mr-2 mb-1">
                  <div @click="deleteProcess(i)" class="flex border border-blue-500">
                    <div class="py-1 px-2 border-r border-blue-500 font-bold">
                      {{ p }}
                    </div>
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5 my-auto mx-1" />
                  </div>
                </div>
                <div v-if="i!=process.length-1" class="mr-2">
                  <Icon
                    iconName="ArrowNarrowRight" 
                  />
                </div>
              </div>
            </div>
            

            <!-- 選択肢 -->
            <div class="p-2 border border-gray-300 mb-5">
              <div class="mb-1">
                下の選択肢から工程順にクリックしてください。
              </div>
              <div class="flex flex-wrap">
                <div v-for="(name) in nameList" :key="name">
                  <div class="py-1 px-3 my-1 mx-2 rounded text-white font-bold text-sm cursor-pointer" :class="process.includes(name)?'bg-gray-400':'bg-blue-700'" @click="clickName(name)">
                    {{ name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="hidden">{{reload}}</div>

            <div>
              <PrimaryButton text="決定" size="normal" class="w-full mb-3" @click="save()"/>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Icon from '@components/Icon.vue'
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'

export default {
  components: {
    Icon,
    Modal,
    PrimaryButton,
    TrashIcon,
  },

  props: {
    // 清掃箇所
    classification: {
      type: String
    },
    // 作業工程
    item: {
      type: String
    },
    // 決定後の処理
    selectCallback: {
      type: Function,
      default: () => {}
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      
      // 作業工程配列
      process: [],
      // 選択肢
      nameList: [
        'A: 洗剤塗布・散布',
        'B: ポリッシャー洗浄',
        'C: 高圧洗浄',
        'D: 隅・コーナー等を擦り洗浄',
        'E: 散水栓からの水にて洗い流し',
        'F: バキュームにて残留水回収',
        'G: フロアスクイジーにて残留水除去',
        'H: モップ等にて拭き上げ',
        'I: 立面の拭き上げ',
        'J: 送風機にて乾燥',
        'K: 清水にてリンス洗浄',
        'L: バキュームにて吸塵',
        'M: カーペット洗浄機にて洗浄･汚水回収',
        'N: ＷＡＸ塗布',
        'O: 剥離剤塗布',
        'P: その他'
      ],
      reload: 0
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },


  watch: {
    open() {
      if (this.open) {
        this.getTarget()
      } else {
        return
      }
    },
    
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.process = []
      if (this.item) {
        let arr = this.item.split('→')
        if (arr && arr.length) {
          arr.forEach(a => {
            if (a) {
              a = a.trim()
              this.process.push(a)
            }
          })
        }
      }
    },

    /**
     * 入力チェック
     */
    async validate() {
      // 選択肢がある場合
      if (this.process && this.process.length) {
        const val = []
        for (let i = 0; i < this.process.length; i++) {
          const p = this.process[i]
          if (!val.includes(p)) {
            val.push(p)
          } else {
            let dia = await dialogs.showConfirmDialog(`「${p}」が重複`, '重複していますがよろしいでしょうか？')
            if (dia != 'YES') {
              return false
            }
          }
        }
      }
      return true
    },

    /**
     * 閉じる
     */
    closed() {
      this.process = []
    },

    /**
     * 工程削除
     * @param i index
     */
    deleteProcess(i) {
      this.process.splice(i, 1)
      if (!this.process) {
        this.process = []
      }
    },

    /**
     * 工程を選択
     */
    clickName(name) {
      this.process.push(name)
    },

    /**
     * 決定ボタンイベント
     */
    async save() {
      if (!await this.validate()) {
        return
      }
      let res = this.process.join(' → ')
      this.selectCallback(res)
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
}

.pc-content {
  max-height: calc(100vh - 130px);
}

.height-full {
  height: calc(100vh - 300px);
}

@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    max-height: calc(100vh - 90px);
  }

}
</style>