// 見積中項目

// 単価
// export const ESTIMATE_UNIT_PRICE = {
  // トイレ単価
  // toilet: '400',
  // // メーター
  // meter: '300',
  // // 写真
  // photo: '1000',
  // エリア年4回単価
//   '超都心': '90',
//   '都心': '85',
//   '千葉・埼玉': '85',
//   '多摩・神奈川': '85',
//   // バス代単価 なし
//   bus0: '0',
//   // バス代単価 あり
//   bus1: '420',
//   // 定期概算_長さ
//   'ファミリー': '7',
//   'シングル': '4',
//   '混合': '7',
//   // ㎡数_換算値
//   '200㎡以下': '323',
//   '300㎡以下': '366',
//   // オーナー契約なし
//   owner0: '1',
//   // オーナー契約あり
//   owner1: '1.15',
//   // 貯水 11ｔ以上
//   over10: '2000',
//   // 貯水 2槽式なし
//   twoTank0: '1',
//   // 貯水 2槽式あり
//   twoTank1: '1.15',  
//   // 排水管清掃 係数
//   drainage1: '1',
//   drainage2: '1.1',
//   drainage3: '1.2',
//   drainage4: '1.3',
//   // 消防 消火器21個以上
//   extinguisherOver20: '250',
//   // 消防 スプリンクラー21個以上
//   sprinklerOver20: '400',
//   // 消防 再点検
//   recheck0: '1',
//   // 消防 再点検あり
//   recheck1: '1.5',
//   // 消防 減額
//   reduction0: '0',
//   // 消防 減額あり
//   reduction1: '3500',
//   // 消防 エリア加算
//   eria1: '0',
//   eria3: '0',
//   // 消防 エリア加算あり
//   eria2: '5000',

// }

// 定期清掃_300㎡超え 
export const FIXED_EXCEED300 = [
  // 項目による係数
  {kindCoef: 'kindCoef', item: [
    {value: '2', name: 'ｽｷｯﾌﾟﾌﾛｱ (×2)'},
    {value: '1.5', name: 'ELV (×1.5)'},
    {value: '2', name: '階段 (×2)'},
    {value: '1.5', name: 'ﾌﾛｰﾘﾝｸﾞ (×1.5)'},
    {value: '1.5', name: 'WAX (×1.5)'},
    {value: '2.5', name: 'ｶｰﾍﾟｯﾄ (×2.5)'},
    {value: '3', name: '手作業 (×3)'},
    {value: '1', name: 'なし (×1)'}
  ]}
]

/**
 * メインタスクで見積もり依頼を選択した際に、右側に出てくる添付
 * （見積りに引き継ぐため）
 */
export const mainEstimateAttachment = [
  { name: '定期清掃', file: ['regularCleanImage', 'regularCleanImage2', 'regularCleanImage3'] },
  { name: 'ガラス清掃', file: ['glassCleanImage', 'glassCleanImage2', 'glassCleanImage3'] },
  { name: '植栽剪定', file: ['plantingPlaceFile', 'plantingPlaceFile2', 'plantingPlaceFile3', 'plantingReportFile', 'plantingReportFile2', 'plantingReportFile3'] },
  { name: '特別清掃', file: ['specialCleanImage', 'specialCleanImage2', 'specialCleanImage3'] },
  { name: '消防設備点検', file: ['fireInspectReport', 'fireInspectReport2', 'fireInspectReport3'] },
  { name: '建築設備定期検査', file: ['constructionEquipmentInspectReport', 'constructionEquipmentInspectReport2', 'constructionEquipmentInspectReport3'] },
  { name: '特定建築物定期調査', file: ['specificInspectReport', 'specificInspectReport2', 'specificInspectReport3'] },
  { name: 'エレベーター保守点検', file: ['evInspectFile', 'evInspectFile2', 'evInspectFile3'] }
]

/**
 * 見積りの区分
 */
export const type = [
  {id: '1', name: '新規物件', active: false},
  {id: '2', name: '追加変更', active: false},
  {id: '3', name: 'スポット', active: false}
]

/**
 * 照明器具清掃 拭き上げ で使用する係数
 */
export const typeList = [
  {id: '2.5', name: 'シングル'},
  {id: '3.5', name: 'ファミリー or 混合'}
]

/**
 * 対応費
 */
export const responseCost = '3000'
