<template>
  <div class="relative z-0 inline-flex ---shadow-sm rounded-md w-full" :class="this.selections.length > 4 ? 'flex-wrap' : ''">
    <template v-for="(selection, index) in selections">
      <button 
        :key="selection.value" 
        type="button" 
        :disabled="disabled"
        class="justify-center relative inline-flex items-center px-4 py-1.5 border text-sm focus:z-10 focus:outline-none ---focus:ring-1 ---focus:ring-indigo-500 ---focus:border-indigo-500"
        :class="classes(selection, index)"
        style="margin-right: 1px; margin-bottom: 1px;"
        @click="select(selection)"
      >
        {{ selection.text }}
      </button>
    </template>
  </div>      
</template>
<script>
export default {
  inheritAttrs: false,

  components: {
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    multiSelect: {
      type: Boolean,
      default: false
    },

    selections: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      // selectedItems: []
    }
  },

  computed: {
    classes()  {
      return (item) => {
        const classes = []
        if (this.selections.length > 4) {
          classes.push('w-1/5')
        } else {
          classes.push('w-full')
        }

        // if (index === 0) {
        //   classes.push('rounded-tl')
        // } 
        // if (index === 4 - 1) {
        //   classes.push('rounded-tr')
        // }
        // if (index === this.selections.length - 1) {
        //   classes.push('rounded-r')
        // }

        if (this.selectedValues.includes(item.value)) {
          classes.push('bg-indigo-900 ')
          // classes.push('hover:bg-indigo-900')
          classes.push('text-white')
          classes.push('border-indigo-900 ')
        } else {
          classes.push('bg-white')
          classes.push('hover:bg-gray-50')
          classes.push('border-gray-300 ')
        }

        return classes
      }      
    },

    selectedValues() {
      return this.value.filter(item => item).map(item => item.value)
      // return this.selectedItems.map(item => item.value)
    }
  },

  created() {
    // this.selectedItems = this.value
  },

  methods: {
    select(selectedItem) {
      let selectedItems = this.value.filter(item => item)
      if (selectedItems.find(optionSelected => optionSelected.value === selectedItem.value)) {
        selectedItems = selectedItems.filter(optionSelected => optionSelected.value !== selectedItem.value)
      } else {
        if (this.multiSelect) {
          selectedItems.push(selectedItem)
        } else {
          selectedItems = [ selectedItem ]
        }
      }
      this.$emit('change', selectedItems)

      // if (this.selectedItems.find(optionSelected => optionSelected.value === selectedItem.value)) {
      //   this.selectedItems = this.selectedItems.filter(optionSelected => optionSelected.value !== selectedItem.value)
      // } else {
      //   if (this.multiSelect) {
      //     this.selectedItems.push(selectedItem)
      //   } else {
      //     this.selectedItems = [ selectedItem ]
      //   }
      // }
      // this.$emit('change', this.selectedItems)

      // const found = this.selectedItems.find((item) => {
      //   item.value === selectedItem.value
      // })

      // if (this.multiSelect) {
      //   if (this.selectedItems.find(optionSelected => optionSelected.value === selectedItem.value)) {
      //       this.selectedItems = this.selectedItems.filter(optionSelected => optionSelected.value !== selectedItem.value)
      //   } else {
      //       this.selectedItems.push(selectedItem)
      //   }
      // } else {
      //   if (found) {
      //     this.selectedItems.splice(0)
      //   } else {
      //     this.selectedItems = [ selectedItem ]
      //   }
      // }
      // this.$emit('change', this.selectedItems)
    }
  }
}
</script>
<style scoped>
button:disabled {
  cursor: not-allowed;
}
</style>