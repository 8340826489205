<template>
  <div>
    <PhotoReportComponent
      typeName='フリー入力'
      :type=1
      :commonItem="false"
      title=""
    >
    </PhotoReportComponent>
  </div>
</template>
<script>

import PhotoReportComponent from '../components/PhotoReportComponent.vue'

export default {
  components: {
    PhotoReportComponent
  },
}
</script>

<style scoped>
</style>