<template>
  <div>
    <UnPatrolListComponent
      typeName="設備点検 未点検一覧"
      :type="4"
      settionStrageName="FacilityUnpatrolListStorageItemName">
    </UnPatrolListComponent>
  </div>
</template>

<script>
import UnPatrolListComponent from '../../Patrol/components/UnPatrolListComponent.vue'

export default {
  components: {
    UnPatrolListComponent
  },
}
</script>