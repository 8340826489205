<template>
    <div>
        <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
            <template #action>
                <div class="content-container-app overflow-auto">
                    <div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
                    <div class="text-lg text-blue-800 font-bold" v-else>編集</div>
                    <div class="flex w-full mt-3 justify-center items-end">
                        <!-- 表示順 -->
                        <div class="w-2/5 ml-2">
                            <div class="text-xs text-gray-600 font-bold">表示順<br> 
                                <span class="font-normal">数値が小さいほど上にきます</span>
                            </div>
                            <NumericTextInput
                                maxlength="3"
                                name="sortNo"
                                v-model="user.sortNo"
                                :value="user.sortNo"
                                class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded"
                            />
                        </div>

                        <!-- 名前 -->
                        <div class="w-2/5 ml-2">
                            <div class="text-xs text-gray-600 font-bold mb-1">スタッフ
                                <span class="text-red-500"> * </span>
                            </div>
                            
                            <div class="border-gray rounded mt-1 flex">
                                <InputIcon
                                    v-if="!user.userId"
                                    class="mt-1 relative"
                                    iconName="ExclamationCircle"
                                    :iconColor="errorColor || 'red'" 
                                    :message="'スタッフは必須選択です。スタッフを選択してください。'"
                                    style="flex: 1;"
                                />
                                <select
                                    :disabled="!isNew"
                                    v-model="user.userId"
                                    class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full rounded"
                                    :class="!isNew ? 'cursor-not-allowed' : ''"
                                >
                                    <option v-for="(row, index) in list" :value="row.userId" :key="index">
                                        {{ row.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- 品質巡回のみ -->
                    <div v-if="type==1" class="flex w-full mt-3 justify-center items-end">
                        <!-- 指導員講習受験番号-->
                        <div class="w-2/5 ml-2">
                            <div class="text-xs text-gray-600 font-bold">指導員講習受験番号</div>
                            <NumericTextInput
                                maxlength="4"
                                name="patrolNumber"
                                caption="指導員講習受験番号"
                                v-model="user.patrolNumber"
                                :value="user.patrolNumber"
                                :format="false"
                                class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded mt-1"
                            />
                        </div>

                        <!-- 担当エリア -->
                        <div class="w-2/5 ml-2 patrol_number_block">
                            <div class="text-xs text-gray-600 font-bold">担当エリア</div>
                            <div class="border border-gray rounded mt-1">
                                <select
                                    name="departmentArea"
                                    v-model="user.area"
                                    class="text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full rounded"
                                >
                                <option
                                    v-for="dep in departmentList" :key='dep.id'
                                    :value="dep.name"
                                    class="bg-white">
                                    {{ dep.name }}
                                </option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div>
                        <PrimaryButton text="登録" size="normal mt-5 w-full" @click="register()" />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import InputIcon from '@components/InputIcon.vue'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'

export default {
    components: {
        Modal,
        PrimaryButton,
        NumericTextInput,
        InputIcon
    },

    props: {
        target: {
            type: Object
        },
        type: {
            type: Number,
            default: null
        },
        open: {
            type: Number,
            default: null
        },
        // 権限はあるが、まだ巡回スタッフとして登録されていない人のリスト
        newList: {
            type: Array
        },
        //_mst_patrol_staff一覧
        staffList: {
            type: Array
        },
        errorColor: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            // ユーザーデータ
            user: {
                //4桁userID
                userId: null,
                //mst_patrol_staffID
                id: null,
                name: '',
                //指導員講習受験番号
                patrolNumber: null,
                // 担当エリア
                area: null,
                sortNo: null,
            },
            // 新規作成
            isNew: false,
            // スタッフ選択リスト
            list: [],
            // 担当エリア
            departmentList: []
        }
    },

    watch: {
        open() {
            this.getTarget()
        }
    },

    methods: {

        /**
         * 表示するデータ取得
         */
        async getTarget() {
            // 編集の場合
            if (this.target && Object.keys(this.target).length) {
                this.user = {
                    userId: this.target.userId,
                    id: this.target.id,
                    name: this.target.name,
                    patrolNumber: String(this.target.patrolNumber),
                    sortNo: String(this.target.sortNo),
                    area: this.target.area
                }
                this.list = this.staffList
            } else {
                this.isNew = true
                this.list = this.newList
            }
            // 品質巡回のみ、担当エリアを取得
            if (this.type == 1) {
            let result2 = await backend.searchData('departmentSub/getForPatrol')
            if (result2.data && result2.data.data) {
                this.departmentList = [{ name: null, id: null }].concat(result2.data.data)
            }
        }
        },

        /**
         * 初期化
         */
        closed() {
            this.$emit('modalClose')
            // ユーザーデータ
            this.user = {
                userId: null,
                id: null,
                patrolNumber: null,
                sortNo: null
            }
            // 新規作成
            this.isNew = false
            // スタッフ選択リスト
            this.list = []
        },

        /**
         * 登録処理
         */
        async register() {
            //名前選択チェック
            if (!this.user.userId) {
                await this.nameErrRequired('名前')
                return
            }
            // mst_patrol_staff登録
            await backend.postData('patrolStaff/save', { ...this.user, updataUserId: this.$store.getters.user.id, type: this.type })

            // ログに登録
            let num = this.user.patrolNumber
            if (!num) {
                num = 'なし'
            }
            let sort = this.user.sortNo
            if (!sort) {
                sort = 'なし'
            }
            let typeName = '品質巡回'
            switch (Number(this.type)) {
                case 2:
                    typeName = '巡回清掃'
                    break
                    
                case 3:
                    typeName = '定期清掃'
                    break
            }
            let staff = this.list.find((l) => {
                return l.userId == this.user.userId
            })
            logManager.recordingByRegist(
                this,
                this.isNew,
                '巡回関連スタッフ',
                typeName + 'スタッフ（名前 : ' + staff.name + '（' + this.user.userId +'）、 表示順 : ' + sort + '）'
            )
            // this.$emit('modalClose')
            this.closed()
        },

        /**
         * 必須項目アラート
         * @param item 項目名
         */
        async nameErrRequired(item) {
            await dialogs.showErrorDialog(item + 'は必須です', item + 'が選択されていません。')
        }
    }
}

</script>
<style scoped>
.content-container-app {
    width: 850px;
    /* height: 230px; */
    padding: 10px;
}

.border-gray {
    border: solid #d3cfcf 1px;
}
</style>