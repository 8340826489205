<template>
  <div>
    <div v-if="!embed">
      <label :for="name" class="block text-xs font-bold text-gray-700 relative">
        {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
      </label>
      <div class="mt-1 flex relative">
        <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" />
        <Suggest 
          ref="suggest"
          :data="data" 
          :show-field="showField"
          :delay="0.1" 
          :full-list="!incrementalSearch"
          :disabled="disabled"
          v-model="text" 
          class="w-full" 
          :class="error ? 'error' : ''"
          :placeholder="placeholder"
          @input="onInput" 
          @values="selected">
        </Suggest>
        <div
          v-if="clearable || !!$slots['addon-content']"
          ref="addonButton"
          class="inset-y-0 mr-px my-px absolute right-0 inline-flex items-center space-x-2 px-4 ---border-l ---border-gray-300 text-sm font-medium rounded-r-md text-gray-700 ---bg-gray-50 ---hover:bg-gray-100 focus:outline-none ---focus:ring-1 ---focus:ring-indigo-500 ---focus:border-indigo-500"
        >
          <Icon
            v-show="clearable && text !== ''"
            iconName="X" 
            :clickable="true" 
            class="w-4 h-4 mr-0.5 text-gray-400"
            @click="clear"
          />
          <slot name="addon-content"></slot>
        </div>    
      </div>
    </div>
    <div v-else class="relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" class="-ml-1" />
      <Suggest 
        ref="suggest"
        :data="data" 
        :show-field="showField" 
        :delay="0.1" 
        :full-list="!incrementalSearch" 
        :disabled="disabled"
        v-model="text" 
        class="w-full" 
        :class="error ? 'error' : ''"
        :placeholder="placeholder"
        @input="onInput" 
        @values="selected">
      </Suggest>
      <div
        v-if="clearable || !!$slots['addon-content']"
        ref="addonButton"
        class="inset-y-0 mr-px my-px absolute right-0 inline-flex items-center space-x-2 px-4 ---border-l ---border-gray-300 text-sm font-medium rounded-r-md text-gray-700 ---bg-gray-50 ---hover:bg-gray-100 focus:outline-none ---focus:ring-1 ---focus:ring-indigo-500 ---focus:border-indigo-500"
      >
        <Icon
          v-show="clearable && text !== ''"
          iconName="X" 
          :clickable="true" 
          class="w-4 h-4 mr-0.5 text-gray-400"
          @click="clear"
        />
        <slot name="addon-content"></slot>
      </div>    
    </div>
  </div>
</template>
<script>
import InputIcon from '@components/InputIcon.vue'
import Suggest from '@components/Suggest/Suggest' 
import Icon from '@components/Icon.vue'

export default {
  components: {
    Suggest,
    InputIcon,
    Icon
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: [
    'value', 
    'name', 
    'caption', 
    'disabled', 
    'displayField', 
    'pigeonFormId', 
    'filter', 
    'embed', 
    'dynamic', 
    'incrementalSearch', 
    'selectionItems', 
    'customSearchFunc',
    'required',
    'error',
    'errorMessage',
    'errorColor',
    'readonly',
    'clearable',
    'placeholder'
  ],
  
  data() {
    return {
      text: '',
      data: []
    }
  },

  computed: {
    displayFields() {
      return Array.isArray(this.displayField) ? this.displayField : [this.displayField]
    },

    addonWidth() {
      if (this.$refs.addonButton) {
        // console.log(this.$refs.addonButton)
        return this.$refs.addonButton.clientWidth
      } else {
        return 0
      }
    },

    // addonButtonTitle() {
    //   if (this.$slots['addon-content']) {
    //     return this.$slots['addon-content'][0].data.attrs.title || ''
    //   } else {
    //     return ''
    //   }
    // }
  },

  watch: {
    text (val) {
      this.$emit('change', val)      
    },

    value(val) {
      this.text = val
    },

    selectionItems(val) {
      this.data = val
    },

    filter: {
      async handler() {
        if (this.dynamic) {
          if (!this.incrementalSearch) {
            await this.searchData()
          }
        }
      },
      deep: true
    }
  },
  
  async created() {
    if (this.dynamic) {
      if (!this.incrementalSearch) {
        await this.searchData()
      }
    } else {
      this.data = this.selectionItems
    }
    this.text = this.value || ''
  },

  mounted() {
    const self = this
    self.$nextTick(() => {
      window.setTimeout(() => {
        if (self.readonly) {
          self.$refs.suggest.$refs.input.readOnly = true
        }
        if (self.addonWidth > 0) {
          self.$refs.suggest.$refs.input.style.cssText = self.$refs.suggest.$refs.input.style.cssText + ` padding-right: ${self.addonWidth + 4}px !important;`
        }
        if (self.$refs.suggest) {
          self.$refs.suggest.$refs.input.addEventListener('blur', function() {
            // console.log('blur')
            self.$emit('blur')
            self.$refs.suggest.close()
          })
          self.$refs.suggest.$refs.input.addEventListener('keydown', function(e) {
            if (e.keyCode === 13) {
              self.$emit('onEnterKey', e)
            }
          })
        }
      }, 1000)
    })
  },

  methods: {
    showField(row) {
      const values = this.displayFields.map((field) => {
        if (typeof field === 'function') {
          return field(row)
        } else {
          if (row[field]) {
            return row[field]
          } else {
            return ''
          }
        }
      })
      return values.join(' ')
    },
  
    selected(data) {
      // console.log(data)
      this.$emit('selected', data)
    },
    
    onInput(e) {
      // console.log(`onInput : ${e}`)
      if (this.dynamic && this.incrementalSearch) {
        this.searchData()
      } else {
        if (e === '' && window.document.activeElement === this.$refs.suggest.$refs.input) {
          // console.log('suggest open.')
          const self = this
          window.setTimeout(() => {
            var evt1 = document.createEvent("HTMLEvents");
            evt1.initEvent("focus", true, true);
            self.$refs.suggest.$refs.input.dispatchEvent(evt1);
          }, 300)
        }
      }
    },
    
    onBlur() {
      this.$emit('blur')
    },

    onChange(e) {
      console.log('onChange')
      console.log(e)
    },
    
    async searchData() {
      if (this.customSearchFunc) {
        const params = {
          pigeonFormId: this.pigeonFormId,
          displayField: this.displayField,
          filter: this.filter,
          keyword: this.incrementalSearch ? this.text : null
        }
        this.data = await this.customSearchFunc(params)
      } else {
        const searchCondition = { ...this.filter }
        searchCondition['type'] = this.pigeonFormId
        if (this.incrementalSearch) {
          const self = this
          const query = { $or: [] }
          this.displayFields.forEach(function(field) {
            const expression = {}
            expression[field] = { $regex: self.text, $options: 'i'}
            query.$or.push(expression)
          });
          searchCondition.$and = [query]
        }
        this.data = await this.$pigeon.searchTasks({ searchCondition })
      }
    },

    clear() {
      console.log('clear')
      this.text = ''
    }
  }
}
</script>

