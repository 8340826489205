<template>
  <div class="---flex-1 ---sm:max-w-xs">
    <label for="search" class="sr-only">Search</label>
    <div class="relative">
      <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
        <Icon iconName="Search" class="w-5 h=5 text-gray-400" />
        <!-- <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
      </div>
      <input 
        id="search" 
        name="search" 
        class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
        placeholder="キーワード検索" 
        type="search"
        :value="value"
        @change="onChange"
      />
    </div>
  </div>  
</template>
<script>
import Icon from '@components/Icon.vue'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    Icon
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange($event) {
      this.$emit('change', $event.target.value)
    }
  }
}
</script>