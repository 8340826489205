<template>
  <div>
    <CheckListComponent
      typeName="品質巡回 点検一覧"
      :type="1"
      :claim="false"
      settionStrageName="CheckListStorageItemName">
    </CheckListComponent>
  </div>
</template>

<script>
import CheckListComponent from '../components/CheckListComponent.vue'

export default {
  components: {
    CheckListComponent
  },
}
</script>