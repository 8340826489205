<template>
  <div>
    <PageHeader title="apollo-RP - Chart" class="list pb-1">
      <template #title-header-content>
      </template>
      <template #page-header-content>
        <div class="flex-1 pl-2 flex ml-6  items-center">
          <!-- 集計単位 -->
          <div class="bg-white rounded">
            <div class="font-bold text-xs mb-1 text-teal-900 ml-2 mt-2">集計単位</div>
            <div class="flex items-center py-1 rounded mx-5 mb-3">
              <div v-for="tab in unitList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[tab.active ? 'bg-teal-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="unitChanged(tab)"
              >
                {{ tab.name }}
              </div>
            </div>
          </div>
          
          <!-- 部署 -->
          <div class="bg-white rounded ml-5">
            <div class="font-bold text-xs mb-1 text-teal-900 ml-2 mt-2">部署</div>
            <div class="flex items-center py-1 rounded mx-5 mb-3">
              <div v-for="tab in departmentList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[tab.active ? 'bg-teal-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="departmentChanged(tab)"
              >
                {{ tab.name }}
              </div>
            </div>
          </div>

          <!-- 区分 -->
          <div v-if="unit==1" class="bg-white rounded ml-5">
            <div class="font-bold text-xs mb-1 text-teal-900 ml-2 mt-2">区分</div>
            <div class="flex items-center py-1 rounded mx-5 mb-3">
              <div v-for="tab in typeList" 
                :key="tab.id" 
                class="w-28 text-center mx-1 cursor-pointer"
                :class="[tab.active ? 'bg-teal-700' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                @click="typeChanged(tab)"
              >
                {{ tab.name }}
              </div>
            </div>
          </div>

          <!-- 基準年度 -->
          <div v-if="unit==1" class="bg-white rounded ml-5 pl-3 pb-2 pr-3">
            <div class="font-bold text-xs mb-2 mt-2">基準年度</div>
            <select
              name="year"
              v-model="year"
              class="border border-gray-400 cursor-pointer text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded text-xs w-28 px-8 py-2"
            >
              <option
                v-for="y in yearList"
                :key='y.id'
                :value="y.name"
                class="bg-white">
                {{ y.name }}
              </option>
            </select>
          </div>
          
          <!-- 基準日 -->
          <div v-if="unit==2" class="bg-white rounded ml-5">
            <div class="font-bold text-xs mb-1 text-teal-900 ml-2 mt-2">
              基準日
              <span v-if="disabledBtn" class="ml-1 text-red-500"> * 日付を選択してください </span>
            </div>
            <div class="flex items-center">
              <div class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="startDay"
                  v-model="startDay"
                />
              </div>

              <div class="font-bold text-2xl">～</div>

              <div v-if="!startDay || startDay==''" class="rounded mx-5 mb-2">
                <WmsTextInput 
                  name="endDay" 
                  :disabled="true"
                />
              </div>
              
              <div v-else class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="endDay"
                  v-model="endDay"
                  :min="minEndDay"
                />
              </div>
            </div>
          </div>

          <div class="mt-auto mb-1">
            <div v-if="unit==1" class="text-white text-xs font-bold pl-4 pb-3">
              ※セルクリックで詳細が表示されます
            </div>
            
            <PrimaryButton
              text="集計"
              class="w-40 mt-auto ml-8 bg-blue-500 hover:bg-blue-600 border-blue-50"
              :class="disabledBtn?'cursor-not-allowed':''"
              :disabled="disabledBtn"
              @click="count(false)"
            >
            </PrimaryButton>
          </div>

          <span class="mt-auto mb-1 ml-8">
            <PrimaryButton
              text="PDF"
              class="w-40 bg-teal-500 hover:bg-teal-600 border-pink-50"
              @click="pdfBtn()"
            >
              <Icon iconName="Printer" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </span>
          
          <span v-if="unit==2" class=" mt-auto mb-1 ml-8">
            <PrimaryButton
              text="保存したデータを表示"
              class="w-44 bg-pink-500 hover:bg-pink-600 border-pink-50"
              @click="modal()"
            >
            </PrimaryButton>
          </span>
        </div>
      </template>
    </PageHeader>

    <main v-if="loading" class="h-full">
      <div class="w-full mt-60 h-full flex justify-center m-auto items-center">
        <p class="text-gray-400 font-bold animate-pulse">集計中...</p>
      </div>
    </main>

    <main v-else class="main-height list w-full overflow-auto">  
      <div>
        <div id="canvas-month">
          <!-- 月（単月） -->
          <div v-if="unit==1 && monthColumn.length" class="flex justify-around mt-5">
            <table class="border-bl mt-5 ml-5 table1">
              <thead class="th">
                <tr class="column">
                  <th v-for="(c, i) in monthColumn" :key="'m' + i" class="text-right pl-3 pr-3 pt-1 pb-1">{{ c }}</th>
                </tr>
              </thead>
              <tbody>
                <tr :class="[setColor(i), lastRow(i)]" v-for="(row, i) in monthData" :key="'mr' + i">
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl">{{ row.monthes }}月</td>
                  <!-- <td v-else class="w-28 text-right pr-3 pb-1 border-bl">総計</td> -->
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(4, row.monthes)">{{ row.four }}</td>
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(3, row.monthes)">{{ row.three }}</td>
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(2, row.monthes)">{{ row.two }}</td>
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg cursor-pointer" :class="setTextColor(1, row.monthes)" @click="getDetail(1, row.monthes, setTextColor(1, row.monthes))">{{ row.one }}</td>
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg cursor-pointer" :class="setTextColor(0, row.monthes)" @click="getDetail(0, row.monthes, setTextColor(0, row.monthes).indexOf('yellow') > -1)">{{ row.zero }}</td>
                  <!-- <td class="w-28 text-right pr-3 pb-1 border-bl">{{ row.total }}</td> -->
                </tr>
              </tbody>
            </table>
            <template>
              <div>
                <line-chart
                  :chart-data="chartData"
                  :options="options"
                  :height="height"
                  :width="width"
                ></line-chart>
              </div>
            </template>
          </div>

          <div v-if="(msg || msg2) && monthColumn.length" class="flex h-fit my-6 ml-36">
            <div v-if="msg" class="text-yellow-700 w-fit text-xs my-auto">
              {{ msg }}
            </div>
            <div v-if="msg2" class="text-gray-800 text-xs text-center rounded my-auto ml-3">
              {{ msg2 }}
            </div>
          </div>

          <!-- 月（年計） -->
          <div v-if="unit==1 && monthColumn.length" class="flex justify-around mb-5">
            <table class="border-bl ml-5 table1">
              <thead class="th">
                <tr class="column">
                  <th v-for="(c, i) in monthColumnYear" :key="'m' + i" class="text-right pl-3 pr-3 pt-1 pb-1">{{ c }}</th>
                </tr>
              </thead>
              <tbody>
                <tr :class="[setColor(i), lastRow(i)]" v-for="(row, i) in monthDataYear" :key="'mr' + i">
                  <td v-if="i!=12" class="w-28 text-right pr-3 pb-1 border-bl">{{ row.monthes }}月 年計</td>
                  <td class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(4, row.monthes)">{{ row.four }}</td>
                  <td class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(3, row.monthes)">{{ row.three }}</td>
                  <td class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(2, row.monthes)">{{ row.two }}</td>
                  <td class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(1, row.monthes)">{{ row.one }}</td>
                  <td class="w-28 text-right pr-3 pb-1 border-bl font-bold text-lg" :class="setTextColor(0, row.monthes)">{{ row.zero }}</td>
                </tr>
              </tbody>
            </table>
            <template>
              <div>
                <line-chart
                  :chart-data="chartDataYear"
                  :options="options"
                  :height="height"
                  :width="width"
                ></line-chart>
              </div>
            </template>
          </div>
        </div>

        <!-- 週 -->
        <div id="canvas-week" v-if="unit==2 && weekData.length" class="mt-10 ml-auto mr-auto mb-10 text-center w-fitcon">
          <div class="flex font-bold ml-5">
            <div>
              表示期間：{{ dispStartDay }} ～ {{ dispEndDay }}
            </div>
            <div class="ml-5">
              集計日:{{ dispDay }}
            </div>
            
          </div>
          <table class="border-bl mt-5 ml-5 mr-5" id="table2">
            <thead class="">
              <tr class="column">
                <th v-for="(c, i) in weekColumn1" :key="'w' + i" :colspan="c.col" class="text-center pl-3 pr-3 pt-1 pb-1" :class="c.class">
                  <div :class="i%2!=0?'pl-5':''">
                    {{ c.name }}
                    <div v-if="i%2!=0" class="inline cursor-pointer pr-5 hover:text-indigo-600" @click="removeStaff(c.staffId)">
                      <Icon iconName="XCircle" class="h-5 w-5 inline" :clickable="true" />
                    </div>
                  </div>
                </th>
              </tr>
              <tr class="column">
                <th v-for="(c, i) in weekColumn2" :key="'w2' + i" class="text-right pl-3 pr-3 pt-1 pb-1" :class="c.class">{{ c.name }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in weekData" :key="'wr' + i">
                <td v-for="(r, j) in row" :key="'wrr' + j" :class="[setColor2(i, r.type)]" class="text-right pr-3 pl-3 pb-1 border-bl">
                  <div v-if="r.type!=4">{{ r.val }}</div>
                  <div v-else>{{ getTotal(row, r.staffId) }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="unit==2 && !weekData.length" class="font-bold text-gray-800 text-lg mt-10 mr-auto ml-auto w-fitcon animate-pulse">
          {{ emptyMsg }}
        </div>
      </div>

      <!-- 担当者ごと -->
      <div v-if="loadingByStaff" class="h-full">
        <div class="w-full mt-8 h-full flex justify-center m-auto items-center">
          <p class="text-gray-400 font-bold animate-pulse">担当者ごとに集計中...</p>
        </div>
      </div>

      <div v-else class=" list w-full  overflow-auto">
        <div v-if="unit==1 && monthColumnStaff.length" class="mt-6 ml-auto mr-20p w-fitcon">
          <PrimaryButton
            text="担当者別グラフの順番・色・表示 設定"
            @click="staffDiplaySetting()"
          >
          </PrimaryButton>
        </div>
        <div>
          <!-- 担当者比較（総数以外） -->
          <div v-if="unit==1 && monthColumnStaff.length && type != 4" class="text-center text-blue-700 w-full font-bold">月単位</div>
          <div v-if="unit==1 && monthColumnStaff.length && type != 4" class="my-5 flex flex-wrap">
            <table class="border-bl mt-5 table1 mx-auto w-fit">
              <thead class="th3">
                <tr class="column">
                  <th class="text-right pl-3 pr-3 pt-1 pb-1">月単位</th>
                  <th v-for="(c, i) in monthColumnStaff" :key="'m' + i" class="pl-2 pr-3 pt-1 pb-1 whitespace-pre-line border-bl">
                    <div class="flex justify-between cursor-pointer" @click="removeStaffMonth(c.staffId, i)" v-tooltip="'一時的に非表示'">
                    <!-- <div class="text-right"> -->
                      <Icon iconName="XCircle" class=" h-5 w-5" :clickable="true" />
                      <div>{{ c.name }}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr :class="setColor(i)" v-for="(row, i) in allStaffData" :key="'mr' + i">
                  <td v-if="row.year" class="w-24 text-right pr-1 pb-1 border-bl">{{ row.year }}年{{ row.month }}月</td>
                  <td v-for="staff in monthColumnStaff" :key="staff.staffId + i" class="w-24 text-right pr-3 pb-1 border-bl font-bold text-lg" >{{ row[staff.staffId] }}</td>
                </tr>
              </tbody>
            </table>
            <template>
              <div class="mt-3 mx-auto w-fit">
                <line-chart
                  :chart-data="chartDataStaff"
                  :height="height"
                  :width="width"
                ></line-chart>
              </div>
            </template>
          </div>
          <!-- 年計 -->
          <div v-if="unit==1 && monthColumnStaff.length && type != 4" class="text-center text-blue-700 w-full font-bold mt-10">年計</div>
          <div v-if="unit==1 && monthColumnStaff.length && type != 4" class="mb-5 flex flex-wrap">
            <table class="border-bl mt-5 table1 mx-auto w-fit">
              <thead class="th3">
                <tr class="column">
                  <th class="text-right pl-3 pr-3 pt-1 pb-1">年計</th>
                  <th v-for="(c, i) in monthColumnStaff" :key="'m' + i" class="pl-2 pr-3 pt-1 pb-1 whitespace-pre-line border-bl">
                    <div class="flex justify-between cursor-pointer" @click="removeStaffMonth(c.staffId, i)" v-tooltip="'一時的に非表示'">
                    <!-- <div class="text-right"> -->
                      <Icon iconName="XCircle" class=" h-5 w-5" :clickable="true" />
                      <div>{{ c.name }}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr :class="setColor(i)" v-for="(row, i) in allStaffDataYear" :key="'mr' + i">
                  <td v-if="row.year" class="w-24 text-right pr-1 pb-1 border-bl">{{ row.year }}年{{ row.month }}月</td>
                  <td v-for="staff in monthColumnStaff" :key="staff.staffId + i" class="w-24 text-right pr-3 pb-1 border-bl font-bold text-lg" >{{ row[staff.staffId] }}</td>
                </tr>
              </tbody>
            </table>
            <template>
              <div class="mt-3 mx-auto w-fit">
                <line-chart
                  :chart-data="chartDataStaffYear"
                  :height="height"
                  :width="width"
                ></line-chart>
              </div>
            </template>
          </div>

          <!-- 担当者比較（総数） -->
          <div v-else-if="unit==1 && monthColumnStaff.length" class="mt-5">
            <!-- 月ごと -->
            <div v-for="(all , idx) in allStaffData" :key="'all' + idx" class="mb-3">
              <!-- <div class="font-extrabold staff-name text-center pt-1" :class="idx!=0?'border-t-2 pt-5':''">
                {{ typeName(idx) }}
              </div> -->
              <div class="text-center w-full text-blue-700 font-extrabold pt-2 staff-name" :class="idx!=0?'border-t-2 pt-8':''">{{ typeName(idx) }} （月単位）</div>
              <div class="flex flex-wrap">  
                <table class="border-bl mt-5 table1 mx-auto w-fit">
                  <thead class="th3">
                    <tr class="column">
                      <th class="text-right pl-3 pr-3 pt-1 pb-1">月単位</th>
                      <th v-for="(c, i) in monthColumnStaff" :key="'m' + i" class="text-right pl-3 pr-3 pt-1 pb-1 whitespace-pre border-bl">
                        <div class="flex justify-between cursor-pointer" @click="removeStaffMonth(c.staffId, i)" v-tooltip="'一時的に非表示'">
                        <!-- <div class="text-right"> -->
                          <Icon iconName="XCircle" class=" h-5 w-5" :clickable="true" />
                          <div>{{ c.name }}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="setColor(i)" v-for="(row, i) in all" :key="'mr' + i">
                      <td v-if="row.year" class="w-24 text-right pr-1 pb-1 border-bl">{{ row.year }}年{{ row.month }}月</td>
                      <td v-for="staff in monthColumnStaff" :key="staff.staffId + i" class="w-24 text-right pr-3 pb-1 border-bl font-bold text-lg" >{{ row[staff.staffId] }}</td>
                    </tr>
                  </tbody>
                </table>
                <template>
                  <div class="mt-3 mx-auto w-fit">
                    <line-chart
                      :chart-data="chartDataStaff[idx]"
                      :height="height"
                      :width="width"
                    ></line-chart>
                  </div>
                </template>
              </div>
              <!-- 年計 -->
              <div class="text-center w-full text-blue-700 font-extrabold pt-6">{{ typeName(idx) }} （年計）</div>
              <div class="flex flex-wrap">  
                <table class="border-bl mt-5 table1 mx-auto w-fit">
                  <thead class="th3">
                    <tr class="column">
                      <th class="text-right pl-3 pr-3 pt-1 pb-1">年計</th>
                      <th v-for="(c, i) in monthColumnStaff" :key="'m2' + i" class="text-right pl-3 pr-3 pt-1 pb-1 whitespace-pre border-bl">
                        <div class="flex justify-between cursor-pointer" @click="removeStaffMonth(c.staffId, i)" v-tooltip="'一時的に非表示'">
                        <!-- <div class="text-right"> -->
                          <Icon iconName="XCircle" class=" h-5 w-5" :clickable="true" />
                          <div>{{ c.name }}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="setColor(i)" v-for="(row, i) in all" :key="'mr2' + i">
                      <td v-if="allStaffDataYear[idx][i].year" class="w-24 text-right pr-1 pb-1 border-bl">{{ allStaffDataYear[idx][i].year }}年{{ allStaffDataYear[idx][i].month }}月</td>
                      <td v-for="staff in monthColumnStaff" :key="staff.staffId + i" class="w-24 text-right pr-3 pb-1 border-bl font-bold text-lg" >{{ allStaffDataYear[idx][i][staff.staffId] }}</td>
                    </tr>
                  </tbody>
                </table>
                <template>
                  <div class="mt-3 mx-auto w-fit">
                    <line-chart
                      :chart-data="chartDataStaffYear[idx]"
                      :height="height"
                      :width="width"
                    ></line-chart>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 履歴モーダル -->
    <LineChartHistoryModal 
      v-model="lineChartHistoryModalShow"
      :open="modalOpen"
      @closeModal="closeModal"
    />
    <!-- 詳細表示モーダル -->
    <CountingDetailModal 
      v-model="countingDetailShow"
      :target="countingDetailData"
      :department="department"
      :departmentName="getDepartment()"
      :type="type"
      :typeName="getType()"
      :open="countingDetailShow"
      @closeCountingDetailShow="closeCountingDetailShow"
    />
    <!-- 担当者グラフの順番・色設定 -->
    <CountingDisplaySettingModal 
      v-model="staffDiplaySettingShow"
      :target="monthColumnStaff"
      :open="staffDiplaySettingShow"
      @close="closeCountingDisplaySettingModal"
    />
  </div>
</template>
<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import * as moment from 'moment'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import LineChart from '../components/LineChart.vue'
import LineChartHistoryModal from '../components/LineChartHistoryModal.vue'
import CountingDetailModal from '../components/CountingDetailModal.vue'
import CountingDisplaySettingModal from '../components/CountingDisplaySettingModal.vue'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import html2canvas from 'html2canvas'
import jsPDF from "jspdf"
import fontJa from '@/assets/fonts/mplus-2m-light-normal.js'

export default {
  components: {
    Icon,
    PrimaryButton,
    // EstimateList,
    // DateSelect,
    PageHeader,
    WmsDateInput,
    WmsTextInput,
    LineChart,
    LineChartHistoryModal,
    CountingDetailModal,
    CountingDisplaySettingModal
    // EmptyMessage
  },

  data() {
    return {
      // 集計単位
      unitList: [
        {id: 1, name: '月', active: true},
        {id: 2, name: '週', active: false}
      ],
      unit: 1,

      // 部署
      departmentList: [
        {id: 1, name: '受託のみ', active: true},
        {id: 2, name: '全体', active: false}
      ],
      department: 1,

      // 区分
      typeList: [
        {id: 1, name: '新規物件', active: true},
        {id: 2, name: '追加変更', active: false},
        {id: 3, name: 'スポット', active: false},
        {id: 4, name: '総数', active: false},
      ],
      type: 1,

      // 基準年度
      year: moment().format('YYYY'),
      yearList: [],

      // 基準日
      startDay: '',
      endDay: '',

      // 月カラム
      monthColumn: [],
      // 月カラム（年計）
      monthColumnYear: [],
      // スタッフカラム
      monthColumnStaff: [],

      // 週カラム
      weekColumn1: [],
      weekColumn2: [],

      // 月データ
      monthData: [{
        monthes: '', four: null, three: null, two: null, one: null, zero: null, total: null
      }],

      // 月データ（年計）
      monthDataYear: [{
        monthes: '',  four: null, three: null, two: null, one: null, zero: null
      }],

      // 担当者比較月データ
      allStaffData: [{}],

      // 担当者比較データ(年計)
      allStaffDataYear: [{}],

      // 担当者ごとの月データローディング中
      loadingByStaff: false,

      // チャート
      chartData: {},

      // チャート（年計）
      chartDataYear: {},

      // チャート（スタッフ）
      chartDataStaff: {},

      // チャート（スタッフ年計）
      chartDataStaffYear: {},

      // チャートのラベル
      labels: [],

      // スタッフチャートのラベル
      labelsStaff: [],

      // チャートのオプション
      options: {  
        tooltips: {
            enabled: false
        }
      },

      // 週データ
      weekData: [],

      // 週毎の開始日
      dayList: [],

      // 高さ
      height: 500,

      // 幅
      width: 850,

      // モーダルopen
      modalOpen: 0,

      // モーダル
      lineChartHistoryModalShow: false,

      // 表示開始日、終了日
      dispStartDay: '',
      dispEndDay: '',

      // 集計した日
      dispDay: '',

      // 対象データがない
      emptyMsg: '',

      // ローディング
      loading: false,

      // 詳細モーダル表示
      countingDetailShow: false,

      // 詳細モーダルに渡す年月
      countingDetailData: {},

      // 配色
      colors: [
        '#6495ED',
        '#00CED1',
        '#FFCCCC',
        '#F4A460',

        '#98FB98',
        '#ADFF2F',

        '#EE82EE',
        '#F08080',

        '#FFD700',

        '#9370DB',
        '#FFB6C1'
      ],

      // 担当者別のグラフの順番・色設定モーダル
      staffDiplaySettingShow: false
    }
  },

  computed: {
    disabledBtn() {
      let result = false
      if (this.unit == 2) {
        if (!this.startDay || this.startDay == '' || !this.endDay || this.endDay == '') {
          result = true
        }
      }
      return result
    },

    /**
     * 終了日の最小値
     */
    minEndDay() {
      let result = ''
      if (this.startDay == '') {
        return result
      } else {
        // 翌週の月曜以降の選択
        let week = moment(this.startDay).day()
        week = 7 - week
        let day =  moment(this.startDay).add(week, 'd')
        return moment(day).format('YYYY-MM-DD')
      }
    },

    msg() {
      const msg = '茶色の数字は過去データ固定値です。'
      
      if (this.unit == 1 && this.type != 4) {
        return msg
      }
      return null
    },

    msg2() {
      const msg = 'グレーのセルは未確定値です。'
      
      if (this.unit == 1 && this.year == Number(moment().format('YYYY'))) {
        return msg
      }
      return null
    }
  },

  watch: {
  },

  created() {
    // 2020年までの年リスト生成
    this.yearList = []
    const thisYear = moment().format('YYYY')

    const num = Number(thisYear) - 2020
    for (let i = 0; i <= num; i++) {
      let y = String(Number(thisYear) - i)
      this.yearList.push({ id: y, name: y })
    }
  },

  methods: {
    /**
     * 集計単位変更イベント
     * @param tab 選択したタブ
     */
    unitChanged(tab) {
      this.unitList.map((u) => {
        u.active = false
      })
      tab.active = true

      this.unit = tab.id
    },
    
    /**
     * 部署変更イベント
     * @param tab 選択したタブ
     */
    departmentChanged(tab) {
      this.departmentList.map((d) => {
        d.active = false
      })
      tab.active = true

      this.department = tab.id

      // 初期化
      // 月カラム
      this.monthColumn = []
      this.monthColumnYear = []
      this.monthColumnStaff = []
      // 月データ
      this.monthData = [{
        monthes: '',  four: null, three: null, two: null, one: null, zero: null, total: null
      }]
      this.monthDataYear = [{
        monthes: '',  four: null, three: null, two: null, one: null, zero: null
      }]
      this.allStaffData = []
      // 週データ
      this.weekData = []

    },
    
    /**
     * 区分変更イベント
     * @param tab 選択したタブ
     */
    typeChanged(tab) {
      this.typeList.map((u) => {
        u.active = false
      })
      tab.active = true

      this.type = tab.id

      // 初期化
      // 月カラム
      this.monthColumn = []
      this.monthColumnYear = []
      this.monthColumnStaff = []
      // 月データ
      this.monthData = [{
        monthes: '',  four: null, three: null, two: null, one: null, zero: null, total: null
      }]
      this.monthDataYear = [{
        monthes: '',  four: null, three: null, two: null, one: null, zero: null
      }]
      this.allStaffData = []
    },

    // /**
    //  * 基準年度変更イベント
    //  */
    // yearChanged() {
    //   console.log('変更')
    // },

    /**
     * 集計ボタンイベント
     * @param onlyStaffData 月ごとの担当者比較のみ再取得
     */
    async count(onlyStaffData) {
      // チェック処理
      if (!this.validate()) {
        return
      }
      if (!onlyStaffData) {
        this.loading = true
      }

      let param = {}

      // 月の集計
      if (this.unit == 1) {
        // カラム生成
        this.monthColumn = ['月']
        this.monthColumnYear = ['年計']
        // ラベル生成
        this.labels = []
        for (let i = -4; i <= 0; i++) {
          const year = (Number(this.year) + i) + '年'
          this.monthColumn.push(year)
          this.monthColumnYear.push(year)
          this.labels.push(year)
        }
        // this.monthColumn.push('合計')

        // 年初日,年末日
        const sMonth = (Number(this.year) - 4) + '-01-01'
        const eMonth = this.year + '-12-31'

        param = {unit: this.unit, sMonth: sMonth, eMonth: eMonth, department: this.department, type: this.type, year: this.year}

        // 担当者ごと（非同期）
        this.getCountAllStaff(param)

        if (onlyStaffData) {
          return
        }

      // 週の集計
      } else {
        this.weekData = []
        this.emptyMsg = ''
        const s = moment(this.startDay)
        const e = moment(this.endDay)

        // 開始週の水曜を取得
        let startWeek = s.day()
        startWeek = 3 - startWeek
        const start = s.add(startWeek, 'd').format('YYYY-MM-DD')
      
        // 終了週の火曜を取得
        let endWeek = e.day()
        endWeek = 2 - endWeek
        const end = e.add(endWeek, 'd').format('YYYY-MM-DD')

        // 日付の差
        const diff = moment(end).diff(moment(start), 'days')

        // 何週分か
        const weeks = Math.trunc(diff / 7)

        // 週毎の開始日生成
        this.dayList = []
        this.dispStartDay = ''
        this.dispEndDay = ''
        this.dispDay = ''
        for (let i = 0; i <= weeks; i++) {
          this.dayList.push(moment(start).add(i * 7, 'd').format('YYYY-MM-DD'))
        }
        
        this.dispStartDay = moment(start).format('YYYY/MM/DD')
        this.dispEndDay = moment(end).format('YYYY/MM/DD')
        this.dispDay = moment().format('YYYY/MM/DD')

        // 月初日,月末日
        const sMonth = s.format('YYYY-MM-01')
        const eMonth = e.endOf('month').format('YYYY-MM-DD')

        param = {unit: this.unit, sDay: start, eDay: end, weeks: weeks, sMonth: sMonth, eMonth: eMonth, dayList: this.dayList.join('\',\''), department: this.department}
      }

      // データ取得
      let week = []
      const result = await backend.searchData('estimateApproved/count', param)
      if (result.data.data) {
        if (this.unit == 1) {
          this.monthChart(result.data.data, '単月')
          this.monthChart(result.data.data, '年計')
        } else {
          week = result.data.data
        }
      }
      this.weekTable(week, param)
      this.loading = false
    },

    /**
     * 担当者の比較集計
     * @param param バックに渡すパラメータ
     */
    async getCountAllStaff (param) {
      this.monthColumnStaff = []
      this.allStaffData = []
      this.allStaffDataYear = []
      this.chartDataStaff = {}
      this.chartDataStaffYear = {}

      if (this.department != 1) {
        return
      }
      this.loadingByStaff = true
      const countAllStaffParam = utils.clone(param)
      
      // 基準年が今年なら（年計もあるので2年前から取得、画面に表示する開始期間を生成）
      if (this.year === moment().format('YYYY')) {
        // 2年前
        countAllStaffParam.sMonth2 = moment().subtract(2, 'years').format('YYYY-MM-01')
        // 1年前
        countAllStaffParam.sMonth1 = moment().subtract(1, 'years').format('YYYY-MM-01')
      } else {
        // 2年前
        countAllStaffParam.sMonth2 = moment(countAllStaffParam.eMonth).subtract(2, 'years').format('YYYY-MM-01')
        // 1年前
        countAllStaffParam.sMonth1 = moment(countAllStaffParam.eMonth).subtract(1, 'years').format('YYYY-MM-01')
      }

      countAllStaffParam.month = Number(moment(countAllStaffParam.sMonth1).format('M'))

      const result = await backend.searchData('estimateApproved/countAllStaff', countAllStaffParam)
      if (result.data.data) {
        let staff = {}
        let id = []
        let monthes = []

        // 月ごと
        // 総数以外
        let res = []
        // 総数の場合
        let res1 = []
        let res2 = []
        let res3 = []
        
        // 年計
        // 総数以外
        let resY = []
        // 総数の場合
        let res1Y = []
        let res2Y = []
        let res3Y = []

        // 1年間分の枠を作成
        // 開始の年
        let year = Number(this.year) - 1
        // 開始の月
        let month = Number(moment(countAllStaffParam.sMonth1).format('M'))
        for (let i = 0; i < 13; i++) {
          if (month == 13) {
            month = 1
            year++
          }
          // 月ごと
          // 総数以外
          let row = { year, month }
          // 総数の場合
          let row1 = { year, month }
          let row2 = { year, month }
          let row3 = { year, month }

          // 年計
          // 総数以外
          let rowY = { year, month }
          // 総数の場合
          let row1Y = { year, month }
          let row2Y = { year, month }
          let row3Y = { year, month }

          monthes.push(year + '年' + month + '月')
          for (let j = 0; j < result.data.data.length; j++) {
            const count = result.data.data[j]
            const staffId = String(count.staffId)
            if (count.staff) {
              // 重複を避けて、スタッフ名とスタッフIDを取得
              if (!staff[staffId]) {
                staff[staffId] = { staffId, name: count.staff, color: count.color }
                id.push(count.staffId)
              }

              // 月ごと
              // 同じ年月ごとにデータを収集
              if (year == count.yy && month == count.mm) {
                let counting = Number(count.counting)
                if (!counting) {
                  counting = null
                }
                let totalMonth = Number(count.totalMonth)
                if (!totalMonth) {
                  totalMonth = null
                }
                const subTotalType = Number(count.subTotalType - count.lastYearSubTotalType)

                // 総数
                if (this.type == 4) {
                  // 新規
                  if (count.type == 1) {
                    row1[staffId] = counting
                    // staff[staffId].type1 = count.totalType

                    // 年計
                    if (counting) {
                      row1Y[staffId] = subTotalType
                    }

                  // 追加変更
                  } else if (count.type == 2) {
                    row2[staffId] = counting
                    // staff[staffId].type2 = count.totalType

                    // 年計
                    if (counting) {
                      row2Y[staffId] = subTotalType
                    }

                  // スポット
                  } else if (count.type == 3) {
                    row3[staffId] = counting
                    // staff[staffId].type3 = count.totalType

                    // 年計
                    if (counting) {
                      row3Y[staffId] = subTotalType
                    }
                  }

                  // 全区分の合計
                  row[staffId] = totalMonth
                  // 全区分の合計 年計
                  if (totalMonth) {
                    rowY[staffId] = Number(count.subTotal - count.lastYearSubTotal)
                  }
                  

                // 総数以外
                } else {
                  row[staffId] = counting
                  // 年計
                  if (counting) {
                    rowY[staffId] = Number(count.subTotal - count.lastYearSubTotal)
                  }
                }
              }
            }
          }
          // 月ごと
          res.push(row)
          // 年計
          resY.push(rowY)

          // 総数
          if (this.type == 4) {
            // 月ごと
            res1.push(row1)
            res2.push(row2)
            res3.push(row3)
            // 年計
            res1Y.push(row1Y)
            res2Y.push(row2Y)
            res3Y.push(row3Y)
          }
          month++
        }

        // 総数
        if (this.type == 4) {
          // 月ごと
          this.allStaffData.push(res)
          this.allStaffData.push(res1)
          this.allStaffData.push(res2)
          this.allStaffData.push(res3)
          // 年計
          this.allStaffDataYear.push(resY)
          this.allStaffDataYear.push(res1Y)
          this.allStaffDataYear.push(res2Y)
          this.allStaffDataYear.push(res3Y)
        } else {
          // 月ごと
          this.allStaffData = res
          // 年計
          this.allStaffDataYear = resY
        }

        // ソート
        id.sort(function (a, b) {
          return a - b
        })
        // カラムを生成
        for (let k = 0; k < id.length; k++) {
          const staffId = id[k]
          this.monthColumnStaff.push({ staffId: staff[String(staffId)].staffId, name: staff[String(staffId)].name.replace('　', '\r\n'), staffName: staff[String(staffId)].name })
        }
        this.getStaffChart(id, staff, monthes)
      }
      // console.log(this.staffData)
      this.loadingByStaff = false
    },

    /**
     * スタッフ比較チャート
     * @param idArr スタッフのID配列
     * @param staff スタッフ情報
     * @param monthes 年月
     */
    getStaffChart(idArr, staff, monthes) {
      // 総数
      if (this.type == 4) {
        this.chartDataStaff = []
        this.chartDataStaffYear = []
        for (let i = 0; i < this.allStaffData.length; i++) {
          const all = this.allStaffData[i]
          this.chartDataStaff.push(this.getStaffChartData(idArr, staff, monthes, all))
          const allYear = this.allStaffDataYear[i]
          this.chartDataStaffYear.push(this.getStaffChartData(idArr, staff, monthes, allYear))
        }
      // 総数以外
      } else {
        this.chartDataStaff = this.getStaffChartData(idArr, staff, monthes, this.allStaffData)
        this.chartDataStaffYear = this.getStaffChartData(idArr, staff, monthes, this.allStaffDataYear)
      }

      
    },

    /**
     * 担当者比較のチャートデータを成形
     * @param idArr スタッフのID配列
     * @param staff スタッフ情報
     * @param monthes 年月
     * @param allStaffData 担当者ごとの表
     */
    getStaffChartData (idArr, staff, monthes, allStaffData) {
      const labels = []
      const colors = []
      const list = []
      // const total = {}

      const chart = {
        labels: monthes,
        datasets: []
      }
        
      for (let i = 0; i < idArr.length; i++) {
        const id = idArr[i]
        const byStaff = []
        labels.push(staff[id].name)
        colors.push(staff[id].color)

        // total[id] = staff[id].total
        // if (idx) {
        //   total[id] = staff[id]['type' + idx]
        // }

        for (let j = 0; j < allStaffData.length; j++) {
          const a = allStaffData[j]
          let counting = null
          if (a[id]) {
            counting = a[id]
          }
          byStaff.push(counting)
        }
        list.push(byStaff)
      }

      // 総計を追加
      // allStaffData.push(total)

      for (let k = 0; k < labels.length; k++) {
        const label = labels[k]
        const data = list[k]

        chart.datasets.push(
          {
            label,
            backgroundColor: colors[k],
            borderColor: colors[k],
            fill:false,
            lineTension: 0,
            data
          }
        )
      }
      return chart
    },

    /**
     * 担当者比較のグラフ削除
     * @param chartDataStaff 元のグラフデータ
     * @param idx 削除するインデックス
     */
    removeStaffChartData (chartDataStaff, idx) {
      chartDataStaff.datasets.splice(idx, 1)

      const chart = {
        labels: chartDataStaff.labels,
        datasets: chartDataStaff.datasets
      }
      return chart
    },

    /**
     * 集計区分（担当者比較）
     */
    typeName(i) {
      if (i == 0) {
        return '合計'
      } else if (i == 1) {
        return '新規物件'
      } else if (i == 2) {
        return '追加変更'
      } else if (i == 3) {
        return 'スポット'
      }
    },

    /**
     * チャート生成
     * @param monthes データ
     * @param tar 単月か年計
     */
    monthChart (monthes, tar) {
      let mm = '月'
      if (tar == '年計') {
        mm = '月 年計'
        // 単月のデータから年計を算出する
        monthes = this.getMonthAnnual(monthes)
      }
      if (this.unit == 1 && monthes.length) {
        let month = []
        let four = []
        let three = []
        let two = []
        let one = []
        let zero = []
        let thisMonth = []
        for (let i = 0; i < 12; i++) {
          const m = monthes[i]
          month.push(m.monthes + mm)
          four.push(m.four)
          three.push(m.three)
          two.push(m.two)
          one.push(m.one)
          // 未来はnull
          if (this.year == moment().format('YYYY') && Number(m.monthes) > Number(moment().format('M'))) {
            zero.push(null)
            thisMonth.push(null)
          // 先月の場合
          } else if (this.year == moment().format('YYYY') && Number(m.monthes) == Number(moment().format('M')) - 1) {
            thisMonth.push(m.zero)
            zero.push(m.zero)
          // 今月の場合
          } else if (this.year == moment().format('YYYY') && Number(m.monthes) == Number(moment().format('M'))) {
            thisMonth.push(m.zero)
            zero.push(null)
          } else {
            zero.push(m.zero)
            thisMonth.push(null)
          }
        }

        let borderWidth = 2
        // 今年の線を太くする
        if (thisMonth && thisMonth.length) {
          borderWidth = 5
        }

        const chart = {
          labels: month,
          datasets: []
        }

        // 今月のデータがある場合
        if (thisMonth && thisMonth.length) {
          chart.datasets.push(
            {
              label: '未確定',
              backgroundColor: '#FFFFFF',
              borderColor: '#23DDD9',
              borderDash: [7, 4],
              borderWidth,
              pointBackgroundColor: '#FFFFFF',
              fill:false,
              lineTension: 0,
              data: thisMonth
            }
          )
        }
        
        const datasets = [
          {
            label: this.labels[4],
            backgroundColor: '#23DDD9',
            borderColor: '#23DDD9',
            borderWidth,
            fill:false,
            lineTension: 0,
            data: zero
          },
          {
            label: this.labels[3],
            backgroundColor: '#9A7DEB',
            borderColor: '#9A7DEB',
            borderWidth: 2,
            fill:false,
            lineTension: 0,
            data: one
          },
          {
            label: this.labels[2],
            backgroundColor: '#A1A0AE',
            borderColor: '#A1A0AE',
            borderWidth: 1.5,
            fill:false,
            lineTension: 0,
            data: two
          },
          {
            label: this.labels[1],
            backgroundColor: '#F98F8F',
            borderColor: '#F98F8F',
            borderWidth: 1.5,
            fill:false,
            lineTension: 0,
            data: three
          },
          {
            label: this.labels[0],
            backgroundColor: '#EECF12',
            borderColor: '#EECF12',
            borderWidth: 1.5,
            fill:false,
            lineTension: 0,
            data: four
          }
        ]

        chart.datasets = chart.datasets.concat(datasets)
        // console.log(this.chartData)
        if (tar == '年計') {
          this.monthDataYear = monthes
          this.chartDataYear = chart
        } else if (tar == '単月') {
          this.monthData = monthes
          this.chartData = chart
        }
      }
    },

    /**
     * 年計データを成形
     * @param monthes 対象データ
     */
    getMonthAnnual(monthes) {
      const val = utils.clone(monthes)
      // 合計行を取得
      const total = val[12]

      for (let i = 0; i < val.length - 1; i++) {
        const v = val[i]
        // 4年前の年計
        v.four = total.five - v.fiveSubtotal + v.fourSubtotal
        // 3年前の年計
        v.three = total.four - v.fourSubtotal + v.threeSubtotal
        // 2年前の年計
        v.two = total.three - v.threeSubtotal + v.twoSubtotal
        // 1年前の年計
        v.one = total.two - v.twoSubtotal + v.oneSubtotal
        // 0年前の年計
        // 未来はnullにする
        if (this.year == moment().format('YYYY') && Number(v.monthes) > Number(moment().format('M'))) {
          v.zero = null
        } else {
          v.zero = total.one - v.oneSubtotal + v.zeroSubtotal
        }
      }
      // 合計行を削除
      val.splice(-1, 1)
      return val
    },

    /**
     * 週の集計
     * @param week 取得した週のデータ
     * @param param パラム
     */
    weekTable(week, param) {
      if (this.unit == 2 && week.column.length) {
        if (param) {
          const weekRowsCopy = utils.clone(week.rows)
          this.saveWeekData(week, weekRowsCopy, param)
        }

        // カラム
        this.weekColumn1 = []
        this.weekColumn2 = []
        this.weekColumn1.push({name: '', col: 1, class: 'th'})
        this.weekColumn2.push({name: '', staffId: null, type: 0, class: 'th min-w250 '})

        for (let i = 0; i < week.column.length; i++) {
          const wc = week.column[i];
          // カラム生成 1行目
          this.weekColumn1.push({name: wc.staffName, staffId: wc.staffId, col: 3, class: 'th'})
          this.weekColumn1.push({name: wc.staffName + ' 集計', staffId: wc.staffId, col: 1, class: 'bg-gray-200'})
          // カラム生成 2行目
          this.weekColumn2.push({name: '①新規', staffId: wc.staffId, type: 1, class: 'min-w85 th'})
          this.weekColumn2.push({name: '②変更', staffId: wc.staffId, type: 2, class: 'min-w85 th'})
          this.weekColumn2.push({name: '➂ｽﾎﾟｯﾄ', staffId: wc.staffId, type: 3, class: 'min-w85 th'})
          this.weekColumn2.push({name: '', staffId: wc.staffId, type: 4, class: 'min-w140 bg-gray-200'})
        }

        // 週データ生成
        this.weekData = []
        for (let i = 0; i < this.dayList.length; i++) {
          const d = this.dayList[i];
          let row = utils.clone(this.weekColumn2)
          row.forEach(r => {
            r.val = ''
          })

          for (let rowI = 0; rowI < row.length; rowI++) {
            const r = row[rowI];

            // 日付
            if (r.type == 0) {
              r.val = moment(d).format('YYYY/MM/DD') + ' ~ ' + moment(d).add(6, 'd').format('YYYY/MM/DD')
            }

            // 取得したデータをリスト化
            let index = null
            for (let weekI = 0; weekI < week.rows.length; weekI++) {
              const wr = week.rows[weekI];
              // 取得したデータの日付と生成した日付が同じ場合
              if (d == moment(wr.weekStart).format('YYYY-MM-DD')) {
                // スタッフIDと区分が同じ場合
                if (r.staffId == wr.staffId && r.type == wr.type) {
                  r.val = Number(wr.counted).toLocaleString()
                  index = weekI
                }
              } else {
                break
              }
            }

            if (index != null) {
              week.rows.splice(index, 1)
            }
          }
          this.weekData.push(row)
        }

        // 最終行を生成
        let total = utils.clone(this.weekColumn2)
        total.forEach(t => {
          t.val = ''
        })
        for (let i = 0; i < total.length; i++) {
          const t = total[i];
          if (t.type == 0) {
            t.val = '総計'
          }
          for (let totI = 0; totI < week.total.length; totI++) {
            const wt = week.total[totI];
            if (t.staffId == wt.staffId && t.type == wt.type) {
              t.val = Number(wt.total).toLocaleString()
            }
          }
        }
        this.weekData.push(total)
        // console.log(this.weekData)
      } else {
        this.emptyMsg = '対象データがありません。'
      }
    },

    /**
     * 集計データ保存
     * @param week データ
     * @param param param
     */
    async saveWeekData(week, weekRowsCopy, param) {
      
      // IDを生成
      const weekId = Date.now()

      for (let i = 0; i < week.column.length; i++) {
        const column = week.column[i];
        column.weekId = weekId
        // column.insertUser = this.$store.getters.user.id
      }
      for (let i = 0; i < weekRowsCopy.length; i++) {
        const rows = weekRowsCopy[i];
        rows.weekId = weekId
        // rows.insertUser = this.$store.getters.user.id
      }
      for (let i = 0; i < week.total.length; i++) {
        const total = week.total[i];
        total.weekId = weekId
        // total.insertUser = this.$store.getters.user.id
      }
      const weekHistory = [
        { weekId: weekId, department: param.department, startDay: param.sDay, endDay: param.eDay, insertUserId: this.$store.getters.user.id, insertUserName: utils.deleteSpace(utils.deleteKana(this.$store.getters.user.user_name)) }
      ]

      // 保存
      week.weekRows = weekRowsCopy
      week.weekHistory = weekHistory
      // console.log(week)
      let result = await backend.postData('weekHistoryKeyword/save', week)
      if (result.data == 'Internal Server Error') {
        alert('集計結果を保存中にエラーが発生しました。\r\nコンソールを確認してください。')
      }
    },

    /**
     * スタッフ、週毎の合計
     * @param row 対象行
     * @param staffId 対象スタッフID
     */
    getTotal(row, staffId) {
      let total = 0
      for (let i = 0; i < row.length; i++) {
        const r = row[i];
        if (r.staffId == staffId) {
          let num = 0
          if (r.val != '') {
            num = r.val.replace(',', '')
          }
          total += Number(num)
        }
      }
      let result = ''
      if (total != 0) {
        result = total.toLocaleString()
      }
      return result
    },

    /**
     * バリデート
     */
    async validate() {
      // 週の集計
      if (this.unit == 2) {
        // 日付チェック
        if (this.startDay > this.endDay) {
          await dialogs.showErrorDialog('日付', '開始日が終了日よりも後になっています。')
          return false
        }
      }
      return true
    },

    /**
     * 背景色設定 月
     * @param i index
     */
    setColor(i) {
      if (i != 12 && i % 2 != 0) {
        return 'bg-blue'
      }
    },

    /**
     * 背景色設定 月 最終行
     * @param i index
     */
    lastRow(i) {
      if (i == 12) {
        return 'th font-bold'
      }
    },

    /**
     * 背景色設定 週
     * @param i index
     */
    setColor2(i, type) {
      if (i != this.weekData.length-1) {
        if (type !=4 && i % 2 != 0) {
          return 'bg-blue'
        } else if (type == 4) {
          return 'bg-gray-200'
        }
      } else {
        if (type == 4) {
          return 'bg-gray-200 font-bold'
        } else {
          return 'th font-bold'
        }
      }
    },

    modal() {
      this.lineChartHistoryModalShow = true
      this.modalOpen++
    },

    /**
     * 過去データ表示
     */
    async closeModal(selected) {
      this.lineChartHistoryModalShow = false
      let result = await backend.searchData('weekHistoryKeyword/getDataByWeekId', { weekId: selected.weekId })
      if (result.data == 'Internal Server Error') {
        alert('履歴データ取得中にエラーが発生しました。\r\nコンソールを確認してください。')
      }
      // 日付の差
      const diff = moment(selected.endDay).diff(moment(selected.startDay), 'days')

      // 何週分か
      const weeks = Math.trunc(diff / 7)

      // 週毎の開始日生成
      this.dayList = []
        this.dispStartDay = ''
        this.dispEndDay = ''
        this.dispDay = ''
        for (let i = 0; i <= weeks; i++) {
          this.dayList.push(moment(selected.startDay).add(i * 7, 'd').format('YYYY-MM-DD'))
        }
        
        this.dispStartDay = moment(selected.startDay).format('YYYY/MM/DD')
        this.dispEndDay = moment(selected.endDay).format('YYYY/MM/DD')
        this.dispDay = moment(selected.insertDay).format('YYYY/MM/DD')
      this.weekTable(result.data.data, null)
    },

    /**
     * PDF出力ボタン
     */
    async pdfBtn() {
      if ((this.unit == 1 && !this.monthColumn.length) || (this.unit == 2 && !this.weekData.length)) {
        await dialogs.showErrorDialog('対象データなし', '出力対象データがありません。\r\n「集計」ボタンを押してください。')
        return
      }

      let target = ''
      let name = ''

      if (this.unit == 1) {
        target = '#canvas-month'
        name = '【月】'
      } else {
        target = '#canvas-week'
        name = '【週】'
      }
      const fileName = `見積集計_${moment().format('YYYYMMDD')}${name}.pdf`
      
      html2canvas(document.querySelector(target)).then(canvas => {
        let downloadEle = document.createElement("a")
        downloadEle.href = canvas.toDataURL("image/png")

        // 横向きに表示
        const doc = new jsPDF('l')

        // 日本語フォント
        doc.addFileToVFS("mplus-2m-light.ttf", fontJa)
        doc.setFont('mplus-2m-light', 'normal')

        // フォントサイズ
        doc.setFontSize(8)

        // 部署名
        let dep = `部署名： ${this.getDepartment()}`
        doc.text(dep, 10, 10)

        if (this.unit == 1) {
          // 区分名
          let type = `区分名： ${this.getType()}`
          doc.text(type, 10, 15)
        }

        // 表、グラフのキャプチャー
        const width = doc.internal.pageSize.width
        doc.addImage(canvas, "JPEG", 0, 20, width, 0);
        
        doc.save(fileName);
        // PDFの2ページ目に貼り付けたい場合、doc.addPage()
      });
    },

    /**
     * 部署名取得
     */
    getDepartment() {
      return this.departmentList.find((d)=> {
        return d.id == this.department
      }).name
    },

    /**
     * 区分名取得
     */
    getType() {
      return this.typeList.find((d)=> {
        return d.id == this.type
      }).name
    },

    /**
     * お客様から頂いたデータを反映している文字色を茶色にする
     * @param y 基準年との差
     * @param m 月
     */
    setTextColor(y, m) {
      // 何年のデータか
      let year = this.year - y

      if (y == 0) {  
        // 今年
        const thisYear = Number(moment().format('YYYY'))
        // 今月
        const thisMonth = Number(moment().format('M'))

        // 今月のデータの場合
        if (year == thisYear && m == thisMonth) {
          return 'bg-gray-400 text-white font-bold'
        }
      }

      if (this.type != 4) {
        if (year <= 2023 && m <= 12) {
          return 'text-yellow-800'
        } else if (year == 2024 && m < 4) {
          return 'text-yellow-800'
        }
      }
      return ''
    },

    /**
     * 削除ボタンイベント
     * @param staffId 削除するスタッフID
     */
    removeStaff(staffId) {
      const res = []
      let res2 = []
      for (let i = 0; i < this.weekData.length; i++) {
        const w = this.weekData[i]
        for (let j = 0; j < w.length; j++) {
          const r = w[j]
          if (r.staffId != staffId) {
            res2.push(r)
          }
        }
        res.push(res2)
        res2 = []
      }
      this.weekData = utils.clone(res)

      let co1 = this.weekColumn1.filter((c1) => {
        return c1.staffId != staffId
      })
      this.weekColumn1 = utils.clone(co1)

      let co2 = this.weekColumn2.filter((c2) => {
        return c2.staffId != staffId
      })
      this.weekColumn2 = utils.clone(co2)
    },

    /**
     * 担当者ごと月データ削除ボタンイベント
     * @param staffId 削除するスタッフID
     */
    removeStaffMonth(staffId, i) {
      // カラムから削除
      this.monthColumnStaff.splice(i, 1)

      // 総数の場合
      if (this.type == 4) {
        for (let j = 0; j < this.allStaffData.length; j++) {
          for (let k = 0; k < this.allStaffData[j].length; k++) {
            delete this.allStaffData[j][k][staffId]
            delete this.allStaffDataYear[j][k][staffId]
          }
          this.chartDataStaff[j] = this.removeStaffChartData(this.chartDataStaff[j], i)
          this.chartDataStaffYear[j] = this.removeStaffChartData(this.chartDataStaffYear[j], i)
        }
      // 総数以外
      } else {
        for (let j = 0; j < this.allStaffData.length; j++) {
          delete this.allStaffData[j][staffId]
          delete this.allStaffDataYear[j][staffId]
        }
        this.chartDataStaff = this.removeStaffChartData(this.chartDataStaff, i)
        this.chartDataStaffYear = this.removeStaffChartData(this.chartDataStaffYear, i)
      }
    },

    /**
     * 集計詳細表示イベント
     * @param y 年
     * @param m 月
     * @param cssTxt 文字色（固定値ではない場合は空文字）
     */
    async getDetail(y, m, cssTxt) {
      const year = this.year - y
      const sMonth = moment(year + '-' + m + '-01').format('YYYY-MM-DD')
      const eMonth = moment(sMonth).endOf('month').format('YYYY-MM-DD')

      if (cssTxt) {
        const res = await dialogs.showConfirmDialog('固定値ですが詳細を表示しますか？', '固定値のため、詳細の数と一致しないことや詳細がない場合があります。')
        if (res !== 'YES') {
          return
        }
      }
      this.countingDetailData = {
        sMonth,
        eMonth,
        month: m
      }
      this.countingDetailShow = true
    },

    /**
     * 詳細モーダルclosed
     */
    closeCountingDetailShow() {
      this.countingDetailShow = false
    },

    /**
     * 担当者別のグラフの順番・色設定モーダルopen
     */
    staffDiplaySetting() {
      this.staffDiplaySettingShow = true
    },

    /**
     * 担当者別のグラフの順番・色設定モーダルopen
     */
    closeCountingDisplaySettingModal() {
      this.staffDiplaySettingShow = false
      
      this.count(true)
    }

  },
}
</script>
<style scoped>
.list {
  min-width: 1500px;
}

.main-height {
  height: calc(100vh - 165px);
}

.w-tab {
  width: 120px;
}

.border-bl {
  border: solid 0.5px #a6dce5;
}

.th {
  background: #a6dce5;
}

.th3 {
  background: #102297;
  color: white;
}

.staff-name {
  /* color: #102297; */
  border-color: #102297;
}

.min-w85 {
  min-width: 85px;
}

.min-w140 {
  min-width: 140px;
}

.min-w250 {
  min-width: 250px;
}

.bg-blue {
  background: #f3f9f5;
}

.table1 tr:hover td {
  background: #a6dce5;
}

#table2 tr:hover td {
  background: #a6dce5 !important;
}

.chart {
  width: 500px !important;
}

.w-fitcon {
  width: fit-content;
}

.mr-20p {
  margin-right: 20%;
}
</style>