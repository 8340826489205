<template>
  <div>
    <div v-if="downloading" class="flex justify-center items-center loading-dialog3">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
    <PageHeader :title="`apollo-RP - ${typeName}`" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            text="検索"
            class="ml-auto flex button-contents"
            @click="getData()"
            :buttonContents="'要'"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center ml-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="ml-2 flex">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex justify-between">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="" class="w-7 h-7 mr-2" />
            <span class="mt-0">未完了の予定 一覧</span>
          </h1>
          
          <!-- 予定日 -->
          <div class="ml-auto mt-4 mr-3 flex">
            <div class="text-xs text-gray-600 font-bold w-28" id="filter-date"  v-tooltip="'作業が複数日の場合、初日の作業日が検索対象'">
              <WmsDateInput
                name="startDay"
                v-model="period.start"
                placeholder="予定日 検索"
              />
            </div>
            <div class="pt-3 mx-1 text-white font-bold">
              ～
            </div>
            <div class="text-xs text-gray-600 font-bold w-28" id="filter-date" v-tooltip="'作業が複数日の場合、初日の作業日が検索対象'">
              <WmsDateInput
                id="filter-date"
                name="endDay"
                :min="period.start"
                v-model="period.end"
                placeholder="予定日 検索"
              />
            </div>
          </div>
          <!-- 得意先 -->
          <div class="mr-2 mt-5">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterClient" 
              placeholder="得意先 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterClient"
            />
          </div>
          <!-- 支店名 -->
          <div class="mr-2 mt-5">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterBranch" 
              placeholder="支店名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterBranch"
            />
          </div>
          <!-- 物件名 -->
          <div class="mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterSite" 
              placeholder="物件名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterSite"
            />
          </div>
          <!-- 担当者 -->
          <div class="mt-5">
            <input
              v-tooltip="'スペースは無視します'"
              type="search"
              name="filterStaff" 
              placeholder="担当者 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterStaff"
            />
          </div>
        </div>     
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 mt-5 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-show="!loading">
            <div class="rounded main-height">
              <table class="main table-auto overflow-auto">
                  <thead class="main sticky top-0">
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'cu2'+index" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 bg-white tracking-wider hover-color" @dblclick="open(row)">

                  <!-- No -->
                  <td class="b-top text-left px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no cursor-not-allowed">
                    {{ i + 1 }}.
                  </td>
                  <!-- 予定日 -->
                  <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-100">
                    {{ row.main }}
                    <div v-if="row.sub">
                      <div v-for="(s, sidx) in row.sub" :key="sidx" class="text-xs p-1 bg-blue-50 text-blue-700 rounded w-fit">
                        {{s}}
                      </div>
                    </div>
                  </td>
                  <!-- 得意先 -->
                  <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-300" v-tooltip="`得意先コード：${row.clientCode}`">
                    {{ row.clientName1 }}
                  </td>
                  <!-- 支店名 -->
                  <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-300" v-tooltip="`得意先コード：${row.clientCode}`">
                    {{ row.clientName2 }}
                  </td>
                  <!-- 物件名 -->
                  <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-300" v-tooltip="`物件コード：${row.siteCode}`">
                    {{ row.siteName }}
                    <span v-if="row.ridgeCode" class="text-red-700 font-bold">（{{ row.ridgeName }}）</span>
                  </td>
                  <!-- 担当者 -->
                  <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                    {{ row.staff }}
                  </td>
                  <!-- ダウンロード -->
                  <td class="w-28 text-center">
                    <PrimaryButton v-tooltip="'ダウンロード'" @click="openPosterModal(row, false)" :buttonContents="'要'">
                      <Icon slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                    </PrimaryButton>
                  </td>
                  <!-- ダウンロード -->
                  <td class="w-28 text-center">
                    <PrimaryButton v-if="row.rowspan>1" v-tooltip="'物件単位の張り紙ダウンロード'" @click="openPosterModal(row, true)" :buttonContents="'要'">
                      <Icon slot="before" iconName="Download" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                    </PrimaryButton>
                    <div v-else-if="row.siteCount>1" class="text-xs text-gray-500">
                      上の行と同物件
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ダウンロード画面 -->
    <PosterModal 
      v-model="posterModal"
      :target="target"
      :target2="target2"
      :bySite="bySite"
      :typeName="typeName"
      :open="posterModal"
      @modalClose="modalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import PosterModal from './PosterModal.vue'
import * as moment from 'moment'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import PrimaryButton from '@components/PrimaryButton.vue'

export default {
  components: {
    Icon,
    WmsDateInput,
    PageHeader,
    PosterModal,
    PrimaryButton
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      loading: false,
      downloading: false,
      
       //検索保持用
      searchCondition: {
        filterClient : '',
        filterBranch : '',
        filterSite : '',
        filterStaff: '',
        period: {},
        periodPresetName: ''
      },
      // カラム
      columns: [
          {title: 'No.', class: 'text-left'},
          {title: '予定日', class: 'text-left'},
          {title: '得意先', class: 'text-left'},
          {title: '支店名', class: 'text-left'},
          {title: '物件名', class: 'text-left'},
          {title: '担当者', class: 'text-left'},
          {title: 'ダウンロード', class: 'text-righ'},
          {title: '棟まとめて', class: 'text-righ'},
      ],
      // 予定日
      period: {
        start: moment().format('YYYY-MM-DD'),
        end: ''
      },
      // 得意先絞込み
      filterClient: '',
      // 支店名絞込み
      filterBranch: '',
      // 物件名絞込み
      filterSite: '',
      // 担当者絞込み
      filterStaff: '',
      // 表示するデータ
      list: [],
      // モーダルopen
      posterModal: false,
      // ダウンロードするデータ（棟ごと・1行ごと）
      target: {},
      // ダウンロードするデータ（棟をまとめて）
      target2: [],
      // ダウンロード方法 false：1行ごと true：棟をまとめる
      bySite: false
    }
  },

  async created() {
    // 条件取得
    this.getCondition()
    
    await this.getData()
  },

  methods: {
    /**
     *  条件取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)

      if(getItem !== null && getItem !== undefined){
        this.filterClient = getItem.filterClient
        this.filterBranch = getItem.filterBranch
        this.filterSite = getItem.filterSite
        this.filterStaff = getItem.filterStaff
        this.period = getItem.period || this.period
        this.periodPresetName = getItem.periodPresetName || this.periodPresetName
      }
    },

    /**
     * データ取得
     */
    async getData() {

      this.loading = true
      this.list = []

      // セッションストレージ保存用
      this.searchCondition.filterClient = this.filterClient
      this.searchCondition.filterBranch = this.filterBranch
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.period = this.period
      this.searchCondition.periodPresetName = this.periodPresetName
      this.searchCondition.filterStaff = this.filterStaff
      
      this.setSessionStorage(this.searchCondition,this.settionStrageName)

      const checkR = await backend.postData('patrolSchedule/getIncompleteByDate', { filterSite: this.filterSite, filterClient: this.filterClient, filterBranch: this.filterBranch, period: this.period, filterStaff: this.filterStaff, type: this.type })
      if (checkR.data && checkR.data.data.length) {
        this.list = checkR.data.data
        let siteCodes = []
        for (let i = 0; i < this.list.length; i++) {
          const l = this.list[i]
          if (l.siteCount == 1) {
            l.rowspan = 1
          } else {
            if (siteCodes.includes(l.siteCode)) {
              l.rowspan = 1
            } else {
              l.rowspan = l.siteCount
              siteCodes.push(l.siteCode)
            }
          }
        }
      }
      this.loading = false
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    async refresh(){
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * 張り紙モーダルopen
     * @param row 対象データ
     * @param bySite 棟をまとめるか：falseまとめない、trueまとめる
     */
    openPosterModal(row, bySite) {
      this.target = {}
      this.target2 = []
      if (!bySite) {
        this.target = utils.clone(row)
      } else {
        this.target2 = this.list.filter((l) => {
          return l.siteCode == row.siteCode
        })
      }
      this.bySite = bySite
      this.posterModal = true
    },

    /**
     * 張り紙モーダルclose
     */
    modalClose() {
      this.posterModal = false
      this.target = {}
      this.target2 = []
    },
  },
}
</script>
<style scoped>
.main {
  width: 100%;
}

.bg-colu {
    background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-100 {
    min-width: 100px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  cursor: pointer;
}

#filter-date >>> input[type=text] {
  height: 37.8px !important;
}

@media screen and (max-width: 1200px) {
  .list {
    min-width: 1200px !important;
    bottom: auto !important;
  }
}
</style>