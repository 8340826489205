<template>
  <div>
    <label :for="name" class="block text-xs font-bold text-gray-700 relative">
      {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="mt-1 flex relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" />
      <SelectionButton 
        :name="name" 
        v-bind="$attrs" 
        v-on="$listeners" 
        class="w-full"
        :class="error ? 'error' : ''"
      />
    </div>
  </div>
</template>
<script>
import InputIcon from '@components/InputIcon.vue'
import SelectionButton from '@components/SelectionButton.vue'

export default {
  inheritAttrs: false,

  components: {
    InputIcon,
    SelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    caption: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    },

    errorMessage: {
      type: String,
      default: ''
    },

    errorColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.error {
  padding-left: 32px;
}
</style>
