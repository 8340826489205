<template>
  <div class="flex flex-col h-full border border-gray-300 rounded bg-white relative">
    <div class="overflow-auto flex-1 relative">
      <VirtualDataTable
        ref="vdt"
        :columns="columns" 
        :columnWidth="columnWidth" 
        :columnAlignment="columnAlignment" 
        :rowHeight="36"
        :data="rows" 
        :selectable="selectable"
        :rowClassesFunc="rowClassesFunc"
        @selectionChange="selectionChange"
      />

      <!-- <table class="min-w-full divide-y divide-gray-200 table-auto">
        <thead class="bg-gray-50">
          <tr>
            <th v-if="selectable" scope="col" class="bg-gray-100 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
              選択
            </th>
            <template v-for="column in columns">
              <th :key="column.name" scope="col" class="bg-gray-100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10">
                {{ column.title }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(row, index) in rows" 
            :key="row._id" 
            :class="row.selected ? 'bg-indigo-50' : (index % 2 === 0 ? 'bg-white' : 'bg-gray-50')"
          >
            <td 
              v-if="selectable" 
              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center cursor-pointer"
            >
              <input 
                :id="row._id" 
                name="item" 
                type="radio" 
                :value="row._id"
                v-model="selectedRowId"
                class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" 
                @change="rowSelectChange"
              >
            </td>
            <template v-for="column in columns">
              <td :key="column.name" class="text-sm font-medium text-gray-600 whitespace-pre-line --flex">
                <label :for="row._id" class="px-6 py-4 cursor-pointer flex">
                  {{ row[column.name] }}
                </label>
              </td>
            </template>
          </tr>
        </tbody>
      </table> -->

    </div>
    <p 
      v-if="rows.length === 0"
      class="absolute w-full h-full flex justify-center items-center text-gray-400"
    >
      {{ noDataMessageText }}
    </p>
  </div>
</template>

<script>
import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as utils from '@libs/utils'

const defaultOptions = {
  formId: '',
  displayFields: [],
  searchFields: [],
  sortField: '',
  selectable: true,
  filter: {}
}

export default {
  components: {
    VirtualDataTable
  },

  props: {
    options: {
      type: Object,
      default: defaultOptions
    },
    searchText: {
      type: String,
      default: ''
    },
    noDataMessageText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      columns:[],
      columnWidth: [],
      columnAlignment: [],
      rows: [],
      selectable: false,
      // selectedRowId: ''
    }
  },

  // computed: {
  //   selectedRow() {
  //     return this.rows.find((row) => {
  //       return row._id === this.selectedRowId
  //     })
  //   }
  // },

  watch: {
    options: {
      handler: function() {
        this.init()
      },
      deep: true
    },

    searchText() {
      this.fetchData()
    }
  },

  async created() {
    console.log('ListView :: created')
    await this.init()
  },

  methods: {
    async init() {

      this.rows.splice(0)
      this.columns.splice(0)
      this.columnWidth.splice(0)
      this.columnAlignment.splice(0)
      
      this.selectable = false
      // this.selectedRowId = ''
      if (this.options) {
        await this.createColumns()
        await this.fetchData()
        this.selectable = this.options.selectable
      }
    },

    async createColumns() {
      if (this.options && this.options.formId) {
        const form =  await this.$pigeon.getForm(this.options.formId)
        if (form) {
          for (const df of this.options.displayFields) {
            for (const c of form.columns) {
              for (const f of c.fields) {
                if (f.name === df) {
                  // this.columns.push(f)
                  this.columns.push([{
                    name: f.name,
                    title: f.title,
                    type: 'text'
                  }])
                  this.columnWidth.push('auto')
                  this.columnAlignment.push('left')
                }
              }
            }
          }
        }
      }
    },

    async fetchData() {

      // if (!this.searchText) {
      //   this.rows.splice(0)
      //   return
      // }

      if (!this.options) {
        return
      }

      const filter = this.options.filter || {}

      const sort = {}
      if (this.options.sortField) {
        sort[this.options.sortField] = 1
      }

      const params = {
        searchCondition: {
          type: this.options.formId,
          status: { $ne: "remove" },
          ...filter 
        },
        sort,
        // selectFields: this.options.displayFields
      }

      if (this.searchText) {
        const query = utils.buildMongoQuery(this.searchText, this.options.searchFields)
        if (query) {
          params.searchCondition = { ...params.searchCondition, ...query }
        }
      }

      try {
        const responseData = await this.$pigeon.searchTasks(params)
        this.rows = responseData
      } catch (error) {
        console.log('fetchData error !!!')
        console.log(error)
        this.rows = []
      }
    },

    // allSelectChange() {
    //   this.rows.forEach((row) => {
    //     row.selected = this.allSelected
    //   })
    //   this.emitSelectionChange()
    // },

    selectionChange(selectedRow) {
      this.$emit('selectionChange', selectedRow)
    },

    // emitSelectionChange() {
    //   this.$emit('selectionChange', this.selectedRow)
    // },

    // async refresh() {
    //   // const selectedRowId = this.selectedRow._id
    //   await this.fetchData()
    //   for (const row of this.rows) {
    //     row.selected = row._id === this.selectedRowId
    //   }
    //   this.emitSelectionChange()

    //   // const selectedRowIds = this.selectedRows.map((row) => {
    //   //   return row._id
    //   // })

    //   // await this.fetchData()
      
    //   // this.rows.forEach((row) => {
    //   //   if (selectedRowIds.includes(row._id)) {
    //   //     row.selected = true
    //   //   }
    //   // })

    //   // this.emitSelectionChange()
    // },

    rowClassesFunc() {
      return null
    }

  }
}
</script>