<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <PlusCircleIcon v-tooltip="'追加'" class="ml-auto mr-3 text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer w-8 h-8" @click="edit(null)" />
        <div class="overflow-hidden rounded main-height">
            <div class="main-height overflow-auto">
                <table class="overflow-auto main table-auto mb-3">
                    <thead class="main sticky top-0 bg-white">
                        <tr>
                            <th v-for="(f, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                                <div v-if="f.title == '発注先'" class="">
                                    <input
                                        type="search"
                                        name="filterSupplier" 
                                        placeholder="発注先 検索"
                                        class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                        v-model="filterSupplier"
                                    />
                                </div>
                                <div v-else></div>
                            </th>
                        </tr>
                        <tr v-if="list && list.length" class="border border-gray-200">
                            <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="index==0?'text-center':'text-left'">
                                {{c.title}}
                            </th>
                        </tr>
                    </thead>

                        <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider">

                            <!-- code -->
                            <td v-if="row.rowspan!=-1" class="b-top text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-500 col-no cursor-not-allowed" :rowspan="row.rowspan">
                                {{ row.purchaseCode }}.
                            </td>

                            <!-- 発注先 -->
                            <td v-if="row.rowspan!=-1" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300" :rowspan="row.rowspan">
                                {{ row.supplier }}
                            </td>

                            <!-- 発注書記載社名 -->
                            <td v-if="row.rowspan!=-1" class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300" :rowspan="row.rowspan">
                                {{ row.purchaseOrderName }}
                            </td>

                            <!-- メアド -->
                            <td class="px-0.5 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                                {{ row.mail }}
                            </td>

                            <!-- 担当者 -->
                            <td class="px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300">
                                {{ row.pic }}
                            </td>
                            
                            <td v-if="row.rowspan!=-1" class="px-0.5 py-0.5 text-center text-sm font-medium border-gray" :rowspan="row.rowspan">
                                <div class="flex justify-center">
                                    <PrimaryButton text="編集" size="normal" @click="edit(row)" />
                                </div>
                            </td>
                        </tr>
                </table>

            </div>
            <div v-if="!list || !list.length" class="main w-full h-full flex justify-center items-center">
                <p class="text-gray-400 font-bold">対象データがありません</p>
            </div>
    
            <!-- 発注マスタ編集 -->
            <EstimatePurchaseEditModal 
                v-model="purchaseEditModalShow"
                :purchaseEditModalShow="purchaseEditModalShow"
                :target="editData"
                :originalList="originalList"
                @closePurchaseEditModal="closePurchaseEditModal"
            />
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import EstimatePurchaseEditModal from '../components/EstimatePurchaseEditModal.vue'
import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'
import { PlusCircleIcon } from '@vue-hero-icons/solid'
export default {
    components: {
        Modal,
        PrimaryButton,
        EstimatePurchaseEditModal,
        PlusCircleIcon
    },

    props: ['purchaseModalShow'],

    data() {
        return {
            // カラム
            columns: [
                {title: 'コード'},
                {title: '発注先'},
                {title: '発注書記載社名'},
                {title: 'メールアドレス'},
                {title: '担当者'},
                {title: ''},
            ],
            // 登録するデータ
            saveList: [],
            // 発注リスト
            originalList: [],
            list: [],
            // 発注先絞込み
            filterSupplier: [],
            // 編集モーダル
            purchaseEditModalShow: false,
            // 編集対象データ
            editData: []
        }
    },

    computed: {
    },

    watch: {
        filterSupplier() {
            this.filtering()
        },

        purchaseModalShow() {
            if (this.purchaseModalShow) {
                this.getSupplierList()
            }
        }
    },

    methods: {
        /**
         * 発注先リスト取得
         */
        async getSupplierList() {
            const result = await estimateManager.getPurchase(this)
            if (result.length) {
                this.originalList = result
            } else {
                this.originalList =  [{
                    purchaseNo: null,
                    supplier: ''
                }]
            }
            this.filtering()
        },

        /**
         * 検索
         */
        filtering() {
            this.list = []
            let res = []

            if (this.filterSupplier != '') {
                this.filterSupplier = this.filterSupplier.trim()
                for (let i = 0; i < this.originalList.length; i++) {
                    const o = this.originalList[i]
                    if (utils.hankaku(o.supplier).toLowerCase().indexOf(utils.hankaku(this.filterSupplier).toLowerCase()) != -1) {
                        res.push(o)
                    }
                }
            } else {
                res = utils.clone(this.originalList)
            }

            // 画面表示の形に生成
            for (let i = 0; i < res.length; i++) {
                const r = res[i]
                for (let j = 0; j < r.details.length; j++) {
                    const d = r.details[j]
                    
                    let rowspan = -1
                    if (j == 0) {
                        rowspan = r.details.length
                    }

                    this.list.push({
                        _id: r._id,
                        purchaseCode: r.purchaseCode,
                        supplier: r.supplier,
                        purchaseOrderName: r.purchaseOrderName,
                        mail: d.mail,
                        pic: d.pic,
                        rowspan
                    })
                }
            }
        },

        /**
         * 編集ボタンイベント
         * @param row 編集対象データ
         */
        edit(row) {
            this.editData = null
            // 編集
            if (row) {
                this.editData = []

                this.editData = this.list.filter((l) => {
                    return row.purchaseCode == l.purchaseCode
                })
            }

            this.purchaseEditModalShow = true
        },

        /**
         * 編集モーダルclode
         */
        closePurchaseEditModal() {
            this.purchaseEditModalShow = false
            this.getSupplierList()
        },

        closed() {
            // 登録するデータ
            this.saveList = [],
            // 発注リスト
            this.originalList = [],
            this.list = [],
            // 発注先絞込み
            this.filterSupplier = []
            this.$emit('closePurchaseModal')
        }
    }
}
</script>
<style scoped>
.main-height {
    height: 85vh;
}

.h-95vh {
    height: 95vh;
}

.h-93vh {
    height: 93vh;
}

.main {
    min-width: 700px;
}
.col-no {
    width: 100px;  
}

.min-300 {
    min-width: 300px;
}

.min-70 {
    min-width: 70px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: #ebfbec;
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>