<template>
  <div class="h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full">
        <div
          v-if="selectable"
          class="px-4 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-b border-gray-200 text-center w-16"
        >
          <input 
            v-if="multiSelect && allowAllSelections"
            type="checkbox" 
            class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 rounded"
            v-model="allSelectChecked"
            @change="allSelectChange($event.target.checked)"
          >
          <span v-else>
            選択
          </span>
        </div> 
        <template v-for="(column, columnIndex) in columns">
          <div
            v-if="columnSortDisable(column) && isSort"
            ref="columns"
            :key="columnIndex"
            class="px-4 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-b border-gray-200"
            style="display: flex;"
            :class="[`text-${columnAlignment[columnIndex] || 'left'}`, columnWidth[columnIndex] === 'auto' ? 'flex-1' : '']"
            :style="columnWidth[columnIndex] !== 'auto' ? `width: ${columnWidth[columnIndex]}` : ''"
            @click="sortSwitching(column)"
          >
            {{ columnTitle(column) }}
            <Icon v-if="columnSort(column)" :iconName="sortIcon" class="w-3 h-3 mr-0.5"/>
          </div>

           <div
            v-else
            ref="columns"
            :key="columnIndex"
            class="px-4 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-b border-gray-200"
            style="display: flex;"
            :class="[`text-${columnAlignment[columnIndex] || 'left'}`, columnWidth[columnIndex] === 'auto' ? 'flex-1' : '']"
            :style="columnWidth[columnIndex] !== 'auto' ? `width: ${columnWidth[columnIndex]}` : ''"
          >
            {{ columnTitle(column) }}
            <Icon class="w-3 h-3 mr-0.5"/>
          </div>
        </template>
      </div>
      <div class="flex-grow overflow-hidden">

        <RecycleScroller
          class="h-full"
          :items="data"
          :item-size="rowHeight"
          key-field="_id"
          v-slot="{ item, index }"
        >

        <div
          class="flex w-full" 
          :class="rowClasses(item, index)"
          :style="{ height: rowHeight + 'px' }"
          v-tooltip="tooltip[index]"
          @dblclick="dblClickRow(item)"
        >
          <template v-if="selectable">
          <div
            class="px-4 py-2 whitespace-nowrap text-sm text-gray-600 flex items-center w-16 justify-center"
          >
            <input
              v-if="!selectableFunc || selectableFunc(item)"
              :id="item._id" 
              name="item" 
              :type="multiSelect ? 'checkbox' : 'radio'" 
              :value="item._id"
              v-model="selectedRowId"
              class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" 
              :class="multiSelect ? 'rounded' : ''"
              @change="rowSelectChange(item, $event)"
            >
          </div>
          </template>
          <label
            v-for="(column, columnIndex) in columns" 
            :key="`${index}-${columnIndex}`" 
            class="block px-4 py-2 whitespace-nowrap text-sm text-gray-600 flex items-center"
            :class="classes(column, columnIndex)"
            :style="columnWidth[columnIndex] !== 'auto' ? `width: ${columnWidth[columnIndex]}` : `width: ${calcColumnWidth(columnIndex)}`"
            :for="item._id"
          >
            <!-- <label :for="item._id" class="cursor-pointer flex"> -->
              <div class="flex flex-col gap-1 w-full">
                <template v-for="c in column">
                  <div :key="c.name">
                    <component
                      :key="c.name"
                      :is="resolveComponent(c)" 
                      :value="item[c.name] || c.fixedName" 
                      :record="item" 
                      :name='c.name'
                      title=""
                      v-bind="c"
                      @toParent="toParent"
                      class="truncate"
                      :class="c.class"
                    />
                  </div>
                </template>
              </div>
            <!-- </label> -->
          </label>
        </div>
        </RecycleScroller>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import { RecycleScroller } from "vue-virtual-scroller"
import TextRenderer from './TextRenderer.vue'
import BadgeRenderer from './BadgeRenderer.vue'
import IconRenderer from './IconRenderer.vue'
import HyperLinkRenderer from './HyperLinkRenderer.vue'
import * as utils from '@libs/utils'
import EstimateFlatButton from '../../apps/Estimation/components/EstimateFlatButton.vue'
import ActionTextRenderer from './ActionTextRenderer.vue'
import Icon from '@components/Icon.vue'

export default {
  components: {
    RecycleScroller,
    TextRenderer,
    BadgeRenderer,
    IconRenderer,
    HyperLinkRenderer,
    EstimateFlatButton,
    ActionTextRenderer,
    Icon
  },

  props: [
    'columns', 
    'columnWidth', 
    'columnAlignment', 
    'data', 
    'rowClassesFunc', 
    'rowHeight', 
    'selectable', 
    'multiSelect', 
    'allowAllSelections', 
    'selectableFunc',
    'keepSelection',
    'defaultSelectedRowId',
    'record',
    'componentResolver',
    'tip',
    'isSort',
    'initSortItem',
    'initSortCount'
  ],

  data() {
    if (this.multiSelect) {
      return {
        selectedRowId: [],
        selectedRow: [],
        allSelectChecked: false,
        tooltip: []
      }
    } else {
      return {
        selectedRowId: '',
        selectedRow: null,
        allSelectChecked: false,
        tooltip: [],
        sortIcon: '',
        sortCount: 0,
        sortItem: ''
      }
    }
  },

  mounted() {
    // 初期ソート設定
    this.setInitSort()
  },

  watch: {
    data() {
      if (this.multiSelect) {
        if (this.tip) {
          this.tooltip = this.tip
        }
        if (!this.keepSelection) {
          this.reset()
        }
        this.allSelectChecked = utils.every(this.data, (rec) => { 
          return this.selectedRowId.includes(rec._id) 
        })
      } else {
        if (this.tip) {
          this.tooltip = this.tip
        }
        this.reset()
      }
    },

    defaultSelectedRowId() {
      if (this.multiSelect) {
        this.selectedRowId = this.defaultSelectedRowId || []
        this.selectedRow = this.data.filter(rec => {
          return this.selectedRowId.includes(rec._id)
        })
      } else {
        this.selectedRowId = this.defaultSelectedRowId || ''
        this.selectedRow = this.data.find(rec => {
          return this.selectedRowId.includes(rec._id)
        })
      }
      this.emitSelectionChange()
    }
  },

  computed: {
    columnTitle() {
      return (column) => {
        return utils.compact(column.map((c) => {
          return c.title
        })).join(' / ')
      }
    },

    columnSortDisable() {
      return (column) => {
        if (column[0].columnSortDisable == true) {
          return true
        }
      }
    },

    columnSort() {
      return (column) => {
        return (column[0].name == this.sortItem)
      }
    },

    classes() {
      return (column, index) => {
        const classes = []
        if (index === 0) {
          classes.push('font-medium')
        }
        classes.push(`text-${this.columnAlignment[index] || 'left'}`)
        if (this.selectable) {
          classes.push('cursor-pointer')
        } else {
          classes.push('cursor-auto')
        }
        return classes
      }
    },

    rowClasses() {
      const self = this
      return (row, index) => {
        const classes = []
        if (self.selectedRowId.indexOf(row._id) >= 0) {
          classes.push('bg-indigo-50')
        } else {
          classes.push(index % 2 === 0 ? 'bg-white' : 'bg-gray-50')
        }
        if (self.rowClassesFunc) {
          const additionalClasses = self.rowClassesFunc(row, index)
          if (additionalClasses) {
            utils.addToArray(classes, additionalClasses)
          }
        }
        return classes
      }
    },

    calcColumnWidth() {
      return (index) => {
        const w = this.$refs.columns[index].clientWidth + 'px'
        return w
      }
    },
  },

  methods: {
    reset() {
      if (this.multiSelect) {
        this.selectedRowId = []
        this.selectedRow = []
      } else {
        this.selectedRowId = ''
        this.selectedRow = null
      }
    },

    resolveComponent(column) {
      switch (column.type) {
        case 'badge':
          return BadgeRenderer
        case 'link':
          return HyperLinkRenderer
        case 'button':
          return EstimateFlatButton
        case 'icon':
          return IconRenderer
        case 'actionText':
          return ActionTextRenderer
        default:
          if (this.componentResolver) {
            return this.componentResolver(column) || TextRenderer
          } else {
            return TextRenderer
          }
      }
    },

    rowSelectChange(selectedRow, $event) {
      console.log('rowSelectChange')
      if (this.multiSelect) {
        if ($event.target.checked) {
          utils.addToArray(this.selectedRow, selectedRow)
        } else {
          utils.removeFromArray(this.selectedRow, (r) => {
            return r._id === selectedRow._id
          })
        }
      } else {
        this.selectedRow = selectedRow
      }      
      this.emitSelectionChange()
    },

    allSelectChange(selected) {
      
      if (!this.keepSelection) {
        this.selectedRowId.splice(0)
        this.selectedRow.splice(0)
      }
      if (selected) {
        for (const rec of this.data) {
          if (!this.selectableFunc || this.selectableFunc(rec)) {
            if (!utils.exists(this.selectedRowId, rec._id)) {
              this.selectedRowId.push(rec._id)
            }
            if (!utils.exists(this.selectedRow, (r) => {
              return r._id === rec._id
            })) {
              this.selectedRow.push(rec)
            }
          }
        }
      } else {
        const selectedRowIdList = this.selectedRowId.map((_id) => { return _id})
        for (const _id of selectedRowIdList) {
          utils.removeFromArray(this.selectedRowId, _id)
          utils.removeFromArray(this.selectedRow, (r) => {
            return r._id === _id
          })
        }
      }
      this.emitSelectionChange()
    },

    emitSelectionChange() {
      this.$emit('selectionChange', this.selectedRow)
    },

    /**
     * 親コンポーネントへの通知 イベント
     */
    toParent(msg, param) {
      this.$emit("toParent", msg, param);
    },

    /**
     * 行 ダブルクリック イベント
     * @param {*} item
     */
    dblClickRow(item) {
      this.$emit('dblClickRow', item);
    },

    /*
     * ソート 切り替え
     */
    sortSwitching(column) {
      this.sortItem = column[0].name
      this.sortCount += 1
      var sort = -1
      if (this.sortCount % 2 == 0) {
        // 降順の場合
        this.sortIcon = 'ArrowNarrowDown'
      } else {
        // 昇順の場合
        this.sortIcon = 'ArrowNarrowUp'
        sort = 1
      }
      // 親コンポーネントへの通知
      this.$emit('changeSort', column, sort)
    },

    /*
     * 初期ソート設定
     */
     setInitSort() {
      if (this.initSortItem && this.initSortCount >= 0) {
        this.sortItem = this.initSortItem
        this.sortCount = this.initSortCount
        if (this.sortCount % 2 == 0) {
          // 降順の場合
          this.sortIcon = 'ArrowNarrowDown'
        } else {
          // 昇順の場合
          this.sortIcon = 'ArrowNarrowUp'
        }
      }
    }
  }
}
</script>