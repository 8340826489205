import axiosBase from 'axios'
import { PIGEON_CONFIG } from '@/config'

const CHATWORK_API_PROXY_ENDPOINT = `${PIGEON_CONFIG.apiProxyServiceEndpoint}/chatwork-api/v2`

export async function sendChatWorkMssage(roomId, message, token) {
  const endpoint = `${CHATWORK_API_PROXY_ENDPOINT}/rooms/${roomId}/messages`

  const axios = axiosBase.create()
  const res = await axios({
    method: 'post',
    url: endpoint,
    headers: {'X-ChatWorkToken': token},
    data: `body=${encodeURIComponent(message)}`
  })
  return res
}