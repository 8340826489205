<template>
  <div>
    <PageHeader title="apollo-RP - Estimation">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end">
          <a class="text-white hover:text-gray-400 ml-auto flex items-center cursor-pointer" @click.prevent="estimateList">
            <Icon iconName="ViewList" class="w-4 h-4 mr-1" />
            見積リスト
          </a>
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-end">
          <div class="text-base font-bold text-white">
            <span>
              <span class="text-gray-400 text-sm">見積 # : </span>
              {{ estimate.estimateNo || '新規見積' }}
            </span>
          </div>
          <div class="text-base font-bold ml-6 text-white w-52">
            <span>
              <span class="text-gray-400 text-sm">見積金額 : </span>
              {{ estimate ? formatValue(estimate.totalAmount, 0, '¥', '') : '' }}
            </span>
          </div>
          <div class="ml-auto flex items-center gap-1">
            <div class="text-white text-xs mr-1" v-if="isParentComplete">メインが完了しているため再度保存はできません</div>
            <div class="text-white text-xs mr-1" v-else-if="estimate.estimateStatus=='受注'">受注しているため再度保存はできません</div>
            <div class="text-white text-xs mr-1" v-else-if="estimate.estimateStatus=='却下'">却下しているため再度保存はできません</div>

            <!-- テスト見積りチェックボックス -->
            <div v-if="!isParentComplete && !['受注', '却下'].includes(estimate.estimateStatus) && estimateAuth">
              <input
                type="checkbox"
                id="test"
                v-model="estimate.test"
              >
              <label for="test" class="text-white text-sm ml-1 mr-5"> テスト </label>
            </div>

            <!-- 承認申請チェックボックス -->
            <div v-if="!isParentComplete && !['受注', '却下'].includes(estimate.estimateStatus) && estimateAuth">
              <input
                type="checkbox"
                id="petition"
                v-model="estimate.approvalPetition"
              >
              <label for="petition" class="text-white text-sm ml-1 mr-5"> 承認申請 </label>
            </div>
            <!-- 作成完了（未申請かつ未完了の場合のみ） -->
            <PrimaryButton text="作成完了" class="w-32 mr-2" @click="completedEstimate(true)" v-if="!isParentComplete && !['受注', '却下'].includes(estimate.estimateStatus) && estimateAuth">
              <LoadingIcon v-if="isLoadingCompleted" slot="before" class="h-4 w-4" />
              <Icon v-if="!isLoadingCompleted" iconName="Archive" slot="before" class="h-4 w-4" />
            </PrimaryButton>

            <!-- 一時保存（未申請かつ未完了の場合のみ） -->
            <PrimaryButton text="一時保存" class="w-32 mr-2" @click="saveEstimate(false, '作成中')" v-if="!isParentComplete && !['受注', '却下'].includes(estimate.estimateStatus) && estimateAuth">
              <LoadingIcon v-if="isSaving" slot="before" class="h-4 w-4" />
              <Icon v-if="!isSaving" iconName="Database" slot="before" class="h-4 w-4" />
            </PrimaryButton>

            <!-- 印刷 -->
            <PrimaryButton text="印刷" class="mr-2 w-28 bg-green-600 hover:bg-green-700" @click="print()">
              <Icon iconName="Printer" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2 list-con">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-gray-100 rounded-md border border-gray-300 overflow-auto h-full">

          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">データ取得中...</p>
          </div>

          <div class="p-4">

            <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-14">
              <!-- 差戻理由 -->
              <div v-if="estimate.estimateStatus=='差戻'" class="sm:col-span-full">
                <WmsTextInput 
                  name="reasonForSendingBack" 
                  caption="差戻理由" 
                  :disabled="true"
                  :multiline="true"
                  :rows="rowCountSendingBack"
                  v-model="estimate.reasonForSendingBack"
                />
              </div>

              <!-- <div v-if="estimate.estimateStatus=='差戻'" class="sm:col-span-7"></div> -->

              <!-- 見積りステータス -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="estimateStatus" 
                  caption="ステータス" 
                  :disabled="true"
                  v-model="estimate.estimateStatus"
                />
              </div>

              <!-- 見積No -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="estimateNo" 
                  caption="見積No" 
                  :disabled="true"
                  v-model="estimate.estimateNo"
                />
              </div>

              <!-- 作成日 -->
              <div class="sm:col-span-2">
                <WmsDateInput
                  name="estimateDate"
                  caption="作成日" 
                  :readonly="isModileDevice"
                  :clearable="isModileDevice"
                  v-model="estimate.estimateDate"
                />
              </div>

              <!-- リクエストポスト依頼 -->
              <div class="sm:col-span-6">
                <WmsTextInput
                  name="requestPostName"
                  caption="サブタスク選択" 
                  v-model="estimate.requestPostName"
                  :required="true"
                  :error="!validateRequestPost.result"
                  :errorMessage="validateRequestPost.message"
                  @change="requestPostNameChanged"
                >
                  <template #addon-content>
                    <Icon
                      iconName="Search" 
                      :clickable="true" 
                      class="w-4 h-4 mr-0.5"
                      @click="selectRequestPost()"
                    />
                  </template>
                </WmsTextInput>
              </div>

              <!-- 社内担当者 -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="taskStaff" 
                  caption="サブタスク担当者" 
                  :disabled="true"
                  v-model="estimate.taskStaff"
                />
              </div>

              <!-- 得意先 -->
              <div class="sm:col-span-6">
                <label 
                  for="clientName" 
                  class="block text-xs font-bold text-gray-700 mb-1"
                >
                  得意先名
                  <span class="-top-0.5 ml-1 text-red-500"> * </span>
                </label>
                <div class="mt-1 flex relative">
                  <InputIcon v-if="approvalEmpty(estimate.clientName)" iconName="ExclamationCircle" iconColor='red' message="完了する場合、必須です。" />
                  <input 
                    name="clientName" 
                    autocomplete="off"
                    class="py-1.5 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none block w-full sm:text-sm border-gray-300 client-name rounded"
                    :class="approvalEmpty(estimate.clientName)?'error WmsTextInputDisplay':'px-3'"
                    v-model="estimate.clientName"
                    @change="changeClientName()"
                  />
                </div>
              </div>

              <!-- 敬称 -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="clientHonorific" 
                  caption="敬称" 
                  v-model="estimate.clientHonorific"
                />
              </div>

              <div class="sm:col-span-3">
                <WmsTextInput 
                  name="clientPhoneNumber" 
                  caption="得意先 TEL" 
                  v-model="estimate.clientPhoneNumber"
                />
              </div>

              <div class="sm:col-span-3">
                <WmsTextInput 
                  name="clientFax" 
                  caption="得意先 FAX" 
                  v-model="estimate.clientFax"
                />
              </div>

              <!-- 得意先郵便番号 -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="clientPostalCode" 
                  caption="得意先 郵便番号"
                  v-model="estimate.clientPostalCode"
                />
              </div>

              <!-- 得意先住所1 -->
              <div class="sm:col-span-5">
                <WmsTextInput 
                  name="clientAddress1" 
                  caption="得意先 住所1"
                  v-model="estimate.clientAddress1"
                />
              </div>

              <!-- 得意先住所2 -->
              <div class="sm:col-span-5">
                <WmsTextInput 
                  name="clientAddress2" 
                  caption="得意先 住所2"
                  v-model="estimate.clientAddress2"
                />
              </div>

              <!-- 得意先担当者 -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="clientPersonName" 
                  caption="得意先 ご担当者様"
                  suffix="様"
                  :disabled="true"
                  v-model="estimate.clientPersonName"
                />
              </div>

              <!-- 建物名 -->
              <div class="sm:col-span-5">
                <WmsTextInput 
                  name="clientOnbuildingName" 
                  caption="建物名"
                  :required="true" 
                  :error="approvalEmpty(estimate.buildingName)"
                  errorMessage="完了する場合、必須です。"
                  v-model="estimate.buildingName"
                />
              </div>

              <!-- 建物郵便番号 -->
              <div class="sm:col-span-2">
                <WmsTextInput 
                  name="buildingPostalCode" 
                  caption="建物 郵便番号"
                  v-model="estimate.buildingPostalCode"
                />
              </div>

              <!-- 物件所在地 -->
              <div class="sm:col-span-7">
                <WmsTextInput 
                  name="buildingAddress2" 
                  caption="建物 住所"
                  :required="true" 
                  :error="approvalEmpty(estimate.buildingAddress2)"
                  errorMessage="完了する場合、必須です。"
                  v-model="estimate.buildingAddress2"
                />
              </div>

              <!-- 区分 -->
              <div class="sm:col-span-3">
                <div class="font-bold text-xs mb-1">区分（集計で使用）
                  <span v-if="estimate.buildingAddress2&&estimate.buildingAddress2!=''" class="text-red-500">*</span>
                  <span v-if="estimate.buildingAddress2==''" class="text-gray-500">物件住所がないので集計されません</span>
                </div>
                <div class="flex items-center py-1 rounded" :class="estimate.classification||estimate.buildingAddress2==''?'':'bg-light-blue-800'">
                  <div v-for="tab in type" 
                    :key="tab.id" 
                    class="w-30p mx-1 cursor-pointer"
                    :class="[tab.active ? 'bg-light-blue-800' : 'hover:bg-gray-400', 'bg-gray-300 rounded-md py-1 px-3 text-sm font-medium text-white']" 
                    @click="typeChanged(tab)"
                  >
                    {{ tab.name }}
                  </div>
                </div>
              </div>

              <div class="sm:col-span-3">
                <WmsTextInput
                  name="requirement" 
                  caption="要件（帳票に表示）" 
                  v-model="estimate.requirement"
                />
              </div>

              <div class="sm:col-span-6">
                <WmsTextInput
                  name="subject" 
                  caption="お問い合わせ内容" 
                  v-model="estimate.subject"
                />
              </div>

              <div class="sm:col-span-2">
                <WmsDateInput 
                  name="expireDate" 
                  caption="有効期限"
                  :readonly="isModileDevice"
                  :clearable="isModileDevice"
                  v-model="estimate.expireDate"
                />
              </div>

              <div class="sm:col-span-2"></div>

              
              <!-- メイン・サブタスクと相違 -->
              <div v-if="diffDetails && diffDetails.length" class="sm:col-span-full pt-1">
                <div class="font-bold text-xs text-red-700">メイン・サブタスクと詳細モーダル選択肢の相違点</div>
                <div class="flex flex-wrap">
                  <div v-for="(row, i) in diffDetails" :key="i">
                    <div class="text-gray-600 mb-0.5 mr-1 text-sm rounded border border-blue-700 w-fit mt-1.5 h-99p">
                      <div class="text-center bg-blue-700 text-white px-3">{{ row.categoryName }}</div>
                      <div v-if="row.details && row.details.length" class="table my-1 mx-3">
                        <div v-for="(de, j) in row.details" :key="'de' + j" class="table-row">
                          <div class="table-cell">
                            {{ de.name }}
                          </div>
                          <div class="table-cell">
                            ：{{ de.value }}
                          </div>
                          <div v-if="de.main" class="text-red-700">
                            （メイン➡ {{ de.main }}）
                          </div>
                          <div v-if="de.sub" class="text-red-700">
                            （サブ➡ {{ de.sub }}）
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-full pt-4">
                <div class="flex items-end">
                  <label class="text-xs font-bold text-gray-700 flex items-center">
                    見積明細
                  </label>
                  <div class="ml-5 flex items-center">
                    <span class="text-gray-500 font-bold text-xs">並べ替え</span>
                    <Toggle v-model="detailSorting" size="small" class="ml-1 -mt-0.5"/>

                    <div class="text-xs font-bold text-blue-700 ml-5">
                      ※カテゴリー名 「＠＠」で改行
                    </div>
                  </div>

                  <div v-if="lightingTab" class="text-red-600 ml-auto">照明器具清掃の金額をご確認ください（式に使用している戸数・タイプを要変更）</div>

                  <div class="text-xs text-gray-500 ml-auto"><span class="bg-yellow-200">&emsp;&emsp;</span>：単価を手動で変更した箇所です。</div>

                  <div class="ml-auto">
                    <!-- 単価1・金額1の列を並び替え -->
                    <button 
                      class="w-auto py-0 text-sm colum-drag mr-4 text-white"
                      @click="columnDrag(1)"
                    >
                      数量1・単価1 並べ替え
                    </button>
                    <!-- 単価1・金額1の列を並び替え -->
                    <button 
                      class="w-auto py-0 text-sm colum-drag mr-3 text-white"
                      @click="columnDrag(2)"
                    >
                      数量2・単価2 並べ替え
                    </button>

                    <button 
                      class="w-auto py-0 text-sm text-indigo-800 hover:text-indigo-500"
                      @click="quoteOtherEstimateDetail"
                    >
                      他の見積りから明細を引用
                    </button>
                  </div>
                </div>
                <div class="mt-1">
                  <EstimateDetailInput 
                    ref="estimateDetailInput"
                    :estimate="estimate"
                    :value="estimate.details" 
                    :sortable="detailSorting"
                    @estimateCalculate="calculate"
                    @setNoteText="setNoteText"
                    @setNoteList="setNoteAndPurchase"
                    @deleteNoteList="deleteNoteAndPurchase"
                    @insertPurchase="insertPurchase"
                    @insertCommission="insertCommission"
                    @getDiffDetails="getDiffDetails"
                  />
                </div>
              </div>

              <div class="sm:col-span-full pt-4">
                <div class="flex items-end">
                  <label class="text-xs font-bold text-gray-700 flex items-center">
                    発注明細
                    <span class="text-xs text-gray-500 ml-5 font-normal"> 発注明細は詳細モーダルで編集してください</span>
                  </label>
                    <button class="w-auto py-0 text-sm ml-auto mr-3 text-indigo-800 hover:text-indigo-500" @click="openPurchaseModal()">発注先マスタ編集</button>
                </div>
                <div class="mt-1">
                  <EstimatePurchase
                    :purchase="purchase"
                    :purchaseModalShow="purchaseModalShow"
                    :purchaseReload="purchaseReload"
                    :allDisabled="true"
                  >
                  </EstimatePurchase>
                </div>
              </div>

              <div class="sm:col-span-full pt-4">
                <div class="flex items-end">
                  <label class="text-xs font-bold text-gray-700 flex items-center">
                    手数料明細
                    <span class="text-xs text-gray-500 ml-5 font-normal"> 手数料明細は詳細モーダルで編集してください</span>
                  </label>
                </div>
                <div class="mt-1">
                  <EstimateCommission
                    :commission="commission"
                    :commissionReload="commissionReload"
                    :allDisabled="true"
                  >
                  </EstimateCommission>
                </div>
              </div>
  
              <div class="sm:col-span-full pt-4">
                <div class="text-xs text-gray-500 ml-auto">個々の特記事項は詳細モーダルで編集してください</div>
                <div class="mt-1">
                  <EstimateNote
                    :notes="estimate.noteList"
                    bgColor="bg-gray-100"
                  >
                  </EstimateNote>
                </div>
              </div>

              <div class="sm:col-span-full pt-4" v-tooltip="!commonNoteEdit?'編集の際は「編集」ボタンを押してください。':''">
                <div class="flex">
                  <div class="text-purple-600 text-xs font-bold mt-auto mr-3 mb-1">
                    共通の特記事項 (出力に反映します。どのカテゴリーにも共通する特記事項としてお使いください。)
                  </div>
                  <div>
                    <PrimaryButton
                      text="編集"
                      @click="commonNoteEdit=!commonNoteEdit"
                      class="h-7 bg-purple-50 text-purple-900 border-purple-900 mb-1"
                    >
                  </PrimaryButton>
                  </div>
                </div>
                <div v-tooltip="!commonNoteEdit?'':'見積書に表示されます。'">
                  <textarea 
                    name="commonNote" 
                    :rows="5"
                    class="text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                    :class="!commonNoteEdit?'bg-gray-200 cursor-not-allowed':''"
                    :disabled="!commonNoteEdit"
                    v-model="estimate.commonNote"
                    @change="estimate.changeCommonNote=true"
                  />
                </div>
              </div>

              <div class="sm:col-span-full pt-4">
                <label class="block text-xs font-bold text-gray-700">
                  添付ファイル
                </label>
                <div class="mt-1">
                  <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-4">
                    <EstimateAttachment ref="estimateAttachment1" name="attachment1" :value="estimate.attachment1" @change="attachmentChange('attachment1', $event)" />
                    <EstimateAttachment ref="estimateAttachment2" name="attachment2" :value="estimate.attachment2" @change="attachmentChange('attachment2', $event)" />
                    <EstimateAttachment ref="estimateAttachment3" name="attachment3" :value="estimate.attachment3" @change="attachmentChange('attachment3', $event)" />
                  </div>
                </div>
              </div>

              <div class="sm:col-span-full pt-4">
                <WmsTextInput 
                  name="memo" 
                  caption="メモ・承認者へのメッセージ（出力には反映されません。情報共有などの内部的なメモ書きとしてお使いください。）"
                  captionColor="text-yellow-600"
                  :multiline="true"
                  :rows="5"
                  v-model="estimate.memo"
                />
              </div>

              <!-- <div class="sm:col-span-full pt-4">
                <WmsTextInput 
                  name="coverPageAdditionalText" 
                  caption="送付状追記文（送付状の本文に追記したい文がある場合、ここに記載ください）"
                  captionColor="text-yellow-600"
                  :multiline="true"
                  :rows="5"
                  v-model="estimate.coverPageAdditionalText"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 他の見積りから明細を引用 -->
    <EstimateDetailSelectorModal 
      v-model="detailSelectorShow" 
      :excludeEstimateNo="estimate.estimateNo"
      @apply="applyOtherEstimateDetails"
    />

    <!-- リクエストポスト依頼 -->
    <EstimateSelectorTaskShow
      v-model="dataSelectorShow"
      :title="dataSelectorParams.title"
      :subtitle="dataSelectorParams.subtitle"
      :rows="taskList"
      :selectCallback="dataSelectorParams.selectCallback"
    />

    <!-- 承認申請モーダル -->
    <EstimateApprovalModal 
      v-model="approvalSelectorShow" 
      :open="approvalSelectorShow"
      :priceChange="estimate.priceChange"
      :estimate="estimate"
      :routeName="estimate.approvalInfo.route"
      @notApproval="completedEstimate"
      @doApproval="approvalApplication"
    />

    <!-- 他の未着手複写モーダル -->
    <!-- <DataSelectorModal
      v-model="copySelecterShow"
      :title="copyParam.title"
      :subtitle="copyParam.subtitle"
      :options="copyParam.options"
      :deleteBtn="true"
      :selectCallback="copyParam.selectCallback"
    /> -->
    
    <!-- 発注マスタ編集 -->
    <EstimatePurchaseModal 
      v-model="purchaseModalShow"
      :purchaseModalShow="purchaseModalShow"
      @closePurchaseModal="closePurchaseModal"
    />

  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js" integrity="sha512-WFN04846sdKMIP5LKNphMaWzU7YpMyCU245etK3g/2ARYbPK9Ub18eG+ljU96qKRCWh+quCY7yefSmlkQw1ANQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script>
import Icon from '@components/Icon.vue'
import InputIcon from '@components/InputIcon.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Toggle from '@components/Toggle.vue'
import DataSelectorModal from '@components/DataSelectorModal.vue'

import PageHeader from '@components/PageHeader.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import EstimateDetailInput from '../components/EstimateDetailInput.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import EstimateNote from '../components/EstimateNote.vue'
import EstimatePurchase from '../components/EstimatePurchase.vue'
import EstimateAttachment from '../components/EstimateAttachment.vue'
import EstimateDetailSelectorModal from '../components/EstimateDetailSelectorModal.vue'
import EstimateApprovalModal from '../components/EstimateApprovalModal.vue'
import EstimateSelectorTaskShow from '../components/EstimateSelectorTaskShow.vue'
import EstimatePurchaseModal from '../components/EstimatePurchaseModal.vue'
import EstimateCommission from '../components/EstimateCommission.vue'
import * as constants from '@libs/constantsEstimate'
import * as estimateManager from '@managers/estimateManager'
import * as requestPostManager from '@managers/requestPostManager'
import * as taskRequestManager from '@managers/taskRequestManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as chatwork from '@libs/chatwork'
import * as logManager from '@managers/logManager'
import * as eriaManager from '@managers/eriaManager'
import * as backend from '@libs/backend'
import BigNumber from "bignumber.js"

import { TAX_RATE } from '@libs/constants'

import LoadingIcon from '@assets/loading-circle.svg' 

import { ESTIMATE_FEATURES } from '@/config'

import _ from 'lodash'
import * as moment from 'moment'

const COMMON_NOTE_NEW = '作業開始は業務内容により異なりますが、ご発注より1.5～2ヶ月を要しますのでご了承下さい。'

export default {
  components: {
    InputIcon,
    PrimaryButton,
    EstimateDetailInput,
    WmsTextInput,
    WmsDateInput,
    EstimateNote,
    EstimatePurchase,
    Toggle,
    EstimateAttachment,
    PageHeader,
    EstimateDetailSelectorModal,
    EstimateSelectorTaskShow,
    EstimatePurchaseModal,
    EstimateCommission,
    Icon,
    LoadingIcon,
    EstimateApprovalModal,
    DataSelectorModal
  },

  props: {
    copyEstimateNo: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      estimate: {},
      detailSorting: false,
      detailSelectorShow: false,
      dataSelectorShow: false,
      approvalSelectorShow: false,
      // copySelecterShow: false,
      purchaseModalShow: false,
      dataSelectorParams: {
        title: '',
        subtitle: '',
      },
      copyParam: {},
      isSaving: false,
      // 作成完了 false:していない
      // isCompleted: false,
      // メインタスク完了しているか false:していない
      isParentComplete: false,
      // メインタスクの情報
      mainData: {},
      // メインタスクとサブタスクの情報
      taskData: null,
      // 保存中アイコン表示
      isLoadingCompleted: false,
      // リクエストポスト依頼リスト
      taskList: [],
      // ローディング中
      loading: false,
      // 複写の場合、照明器具清掃の「箇所数不明」の確認が必要か false：不要
      lightingTab: false,
      // 発注明細（過去の見積りにはないため、ここで宣言）
      purchase: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        supplier: '',
        rate: '',
        price: '',
        priceBgColor: false,
        rateBgColor: false
      }],
      // 発注明細表示のための変数
      purchaseReload: 0,
      // 手数料明細（過去の見積りにはないため、ここで宣言）
      commission: [{
        categoryCode: '',
        categoryName: '',
        groupName: '',
        estimatePrice: '',
        rate: '',
        commissionPrice: '',
        price: ''
      }],
      // 手数料明細表示のための変数
      commissionReload: 0,
      // 区分
      type: utils.clone(constants.type),
      // 画面を開いた時点の見積データ
      originalEstimate: {},
      // メインの情報と見積りの金額取得の材料情報が違う
      diffDetails: [],
      // 共通の特記事項編集可能フラグ
      commonNoteEdit: false

    }
  },

  async created() {
    this.initialize()
  },

  watch: {

    'estimate.discount': {
      handler: function() {
        this.calculate(null, null)
      }
    },
  },

  computed: {
    isModileDevice() {
      return utils.deviceInfo.isMobile
    },

    appVersion() {
      return this.$store.getters.appVersion
    },

    /**
     * 見積り金額表示
     */
    formatValue() {
      return (val, decimalPlace = 0, prefix = '', suffix = '') => {
        if (val === '') {
          return val
        } else {
          return `${prefix}${utils.format(val, decimalPlace)}${suffix}`
        }
      }
    },

    /**
     * リクエストポスト依頼選択必須
     */
    validateRequestPost() {
      if (!this.estimate.requestPostId) {
        return {
          result: false,
          message: 'サブタスクを選択してください。'
        }
      } else {
        return {
          result: true,
          message: ''
        }
      }
    },

    features() {
      return ESTIMATE_FEATURES
    },

    /**
     * 見積り権限
     */
    estimateAuth() {
      return this.$store.getters.estimateAuth
    },

    /**
     * 差戻理由行数
     */
    rowCountSendingBack() {
      if (this.estimate.reasonForSendingBack != '') {
        let c = 1
        c = ( this.estimate.reasonForSendingBack.match( /\n/g ) || [] ).length
        if (c) {
          return c+2
        } else {
          return 1
        }
      } else {
        return 1
      }
    },
  },

  methods: {
    async initialize() {
      this.loading = true
      this.estimate = this.createNewEstimate()
      const estimateNo = this.$route.query.estimateNo

      // タスク依頼進捗一覧⇒詳細画面⇒見積り一覧⇒新規作成で遷移した場合、requestPostId,taskRequestIdあり
      const requestPostId = this.$route.query.requestPostId
      const taskRequestId = this.$route.query.taskRequestId

      // 編集、もしくは複写の場合
      if (estimateNo) {
        const estimate = await estimateManager.getEstimate(this, estimateNo)
        if (!estimate) {
          await dialogs.showErrorDialog('エラー', '見積りを開くことができません。')
          this.estimate = this.createNewEstimate()
        } else {
          this.estimate = estimate
          this.setClassificationType()

          // 複写の場合
          if (this.estimate.allCopy != undefined && this.estimate.allCopy) {
            // 変更した部分を削除＆データ成形
            this.remakeData()
          }

          await this.getMain(this.estimate)
          
          // 発注明細がない場合
          if (!this.estimate.purchase) {
            this.setNoteAndPurchaseInit()
          } else {
            this.purchase = this.estimate.purchase
            this.purchaseReload++
          }
          // 手数料明細がない場合
          if (!this.estimate.commission) {
            this.setNoteAndPurchaseInit()
          } else {
            this.commission = this.estimate.commission
            this.commissionReload++
          }
        }
      
      // 編集以外の場合
      } else {

        // コピーの場合
        if (this.copyEstimateNo) {
          await this.copyEstimate(this.copyEstimateNo)

        // 新規作成の場合
        } else {
          // リクエストポスト依頼 進捗一覧から遷移した場合、該当するタスク依頼取得
          if (taskRequestId) {
            let task = await this.getDataByTaskId(requestPostId, taskRequestId)

            if (task) {
              await this.requestPostSelected(task)
              this.loading = false
              await this.getTaskList()
              this.originalEstimate = utils.clone(this.estimate)
              return
            }
          }
        }
      }
      this.originalEstimate = utils.clone(this.estimate)
      this.loading = false
      await this.getTaskList()

      // カテゴリ名の改行は、改行コードに変換する
      if (this.estimate.details) {
        this.estimate.details.forEach((d) => {
          d.categoryName = utils.replaceNewLineCode(d.categoryName)
        })
      }

      this.getDiffDetails()
    },

    /**
     * 区分タブを活性
     */
    setClassificationType() {
      if (this.estimate.classification) {
        this.type.map((t) => {
          if (t.id == this.estimate.classification) {
            t.active = true
          } else {
            t.active = false
          }
        })
      }
    },
  
    /**
     * 見積明細計算
     * @param gName グループ名
     * @param categoryCode カテゴリーコード
     */
    calculate(gName, categoryCode) {
      for (let index = 0; index < this.estimate.details.length; index++) {
        const estimateDetail = this.estimate.details[index]

        // 詳細もしくはカテゴリーの単価があれば、カテゴリーの単価を計算
        if (estimateDetail.itemUnitPrice !== '' || estimateDetail.categoryUnitPrice !== '') {
          // 単価2を手動で編集していない、発注価格を手動で変更していない場合
          if (!estimateDetail.categoryBgColor && !estimateDetail.changePurchasePrice) {
            // カテゴリーの単価
            estimateDetail.categoryUnitPrice = String(BigNumber(Number(estimateDetail.itemQty || 1)).times(Number(estimateDetail.itemUnitPrice || 0)))
          }
          // カテゴリーの金額
          estimateDetail.categoryAmount = String(BigNumber(Number(estimateDetail.categoryQty || 1)).times(Number(estimateDetail.categoryUnitPrice || 0)))
        } else {
          // カテゴリーの単価
          estimateDetail.categoryUnitPrice = ''
          // カテゴリーの金額
          estimateDetail.categoryAmount = ''
        }

        // 定期、特別清掃以外で、セル結合されていたら、金額も結合
        if (estimateDetail.categoryCode != '5' && !(estimateDetail.categoryCode=='14' && !estimateDetail.newData) && estimateDetail.rowspan < 0) {
          for (let i = index; i >= 0; i--) {
            if (this.estimate.details[i].rowspan > 0) {
              // 単価2を手動で編集していない、発注価格を手動で変更していない場合
              if (!this.estimate.details[i].categoryBgColor && !this.estimate.details[i].changePurchasePrice) {
                let price1 = BigNumber(Number(this.estimate.details[index].itemQty || 0)).times(Number(this.estimate.details[index].itemUnitPrice || 0))
                this.estimate.details[i].categoryUnitPrice = String(BigNumber(Number(this.estimate.details[i].categoryUnitPrice || 0)).plus(price1))
              }
              this.estimate.details[index].categoryUnitPrice = ''
              break
            }
          }

        // 定期
        } else if (estimateDetail.categoryCode == '5' && estimateDetail.fixedRowspan < 0) {
          for (let i = index; i >= 0; i--) {
            if (this.estimate.details[i].fixedRowspan > 0) {
              // 単価2を手動で編集していない、発注価格を手動で変更していない場合
              if (!this.estimate.details[i].categoryBgColor && !this.estimate.details[i].changePurchasePrice) {
                let price1 = BigNumber(Number(this.estimate.details[index].itemQty || 0)).times(Number(this.estimate.details[index].itemUnitPrice || 0))
                this.estimate.details[i].categoryUnitPrice = String(BigNumber(Number(this.estimate.details[i].categoryUnitPrice || 0)).plus(price1))
              }
              this.estimate.details[index].categoryUnitPrice = ''

              break
            }
          }
        }
      }

      const regex = /[0-9]/g

      // セル結合のため、再計算
      for (let i = 0; i < this.estimate.details.length; i++) {
        const estimateDetail = this.estimate.details[i]
        // カテゴリーの金額
        estimateDetail.categoryAmount = String(BigNumber(Number(estimateDetail.categoryQty || 1)).times(Number(estimateDetail.categoryUnitPrice || 0)))
        // 回/●年の場合
        if (estimateDetail.categoryUnitName.indexOf('回/') != -1 && estimateDetail.categoryUnitName.indexOf('年') != -1) {
          estimateDetail.categoryUnitName = utils.hankaku(estimateDetail.categoryUnitName)
          if (estimateDetail.categoryUnitName.match(regex)) {
            let value = ''
            let valueArr = estimateDetail.categoryUnitName.match(regex)
            value = valueArr.toString()
            estimateDetail.categoryAmount = String(Math.floor(Number(estimateDetail.categoryAmount) / Number(value)))
          }
        }
      }

      // 定期の合計行
      for (let i = 0; i < this.estimate.details.length; i++) {
        if (this.estimate.details[i].categoryCode == '5' && this.estimate.details[i].type == 'fixedCalc') {
          let fixTotal = 0

          for (let j = i+1; j < this.estimate.details.length; j++) {
            const target = this.estimate.details[j];
            // 同じグループ名の合計金額を取得
            if (this.estimate.details[i].groupName == target.groupName) {
              if (!isNaN(target.categoryAmount) && target.categoryAmount != '' && target.categoryAmount != '0') {
                // 金額を加算
                fixTotal = fixTotal + Number(target.categoryAmount)
              }

            // 同じグループ名でなくなったら、終了
            } else {
              break
            }
          }
          this.estimate.details[i].itemName = this.estimate.details[i].categoryName + '年額合計  ' + fixTotal.toLocaleString() + '円/税抜'
          // 月額表示ONの場合、月額表示
          // console.log(this.estimate.details[i].subItemModalData)
          if (this.estimate.details[i].subItemModalData) {
            if (this.estimate.details[i].subItemModalData.monthlyFeeFlag && this.estimate.details[i].subItemModalData.monthlyFee) {
              this.estimate.details[i].itemName += '\r\n ' + ' 月額：' + this.estimate.details[i].subItemModalData.monthlyFee.toLocaleString() + '円'
            }
          }
          this.estimate.details[i].categoryAmount = ''
        }
      }

      // 小計 ＝ 全ての合計 - 調整費
      this.estimate.withoutTax = String(this.estimate.details.reduce((n, {categoryAmount}) => n + Number(categoryAmount), 0) - Math.abs(Number(this.estimate.discount || '')))
      this.estimate.tax = String(Math.floor(BigNumber(Number(this.estimate.withoutTax)).times(TAX_RATE / 100)))

      this.estimate.totalAmount =  String(Number(this.estimate.withoutTax) + Number(this.estimate.tax))

      // 発注明細 グループ名があるときのみ再計算
      if (gName) {
        this.getCommissionPrice(gName)
        this.getPurchasePrice(gName, false, categoryCode)
      }
    },

    /**
     * カテゴリーごとの単価2の合計
     */
    totalPrice2ByCategoryGrp(gName) {
      let price = 0
      this.estimate.details.forEach((d) => {
        if (d.groupName == gName) {
          if (!isNaN(d.categoryUnitPrice)) {
            price += Number(d.categoryUnitPrice)
          }
        }
      })
      if (price == 0) {
        return ''
      } else {
        return String(price)
      }
    },
  
    /**
     * 見積を新規作成
     */
    createNewEstimate() {
      const newEstimate = estimateManager.createNewEstimateModel()
      return newEstimate
    },
  
    /**
     * 保存ボタン押下
     */
    async saveEstimate(silent, status) {
      
      const validationResult = await this.validation()
      if (!validationResult) {
        return
      }

      try {
        // 保存ボタン非活性
        this.isSaving = true

        this.estimate.estimateStatus = status
        
        // 承認者データをクリアにする
        this.estimate.approval1 = '---'
        this.estimate.approval2 = '---'
        // 1番目の承認ルート
        this.estimate.approvalFlow1 = [{
          sequentialOrder: '1',
          userId: '',
          userName: '',
          chatworkAccountId: '',
          chatworkRoomId: '',
          status: '未承認',
          stamp: ''
        }]
        // 2番目の承認ルート
        this.estimate.approvalFlow2 = [{
          sequentialOrder: '2',
          userId: '',
          userName: '',
          chatworkAccountId: '',
          chatworkRoomId: '',
          status: '未承認',
          stamp: ''
        }]
        
        // 実際の作成者
        this.estimate.estimateCreater = this.$store.getters.user.id

        // 申請した日時
        this.estimate.approvalPetitionDate = null

        let result
        let newFlag = false
        // 印鑑
        this.setStamp()

        // 編集の場合
        if (this.estimate.estimateNo || this.estimate.allCopy) {
          
          if (this.estimate.allCopy) {
            // 複写フラグをfalseにして保存
            this.estimate.allCopy = false
            console.log(this.estimate)
            this.estimate.estimateNo = this.$route.query.estimateNo
          }

          // IDがない場合
          if (!this.estimate._id) {
            const savedEstimate = await estimateManager.getEstimate(this, this.estimate.estimateNo)
            if (!savedEstimate) {
              await dialogs.showErrorDialog('見積りの保存', '見積りを保存できませんでした。')
              return false
            }
            this.estimate._id = savedEstimate._id
          }
          result = await estimateManager.updateEstimate(this, this.estimate)
        
        // 新規保存の場合
        } else {
          result = await estimateManager.registerEstimate(this, this.estimate)
          newFlag = true
        }

        if (!silent) {
          if (result) {
            this.originalEstimate = utils.clone(this.estimate)
            logManager.recordingByRegist(this, newFlag, '見積', '見積（見積番号：' + this.estimate.estimateNo + '）')
            
            // 添付ファイルの確認
            if (!await this.checkAttachment()) {
              await dialogs.showSuccessDialog('見積りの保存', '見積りを保存しました。')
              // await this.selectCopyTask()
            }
          
            // 1度作成完了されている見積りは集計から除外
            await this.deleteEstimateApprovedCount()

          } else {
            await dialogs.showErrorDialog('見積りの保存', '見積りを保存できませんでした。')
          }
        }
        return result
      } finally {
        this.isSaving = false
      }
    },

    /**
     * 作成完了ボタンイベント
     * @param modalOpen 承認画面を開くか
     */
    async completedEstimate(modalOpen) {
      const validationResult = await this.validation()
      if (!validationResult) {
        return
      }

      // 得意先名、物件名、物件住所が入っているか確認
      if (!await this.compValidation()) {
        return
      }

      // 実際の作成者
      this.estimate.estimateCreater = this.$store.getters.user.id

      this.setStamp()
      this.estimate.priceChange = false

      // 調整費を入力しているか確認
      if (this.estimate.discount && this.estimate.discount != '' && this.estimate.discount != '0') {
        this.estimate.priceChange = true

      // 調整費がなければ他に手動で価格変更していないか確認
      } else {
        for (let i = 0; i < this.estimate.details.length; i++) {
          let d = this.estimate.details[i]
          for (let key in d) {
            if (key.indexOf('BgColor') != -1) {
              if (d[key]) {
                this.estimate.priceChange = true
                break
              }
            }
          }
        }
      }

      let purchaseOver = false
      // 価格変更していない場合
      if (!this.estimate.priceChange) {
        // 発注率が設定値を超えているものがあれば、承認を通す
        for (let i = 0; i < this.purchase.length; i++) {
          const pu = this.purchase[i];
          if (pu.rateBgColor) {
            purchaseOver = true
            break
          }
        }
      }
      
      // 単価を手動で変更している、もしくは承認申請チェックあり、もしくは発注率オーバーの場合は、承認モーダルを表示
      if ((this.estimate.priceChange || this.estimate.approvalPetition || purchaseOver) && modalOpen) {
        this.approvalSelectorShow = true
      } else {
        this.approvalSelectorShow = false
        // 承認申請のチャットワーク送信をオフにする
        this.estimate.approvalInfo.chatwork1 = false
        this.estimate.approvalInfo.chatwork2 = false

        // ステータスを作成完了にしてデータ保存
        const result = await this.saveEstimate(true, '作成完了')
        if (result) {
          // 集計用に保存
          await this.setCountData(this.estimate)

          this.originalEstimate = utils.clone(this.estimate)
          logManager.recording(this, logManager.Loglevel.INFO, '見積', '作成完了', '見積（見積番号：' + this.estimate.estimateNo + '）を作成完了しました。')
          
          // 添付ファイルの確認
          if (!await this.checkAttachment()) {
            await dialogs.showSuccessDialog('見積り作成完了', '見積りを保存しました。')
            // await this.selectCopyTask()
          }
        } else {
          await dialogs.showErrorDialog('見積り作成完了', '見積りを保存できませんでした。')
        }
      }
    },

    /**
     * 添付ができているかの確認
     */
    async checkAttachment() {
      let d = await estimateManager.getEstimate(this, this.estimate.estimateNo)
      let res = false

      for (let i = 1; i < 4; i++) {
        let tar = 'estimateAttachment' + i
        let tar2 = 'attachment' + i
        let res2 = await this.$refs[tar].$refs.attachment.checkBytes(d[tar2])
        if (res2) {
          res = true
        }
      }
      return res
    },


    /**
     * 所属部署 取得
     * @param {*} userId 
     */
    async getDepartments(userId) {
      let dep = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: userId})
      if (dep.data.data.departmentId) {
        return { departmentId: dep.data.data.departmentId, departmentName: dep.data.data.departmentName }
      } else {
        return { departmentId: '', departmentName: '' }
      }
    },

    /**
     * 承認された見積りを集計のために保存
     * @param est 見積りデータ
     */
    async setCountData(est) {
      // 物件住所がないものは集計しない
      if (!est.buildingAddress2 || est.buildingAddress2 == '') {
        return
      }
      // 区分がないものは集計しない
      if (!est.classification || est.classification == '') {
        return
      }
      // サブ担当者のIDがなければ＆未定でもなければ、名前からIDを取得
      if ((!est.taskStaffId || est.taskStaffId == '') && est.taskStaff != '未定') {
        est.taskStaffId = utils.getUserIdByName(est.taskStaff, this.$store.getters.userList)
      }
      
      let staffId = null
      if (est.taskStaffId && !isNaN(est.taskStaffId)) {
        staffId = est.taskStaffId
      }

      // 部署IDを正しいものを再取得
      let departmentId = null
      if (!est.departmentIdSubTask || est.departmentIdSubTask == '') {
        if (staffId) {
          let dep = await this.getDepartments(staffId)
          if (dep && dep.departmentId) {
            departmentId = dep.departmentId
          }
        }
      } else {
        departmentId = est.departmentIdSubTask
      }

      // メインデータが取得できていない場合は取得
      if (!this.mainData.requestDate) {
          const parentRequest = await requestPostManager.getRequestPost(this, est.requestPostId)
          this.mainData = {}
          if (parentRequest) {
            // メインのID
            this.mainData._id = parentRequest._id
            // メインの問い合わせNo
            this.mainData.requestNo = parentRequest.requestNo
            // 依頼日
            this.mainData.requestDate = parentRequest.requestDate
            // 見積りカテゴリーその他も結合
            this.mainData.estimateCategory = parentRequest.estimationCleanCategory.concat(parentRequest.estimationInspectCategory).concat(parentRequest.estimationOtherCategory)
          }
      }

      let scheduledDays = null
      scheduledDays = utils.getScheduleDays(this.mainData.estimateCategory)
      let category = null
      if (this.mainData.estimateCategory) {
        category = this.mainData.estimateCategory.join('、')
      }

      // 同一物件判定は、得意先名OR得意先住所が一致＋物件名OR物件住所が一致
      // 担当者はサブタスク担当者
      const list = {
        estimateNo: est.estimateNo,
        staffId: staffId,
        staff: utils.deleteKana(est.taskStaff),
        departmentIdSubTask: departmentId,
        clientAddress: utils.deleteMark(utils.deleteSpace(utils.hankaku(est.clientAddress1))),
        siteAddress: utils.deleteMark(utils.deleteSpace(utils.hankaku(est.buildingAddress2))),
        classification: est.classification,
        approvedDate: utils.getToday(),
        client: utils.deleteMark(est.clientName),
        site: utils.deleteMark(est.buildingName),
        test: est.test,
        insertUser: this.$store.getters.user.id,
        // 以下は 2024/6 追加
        // メインタスク依頼日
        requestDate: this.mainData.requestDate,
        // 見積り申請日（自己承認のためNull）
        approvalPetitionDate: null,
        // 目安日数
        scheduledDays,
        // 見積りカテゴリー（メイン）
        category,
        // メインのID
        requestPostId: this.mainData._id,
        // 税抜き金額
        withoutTax: est.withoutTax,
        // メインの問い合わせNo
        requestNo: this.mainData.requestNo
      }

      let estimateApprovedResult = await backend.postData('estimateApproved/save', list)
      if (estimateApprovedResult.data == 'Internal Server Error') {
        alert('集計用にデータを保存中にエラーが発生しました。\r\nAPIコンソールを確認してください。')
      }
    },

    /**
     * 1度作成完了されている見積りは集計から除外するので、集計テーブルから対象のデータを削除
     */
    async deleteEstimateApprovedCount() {
      // 更新の場合、削除
      if (this.estimate.estimateNo && this.estimate.estimateNo != '') {
        let deleteApproved = await backend.deleteDataByKey('estimateApproved/deleteDataByKey', {key: this.estimate.estimateNo}, false)
        if (deleteApproved.data == 'Internal Server Error') {
          alert('エラーが発生しました。\r\n【estimateApproved/deleteDataByKey】')
          console.log('【再登録のため集計データ作成完了登録分削除】')
        }
      }
    },

    /**
     * 作成者,会社の印鑑URLを保存
     */
    setStamp() {
      this.estimate.userStamp = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}stamps/${this.$store.getters.user.id}.png`
      this.estimate.campanyStamp = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}stamps/company.png`
    },

    /**
     * 見積申請ボタン押下
     * @param ルートデータ
     */
    async approvalApplication(routeData) {

      this.approvalSelectorShow = false

      try {
        // 保存ボタン非活性
        this.isSaving = true
        // 承認申請ボタンアイコン切替
        this.isLoadingCompleted = true
        // 実際の作成者
        this.estimate.estimateCreater = this.$store.getters.user.id

        // 該当ルート名の承認マスタデータを更新（優先度をカウントアップするため）
        const registApp = await estimateManager.updateTasks(this, routeData._id, routeData)
        if (!registApp) {
          alert('承認ルートでエラーが発生しました。')
          return
        }

        this.estimate.estimateStatus = '承認申請中'
        this.estimate.approval1 = '承認待ち'
        
        let isApp2 = false
        if (this.estimate.approvalFlow2.length) {
          for (let i = 0; i < this.estimate.approvalFlow2.length; i++) {
            if (this.estimate.approvalFlow2[i].userId != '') {
              isApp2 = true
              break
            }
          }
        }
        if (isApp2) {
          this.estimate.approval2 = '承認待ち'
        } else {
          this.estimate.approval2 = '---'
        }

        // 承認申請日
        this.estimate.approvalPetitionDate = moment().format('YYYY-MM-DD HH:mm:ss')
        
        // 複写フラグをfalseにして保存
        this.estimate.allCopy = false
        
        let result

        // 編集の場合
        if (this.estimate.estimateNo) {
          // IDがない場合
          if (!this.estimate._id) {
            const savedEstimate = await estimateManager.getEstimate(this, this.estimate.estimateNo)
            if (!savedEstimate) {
              await dialogs.showErrorDialog('見積り保存', '見積りの保存ができませんでした。')
              return false
            }
            this.estimate._id = savedEstimate._id
          }
          result = await estimateManager.updateEstimate(this, this.estimate)
        
        // 新規保存の場合
        } else {
          result = await estimateManager.registerEstimate(this, this.estimate)
        }

        if (result) {
          // 承認申請中は見積集計カウント対象外なので、カウントテーブルから削除
          // 1度作成完了されている見積りは集計から除外
          await this.deleteEstimateApprovedCount()
          
          // 添付ファイルの確認
          if (!await this.checkAttachment()) {
            await dialogs.showSuccessDialog('見積り保存', '見積り保存しました。')
            // await this.selectCopyTask()
          }
          logManager.recording(this, logManager.Loglevel.INFO, '見積', '承認申請', '見積（見積番号：' + this.estimate.estimateNo + '）を承認申請しました。')
        } else {
          await dialogs.showErrorDialog('見積り保存', '見積りの保存ができませんでした。')
        }

        let viewUrl = ''
        // 保存でき、承認者へチャット送信するなら、承認画面のURLをチャットで送信
        if (result && (this.estimate.approvalInfo.chatwork1 || this.estimate.approvalInfo.chatwork2)) {          
          viewUrl = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}EstimateView?estimateNo=${this.estimate.estimateNo}&approver=true&sequentialOrder=1`

          for (let i = 0; i < this.estimate.approvalFlow1.length; i++) {
            // チャットワーク送る
            this.sendChatWorkMessage(this.estimate, viewUrl, this.estimate.approvalFlow1[i])
          }
        }

        return result

      } finally {
        this.isSaving = false
        this.isLoadingCompleted = false
      }
    },

    /**
     * 承認申請時の必須項目が未入力の場合、アイコン表示判定
     */
    approvalEmpty(target) {
      if (!target || target == '') {
        return true
      } else {
        return false
      }
    },

    /**
     * 完了バリデート
     */
    async compValidation() {
      // 得意先名
      if (!this.estimate.clientName || this.estimate.clientName == '') {
        await dialogs.showErrorDialog('得意先名未入力', '完了する場合は、得意先名は必須です。')
        return false

      // 物件名
      } else if (!this.estimate.buildingName || this.estimate.buildingName == '') {
        await dialogs.showErrorDialog('建物名未入力', '完了する場合は、建物名は必須です。')
        return false

      // 物件住所
      } else if (!this.estimate.buildingAddress2 || this.estimate.buildingAddress2 == '') {
        await dialogs.showErrorDialog('建物住所未入力', '完了する場合は、建物住所は必須です。\r\n承認された見積は、集計で住所が同じならば同物件と判断しますので、正しい住所を入力してください。')
        return false
      }

      for (let i = 0; i < this.estimate.purchase.length; i++) {
        const p = this.estimate.purchase[i]
        if (!p.supplier || p.supplier == '') {
          await dialogs.showErrorDialog('発注先未入力あり', `${p.categoryName}の発注先を入力してください。`, 'OK')
          return false
        }
      }
      return true
    },

    showLoading(message, subMessage) {
      dialogs.showLoading(message, subMessage)
    },

    hideLoading() {
      dialogs.hideLoading()
    },

    showPrintingMessage() {
      this.showLoading('印刷（PDF出力）処理中です・・・')
    },

    hidePrintingMessage() {
      this.hideLoading()
    },

    // async print(printKind) {
    //   if (printKind === 'estimate') {
    //     const win = this.isModileDevice ? window.open() : null
    //     await this.printEstimate(win)
    //   } else if (printKind === 'coverpage') {
    //     const win = this.isModileDevice ? window.open() : null
    //     await this.printCoverPage(win)
    //   } else if (printKind === 'estimateWithCoverPage') {
    //     const win = this.isModileDevice ? window.open() : null
    //     await this.printEstimateWithCoverPage(win)
    //   }
    // },

    // async printEstimate(win) {

    //   try {
    //     this.showPrintingMessage()

    //     const result = await this.saveEstimate(true, '作成中')
    //     if (!result) {
    //       console.log('見積りを保存できませんでした。')
    //       return
    //     }

    //     const savedEstimate = await estimateManager.getEstimate(this, this.estimate.estimateNo)
    //     if (savedEstimate) {
    //       const printUrl = estimateManager.buildPrintUrlForDirectPrint(savedEstimate, this.$store.getters.user.token)
    //       console.log(printUrl)
    //       if (win) {
    //         win.location = printUrl
    //       } else {
    //         window.open(printUrl) 
    //       }
    //     }
    //   } finally { 
    //     this.hidePrintingMessage()
    //   }
    // },

    /**
     * チャットワーク送信
     * @param project 内容
     * @param viewUrl URL
     * @param chatInfo チャット情報
     */
    async sendChatWorkMessage(project, viewUrl, chatInfo) {
      if (ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE && ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.enable) {

        let mension = ''

        // ルームIDをそれぞれの認証者用に変更_2022.11.08ご要望
        let roomId = ''
        let r = await this.$pigeon.getTasksByFormId('estimate_approver_chatwork_master')
        if (r.length) {
          for (let i = 0; i < r.length; i++) {
            const result = r[i]
            if (result.userId == chatInfo.userId) {
              roomId = result.roomId
              break
            }
          }
        }

        if (!this.estimate.approvalInfo.chatwork1) {
          return
        }
        if (chatInfo.userId != '') {
          if (chatInfo.chatworkAccountId != '') {
            mension = `[To:${chatInfo.chatworkAccountId}] (承認者1) ${chatInfo.userName}さん`
          } else {
            mension = `[To] (承認者1) ${chatInfo.userName}さん`
          }
        }

        const context = {
          mension,
          user: utils.deleteKana(this.$store.getters.user.user_name),
          estimateNo: project.estimateNo,
          requestPostName: project.requestPostName,
          clientName: project.clientName,
          buildingName: project.buildingName,
          message: project.approvalInfo.message1 || '',
          viewUrl: viewUrl
        }

        const expression = '`' + ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.messageTemplate + '`' 

        const message = utils.evaluate(context, expression)
        const chatworkApiToken = ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.chatworkApiToken
        console.log(message)
        console.log(roomId)
        console.log(chatworkApiToken)
        if (roomId == '') {
          await dialogs.showErrorDialog('チャット送信不可', chatInfo.userName + 'さんの承認者用roomIDが登録されていません。\r\nマスタメンテの「承認チャットワーク」にて登録してください。')
        } else {
          await chatwork.sendChatWorkMssage(
            roomId, 
            message,
            chatworkApiToken
          )
        }
      }
    },

    /**
     * バリデーション
     */
    async validation() {
      // リクエストポスト依頼判定
      if (!this.validateRequestPost.result) {
        await dialogs.showErrorDialog('入力確認', this.validateRequestPost.message)
        return false
      }

      // 区分
      if (!this.estimate.classification && this.estimate.buildingAddress2 != '') {
        await dialogs.showErrorDialog('入力確認', '区分を選択してください。')
        return false
      }

      // 調整費
      if (this.estimate.discount && this.estimate.discount != '0' && Number(this.estimate.discount) > 0) {
        await dialogs.showErrorDialog('調整費', '今後調整費は入力できません。\r\n詳細画面にて個別に値引きをしてください。')
        return false
      }

      // 金額の整合性
      if (!await this.checkPrice()) {
        return false
      }

      return true
    },

    /**
     * 単価1と2の整合性が取れているか確認
     */
    async checkPrice() {
      // 手動で変更など関係なしに計算
      const tar = utils.clone(this.estimate.details)
      for (let index = 0; index < tar.length; index++) {
        const d = tar[index]
        // 詳細もしくはカテゴリーの単価があれば、カテゴリーの単価を計算
        if (d.itemUnitPrice !== '' || d.categoryUnitPrice !== '') {
          // カテゴリーの単価
          d.categoryUnitPrice = String(BigNumber(Number(d.itemQty || 1)).times(Number(d.itemUnitPrice || 0)))
          // カテゴリーの金額
          d.categoryAmount = String(BigNumber(Number(d.categoryQty || 1)).times(Number(d.categoryUnitPrice || 0)))
        } else {
          // カテゴリーの単価
          d.categoryUnitPrice = ''
          // カテゴリーの金額
          d.categoryAmount = ''
        }

        // 定期、特別清掃以外で、セル結合されていたら、金額も結合
        if (d.categoryCode != '5' && !(d.categoryCode=='14' && !d.newData) && d.rowspan < 0) {
          for (let i = index; i >= 0; i--) {
            if (tar[i].rowspan > 0) {
              let price1 = BigNumber(Number(tar[index].itemQty || 0)).times(Number(tar[index].itemUnitPrice || 0))
              tar[i].categoryUnitPrice = String(BigNumber(Number(tar[i].categoryUnitPrice || 0)).plus(price1))
              
              tar[index].categoryUnitPrice = ''
              break
            }
          }

        // 定期
        } else if (d.categoryCode == '5' && d.fixedRowspan < 0) {
          for (let i = index; i >= 0; i--) {
            if (tar[i].fixedRowspan > 0) {
              let price1 = BigNumber(Number(tar[index].itemQty || 0)).times(Number(tar[index].itemUnitPrice || 0))
              tar[i].categoryUnitPrice = String(BigNumber(Number(tar[i].categoryUnitPrice || 0)).plus(price1))

              tar[index].categoryUnitPrice = ''
              break
            }
          }
        }
      }

      const regex = /[0-9]/g

      // セル結合のため、再計算
      for (let i = 0; i < tar.length; i++) {
        const d = tar[i]
        // カテゴリーの金額
        d.categoryAmount = String(BigNumber(Number(d.categoryQty || 1)).times(Number(d.categoryUnitPrice || 0)))

        // 回/●年の場合
        if (d.categoryUnitName.indexOf('回/') != -1 && d.categoryUnitName.indexOf('年') != -1) {
          d.categoryUnitName = utils.hankaku(d.categoryUnitName)
          if (d.categoryUnitName.match(regex)) {
            let value = ''
            let valueArr = d.categoryUnitName.match(regex)
            value = valueArr.toString()
            d.categoryAmount = String(Math.floor(Number(d.categoryAmount) / Number(value)))
          }
        }
      }

      // 比較する
      const original = utils.clone(this.estimate.details)
      for (let j = 0; j < original.length; j++) {
        const ori = original[j]
        const t = tar[j]
        
        if (ori.rowspan > 0) {
          if (ori.categoryUnitPrice && ori.categoryUnitPrice != t.categoryUnitPrice) {
            // console.log(ori)
            // console.log(t)
            await dialogs.showErrorDialog(ori.itemName + 'を詳細画面で確認してください。', '不整合な値が見つかりました。詳細モーダルを開いて完了ボタンを押してください。')
            return false
          }
          if (t.categoryUnitPrice && !isNaN(t.categoryUnitPrice) && !Number.isInteger(Number(t.categoryUnitPrice))) {
            await dialogs.showErrorDialog(ori.itemName + 'を詳細画面で確認してください。', '単価2に小数があります。詳細モーダルを開いて完了ボタンを押してください。')
            return false
          }
        }
      }
      return true
    },

    /**
     * リクエストポスト依頼を選択イベント
     * @param selectedRequestPost 選択したリクエストポストデータ
     */
    async requestPostSelected(selectedRequestPost) {

      if (!this.estimate.allCopy) {
        // 初期化
        this.estimate = this.createNewEstimate()
      }

      // リクエストポスト依頼に表示する文言
      this.estimate.requestPostName = selectedRequestPost.inquiry_title

      // リクエストポストID
      this.estimate.requestPostId = selectedRequestPost.requestPostId

      // メインタスク部署ID
      this.estimate.departmentIdMain = selectedRequestPost.departmentIdMain

      // タスク依頼 ID
      this.estimate.taskRequestId = selectedRequestPost.taskRequestId

      // タスク依頼 No
      this.estimate.subTaskNo = selectedRequestPost.subTaskNo
      
      // タスク担当者ID
      this.estimate.taskStaffId = selectedRequestPost.taskStaffId
      
      // タスク担当者
      this.estimate.taskStaff = selectedRequestPost.taskStaff

      // サブタスク担当者の部署ID
      this.estimate.departmentIdSubTask = selectedRequestPost.departmentIdSubTask

      // タスク依頼内容
      this.estimate.requestTask = selectedRequestPost.requestTask

      // 得意先コード
      this.estimate.clientCode = selectedRequestPost.clientCode
      this.estimate.clientName = selectedRequestPost.clientName
      this.estimate.clientAddress1 = selectedRequestPost.clientAddress1
      this.estimate.clientAddress2 = selectedRequestPost.clientAddress2

      // 得意先のデータをマスタから取得
      if (selectedRequestPost.clientCode && selectedRequestPost.clientCode != '') {
        const client = await estimateManager.getClientByCode(selectedRequestPost.clientCode)

        if (client) {
          this.estimate.clientHonorific = client.honorific
          this.estimate.clientPostalCode = client.postalCode
          this.estimate.clientPhoneNumber = client.clientPhoneNumber
          this.estimate.clientFax = client.clientFax
        }
      }

      if (!this.estimate.clientHonorific || this.estimate.clientHonorific == '') {
        this.estimate.clientHonorific = '御中'
      }

      // 得意先担当者名
      this.estimate.clientPersonName = selectedRequestPost.clientPersonName

      // 現場の引用
      this.estimate.buildingName = selectedRequestPost.buildingName
      this.estimate.buildingPostalCode = selectedRequestPost.postalCode
      this.estimate.buildingAddress = selectedRequestPost.state + selectedRequestPost.city + selectedRequestPost.street + selectedRequestPost.building
      this.estimate.buildingAddress2 = selectedRequestPost.state + selectedRequestPost.city + selectedRequestPost.street
      this.estimate.subject = selectedRequestPost.note

      // 物件情報
      this.estimate.buildingInfo.address.state = selectedRequestPost.state
      this.estimate.buildingInfo.address.city = selectedRequestPost.city
      this.estimate.buildingInfo.address.street = selectedRequestPost.street
      if (selectedRequestPost.stairs && selectedRequestPost.stairs != '') {
        this.estimate.buildingInfo.stairs = selectedRequestPost.stairs
      }
      this.estimate.buildingInfo.basement = ''
      if (selectedRequestPost.basement && selectedRequestPost.basement != '') {
        this.estimate.buildingInfo.basement = selectedRequestPost.basement
      }
      if (selectedRequestPost.households && selectedRequestPost.households != '') {
        this.estimate.buildingInfo.households = selectedRequestPost.households
      }

      // タイプ
      this.estimate.buildingInfo.residenceType = selectedRequestPost.residenceType[0]

      // 駐車場
      this.estimate.buildingInfo.parking = this.setParking(selectedRequestPost)
      
      // トイレ使用
      this.estimate.buildingInfo.availableToilet = this.setToilet(selectedRequestPost)

      // 手数料有無
      this.estimate.buildingInfo.commission = selectedRequestPost.commission

      // オーナー契約
      this.estimate.buildingInfo.owner = selectedRequestPost.owner

      // 竣工
      if (selectedRequestPost.finishPoint) {
        this.estimate.buildingInfo.finishPoint = selectedRequestPost.finishPoint
        this.estimate.buildingInfo.finishYear = selectedRequestPost.finishYear
        this.estimate.buildingInfo.finishMonth = selectedRequestPost.finishMonth
      }

      if (this.isToiletClean(selectedRequestPost, 'rlsToiletCleaning')) {
        this.estimate.buildingInfo.rlsToiletTimes = selectedRequestPost.rlsToiletTimes
        this.estimate.buildingInfo.rlsToiletPlace = selectedRequestPost.rlsToiletPlace
      }

      if (this.isToiletClean(selectedRequestPost, 'plusToiletCleaning')) {
        this.estimate.buildingInfo.plusToiletTimes = selectedRequestPost.plusToiletTimes
        this.estimate.buildingInfo.plusToiletPlace = selectedRequestPost.plusToiletPlace
      }

      if (this.isToiletClean(selectedRequestPost, 'plusToiletCleaningNormal')) {
        this.estimate.buildingInfo.plusToiletTimesNormal = selectedRequestPost.plusToiletTimesNormal
        this.estimate.buildingInfo.plusToiletPlaceNormal = selectedRequestPost.plusToiletPlaceNormal
      }

      this.estimate.buildingInfo.rlsTrash = this.setTrash(selectedRequestPost, 'dustRemoval', 'dustCount')

      this.estimate.buildingInfo.rlsPlusTrash = this.setTrash(selectedRequestPost, 'plusDustRemoval', 'plusDustCount')

      this.estimate.buildingInfo.rlsCleaningTimes = this.setClean(selectedRequestPost, 'cleanCount')

      this.estimate.buildingInfo.plusCleaningTimes = this.setClean(selectedRequestPost, 'plusCleanCount')

      // 日常清掃
      this.estimate.buildingInfo.normal = this.setNormal(selectedRequestPost)

      // 管理員
      this.estimate.buildingInfo.mgt = this.setMgt(selectedRequestPost)
      
      // ガラス清掃
      this.estimate.buildingInfo.glassTimes = selectedRequestPost.glassCleanCount

      // 植栽剪定（のちに追加）
      this.estimate.buildingInfo.plantingTimes = selectedRequestPost.plantingCount

      // メモ
      this.setMemo(selectedRequestPost)

      // 大項目マスター取得
      const list = this.$store.getters.estimateCategoryList

      let arr = []

      // カテゴリー名、カテゴリーコード取得（御見積_清掃カテゴリー、御見積_点検カテゴリー）
      if (selectedRequestPost.taskEstimationCleanCategory.length || selectedRequestPost.taskEstimationInspectCategory.length || selectedRequestPost.estimationOtherCategory.length) {
        this.estimate.details.splice(0)

        arr = this.setCategoryMenu(selectedRequestPost)

        // 単独発注判定
        this.estimate.buildingInfo.other = this.setOther(arr)

        for (let i = 0; i < arr.length; i++) {
          let target = {}
          let emptyEstimateDetail = estimateManager.createEmptyDetail()
          // カテゴリーコード 初期値は空文字
          emptyEstimateDetail.categoryCode = ''

          // カテゴリー名
          emptyEstimateDetail.categoryName = arr[i]

          // カテゴリーコード
          target =  list.find((l) => {
            if (l.categoryName == arr[i].replace(/[0-9]/g, '')) {
              return true
            }
          })

          // 並べ替えで使用するグループ名
          emptyEstimateDetail.groupName = emptyEstimateDetail.categoryName + Math.floor(Math.random()*90000) + 10000

          if (target) {
            // カテゴリーコード
            emptyEstimateDetail.categoryCode = target.categoryCode
            // カテゴリーの単位をセット
            emptyEstimateDetail.categoryUnitName = target.unitName
            // カテゴリーの数量をセット
            emptyEstimateDetail.categoryQty = target.qty

            // 日常
            if (target.categoryCode == '3') {
              this.setDetailData(emptyEstimateDetail, this.estimate.buildingInfo.normal)
              continue
            
            // 管理
            } else if (target.categoryCode == '4') {
              this.setDetailData(emptyEstimateDetail, this.estimate.buildingInfo.mgt)
              continue

            // 定期 仕様変更タブ制はなし、行を追加 11/29
            } else if (target.categoryCode == '5') {
              for (let i = 0; i < selectedRequestPost.regularCleans.length; i++) {
                // グループ名 生成
                emptyEstimateDetail.groupName = emptyEstimateDetail.categoryName + Math.floor(Math.random()*90000) + 10000
                const r = selectedRequestPost.regularCleans[i]
                this.setDetailData(emptyEstimateDetail, [r])
              }
              continue

            // ガラス清掃リクエストポストの希望回数を入れる
            } else if (target.categoryCode == '10') {
              emptyEstimateDetail.categoryQty = selectedRequestPost.glassCleanCount

            // 植栽剪定リクエストポストの希望回数を入れる
            } else if (target.categoryCode == '13') {
              emptyEstimateDetail.categoryQty = selectedRequestPost.plantingCount

            // EV点検 リクエストポスト希望回数、単位を入れる
            } else if (target.categoryCode == '19') {
              emptyEstimateDetail.categoryQty = selectedRequestPost.evQty
              emptyEstimateDetail.categoryUnitName = selectedRequestPost.evUnitName
            }
          }
          
          // 特別清掃の場合リクエストポストの詳細、希望回数、単位を入れる
          if(target && target.categoryCode == '14') {

            this.setSp(selectedRequestPost, emptyEstimateDetail, arr[i])
            continue

          } else {
            // ラウンドサブメニュー
            if (selectedRequestPost.roundSubs.length) {
              let r = selectedRequestPost.roundSubs
              for (let j = 0; j < r.length; j++) {
                if (r[j].roundSubContent == arr[i]) {
                  emptyEstimateDetail.categoryQty = r[j].roundSubCount
                  emptyEstimateDetail.categoryUnitName = r[j].roundSubUnitName

                  if (emptyEstimateDetail.categoryCode == '') {
                    emptyEstimateDetail.categoryCode = 'rlsSub'
                  }
                  r.splice(j, 1)
                  break
                }
              }
            }

            // その他作業あり
            if(selectedRequestPost.otherWorks.length) {
              let o = selectedRequestPost.otherWorks
              for (let j = 0; j < o.length; j++) {
                if (o[j].otherWorkContent == arr[i]) {
                  emptyEstimateDetail.categoryQty = o[j].otherWorkCount
                  emptyEstimateDetail.categoryUnitName = o[j].otherWorkUnitName
                  o.splice(j, 1)
                  break
                }
              }
            }

            this.estimate.details.push(emptyEstimateDetail)
          }
        }
        // 特記事項リスト,発注
        this.setNoteAndPurchaseInit()
      }
      // 添付書類を引き継ぐ
      this.copyAttachment(selectedRequestPost)
      // 要件に文言をセット
      this.setRequirement()
      // 共通の特記事項
      await this.setCommonNote()
    },

    /**
     * 共通の特記事項
     */
    async setCommonNote() {
      // 竣工予定あり
      if (this.estimate.buildingInfo.finishPoint) {
        let text = await estimateManager.getEstimateNoteText(this, '99-3')
        let year = '●●年'
        let month = ''
        if (this.estimate.buildingInfo.finishYear) {
          year = this.estimate.buildingInfo.finishYear + '年'
        }
        if (this.estimate.buildingInfo.finishMonth) {
          month = this.estimate.buildingInfo.finishMonth + '月'
        }
        text = text.replace('●●年', year + month)

        // 既に共通の特記事項に何か記載ある場合
        if (this.estimate.commonNote) {
          let res = []
          let arr = this.estimate.commonNote.split('\n')
          if (arr && arr.length) {
            for (let i = 0; i < arr.length; i++) {
              const a = arr[i]
              // 竣工に関しての行でない場合、取得
              if (a && a.indexOf('竣工') == -1 && a.indexOf('ご発注頂く際には再見積') == -1) {
                res.push(a)
              }
            }
          }
          a.push(text)
          this.estimate.commonNote = a.join('\n')
        } else {
          this.estimate.commonNote = text
        }
      }
    },

    /**
     * 添付書類をコピー
     * @param rp 選択したメイン・サブのデータ
     */
    async copyAttachment(rp) {
      let arr = rp.taskEstimationCleanCategory.concat(rp.taskEstimationInspectCategory).concat(rp.taskEstimationOtherCategory)
      const filesURL = []
      // メイン左側の添付ファイルを優先的に引き継ぐ
      for (let i = 0; i < 5; i++) {
        let num = ''
        if (i >= 1) {
          num = i + 1
        }
        // 添付されている場合
        const file = 'commonFile' + num
        if (rp[file] && rp[file].url) {
          filesURL.push({ name: rp[file + '_display_file_name'], url: rp[file].url, file: rp[file] })
        }
      }

      // メイン右側の添付ファイルがあり、サブタスクで選択されている見積もりカテゴリーの場合
      // （添付ファイルがあっても、サブで選択されていなければ引き継がない）
      const mainEstimateAttachment = constants.mainEstimateAttachment
      for (let i = 0; i < arr.length; i++) {
        const sub = arr[i]
        for (let j = 0; j < mainEstimateAttachment.length; j++) {
          const tar = mainEstimateAttachment[j]
          // サブで選択しているカテゴリーに添付のプロパティーがある場合
          if (sub == tar.name) {
            for (let k = 0; k < tar.file.length; k++) {
              const file = tar.file[k]
              // 添付されている場合、データを取得
              if (rp[file] && rp[file].url) {
                filesURL.push({ name: rp[file + '_display_file_name'], url: rp[file].url, file: rp[file] })
              }
            }
          }
        }
      }

      // 1番目から3つ目までを引き継ぐ
      const canAttach = []
      for (let i = 0; i < 3; i++) {
        if (filesURL.length && filesURL[i]) {
          const name = 'attachment' + (i + 1)
          // コピーを示すプロパティ
          this.estimate[name + '_file_content'] = filesURL[i].url
          this.estimate[name + '_file_name'] = filesURL[i].name
          this.estimate[name + '_display_file_name'] = filesURL[i].name
          // 添付内容をコピー
          this.estimate[name] = utils.clone(filesURL[i].file)

          // ダイアログ表示用のファイル名
          canAttach.push(filesURL[i].name)
        }
      }

      // 多すぎて添付できなかった分
      const cannotAttach = []
      for (let i = 0; i < filesURL.length; i++) {
        const f = filesURL[i]
        if (i >= 3) {
          cannotAttach.push(f.name)
        }
      }

      // 引き継げなかった資料がある場合
      if (cannotAttach.length) {
        await dialogs.showInfoDialog('引き継げなかったメインタスクの添付があります', `添付が多すぎるため、\r\n${cannotAttach.join('\r\n')}\r\nは引き継げませんでした。\r\n\r\n※${canAttach.join('、')}は引き継ぎました。`)
      // 全て引き継げた場合
      } else if (canAttach.length) {
        await dialogs.showInfoDialog('メインタスクの添付を引き継ぎました', `${canAttach.join('\r\n')}\r\nを引き継ぎました。`)
      }
    },

    /**
     * 日常、管理、定期のRPデータを反映
     * @param data 元データ
     * @param rpData 反映対象RPデータ
     */
    setDetailData(data, rpData) {
      for (let j = 0; j < rpData.length; j++) {
        let n = rpData[j]
        let empty = estimateManager.createEmptyDetail()
        empty.categoryName = data.categoryName
        empty.categoryCode = data.categoryCode
        empty.categoryQty = data.categoryQty
        empty.categoryUnitName = data.categoryUnitName
        empty.groupName = data.groupName
        
        if (j == 0) {
          empty.rowspan = rpData.length
        } else {
          empty.rowspan = -1
        }

        // 定期
        if (data.categoryCode == '5') {
          empty.categoryQty = n.regularCleanCount
          empty.categoryUnitName = n.regularCleanUnitName
          empty.categoryName = n.regularCleanContent
          empty['fixedRowspan'] = 1

        // 日常、管理
        } else {
          empty.itemName = n.times + ' ' + n.hours
        }
        this.estimate.details.push(empty)
      }
    },

    /**
     * 駐車場の値
     * @param selectedRequestPost 反映するRPのデータ
     */
    setParking(selectedRequestPost) {
      let parking = ''
      if (selectedRequestPost.parkingFlag[0] == '有') {
        parking = 'なし'
      } else if (selectedRequestPost.parkingFlag[0] == '無') {
        parking =  'あり'
      }
      return parking
    },

    /**
     * トイレ使用の値
     * @param selectedRequestPost 反映するRPのデータ
     */
    setToilet(selectedRequestPost) {
      let toilet = ''
      if (selectedRequestPost.availableToiletFlag[0] == '有') {
        toilet = '可'
      } else if (selectedRequestPost.availableToiletFlag[0] == '無') {
        toilet = '不可'
      }
      return toilet
    },

    /**
     * トイレ清掃の有無チェック
     * @param selectedRequestPost 反映するRPのデータ
     * @param target 対象データ
     */
    isToiletClean(selectedRequestPost, target) {
      let result = false
      if (selectedRequestPost[target] && selectedRequestPost[target].length && (selectedRequestPost[target][0] == '有料トイレ清掃あり' || selectedRequestPost[target][0] == 'トイレ掃除有')) {
        result = true
      }
      return result
    },

    /**
     * ゴミ出し回数
     * @param selectedRequestPost 反映するRPのデータ
     * @param target 対象データ
     * @param value 回数
     */
    setTrash(selectedRequestPost, target, value) {
      let result = ''
      if (selectedRequestPost[target][0] == '無') {
        result = 'ゴミ出し無し'
      } else if (selectedRequestPost[target][0] == '有') {
        result = selectedRequestPost[value][0]
      }
      return result
    },

    /**
     * 清掃回数
     * @param selectedRequestPost 反映するRPのデータ
     * @param target 対象データ
     */
    setClean(selectedRequestPost, target) {
      let result = ''
      if (selectedRequestPost[target].length && selectedRequestPost[target][0] != '') {
        result = selectedRequestPost[target][0]
      }
      return result
    },

    /**
     * 日常清掃
     * @param selectedRequestPost 反映するRPのデータ
     */
    setNormal(selectedRequestPost) {
      let normal = []
      if (selectedRequestPost.everydayCleans.length) {
        for (let i = 0; i < selectedRequestPost.everydayCleans.length; i++) {
          let n = selectedRequestPost.everydayCleans[i]
          
          let ns = ''
          let nh = ''
          let nt = ''
          // 日常清掃 時間指定あり
          if (n.everydayStartTimeFlag[0] == '有') {
            ns = 'あり'
          // 日常清掃 時間指定なし
          } else if (n.everydayStartTimeFlag[0] == '無') {
            ns = 'なし'
          }
          nh = n.everydayCleanHours + ' 時間'
          nt = '週 ' + n.everydayCleanCount + ' 回'

          let nho = ''
            if (n.everydayHolidayFlag[0] == '無') {
              nho = 'なし'
            } else if (n.everydayHoliday[0] == '通常業務') {
              nho = 'FULL'
            } else if (n.everydayHoliday[0] == 'ゴミ出しのみ') {
              nho = 'ゴミ出しのみ'
            }
          let nToiletT = '0'
          let nToiletP = '0'
          if (n.toiletCleaning == '有料トイレ清掃あり' || n.toiletCleaning == 'トイレ掃除有') {
            nToiletT = n.toiletTimes
            nToiletP = n.toiletPlace
          }
          let data = {times: nt, hours: nh, specific: ns, holiday: nho, toiletTimes: nToiletT, toiletPlace: nToiletP, startTime: n.everydayStartTime, endTime: n.everydayEndTime}
          normal.push(data)
        }
      }
      return normal
    },

    /**
     * 管理員業務
     * @param selectedRequestPost 反映するRPのデータ
     */
    setMgt(selectedRequestPost) {
      let mgt = []
      if (selectedRequestPost.managerWorks.length) {
        for (let i = 0; i < selectedRequestPost.managerWorks.length; i++) {
          let m = selectedRequestPost.managerWorks[i]
          
          let ms = ''
          let mh = ''
          let mt = ''
          // 時間指定あり
          if (m.managerWorkTimeFlag[0] == '有') {
            ms = 'あり'
          // 時間指定なし
          } else if (m.managerWorkTimeFlag[0] == '無') {
            ms = 'なし'
          }
          mh = m.managerWorkHours + ' 時間'
          mt = '週 ' + m.managerWorkCount + ' 回'

          let mho = ''
            if (m.managerWorkHolidayFlag[0] == '無') {
              mho = 'なし'
            } else if (m.managerWorkHoliday[0] == '通常業務') {
              mho = 'FULL'
            } else if (m.managerWorkHoliday[0] == 'ゴミ出しのみ') {
              mho = 'ゴミ出しのみ'
            }
          let data = {times: mt, hours: mh, specific: ms, holiday: mho, startTime: m.managerWorkTime, endTime: m.managerWorkEndTime}
          mgt.push(data)
        }
      }
      return mgt
    },

    /**
     * リクエストポストのカテゴリーを編集
     * @param clean RPカテゴリー
     * @param category 対象カテゴリー名
     * @param target 対象カテゴリー内容
     */
    setCategoryName(clean, category, target) {
      
      // カテゴリー名を生成
      for (let j = 0; j < target.length; j++) {
        let name = ''
        name = category + (j+1)
        clean.splice(clean.indexOf(category) + j, 0, name)
      }
      clean.splice(clean.indexOf(category), 1)

      return clean
    },

    /**
     * 得意先名変更イベント
     */
    async changeClientName() {
      if (this.estimate.clientCode && this.estimate.clientCode != '') {
        await dialogs.showInfoDialog('得意先名', '全く別の得意先に変更する場合は、メインタスクを修正してから見積もり作成することをお勧めします。')
      }
    },

    /**
     * 特記事項の行を生成_初期設定
     */
    setNoteAndPurchaseInit() {
      // 見積りの行からグループ名のみの配列を作成
      let groupArr = []
      let nameArr = []
      let codeArr = []
      this.estimate.details.forEach((d) => {
        if (!groupArr.includes(d.groupName)) {
          groupArr.push(d.groupName)
          nameArr.push(d.categoryName)
          codeArr.push(d.categoryCode)
        }
      })

      // 特記事項_まだ生成されていないなら生成
      if (!this.estimate.noteList.length || (this.estimate.noteList.length == 1 && this.estimate.noteList[0].categoryName == '')) {
        this.estimate.noteList = []
        for (let i = 0; i < groupArr.length; i++) {
          this.estimate.noteList.push({
            categoryName: nameArr[i],
            groupName: groupArr[i],
            noteHTML: ''
          })
        }
      }

      // 発注明細_まだ生成されていないなら生成
      if (!this.estimate.purchase || !this.estimate.purchase.length || (this.estimate.purchase.length == 1 && this.estimate.purchase[0].categoryName == '')) {
        this.estimate.purchase = []
        for (let j = 0; j < groupArr.length; j++) {
          this.estimate.purchase.push({
            categoryCode: codeArr[j],
            categoryName: nameArr[j],
            groupName: groupArr[j],
            supplier: '',
            rate: '',
            price: ''
          })
        }
      }
      this.purchase = this.estimate.purchase
      
      // 手数料明細_まだ生成されていないなら生成
      if (!this.estimate.commission || !this.estimate.commission.length || (this.estimate.commission.length == 1 && this.estimate.commission[0].categoryName == '')) {
        this.estimate.commission = []
        for (let k = 0; k < groupArr.length; k++) {
          this.estimate.commission.push({
            categoryCode: codeArr[k],
            categoryName: nameArr[k],
            groupName: groupArr[k],
            estimatePrice: this.totalPrice2ByCategoryGrp(groupArr[k]),
            rate: '',
            commissionPrice: '',
            price: ''
          })
        }
      }
      this.commission = this.estimate.commission
    },

    /**
     * 紐づく特記事項の行がなければ作成
     * @param gName グループ名
     * @param name カテゴリー名
     * @param code カテゴリーコード
     */
    setNoteAndPurchase(gName, name, code) {
      // 定期清掃の場合、内容の合計行の名前を変更
      if (code == '5') {
        for (let i = 0; i < this.estimate.details.length; i++) {
          const ed = this.estimate.details[i]
          // 合計行の名前変更
          if (ed.categoryCode == '5' && ed.type == 'fixedCalc' && gName == ed.groupName) {
            let num = ed.itemName.indexOf('年額合計')
            let st = ed.itemName.substr(num)
            ed.itemName = name + st
          }
        }
      }
      // 見積りの行からグループ名のみの配列を作成
      let groupArr = []
      let nameArr = []
      let codeArr = []
      this.estimate.details.forEach((d) => {
        if (!groupArr.includes(d.groupName)) {
          groupArr.push(d.groupName)
          nameArr.push(d.categoryName)
          codeArr.push(d.categoryCode)
        }
      })

      // 特記事項_まだ生成されていないなら生成
      if (!this.estimate.noteList.length || (this.estimate.noteList.length == 1 && this.estimate.noteList[0].categoryName == '')) {
        this.estimate.noteList = []
        for (let i = 0; i < groupArr.length; i++) {
          this.estimate.noteList.push({
            categoryName: nameArr[i],
            groupName: groupArr[i],
            noteHTML: ''
          })
        }
      } else {
        let exist = false
        for (let i = 0; i < this.estimate.noteList.length; i++) {
          const n = this.estimate.noteList[i]
          // 引数と同じグループ名がある
          if (gName == n.groupName) {
            exist = true
            n.categoryName = name
            break
          }
        }

        // 特記事項に引数と同じグループ名がなかった場合
        if (!exist) {
          let index = groupArr.indexOf(gName)
          this.estimate.noteList.splice(index, 0, {categoryName: name, groupName: gName, noteHTML: ''})
        }
      }

      let groupInN = []
      // 見積り表になくて、特記事項にあるグループ名は省く
      for (let gIdxN = 0; gIdxN < groupArr.length; gIdxN++) {
        const checkNG = groupArr[gIdxN]
        for (let nA = 0; nA <  this.estimate.noteList.length; nA++) {
          const nG =  this.estimate.noteList[nA]
          if (checkNG == nG.groupName) {
            groupInN.push(nG)
            break
          }
        }
      }
      this.estimate.noteList = groupInN

      // 発注明細_まだ生成されていないなら生成
      if (!this.estimate.purchase || !this.estimate.purchase.length || (this.estimate.purchase.length == 1 && this.estimate.purchase[0].categoryName == '')) {
        this.estimate.purchase = []
        for (let i = 0; i < groupArr.length; i++) {
          this.estimate.purchase.push({
            categoryCode: codeArr[i],
            categoryName: nameArr[i],
            groupName: groupArr[i],
            supplier: '',
            rate: '',
            price: ''
          })
        }
      } else {
        let exist = false
        for (let i = 0; i < this.estimate.purchase.length; i++) {
          const p = this.estimate.purchase[i]
          // 引数と同じグループ名がある
          if (gName == p.groupName) {
            exist = true
            p.categoryName = name
            p.categoryCode = code
            break
          }
        }

        // 発注に引数と同じグループ名がなかった場合
        if (!exist) {
          let pre = groupArr.indexOf(gName) - 1
          let preGroup = groupArr[pre]
          let index = null
          // ラウンドプラスが複数行あるため挿入場所を確認
          for (let i = 0; i < this.estimate.purchase.length; i++) {
            const p = this.estimate.purchase[i]
            // 1行前のデータを見つける
            if (p.groupName == preGroup) {
              index = i
            }
          }
          index++
          this.estimate.purchase.splice(index, 0, {categoryCode: code, categoryName: name, groupName: gName, supplier: '', rate: '', price: ''})
        }
      }

      let groupInP = []
      // 見積り表になくて、発注表にあるグループ名は省く
      for (let gIdx = 0; gIdx < groupArr.length; gIdx++) {
        const checkPG = groupArr[gIdx]
        for (let pA = 0; pA <  this.estimate.purchase.length; pA++) {
          const pG =  this.estimate.purchase[pA]
          if (checkPG == pG.groupName) {
            groupInP.push(pG)
          }
        }
      }
      this.estimate.purchase = groupInP
      this.purchase = groupInP

      // 手数料明細_まだ生成されていないなら生成
      if (!this.estimate.commission || !this.estimate.commission.length || (this.estimate.commission.length == 1 && this.estimate.commission[0].categoryName == '')) {
        this.estimate.commission = []
        for (let i = 0; i < groupArr.length; i++) {
          this.estimate.commission.push({
            categoryCode: codeArr[i],
            categoryName: nameArr[i],
            groupName: groupArr[i],
            estimatePrice: '',
            rate: '',
            commissionPrice: '',
            price: ''
          })
        }
      } else {
        let exist2 = false
        for (let i = 0; i < this.estimate.commission.length; i++) {
          const c = this.estimate.commission[i]
          // 引数と同じグループ名がある
          if (gName == c.groupName) {
            exist2 = true
            c.categoryName = name
            c.categoryCode = code
            break
          }
        }

        // 発注に引数と同じグループ名がなかった場合
        if (!exist2) {
          let index2 = groupArr.indexOf(gName)
          this.estimate.commission.splice(index2, 0, {categoryCode: code, categoryName: name, groupName: gName, estimatePrice: '', rate: '', commissionPrice: '', price: ''})
        }
      }

      let groupInC = []
      // 見積り表になくて、手数料表にあるグループ名は省く
      for (let gIdx2 = 0; gIdx2 < groupArr.length; gIdx2++) {
        const checkCG = groupArr[gIdx2]
        for (let cA = 0; cA <  this.estimate.commission.length; cA++) {
          const cG =  this.estimate.commission[cA]
          if (checkCG == cG.groupName) {
            groupInC.push(cG)
            break
          }
        }
      }
      this.estimate.commission = groupInC
      this.commission = groupInC
    },

    /**
     * 同じグループ名の発注明細にデータを挿入
     * @param p 対象データ
     */
    insertPurchase(p) {
      // 新仕様のラウンドプラスの場合
      if (Array.isArray(p)) {
        // 同じグループ名の行を見つけ、行数をカウント
        let idx = null
        let count = 0
        for (let i = 0; i < this.estimate.purchase.length; i++) {
          const pur = this.estimate.purchase[i]
          if (pur.groupName == p[0].groupName) {
            count++
            if (idx == null) {
              idx = i
            }
          }
        }
        // 既存のラウンドプラスを削除
        this.estimate.purchase.splice(idx, count)
        
        // 同じ場所に挿入
        for (let i = 0; i < p.length; i++) {
          const pu = p[i]
          this.estimate.purchase.splice(idx, 0, pu)
          idx++
        }

      } else if (p && p.groupName) {
        for (let i = 0; i < this.estimate.purchase.length; i++) {
          let ep = this.estimate.purchase[i]
          if (ep.groupName == p.groupName) {
            this.estimate.purchase[i] = p
            break
          }
        }
      }
      this.purchase = this.estimate.purchase
      this.purchaseReload++
    },
    
    /**
     * 同じグループ名の手数料明細にデータを挿入
     * @param c 対象データ
     */
    insertCommission(c) {
      if (c && c.groupName) {
        for (let i = 0; i < this.estimate.commission.length; i++) {
          let ec = this.estimate.commission[i]
          if (ec.groupName == c.groupName) {
            this.estimate.commission[i] = c
            this.estimate.commission[i].rate = utils.hankaku(c.rate)
            break
          }
        }
        this.commission = this.estimate.commission
        this.commissionReload++
      }
    },

    /**
     * 紐づく特記事項の行を削除
     * @param gName グループ名
     */
    deleteNoteAndPurchase(gName) {
      // 特記事項
      let index = null
      for (let i = 0; i < this.estimate.noteList.length; i++) {
        const n = this.estimate.noteList[i];
        if (gName == n.groupName) {
          index = i
          break
        }
      }

      if (index != null && index >= 0) {
        this.estimate.noteList.splice(index, 1)
      }

      if (!this.estimate.noteList.length) {
        this.estimate.noteList = [{
          categoryName: '',
          groupName: '',
          noteHTML: ''
        }]
      }

      // 発注明細
      let index2 = null
      let count = 0
      // ラウンドプラスのみ複数行あり
      for (let j = 0; j < this.estimate.purchase.length; j++) {
        const p = this.estimate.purchase[j]
        if (gName == p.groupName) {
          if (!index2) {
            index2 = j
          }
          count++
          break
        }
      }

      // 発注明細
      if (index2 != null && index2 >= 0) {
        this.estimate.purchase.splice(index2, count)
      }

      // 手数料明細
      let index3 = null
      for (let k = 0; k < this.estimate.commission.length; k++) {
        const c = this.estimate.commission[k]
        if (gName == c.groupName) {
          index3 = k
          break
        }
      }
      // 手数料明細
      if (index3 != null && index3 >= 0) {
        this.estimate.commission.splice(index3, 1)
      }

      // 発注明細
      if (!this.estimate.purchase.length) {
        this.estimate.purchase = [{
          categoryCode: '',
          categoryName: '',
          groupName: '',
          supplier: '',
          rate: '',
          price: ''
        }]
      }

      // 手数料明細
      if (!this.estimate.commission.length) {
        this.estimate.commission = [{
          categoryCode: '',
          categoryName: '',
          groupName: '',
          estimatePrice: '',
          rate: '',
          commissionPrice: '',
          price: ''
        }]
      }
      this.purchase = this.estimate.purchase
      this.commission = this.estimate.commission
      this.purchaseReload++
      this.commissionReload++
    },

    /**
     * 特記事項内容をセット
     * @param groupName グループ名
     * @param text 特記事項内容
     */
    setNoteText(groupName, text) {
      for (let i = 0; i < this.estimate.noteList.length; i++) {
        let target = this.estimate.noteList[i]
        if (target.groupName == groupName) {
          target.noteHTML = text
          break
        }
      }
    },

    requestPostNameChanged(requestPostName) {
      if (requestPostName === '' && this.$route.query.taskRequestId) {
        return
      }
      if (requestPostName === '') {
        this.estimate.requestPostId = ''
        this.estimate.requestPostName = ''
      }
    },

    /**
     * 見積りコピー
     * @param copyEstimateNo コピー元の見積りNo
     */
    async copyEstimate(copyEstimateNo) {
      const copiedEstimate = await estimateManager.copyEstimate(this, copyEstimateNo)
      if (copiedEstimate) {
        this.estimate = copiedEstimate
        this.setClassificationType()
        
        // 発注明細がない場合
        if (!this.estimate.purchase) {
          // this.estimate.purchase = []
          this.setNoteAndPurchaseInit()
        } else {
          this.purchase = this.estimate.purchase
        }
        await this.getMain(this.estimate)
      } else {
        await dialogs.showErrorDialog('見積りのコピー', '見積りをコピーできませんでした。')
      }
    },

    /**
     * 編集したか判定
     */
    isDirty() {
      let result = utils.isEquals(utils.toPlainObject(this.originalEstimate), utils.toPlainObject(this.estimate))
      return !result
    },

    /**
     * 見積リストへ遷移
     */
    async estimateList() {
      this.$router.push({ name: 'EstimateList' })
    },

    /**
     * 添付資料変更イベント
     * @param targetAttachmentName プロパティー名
     * @param attachmentInfo 添付資料
     */
    attachmentChange(targetAttachmentName, attachmentInfo) {
      // 変更があった場合、メインから引継ぎした内容は破棄
      delete this.estimate[targetAttachmentName + '_file_content']
      delete this.estimate[targetAttachmentName + '_display_file_name']
      delete this.estimate[targetAttachmentName + '_file_name']

      if (!this.estimate[targetAttachmentName]) {
        this.estimate[targetAttachmentName] = estimateManager.createEmptyAttachment()
      }
      if (attachmentInfo) {
        this.estimate[targetAttachmentName].originalName = attachmentInfo.filename
        this.estimate[targetAttachmentName].content = attachmentInfo.content
      } else {
        this.estimate[targetAttachmentName] = estimateManager.createEmptyAttachment()
      }
    },

    /**
     * 明細を引用イベント
     */
    quoteOtherEstimateDetail() {
      this.detailSelectorShow = true
    },

    /**
     * 明細を引用イベントで追加データ決定後イベント
     */
    async applyOtherEstimateDetails(val) {
      // 明細
      const quoteDetails = val.selectedDetails
      // メモ・承認者へのメッセージ
      const memo = val.memo

      // 明細がある場合
      if (quoteDetails && quoteDetails.details && quoteDetails.details.length) {
        // 選択したデータ
        const details = quoteDetails.details
        // 発注
        const purchase = quoteDetails.purchase
        // 手数料
        const commission = quoteDetails.commission

        // 現在の見積り表の最後のインデックス
        // let index = this.estimate.details.length

        for (let detailIndex = 0; detailIndex < details.length; detailIndex++) {
          const detail = details[detailIndex]

          this.estimate.details.push(detail)

          this.setNoteAndPurchase(detail.groupName, detail.categoryName, detail.categoryCode)
          for (let pI = 0; pI < purchase.length; pI++) {
            const p = purchase[pI]
            this.insertPurchase(p)
          }
          for (let cI = 0; cI < commission.length; cI++) {
            const c = commission[cI]
            this.insertCommission(c)
          }

          if (detail.subItemModalData) {
            if (detail.subItemModalData.noteHTML) {
              this.setNoteText(detail.groupName, detail.subItemModalData.noteHTML)
            }
          }
        }

        this.purchaseReload++
        this.commissionReload++

        this.estimate.details.splice()

        this.$refs.estimateDetailInput.refresh()

        this.detailSelectorShow = false

        this.calculate(null, null)
        await this.getDiffDetails()
      }

      // メモ・承認者へのメッセージを選択している場合
      if (memo) {
        let kaigyou = ''
        if (this.estimate.memo) {
          kaigyou = '\n'
        }
        this.estimate.memo += kaigyou + memo
      }
    },

    /**
     * リクエストポスト依頼選択イベント
     */
    selectRequestPost() {
      this.dataSelectorParams = {
        title: 'サブタスクの選択',
        subtitle: '',
        selectCallback: (selectedRequestPost) => {
          this.dataSelectorShow = false
          this.requestPostSelected(selectedRequestPost)
        }
      }
      this.dataSelectorShow = true
    },

    /**
     * リクエストポスト依頼の候補リスト取得
     */
    async getTaskList() {
      // リクエストポストのstatusがopenのみ取得
      let rp = await requestPostManager.searchByStatus(this, 'open')

      let tr = []
      let ids = []
      // 取得したリクエストポストIDに紐づくタスク依頼を取得(statusはopenのみ、requestTaskに「見積」が含まれるもの)
      for (let i = 0; i < rp.length; i++) {
        ids.push(rp[i]._id)
      }
      tr = await taskRequestManager.getByRequestPostIdAndStatus(this, ids, 'open', '見積')

      let result = []
      // タスク依頼とリクエストポストを結合（タスク1件につき1レコード）
      for (let i = 0; i < tr.length; i++) {
        for (let j = 0; j < rp.length; j++) {
          if (tr[i].requestPostId == rp[j]._id) {
            rp[j]['requestPostId'] = rp[j]._id
            rp[j]['requestPostSalesPersonName'] = rp[j].salesPersonName
            rp[j]['departmentIdMain'] = rp[j].toDepartmentId
            tr[i]['taskRequestId'] = tr[i]._id
            tr[i]['taskLimit'] = tr[i].limitDate
            tr[i]['taskStaffId'] = tr[i].toStaffId
            tr[i]['taskStaff'] = tr[i].toStaffName
            tr[i]['departmentIdSubTask'] = tr[i].departmentId
            tr[i]['taskEstimationCleanCategory'] = tr[i].estimationCleanCategory
            tr[i]['taskEstimationInspectCategory'] = tr[i].estimationInspectCategory
            tr[i]['taskEstimationOtherCategory'] = tr[i].estimationOtherCategory
            tr[i]['taskCategory'] = (tr[i].estimationCleanCategory.concat(tr[i].estimationInspectCategory).concat(tr[i].estimationOtherCategory)).join(', ')

            result.push({...rp[j], ...tr[i]})
            break
          }
        }
      }

      // タスク依頼の期限日でソート
      let sort = result.sort(function(a, b) {
        return (a.taskLimit < b.taskLimit) ? -1 : 1;
      });
      this.taskList = sort
    },

    /**
     * リクエストポスト依頼 進捗一覧から遷移した際のデータ取得
     */
    async getDataByTaskId(requestId , taskId) {
      // リクエストポストをrequestIdで取得
      let rp = await requestPostManager.getRequestPost(this, requestId)

      // タスク依頼をtaskIdで取得
      let tr = await taskRequestManager.get(this, taskId)

      let result = {}
      // タスク依頼とリクエストポストを結合
      if (tr.requestPostId == rp._id) {
        rp['requestPostId'] = rp._id
        rp['requestPostSalesPersonName'] = rp.salesPersonName
        rp['departmentIdMain'] = rp.toDepartmentId
        rp['mainEstimationCleanCategory'] = rp.estimationCleanCategory
        tr['taskRequestId'] = tr._id
        tr['taskLimit'] = tr.limitDate
        tr['taskStaffId'] = tr.toStaffId
        tr['taskStaff'] = tr.toStaffName
        tr['departmentIdSubTask'] = tr.departmentId
        tr['taskEstimationCleanCategory'] = tr.estimationCleanCategory
        tr['taskEstimationInspectCategory'] = tr.estimationInspectCategory
        tr['taskEstimationOtherCategory'] = tr.estimationOtherCategory
        tr['taskCategory'] = (tr.estimationCleanCategory.concat(tr.estimationInspectCategory).concat(tr.estimationOtherCategory)).join(', ')
        result = {...rp, ...tr}
      }
      this.taskData = result
      return result
    },

    /**
     * 列の並び替えイベント
     * （数量2、単価2の列の並び替え）
     */
    columnDrag(num) {
      this.$refs.estimateDetailInput.columnDrag(num)
    },

    /**
     * 複写の場合、変更されたデータをコピーに反映
     */
    async remakeData() {
      this.lightingTab = false
      let task = await this.getDataByTaskId(this.estimate.requestPostId, this.estimate.taskRequestId)

      if (task) {
        // メモの書き換え
        this.setMemo(task)
        
        let copyB = this.estimate.buildingInfo
        // 見積り金額に関わる項目が変更されていれば、該当箇所のコピーは削除

        // オーナー契約が変わっていれば、全て変更
        if (copyB.owner != task.owner) {
          await this.requestPostSelected(task)
          return
        }

        // 物件の住所
        if (copyB.address.state != task.state || copyB.address.city != task.city || copyB.address.street != task.street) {
          let oldNEria = await eriaManager.getEria(this, copyB.address, 'normal')
          let newNEria = await eriaManager.getEria(this, {state: task.state, city: task.city, street: task.street}, 'normal')
          // 日常・管理エリアが違う場合
          if (oldNEria != newNEria) {
            this.deleteTarget('2', 'ラウンドクリーンプラス')
            this.deleteTarget('2', 'ラウンドダブルプラス')

            let target = ['3', '4']
            for (let i = 0; i < target.length; i++) {
              const t = target[i];
              this.deleteTarget(t, null)
            }
          }

          let oldFEria = await eriaManager.getEria(this, copyB.address, 'fixed')
          let newFEria = await eriaManager.getEria(this, {state: task.state, city: task.city, street: task.street}, 'fixed')
          // 定期清掃エリアが違う場合
          if (oldFEria != newFEria) {
            this.deleteTarget('2', 'ラウンドウォッシュプラス')
            this.deleteTarget('2', 'ラウンドダブルプラス')

            let target = ['5', '12']
            for (let i = 0; i < target.length; i++) {
              const t = target[i];
              this.deleteTarget(t, null)
            }
          }

          this.estimate.buildingName = task.buildingName
          this.estimate.buildingAddress = task.state + task.city + task.street + task.building
          this.estimate.buildingAddress2 = task.state + task.city + task.street
          copyB.address.state = task.state
          copyB.address.city = task.city
          copyB.address.street = task.street
        }

        // 戸数
        if (copyB.households != task.households) {
          let target = ['1', '2', '6', '7', '8', '9', '11']
          for (let i = 0; i < target.length; i++) {
            const t = target[i];
            this.deleteTarget(t, null)
          }
          this.isLightingTab()

          // 単独発注＆300㎡越えor不明の場合
          if (this.isMetreOut('3')) {
            this.deleteTarget('3', null)
          }
          if (this.isMetreOut('4')) {
            this.deleteTarget('4', null)
          }

          copyB.households = task.households
        }

        // 階数
        if (copyB.stairs != task.stairs) {
          // 単独発注＆300㎡越えor不明の場合
          if (this.isMetreOut('3')) {
            this.deleteTarget('3', null)
          }
          if (this.isMetreOut('4')) {
            this.deleteTarget('4', null)
          }

          copyB.stairs = task.stairs
        }

        // タイプ
        if (task.residenceType[0]) {
          if (copyB.residenceType != task.residenceType[0]) {
            let target = ['1', '6', '7', '8', '9']
            if (copyB.residenceType == 'シングル' || task.residenceType[0] == 'シングル') {
              
              for (let i = 0; i < target.length; i++) {
                const t = target[i];
                this.deleteTarget(t, null)
              }
              // 単独発注＆300㎡越えor不明の場合
              if (this.isMetreOut('3')) {
                this.deleteTarget('3', null)
              }
              if (this.isMetreOut('4')) {
                this.deleteTarget('4', null)
              }

              this.isLightingTab()

            // ファミリーと混合の書き換え（金額に影響しないため、表示を変更）
            } else {
              let type = ''
              if (task.residenceType[0] == 'ファミリー') {
                type = '2'
              } else if (task.residenceType[0] == '混合') {
                type = '3'
              }

              for (let index = 0; index < this.estimate.details.length; index++) {
                const e = this.estimate.details[index];
                if (target.includes(e.categoryCode)) {
                  // 選択した値
                  if (e.subItemModalData.selectedValue) {
                    e.subItemModalData.selectedValue[1] = type
                  }

                  // ツールチップ
                  if (e.subItemModalData.toolTip) {
                    if (e.listForTooltip.indexOf(e.subItemModalData.toolTip['タイプ']) != -1) {
                      e.listForTooltip.replace(e.subItemModalData.toolTip['タイプ'], task.residenceType[0])
                    }
                    e.subItemModalData.toolTip['タイプ'] = task.residenceType[0]
                  }
                } else if (['3', '4'].includes(e.categoryCode)) {
                  // 選択した値
                  if (e.subItemModalData.modalTabData) {
                    for (let j = 0; j < e.subItemModalData.modalTabData.length; j++) {
                      const mt = e.subItemModalData.modalTabData[j];
                      if (mt.data) {
                        if (mt.data.selectedValue) {
                          mt.data.selectedValue[12] = type
                        }
                        if (mt.data.toolTip) {
                          mt.data.toolTip['タイプ'] = task.residenceType[0]
                        }
                      }
                    }
                  }
                }
              }
            }
            copyB.residenceType = task.residenceType[0]
          }
        } else {
          if (copyB.residenceType != undefined && copyB.residenceType != null && copyB.residenceType != '') {
            let target = ['1', '6', '7', '8', '9']
            for (let i = 0; i < target.length; i++) {
                const t = target[i];
                this.deleteTarget(t, null)
              }
              // 単独発注＆300㎡越えor不明の場合
              if (this.isMetreOut('3')) {
                this.deleteTarget('3', null)
              }
              if (this.isMetreOut('4')) {
                this.deleteTarget('4', null)
              }

              this.isLightingTab()

              copyB.residenceType = ''
          }
        }

        // 駐車場
        if (copyB.parking != this.setParking(task)) {
          let target = ['5', '12']
          for (let i = 0; i < target.length; i++) {
            const t = target[i];
            this.deleteTarget(t, null)
          }
          
          this.deleteTarget('2', 'ラウンドウォッシュプラス')
          this.deleteTarget('2', 'ラウンドダブルプラス')

          copyB.parking = this.setParking(task)
        }

        // トイレ使用
        if (copyB.availableToilet != this.setToilet(task)) {
          this.deleteTarget('3', null)
        }

        // トイレ清掃の回数・箇所
        if (this.isToiletClean(task, 'rlsToiletCleaning')) {
          if (copyB.rlsToiletTimes != task.rlsToiletTimes || copyB.rlsToiletPlace != task.rlsToiletPlace) {
            this.deleteTarget('1', null)

            copyB.rlsToiletTimes = task.rlsToiletTimes
            copyB.rlsToiletPlace = task.rlsToiletPlace
          }
        } else {
          if (copyB.rlsToiletTimes != '0' || copyB.rlsToiletPlace != '0') {
            this.deleteTarget('1', null)

            copyB.rlsToiletTimes = '0'
            copyB.rlsToiletPlace = '0'
          }
        }

        if (this.isToiletClean(task, 'plusToiletCleaning')) {
          if (copyB.plusToiletTimes != task.plusToiletTimes || copyB.plusToiletPlace != task.plusToiletPlace) {
            this.deleteTarget('2', null)

            copyB.plusToiletTimes = task.plusToiletTimes
            copyB.plusToiletPlace = task.plusToiletPlace
          }
        } else {
          if (copyB.plusToiletTimes != '0' || copyB.plusToiletPlace != '0') {
            this.deleteTarget('2', null)

            copyB.plusToiletTimes = '0'
            copyB.plusToiletPlace = '0'
          }
        }

        // ゴミ出し
        if (copyB.rlsTrash != this.setTrash(task, 'dustRemoval', 'dustCount')) {
          this.deleteTarget('1', null)

          copyB.rlsTrash = this.setTrash(task, 'dustRemoval', 'dustCount')
        }
        
        if (copyB.rlsPlusTrash != this.setTrash(task, 'plusDustRemoval', 'plusDustCount')) {
          this.deleteTarget('2', null)

          copyB.rlsPlusTrash = this.setTrash(task, 'plusDustRemoval', 'plusDustCount')
        }

        // 清掃回数
        if (copyB.rlsCleaningTimes != this.setClean(task, 'cleanCount')) {
          this.deleteTarget('1', null)

          copyB.rlsCleaningTimes = this.setClean(task, 'cleanCount')
        }
        
        if (copyB.plusCleaningTimes != this.setClean(task, 'plusCleanCount')) {
          this.deleteTarget('2', null)

          copyB.plusCleaningTimes = this.setClean(task, 'plusCleanCount')
        }

        // 日常清掃
        if (copyB.normal.length) {
          let n = this.setNormal(task)
          if (copyB.normal.length != n.length) {
            this.deleteTarget('3', null)
          }
          for (let i = 0; i < n.length; i++) {
            const e = n[i];
            if (!_.isEqual(copyB.normal[i], e)) {
              this.deleteTarget('3', null)
              break
            }
          }
        }

        // 管理員
        if (copyB.mgt.length) {
          let m = this.setMgt(task)
          if (copyB.mgt.length != m.length) {
            this.deleteTarget('4', null)
          } else {
            for (let i = 0; i < m.length; i++) {
              const e = m[i];
              if (!_.isEqual(copyB.mgt[i], e)) {
                this.deleteTarget('4', null)
                break
              }
            }
          }
        }

        // 定期
        if (this.estimate.isChangeRegular) {
          this.deleteTarget('5', null)
        }
        console.log(this.estimate.isChangeRegular)

        // ガラス清掃
        if (copyB.glassTimes != task.glassCleanCount) {
          this.changeCategoryQty('10', task.glassCleanCount, null)

          copyB.glassTimes = task.glassCleanCount
        }

        // 植栽
        if (copyB.plantingTimes) {
          if (copyB.plantingTimes != task.plantingCount) {
            this.changeCategoryQty('13', task.plantingCount, null)

            copyB.plantingTimes = task.plantingCount
          }
        } else {
          let t = this.findCategory('13')
          if (t && t.length) {
            for (let i = 0; i < t.length; i++) {
              const e = t[i];
              if (e.categoryQty != task.plantingCount) {
                this.changeCategoryQty('13', task.plantingCount, null)

                copyB.plantingTimes = task.plantingCount
                break
              }
            }
          }
        }

        // 特別清掃
        if (this.estimate.isChangeSp) {
          this.deleteTarget('14', null)
        }

        // EV点検
        if (task.evQty && task.evQty != '') {
          let ev = this.findCategory('19')
          if (ev && ev.length) {
            this.changeCategoryQty('19', task.evQty, task.evUnitName)
          }
        }

        // ラウンドサブ
        if (this.estimate.isChangeSubs.length) {
          for (let i = 0; i < this.estimate.isChangeSubs.length; i++) {
            const sub = this.estimate.isChangeSubs[i];
            if (sub.code != '' && sub.delete) {
              this.deleteTarget(sub.code, null)
            } else if (sub.code != '' && !sub.delete) {
              this.changeSub(sub.code, sub.qty, sub.uName)
            }
          }
        }

        // その他作業
        if (this.estimate.isChangeOther.length) {
          for (let i = 0; i < this.estimate.isChangeOther.length; i++) {
            const ot = this.estimate.isChangeOther[i];
            if (ot.code != '' && ot.delete) {
              this.deleteTarget(ot.code, null)
            } else if (ot.code != '' && !ot.delete) {
              this.changeSub(ot.code, ot.qty, ot.uName)
            }
          }
        }

        // タスクのカテゴリーを連結＆生成
        if (task.taskEstimationCleanCategory.length || task.taskEstimationInspectCategory.length || task.estimationOtherCategory.length) {
          let arr = this.setCategoryMenu(task)

          let otherValue = ''
          if (this.setOther(arr) == '単独') {
            otherValue = '0'
          } else if (this.setOther(arr) == '他あり') {
            otherValue = '1'
          }

          // 単独発注
          let normalO = this.findCategory('3')
          if (normalO && normalO.length) {
            for (let i = 0; i < normalO.length; i++) {
              const e = normalO[i];
              if (e.modalTabData && e.modalTabData.length) {
                for (let j = 0; j < e.modalTabData.length; j++) {
                  const m = e.modalTabData[j];
                  if (m.data && m.data.selectedValue) {
                    if (m.data.selectedValue[10]) {
                      if (m.data.selectedValue[10] != otherValue) {
                        this.deleteTarget('3', null)
                      }
                    }
                  }
                }
              }
            }
          }

          let mgtO = this.findCategory('4')
          if (mgtO && mgtO.length) {
            for (let i = 0; i < mgtO.length; i++) {
              const e = mgtO[i];
              if (e.modalTabData && e.modalTabData.length) {
                for (let j = 0; j < e.modalTabData.length; j++) {
                  const m = e.modalTabData[j];
                  if (m.data && m.data.selectedValue) {
                    if (m.data.selectedValue[10]) {
                      if (m.data.selectedValue[10] != otherValue) {
                        this.deleteTarget('4', null)
                      }
                    }
                  }
                }
              }
            }
          }
          // 大項目マスター取得
          const list = this.$store.getters.estimateCategoryList

          // コピーのデータに同じカテゴリーコードが存在するか確認
          let cCode = []
          for (let j = 0; j < this.estimate.details.length; j++) {
            const d = this.estimate.details[j];
            if (!cCode.includes(d.categoryCode)) {
              cCode.push(d.categoryCode)
            }
          }

          // タスクのカテゴリーコード配列
          let newCCode = []

          for (let index = 0; index < arr.length; index++) {
            
            let target = {}
            let emptyEstimateDetail = estimateManager.createEmptyDetail()
            // カテゴリーコード 初期値は空文字
            emptyEstimateDetail.categoryCode = ''

            // カテゴリー名からマスタデータ取得
            target =  list.find((l) => {
              if (l.categoryName == arr[index].replace(/[0-9]/g, '')) {
                return true
              }
            })

            // カテゴリー名
            emptyEstimateDetail.categoryName = arr[index]

            if (cCode.length && target) {
              // カテゴリーコード
              emptyEstimateDetail.categoryCode = target.categoryCode
              newCCode.push(target.categoryCode)

              // カテゴリーの単位をセット
              emptyEstimateDetail.categoryUnitName = target.unitName
              // カテゴリーの数量をセット
              emptyEstimateDetail.categoryQty = target.qty
              // 並べ替えで使用するグループ名
              emptyEstimateDetail.groupName = emptyEstimateDetail.categoryName + Math.floor(Math.random()*90000) + 10000


              // 対象のカテゴリーコードがない場合、作成
              if (!cCode.includes(target.categoryCode)) {
                // 日常
                if (target.categoryCode == '3') {
                  copyB.normal = this.setNormal(task)
                  this.setDetailData(emptyEstimateDetail, copyB.normal)
                  continue
                
                // 管理
                } else if (target.categoryCode == '4') {
                  copyB.mgt = this.setMgt(task)
                  this.setDetailData(emptyEstimateDetail, copyB.mgt)
                  continue

                // 定期
                } else if (target.categoryCode == '5') {
                  for (let i = 0; i < task.regularCleans.length; i++) {
                    // グループ名 生成
                    emptyEstimateDetail.groupName = emptyEstimateDetail.categoryName + Math.floor(Math.random()*90000) + 10000
                    const r = task.regularCleans[i]
                    this.setDetailData(emptyEstimateDetail, [r])
                  }
                  continue

                // ガラス清掃リクエストポストの希望回数を入れる
                } else if (target.categoryCode == '10') {
                  emptyEstimateDetail.categoryQty = task.glassCleanCount

                // 植栽剪定リクエストポストの希望回数を入れる
                } else if (target.categoryCode == '13') {
                  emptyEstimateDetail.categoryQty = task.plantingCount

                // EV点検 リクエストポスト希望回数、単位を入れる
                } else if (target.categoryCode == '19') {
                  emptyEstimateDetail.categoryQty = task.evQty
                  emptyEstimateDetail.categoryUnitName = task.evUnitName

                // 特別清掃
                } else if (target.categoryCode == '14') {
                  this.setSp(task, emptyEstimateDetail, arr[index]) 
                  continue

                // ラウンドサブメニュー
                } else if ((['6', '7', '8', '9'].includes(emptyEstimateDetail.categoryCode) || emptyEstimateDetail.categoryCode == '') && task.roundSubs.length) {
                  let r = task.roundSubs
                  for (let j = 0; j < r.length; j++) {
                    const d = emptyEstimateDetail
                    if (d.categoryName == r[j].roundSubContent) {
                      
                      if (emptyEstimateDetail.categoryCode == '') {
                        emptyEstimateDetail.categoryCode == 'rlsSub'
                      }
                      
                      emptyEstimateDetail.categoryQty = r[j].roundSubCount
                      emptyEstimateDetail.categoryUnitName = r[j].roundSubUnitName
                      r.splice(j, 1)
                    }
                  }

                // その他作業あり
                } else if(['23', '24', '25', '26'].includes(emptyEstimateDetail.categoryCode) && task.otherWorks.length) {
                  let o = task.otherWorks
                  for (let j = 0; j < o.length; j++) {
                    if (o[j].otherWorkContent == arr[index]) {
                      emptyEstimateDetail.categoryQty = o[j].otherWorkCount
                      emptyEstimateDetail.categoryUnitName = o[j].otherWorkUnitName
                      o.splice(j, 1)
                      break
                    }
                  }
                }

                // 内容が空以外をpush
                if (Object.keys(emptyEstimateDetail).length && !['23', '24', '25', '26'].includes(emptyEstimateDetail.categoryCode)) {
                  this.estimate.details.push(emptyEstimateDetail)
                }
              }

            // コピーに何も残っていない
            } else if (!cCode.length) {
              this.requestPostSelected(task)
              return
            }
          }

          // コピー元にあるが、新タスクにはないカテゴリーコードの行は削除
          // for (let a = 0; a < cCode.length; a++) {
          //   let check = false
          //   const oldC = cCode[a];
          //   for (let b = 0; b < newCCode.length; b++) {
          //     const newC = newCCode[b];
          //     if (oldC == newC) {
          //       check = true
          //     }
          //   }
          //   if (!check) {
          //     this.deleteTarget(oldC, null)
          //   }
          // }
        }
      }
      // 発注明細がない場合
      if (!this.estimate.purchase) {
        this.setNoteAndPurchaseInit()
      } else {
        this.purchase = this.estimate.purchase
      }
      
      this.calculate(null, null)
    },

    /**
     * 見積りから対象のデータを削除
     * @param categoryCode カテゴリーコード
     */
    deleteTarget(categoryCode, categoryName) {
      let gName = ''
      // 見積り詳細から削除
      for (let i = this.estimate.details.length-1; i >= 0; i--) {
        const e = this.estimate.details[i]
        if (e.categoryCode == categoryCode) {
          if (categoryName) {
            if (utils.hankaku(e.categoryName).indexOf(utils.hankaku(categoryName)) != -1) {
              gName = e.groupName
              this.estimate.details.splice(i, 1)
            }
          } else {
            gName = e.groupName
            this.estimate.details.splice(i, 1)
          }
        }
      }

      // 特記事項から削除
      this.deleteNoteAndPurchase(gName)
    },

    /**
     * 複写の場合、照明器具清掃の「箇所数不明」の確認が必要か判定
     */
    isLightingTab() {
      if (this.lightingTab) {
        return
      }
      for (let i = 0; i < this.estimate.details.length; i++) {
        const e = this.estimate.details[i]
        if (e.categoryCode == '14') {
          if (e.subItemModalData.display) {
            for (let j = 0; j < e.subItemModalData.display.length; j++) {
              const d = e.subItemModalData.display[j];
              if (d.lightingTab) {
                this.lightingTab = true
                return
              }
            }
          }
        }
      }
    },

    /**
     * 単独発注かつ300㎡超えor不明か判定
     */
    isMetreOut(categoryCode) {
      let result = false
      for (let i = 0; i < this.estimate.details.length; i++) {
        const e = this.estimate.details[i];
        if (e.categoryCode == categoryCode) {
          if (e.subItemModalData.modalTabData) {
            for (let j = 0; j < e.subItemModalData.modalTabData.length; j++) {
              const d = e.subItemModalData.modalTabData[j].data;
              if (d.selectedValue) {
                if (d.selectedValue[10] == '0' && d.selectedValue[11] == '3') {
                  result = true
                  return result
                }
              }
            }
          }
        }
      }
      return result
    },

    /**
     * 該当のカテゴリーコードがあればその詳細データ返す
     * @param categoryCode 対象カテゴリーコード
     */
    findCategory(categoryCode) {
      let result = []
      for (let i = 0; i < this.estimate.details.length; i++) {
        const e = this.estimate.details[i];
        if (e.categoryCode == categoryCode) {
          result.push(e)
        }
      }
      return result
    },

    /**
     * カテゴリーの数量を変更
     * @param categoryCode 該当カテゴリーコード
     * @param qty 数量
     * @param unitName 単位
     */
    changeCategoryQty(categoryCode, qty, unitName) {
      for (let i = 0; i < this.estimate.details.length; i++) {
        const e = this.estimate.details[i];
        if (e.categoryCode == categoryCode && e.rowspan != -1) {
          e.categoryQty = qty
          if (unitName) {
            e.categoryUnitName = unitName
          }
        }
      }
    },

    /**
     * カテゴリーの数量を変更
     * @param categoryCode 該当カテゴリーコード
     * @param qty 数量
     * @param uName 単位
     */
    changeSub(categoryCode, qty, uName) {
      for (let i = 0; i < this.estimate.details.length; i++) {
        const e = this.estimate.details[i];
        if (e.categoryCode == categoryCode && e.rowspan != -1) {
          
          if (qty != undefined && qty != null) {
            e.categoryQty = qty
          }
          if (uName != undefined && uName != null) {
            e.categoryUnitName = uName
          }
        }
      }
    },

    /**
     * サブタスクのカテゴリーを生成
     * @param selectedRequestPost サブタスク
     */
    setCategoryMenu(selectedRequestPost) {
      let arr = []
      // ラウンドサブメニューを取得
        let sub = []
        if (selectedRequestPost.taskEstimationCleanCategory.length && selectedRequestPost.taskEstimationCleanCategory.includes('ラウンドサブメニュー')) {
          sub = selectedRequestPost.roundSubs.map((r) => {
            return r.roundSubContent
          })

          // カテゴリーからラウンドサブメニューを削除
          let cleanArr = []
          cleanArr = selectedRequestPost.taskEstimationCleanCategory.filter((t) => {
            return t != 'ラウンドサブメニュー'
          })
          // ラウンドサブメニューの項目を結合
          selectedRequestPost.taskEstimationCleanCategory = cleanArr.concat(sub)
        }
        
        // その他作業の詳細を取得
        let other = []
        if (selectedRequestPost.estimationOtherCategory.length) {
          other = selectedRequestPost.otherWorks.map((o) => {
            return o.otherWorkContent
          })
        }

        // 全てのカテゴリーを連結
        arr = selectedRequestPost.taskEstimationCleanCategory.concat(selectedRequestPost.taskEstimationInspectCategory).concat(other)

        // 特別清掃を取得
        if (arr.includes('各種特別清掃') || arr.includes('特別清掃')) {
          arr = this.setCategoryName(arr, '特別清掃', selectedRequestPost.specialCleans)
        }

        // 文言変更前のカテゴリーを新しい文言に変更
        for (let i = 0; i < arr.length; i++) {
          const a = arr[i]
          if (a == '消防点検') {
            arr[i] = '消防設備点検'
          } else if (a == '建築設備点検') {
            arr[i] = '建築設備定期検査'
          } else if (a == '特定建築物定期検査') {
            arr[i] = '特定建築物定期調査'
          }
        }

        return arr
    },

    /**
     * 単独発注判定
     * @param arr カテゴリー
     */
    setOther(arr) {
      let result = ''
      if (arr.length > 1) {
        result = '他あり'
      } else if (arr.length == 1) {
        result = '単独'
      }
      return result
    },

    /**
     * 特別清掃をセット
     * @param selectedRequestPost サブタスク
     * @param emptyEstimateDetail 生成した見積りデータ
     * @param arr カテゴリー
     * 
     */
    setSp(selectedRequestPost, emptyEstimateDetail, arr) {
      // 中項目取得
      let val = this.$store.getters.estimateSubItemList.filter((s) => {
        return s.parentCode == '14'
      })
      let modalDisp = []
        
      let empty = estimateManager.createEmptyDetail()
      let sp = selectedRequestPost.specialCleans

      empty.categoryName = emptyEstimateDetail.categoryName
      empty.categoryCode = emptyEstimateDetail.categoryCode
      empty.categoryQty = sp[Number(arr.replace(/[^0-9]/g, ''))-1].specialCleanCount
      empty.categoryUnitName = sp[Number(arr.replace(/[^0-9]/g, ''))-1].specialCleanUnitName
      empty.itemName = sp[Number(arr.replace(/[^0-9]/g, ''))-1].specialCleanContent
      empty.groupName = empty.categoryName + Math.floor(Math.random()*90000) + 10000
      // オーナー契約
      if (this.estimate.buildingInfo.owner == 'あり') {
        empty.subItemModalData['spOwner'] = '1'
      } else {
        empty.subItemModalData['spOwner'] = '0'
      }
      // 竣工係数
      if (this.estimate.buildingInfo.finishPoint) {
        empty.subItemModalData['spFinishPoint'] = this.estimate.buildingInfo.finishPoint
      } else {
        empty.subItemModalData['spFinishPoint'] = '0'
      }

      empty.subItemModalData['display'] = []
      empty.subItemModalData['conf'] = {}
      empty.subItemModalData['selectedValue'] = []
      empty.subItemModalData['toolTip'] = {}
      empty.subItemModalData['price'] = {}
      empty.subItemModalData['modalTabData'] = []
      empty.subItemModalData['noteHTML'] = ''

      if (empty.itemName == 'エアコンフィルター') {
        empty.itemName = 'エアコンフィルター清掃'
      }

      let v = val.find((v) => {
        return v.name == empty.itemName
      })
      
      let display = {
        house: this.estimate.buildingInfo.households,
        lightingUnit: '',
        name: empty.itemName,
        number: '',
        price: '',
        qty: '',
        spFlag: false,
        spModalValue: '',
        unitName: ''
      }
      
      empty.subItemModalData['display'].push(display)
      let d = empty.subItemModalData['display'][0]

      // 中項目にあった場合
      if (v) {
        d.name = v.name
        d.number = Number(v.order)
        if (v.selectValue != '') {
          d.spFlag = true
        } else {
          d.spFlag = false
        }

        // 単価表から単価を取得
        const priceCode = sp[Number(arr.replace(/[^0-9]/g, ''))-1].specialCleanContentId
        let list = this.$store.getters.estimateItemPriceList.filter((l) => {
          // 選択肢がないものは単価を取得。駐輪場清掃・ルーバーは選択肢があるから取得しない
          if (l.parentCode == '14' && l.priceCode == priceCode && !['bicycle', 'louver'].includes(priceCode)) {
            return true
          }
        })

        // 単価表にあった場合
        if (list.length) {

          // オーナー契約単価取得
          let priceList = this.$store.getters.estimateItemPriceList
          let owner = priceList.find((p) => {
            return p.parentCode == '0' && p.priceCode == 'owner' + empty.subItemModalData['spOwner']
          })
          // 竣工係数取得
          let spFinishPoint = 1
          if (empty.subItemModalData['spFinishPoint']) {
            // 1年ごとに5％増
            spFinishPoint = BigNumber(0.05).times(Number(empty.subItemModalData['spFinishPoint'])).plus(1)
          }

          let o = owner.price
          d.price = String(BigNumber(Number(list[0].price)).times(BigNumber(Number(o))).times(spFinishPoint))
          
          d.qty = list[0].qty
          d.unitName = list[0].unitName
          
        }
      }

      modalDisp = empty.subItemModalData['display']
        
      empty.itemUnitPrice = modalDisp[0].price
      empty.itemQty = modalDisp[0].qty
      empty.itemUnitName = modalDisp[0].unitName

      // 特別清掃が1つのみの場合
      if (sp.length == 1) {
        // カテゴリー名の数字を削除
        empty.categoryName = empty.categoryName.replace('1', '')

      // 特別清掃が複数ある場合
      } else {
        // 数字を丸文字にする
        let num = empty.categoryName.replace(/[^0-9]/g, '')
        empty.categoryName = empty.categoryName.replace(num, '')
        empty.categoryName = empty.categoryName + utils.toCircled(Number(num))
      }

      this.estimate.details.push(empty)
      this.calculate(empty.groupName, empty.categoryCode)
    },

    /**
     * メモをセット
     * @param selectedRequestPost タスク
     */
    setMemo(selectedRequestPost) {
      let memo = this.estimate.buildingInfo.memo
      memo.rls = selectedRequestPost.roundServiceMemo 
      memo.rlsPlus = selectedRequestPost.roundPlusMemo
      memo.normal = selectedRequestPost.everydayCleanMemo
      memo.mgt = selectedRequestPost.managerWorkMemo
      memo.fixed = selectedRequestPost.regularCleanMemo
      memo.glass = selectedRequestPost.glassCleanMemo
      memo.dorainPipe = selectedRequestPost.estimationDorainPipeMemo 
      memo.waterTank = selectedRequestPost.estimationWaterTankMemo 
      memo.plant = selectedRequestPost.plantingMemo 
      memo.sp = selectedRequestPost.specialCleanMemo 
      memo.rlsSub = selectedRequestPost.roundSubMemo
    },

    /**
     * 発注金額を算出
     * @param gName グループ名
     * @param changePrice 発注金額変更
     * @param changeRate 発注率変更
     */
    async getPurchasePrice(gName, changePrice, categoryCode) {
      // 貯水槽、消防、EV、増圧の場合、自動計算なし
      // if (['12', '15', '19', '20'].includes(categoryCode)) {
      //   return
      // }
      let rate = 0
      let pPrice = 0
      let pIdx = null
      
      for (let i = 0; i < this.estimate.purchase.length; i++) {
        const p = this.estimate.purchase[i]
        if (p.groupName == gName) {
          rate = Number(p.rate)
          pPrice = Number(p.price)
          pIdx = i
          break
        }
      }
      // 発注率,発注金額がない場合は何もしない
      if ((!rate || isNaN(rate)) && !pPrice || isNaN(pPrice)) {
        return
      }

      // subItemModalDataのselectedValue取得
      let selectedValue = null

      // 見積金額を取得
      let categoryUnitPrice = 0
      // 見積金額を取得
      for (let i = 0; i < this.estimate.details.length; i++) {
        const d = this.estimate.details[i]
        if (d.groupName == gName) {
          // カテゴリコードがないものの、selectedValueを取得
          if ((!categoryCode || categoryCode == '') && !selectedValue) {
            if (d.subItemModalData && d.subItemModalData.selectedValue) {
              selectedValue = d.subItemModalData.selectedValue[0]
            }
          }

          let epC = Number(d.categoryUnitPrice)
          if (!epC || !isNaN(epC)) {
            categoryUnitPrice += epC
          }
        }
      }

      // 手数料の見積金額を取得（もとにする金額は手数料なしの金額）
      let ePrice = 0
      let commFlag = false
      for (let i = 0; i < this.commission.length; i++) {
        const d = this.commission[i]
        if (d.groupName == gName) {
          commFlag = true
          let ep = Number(d.estimatePrice)
          if (!ep || !isNaN(ep)) {
            ePrice = ep
          }
          break
        }
      }

      // 仕様変更があったため、手数料なしの値段がない手数料明細があるので
      if (commFlag && !ePrice) {
        ePrice = categoryUnitPrice
      }

      // 発注率計算
      if (!changePrice) {
        if (ePrice != null && ePrice != undefined && pIdx != null && pIdx != undefined) {
          let r = 0
          if (ePrice) {
            r = pPrice / ePrice
            r = Math.floor(r * 1000) / 10
            this.estimate.purchase[pIdx].rate = String(r)
          } else {
            this.estimate.purchase[pIdx].rate = '0'
            this.estimate.purchase[pIdx].price = '0'
          }
        }
      }

      // 発注率初期値を取得（発注先によって率が変わる）
      let defaultRate = utils.getPurchaseRate(categoryCode, this.purchase[pIdx].supplier, this.$store.getters.purchaseRate, selectedValue)
      // マスタ値を超えている場合
      if (this.estimate.purchase[pIdx].rate && this.estimate.purchase[pIdx].rate != '' && !isNaN(this.estimate.purchase[pIdx].rate)) {
        let rate = Number(this.estimate.purchase[pIdx].rate)
        if (defaultRate < rate) {
          await dialogs.showErrorDialog('発注率オーバー', '発注率が設定値を超えています。\r\n設定値：' + defaultRate + '%')
          this.estimate.purchase[pIdx].priceBgColor = true
          this.estimate.purchase[pIdx].rateBgColor = true
        } else {
          this.estimate.purchase[pIdx].priceBgColor = false
          this.estimate.purchase[pIdx].rateBgColor = false
        }
      }

      this.purchase = this.estimate.purchase
      this.purchaseReload++
    },

    /**
     * 発注マスタオープン
     */
    openPurchaseModal() {
      this.purchaseModalShow = true
    },
    
    /**
     * 発注マスタクローズ
     */
    closePurchaseModal() {
      this.purchaseModalShow = false
    },

    /**
     * 手数料計算 見積もりに変更があれば、手数料明細の金額も変更
     * @param gName グループネーム
     */
    getCommissionPrice(gName) {
      let commissionPrice = 0
      let rate = 0
      // アポロ金額
      let ePrice = 0
      // 手数料込（見積の金額）
      let price = 0

      // 見積金額を取得
      for (let i = 0; i < this.estimate.details.length; i++) {
        const d = this.estimate.details[i]
        if (d.groupName == gName) {
          let ep = Number(d.categoryUnitPrice)
          if (!ep || !isNaN(ep)) {
            price += ep
          }
        }
      }

      // 見積金額が0より大きい
      if (price) {
        for (let i = 0; i < this.commission.length; i++) {
          const com = this.commission[i]
          if (com.groupName == gName) {
            if (!isNaN(com.rate)) {
              rate = Number(com.rate)
              break
            }
          }
        }
        // 手数料率0より大きい
        if (rate) {
          rate = rate / 100
          commissionPrice = BigNumber(price).times(rate)
          commissionPrice = Math.floor(commissionPrice)
          ePrice = price - commissionPrice
        } else if (rate == 0) {
          ePrice = price
        }
      }

      let commissionPriceSt = '0'
      let priceSt = '0'
      let ePriceSt = '0'


      if (commissionPrice) {
        commissionPriceSt = String(commissionPrice)
      }
      if (price) {
        priceSt = String(price)
      }
      if (ePrice) {
        ePriceSt = String(ePrice)
      }

      for (let j = 0; j < this.commission.length; j++) {
        const com2 = this.commission[j]
        if (com2.groupName == gName) {
          com2.estimatePrice = ePriceSt
          com2.commissionPrice = commissionPriceSt
          com2.price = priceSt
          break
        }
      }

      this.commissionReload++
      this.estimate.commission = this.commission
    },

    /**
     * タイプ選択
     * @param tab 選択したタブ
     */
    async typeChanged(tab) {
      this.type.map((t) => {
        t.active = false
      })
      tab.active = true
      this.estimate.classification = tab.id

      // 共通の特記事項
      // 新規の場合
      if (this.estimate.classification == '1') {
        // 共通の特記事項に文言追加
        // 何も記載無し、もしくは「作業開始は」（期間で比較しない）がない場合
        if (!this.estimate.commonNote || this.estimate.commonNote.indexOf('作業開始は') == -1) {
          if (this.estimate.changeCommonNote) {
            const res = await dialogs.showConfirmDialog('共通の特記事項', '共通の特記事項が編集された形跡があります。\r\n『' + COMMON_NOTE_NEW + '』を記載してよろしいですか？' )
            if (res != 'YES') {
              return
            }
          }
          if (!this.estimate.commonNote) {
            this.estimate.commonNote = COMMON_NOTE_NEW
          } else {
            this.estimate.commonNote += '\n' + COMMON_NOTE_NEW
          }
        }
      // 新規以外
      } else {
        if (this.estimate.commonNote) {
          // 完全一致の文言がある場合
          if (this.estimate.commonNote.indexOf(COMMON_NOTE_NEW) > -1) {  
            // 「作業開始は…」を削除する
            this.estimate.commonNote = this.estimate.commonNote.replace(COMMON_NOTE_NEW + '\n', '')
            this.estimate.commonNote = this.estimate.commonNote.replace(COMMON_NOTE_NEW, '')

          // 文言が少し違う場合
          } else if (this.estimate.commonNote.indexOf('作業開始は') > -1) {
            const co = this.estimate.commonNote
            const index = co.indexOf('作業開始は')
            let newstring1 = co.substring(0, index)
            let index2 = index
            // 「作業開始は」から、最初の句点までのインデックスを取得
            for (let i = index + 1; i < co.length; i++) {
              const c = co.charAt(i)
              if (c == '。') {
                if (co.charAt(i + 1) == '\n') {
                  index2 = i + 1 + 1
                } else {
                  index2 = i + 1
                }
                break
              }
            }
            let newstring2 = co.substring(index2)
            this.estimate.commonNote = newstring1 + newstring2
          }
        }
      }

      // 選択した区分によって要件の文言を変更
      this.setRequirement()

      this.estimate.changeCommonNote = false
    },

    /**
     * 要件の文言をセット
     */
    setRequirement() {
      // 優先1、ラウンドトラッシュのみは、「ゴミ搬出業務」
      // 優先2、スポットは「スポット業務」
      // 優先3、清掃業務カテゴリーが1つでもあれば、「清掃業務」
      // 優先4、清掃作業カテゴリーがなく、植栽のみは「植栽業務」、設備のみは「点検業務」
      // 優先5、清掃作業カテゴリーがなく、清掃業務カテゴリー以外の業務が複数ある場合はブランク（例：植栽と設備など）
      
      let count = 0
      let categoryCode
      for (let i = 0; i < this.estimate.details.length; i++) {
        const d = this.estimate.details[i]
        if (d.rowspan >= 1) {
          count++
          categoryCode = d.categoryCode
          // 複数カテゴリーがある場合
          if (count > 1) {
            this.setRequirement2(this.estimate.classification)
            return
          }
        }
      }
      // カテゴリーが1つで、それがラウンドトラッシュの場合
      if (categoryCode == '8') {
        this.estimate.requirement = 'ゴミ搬出業務'
      } else {
        this.setRequirement2(this.estimate.classification)
      }
    },

    /**
     * 選択した区分によって要件の文言を変更
     */
    setRequirement2(classification) {
      if (classification == '3') {
        this.estimate.requirement = 'スポット業務'
      } else {
        // 植栽カテゴリーコード
        let plantCode = ['13']
        let plant = false
        // 点検
        let facilityCode = ['15', '16', '17', '18', '19', '20', '21', '22', '27']
        let faci = false
        // その他業務（清掃以外）
        let otherCode = ['8', '23', '24', '25', '26']
        let other = false

        // 上記のコード以外のものが含まれていれば、「清掃業務」
        for (let i = 0; i < this.estimate.details.length; i++) {
          const d = this.estimate.details[i]
          if (d.rowspan >= 1) {
            const code = d.categoryCode
            // カテゴリーコードなしがあれば、清掃業務で決定
            if (!d.categoryCode) {
              this.estimate.requirement = '清掃業務'
              return
            } else {
              if (plantCode.includes(code)) {
                plant = true
              } else if (facilityCode.includes(code)) {
                faci = true
              } else if (otherCode.includes(code)) {
                other = true
              // 清掃業務がある場合
              } else {
                this.estimate.requirement = '清掃業務'
                return
              }
            }
          }
        }

        // 植栽のみ
        if (plant && !faci && !other) {
          this.estimate.requirement = '植栽業務'
        // 設備系のみ
        } else if (!plant && faci && !other) {
          this.estimate.requirement = '点検業務'
        // 清掃業務以外の業務が複数ある場合
        } else {
          this.estimate.requirement = ''
        }
      }
    },

    /**
     * 印刷イベント
     */
    async print() {
      if (!this.estimate.estimateNo || this.estimate.estimateNo == '') {
        await dialogs.showErrorDialog('保存してください', '帳票を確認するには一度保存してください。')
        return
      }
      if (this.isDirty()) {
        const pR = await dialogs.showWarningConfirmDialog('変更されています', '変更された見積りを確認したい場合、一度保存してください。\r\nこのまま帳票を表示しますか？')
        if (pR != 'YES') {
          return
        }
      }
      const win = this.isModileDevice ? window.open() : null
      await this.printEstimate(win)
    },

    /**
     * 帳票印刷実行
     */
    async printEstimate(win) {
      try {
        this.showPrintingMessage()

        const printUrl = this.createPrintUrl()
        if (printUrl) {
          if (win) {
            win.location = printUrl
          } else {
            window.open(printUrl) 
          }
        }
      } finally {
        dialogs.hideLoading()
      }
    },

    /**
     * 印刷URL生成
     */
    createPrintUrl() {
      if (this.estimate) {
        const printUrl = estimateManager.buildPrintUrlForDirectPrint(this.estimate, this.$store.getters.user.token, 'Estimate')
        return printUrl
      } else {
        return null
      }
    },

    /**
     * 見積りの親であるメインタスクの情報を取得
     */
    async getMain(estimate) {
      const parentRequest = await requestPostManager.getRequestPost(this, estimate.requestPostId)
      if (parentRequest.status == 'done') {
        this.isParentComplete = true
      }
      this.mainData = {}
      if (parentRequest) {
        // メインのID
        this.mainData._id = parentRequest._id
        // メインの問い合わせNo
        this.mainData.requestNo = parentRequest.requestNo
        // 依頼日
        this.mainData.requestDate = parentRequest.requestDate
        // 見積りカテゴリーその他も結合
        this.mainData.estimateCategory = parentRequest.estimationCleanCategory.concat(parentRequest.estimationInspectCategory).concat(parentRequest.estimationOtherCategory)
      }
    },

    /**
     * メインタスク情報と見積り詳細情報で違う内容を取得
     */
    async getDiffDetails() {
      // 見積り詳細情報を取得
      const selectedDetails = utils.getSelectedDetails(this.estimate, this.$store.getters.estimateSubItemList, false)

      // メイン・サブの情報を取得
      if (!this.taskData || !this.taskData.requestPostId) {
        await this.getDataByTaskId(this.estimate.requestPostId, this.estimate.taskRequestId)
      }

      // 相違があれば更新
      // 物件住所
      if (this.estimate.buildingInfo.address.state != this.taskData.state) {
        this.estimate.buildingInfo.address.state = this.taskData.state
      }
      if (this.estimate.buildingInfo.address.city != this.taskData.city) {
        this.estimate.buildingInfo.address.city = this.taskData.city
      }
      if (this.estimate.buildingInfo.address.street != this.taskData.street) {
        this.estimate.buildingInfo.address.street = this.taskData.street
      }
      // 階数
      if (this.estimate.buildingInfo.stairs != this.taskData.stairs) {
        this.estimate.buildingInfo.stairs = this.taskData.stairs
      }
      // 地下
      if (this.estimate.buildingInfo.basement != this.taskData.basement) {
        this.estimate.buildingInfo.basement = this.taskData.basement
      }
      // 戸数
      if (this.estimate.buildingInfo.households != this.taskData.households) {
        this.estimate.buildingInfo.households = this.taskData.households
      }
      // 駐車場
      const parking = this.setParking(this.taskData)
      if (this.estimate.buildingInfo.parking = parking) {
        this.estimate.buildingInfo.parking = parking
      }
      // 使用可能なトイレの有無
      const availableToilet = this.setToilet(this.taskData)
      if (availableToilet && this.estimate.buildingInfo.availableToilet != availableToilet) {
        this.estimate.buildingInfo.availableToilet = availableToilet
      }
      // オーナー契約
      this.estimate.buildingInfo.owner = this.taskData.owner

      // 手数料
      this.estimate.buildingInfo.commission = this.taskData.commission

      // 竣工
      this.estimate.buildingInfo.finishPoint = null
      this.estimate.buildingInfo.finishYear = null
      this.estimate.buildingInfo.finishMonth = null
      if (this.taskData.finishPoint) {
        this.estimate.buildingInfo.finishPoint = this.taskData.finishPoint
        this.estimate.buildingInfo.finishYear = this.taskData.finishYear
        this.estimate.buildingInfo.finishMonth = this.taskData.finishMonth
      }

      // メイン・サブ情報と見積り情報で相違があるところを見つける
      this.diffDetails = []
      // 定期清掃の出てくる回数
      let fixedNum = 0
      for (let i = 0; i < selectedDetails.length; i++) {
        const s = selectedDetails[i]
        if (s.details) {
          // 定期清掃
          if (s.categoryCode == '5') {
            s.tabIdx = fixedNum
            fixedNum++
          }
          const diff = await this.getDiffTask(s.categoryCode, s.details, this.taskData, s.tabIdx, s.groupName, null)
          if (diff && diff.length) {
            this.diffDetails.push({ categoryName: s.categoryName, details: diff, categoryCode: s.categoryCode, groupName: s.groupName, tabId: s.tabId })
          }
        }
        // ラウンドプラス
        if (s.rlsPlus1) {
          // RLS
          const diff1 = await this.getDiffTask(s.rlsPlus1.categoryCode, s.rlsPlus1.details, this.taskData, s.rlsPlus1.tabIdx, s.rlsPlus1.groupName, s.rlsPlus1.categoryName)
          if (diff1 && diff1.length) this.diffDetails.push({ categoryName: s.categoryName + `（${s.rlsPlus1.categoryName}）`, details: diff1, categoryCode: s.rlsPlus1.categoryCode, groupName: s.rlsPlus1.groupName, tabId: s.rlsPlus1.tabId })

          // 固定清掃
          if (s.rlsPlus2) {
            const diff2 = await this.getDiffTask(s.rlsPlus2.categoryCode, s.rlsPlus2.details, this.taskData, s.rlsPlus2.tabIdx, s.rlsPlus2.groupName, s.rlsPlus2.categoryName)
            if (diff2 && diff2.length) this.diffDetails.push({ categoryName: s.categoryName + `（${s.rlsPlus2.categoryName}）`, details: diff2, categoryCode: s.rlsPlus2.categoryCode, groupName: s.rlsPlus2.groupName, tabId: s.rlsPlus2.tabId })
          }

          // 定期清掃
          if (s.rlsPlus3) {
            const diff3= await this.getDiffTask(s.rlsPlus3.categoryCode, s.rlsPlus3.details, this.taskData, s.rlsPlus3.tabIdx, s.rlsPlus3.groupName, s.rlsPlus3.categoryName)
            if (diff3 && diff3.length) this.diffDetails.push({ categoryName: s.categoryName + `（${s.rlsPlus3.categoryName}）`, details: diff3, categoryCode: s.rlsPlus3.categoryCode, groupName: s.rlsPlus3.groupName, tabId: s.rlsPlus3.tabId })
          }
        }
      }
    },

    /**
     * タスク情報との相違点を見つける
     * @param categoryCode カテゴリーコード
     * @param estimate 見積り情報
     * @param task タスク情報
     * @param tabIdx タブのID
     * @param groupName グーループネーム
     * @param rlsPlusCategoryName ラウンドプラスの種別名
     */
    async getDiffTask(categoryCode, estimate, task, tabIdx, groupName, rlsPlusCategoryName) {
      const diff = []
      const t = task

      for (let i = 0; i < estimate.length; i++) {
        const e = estimate[i]

        // エリア
        if (e.name.indexOf('エリア') > -1) {
          let eria = ''
          // ラウンドプラス
          if (categoryCode == '2') {
            if (rlsPlusCategoryName == '固定清掃') {
              eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'normal')  

            } else if (rlsPlusCategoryName == '定期清掃') {
              eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'fixed')
            }
          }
          // 日常・管理
          else if (categoryCode == '3' || categoryCode == '4') {
            eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'normal')
          }
          // 定期・スポット
          else if (categoryCode == '5' || categoryCode == '28') {
            eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'fixed')
          }
          // 貯水槽
          else if (categoryCode == '12') {
            let waterStorage = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'waterStorage')
            if (e.name.indexOf('ニッツ') > -1 && waterStorage && waterStorage.eriaNittsu) {
              eria = waterStorage.eriaNittsu
            } else if (e.name.indexOf('ベスパ') > -1 && waterStorage && waterStorage.eriaBesper) {
              eria = waterStorage.eriaBesper
            }
          }
          // 消防点検
          else if (categoryCode == '15') {
            eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'fire')
          }
          // 増圧ポンプ
          else if (categoryCode == '20') {
            eria = await eriaManager.getEria(this, this.estimate.buildingInfo.address, 'booster')
          }
          
          if (eria && e.value != eria) {
            diff.push({ ...e, main: eria })
          }
        }

        // 階数
        if (e.name == '階数') {
          if (t.stairs && e.value != t.stairs) {
            diff.push({ ...e, main: t.stairs })
          }
        }

        // 戸数
        else if (e.name == '戸数') {
          if (t.households && e.value != t.households) {
            diff.push({ ...e, main: t.households })
          }
        }

        // タイプ（ラウンドプラスの巡回清掃以外_金額に影響ないから）
        else if (e.name == 'タイプ' && !(categoryCode == '2' && rlsPlusCategoryName == '巡回清掃')) {
          if (t.residenceType[0] && e.value != t.residenceType[0]) {
            diff.push({ ...e, main: t.residenceType[0] })
            this.estimate.buildingInfo.residenceType = t.residenceType[0]
          }
        }

        // 駐車場
        else if (e.name.indexOf('作業時間') == -1 && (e.name.indexOf('駐車場代') > -1 || e.name.indexOf('交通費') > -1)) {
          const parking = this.setParking(t)
          if (parking && e.value != parking) {
            diff.push({ ...e, main: parking })
          }
        }

        // トイレ使用
        else if (e.name == 'トイレ使用') {
          const availableToilet = this.setToilet(t)
          if (availableToilet && e.value != availableToilet) {
            diff.push({ ...e, main: availableToilet })
          }
        }

        // オーナー契約
        else if (e.name == 'オーナー契約') {
          if (t.owner && e.value != t.owner) {
            diff.push({ ...e, sub: t.owner })
          }
        }

        // 竣工
        else if (e.name.indexOf('竣工') > -1) {
          if (t.finishPoint && e.value != t.finishPoint) {
            diff.push({ ...e, sub: t.finishPoint })
          }
        }

        // RLSの場合、かつメインタスクで選択されている場合
        if (categoryCode == '1' && t.mainEstimationCleanCategory.includes('ラウンドサービス')) {

          // 清掃回数
          if (e.name == '清掃回数') {
            let rlsCleaningTimes = this.setClean(t, 'cleanCount')
            if (e.value != rlsCleaningTimes) {
              diff.push({ ...e, main: rlsCleaningTimes })
              this.estimate.buildingInfo.rlsCleaningTimes = rlsCleaningTimes
            }
          }

          // ゴミ出し回数
          else if (e.name.indexOf('ゴミ出し回数') > -1) {
            let rlsTrash = this.setTrash(t, 'dustRemoval', 'dustCount')
            // 不明でない場合
            if (rlsTrash) {
              if (e.value != rlsTrash) {
            diff.push({ ...e, main: rlsTrash })
                this.estimate.buildingInfo.rlsTrash = rlsTrash
              }
            }
          }

          // 有料トイレ清掃回数
          else if (e.name == '有料トイレ清掃回数') {
            let rlsToiletTimes = '0'
            // トイレ清掃ありの場合
            if (this.isToiletClean(t, 'rlsToiletCleaning')) {
              rlsToiletTimes = t.rlsToiletTimes
            }
            if (e.value != rlsToiletTimes) {
              diff.push({ ...e, main: rlsToiletTimes })
              this.estimate.buildingInfo.rlsToiletTimes = rlsToiletTimes
            }
          }

          // 有料トイレ清掃箇所
          else if (e.name == '有料トイレ清掃箇所') {
            let rlsToiletPlace = '0'
            // トイレ清掃ありの場合
            if (this.isToiletClean(t, 'rlsToiletCleaning')) {
              rlsToiletPlace = t.rlsToiletPlace
            }
            if (e.value != rlsToiletPlace) {
              diff.push({ ...e, main: rlsToiletPlace })
              this.estimate.buildingInfo.rlsToiletPlace = rlsToiletPlace
            }
          }
        }

        // ラウンドプラスの場合、かつメインタスクで選択されている場合
        else if (categoryCode == '2' && t.mainEstimationCleanCategory.includes('ラウンドプラス')) {

          // RLS
          if (rlsPlusCategoryName == '巡回清掃') {
            // 清掃回数
            if (e.name == '清掃回数') {
              let plusCleaningTimes = this.setClean(t, 'plusCleanCount')
              if (e.value != plusCleaningTimes) {
              diff.push({ ...e, main: plusCleaningTimes })
                this.estimate.buildingInfo.plusCleaningTimes = plusCleaningTimes
              }
            }

            // ゴミ出し回数
            else if (e.name.indexOf('ゴミ出し回数') > -1) {
              let rlsPlusTrash = this.setTrash(t, 'plusDustRemoval', 'plusDustCount')
              // 不明でない場合
              if (rlsPlusTrash) {
                if (e.value != rlsPlusTrash) {
                  diff.push({ ...e, main: rlsPlusTrash })
                  this.estimate.buildingInfo.rlsPlusTrash = rlsPlusTrash
                }
              }
            }

            // 有料トイレ清掃回数
            else if (e.name == '有料トイレ清掃回数') {
              let plusToiletTimes = '0'
              // トイレ清掃ありの場合
              if (this.isToiletClean(t, 'plusToiletCleaning')) {
                plusToiletTimes = t.plusToiletTimes
              }
              if (e.value != plusToiletTimes) {
                diff.push({ ...e, main: plusToiletTimes })
                this.estimate.buildingInfo.plusToiletTimes = plusToiletTimes
              }
            }

            // 有料トイレ清掃箇所
            else if (e.name == '有料トイレ清掃箇所') {
              let plusToiletPlace = '0'
              // トイレ清掃ありの場合
              if (this.isToiletClean(t, 'plusToiletCleaning')) {
                plusToiletPlace = t.plusToiletPlace
              }
              if (e.value != plusToiletPlace) {
                diff.push({ ...e, main: plusToiletPlace })
                this.estimate.buildingInfo.plusToiletPlace = plusToiletPlace
              }
            }

          // 固定清掃
          } else if (rlsPlusCategoryName == '固定清掃') {
            // 有料トイレ清掃回数
            if (e.name == '有料トイレ清掃回数') {
              let plusToiletTimesNormal = '0'
              // トイレ清掃ありの場合
              if (this.isToiletClean(t, 'plusToiletCleaningNormal')) {
                plusToiletTimesNormal = t.plusToiletTimesNormal
              }
              if (e.value != plusToiletTimesNormal) {
                diff.push({ ...e, main: plusToiletTimesNormal })
                this.estimate.buildingInfo.plusToiletTimesNormal = plusToiletTimesNormal
              }
            }

            // 有料トイレ清掃箇所
            else if (e.name == '有料トイレ清掃箇所') {
              let plusToiletPlaceNormal = '0'
              // トイレ清掃ありの場合
              if (this.isToiletClean(t, 'plusToiletCleaningNormal')) {
                plusToiletPlaceNormal = t.plusToiletPlaceNormal
              }
              if (e.value != plusToiletPlaceNormal) {
                diff.push({ ...e, main: plusToiletPlaceNormal })
                this.estimate.buildingInfo.plusToiletPlaceNormal = plusToiletPlaceNormal
              }
            }
          }
        }

        // 日常清掃の場合、かつメインタスクで選択されている場合
        else if (categoryCode == '3' && t.mainEstimationCleanCategory.includes('日常清掃')) {
          // 相違がなくても、とりあえず更新
          this.estimate.buildingInfo.normal = this.setNormal(t)
          if (this.estimate.buildingInfo.normal) {
            let num = ''
            // if (this.estimate.buildingInfo.normal.length > 1) {
            //   num = ' ※日常' + (Number(tabIdx) + 1) + 'つ目'
            // }
            // タブのインデックスと同じインデックスのメイン日常清掃の情報で比較
            const normalRow = this.estimate.buildingInfo.normal[tabIdx]
            if (normalRow) {
              // 有料トイレ清掃回数
              if (e.name == '有料トイレ清掃回数') {
                if (e.value != normalRow.toiletTimes) {
                  diff.push({ ...e, main: normalRow.toiletTimes + num })
                }
              }

              // 有料トイレ清掃箇所
              else if (e.name == '有料トイレ清掃箇所') {
                if (e.value != normalRow.toiletPlace) {
                  diff.push({ ...e, main: normalRow.toiletPlace + num })
                }
              }

              // 時間指定
              else if (e.name == '時間指定') {
                // 不明でない場合
                if (normalRow.specific) {
                  if (e.value != normalRow.specific) {
                    diff.push({ ...e, main: normalRow.specific + num })
                  }
                }
              }

              // 清掃時間(指定あり)
              else if (e.name == '清掃時間(指定あり)') {
                if (e.value != normalRow.hours) {
                  diff.push({ ...e, main: normalRow.hours + num })
                }
              }

              // 清掃時間(指定なし)
              else if (e.name == '清掃時間(指定なし)') {
                if (e.value != normalRow.hours) {
                  diff.push({ ...e, main: normalRow.hours + num })
                }
              }

              // 清掃回数
              else if (e.name == '清掃回数') {
                if (e.value != normalRow.times) {
                  diff.push({ ...e, main: normalRow.times + num })
                }
              }

              // 祝日対応
              else if (e.name == '祝日対応') {
                // 不明でない場合
                if (normalRow.holiday) {
                  if (e.value != normalRow.holiday) {
                    diff.push({ ...e, main: normalRow.holiday + num })
                  }
                }
              }
            }
          }
        }

        // 管理員の場合、かつメインタスクで選択されている場合
        else if (categoryCode == '4' && t.mainEstimationCleanCategory.includes('管理員業務')) {
          // 相違がなくても、とりあえず更新
          this.estimate.buildingInfo.mgt = this.setMgt(t)
          if (this.estimate.buildingInfo.mgt) {
            let num = ''
            // if (this.estimate.buildingInfo.mgt.length > 1) {
            //   num = ' ※管理' + (Number(tabIdx) + 1) + 'つ目'
            // }
            // タブのインデックスと同じインデックスのメイン管理員の情報で比較
            const mgt = this.estimate.buildingInfo.mgt[tabIdx]
            if (mgt) {
              // 時間指定
              if (e.name == '時間指定') {
                // 不明でない場合
                if (mgt.specific) {
                  if (e.value != mgt.specific) {
                    diff.push({ ...e, main: mgt.specific + num })
                  }
                }
              }

              // 清掃時間(指定あり)
              else if (e.name == '清掃時間(指定あり)') {
                if (e.value != mgt.hours) {
                  diff.push({ ...e, main: mgt.hours + num })
                }
              }

              // 清掃時間(指定なし)
              else if (e.name == '清掃時間(指定なし)') {
                if (e.value != mgt.hours) {
                  diff.push({ ...e, main: mgt.hours + num })
                }
              }

              // 清掃回数
              else if (e.name == '清掃回数') {
                if (e.value != mgt.times) {
                  diff.push({ ...e, main: mgt.times + num })
                }
              }

              // 祝日対応
              else if (e.name == '祝日対応') {
                // 不明でない場合
                if (mgt.holiday) {
                  if (e.value != mgt.holiday) {
                    diff.push({ ...e, main: mgt.holiday + num })
                  }
                }
              }
            }
          }
        }

        // 定期の場合、かつメインタスクで選択されている場合
        else if (categoryCode == '5' && t.mainEstimationCleanCategory.includes('定期清掃')) {
          if (e.name == '清掃回数（回/年）') {
            if (t.regularCleans[tabIdx] && t.regularCleans[tabIdx].regularCleanCount && e.value != t.regularCleans[tabIdx].regularCleanCount) {
              diff.push({ ...e, main: t.regularCleans[tabIdx].regularCleanCount })
            }
          }
        }

        // 特別清掃の場合、かつメインタスクで選択されている場合
        else if (categoryCode == '14') {
          if (e.name == '照明器具清掃') {
            for (let i = 0; i < this.estimate.details.length; i++) {
              const est = this.estimate.details[i]
              if (est.categoryCode == '14' && est.groupName == groupName) {
                if (est.subItemModalData && est.subItemModalData.display) {
                  for (let j = 0; j < est.subItemModalData.display.length; j++) {
                    const d = est.subItemModalData.display[j]
                    // 箇所数不明になっている場合
                    if (d.lightingTab) {
                      let main = ''
                      if (t.households && d.house != t.households) {
                        main = `戸数：${t.households}`
                      }

                      // メインでタイプが選択されている
                      if (t.residenceType[0]) {
                        const typeList = constants.typeList
                        let value = typeList.find((type) => {
                          return type.name.indexOf(t.residenceType[0]) > -1
                        })

                        if (value && value.id && d.type != value.id) {
                          if (main) {
                            main += '、'
                          }
                          main += `タイプ：${t.residenceType[0]}`
                        }
                      }

                      if (main) {
                        diff.push({ ...e, main })
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 手数料
      if (t.commission && this.estimate.commission) {
        // 該当の手数料を取得
        for (let j = 0; j < this.estimate.commission.length; j++) {
          const c = this.estimate.commission[j]
          if (c.groupName == groupName) {
            if (t.commission == 'なし') {
              if (c.commissionPrice && c.commissionPrice != '0' && Number(c.commissionPrice) > 0) {
                diff.push({ name: '手数料', value: 'あり', sub: t.commission })
              }
            } else if (t.commission == 'あり') {
              if (!c.commissionPrice || c.commissionPrice == '0') {
                diff.push({ name: '手数料', value: 'なし', sub: t.commission })
              }
            }
            break
          }
        }
      }

      return diff
    }
  }
}
</script>
<style scoped>
.colum-drag {
  background: #59aec1;
  padding: 2px 13px;
  border-radius: 100vh;
}

.colum-drag:hover {
  background: #468c9c;
}

.w-30p {
  width: 33%;
  text-align: center;
}

.client-name {
  border-width: 1px;
}

.client-name:focus {
  border-width: 3px;
}

@media screen and (max-width: 1450px) {
  main,
  .header-contents {
    width: 1450px !important;
  }

  .list-con {
    bottom: auto !important;
  }
}
</style>