// 発注メール送信履歴
const PURCHASE_MAIL_HISTORY = 'purchase_mail_history'

/**
 * 受注番号に紐づく送信履歴データ取得
 * @param {*} context 
 * @param {*} orderNo 受注番号
 * @returns 
 */
export async function getHistoryByOrderNo(context, orderNo) {
  // console.log(orderNo)
  if (!orderNo || orderNo == '') {
    return null
  }
  const searchCondition = {
    searchCondition: {
      type: PURCHASE_MAIL_HISTORY,
      orderNo
    },
    sort: {
      createDate: 1
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    // console.log(searchCondition)
    const res = responseData || null
    // console.log(res)
    return res
  } catch (error) {
    console.log('getHistoryByOrderNo error !!!')
    console.log(error)
    return null
  }
}

/**
 * 発注番号に紐づく送信履歴データ取得
 * @param {*} context 
 * @param {*} purchaseNo 発注番号
 * @returns 
 */
export async function getHistoryByHistoryNo(context, historyNo) {
  if (!historyNo || historyNo == '') {
    return null
  }
  // console.log(orderNo)
  const searchCondition = {
    searchCondition: {
      type: PURCHASE_MAIL_HISTORY,
      historyNo
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    // console.log(searchCondition)
    const res = responseData[0] || null
    // console.log(res)
    return res
  } catch (error) {
    console.log('getHistoryByHistoryNo error !!!')
    console.log(error)
    return null
  }
}

/**
 * 発注メール送信履歴登録
 * @param {*} context 
 * @param {*} data 対象データ
 * @returns 
 */
export async function historyRegister(context, data) {
  try {
    data.type = PURCHASE_MAIL_HISTORY
    await context.$pigeon.registerTask(PURCHASE_MAIL_HISTORY, data, {})
    return true
  } catch (error) {
    console.log('historyRegister error !!!')
    console.log(error)
    return false
  }
}
