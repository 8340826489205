<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column2" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white th-name">
            <div v-if="c.name" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td class="th-price">
            <div v-if="price[i] && price[i].priceCode == c.value">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price[i].price"
                @change="changeData(price[i], c.name)"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * MRS
 */
const CATEGORY = '29'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // カラム
      column1: [
        { name: '内容', colspan: 1, class: 'ml-5 bg-teal-50 p-3'},
        { name: '単価', colspan: 1, class: 'ml-5 bg-teal-50 p-3 '},
      ],
      // カラム
      column2: [
        { name: '基本単価', value: '1' },
        { name: '41戸以上　1戸につき', value: 'households' },
        { name: '清掃回数　週3.5以上1回につき', value: 'cleaning' },
        { name: 'ゴミ搬出回数　週4.5以上1回につき', value: 'garbage' },
      ],
      // 単価
      price: [],
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {
      this.other = []

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      this.price = this.setSort(dbc)

    },

    /**
     * ソート
     * @param target 対象データ
     */
    setSort(target) {
      console.log(target)
      let result = []
      for (let l = 0; l < this.column2.length; l++) {
        const sort = this.column2[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode == sort.value) {
            result.push(er)
          }
        }
      }
      return result
    },
    /**
     * 変更イベント
     */
    changeData(target, name) {
      target.itemName = name
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.left-100p {
  left: 100px;
}

.th-name {
  min-width: fit-content;
}

.th-price {
  min-width: fit-content;
}
</style>
