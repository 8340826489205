<template>
  <div>
    <TeamNameMasterComponent
      typeName="設備点検 チーム名設定"
      :type="4"
      settionStrageName="TeamNameMasterStorageItemName">
    </TeamNameMasterComponent>
  </div>
</template>

<script>
import TeamNameMasterComponent from '../components/TeamNameMasterComponent.vue'

export default {
  components: {
    TeamNameMasterComponent
  },
}
</script>