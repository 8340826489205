<template>
    <div v-if="isNote" class="detail overflow-hidden border border-gray-300 rounded">
        <table v-if="isNote" class="min-w-full divide-y divide-gray-200 table-fixed">
            <thead>
                <tr v-if="isNote" class="divide-x divide-gray-200">
                    <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                        {{c.title}}
                    </th>
                </tr>

                <tr v-for="(n, i) in notes" :key="i" class="divide-y divide-x divide-gray-200 bg-white tracking-wider cursor-not-allowed" :class="bgColor">

                    <!-- No -->
                    <td v-if="n.noteHTML != ''" class="td-height b-top text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-900 col-no">
                        {{ i + 1 }}
                    </td>
                    <!-- <td v-else class="td-height b-top text-center px-0.5 py-0.5 whitespace-nowrap text-sm font-medium text-gray-900 col-no">
                        {{ n.no }}
                    </td> -->

                    <!-- カテゴリー名 -->
                    <td v-if="n.noteHTML != ''" class="td-height px-4 py-0.5 whitespace-pre-line break-words text-sm text-gray-500 w-80">
                        {{ getNewLine(n.categoryName) }}
                    </td>

                    <!-- 特記事項 -->
                    <td v-if="n.noteHTML != ''" v-html="n.noteHTML" class="td-height px-2 py-5 whitespace-nowrap text-sm text-gray-700 col-note"></td>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import * as utils from '@libs/utils'

export default {
    props: ['notes', 'bgColor'],

    data() {
        return {
            // カラム
            columns: [
                {title: 'No.'},
                {title: 'カテゴリー'},
                {title: '特記事項内容'},
            ]
        }
    },

    computed: {
        /**
         * 1行も特記事項が記載なしの場合は表ごと非表示
         */
        isNote() {
            let result = false
            if (this.notes && this.notes.length) {
                for (let i = 0; i < this.notes.length; i++) {
                    if (this.notes[i].noteHTML != '') {
                        result = true
                    }
                }
            }
            return result
        },

        /**
         * 改行判定
         */
        getNewLine() {
            return (val) => {
                return utils.replaceNewLine(val)
            }
        }
    }
}
</script>
<style scoped>
.col-no {
    width: 70px;  
}

.col-cat {
    width: 200px;
}

.col-note {
    min-width: 930px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: #efebfb;
}

.td-height {
    height: 38px;
}

@media screen and (max-width: 1200px) {
    .detail {
        overflow: auto;
    }
}
</style>