<template>
  <router-link v-if="judgment" :to="path" class="bg-opacity-75 shadow-outline hover:text-white cursor-pointer hover:bg-opacity-70 inline-flex justify-center items-center px-2.5 py-0.5 rounded text-xs font-medium relative btn">
    <Icon :iconName="iconName" class="w-4 h-4 mr-1" />
    {{ text }}
  </router-link>
  <a v-else class="text-white bg-gray-400 bg-opacity-75 inline-flex justify-center items-center px-2.5 py-0.5 rounded text-xs font-medium relative">
    <Icon :iconName="iconName" class="w-4 h-4 mr-1" />
    {{ text }}
  </a>
</template>
<script>
import Icon from '@components/Icon.vue'
import { evaluate } from '@libs/utils'
export default {

  components: {
    Icon
  },

  props: ['url', 'text', 'iconName', 'record'],

  computed: {
    path() {
      const record = this.record
      return evaluate({ record }, '`' + this.url + '`')
    },

    judgment() {
      return this.record['approveAuth']
    }
  },

  methods: {
    click() {
      if (this.onclick) {
        this.onclick(this.record)
      }
    }
  }
}
</script>
<style scoped>
.btn {
  background: #81aac7;
  color: #f0f7fd;
  border-bottom: 3px solid #667285;
  border-right: 3px solid #c0c0cb;
}

.btn:hover {
  margin-top: 3px;
  border-bottom: 1px solid #667285;
  border-right: 1px solid #c0c0cb;
}
</style>
