<template>
  <div>
    <PageHeader title="apollo-RP - Estimation" class="list">
      <template #title-header-content>
        <div class="pl-2 flex ml-auto justify-end">
          <router-link v-if="!approver" :to="{ name: 'EstimateList' }" class="font-bold text-white hover:text-gray-400 ml-auto flex items-center">
            <Icon iconName="ViewList" class="w-4 h-4 mr-1" />
            見積リスト
          </router-link>
          <EstimateListDropdownButton v-if="approver" class="w-130p" @select="selectedList" />
        </div>
      </template>
      <template #page-header-content>
        
        <div class="flex">
            
          <div class="pl-4 flex items-center">
            <div class="text-base font-bold text-white">
              <span>
                <span class="text-gray-400 text-sm">見積 # : </span>
                {{estimate ? estimate.estimateNo : ''}}
              </span>
            </div>
            
            <div class="text-base font-bold ml-6 text-white ---w-56">
              <span>
                <span class="text-gray-400 text-sm">見積金額 : </span>
                {{ estimate ? formatValue(estimate.totalAmount, 0, '¥', '') : '' }}
              </span>
            </div>

            <div class="text-base font-bold ml-6 text-white ---w-56">
              <span>
                <span class="text-gray-400 text-sm">要件 : </span>
                {{ estimate.requirement || '---' }}
              </span>
            </div>
          </div>
          
          <div v-if="estimate.test" class="text-lg font-bold ml-auto my-auto text-white ---w-56">
            <span>テスト用</span>
          </div>

          <div class="ml-auto flex items-center gap-1 mr-3">
            <PrimaryButton class="bg-green-600 mr-2" text="メイン一覧" @click="pageTransition('RequestPostList')">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
            <PrimaryButton class="bg-green-600 mr-2" text="サブ進捗一覧" @click="pageTransition('RequestPostProgressList')">
              <Icon iconName="ViewGrid" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <!-- 参照画面 -->
          <div class="ml-auto flex items-center gap-1" v-if="!approver && estimateAuth">
            <SecondaryButton text="コピー" class="mr-2 w-28" @click="copyEstimate">
              <Icon iconName="DocumentDuplicate" slot="before" class="h-4 w-4" />
            </SecondaryButton>
            <PrimaryButton v-if="estimate && estimate.estimateStatus == '受注'" class="bg-red-600 mr-2" text="受注取消" @click="deleteOrder()">
              <Icon iconName="Trash" slot="before" class="h-4 w-4" />
            </PrimaryButton>
            <PrimaryButton v-if="canDelete" class="w-28 bg-red-600 mr-2" text="削除" @click="deleteEstimate">
              <Icon iconName="Trash" slot="before" class="h-4 w-4" />
            </PrimaryButton>
            <PrimaryButton v-if="canDelete" class="w-28 bg-yellow-600 mr-2" text="却下" @click="reject()">
              <Icon iconName="XCircle" slot="before" class="h-4 w-4" />
            </PrimaryButton>
            <router-link v-if="canEdit" :to="{ name: 'EstimateForm', query: { estimateNo: estimate.estimateNo } }">
              <PrimaryButton class="w-28 mr-2" text="編集">
                <Icon iconName="Pencil" slot="before" class="h-4 w-4" />
              </PrimaryButton>
            </router-link>
            <EstimatePrintDropdownButton class="mr-2 w-28" @select="print" />

            <PrimaryButton text="契約書" class="mr-2 w-28" @click="downloadContract" v-if="canContractOutput" >
              <Icon iconName="DocumentDownload" slot="before" class="h-4 w-4" />
            </PrimaryButton>
          </div>

          <!-- 承認画面 -->
          <div class="ml-auto flex items-center gap-1" v-else-if="approver && estimateAuth">
            <PrimaryButton :disabled="apploved" text="承認" class="mr-2 w-28" @click="permit(true)">
              <LoadingIcon v-if="isLoadingApproval" slot="before" class="h-4 w-4" />
              <Icon v-if="!isLoadingApproval" iconName="BadgeCheck" slot="before" class="h-5 w-5" />
            </PrimaryButton>
            <PrimaryButton :disabled="isSendingBack" text="差戻" class="mr-2 w-28" @click="sendingBack('差戻')">
              <LoadingIcon v-if="isLoadingApproval" slot="before" class="h-4 w-4" />
              <Icon v-if="!isLoadingApproval" iconName="Reply" slot="before" class="h-5 w-5" />
            </PrimaryButton>
            <PrimaryButton v-if="canDelete" class="w-28 bg-yellow-600 mr-2" text="却下" @click="sendingBack('却下')">
              <Icon iconName="XCircle" slot="before" class="h-4 w-4" />
            </PrimaryButton>
            <EstimatePrintDropdownButton class="mr-2 w-28" @select="print" />
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 bottom-4 mt-5 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <!-- お問い合わせ内容 -->
        <div class="bg-light-blue-900 border border-gray-300 p-2">
          <dt class="p-2 text-sm font-medium text-white">
            お問い合わせ内容
            <span v-if="!estimate.subject">
              ： ---
            </span>
          </dt>
          <dd v-if="estimate.subject" class="text-sm text-gray-900 border rounded border-gray-300 bg-white p-2 w-fit">
            {{ estimate.subject }}
          </dd>
        </div>
        <vsa-list v-if="estimate.memo!=undefined" class="v-list" :initActive="estimate.memo!=''">
          <vsa-item>
            <vsa-heading>
              メモ
            </vsa-heading>

            <vsa-icon>
              <span>∨</span>
            </vsa-icon>

            <vsa-content>
              <WmsTextInput 
                name="memo" 
                :multiline="true"
                :rows="rowCount"
                :disabled="!isMobileDevice"
                v-model="estimate.memo"
              />
            </vsa-content>
          </vsa-item>
        </vsa-list>

        <div class="bg-gray-100 rounded-md border border-gray-300 flex flex-col">

          <div class="pt-4 flex items-center">
            <!-- <h1 class="text-xl font-bold ml-2">{{etimateTitle}}</h1> -->

            <div class="flex">
              <div class="text-base font-bold ml-10 flex">
                <span class="flex items-center text-gary-900">
                  <span class="text-gray-500 text-sm mr-2">担当者 : </span>
                  {{ estimate.taskStaff || '---' }}
                </span>
                <span v-if="estimateCreater" class="flex items-center text-gary-900 ml-10">
                  <span class="text-gray-500 text-sm mr-2">作成者 : </span>
                  {{ estimateCreater }}
                </span>
              </div>

              <div class="text-gray-500 text-sm mr-2 ml-10 font-bold mt-auto">
                承認者1 : {{ appName1 }}
              </div>

              <div class="text-gray-500 text-sm mr-2 ml-7 font-bold mt-auto">
                承認者2 : {{ appName2 }}
              </div>
            </div>
          </div>

          <!-- 差戻理由 作成者参照画面 -->
          <div v-if="estimate.estimateStatus=='差戻' && !approver" class="mx-10 mt-5">
            <span class="text-red-700 font-bold text-xs">差戻理由</span>
            <textarea 
              type="text" 
              name="reasonForSendingBack"
              :rows="rowCountSendingBack"
              :disabled="true"
              class="w-full py-1 cursor-not-allowed bg-gray-50 border border-gray-100 rounded"
              v-model="estimate.reasonForSendingBack"
            />
          </div>

          <!-- 差戻理由記入欄（承認確認の時だけ） -->
          <div v-if="approver" class="mx-10 mt-5">
            <span class="text-gray-500 text-sm mr-2">今回の差戻・却下理由</span>
            <textarea 
              type="text" 
              name="nowReasonForSendingBack"
              rows="5"
              class="w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent h-20"
              v-model="estimate.nowReasonForSendingBack"
            />

            <!-- 前回までの差戻理由 -->
            <div v-if="estimate.reasonForSendingBack" class="mt-2">
              <span class="text-gray-500 text-sm mr-2">＜前回までの差戻・却下理由＞</span>
              <textarea 
                type="text" 
                name="reasonForSendingBack"
                :rows="rowCountSendingBack"
                :disabled="true"
                class="w-full py-1 cursor-not-allowed bg-gray-50 border border-gray-100 rounded"
                v-model="estimate.reasonForSendingBack"
              />
            </div>
          </div>
          
          <div class="p-4 flex-1">

            <EstimateView
              :estimateData="estimate"
              @getEstimate="initialize"
            />

          </div>
        </div>
      </div>
    </main>
    <!-- 承認申請モーダル -->
    <Approval2Modal 
      v-model="approval2ModalShow" 
      :open="approval2ModalShow"
      :estimate="estimate"
      @doApproval="permit"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import SecondaryButton from '@components/SecondaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import EstimateView from '../components/EstimateView.vue'
import Approval2Modal from '../components/EstimateApproval2ChatModal.vue'
import EstimateListDropdownButton from '../components/EstimateListDropdownButton.vue'
import EstimatePrintDropdownButton from '../components/EstimatePrintDropdownButton.vue'

import * as estimateManager from '@managers/estimateManager'
import * as requestPostManager from '@managers/requestPostManager'
import * as orderManager from '@managers/orderManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as chatwork from '@libs/chatwork'
import * as backend from '@libs/backend'

import { ESTIMATE_FEATURES } from '@/config'
import { ESTIMATE_SENDING_BACK_FEATURES } from '@/config'
import { ESTIMATE_SENDING_APPROVED_OK } from '@/config'

import LoadingIcon from '@assets/loading-circle.svg' 

import moment from 'moment'

import { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon } from 'vue-simple-accordion'
import '@components/VueSimpleAccordion.scss'
import * as logManager from '@managers/logManager'


export default {
  components: {
    Icon,
    PrimaryButton,
    SecondaryButton,
    // EstimateStatusTracker,
    EstimateListDropdownButton,
    EstimatePrintDropdownButton,
    PageHeader,
    EstimateView,
    LoadingIcon,
    WmsTextInput,
    Approval2Modal,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },

  data() {
    return {
      // 見積り
      estimate: {},
      statusRenderKey: 0,
      // 承認確認メニューから遷移した
      approver: false,
      // 承認ボタン表示非表示の判定中
      isLoadingApproval: false,
      apploved: false,
      isSendingBack: false,
      approval2ModalShow: false,
      userName: '',
      userId: '',
      // 承認者1
      appName1: '---',
      // 承認者2
      appName2: '---',
      // 編集可能
      canEdit: true,
      // 削除可能
      canDelete: false,
      // 受注ボタン表示・非表示
      // canOrder: false,
      // 契約書ボタン表示・非表示
      canContractOutput: false,
      // メイン一覧遷移時の検索用お問い合わせNo
      requestNo: '',
      // メインタスクの見積カテゴリー
      mainData: {},
    }
  },

  computed: {
    appVersion() {
      return this.$store.getters.appVersion
    },

    formatValue() {
      return (val, decimalPlace = 0, prefix = '', suffix = '') => {
        if (val === '') {
          return val
        } else {
          return `${prefix}${utils.format(val, decimalPlace)}${suffix}`
        }
      }
    },

    attachments() {
      return [ this.estimate.attachment1, this.estimate.attachment2, this.estimate.attachment3].filter((attachment) => {
        return (attachment && attachment.fileId)
      })
    },

    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * メモの行数
     */
    rowCount() {
      if (this.estimate.memo != '') {
        let c = 1
        c = ( this.estimate.memo.match( /\n/g ) || [] ).length
        if (c) {
          return c+2
        } else {
          return 1
        }
      } else {
        return 1
      }
    },

    /**
     * 前回までの差戻理由行数
     */
    rowCountSendingBack() {
      if (this.estimate.reasonForSendingBack != '') {
        let c = 1
        c = ( this.estimate.reasonForSendingBack.match( /\n/g ) || [] ).length
        if (c) {
          return c+2
        } else {
          return 1
        }
      } else {
        return 1
      }
    },

    /**
     * 見積り権限
     */
    estimateAuth() {
      return this.$store.getters.estimateAuth
    },

    /**
     * 見積作成者（最終的な）
     */
    estimateCreater() {
      const userList = this.$store.getters.userList
      if (this.estimate.estimateCreater && this.estimate.estimateCreater != '') {
        return utils.getUserNameById(this.estimate.estimateCreater, userList)

      // estimateCreaterがない場合
      } else {
        if (this.estimate.userStamp && this.estimate.userStamp != '') {
          let result = null
          let num = this.estimate.userStamp.length - 8
          result = this.estimate.userStamp.substr(num, 4)
          return utils.getUserNameById(result, userList)
        } else {
          return null
        }
      }
    }
  },

  async created() {
    let user = this.$store.getters.user
    this.userName = utils.deleteKana(user.user_name)
    this.userId = user.id
    await this.initialize()
  },

  methods: {
    async initialize() {
      const estimateNo = this.$route.query.estimateNo
      // 参照する見積りNoあり
      if (estimateNo) {
        this.canEdit = true
        this.canContractOutput = false

        const estimate = await estimateManager.getEstimate(this, estimateNo)
        // 見積りデータ取得失敗
        if (!estimate) {
          await dialogs.showErrorDialog('見積りの参照', '見積りを開くことができません。')
          return
        // 見積りデータ取得成功
        } else {
          this.estimate = estimate
          const parentRequest = await requestPostManager.getRequestPost(this, estimate.requestPostId)
          // お問い合わせNoを取得
          this.requestNo = parentRequest.requestNo
          // メインのID
          this.mainData._id = parentRequest._id
          // メインの問い合わせNo
          this.mainData.requestNo = parentRequest.requestNo
          // 依頼日
          this.mainData.requestDate = parentRequest.requestDate
          // 見積りカテゴリーその他も結合
          this.mainData.estimateCategory = parentRequest.estimationCleanCategory.concat(parentRequest.estimationInspectCategory).concat(parentRequest.estimationOtherCategory)
          // メインのステータス
          this.mainData.status = parentRequest.status
        
          // メインのステータスが完了なら編集不可
          if (parentRequest.status == 'done') {
            this.canEdit = false
          }
          // ステータスが受注なら編集不可
          if (this.estimate.estimateStatus == '受注' || this.estimate.estimateStatus == '却下') {
            this.canEdit = false
          }
          // 承認確認ボタンから遷移した場合 'true'(文字列)がparamに入っている
          if (this.$route.query.approver == 'true') {
            this.approver = true
            let check = false
            // チャットのURLから開いた場合
            if (this.$route.query.sequentialOrder == '1') {
              for (let i = 0; i <  this.estimate.approvalFlow1.length; i++) {
                if ( this.estimate.approvalFlow1[i].userId == this.userId) {
                  check = true
                  break
                }
              }
              if (!check) {
                this.approver = false
                await dialogs.showErrorDialog('承認画面を開けません', '承認者ではない、もしくは承認順がまだのため参照画面を開きます。')
              }
            } else if (this.$route.query.sequentialOrder == '2') {
              for (let i = 0; i <  this.estimate.approvalFlow2.length; i++) {
                if ( this.estimate.approvalFlow2[i].userId == this.userId) {
                  check = true
                  break
                }
              }
              if (!check) {
                this.approver = false
                await dialogs.showErrorDialog('承認画面を開けません', '承認者ではないので参照画面を開きます。')
              }
            }
          }
          // 承認者の表示
          this.setApproverName()

          // ステータスが受注なら削除不可
          if (this.estimate.estimateStatus == '受注') {
            this.canDelete = false
          } else {
            // 削除ボタンの表示・非表示（全員が承認済みなら削除不可、それ以外、承認なしのも永遠と削除可能）
            if (this.estimate.approval1 == '承認済み' && this.estimate.approval2 == '承認済み') {
              this.canDelete = false
            } else if (this.estimate.approval1 == '承認済み' && this.estimate.approval2 == '---') {
              this.canDelete = false
            } else {
              this.canDelete = true
            }
          }

          // 作成完了の場合、受注ボタン表示
          // if (this.estimate.estimateStatus == '作成完了' || this.estimate.estimateStatus == '受注') {
          //   this.canOrder = true
          // }

          // ステータスが受注の場合かつ見積権限者で、受注データがある場合のみ、契約書ボタン表示
          if (this.estimate.estimateStatus == '受注' && this.estimateAuth) {
            // 見積Noに紐づく受注データを取得
            const orderData = await orderManager.getByEstimateNo(this, this.estimate.estimateNo)
            if (orderData.length > 0) {
              this.canContractOutput = true
            }
          }
        }
      
      } else {
        await dialogs.showErrorDialog('見積りの参照', '見積りを開くことができません。')
      }
    },

    /**
     * 承認画面表示時の「見積リスト」ドロップダウン選択イベント
     */
    selectedList(value) {
      if (value == 'list') {
        // 「担当の承認待ち」一覧に遷移
        this.$router.push({ 
          name: 'EstimateList'
        })
      } else if (value == 'approval') {
        // 「担当の承認待ち」一覧に遷移
        this.$router.push({ 
          name: 'EstimateList',
          query: {
            tabId: 'awaitingApproval'
          }
        })
      }
    },

    /**
     * 承認者の表示
     */
    setApproverName() {
      // 承認者1の表示
      if (this.estimate.approval1 != '---') {
        let name = []
        for (let j = 0; j < this.estimate.approvalFlow1.length; j++) {
          name.push(utils.deleteKana(this.estimate.approvalFlow1[j].userName) + '⇒' + this.estimate.approvalFlow1[j].status + ' ')
        }
        this.appName1 = name.join(' , ')
      }

      // 承認者2の表示
      if (this.estimate.approval2 != '---') {
        let name = []
        for (let j = 0; j < this.estimate.approvalFlow2.length; j++) {
          name.push(utils.deleteKana(this.estimate.approvalFlow2[j].userName) + '⇒' + this.estimate.approvalFlow2[j].status + ' ')
        }
        this.appName2 = name.join(' , ')
      }
    },

    createPrintUrl(target) {
      if (this.estimate) {
        const printUrl = estimateManager.buildPrintUrlForDirectPrint(this.estimate, this.$store.getters.user.token, target)
        return printUrl
      } else {
        return null
      }
    },

    /**
     * 印刷イベント
     */
    async print(target) {
      const win = this.isModileDevice ? window.open() : null
      await this.printEstimate(win, target)
    },

    async printEstimate(win, target) {
      try {
        this.showPrintingMessage()

        const printUrl = this.createPrintUrl(target)
        if (printUrl) {
          if (win) {
            win.location = printUrl
          } else {
            window.open(printUrl) 
          }
        }
      } finally {
        this.hidePrintingMessage()
      }
    },

    async copyEstimate() {
      if (this.mainData.status == 'done') {
        const res = await dialogs.showConfirmDialog('メインタスクが完了しています', 'メインタスクが完了しているためコピーを保存できませんが、\r\nコピー画面に遷移しますか？')
        if (res != 'YES') {
          return
        }
      }
      this.$router.push({ 
        name: 'EstimateForm',
        params: {
          copyEstimateNo: this.estimate.estimateNo
        }
      })
    },

    editEstimate() {
      this.$router.push({ 
        name: 'EstimateForm',
        params: {
          estimateNo: this.estimate.estimateNo
        }
      })
    },

    /**
     * 受注取り消し
     */
    async deleteOrder() {
      const dialogResult = await dialogs.showWarningConfirmDialog('受注を取り消す', `本当に受注を取り消しますか？`)
      if (dialogResult != 'YES') {
        return
      }
      // 見積りのデータを変更 (受注削除が間違いだった時のためにorderStatusは削除せずにそのままにする)
      this.estimate.estimateStatus = '作成完了'
      // 見積もり更新
      const resultEstimate = await estimateManager.updateEstimate(this, this.estimate)

      // 見積り更新成功
      if (resultEstimate) {
        // 受注メイン・サブに削除フラグを立てる
        const resultOrder = await orderManager.removeOrder(this, this.estimate.estimateNo)

        // 受注メイン・サブ削除成功
        if (resultOrder) {
          // ログに保存
          logManager.recording(this, logManager.Loglevel.INFO, '受注', '受注取消', '見積（見積番号：' + this.estimate.estimateNo + '）の受注を取り消しました。')

          await dialogs.showSuccessDialog('受注取り消し', `見積りのステータスは作成完了に戻り、\r\n受注のメインタスク・サブタスクは削除されました。`)
          await this.initialize()

        // 受注メイン・サブ削除失敗
        } else {
          await dialogs.showErrorDialog('受注の取り消しエラー', `受注メイン・サブデータを削除中にエラーが発生しました。`)
        }

      // 見積り更新失敗
      } else {
        await dialogs.showErrorDialog('受注の取り消しエラー', `見積りデータを更新中にエラーが発生しました。`)
      }
    },

    /**
     * 見積削除イベント
     */
    async deleteEstimate() {
      const dialogResult = await dialogs.showWarningConfirmDialog('見積の削除', `見積を削除しますか？`)
      if (dialogResult === 'YES') {
        const result = await estimateManager.deleteEstimate(this, this.estimate)
        if (result) {
          // ログに保存
          logManager.recording(this, logManager.Loglevel.INFO, '見積', '削除', '見積（見積番号：' + this.estimate.estimateNo + '）は削除されました。')
          await dialogs.showSuccessDialog('見積の削除', `見積を削除しました。`)
          await this.deleteEstimateApprovedCount()
          this.$router.replace({ name: 'EstimateList' })
        } else {
          await dialogs.showErrorDialog('見積の削除', `見積を削除できませんでした。`)
        }
      }
    },

    /**
     * 却下ボタンイベント
     */
    async reject() {
      const dialogResult = await dialogs.showWarningConfirmDialog('見積の却下', `見積を却下しますか？`)
      if (dialogResult === 'YES') {
        this.estimate.estimateStatus = '却下'
        this.canEdit = false

        // 承認者データをクリアにする
        this.estimate.approval1 = '---'
        this.estimate.approval2 = '---'
        // 1番目の承認ルート
        this.estimate.approvalFlow1 = [{
          sequentialOrder: '1',
          userId: '',
          userName: '',
          chatworkAccountId: '',
          chatworkRoomId: '',
          status: '未承認',
          stamp: ''
        }]
        // 2番目の承認ルート
        this.estimate.approvalFlow2 = [{
          sequentialOrder: '2',
          userId: '',
          userName: '',
          chatworkAccountId: '',
          chatworkRoomId: '',
          status: '未承認',
          stamp: ''
        }]

        // 更新
        let result = await estimateManager.updateEstimate(this, this.estimate)
        if (result) {
          // ログに保存
          logManager.recording(this, logManager.Loglevel.INFO, '見積', '却下', '見積（見積番号：' + this.estimate.estimateNo + '）は却下されました。')
          await dialogs.showSuccessDialog('見積の却下', `見積を却下しました。`)
          await this.deleteEstimateApprovedCount()
          // this.$router.replace({ name: 'EstimateList' })
        } else {
          await dialogs.showErrorDialog('見積の却下', `見積を却下できませんでした。`)
        }
      }
    },

    /**
     * 集計から除外するので、集計テーブルから対象のデータを削除
     */
    async deleteEstimateApprovedCount() {
      // 更新の場合、削除
      if (this.estimate.estimateNo && this.estimate.estimateNo != '') {
        let deleteApproved = await backend.deleteDataByKey('estimateApproved/deleteDataByKey', {key: this.estimate.estimateNo}, false)
        if (deleteApproved.data == 'Internal Server Error') {
          alert('エラーが発生しました。\r\n【削除のため集計データ作成完了登録分削除】')
          console.log('【見積り削除のため集計データ作成完了登録分削除】')
          // console.log(this.estimate)
        }
      }
    },

    showLoading(message, subMessage) {
      dialogs.showLoading(message, subMessage)
    },

    hideLoading() {
      dialogs.hideLoading()
    },

    showPrintingMessage() {
      this.showLoading('印刷（PDF出力）処理中です・・・')
    },

    hidePrintingMessage() {
      this.hideLoading()
    },
    
    /**
     * 承認された見積りを集計のために保存
     * @param est 見積りデータ
     */
    async setCountData(est) {
      // 物件住所がないものは集計しない
      if (!est.buildingAddress2 || est.buildingAddress2 == '') {
        return
      }
      // 区分がないものは集計しない
      if (!est.classification || est.classification == '') {
        return
      }
      // サブ担当者のIDがなければ＆未定でもなければ、名前からIDを取得
      if ((!est.taskStaffId || est.taskStaffId == '') && est.taskStaff != '未定') {
        est.taskStaffId = utils.getUserIdByName(est.taskStaff, this.$store.getters.userList)
      }
      
      let staffId = null
      if (est.taskStaffId && !isNaN(est.taskStaffId)) {
        staffId = est.taskStaffId
      }

      // 部署IDを正しいものを再取得
      let departmentId = null
      if (!est.departmentIdSubTask || est.departmentIdSubTask == '') {
        if (staffId) {
          let dep = await this.getDepartments(staffId)
          if (dep && dep.departmentId) {
            departmentId = dep.departmentId
          }
        }
      } else {
        departmentId = est.departmentIdSubTask
      }

      let scheduledDays = null
      scheduledDays = utils.getScheduleDays(this.mainData.estimateCategory)
      let category = null
      if (this.mainData.estimateCategory) {
        category = this.mainData.estimateCategory.join('、')
      }

      // 同一物件判定は、得意先名OR得意先住所が一致＋物件名OR物件住所が一致
      // 担当者はサブタスク担当者
      const list = {
        estimateNo: est.estimateNo,
        staffId: staffId,
        staff: utils.deleteKana(est.taskStaff),
        departmentIdSubTask: departmentId,
        clientAddress: utils.deleteMark(utils.deleteSpace(utils.hankaku(est.clientAddress1))),
        siteAddress: utils.deleteMark(utils.deleteSpace(utils.hankaku(est.buildingAddress2))),
        classification: est.classification,
        approvedDate: utils.getToday(),
        client: utils.deleteMark(est.clientName),
        site: utils.deleteMark(est.buildingName),
        test: est.test,
        insertUser: this.$store.getters.user.id,
        // 以下は 2024/6 追加
        // メインタスク依頼日
        requestDate: this.mainData.requestDate,
        // 見積り申請日
        approvalPetitionDate: est.approvalPetitionDate,
        // 目安日数
        scheduledDays,
        // 見積りカテゴリー（メイン）
        category,
        // メインのID
        requestPostId: this.mainData._id,
        // 税抜き金額
        withoutTax: est.withoutTax,
        // メインの問い合わせNo
        requestNo: this.mainData.requestNo
      }

      let estimateApprovedResult = await backend.postData('estimateApproved/save', list)
      if (estimateApprovedResult.data == 'Internal Server Error') {
        alert('集計用にデータを保存中にエラーが発生しました。\r\nAPIコンソールを確認してください。')
      }
    },

    /**
     * 承認ボタンイベント
     */
    async permit(modalShow) {
      try {
        this.isLoadingApproval = true

        let result

        // 承認者1が「承認済み」でなければ、承認者1からユーザーを探し、ステータスを承認済みにする
        if (this.estimate.approval1 != '承認済み') {
          // let app1 = this.estimate.approvalFlow1
          this.estimate.approvalFlow1.forEach((a) => {
            if (a.userId == this.userId) {
              a.status = '承認済み'
              a.stamp = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}stamps/${this.$store.getters.user.id}.png`
              a.date = moment().format('YYYY-MM-DD HH:mm:ss')
            }
          })

          let wait1 = this.estimate.approvalFlow1.filter((a) => {
            return a.status == '未承認'
          })

          // 承認者1で全員承認済みの場合
          if (!wait1.length) {
            this.estimate.approval1 = '承認済み'

            // 承認者2が設定されている場合は、ダイアログを表示
            if (modalShow && this.estimate.approvalFlow2.length && this.estimate.approval2 == '承認待ち') {
              this.approval2ModalShow = true
              return
            }
            
            // 承認者2が設定されていない場合
            if (!this.estimate.approvalFlow2.length) {
              // 見積りステータスを「作成完了」
              this.estimate.estimateStatus = '作成完了'
            }
          }

        // 承認者1が既に全員「承認済み」ならば
        } else {
          // 承認2のチャット送信モーダルを閉じた際、チャット送信ONの場合
          if (!modalShow && this.estimate.approvalInfo.chatwork2) {
            this.sendChatWorkMessage()
          }

          if (modalShow) {
            
            // 承認者2からユーザーを探し、ステータスを承認済みにする
            this.estimate.approvalFlow2.forEach((a) => {
              if (a.userId == this.userId) {
                a.status = '承認済み'
                a.stamp = `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}stamps/${this.$store.getters.user.id}.png`
                a.date = moment().format('YYYY-MM-DD HH:mm:ss')
              }
            })

            let wait2 = this.estimate.approvalFlow2.filter((a) => {
              return a.status == '未承認'
            })

            // 承認者2で全員承認済みの場合
            if (!wait2.length) {
              this.estimate.approval2 = '承認済み'
              // 見積りステータスを「作成完了」にする
              this.estimate.estimateStatus = '作成完了'
            }
          }
        }

        // 更新
        result = await estimateManager.updateEstimate(this, this.estimate)
        if (result) {
          if (this.estimate.estimateStatus == '作成完了') {
            // 集計用に保存_アップサート
            await this.setCountData(this.estimate)
            // 見積作成者にチャット送信
            this.sendChatWorkFromApprover('承認')
            // ログに保存
            logManager.recording(this, logManager.Loglevel.INFO, '見積', '作成完了', '見積（見積番号：' + this.estimate.estimateNo + '）は承認され作成完了しました。')
          }
          
          // 承認者の表示
          this.setApproverName()
          this.approval2ModalShow = false
          this.apploved = true

          // 「担当の承認待ち」一覧に遷移
          this.$router.push({ 
            name: 'EstimateList',
            query: {
              tabId: 'awaitingApproval'
            }
          })

        } else {
          await dialogs.showErrorDialog('承認エラー', '承認できませんでした。')
        }
      } finally {
        this.isLoadingApproval = false
      }
    },

    /**
     * 所属部署 取得
     * @param {*} userId 
     */
    async getDepartments(userId) {
      let dep = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: userId})
      if (dep.data.data.departmentId) {
        return { departmentId: dep.data.data.departmentId, departmentName: dep.data.data.departmentName }
      } else {
        return { departmentId: '', departmentName: '' }
      }
    },

    /**
     * 差戻・却下ボタンイベント
     * @param type 差戻、もしくは却下どちらか
     */
    async sendingBack(type) {
      try {
        // 差戻・却下理由のチェック
        if (!this.estimate.nowReasonForSendingBack) {
          const dialogResult = await dialogs.showWarningConfirmDialog(type, '理由が記入されていませんが、よろしいですか？')
          if (dialogResult != 'YES') {
            return
          } else {
            this.estimate.nowReasonForSendingBack = '*' + type + '理由記入なし*'
          }
        }

        this.isLoadingApproval = true

        let result

        this.estimate.approval1 = type
        if (this.estimate.approval2 != '---') {
          this.estimate.approval2 = type
        }

        // 承認者全員のステータスを「未承認」にする
        this.estimate.approvalFlow1.forEach((a1) => {
          a1.status = '未承認'
          if (a1.userId == this.userId) {
            a1.date = moment().format('YYYY/MM/DD HH:mm:ss')
          }
        })
        if (this.estimate.approvalFlow2.length && this.estimate.approvalFlow2.length > 0) {
          this.estimate.approvalFlow2.forEach((a2) => {
            a2.status = '未承認'
          if (a2.userId == this.userId) {
            a2.date = moment().format('YYYY/MM/DD HH:mm:ss')
          }
          })
        }

        this.estimate.estimateStatus = type
        let txt = type
        if (type == '差戻') {
          txt += '者'
        }
        this.estimate.nowReasonForSendingBack += '\n' + moment().format('YYYY/MM/DD HH:mm') + '  ' + txt + '：' + this.userName
        // 前回の理由＋今回の理由
        this.estimate.reasonForSendingBack = this.estimate.nowReasonForSendingBack + '\r\n\r\n' + this.estimate.reasonForSendingBack
        this.estimate.nowReasonForSendingBack = ''
        // 更新
        result = await estimateManager.updateEstimate(this, this.estimate)
        if (result) {
          this.apploved = true
          // ログに保存
          logManager.recording(this, logManager.Loglevel.INFO, '見積', type, '見積（見積番号：' + this.estimate.estimateNo + '）は' + type + 'されました。')

          this.sendChatWorkFromApprover(type)

          // 「担当の承認待ち」一覧に遷移
          this.$router.push({ 
            name: 'EstimateList',
            query: {
              tabId: 'awaitingApproval'
            }
          })
          
        } else {
          await dialogs.showErrorDialog(type, type + 'できませんでした。')
        }
      } finally {
        this.isLoadingApproval = false
      }
    },

    /**
     * チャットワーク送信 承認者へ
     */
    async sendChatWorkMessage() {
      if (ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE && ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.enable) {

        let cannotSend = []
        let mension = ''

        // ルームIDをそれぞれの認証者用に変更_2022.11.08ご要望
        let r = await this.$pigeon.getTasksByFormId('estimate_approver_chatwork_master')

        for (let i = 0; i < this.estimate.approvalFlow2.length; i++) {
          
          let chatInfo = this.estimate.approvalFlow2[i]
          
          if (r.length) {
            let roomId = ''
            for (let rIdx = 0; rIdx < r.length; rIdx++) {
              const result = r[rIdx]
              // 承認者用のルームIDがある場合
              if (result.userId == chatInfo.userId) {
                roomId = result.roomId

                // チャットワーク送る
                if (chatInfo.chatworkAccountId != '') {
                  mension = `[To:${chatInfo.chatworkAccountId}] (承認者1) ${chatInfo.userName}さん`
                } else {
                  mension = `[To] (承認者1) ${chatInfo.userName}さん`
                }
                const context = {
                  mension,
                  user: utils.deleteKana(this.estimateCreater),
                  estimateNo: this.estimate.estimateNo,
                  requestPostName: this.estimate.requestPostName,
                  clientName: this.estimate.clientName,
                  buildingName: this.estimate.buildingName,
                  message: this.estimate.approvalInfo.message2 || '',
                  viewUrl: `${utils.getSystemOrigin()}/EstimateView?estimateNo=${this.estimate.estimateNo}&approver=true&sequentialOrder=2`
                }

                const expression = '`' + ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.messageTemplate + '`' 
                const chatworkApiToken = ESTIMATE_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE.chatworkApiToken

                const message = utils.evaluate(context, expression)
                console.log(message)
                await chatwork.sendChatWorkMssage(
                  roomId, 
                  message,
                  chatworkApiToken
                )
                break
              }
            }
            // 送信不可リスト作成
            if (roomId == '') {
              cannotSend.push(this.estimate.approvalFlow2[i].userName + 'さん')
            }
          } else {
            await dialogs.showErrorDialog('チャット送信不可', '承認者用のroomIDが登録されていません。\r\nマスタメンテの「承認チャットワーク」にて登録してください。')
            return
          }
        }

        if (cannotSend.length) {
          await dialogs.showErrorDialog('チャット送信不可', cannotSend.join('、') + 'のチャットワークのRoomIDが登録されていないので送れません。\r\nマスタメンテの「承認チャットワーク」にて登録してください。')
        }
      }
    },

    /**
     * チャットワーク送信 承認者から作成者へ
     * @param type 差戻か却下か承認か
     */
    async sendChatWorkFromApprover(type) {
      if (ESTIMATE_SENDING_BACK_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE && ESTIMATE_SENDING_BACK_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE.enable) {
        
        let mension = ''
        let chatworkRoomId = ''
        let chatworkApiToken = ''
        let chatworkAccountId = ''
        let result = null

        // estimateCreaterがある場合
        if (this.estimate.estimateCreater && this.estimate.estimateCreater != '') {
          result = this.estimate.estimateCreater

        // estimateCreaterがなければ、userStampからIDを取得
        } else if (this.estimate.userStamp && this.estimate.userStamp != ''){
          let num = this.estimate.userStamp.length - 8
          result = this.estimate.userStamp.substr(num, 4)
        } else {
          const createrR = await dialogs.showConfirmDialog('チャット送信不可', '作成者が不明なためチャットを送信できませんでした。\r\n担当者にチャット送信しますか？')
          if (createrR == 'YES') {
            await this.sendChatWorkFromApproverTaskStaff(type)
            return
          }
          return
        }
        
        let name = utils.getUserNameById(result, this.$store.getters.userList)
        if (name && name != '') {
          name = utils.deleteKana(name)
        }

        let info = await this.getChatData(result)
        chatworkAccountId = info.chatworkAccountId
        chatworkRoomId = info.room
        chatworkApiToken = info.api

        if (!chatworkRoomId || chatworkRoomId == '') {
          await dialogs.showErrorDialog('チャット送信不可', 'ルームIDが不明なためチャットを送信できませんでした。\r\n対象の部署データにルームIDが登録されているかご確認ください。')
          return
        } else if (!chatworkApiToken || chatworkApiToken == '') {
          await dialogs.showErrorDialog('チャット送信不可', 'APIトークンが不明なためチャットを送信できませんでした。\r\n対象の部署データにAPIトークンが登録されているかご確認ください。')
          return
        }

        // チャットワーク送る
        if (chatworkAccountId && chatworkAccountId != '') {
          mension = `[To:${chatworkAccountId}] (見積作成者) ${name}さん`
        } else {
          mension = `[To] (見積作成者) ${name}さん`
        }

        const context = {
          type,
          mension,
          estimateNo: this.estimate.estimateNo,
          requestPostName: this.estimate.requestPostName,
          clientName: this.estimate.clientName,
          reason: this.estimate.reasonForSendingBack,
          viewUrl: `${utils.getSystemOrigin()}/estimate?estimateNo=${this.estimate.estimateNo}`
        }

        let expression = '`' + ESTIMATE_SENDING_BACK_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE.messageTemplate + '`' 
        // 承認済みのチャットの場合
        if (type == '承認') {
          expression = '`' + ESTIMATE_SENDING_APPROVED_OK.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVED_OK_MESSAGE.messageTemplate + '`' 
          context.viewUrl = `${utils.getSystemOrigin()}/EstimateView?estimateNo=${this.estimate.estimateNo}`
        }

        const message = utils.evaluate(context, expression)
        console.log(message)
        await chatwork.sendChatWorkMssage(
          chatworkRoomId, 
          message,
          chatworkApiToken
        )
      }
    },

    /**
     * 作成者不明の場合に担当者にチャット送信
     * @param type 差戻か却下か
     */
    async sendChatWorkFromApproverTaskStaff(type) {
        const name = utils.deleteKana(this.estimate.taskStaff).replace(/\s+/g, '')
        
        let mension = ''
        let chatworkRoomId = ''
        let chatworkApiToken = ''
        let chatworkAccountId = ''
        let result = null

        // サブタスク担当者IDがあれば、ＩＤから検索
        if (this.estimate.taskStaffId && this.estimate.taskStaffId != '') {
          result = this.estimate.taskStaffId
        // サブタスク担当者IDがなければ、名前から検索
        } else if (this.estimate.taskStaff && this.estimate.taskStaff != ''){
          result = utils.getUserIdByName(this.estimate.taskStaff, this.$store.getters.userList)
        } else {
          await dialogs.showErrorDialog('チャット送信不可', '担当者が不明なためチャットを送信できませんでした。')
          return
        }

        let info = await this.getChatData(result)
        chatworkAccountId = info.chatworkAccountId
        chatworkRoomId = info.room
        chatworkApiToken = info.api

        if (!chatworkRoomId || chatworkRoomId == '') {
          await dialogs.showErrorDialog('チャット送信不可', 'ルームIDが不明なためチャットを送信できませんでした。\r\n対象の部署データにルームIDが登録されているかご確認ください。')
          return
        } else if (!chatworkApiToken || chatworkApiToken == '') {
          await dialogs.showErrorDialog('チャット送信不可', 'APIトークンが不明なためチャットを送信できませんでした。\r\n対象の部署データにAPIトークンが登録されているかご確認ください。')
          return
        }

        // チャットワーク送る
        if (chatworkAccountId && chatworkAccountId != '') {
          mension = `[To:${chatworkAccountId}] (見積担当者) ${name}さん`
        } else {
          mension = `[To] (見積担当者) ${name}さん`
        }

        const context = {
          type,
          mension,
          estimateNo: this.estimate.estimateNo,
          requestPostName: this.estimate.requestPostName,
          clientName: this.estimate.clientName,
          reason: this.estimate.reasonForSendingBack,
          viewUrl: `${utils.getSystemOrigin()}/estimate?estimateNo=${this.estimate.estimateNo}`
        }

        let expression = '`' + ESTIMATE_SENDING_BACK_FEATURES.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE.messageTemplate + '`' 
        // 承認済みのチャットの場合
        if (type == '承認') {
          expression = '`' + ESTIMATE_SENDING_APPROVED_OK.ESTIMATION.SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVED_OK_MESSAGE.messageTemplate + '`' 
        }

        const message = utils.evaluate(context, expression)
        console.log(message)
        await chatwork.sendChatWorkMssage(
          chatworkRoomId, 
          message,
          chatworkApiToken
        )
    },

  /**
   * ユーザーの所属している部署のチャットワーク情報を取得
   * @param userId ユーザーID
   */
    async getChatData(userId) {
      let chatworkAccountId = ''
      let chatworkRoomId = ''
      let chatworkApiToken = ''

      let info = await backend.searchData('user/getAllDataByUser', {day: utils.getToday(), userId: userId})
      if (info.data.data) {
        chatworkAccountId = info.data.data.chatworkAccountId
        chatworkRoomId = info.data.data.chatworkRoomId
        chatworkApiToken = info.data.data.chatworkApiTokenDepartment
      }
      return {chatworkAccountId, room: chatworkRoomId, api: chatworkApiToken}
    },

    /**
     * 契約書出力
     */
    async downloadContract() {
      try {
        // 見積Noに紐づく受注データを取得
        const orderData = await orderManager.getByEstimateNo(this, this.estimate.estimateNo)
        if (!orderData || orderData.length != 1) {
          await dialogs.showErrorDialog('契約書エラー', '契約書をダウンロードできませんでした。')
          return
        }
        let o = orderData[0]
        // 契約日、契約終了日を年月日形式に変換
        if (o.contractDate) {
          o.stringContractDate = utils.formatDateString(o.contractDate, '{yyyy}年{M}月{d}日')
          o.stringContractDate = utils.zenkaku(o.stringContractDate)
        } else {
          o.stringContractDate = ''
        }
        if (o.contractEndDate) {
          o.stringContractEndDate = utils.formatDateString(o.contractEndDate, '{yyyy}年{M}月{d}日') + 'までの'
          o.stringContractEndDate = utils.zenkaku(o.stringContractEndDate)
        } else {
          o.stringContractEndDate = ''
        }

        // 受注データに建物名を追加
        o.buildingName2 = this.estimate.buildingName
        // 受注データに住所を追加
        o.buildingAddress = utils.zenkaku(this.estimate.buildingAddress2)
        // 見積明細カテゴリーで重複しているカテゴリーを削除
        if (o.estimationDetailsCategory) {
          o.newEstimationDetailsCategory = Array.from(new Set(o.estimationDetailsCategory))
        } else {
          o.newEstimationDetailsCategory = []
        }

        if (o.estimationDetailsCategoryCode) {
          o.newEstimationDetailsCategoryCode = Array.from(new Set(o.estimationDetailsCategoryCode))
        } else {
          o.newEstimationDetailsCategoryCode = []
        }

        await backend.contractOutput({order: o, estimate: this.estimate.details})
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * メイン一覧、サブ進捗一覧へ遷移
     * @param name 遷移先
     */
    pageTransition(name) {
      this.$router.push({ 
        name: name,
        query: {
          requestNo: this.requestNo
        }
      })
    }
  }
}
</script>
<style scoped>

.list {
  width: 99vw;
}

.w-130p {
  width: 130px;
}

@media screen and (max-width: 1400px) {
  .list {
    min-width: 1400px !important;
  }
}
</style>