<template>
  <div class="flex-1 p-4">

    <InputGroupLabel
      name="categoryTitle"
      caption="雇い入れ"
      class="mt-4"
    />

    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 self-start mt-3">

      <div class="sm:col-span-full six-selection">
        <SelectionButton2
          name="employmentClass" 
          caption="種別"
          v-model="requestPost.employmentClass"
          :item="classSelections"
          :multiSelect="false"
          :required="true"
          :error="!requireValidateArr('employmentClass', '種別').result"
          :errorMessage="requireValidateArr('employmentClass', '種別').message"
          :disabled="referenceMode"
          @change="changeEmploymentClass()"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('退職日')">
        <WmsDateInput
          v-if="!referenceMode"
          name="employmentRetirementDate"
          caption="退職日" 
          v-model="requestPost.employmentRetirementDate"
          :readonly="isModileDevice"
          :clearable="isModileDevice"
          :required="isRequired('退職日')"
          :error="!requireValidateStr('employmentRetirementDate', '退職日').result"
          :errorMessage="requireValidateStr('employmentRetirementDate', '退職日').message"
        />
        <WmsTextInput
          v-else
          name="employmentRetirementDate"
          caption="退職日" 
          :disabled="true"
          v-model="requestPost.employmentRetirementDate"
          :required="isRequired('退職日')"
        />
      </div>

      <div class="sm:col-span-full">
        <NumericTextInput2
          name="employmentStaffNumber" 
          caption="社員番号"
          v-model="requestPost.employmentStaffNumber"
          :format="false"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('氏名')&&requestPost.employmentClass&&requestPost.employmentClass[0]=='新規入社'">
        <WmsTextInput
          name="employmentName" 
          caption="氏名"
          v-model="requestPost.employmentName"
          :disabled="referenceMode"
          :required="isRequired('氏名')"
          :error="!requireValidateStr('employmentName', '氏名').result"
          :errorMessage="requireValidateStr('employmentName', '氏名').message"
          @change="setTitle()"
        />
      </div>
      <div class="sm:col-span-full" v-else-if="isShow('氏名')">
        <WmsSuggestInput
          name="employmentName" 
          caption="氏名"
          :required="isRequired('氏名')"
          :selectionItems="employmentNameList"
          displayField="nameStaff"
          v-model="requestPost.employmentName"
          :disabled="referenceMode"
          :error="!requireValidateStr('employmentName', '氏名').result"
          :errorMessage="requireValidateStr('employmentName', '氏名').message"
          @selected="staffSelected($event)"
          @blur="staffChanged()"
        >
        </WmsSuggestInput>
      </div>

      <!-- <div class="sm:col-span-full" v-if="isOther&&isShow('物件名')">
        <WmsTextInput
          name="employmentBuildingName"
          caption="物件名"
          v-model="requestPost.employmentBuildingName"
          :disabled="referenceMode"
          :required="isRequired('物件名')"
          :error="!requireValidateStr('employmentBuildingName', '物件名').result"
          :errorMessage="requireValidateStr('employmentBuildingName', '物件名').message"
        />
      </div> -->
      <div class="sm:col-span-full" v-if="isOther&&isShow('物件名')">
        <WmsSuggestInput
          name="employmentBuildingName" 
          caption="物件名"
          :required="isRequired('物件名')"
          :selectionItems="employmentBuildingList"
          displayField="nameSite"
          v-model="requestPost.employmentBuildingName"
          :disabled="referenceMode"
          :error="!requireValidateStr('employmentBuildingName', '物件名').result"
          :errorMessage="requireValidateStr('employmentBuildingName', '物件名').message"
          @selected="siteSelected($event)"
          @blur="siteChanged()"
        >
          <!-- 検索アイコン -->
          <template #addon-content>
            <Icon
              iconName="Search" 
              :clickable="true" 
              class="w-4 h-4 mr-0.5"
              @click="openSiteModal()"
            />
          </template>
        </WmsSuggestInput>
      </div>

      <div class="sm:col-span-full" v-if="isOther&&isShow('物件名')">
        <WmsTextInput
          name="employmentSiteCode" 
          caption="物件コード"
          v-model="requestPost.employmentSiteCode"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isOther&&isShow('物件名')">
        <WmsTextInput
          name="employmentSiteAddress" 
          caption="物件住所"
          v-model="requestPost.employmentSiteAddress"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isOther">
        <WmsDateInput
          v-if="!referenceMode"
          name="employmentChangedDate"
          caption="変更日" 
          v-model="requestPost.employmentChangedDate"
          :readonly="isModileDevice"
          :clearable="isModileDevice"
          :required="isRequired('変更日')"
          :error="!requireValidateStr('employmentChangedDate', '変更日').result"
          :errorMessage="requireValidateStr('employmentChangedDate', '変更日').message"
        />
        <WmsTextInput
          v-else
          name="employmentChangedDate"
          caption="変更日" 
          :disabled="true"
          v-model="requestPost.employmentChangedDate"
          :required="isRequired('変更日')"
        />
      </div>

      <div class="sm:col-span-full" v-if="isOther">
        <WmsTextInput 
          name="employmentRemarks" 
          caption="連絡事項"
          v-model="requestPost.employmentRemarks"
          :multiline="true"
          :rows="5"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('フリガナ')">
        <WmsTextInput
          name="employmentKana" 
          caption="フリガナ"
          v-model="requestPost.employmentKana"
          :disabled="referenceMode"
          :required="isRequired('フリガナ')"
          :error="!requireValidateStr('employmentKana', 'フリガナ').result"
          :errorMessage="requireValidateStr('employmentKana', 'フリガナ').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('生年月日')">
        <WmsDateInput
          v-if="!referenceMode"
          name="employmentBirthDay"
          caption="生年月日" 
          v-model="requestPost.employmentBirthDay"
          @change="ageCalculation"
          :readonly="isModileDevice"
          :clearable="isModileDevice"
          :required="isRequired('生年月日')"
          :error="!requireValidateStr('employmentBirthDay', '生年月日').result"
          :errorMessage="requireValidateStr('employmentBirthDay', '生年月日').message"
        />
        <WmsTextInput
          v-else
          name="employmentBirthDay"
          caption="生年月日" 
          :disabled="true"
          :required="isRequired('生年月日')"
          v-model="requestPost.employmentBirthDay"
        />
      </div>

      <div class="sm:col-span-full text-blue-800" v-if="isShow('生年月日')">
        <div v-if="requestPost.employmentBirthDay" class="mt-auto mb-2 mx-3 text-sm">
          和暦： {{ japaneseDate(requestPost.employmentBirthDay) }}
        </div>
      </div>

      <div class="sm:col-span-full" v-if="isShow('年齢')">
        <NumericTextInput2 
          name="employmentAge" 
          caption="年齢"
          maxLenght="3"
          v-model="requestPost.employmentAge"
          :disabled="referenceMode"
          :required="isRequired('年齢')"
          :error="!requireValidateStr('employmentAge', '年齢').result"
          :errorMessage="requireValidateStr('employmentAge', '年齢').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('性別')">
        <SelectionButton2
          name="employmentSex" 
          caption="性別"
          v-model="requestPost.employmentSex"
          :item="sexSelections"
          :multiSelect="false"
          :disabled="referenceMode"
          :required="isRequired('性別')"
          :error="!requireValidateArr('employmentSex', '性別').result"
          :errorMessage="requireValidateArr('employmentSex', '性別').message"
        />
      </div>

      <div class="sm:col-span-3" v-if="isShow('郵便番号')">
        <WmsTextInput 
          name="employmentPostCode" 
          caption="郵便番号"
          placeholder="000-0000"
          v-model="requestPost.employmentPostCode"
          :disabled="referenceMode"
          :required="isRequired('郵便番号')"
          :error="!requireValidateStr('employmentPostCode', '郵便番号').result"
          :errorMessage="requireValidateStr('employmentPostCode', '郵便番号').message"
        />
      </div>
      <div class="sm:col-span-3 adressbutton" v-if="!referenceMode&&isShow('郵便番号')">
        <PrimaryButton text="住所検索" class="w-40 adressbutton bg-green-500" @click="addressSearch()">
        </PrimaryButton>

        <PrimaryButton text="住所から取得" class="w-40 bg-green-500" @click="postalCodeSearch()">
        </PrimaryButton>
      </div>

      <div class="sm:col-span-full" v-if="isShow('住所１')">
        <WmsTextInput
          name="employmentAddress1" 
          caption="住所１"
          v-model="requestPost.employmentAddress1"
          :disabled="referenceMode"
          :required="isRequired('住所１')"
          :error="!requireValidateStr('employmentAddress1', '住所１').result"
          :errorMessage="requireValidateStr('employmentAddress1', '住所１').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('住所２')">
        <WmsTextInput
          name="employmentAddress2"
          caption="住所２"
          v-model="requestPost.employmentAddress2"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('固定電話')">
        <label for="employmentTel" class="block text-xs font-bold text-gray-700">固定電話</label>
          <div class="mt-1 flex relative">
            <input
              type="tel" 
              name="employmentTel"
              v-model="requestPost.employmentTel"
              :disabled="referenceMode"
              class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
              :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
              @keypress="onKeyPressTel"
            />
          </div>
      </div>

      <div class="sm:col-span-full" v-if="isShow('携帯電話')">
        <label for="employmentMobile" class="block text-xs font-bold text-gray-700 relative">
          携帯電話
          <span v-if="isRequired('携帯電話')" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
          <span v-if="errMobile" class="text-xs font-bold text-red-500 ml-5">{{ errMobile }}</span>
        </label>
          <div class="mt-1 flex relative">
            <InputIcon v-if="!requireValidateStr('employmentMobile', '携帯電話').result" iconName="ExclamationCircle" iconColor="red" :message="requireValidateStr('employmentMobile', '携帯電話').message" />
            <input
              type="tel" 
              name="employmentMobile"
              v-model="requestPost.employmentMobile"
              :disabled="referenceMode"
              class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
              :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
              @keypress="onKeyPressTel"
            />
          </div>
      </div>

      <div class="sm:col-span-full" v-if="isShow('振込銀行')">
        <WmsTextInput
          name="employmentBank"
          caption="振込銀行"
          v-model="requestPost.employmentBank"
          :disabled="referenceMode"
        />
      </div>
      <div class="sm:col-span-full" v-if="isShow('支店名')">
        <WmsTextInput
          name="employmentBankBranch"
          caption="支店名"
          v-model="requestPost.employmentBankBranch"
          :disabled="referenceMode"
        />
      </div>
      <div class="sm:col-span-full" v-if="isShow('口座番号')">
        <WmsTextInput
          name="employmentBankAccountNumber"
          caption="口座番号"
          v-model="requestPost.employmentBankAccountNumber"
          :disabled="referenceMode"
        />
      </div>
      <div class="sm:col-span-full" v-if="isShow('口座名義')">
        <WmsTextInput
          name="employmentBankAccountName"
          caption="口座名義"
          v-model="requestPost.employmentBankAccountName"
          :disabled="referenceMode"
        />
      </div>

      <InputGroupLabel
        v-if="isShow('緊急連絡先')"
        name="categoryTitle"
        caption="緊急連絡先"
        class="mt-10"
      />

      <div class="sm:col-span-full" v-if="isShow('緊急連絡先')">
        <WmsTextInput
          name="employmentEmergencyContactKana"
          caption="フリガナ"
          v-model="requestPost.employmentEmergencyContactKana"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('緊急連絡先')">
        <WmsTextInput
          name="employmentEmergencyContact"
          caption="氏名"
          v-model="requestPost.employmentEmergencyContact"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('緊急連絡先')">
        <WmsTextInput
          name="employmentEmergencyRelationship"
          caption="御関係"
          v-model="requestPost.employmentEmergencyRelationship"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('緊急連絡先')">
        <WmsTextInput
          name="employmentEmergencyAddress"
          caption="住所"
          v-model="requestPost.employmentEmergencyAddress"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('緊急連絡先')">
        <label for="employmentEmergencyPhoneNumber" class="block text-xs font-bold text-gray-700">電話番号</label>
        <div class="mt-1 flex relative">
          <input
            type="tel" 
            name="employmentEmergencyPhoneNumber"
            v-model="requestPost.employmentEmergencyPhoneNumber"
            :disabled="referenceMode"
            class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
            :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-500':''"
            @keypress="onKeyPressTel"
          />
        </div>
      </div>

      <InputGroupLabel class="mt-1 mb-10" v-if="isShow('緊急連絡先')" />

      <div class="sm:col-span-full" v-if="requestPost.employmentClass.includes('新規入社')||requestPost.employmentClass.includes('再入社')">
        <NumericTextInput2
          name="employmentStaffNumber" 
          caption="社員番号"
          v-model="requestPost.employmentStaffNumber"
          :format="false"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('入社日')">
        <WmsDateInput
          v-if="!referenceMode"
          name="employmentHireDate"
          caption="入社日" 
          v-model="requestPost.employmentHireDate"
          :readonly="isModileDevice"
          :clearable="isModileDevice"
          :required="isRequired('入社日')"
          :error="!requireValidateStr('employmentHireDate', '入社日').result"
          :errorMessage="requireValidateStr('employmentHireDate', '入社日').message"
        />
        <WmsTextInput
          v-else
          name="employmentHireDate"
          caption="入社日" 
          :disabled="true"
          v-model="requestPost.employmentHireDate"
          :required="isRequired('入社日')"
        />
      </div>

      <div class="sm:col-span-full" v-if="!isOther&&isShow('物件名')">
        <WmsSuggestInput
          name="employmentBuildingName" 
          caption="物件名"
          :required="isRequired('物件名')"
          :selectionItems="employmentBuildingList"
          displayField="nameSite"
          v-model="requestPost.employmentBuildingName"
          :disabled="referenceMode"
          :error="!requireValidateStr('employmentBuildingName', '物件名').result"
          :errorMessage="requireValidateStr('employmentBuildingName', '物件名').message"
          @selected="siteSelected($event)"
          @blur="siteChanged()"
        >
          <!-- 検索アイコン -->
          <template #addon-content>
            <Icon
              iconName="Search" 
              :clickable="true" 
              class="w-4 h-4 mr-0.5"
              @click="openSiteModal()"
            />
          </template>
        </WmsSuggestInput>
      </div>

      <div class="sm:col-span-full" v-if="!isOther&&isShow('物件名')">
        <WmsTextInput
          name="employmentSiteCode" 
          caption="物件コード"
          v-model="requestPost.employmentSiteCode"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="!isOther&&isShow('物件名')">
        <WmsTextInput
          name="employmentSiteAddress" 
          caption="物件住所"
          v-model="requestPost.employmentSiteAddress"
          :disabled="referenceMode"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('エリア')">
        <div class="text-xs text-gray-600 font-bold relative" style="margin-bottom:4px;">
          エリア <span v-if="isRequired('エリア')" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
        </div>
        <div class="relative">
          <InputIcon v-if="!requireValidateStr('employmentArea', 'エリア').result" iconName="ExclamationCircle" iconColor="red" :message="requireValidateStr('employmentArea', 'エリア').message" />
          <select
              class="w-full rounded border-gray-300 text-sm"
              v-model="requestPost.employmentArea"
              :disabled="referenceMode"
              :class="referenceMode?'bg-gray-200 cursor-not-allowed text-gray-800':''"
          >
              <option v-for="(row, index) in areaSelections" :value="row" :key="index">
                  {{ row }}
              </option>
          </select>
        </div>
      </div>

      <div class="sm:col-span-full" v-if="isShow('得意先名')">
        <WmsTextInput
          name="employmentCustomer"
          caption="得意先名"
          v-model="requestPost.employmentCustomer"
          :disabled="referenceMode"
          :required="isRequired('得意先名')"
          :error="!requireValidateStr('employmentCustomer', '得意先名').result"
          :errorMessage="requireValidateStr('employmentCustomer', '得意先名').message"
        />
      </div>

      <div class="sm:col-span-full five-selection" v-if="isShow('作業内容')">
        <SelectionButton2
          name="employmentSex" 
          caption="作業内容"
          v-model="requestPost.employmentWorkTask"
          :item="workSelections"
          :multiSelect="false"
          :disabled="referenceMode"
          :required="isRequired('作業内容')"
          :error="!requireValidateArr('employmentWorkTask', '作業内容').result"
          :errorMessage="requireValidateArr('employmentWorkTask', '作業内容').message"
        />
      </div>

      <div class="sm:col-span-full mt-10" v-if="isShow('時給')">
        <NumericTextInput2 
          name="employmentHourlyWage" 
          caption="時給"
          v-model="requestPost.employmentHourlyWage"
          @change = "limitHourlyWageInput"
          :disabled="referenceMode"
          :required="isRequired('時給')"
          :error="!requireValidateStr('employmentHourlyWage', '時給').result"
          :errorMessage="requireValidateStr('employmentHourlyWage', '時給').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('締め日')">
        <SelectionButton2
          name="employmentClosingDate" 
          caption="締め日"
          v-model="requestPost.employmentClosingDate"
          :item="closingDate"
          :multiSelect="false"
          :disabled="referenceMode"
        />
      </div>

      
      <InputGroupLabel
        v-if="isShow('交通費')"
        name="categoryTitle"
        caption="交通費（不要であれば0を入力）"
        class="mt-10"
      />

      <div class="sm:col-span-full grid grid-cols-10" v-if="isShow('交通費')">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="employmentTrainType" 
            caption="電車 区分"
            v-model="requestPost.employmentTrainType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            :required="isRequired('電車 区分')"
            :error="!requireValidateArr('employmentTrainType', '電車 区分').result"
            :errorMessage="requireValidateArr('employmentTrainType', '電車 区分').message"
            @change="changeTrans('employmentTrainType', 'employmentTrainExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="employmentTrainExpenses" 
            caption="電車 交通費"
            maxLenght="5"
            v-model="requestPost.employmentTrainExpenses"
            :disabled="referenceMode"
            :required="isRequired('電車 交通費')"
            :error="!requireValidateStr('employmentTrainExpenses', '電車 交通費').result"
            :errorMessage="requireValidateStr('employmentTrainExpenses', '電車 交通費').message"
          />
        </div>
      </div>

      <div class="sm:col-span-full grid grid-cols-10" v-if="isShow('交通費')">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="employmentBusType" 
            caption="バス 区分"
            v-model="requestPost.employmentBusType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            :required="isRequired('バス 区分')"
            :error="!requireValidateArr('employmentBusType', 'バス 区分').result"
            :errorMessage="requireValidateArr('employmentBusType', 'バス 区分').message"
            @change="changeTrans('employmentBusType', 'employmentBusExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="employmentBusExpenses" 
            caption="バス 交通費"
            maxLenght="5"
            v-model="requestPost.employmentBusExpenses"
            :disabled="referenceMode"
            :required="isRequired('バス 交通費')"
            :error="!requireValidateStr('employmentBusExpenses', 'バス 交通費').result"
            :errorMessage="requireValidateStr('employmentBusExpenses', 'バス 交通費').message"
          />
        </div>
      </div>

      <div class="sm:col-span-full grid grid-cols-10" v-if="isShow('交通費')">
        <div class="sm:col-span-5 mr-5">
          <SelectionButton2
            name="employmentParkingType" 
            caption="駐輪 区分"
            v-model="requestPost.employmentParkingType"
            :item="provisionSelections"
            :multiSelect="false"
            :disabled="referenceMode"
            :required="isRequired('駐輪 区分')"
            :error="!requireValidateArr('employmentParkingType', '駐輪 区分').result"
            :errorMessage="requireValidateArr('employmentParkingType', '駐輪 区分').message"
            @change="changeTrans('employmentParkingType', 'employmentParkingExpenses')"
          />
        </div>

        <div class="sm:col-span-5">
          <NumericTextInput2 
            name="employmentParkingExpenses" 
            caption="駐輪 交通費"
            maxLenght="5"
            v-model="requestPost.employmentParkingExpenses"
            :disabled="referenceMode"
            :required="isRequired('駐輪 交通費')"
            :error="!requireValidateStr('employmentParkingExpenses', '駐輪 交通費').result"
            :errorMessage="requireValidateStr('employmentParkingExpenses', '駐輪 交通費').message"
          />
        </div>
      </div>

      <InputGroupLabel class="mt-1 mb-10" v-if="isShow('交通費')" />

      <div v-for="(aw, i) in requestPost.employmentAppliedWork" v-bind:key="'AppliedWork' + i"  class="sm:col-span-full">
        <div class="flex" v-if="isShow('ご応募いただいた勤務曜日')">
          <div id="employmentAppliedWorkDays" class="mr-3">
            <SelectionButton2
              name="employmentAppliedWorkDays"
              caption="ご応募いただいた勤務曜日"
              v-model="aw.days"
              :item ="daysSelection"
              :multiSelect="true"
              :disabled="referenceMode"
              :required="isRequired('ご応募いただいた勤務曜日')"
              :error="!requireValidateArr2(aw.days, 'ご応募いただいた勤務曜日').result"
              :errorMessage="requireValidateArr2(aw.days, 'ご応募いただいた勤務曜日').message"
            />
          </div>

          <div>  
            <div class="flex">
              <div class="">
                <WmsTimeInput2
                  name="employmentAppliedWorkStartTime" 
                  caption="勤務時間"
                  v-model="aw.startTime"
                  class="time"
                  :clearable="true"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="referenceMode"
                  :required="isRequired('勤務時間')"
                  :error="!requireValidateArr2(aw.startTime, '勤務時間').result"
                  :errorMessage="requireValidateArr2(aw.startTime, '勤務時間').message"
                  @change="changeWorkingTime(i)"
                />
              </div>
              <div class="mt-6 mx-1 font-bold">～</div>
              <div class="mt-4">
                <WmsTimeInput2
                  name="employmentAppliedWorkEndTime" 
                  caption=""
                  v-model="aw.endTime"
                  class="time"
                  :clearable="true"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="referenceMode"
                  :error="!requireValidateArr2(aw.endTime, '勤務時間').result"
                  :errorMessage="requireValidateArr2(aw.endTime, '勤務時間').message"
                  @change="changeWorkingTime(i)"
                />
              </div>

              <!-- 勤務時間 -->
              <div class="text-sm ml-2 mt-auto mb-1 min-w-fit">
                ( {{ workingHours[i] }} h )
              </div>
            </div>

            <div class="text-xs mt-1 text-gray-700 font-bold">休憩時間 ※6時間以上の場合のみ</div>
            <div class="flex">
              <div class="">
                <WmsTimeInput2
                  name="employmentAppliedWorkStartBreakTime" 
                  caption=""
                  v-model="aw.startBreakTime"
                  class="time"
                  :clearable="true"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="referenceMode"
                />
              </div>
              <div class="mt-4 mx-1 font-bold">～</div>
              <div class="">
                <WmsTimeInput2
                  name="employmentAppliedWorkEndBreakTime" 
                  caption=""
                  v-model="aw.endBreakTime"
                  class="time"
                  :clearable="true"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="referenceMode"
                />
              </div>
            </div>
          </div>

          <!-- 勤務曜日・時間 追加ボタン -->
          <div class="addButton-contents mt-auto ml-auto" v-if="!referenceMode && i==0">
            <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'追加'" @click="addAppliedWork()">
              <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            </PrimaryButton>
          </div>
          <!-- 勤務曜日・時間 削除ボタン -->
          <div class="mt-auto text-right ml-auto" v-else-if="!referenceMode">
            <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeAppliedWork(i)">
              <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
            </PrimaryButton>
          </div>
        </div>
      </div>

      <div class="sm:col-span-full five-selection" v-if="isShow('祝日区分')">
        <SelectionButton2
          name="employmentHolidayType" 
          caption="祝日区分"
          v-model="requestPost.employmentHolidayType"
          :item="holidayType"
          :multiSelect="false"
          :disabled="referenceMode"
          :required="isRequired('祝日区分')"
          :error="!requireValidateArr('employmentHolidayType', '祝日区分').result"
          :errorMessage="requireValidateArr('employmentHolidayType', '祝日区分').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('ゴミ搬出')">
        <SelectionButton2
          name="employmentTrashRemoval" 
          caption="ゴミ搬出"
          v-model="requestPost.employmentTrashRemoval"
          :item="trashRemoval"
          :multiSelect="false"
          :disabled="referenceMode"
          :required="isRequired('ゴミ搬出')"
          :error="!requireValidateArr('employmentTrashRemoval', 'ゴミ搬出').result"
          :errorMessage="requireValidateArr('employmentTrashRemoval', 'ゴミ搬出').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('必要な書類')">
        <SelectionButton2
          name="employmentMaterial"
          caption="必要な書類"
          v-model="requestPost.employmentMaterial"
          :item="materialSelections"
          :multiSelect="true"
          :disabled="referenceMode"
          :required="isRequired('必要な書類')"
          :error="!requireValidateArr('employmentMaterial', '必要な書類').result"
          :errorMessage="requireValidateArr('employmentMaterial', '必要な書類').message"
        />
      </div>

      <div class="sm:col-span-full" v-if="isShow('履歴書')">
        <label class="block text-xs font-bold text-gray-700 relative">
          添付資料（履歴書）<span v-if="isRequired('履歴書')" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
        </label>
        <div class="mt-1">
          <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
            <WmsAttachment 
              name="employmentDocument" 
              style="max-width: none!important;"
              :value="requestPost.employmentDocument"
              :disabled="referenceMode"
              @change="attachmentChange('employmentDocument', $event)" 
              :errorMessage="requireValidateAttach('employmentDocument', '履歴書').message"
            />
          </div>
        </div>
      </div>

      <!-- <div class="sm:col-span-full">
        <WmsTextInput
          name="employmentNotice"
          caption="連絡事項"
          v-model="requestPost.employmentNotice"
          :disabled="referenceMode"
        />
      </div> -->

      <div class="sm:col-span-full" v-if="!isOther">
        <WmsTextInput 
          name="employmentRemarks" 
          caption="連絡事項"
          v-model="requestPost.employmentRemarks"
          :multiline="true"
          :rows="5"
          :disabled="referenceMode"
        />
      </div>
    </div>

    <!-- 物件選択モーダル -->
    <SiteSelectorModal
      v-model="siteModal"
      :open="siteModal"
      @modalSiteSelected="siteSelected"
    />
  </div>
</template>
  
<script>
  import * as utils from '@libs/utils'
  import * as dialogs from '@libs/dialogs'
  import * as helper from '@libs/helper'
  import * as constants from '@libs/constants'
  import * as backend from '@libs/backend'
  import moment from 'moment'
  import Icon from '@components/Icon.vue'
  import InputIcon from '@components/InputIcon.vue'
  import PrimaryButton from '@components/PrimaryButton.vue'
  import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
  import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
  import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
  import NumericTextInput2 from '@components/NumericTextInput2.vue'
  import SelectionButton2 from '@components/SelectionButton2.vue'
  import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
  import InputGroupLabel from '../components/InputGroupLabel.vue'
  import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
  import SiteSelectorModal from '../components/SiteSelectorModal.vue'

  const SEX_SELECTIONS = [
    {
      value: '男性',
      text: '男性'
    },
    {
      value: '女性',
      text: '女性'
    }
  ]

  const CLASS_SELECTIONS = [
    {
      value: '新規入社',
      text: '新規入社'
    },
    {
      value: '再入社',
      text: '再入社'
    },
    {
      value: '物件追加',
      text: '物件追加'
    },
    {
      value: '物件退職',
      text: '物件退職'
    },
    {
      value: '退職',
      text: '退職'
    },
    {
      value: 'その他変更',
      text: 'その他変更'
    }
  ]

  const CLOSINGDATE_SELECTIONS = [
    {
      value: '20日',
      text: '20日'
    },
    {
      value: '30日',
      text: '30日'
    }
  ]

  // 新規入社 必須項目
  const ENTER = ['入社日', '氏名', 'フリガナ', '生年月日', '年齢', '性別', '郵便番号', '住所１', '携帯電話', '物件名', 'エリア', '得意先名', '作業内容', '時給', 'ご応募いただいた勤務曜日', '勤務時間', '祝日区分', 'ゴミ搬出', '必要な書類', '履歴書']
  // 再入社 必須項目
  const AGAIN = ['入社日', '氏名', '携帯電話', '物件名', 'エリア', '得意先名', '作業内容', '時給', '交通費', '電車 区分', '電車 交通費', 'バス 区分', 'バス 交通費', '駐輪 区分', '駐輪 交通費', 'ご応募いただいた勤務曜日', '勤務時間',  '祝日区分', 'ゴミ搬出', '必要な書類']
  // 再入社 必須ではないが表示する
  const AGAIN_NOT_REQUIR = ['振込銀行', '支店名', '口座番号', '口座名義', '緊急連絡先']
  // 物件追加 必須項目
  const ADD = ['入社日', '氏名', '物件名', 'エリア', '得意先名', '作業内容', '時給', '交通費', '電車 区分', '電車 交通費', 'バス 区分', 'バス 交通費', '駐輪 区分', '駐輪 交通費', 'ご応募いただいた勤務曜日', '勤務時間',  '祝日区分', 'ゴミ搬出', '必要な書類']
  // 物件退職・退職 必須項目
  const RETIRE = ['退職日', '氏名', '物件名', 'エリア', '得意先名']
  // その他変更 必須
  const OTHER = ['氏名', '物件名', '変更日']

  export default {
    components: {
      Icon,
      InputIcon,
      PrimaryButton,
      WmsDateInput,
      WmsTextInput,
      WmsTimeInput2,
      NumericTextInput2,
      InputGroupLabel,
      SelectionButton2,
      WmsAttachment,
      WmsSuggestInput,
      SiteSelectorModal
    },

    props: {
      requestPost: {
        type: Object,
        default: () => {}
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isTaskRequestMode: {
        type: Boolean,
        default: false
      },
      referenceMode: {
        type: Boolean,
        default: false
      }
    },
  
    data() {
      return {
        sexSelections: SEX_SELECTIONS,
        // classSelections: CLASS_SELECTIONS,
        areaSelections: constants.AREA_SELECTIONS,
        provisionSelections: constants.PROVISION_SELECTIONS,
        workSelections: constants.WORK_SELECTIONS,
        holidayType: constants.HOLIDAYTYPE_SELECTIONS,
        trashRemoval: constants.TRASHREMOVAL_SELECTIONS,
        closingDate: CLOSINGDATE_SELECTIONS,
        daysSelection: constants.DAYS_SELECTIONS,
        materialSelections: constants.MATERIAL_SELECTIONS,
        hourItems: constants.HOURS,
        minuteItems: constants.MINUTES,
        workingHours: [0],
        // 清掃員リスト
        employmentNameList: [],
        // 物件リスト
        employmentBuildingList: [],
        // 物件リスト全取得
        allEmploymentBuildingList: [],
        // 物件検索モーダル
        siteModal: false
      }
    },
  
    computed: {
      isModileDevice() {
        return utils.deviceInfo.isMobile
      },

      /**
       * 種別の選択項目
       */
      classSelections() {
        let res = utils.clone(CLASS_SELECTIONS)
        if (this.requestPost.notNewEnter) {
          res = CLASS_SELECTIONS.filter((c) => {
            return c.value != '新規入社'
          })
        }
        return res
      },

      /**
       * 誕生日を和暦に変換
       */
      japaneseDate() {
        return (val)=> {
          if (val) {
            return utils.japaneseDate(val)
          } else {
            return ''
          }
        }
      },

      /**
       * 必須項目入力チェック（文字列）
       */
      requireValidateStr() {
        return (item, name)=> {
          if (this.isRequired(name)) {  
            if (!this.requestPost[item]) {
              return {
                result: false,
                message: `${name}は必須項目です。${name}を入力してください。`
              }
            }
          }

          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 必須項目入力チェック（配列）
       */
      requireValidateArr() {
        return (item, name)=> {
          if (this.isRequired(name)) {  
            if (!this.requestPost[item] || !this.requestPost[item].length) {
              return {
                result: false,
                message: `${name}は必須項目です。${name}を選択してください。`
              }
            }
          }

          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 必須項目入力チェック（連想配列）
       */
      requireValidateArr2() {
        return (item, name)=> {
          if (this.isRequired(name)) {  
            let input = '入力'
            if (Array.isArray(item)) {
              input = '選択'
            }
            if (!item || !item.length) {
              return {
                result: false,
                message: `${name}は必須項目です。${name}を${input}してください。`
              }
            }
          }

          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 必須項目入力チェック（添付ファイル）
       */
      requireValidateAttach() {
        return (item, name)=> {
          if (this.isRequired(name)) {
            if (!this.requestPost[item] || (!this.requestPost[item].content && !this.requestPost[item].url)) {
              return {
                result: false,
                message: `${name}は必須項目です。${name}を添付してください。`
              }
            }
          }
          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 表示するか否か
       */
      isShow() {
        return (name) => {
          // 種別
          const ec = this.requestPost.employmentClass
          // 「その他変更」の場合、表示する項目
          const other = ['氏名', '物件名', '変更日', '郵便番号', '住所１', '住所２', '固定電話', '携帯電話', '振込銀行', '支店名', '口座番号', '口座名義', '緊急連絡先', '交通費', '時給']
          // 「再入社」の場合、表示する項目
          const again = AGAIN.concat(AGAIN_NOT_REQUIR)
          if (ec && ec.length) {
            const e = ec[0]
            if (e == '新規入社') {
              if (name == '退職日') {
                return false
              } else {
                return true
              }
            } else if (e == 'その他変更') {
              if (other.includes(name)) {
                return true
              }
            } else if (e == '再入社') {
              if (again.includes(name)) {
                return true
              }
            } else {
              return this.isRequired(name)
            }
          }
          return false
        }
      },

      /**
       * 「その他変更」を選択しているか
       */
      isOther() {
        if (this.requestPost.employmentClass && this.requestPost.employmentClass[0] == 'その他変更') {
          return true
        }
        return false
      },

      /**
       * 種別によって必須が変わる
       * @param name 項目名
       */
      isRequired() {
        return (name) => {
          // 種別はいつでも必須
          if (name == '種別') {
            return true
          }
          // 種別
          const ec = this.requestPost.employmentClass
          // 新規入社
          const enter = ENTER
          // 再入社
          const again = AGAIN
          // 物件追加
          const add = ADD
          // 物件退職・退職
          const retire = RETIRE
          // その他変更
          const other = OTHER

          if (ec && ec.length) {
            const e = ec[0]
            if (e == '新規入社') {
              if (enter.includes(name)) {
                return true
              }
            } else if (e == '再入社') {
              if (again.includes(name)) {
                return true
              }
            } else if (e == '物件追加') {
              if (add.includes(name)) {
                return true
              }
            } else if (e == '物件退職' || e == '退職') {
              if (retire.includes(name)) {
                return true
              }
            } else if (e == 'その他変更') {
              if (other.includes(name)) {
                return true
              }
            }
          }
          return false
        }
      },

      /**
       * 携帯番号入力形式確認
       */
      errMobile() {
        if (this.requestPost.employmentMobile) {
          const pattern = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/
          //入力値が正しいか
          if (!pattern.test(this.requestPost.employmentMobile)) {
            return '【3桁-4桁-4桁】の形式で入力してください。'
          }
        }
        return ''
      },
    },
  
    async created() {
      // 実働時間取得
      this.changeWorkingTime(0)
      // 清掃員リスト取得
      this.getEmploymentNameList()
      // 物件リスト取得
      await this.getAllEmploymentBuildingList()
      this.getEmploymentBuildingList()
    },
  
    methods: {
      onKeyPressTel(event) {
        // Allow numbers (charCode 48-57) and dash (charCode 45)
        if ((event.charCode >= 48 && event.charCode <= 57) ||  event.charCode === 45 ) {
          return true;
        } else {
          event.preventDefault(); // Prevent any other character from being entered
        }
      },

      /**
       * 生年月日から年齢を取得
       */
      async ageCalculation(){
        if (!this.requestPost.employmentBirthDay) {
          this.requestPost.employmentAge = ''
        } else {
          var birthday = this.requestPost.employmentBirthDay.split("-")
          var birth = moment().year(birthday[0]).month(birthday[1] - 1).date(birthday[2])
          this.requestPost.employmentAge = String(moment().diff(birth, 'years'))
        }
      },

      /**
       * 入力チェック
       */
      async validation() {
        if (this.errMobile) {
          await dialogs.showInfoDialog('携帯番号', '【3桁-4桁-4桁】の形式で入力してください。')
          return false
        }

        // 配列、文字列
        const valStr = [
          { item: 'employmentClass', name: '種別' }
          ,{ item: 'employmentHireDate', name: '入社日' }
          ,{ item: 'employmentRetirementDate', name: '退職日' }
          ,{ item: 'employmentName', name: '氏名' }
          ,{ item: 'employmentKana', name: 'フリガナ' }
          ,{ item: 'employmentBirthDay', name: '生年月日' }
          ,{ item: 'employmentAge', name: '年齢' }
          ,{ item: 'employmentSex', name: '性別' }
          ,{ item: 'employmentPostCode', name: '郵便番号' }
          ,{ item: 'employmentAddress1', name: '住所１' }
          ,{ item: 'employmentMobile', name: '携帯電話' }
          ,{ item: 'employmentBuildingName', name: '物件名' }
          ,{ item: 'employmentChangedDate', name: '変更日' }
          ,{ item: 'employmentArea', name: 'エリア' }
          ,{ item: 'employmentCustomer', name: '得意先名' }
          ,{ item: 'employmentWorkTask', name: '作業内容' }
          ,{ item: 'employmentHourlyWage', name: '時給' }
          ,{ item: 'employmentTrainType', name: '電車 区分' }
          ,{ item: 'employmentTrainExpenses', name: '電車 交通費' }
          ,{ item: 'employmentBusType', name: 'バス 区分' }
          ,{ item: 'employmentBusExpenses', name: 'バス 交通費' }
          ,{ item: 'employmentParkingType', name: '駐輪 区分' }
          ,{ item: 'employmentParkingExpenses', name: '駐輪 交通費' }
          ,{ item: 'employmentAppliedWork' }
          ,{ item: 'employmentHolidayType', name: '祝日区分' }
          ,{ item: 'employmentTrashRemoval', name: 'ゴミ搬出' }
          ,{ item: 'employmentMaterial', name: '必要な書類' }
        ]

        for (let i = 0; i < valStr.length; i++) {
          const s = valStr[i]
          let res = {}
          // 勤務曜日、勤務時間の場合
          if (s.item == 'employmentAppliedWork') {
          for (let j = 0; j < this.requestPost[s.item].length; j++) {
            const tar = this.requestPost[s.item][j]
            // 勤務曜日
            res = this.requireValidateArr2(tar.days, 'ご応募いただいた勤務曜日')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
            // 開始時間
            res = this.requireValidateArr2(tar.startTime, '勤務時間')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
            // 終了時間
            res = this.requireValidateArr2(tar.endTime, '勤務時間')
            if (!res.result) {
              await dialogs.showInfoDialog('入力確認', res.message, 'OK')
              return false
            }
          }
          } else {  
            // 配列
            if (Array.isArray(this.requestPost[s.item])) {
              res = this.requireValidateArr(s.item, s.name)

            // 文字列
            } else {
              res = this.requireValidateStr(s.item, s.name)
            }
          }

          if (!res.result) {
            await dialogs.showInfoDialog('入力確認', res.message, 'OK')
            return false
          }
        }

        // オブジェクト
        const valObj = [
          { item: 'employmentDocument', name: '履歴書' }
        ]
        for (let i = 0; i < valObj.length; i++) {
          const a = valObj[i]
          let res = {}
          res = this.requireValidateAttach(a.item, a.name)
          if (!res.result) {
            await dialogs.showInfoDialog('入力確認', res.message, 'OK')
            return false
          }
        }

        // 休憩時間の確認
        const work = this.requestPost.employmentAppliedWork
        for (let k = 0; k < work.length; k++) {
          const w = work[k]
          const wh = this.workingHours[k]
          const br = utils.getHours(w.startBreakTime || '', w.endBreakTime || '')
          // 実働時間が6時間以上の場合
          if (wh >= 6) {
            if (!br) {
              let ds = await dialogs.showConfirmDialog((k + 1) + '番目の休憩時間はよろしいでしょうか', '勤務時間が6時間以上になっていますが、休憩時間は0時間でよろしいでしょうか？')
              if (ds != 'YES') {
                return
              }
            }
          } else {
            if (br) {
              await dialogs.showErrorDialog((k + 1) + '番目の休憩時間', '勤務時間が6時間未満ですが、休憩時間が入力されています。')
              return
            }
          }
        }

        return true
      },
        
      /**
       * 郵便番号より住所検索
       */
      addressSearch(){
        var geocoder = new window.google.maps.Geocoder()
        var pattern = /^[0-9]{3}-?[0-9]{4}$/
        if(this.requestPost.employmentPostCode) {
          var postalCode = this.requestPost.employmentPostCode
          if (postalCode) {
            //入力値が正しいか
            if (pattern.test(postalCode)) {
              //住所を取得
              geocoder.geocode ({
                address:postalCode,
                language:"ja",
                region:"jp" 
              }, this.addressSearchCb)
            } else {
              alert("入力が3桁-4桁の形式であるか確認してください。")
            }
          } 
        } else {
            alert("郵便番号を入力をしてください。")
        }
      },
      
      /**
       * 郵便番号より住所検索 CallBack
       */
      addressSearchCb(results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results.length > 0){
            if (results[0].geometry) {
              if(results[0].address_components[4].long_name == '日本') {
                this.requestPost.employmentAddress1 = results[0].address_components[3].long_name +
                results[0].address_components[2].long_name + results[0].address_components[1].long_name
                console.log(this.requestPost.employmentAddress1)
              } else {
                this.requestPost.employmentAddress1 = results[0].address_components[4].long_name +
                results[0].address_components[3].long_name + results[0].address_components[2].long_name +
                results[0].address_components[1].long_name
              }
            }
          }
        } else {
          if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
            alert("郵便番号は見つかりませんでした。")
          } else {
            alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
          }
        }
      },

      /**
       * 住所より郵便番号検索
       */
      postalCodeSearch(){
        var geocoder = new window.google.maps.Geocoder()
        if (!this.requestPost.employmentAddress1 && !this.requestPost.employmentAddress2) {
          alert("住所を入力してください")
          return
        }
        
          var unionAddress = this.requestPost.employmentAddress1 + this.requestPost.employmentAddress2
          geocoder.geocode({
            address:unionAddress,
            language:"ja",
            region:"jp"
          }, this.postalCodeSearchCb)
      },

      /**
       * 住所より郵便番号検索 CallBack
       */
      postalCodeSearchCb(results, status) {
        //アラート用フラグ
        var flag = false
        var ret = this.requestPost
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results.length > 0) {
            if (results[0].geometry) {
              var result = results[0].address_components;
              result.forEach(function(returnData) {
                returnData.types.forEach(function(getType) {
                  if (getType == 'postal_code') {
                    console.log( returnData.long_name)
                    ret.employmentPostCode = returnData.long_name
                    flag = true
                  }
                })
              })
            } 
          }
          if (flag == false) {
            alert("郵便番号が見つかりませんでした。")
          }
        } else {
          if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
            alert("郵便番号は見つかりませんでした")
          } else {
            alert("システムの管理者にお問い合わせください。ステータスコード：" + status)
          }
        } 
      },

      /**
       * 添付ファイル
       */
      attachmentChange(targetAttachmentName, attachmentInfo) {

        // 面接から履歴書を引き継いでいたので、変更の際はそれをリセット
        delete this.requestPost[targetAttachmentName + '_file_content']
        delete this.requestPost[targetAttachmentName + '_display_file_name']
        delete this.requestPost[targetAttachmentName + '_file_name']

        if (!this.requestPost[targetAttachmentName]) {
          this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
        }
        if (attachmentInfo) {
          this.requestPost[targetAttachmentName].originalName = attachmentInfo.filename
          this.requestPost[targetAttachmentName].content = attachmentInfo.content
        } else {
          this.requestPost[targetAttachmentName] = helper.createEmptyAttachment()
        }
      },

      /**
       * 賃金の確認
       */
      async limitHourlyWageInput() {
        const err = utils.validateHourlyWage(this.requestPost.employmentHourlyWage)
        if (err) {
          await dialogs.showErrorDialog('賃金', err)
          this.requestPost.employmentHourlyWage = ''
        }
      },

      /**
       * 勤務時間変更イベント
       * @param i index
       */
      changeWorkingTime(i) {
        const work =this.requestPost.employmentAppliedWork
        if (this.workingHours.length != this.requestPost.employmentAppliedWork.length) {
          this.workingHours = []
          for (let index = 0; index < work.length; index++) {
            const w = work[index]
            this.workingHours.push(utils.getHours(w.startTime, w.endTime))
          }
        } else {
          this.workingHours[i] = utils.getHours(work[i].startTime, work[i].endTime)
        }
      },

        /**
       * 勤務曜日・時間 追加ボタン
       */
      addAppliedWork() {
        let add = {
          days: [],
          startTime: '',
          endTime: '',
          startBreakTime: '',
          endBreakTime: ''
        }
        this.requestPost.employmentAppliedWork.push(add)
        this.workingHours.push(0)
      },

      /**
       * 勤務曜日・時間 削除ボタン
       */
      removeAppliedWork(idx) {
        this.requestPost.employmentAppliedWork.splice(idx, 1)
      },

      /**
       * 交通費区分変更イベント
       * @param type 区分
       * @param fee 交通費
       */
      changeTrans(type, fee) {
        if (this.requestPost[type] && this.requestPost[type][0] == '無') {
          this.requestPost[fee] = '0'
        } else if (this.requestPost[type] && this.requestPost[type][0] != '無') {
          if (this.requestPost[fee] == '0') {
            this.requestPost[fee] = ''
          }
        }
      },

      /**
       * タイトルをセット
       */
      setTitle() {
        if (this.requestPost.employmentClass && this.requestPost.employmentClass[0] != '新規入社') {
          this.$emit('get-title2', this.requestPost)
        }
      },

      /**
       * 清掃員リスト取得
       */
      async getEmploymentNameList() {
        this.employmentNameList = []
        // 新規入社の場合は取得不要
        if (this.requestPost.employmentClass && this.requestPost.employmentClass[0] == '新規入社') {
          return
        }
        const list = await backend.searchData('akbsTable/getInstructEmploymentStaff', null)
        if (list && list.data.data && list.data.data.length) {
          this.employmentNameList = list.data.data
        }
      },

      /**
       * 清掃員名選択
       */
      async staffSelected(e) {
        this.requestPost.employmentStaffNumber = e.staffDailyCleaning
        this.requestPost.employmentMobile = e.numberMobilePhoneStaff
        await this.getEmploymentBuildingList(e)
        this.setTitle()
      },

      /**
       * 清掃員名変更
       */
      async staffChanged() {
        if (!this.requestPost.employmentName) {
          this.requestPost.employmentStaffNumber = ''
          this.requestPost.employmentMobile = ''
          
          await this.getEmploymentBuildingList()
        }
        this.setTitle()
      },

      /**
       * 物件リスト取得
       */
      async getEmploymentBuildingList(tar) {
        // 種別が物件退職、もしくは退職
        if (this.requestPost.employmentClass && this.requestPost.employmentClass[0] && this.requestPost.employmentClass[0].indexOf('退職') > -1 && this.requestPost.employmentStaffNumber) {
          const param = {
            staff: this.requestPost.employmentName
          }
          if (tar && tar.nameStaff) {
            param.staff = tar.nameStaff
          }
          const list = await backend.searchData('akbsTable/getSiteList', param)
          if (list && list.data.data && list.data.data.length) {
            this.employmentBuildingList = list.data.data
            return
          }
        }
        this.employmentBuildingList = utils.clone(this.allEmploymentBuildingList)
      },

      /**
       * 物件リスト全取得
       */
      async getAllEmploymentBuildingList() {
        const list = await backend.searchData('akbsTable/getSiteList', null)
        if (list && list.data.data && list.data.data.length) {
          this.allEmploymentBuildingList = list.data.data
        } else {
          this.allEmploymentBuildingList = []
        }
      },

      /**
       * 種別変更
       */
      async changeEmploymentClass() {
        await this.getEmploymentBuildingList()
        this.setTitle()
      },

      /**
       * 物件選択モーダルopen
       */
      openSiteModal() {
        this.siteModal = true
      },

      /**
       * 物件選択モーダルclose
       */
      siteSelected(target) {
        this.requestPost.employmentSiteCode = target.cdSite
        this.requestPost.employmentBuildingName = target.nameSite
        this.requestPost.employmentCustomer = target.nameCustomer
        if (target.nameCustomer2) {
          this.requestPost.employmentCustomer += ' ' + target.nameCustomer2
        }
        this.requestPost.employmentSiteAddress = target.address
        this.getArea()
        this.setTitle()
        this.siteModal = false
      },

      /**
       * 物件変更イベント
       */
      siteChanged() {
        if (!this.requestPost.employmentBuildingName) {
          this.requestPost.employmentSiteCode = ''
          this.requestPost.employmentBuildingName = ''
          this.requestPost.employmentCustomer = ''
          this.requestPost.employmentSiteAddress = ''
          this.requestPost.employmentArea = ''
        }
        this.setTitle()
      },

      /**
       * 住所からエリアを取得
       */
      getArea() {
        if (this.requestPost.employmentSiteAddress) {
          for (let i = 0; i < this.areaSelections.length; i++) {
            const a = this.areaSelections[i]
            if (a && this.requestPost.employmentSiteAddress.indexOf(a) > -1) {
              this.requestPost.employmentArea = a
              return
            }
          }
        }
        
        this.requestPost.employmentArea = ''
      }
    }
  }
</script>
  
<style>
  .five-selection div button[type=button] {
    width: 19.8% !important;
  }

  .six-selection div button[type=button] {
    width: 16.2% !important;
  }

  #employmentAppliedWorkDays div button[type=button] {
    width: 12% !important;
  }

  .time div #hour-input {
    width: 65px !important;
  }

  .time div #minute-input {
    width: 65px !important;
  }
</style>