<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(p, i) in price" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{ p.name }}{{ p.title }}
            </div>
          </th>
          <td>
            <div v-if="p.price!=''">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="p.price"
                @change="changeData(p)"
              />
            </div>
            <div v-else class="my-3 font-bold text-gray-500">
              値段設定なし
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 特別清掃
 */
const CATEGORY = '14'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // カラム
      column1: [
        { name: '内容', class: 'ml-5 bg-teal-50'},
        { name: '単価', class: 'ml-5 bg-teal-50'},
      ],
      // 単価
      price: [],
      // 中項目
      subItems: []
    }
  },

  created() {
    // 中項目を取得
    const list = this.$store.getters.estimateSubItemList
    this.subItems = list.filter((s) => {
      return s.parentCode == '14'
    })

    // オブジェクトに変換
    let parse = this.subItems.map((original) => {
      if (original.selectValue != '') {
        return {...original, itemCode: JSON.parse(original.selectValue.replace(/\//g, ''))}
      } else {
        return {...original}
      }
    })
    this.subItems = parse
    
    this.dataByCategory()
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      this.price = this.setSort(dbc)
      this.price = this.setTitle()
    },

    /**
     * ソート
     * @param target 対象データ
     */
    setSort(target) {
      const spItems = [
        { id:'wall',text:'腰壁清掃' },
        { id:'lighting',text:'照明器具清掃' },
        { id:'acid',text:'エントランス等石材酸洗浄' },
        { id:'coating',text:'エントランスコーティング' },
        { id:'garari',text:'ガラリ清掃' },
        { id:'gutter',text:'側溝清掃' },
        { id:'masu',text:'枡清掃' },
        { id:'parking',text:'駐車場ピット内清掃' },
        { id:'safety',text:'安全対策要員' },
        { id:'shovel',text:'釜場清掃（スコップ）' },
        { id:'sludge',text:'汚泥処理' },
        { id:'inter',text:'インターロッキング洗浄' },
        { id:'drain',text:'屋上ドレン清掃' },
        { id:'bicycle',text:'駐輪場清掃' },
        { id:'roof',text:'駐輪場屋根' },
        { id:'wipe',text:'壁面拭き上げ' },
        { id:'filter',text:'エアコンフィルター清掃' },
        { id:'louver',text:'ルーバー' },
        { id:'pallet',text:'機械式駐車場パレット清掃（掃き）' },
        { id:'fence',text:'クーラーフェンス' },
        { id:'ceiling',text:'天井清掃(ステンレス）' },
        { id:'pond',text:'水景(池)清掃' },
        { id:'lattice',text:'外廊下面格子・網戸清掃' }
      ]
      let result = []
      for (let l = 0; l < spItems.length; l++) {
        const sort = spItems[l].id
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[0] == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 「内容」の文言生成
     */
    setTitle() {
      let target = utils.clone(this.price)
      for (let i = 0; i < target.length; i++) {
        const t = target[i]
        t.title = ''
        let code = t.priceCode.split('-')
        if (code.length == 2 && code[0] != 'coating' && code[0] != 'pallet') {
          let item = this.subItems.find((s) => {
            return code[0] == s.item
          })
          if (item.itemCode) {
            t.title = '（' + item.itemCode[code[1]] + '）'
          }
        }
      }
      return target
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      target.itemName = target.name + target.title
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
