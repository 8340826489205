<template>
  <div class="relative">
    <Popper
      ref="popper"
      trigger="clickToToggle"
      :append-to-body="true"
      :options="popperOptions"
      class="inline-block w-full z-10"
      :disabled="disabled || selections.length === 0"
    >
      <button 
        ref="button"
        slot="reference"
        type="button" 
        :name="name"
        class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded cursor-default text-left bg-white px-3"
        :class="disabled ? ['bg-gray-200'] : []" 
        :disabled="disabled"
      >
        <span class="block truncate">
          {{ value ? value.text : '&nbsp;' }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>
      <ul class="z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
        <template v-for="(selection, index) in selections">
          <div v-if="selection.value === '---'" :key="selection.value + index" class="h-px border-b border-gray-300"></div>
          <li
            v-else 
            :key="selection.value" 
            class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
            @click="select(selection)"
          >
            <span class="font-normal block truncate">
              {{ selection.text }}
            </span>
          </li>
        </template>
      </ul>
    </Popper>      
    <div class="absolute inset-y-0 flex items-center right-8">
      <Icon
        v-if="clearable && value !== null"
        iconName="X" 
        :clickable="true" 
        class="h-4 w-4 text-gray-400"
        @click="clear"
      />
    </div>
  </div>
</template>
<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import Icon from '@components/Icon.vue'

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  fn: (data) => {
    data.instance.popper.style.width = data.offsets.reference.width + 'px'
    return data
  }
}

const popperOptions = {
  placement: 'bottom',
  modifiers: {
    sameWidth
  }
}

export default {
  components: {
    Popper,
    Icon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    selections: {
      type: Array,
      default: () => []
    },

    clearable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      popperOptions,
      // showPopup: false
    }
  },

  methods: {
    showMenu() {
      this.$refs.popper.doToggle()
      // var evt = document.createEvent("HTMLEvents")
      // evt.initEvent("click", true, true)
      // this.$refs.button.dispatchEvent(evt)
    },

    select(selectedItem) {
      console.log('Select.vue :: select')
      console.log(selectedItem)
      this.$refs.popper.doClose()
      // this.showPopup = false
      this.$emit('change', selectedItem)
    },

    clear() {
      console.log('clear')
      this.$emit('change', null)
    }
  }
}
</script>
