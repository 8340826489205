<template>
  <div>
    <PatrolTermComponent
      typeName="設備点検 項目・サイクル設定"
      :type="4"
      settionStrageName="FacilityTermStorageItemName">
    </PatrolTermComponent>
  </div>
</template>

<script>
import PatrolTermComponent from '../../Patrol/components/PatrolTermComponent.vue'

export default {
  components: {
    PatrolTermComponent
  },
}
</script>