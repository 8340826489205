import * as utils from '@libs/utils'
import * as netutils from '@libs/netutils'
import moment from 'moment'

const TASKS_TYPE ='log'

export const Loglevel = {
  TRACE: 0,                  // ログレベル:0：TRACE、1：DEBUG、2：INFO、3：WARN、4：ERROR
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4
}

export const LogSchema = {
  level: '',                  // ログレベル:0：TRACE、1：DEBUG、2：INFO、3：WARN、4：ERROR
  loginId: '',
  userName: '',
  serviceName: '',
  operation: '',
  logText: '',
  ipAddress: '',
  recordingDateTime: '',
}

/** 
 * 取得(ID条件)
 */
export async function getById(context, id) {
  const searchCondition = {
    searchCondition: {
      type: TASKS_TYPE,
      status: { $ne: "remove" },
      id,
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getById error !!!')
    console.log(error)
    return null
  }
}

/**
 * 取得
 * @param {*} context 
 * @param {*} searchKeys 
 * @param {*} keyword 
 * @param {*} status 
 * @param {*} periodKey 
 * @param {*} period 
 * @returns 
 */
export async function get(context, searchKeys, keyword, periodKey, period) {
  let searchCondition = {
    searchCondition: {
      type: TASKS_TYPE
    },
    sort: {
      recordingDateTime: -1
    }
  }

  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start + ' 00:00:00'
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end + ' 23:59:59'
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
  }

  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
  if (keywordCondition) {
    searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
  }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const ret = responseData || null
    return ret
  } catch (error) {
    console.log('get error !!!')
    console.log(error)
    return null
  }
}

function buildMultipleKeywordSearchCondition(keywordText, searchKeys) {
  keywordText = keywordText.trim()
  if (keywordText === '') {
    return null
  } else {
    const keywords = keywordText.split(/\s+/g)
    const condition = { $and: [] }
    keywords.forEach(function(keyword) {
      const query = { $or: [] }
      searchKeys.forEach(function(searchKey) {
        const expression = {}
        expression[searchKey] = { $regex: keyword, $options: 'i'}
        query.$or.push(expression)
      });
      condition.$and.push(query);
    });
    return condition;
  }
}

/** 
 * 登録
 */
export async function register(context, target) {
  try {
    const registrationData = createRegistrationData(target)
    await context.$pigeon.registerTask(TASKS_TYPE, registrationData, { cwCooperation: 'cw-off' })
    return true
  } catch (error) {
    console.log('register error !!!')
    console.log(error)
    return false
  }
}

/**
 * 更新
 * @param {*} context 
 * @param {*} target 
 * @returns 
 */
export async function update(context, target) {
  try {
    const registrationData = createRegistrationData(target)
    await context.$pigeon.updateTask(registrationData._id, registrationData, { cwCooperation: 'cw-off' })
    return true
  } catch (error) {
    console.log('update error !!!')
    console.log(error)
    return false
  }
}

/**
 * 新規タスク依頼生成
 * @returns 
 */
export async function create(context) {
  const newLog = { ...utils.clone(LogSchema) }

  newLog.ipAddress = await netutils.getIp()
  newLog.recordingDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
  newLog.loginId = context.$store.getters.user.login_id
  newLog.userName = context.$store.getters.user.user_name

  return newLog
}

/**
 * 登録データ 生成
 */
function createRegistrationData(target) {
  const registrationData = utils.clone(target)
  return registrationData
}

/**
 * 記録
 * @param {*} context 
 * @param {*} level ログレベル
 * @param {*} serviceName サービス名
 * @param {*} operation 操作内容
 * @param {*} logText ログテキスト
 */
export async function recording(context, level, serviceName, operation, logText) {
  var rec = await create(context)

  rec.level = level
  rec.serviceName = serviceName
  rec.operation = operation
  rec.logText = logText

  const ret = await register(context, rec)
  return ret
}

/**
 * 記録（データ登録用）
 * @param {*} context 
 * @param {*} newFlag 新規フラグ
 * @param {*} serviceName サービス名
 * @param {*} serviceName ログテキスト
 */
 export async function recordingByRegist(context, newFlag, serviceName, text) {
  var rec = await create(context)

  rec.level = Loglevel.INFO
  rec.serviceName = serviceName
  if (newFlag) {
    rec.operation = '新規登録'
    rec.logText = text + 'を登録しました。'
  } else {
    rec.operation = '更新'
    rec.logText = text + 'を更新しました。'
  }
  const ret = await register(context, rec)
  return ret
}
