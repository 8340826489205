import axiosBase from 'axios'

export async function getDataUrl(url) {
  return new Promise((resolve, reject) => {
    const axios = axiosBase.create()
    axios.get(url, { responseType: 'blob' }).then((response) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(response.data)
      reader.onload = function() {
        const dataUrl = reader.result
        resolve(dataUrl)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

/**
 * グローバルIP 取得
 * @returns グローバルIP
 */
export async function getIp() {
  return new Promise((resolve) => {
    const axios = axiosBase.create()
    axios.post('https://ipinfo.io/').then((res) => {
      resolve(res.data.ip)
    }).catch(() => {
      resolve('Cannot get ip')
    })
  })
}
