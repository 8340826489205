<template>
  <component v-if="icon" ref="svg" :is="icon" :class="classes" @click="onClick" />
</template>
<script>
import { SOLID_ICONS, OUTLINE_ICONS } from '@libs/constants'
export default {
  props: {
    iconType: {
      type: String,
      default: 'solid'
    },
    iconName: {
      type: String,
      default: ''
    },
    strokeWidth: {
      type: Number,
      default: 1
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      if (this.iconType === 'solid') {
        return SOLID_ICONS[`${this.iconName}Icon`]
      } else {
        return OUTLINE_ICONS[`${this.iconName}Icon`]
      }
    },
    classes() {
      const classes = []
      if (this.clickable) {
        classes.push('cursor-pointer hover:text-indigo-600 active:text-indigo-800')
      }
      return classes
    }
  },
  mounted() {
    if (this.$refs && this.$refs.svg) {
      const path = this.$refs.svg.querySelector('path')
      if (path) {
        path.style.cssText = path.style.cssText + ` stroke-width: ${this.strokeWidth}`
      }
    }
  },
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit('click')
      }
    }
  }
}
</script>