<template>
  <div>
    <RidgeComponent
      typeName="定期清掃 棟設定"
      :type="3"
      settionStrageName="FixedRidgeStorageItemName">
    </RidgeComponent>
  </div>
</template>

<script>
import RidgeComponent from '../components/RidgeComponent.vue'

export default {
  components: {
    RidgeComponent,
  }
}
</script>