<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="text-center">
    <div class="mx-auto relative">
      <Icon v-if="true" iconType="outline" :iconName="iconName" :strokeWidth="1" class="mx-auto h-16 w-16" />
    </div>
    <h3 class="mt-2 text-md text-gray-700 font-bold">{{ message }}</h3>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'

export default {
  components: {
    Icon
  },

  props: {
    iconName: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: '該当データはありません'
    }
  }
}
</script>
