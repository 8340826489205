<template>
  <div>
    <PhotoReportComponent
      typeName='バイク点検'
      :type=3
      :commonItem="true"
      title="環境整備（バイク_RLS）"
    >
    </PhotoReportComponent>
  </div>
</template>
<script>

import PhotoReportComponent from '../components/PhotoReportComponent.vue'

export default {
  components: {
    PhotoReportComponent
  },
}
</script>

<style scoped>
</style>