<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <div class="overflow-hidden rounded main-height w-80">
            
            <!-- コード -->
            <div class="text-sm font-bold mt-1">
                <label class="block text-sm font-bold text-blue-700">
                    作業内容コード
                </label>
                <label class="block text-xs text-gray-400 mt-1">
                    自動で７桁の半角ゼロ埋になります。（例：1000 → 0001000）
                </label>
                <WmsTextInput 
                    v-model="val.code"
                    @change="changeCode()"
                />
            </div>
            <!-- 大項目 -->
            <label class="block text-xs font-bold text-gray-600 mt-6">
                大項目（作業内容区分名）
            </label>
            <WmsTextInput 
                v-model="val.category"
            />
            <!-- 小項目 -->
            <label class="block text-xs font-bold text-gray-600 mt-6">
                小項目（作業内容名）
            </label>
            <WmsTextInput 
                v-model="val.detail"
            />
            <div class="mt-5">
                <PrimaryButton text="保存" size="normal w-full " @click="save()" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as estimateManager from '@managers/estimateManager'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'
export default {
    components: {
        WmsTextInput,
        Modal,
        PrimaryButton,
    },

    props: ['open', 'target', 'allList'],

    data() {
        return {
            // カラム
            columns: [
                {title: '以下の文言がカテゴリー名に含まれる場合'},
                {title: '削除'},
            ],
            // 編集データ
            val: {
                code: '',
                categoryName: []
            },
            // 編集か否か
            isEdit: false,
        }
    },

    computed: {
    },

    watch: {
        open() {
            if (this.open) {
                this.getData()
            }
        }
    },

    methods: {
        /**
         * 編集対象データ取得
         */
        async getData() {
            // 編集対象データがある場合
            if (this.target) {
                this.isEdit = true
                this.val = {}
                this.val = {
                    _id: this.target._id,
                    code: this.target.code,
                    category: this.target.category,
                    detail: this.target.detail
                }
            } else {
                this.isEdit = false
                this.val = {
                    code: '',
                    category: '',
                    detail: ''
                }
            }
        },

        /**
         * 入力チェック
         */
        async validate() {
            if (!this.val.code) {
                await dialogs.showErrorDialog('作業内容コード', '作業内容コードを入力してください。')
                return false
            } else if (this.val.code.length != 7) {
                await dialogs.showErrorDialog('作業内容コード', '作業内容コードは７桁で入力してください。')
                return false
            } else if (!this.val.category) {
                await dialogs.showErrorDialog('大項目を入力してください', '大項目（作業内容区分名）を入力してください。')
                return false
            } else if (!this.val.detail) {
                await dialogs.showErrorDialog('小項目を入力してください', '小項目（作業内容名）を入力してください。')
                return false
            }

            for (let i = 0; i < this.allList.length; i++) {
                const o = this.allList[i]
                if (o.code == this.val.code && o._id != this.val._id) {
                    await dialogs.showErrorDialog('重複しています', '同じ作業内容コードのデータが既にあります。')
                    return false
                }

                if (o.category == this.val.category && o.detail == this.val.detail && o._id != this.val._id) {
                    await dialogs.showErrorDialog('重複しています', '他の作業内容コード（' + o.code + '）と大項目・小項目が同じ条件です。')
                    return false
                }
            }
            return true
        },

        /**
         * 保存イベント
         */
        async save() {

            if (!await this.validate()) {
                return
            }

            let result

            // 更新
            if (this.isEdit) {
                result = await estimateManager.updateWorkCode(this, this.val)

            // 新規
            } else {
                result = await estimateManager.registerWorkCode(this, this.val)
            }
            
            if (!result) {
                await dialogs.showErrorDialog('保存エラー', '保存中にエラーが発生しました。')
                return
            }

            logManager.recordingByRegist(this, !this.isEdit, '作業区分マスタ', `作業区分（${this.val.code}）`)

            this.closed
            this.$emit('modalClose')
        },

        /**
         * 初期化
         */
        closed() {
            // 編集データ
            this.val = {
                code: '',
                categoryName: []
            }
            // 編集か否か
            this.isEdit = false
        },

        /**
         * 作業内容コード変更イベント
         */
        changeCode() {
            let num = utils.hankaku(this.val.code)
            num = utils.sanitizeStringForNumber(num)
            num = ( '0000000' + num ).slice( -7 )
            this.val.code = num
        }
    }
}
</script>
<style scoped>
.main-height {
    max-height: calc(100% - 200px);
}

.min-300 {
    min-width: 300px;
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>