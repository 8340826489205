import Vue from 'vue'
import App from '@apps/main/App.vue'
import router from './router'
import store from '@/store'
import VueCompositionApi from '@vue/composition-api'
import VCalendar from 'v-calendar'
import VTooltip from 'v-tooltip'
import PigeonPlugin from '@/plugins/PigeonPlugin'
import 'tailwindcss/tailwind.css'
import '@assets/style.scss'
import dotenv from 'dotenv'

import { PIGEON_CONFIG } from '@/config'
Vue.config.devtools = true
Vue.use(VueCompositionApi)
Vue.use(VCalendar)
Vue.use(PigeonPlugin, {
  baseUrl: PIGEON_CONFIG.apiEndpointBase,
  router,
  store
})
Vue.use(VTooltip)
Vue.config.productionTip = false
dotenv.config()

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.config.errorHandler = function (err, vm, info) {
  alert('エラーが発生しました。')
  console.log('エラーが発生しました。')
  console.log(err)
  console.log(info)
  console.log(vm)
}