// 日常清掃・管理業務用エリアマスタ
const ERIA_NORMAL_MANAGEMENT_MASTER = 'eria_normal_management_master'
// 定期清掃用エリアマスタ
const ERIA_FIXED_MASTER = 'eria_fixed_master'
// 増圧ポンプエリアマスタ
const ERIA_BOOSTER_MASTER = 'eria_booster_master'
// 貯水槽エリアマスタ
const ERIA_WATER_MASTER = 'eria_water_storage_master'
// 消防エリアマスタ
const ERIA_FIRE_MASTER = 'eria_fire_master'


/**
 * 日常清掃・管理業務用エリアマスタ 県名部分一致
 * @param {*} context 
 * @param {*} state 県名
 * @returns 
 */
export async function getNomal(context, state) {
  const searchCondition = {
    searchCondition: {
      type: ERIA_NORMAL_MANAGEMENT_MASTER,
      state: {$regex: state}
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const result = responseData
    return result
  } catch (error) {
    console.log('getNomal error !!!')
    console.log(error)
    return null
  }
}

/**
 * 定期清掃用エリアマスタ 県名部分一致
 * @param {*} context 
 * @param {*} state 県名
 * @returns 
 */
export async function getFixed(context, state) {
  const searchCondition = {
    searchCondition: {
      type: ERIA_FIXED_MASTER,
      state: {$regex: state}
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getFixed error !!!')
    console.log(error)
    return null
  }
}

/**
 * 増圧ポンプ用エリアマスタ 県名部分一致
 * @param {*} context 
 * @param {*} state 県名
 * @returns 
 */
export async function getBooster(context, state) {
  const searchCondition = {
    searchCondition: {
      type: ERIA_BOOSTER_MASTER,
      state: {$regex: state}
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getBooster error !!!')
    console.log(error)
    return null
  }
}

/**
 * 貯水槽用エリアマスタ 県名部分一致
 * @param {*} context 
 * @param {*} state 県名
 * @returns 
 */
export async function getWater(context, state) {
  const searchCondition = {
    searchCondition: {
      type: ERIA_WATER_MASTER,
      state: {$regex: state}
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getWater error !!!')
    console.log(error)
    return null
  }
}

/**
 * 消防エリアマスタ 県名部分一致
 * @param {*} context 
 * @param {*} state 県名
 * @returns 
 */
export async function getFire(context, state) {
  const searchCondition = {
    searchCondition: {
      type: ERIA_FIRE_MASTER,
      state: {$regex: state}
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getFire error !!!')
    console.log(error)
    return null
  }
}

/**
 * エリア判定
 * @param {*} context 
 * @param {*} address 住所
 * @param {*} name 清掃名 normal:日常清掃・管理業務、fixed:定期清掃、booster:増圧ポンプ、waterStorage:貯水槽、fire:消防
 * @returns 
 */
export async function getEria(context, address, name) {
  if (address.state == '') {
    return null
  }
  // 日常清掃・管理業務
  if (name == 'normal') {
    let list = await getNomal(context, address.state)
    if (!list.length) {
      return null
    } else if (list.length == 1) {
      return list[0].eria
    } else {
      let filter = list.filter((f) => {
        return address.city.indexOf(f.city) > -1
      })
      if (!filter.length && address.state == '東京都') {
        return '多摩・神奈川'
      } else if (!filter.length) {
        return null
      } else if (filter.length == 1) {
        return filter[0].eria
      } else {
        let filterStreet = filter.filter((f) => {
          return address.street.indexOf(f.street) > -1
        })
        if (!filterStreet.length) {
          return null
        } else if (filterStreet.length == 1) {
          return filterStreet[0].eria
        } else {
          return filterStreet.find((f) => {
            return f.street != ''
          }).eria
        }
      }
    }
  // 定期清掃
  } else if (name == 'fixed') {
    let list = await getFixed(context, address.state)
    if (!list.length) {
      return 'その他'
    } else if (list.length == 1) {
      return list[0].eria
    } else {
      let filter = list.filter((f) => {
        return address.city.indexOf(f.city) > -1
      })
      if (!filter.length) {
        return 'その他'
      } else if (filter.length == 1) {
        return filter[0].eria
      } else {
        let filterStreet = filter.filter((f) => {
          return address.street.indexOf(f.street) > -1
        })
        if (!filterStreet.length) {
          return 'その他'
        } else if (filterStreet.length == 1) {
          return filterStreet[0].eria
        } else if (filterStreet.length > 1){
          let filterEmpty = filterStreet.find((f) => {
            return f.street != ''
          })
          return filterEmpty.eria
        }
      }
    }
  // 増圧ポンプ
  } else if (name == 'booster') {
    let list = await getBooster(context, address.state)
    if (!list.length) {
      return 'その他'
    } else if (list.length == 1) {
      return list[0].eria
    } else {
      let filter = list.filter((f) => {
        return address.city.indexOf(f.city) > -1
      })
      if (!filter.length) {
        return 'その他'
      } else if (filter.length == 1) {
        return filter[0].eria
      } else {
        let filterStreet = filter.filter((f) => {
          return address.street.indexOf(f.street) > -1
        })
        if (!filterStreet.length) {
          return 'その他'
        } else if (filterStreet.length == 1) {
          return filterStreet[0].eria
        } else if (filterStreet.length > 1){
          let filterEmpty = filterStreet.find((f) => {
            return f.street != ''
          })
          return filterEmpty.eria
        }
      }
    }
  
  // 貯水槽
  } else if (name == 'waterStorage') {
    
    let list = await getWater(context, address.state)
    if (!list.length) {
      return {eriaNittsu: 'その他', eriaBesper: 'その他'}
    }
    let nittsuList = []
    let besperList = []
    let nit = 'その他'
    let besp = 'その他'
    for (let i = 0; i < list.length; i++) {
      const l = list[i]
      if (l.supplier == '1') {
        nittsuList.push(l)
      } else if (l.supplier == '2') {
        besperList.push(l)
      }
    }
    // ニッツ
    if (nittsuList.length) {
      if (nittsuList.length == 1) {
        nit = nittsuList[0].eria
      } else {
        let filter = nittsuList.filter((f) => {
          return address.city.indexOf(f.city) > -1
        })
        if (filter.length == 1) {
          nit = filter[0].eria
        }
      }
    }
    // ベスパー
    if (besperList.length == 1) {
      besp = besperList[0].eria
    }

    return {eriaNittsu: nit, eriaBesper: besp}

  // 消防
  } else if (name == 'fire') {
    let list = await getFire(context, address.state)
    if (!list.length) {
      return 'その他'
    } else if (list.length == 1) {
      return list[0].eria
    } else {
      let filter = list.filter((f) => {
        return address.city.indexOf(f.city) > -1
      })
      if (!filter.length) {
        return 'その他'
      } else if (filter.length == 1) {
        return filter[0].eria
      } else {
        let filterStreet = filter.filter((f) => {
          return address.street.indexOf(f.street) > -1
        })
        if (!filterStreet.length) {
          return 'その他'
        } else if (filterStreet.length == 1) {
          return filterStreet[0].eria
        } else if (filterStreet.length > 1){
          let filterEmpty = filterStreet.find((f) => {
            return f.street != ''
          })
          return filterEmpty.eria
        }
      }
    }
  }
}