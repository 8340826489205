<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <div v-if="loading" class="flex justify-center items-center loading-dialog">
        <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
      </div>
      <template #title>他の見積りから明細を引用</template>
      <template #subtitle>引用したいデータをクリックしてください。</template>
      <div class="bg-indigo-800">
        <div class="flex py-2">
          <input
            type="search"
            name="estimateNo" 
            autocomplete="off"
            placeholder="見積りNo 検索"
            class="mx-2 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
            v-model="filter.estimateNo"
          />
          <input
            type="search"
            name="clientName" 
            autocomplete="off"
            placeholder="得意先名 検索"
            class="mr-2 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
            v-model="filter.clientName"
          />
          <input
            type="search"
            name="buildingName" 
            autocomplete="off"
            placeholder="建物名 検索"
            class="mr-2 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
            v-model="filter.buildingName"
          />
          <input
            type="search"
            name="taskStaff" 
            autocomplete="off"
            placeholder="担当者 検索"
            class="mr-2 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
            v-model="filter.taskStaff"
          />
          <DateSelect
            ref="dateSelect"
            id="filterDate"
            :presetName="filter.periodPresetName"
            :period="filter.period"
            :none="true"
            class=""
            @change="periodChange"
          />
          <!-- 検索ボタン -->
          <PrimaryButton
            text="検索"
            @click="getList()"
            class="ml-auto mr-2 w-32 border-white"
            :buttonContents="'要'"
            :disabled="loading"
          >
          </PrimaryButton>
        </div>
      </div>
      <!-- 見積一覧 -->
      <div v-if="!open">
        <div class="content-container flex flex-col border border-gray-300 rounded relative">
          <div v-if="list&&list.length" style="max-height: 78vh;">
            <table class="table-auto overflow-auto w-full ">
              <thead class="sticky top-0 z-50">
                <tr v-if="list && list.length" class="border border-gray-200">
                  <th v-for="(c, index) in columns" :key="'cu' + index" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 tracking-wider" style="background: #ebf0fb;" :class="c.class">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

              <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 tracking-wider hover-color cursor-pointer">

                <!-- 見積番号 -->
                <td class="b-top text-left px-6 py-3 whitespace-nowrap text-sm font-bold text-blue-500 min-w-fit cursor-pointer"  @click="toEstimateView(row.estimateNo)">
                  {{ row.estimateNo }}
                </td>

                <!-- サブタスク/要件 -->
                <td class="px-6 py-3 truncate text-sm text-gray-700 min-w-200 max-w-250px" @click="getDetails(row)">
                  <div class="truncate">
                    {{ row.requestPostName }}
                  </div>
                  <div class="truncate">
                    {{ row.requirement }}
                  </div>
                </td>
                <!-- 得意先/物件名 -->
                <td class="px-6 py-3 truncate text-sm text-gray-700 min-w-200 max-w-250px" @click="getDetails(row)">
                  <div class="truncate">
                    {{ row.clientName }}
                  </div>
                  <div class="truncate">
                    {{ row.buildingName }}
                  </div>
                </td>
                <!-- 税抜き/税込み -->
                <td class="px-6 py-3 text-right whitespace-prerap text-sm text-gray-700 min-w-fit" @click="getDetails(row)">
                  <div>
                    {{ row.withoutTax2 }}
                  </div>
                  <div>
                    {{ row.totalAmount2 }}
                  </div>
                </td>
                <!-- 作成日/期限 -->
                <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-w-130" @click="getDetails(row)">
                  <div>
                    {{ row.estimateDate }}
                  </div>
                  <div>
                    {{ row.expireDate }}
                  </div>
                </td>
                <!-- 担当者 -->
                <td class="px-6 py-3 text-sm text-gray-700" @click="getDetails(row)">
                  <div class="truncate w-28">
                    {{ row.taskStaff }}
                  </div>
                </td>
                <!-- 承認1 -->
                <td class="text-center px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-w-fit" @click="getDetails(row)">
                  <div>
                    <Badge :color="EstimateStatusColorTable[row.approval1]">{{ row.approval1 }}</Badge>
                  </div>
                  <div>
                    {{ row.approval1Name }}
                  </div>
                </td>
                <!-- 承認2 -->
                <td class="text-center px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-w-fit" @click="getDetails(row)">
                  <div>
                    <Badge :color="EstimateStatusColorTable[row.approval2]">{{ row.approval2 }}</Badge>
                  </div>
                  <div>
                    {{ row.approval2Name }}
                  </div>
                </td>
                <!-- 状態 -->
                <td class="text-center px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-w-fit" @click="getDetails(row)">
                  <Badge :color="EstimateStatusColorTable[row.statusBadge]">{{ row.statusBadge }}</Badge>
                </td>
              </tr>
            </table>
          </div>
          <template v-else-if="!fetch&&!loading">
            <div class="w-full h-full flex justify-center items-center text-gray-500 font-bold my-auto">
              検索ボタンを押してください。
            </div>
          </template>
          <template v-else-if="overLimitData&&!loading">
            <div class="w-full h-full flex justify-center items-center text-gray-500 font-bold my-auto">
              データが多すぎます。条件を絞り込んでください。
            </div>
          </template>
          <template v-else-if="!loading">
            <div class="w-full h-full flex justify-center items-center text-gray-500 font-bold my-auto">
              条件に一致するデータがありません。
            </div>
          </template>
        </div>
      </div>
      <div v-else class="content-container">
        <EstimateDetailSelector 
          ref="EstimateDetailSelector"
          :estimateData="estimateData"
          :open="open"
          @selectionChange="selectionChange"
          @selectionChangeMemo="selectionChangeMemo"
          @searchEnd="searchDetailEnd"
        />
      </div>
      <template v-if="estimateData" #action>
        <div class="flex w-full justify-between">
          <PrimaryButton v-if="(selectedDetails && selectedDetails.details.length) || memo" text="選択したデータを見積りに引用" size="sm ml-auto" @click="apply" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@components/Modal.vue'
import EstimateDetailSelector from './EstimateDetailSelector.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import DateSelect from '@components/DateSelect.vue'
import Badge from '@components/Badge.vue'
import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'
import * as constants from '@libs/constants'

const EstimateStatusColorTable = {}
constants.ESTIMATE_STATUS_COLOR.forEach((s) => {
  EstimateStatusColorTable[s.name] = s.color
})

export default {
  components: {
    Modal,
    EstimateDetailSelector,
    PrimaryButton,
    DateSelect,
    Badge
  },

  inheritAttrs: false,

  props: {
    excludeEstimateNo: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      renderKey: 0,
      selectedDetails: null,
      loading: false,
      filter: {
        estimateNo: '',
        clientName: '',
        buildingName: '',
        taskStaff: '',
        periodPresetName: '2_month',
        period: null
      },
      // 一覧データ
      list: [],
      // 一覧のカラム
      columns: [
        { title: '見積番号', class: 'text-left' },
        { title: 'サブタスク / 要件', class: 'text-left' },
        { title: '得意先名 / 建物名', class: 'text-left' },
        { title: '税抜 / 税込', class: 'text-right' },
        { title: '作成日 / 期限', class: 'text-left' },
        { title: '担当者 / 作成者', class: 'text-left' },
        { title: '承認者1', class: 'text-center' },
        { title: '承認者2', class: 'text-center' },
        { title: '状態', class: 'text-center' }
      ],
      // 一覧のアイコン色
      EstimateStatusColorTable,
      // 件数が多すぎで表示不可
      overLimitData: false,
      // 一度でも検索ボタンを押したか
      fetch: false,
      // どの見積もりにするか確定したときの見積もりデータ
      estimateData: null,
      // 見積り選択ずみ
      open: false,
      // メモ
      memo: null
    }
  },

  created() {
    // 初期値はログインユーザー
    this.filter.taskStaff = utils.deleteKana(this.$store.getters.user.user_name)
  },

  methods: {
    /**
     * 見積り明細チェック変更イベント
     * @param selectedDetails 選択したデータ
     */
    selectionChange(selectedDetails) {
      this.selectedDetails = selectedDetails
    },

    /**
     * メモチェック変更イベント
     * @param memo メモ
     */
    selectionChangeMemo(memo) {
      this.memo = memo
    },

    apply() {
      this.$emit('apply', { selectedDetails: this.selectedDetails, memo: this.memo })
    },

    closed() {
      console.log('EstimateDetailSelectorModal::closed')
      
      this.selectedDetails = null
      this.estimateData = null
      this.open = false
      this.memo = null
      this.loading = false
      this.renderKey++
    },

    /**
     * 期間変更
     */
    periodChange(range, presetName) {
      this.filter.period = range
      this.filter.periodPresetName = presetName
    },

    /**
     * 検索ボタン
     */
    async getList() {
      this.loading = true
      this.fetch = true
      this.overLimitData = false
      this.estimateData = null
      this.open = false
      const estimates = await estimateManager.quoteOtherEstimation(this, this.filter)
      if (estimates.error) {
        if (estimates.msg && estimates.msg.indexOf('limit') > -1) {
          this.overLimitData = true
        }
        
        this.list = []
      } else {
        this.formingData(estimates)
        
      }
      this.loading = false
    },

    /**
     * 詳細データ表示
     */
    getDetails(row) {
      this.estimateData = row
      this.open = true
    },

    /**
     * 詳細データ取得完了
     */
    searchDetailEnd() {
      this.loading = false
    },

    /**
     * 一覧のデータを成形
     * @param val データ
     */
    formingData(val) {
      this.list = []
      if (val && val.length) {
        val.forEach((v) => {
          // 自身の見積もりは表示しない
          if (v.estimateNo != this.excludeEstimateNo) {
            // 3桁区切りの金額
            v.withoutTax2 = utils.formatNumber(v.withoutTax, true)
            v.totalAmount2 = utils.formatNumber(v.totalAmount, true)
            // 状態
            v.statusBadge = this.getStatus(v.estimateStatus, v.orderStatus)
            this.list.push(v)
          }
        })
      }
    },

    /**
     * 状態を取得
     * @param status 見積りステータス
     * @param order 見積り受注のステータス
     */
    getStatus(status, order) {
      let res = status
      if (status == '受注') {
        if (order) {
          if (order.status) {
            res = order.status
          }
        }
      }
      return res
    },

		/**
		 * 詳細画面を開く
		 */
		toEstimateView(estimateNo) {
			if (estimateNo) {
				let resolvedRoute = this.$router.resolve({ 
					name: 'EstimateView',
					query: {
						estimateNo
					}
				})
				window.open(resolvedRoute.href, '_blank')
			}
		}

  }
}
</script>
<style scoped>
.content-container {
  min-width: 1500px;
  min-height: 70vh;
  overflow: auto;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
}

#filterDate >>> div {
  color: rgb(70, 70, 70) !important;
}

#filterDate >>> input[type=text] {
  background: white !important;
}
</style>