<template>
  <VueFinalModal
    :click-to-close="false" 
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="flex justify-center items-center"
    content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900 overflow-auto"
    v-on="$listeners"
  >
    <span class="text-lg font-bold flex items-center">
      <div class="flex flex-col w-full">
        <div class="flex items-center">
          <slot name="title"></slot>
          <span class="text-sm text-gray-500 font-normal ml-4 flex-1">
            <slot name="subtitle"></slot>
          </span>
          <!-- <button @click="close"> -->
          <button type="button" v-if="!xIconDisabled" @click="closeModal(close)">
            <XIcon />
          </button>
        </div>
        <div class="text-sm text-gray-500 font-normal ml-4 flex-1">
          <slot name="sub-header"></slot>
        </div>
      </div>
    </span>
    <div class="flex-grow overflow-y-auto pt-3">
      <slot v-bind:params="params"></slot>
    </div>
    <div class="items-center pt-3" :class="!isMobileDevice?'flex-shrink-0 flex justify-end':''">
      <slot name="action"></slot>
      <!-- <v-button class="vfm-btn" @click="$emit('confirm', close)">confirm</v-button>
      <v-button class="vfm-btn" @click="$emit('cancel', close)">cancel</v-button> -->
    </div>
    <!-- <button class="absolute top-0 right-0 mt-2 mr-2" @click="close">
      <XIcon />
    </button> -->
  </VueFinalModal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import { XIcon } from '@vue-hero-icons/solid'
import * as utils from '@libs/utils'
export default {
  name: 'Modal',
  inheritAttrs: false,
  components: {
    VueFinalModal,
    XIcon
  },
  props: {
    forceClosedEvent: {
      type: Boolean,
      default: false
    },
    xIconDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  methods: {
    closeModal(closeFunc) {
      closeFunc()
      if (this.forceClosedEvent) {
        this.$emit('closed')
      }
    }
  }
}
</script>
