<template>
  <div>
    <PatrolInspectionComponent
      typeName="巡回清掃 報告書項目設定"
      :type="2"
      settionStrageName="RoundInspectionStorageItemName">
    </PatrolInspectionComponent>
  </div>
</template>

<script>
import PatrolInspectionComponent from '../../Patrol/components/PatrolInspectionComponent.vue'

export default {
  components: {
    PatrolInspectionComponent
  },
}
</script>