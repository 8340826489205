<template>
<div>
  <h3 
    class=" text-lg font-bold"
    :class="classes"
  >
    {{ caption }}
  </h3>  
  <p 
    class="text-sm font-bold border-b"
    :class="classes"
  >
    {{ description }}
  </p>  
</div>
</template>

<script>
    export default {
        props: {
            caption: {
            type: String,
            default: ''
            },
            color: {
            type: String,
            default: 'black'
            },
            description: {
              type: String,
              default: ''
            }
        },
        computed: {
            classes() {
            const classes = []
            classes.push(`text-${this.color}-400`)
            classes.push(`border-${this.color}-300`)
            return classes
            }
        }
    }
</script>
