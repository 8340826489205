<template>
  <div>
    <div class="text-gray-500 mb-2">
      日常清掃の トイレ使用 不可 の場合は、こちらの管理員業務の単価を参照します。
    </div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div :class="c.class" class="p-3">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr class="sticky top-8 z-20">
          <th v-for="(c, i) in column2" :key="i">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1">
              {{ c.name }}時間
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in cleaningTimes" :key="i" class="hover-color text-right" :class="c=='6.5'?'border-b border-gray-300':''">
          <th v-if="eria[i].name" :rowspan="eria[i].rowspan" class="sticky left-0 z-10 bg-white text-black th-name">
            <div class="ml-3 mr-2 bg-white">
              {{eria[i].name}}
            </div>
          </th>
          <th class="sticky left-100p z-10 bg-white th-week-count">
            <div class="ml-3 mr-2" :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']">
              週 {{c}} 回
            </div>
          </th>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime1[i].price"
                @change="changeData(noTime1[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-blue-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime1Half[i].price"
                @change="changeData(noTime1Half[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-teal-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime2[i].price"
                @change="changeData(noTime2[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-blue-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime2Half[i].price"
                @change="changeData(noTime2Half[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-teal-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime3[i].price"
                @change="changeData(noTime3[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-blue-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="noTime4[i].price"
                @change="changeData(noTime4[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-red-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time2[i].price"
                @change="changeData(time2[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-yellow-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time2Half[i].price"
                @change="changeData(time2Half[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-red-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time3[i].price"
                @change="changeData(time3[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-yellow-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time4[i].price"
                @change="changeData(time4[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-red-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time5[i].price"
                @change="changeData(time5[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-yellow-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time6[i].price"
                @change="changeData(time6[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-red-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time7[i].price"
                @change="changeData(time7[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-yellow-50"
                :class="[c=='6.5'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="time8[i].price"
                @change="changeData(time8[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 管理員業務
 */
const CATEGORY = '4'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 清掃回数
      cleaningTimes: [],
      // エリアのカラム
      eria: [
        { name: '超都心', rowspan: 12, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '都心', rowspan: 12, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '千葉・埼玉', rowspan: 12, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '多摩・神奈川', rowspan: 12, class: 'ml-5 bg-blue-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
      ],
      // 1段目のカラム
      column1: [
        { name: '', colspan: 2, class: 'bg-white sticky w-24 left-0 z-20'},
        { name: '時間指定なし', colspan: 6, class: 'ml-5 bg-teal-50'},
        { name: '時間指定あり', colspan: 8, class: 'ml-5 bg-red-50'}
      ],
      // 2段目のカラム
      column2: [
        // 時間指定なし
        { name: '', class: 'bg-white'},
        { name: '', class: 'bg-white'},
        { name: '1', class: 'ml-5 bg-teal-50'},
        { name: '1.5', class: 'bg-blue-50'},
        { name: '2', class: 'bg-teal-50'},
        { name: '2.5', class: 'bg-blue-50'},
        { name: '3', class: 'bg-teal-50'},
        { name: '4', class: 'bg-blue-50'},
        // 時間指定あり
        { name: '2', class: 'ml-5 bg-red-50'},
        { name: '2.5', class: 'bg-yellow-50'},
        { name: '3', class: 'bg-red-50'},
        { name: '4', class: 'bg-yellow-50'},
        { name: '5', class: 'bg-red-50'},
        { name: '6', class: 'bg-yellow-50'},
        { name: '7', class: 'bg-red-50'},
        { name: '8', class: 'bg-yellow-50'}
      ],
      // 時間指定ない
      noTime1: [],
      noTime1Half: [],
      noTime2: [],
      noTime2Half: [],
      noTime3: [],
      noTime4: [],
      // 時間指定あり
      time2: [],
      time2Half: [],
      time3: [],
      time4: [],
      time5: [],
      time6: [],
      time7: [],
      time8: [],
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    dataByCategory() {
      // 清掃回数カラム生成
      this.setCleaningTimes()
      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })
      if (dbc && dbc.length) {
        for (let i = 0; i < dbc.length; i++) {
          const l = dbc[i]
          let code = l.priceCode.split('-')
          switch (code[2]) {
            // 時間指定なし
            case '0':
              switch (code[3]) {
                case '1':
                  this.noTime1.push(l)
                  break;
                case '1.5':
                  this.noTime1Half.push(l)
                  break;
                case '2':
                  this.noTime2.push(l)
                  break;
                case '2.5':
                  this.noTime2Half.push(l)
                  break;
                case '3':
                  this.noTime3.push(l)
                  break;
                case '4':
                  this.noTime4.push(l)
                  break;
              }
              break;
          
            // 時間指定あり
            case '1':
              switch (code[3]) {
                case '2':
                  this.time2.push(l)
                  break;
                case '2.5':
                  this.time2Half.push(l)
                  break;
                case '3':
                  this.time3.push(l)
                  break;
                case '4':
                  this.time4.push(l)
                  break;
                case '5':
                  this.time5.push(l)
                  break;
                case '6':
                  this.time6.push(l)
                  break;
                case '7':
                  this.time7.push(l)
                  break;
                case '8':
                  this.time8.push(l)
                  break;
              }
              break;
          }
        }

        this.noTime1 = this.setByTimes(this.noTime1)
        this.noTime1Half = this.setByTimes(this.noTime1Half)
        this.noTime2 = this.setByTimes(this.noTime2)
        this.noTime2Half = this.setByTimes(this.noTime2Half)
        this.noTime3 = this.setByTimes(this.noTime3)
        this.noTime4 = this.setByTimes(this.noTime4)
        this.time2 = this.setByTimes(this.time2)
        this.time2Half = this.setByTimes(this.time2Half)
        this.time3 = this.setByTimes(this.time3)
        this.time4 = this.setByTimes(this.time4)
        this.time5 = this.setByTimes(this.time5)
        this.time6 = this.setByTimes(this.time6)
        this.time7 = this.setByTimes(this.time7)
        this.time8 = this.setByTimes(this.time8)
      }
    },

    /**
     * 清掃時間ごとのデータ生成
     * @param target 対象データ
     */
    setByTimes(target) {
      let result = []
      let eria1 = []
      let eria2 = []
      let eria3 = []
      let eria4 = []
      for (let k = 0; k < target.length; k++) {
        const t =target[k]
        let code = t.priceCode.split('-')
        switch (code[0]) {
          // 超都心
          case '1':
            eria1.push(t)
            break;
            
          // 都心
          case '2':
            eria2.push(t)
            break;
            
          // 千葉・埼玉
          case '3':
            eria3.push(t)
            break;
            
          // 多摩・神奈川
          case '4':
            eria4.push(t)
            break;
        
          default:
            break;
        }
      }
      eria1 = this.setTimesSort(eria1)
      eria2 = this.setTimesSort(eria2)
      eria3 = this.setTimesSort(eria3)
      eria4 = this.setTimesSort(eria4)

      if (eria1.length) {
        result = result.concat(eria1)
      }
      if (eria2.length) {
        result = result.concat(eria2)
      }
      if (eria3.length) {
        result = result.concat(eria3)
      }
      if (eria4.length) {
        result = result.concat(eria4)
      }
      return result
    },

    /**
     * 清掃回数のソート
     * @param target 対象データ
     */
    setTimesSort(target) {
      const times = ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5']
      let result = []
      for (let l = 0; l < times.length; l++) {
        const time = times[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[4] == time) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 清掃回数カラム生成
     */
    setCleaningTimes() {
      this.cleaningTimes = []
      for (let i = 0; i < 4; i++) {
        this.cleaningTimes = this.cleaningTimes.concat([
          '1',
          '1.5',
          '2',
          '2.5',
          '3',
          '3.5',
          '4',
          '4.5',
          '5',
          '5.5',
          '6 (7)',
          '6.5'
        ])
      }
    },
    
    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''
      let name4 = ''

      switch (code[0]) {
        case '1':
          name1 = '超都心'
          break;
      
        case '2':
          name1 = '都心'
          break;
          
        case '3':
          name1 = '千葉・埼玉'
          break;
          
        case '4':
          name1 = '多摩・神奈川'
          break;
      }
      
      switch (code[2]) {
        case '0':
          name2 = '・時間指定なし'
          break;
      
        case '1':
          name2 = '・時間指定あり'
          break;
      }

      if (code[3]) {
        name3 = `・${code[3]}ｈ`
      }

      if (code[4]) {
        name4 = `・週${code[4]}回`
      }

      target.itemName = name1 + name2 + name3 + name4

      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.th-name {
  min-width: 150px;
}

.th-week-count {
  min-width: 120px;
}</style>
