<template>
  <div>
      <!-- <div v-if="processing" class="flex justify-center items-center loading-dialog2">
        <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">{{ processingText }}</p>
      </div> -->
      <template>
        <div class="overflow-auto" :class="isMobileDevice?'mobile-content':'pc-content'">

          <div class="flex justify-end">
            <div class="flex preview-btn rounded">
              <div v-if="!this.target.completionDate" @click="previewBtn()" class="font-bold text-white bg-teal-700 px-5 py-1 rounded cursor-pointer" :class="preview?'mr-10':''">
                {{ !preview?'プレビュー':'プレビューを閉じる' }}
              </div>
              <Icon v-if="!preview&&!processing" slot="before" iconName="X" iconType="outline" :strokeWidth="2" class="h-8 w-8 ml-10 mr-1 mb-5" :clickable="true"  @click="previous()" />
              <LoadingIcon v-else-if="!preview&&processing" slot="before" class="h-8 w-8 ml-10 mr-1 mb-5 text-gray-700" />
            </div>
          </div>

          <div class="text-lg text-blue-800 font-bold flex ">
            <div class="ml-3">報告書</div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold mr-auto info1">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold info2">
                物件名 : {{ list.siteName }}
                <span v-if="list.ridgeCode" class="text-red-700">（{{ list.ridgeName }}）</span>
              </div>
            </div>
            <!-- 編集可能の場合 -->
            <div v-if="canEdit" class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                作業日 : {{ list.today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                時間 : <span v-if="start!=''">{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <!-- 編集不可能の場合 -->
            <div v-else v-for="(today, ti) in list.today" :key="ti" class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                作業日 : {{ today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                時間 : {{ start[ti] }} ～ {{ end[ti] }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                責任者 : {{ list.staff }}
              </div>
              <div v-if="(canEdit||location.indexOf('1日目')==-1)" class="text-gray-600 font-bold info2">
                位置情報 : {{ location }}
              </div>
            </div>
            <div v-if="!canEdit&&location.indexOf('1日目')!=-1" class="mr-auto mt-5 info-group mb-3">
              <div class="text-gray-600 font-bold w-full">
                位置情報 :
                <span class="text-xs">{{ location }}</span>
              </div>
            </div>

            <div v-if="canEdit" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="開始" size="normal" class="w-full mr-1" :class="start!=''?'bg-gray-400':''" v-if="start==''" @click="startBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="キャンセル" size="normal" class="w-full mr-1 bg-red-400 hover:bg-red-500" v-if="start!=''" @click="cancelBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="終了" size="normal" class="w-full ml-1" :class="start==''||end!=''||processing?'bg-gray-400':''" :disabled="start==''||end!=''" @click="endBtn()" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
            </div>

            <div v-if="completeBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を再表示" size="normal" class="w-full mr-1 bg-teal-50 hover:bg-teal-50 edit text-teal-700" @click="reDisplay()" />
            </div>

            <div v-if="undoBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を非表示に戻す" size="normal" class="w-full mr-1 bg-yellow-50 hover:bg-yellow-50 edit text-yellow-700 border-yellow-600" @click="undo()" />
            </div>

            <div v-if="canEdit" class="flex justify-end mt-2">
              <div v-if="tempId==6">
                <div class="bg-indigo-700 px-6 py-1 text-center font-bold text-sm text-white rounded cursor-pointer mr-3" @click="addSafety()">
                  安全対策追加
                </div>
              </div>
              <div class="flex">
                <span class="text-gray-500 font-bold text-xs mr-1 ml-auto my-auto">「該当なし」を表示</span>
                <Toggle v-model="toggleNA" size="small"/>

                <PrimaryButton text="項目設定" size="sm py-1" class="bg-indigo-700 ml-4" @click="editItem()" />
              </div>
            </div>

            <!-- 三井の場合のみ -->
            <div v-if="tempId == 4" class="text-xs text-gray-600 font-bold m-auto w-fit mt-2">三井不動産の場合、今回対象ではない清掃項目は『該当なし』を選択してください。（エクセルに表示したくない清掃項目は『非表示』にしてください。）</div>
            <!-- 大和ライフネクストのみ、工程の中にその他がある場合 -->
            <div v-if="tempId == 8&&processOtherFlg" class="flex" :class="!canEdit?'mt-5':''">
              <div class="h-full my-auto mr-2">【作業工程】 P: その他（</div>
              <div>
                <input
                  placeholder="ご記入ください"
                  type="text"
                  v-model="processOther"
                  class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1 w-full"
                  @change="changeProcessOther()"
                />
              </div>
              <div class="h-full my-auto ml-2">
                ）
              </div>
            </div>
          </div>

          <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
            <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.classification != 'safety' && !row.monthlyDisabled">
              
              <div class="flex">
                <!-- No -->
                <div class="w-8 text-blue-700 font-bold my-auto">
                  <span v-if="row.sortNo > 0 && row.classification != 'safety'">{{ row.sortNo }}.</span>
                  <span v-else-if="row.classification != 'safety'">◆</span>
                </div>

                <!-- 区分 -->
                <div v-if="row.sortNo > 0 && canEdit">
                  
                  <div v-if="row.classification.indexOf('サービス作業') != -1" class="text-xs text-gray-500 ml-2 mb-2 my-auto">ここから下はサービス作業という意味です。報告書に表示させたくない場合は、文言を変更、もしくは該当なしを選択してください。</div>
                  <div class="flex">
                    <!-- 毎月・偶数月・奇数月 -->
                    <div class="mr-1 mb-1 mt-auto">
                      <div
                        class="py-1 w-16 rounded text-white font-bold text-center cursor-pointer"
                        :class="monthlyBtnColor(row.monthly)"
                        @click="changeMonthly(row)"
                      >
                        {{ row.monthly || '毎月' }}
                      </div>
                    </div>
                    <!-- 清掃箇所 -->
                    <input
                      type="text"
                      v-model="row.classification"
                      class="h-9 mt-auto border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 font-bold mb-1"
                      @change="temporarilyRegister('item', row)"
                    />
                    
                    <!-- 詳細1 -->
                    <div v-if="row.classification.indexOf('サービス作業') == -1 && tempId != 8">
                      <div class="text-xs text-center text-gray-400">{{ placeholder(1, row) }}</div>
                      <input
                        v-if="tempId&&![7].includes(tempId)&&(placeholder(1, row).indexOf('補修')==-1)"
                        type="text"
                        v-model="row.detail1"
                        class="text-center border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                        @change="temporarilyRegister('item', row)"
                      />
                      <div v-else-if="tempId==6" class="text-white text-center cursor-pointer mb-1 mx-1 w-20">
                        <div class="p-1 font-bold rounded" :class="row.detail1=='C'?'bg-indigo-500':'bg-gray-400'" @click="itemEvaluation(row, 'detail1', 'C')">C</div>
                      </div>
                    </div>
                    <!-- 詳細2 -->
                    <div v-if="row.classification.indexOf('サービス作業') == -1">
                      <div class="text-xs text-center text-gray-400">{{ placeholder(2, row) }}</div>
                      <input
                        v-if="tempId&&![7, 8].includes(tempId)&&(placeholder(2, row).indexOf('ボタン')==-1)&&(placeholder(2, row).indexOf('補修')==-1)"
                        type="text"
                        v-model="row.detail2"
                        class="text-center border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                        @change="temporarilyRegister('item', row)"
                      />
                      <div v-else-if="tempId==3" class="bg-indigo-500 text-white rounded text-center cursor-pointer">
                        <div v-if="!row.start" class="p-1 font-bold" @click="itemStartEnd(row)">開始</div>
                        <div v-else class="p-1 font-bold" @click="itemStartEnd(row)">終了</div>
                      </div>
                      <div v-else-if="tempId==6" class="text-white text-center cursor-pointer mb-1 w-20">
                        <div class="p-1 font-bold rounded" :class="row.detail2=='D'?'bg-indigo-500':'bg-gray-400'" @click="itemEvaluation(row, 'detail2', 'D')">D</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="row.sortNo==-1000 && canEdit && tempId != 4" class="flex">
                  <PrimaryButton text="定期清掃" size="normal" class="font-bold p-6p mb-2" :class="row.classification=='定期清掃'?'':'bg-gray-400'" @click="cleaningTitle(i, '定期清掃')" />
                  <PrimaryButton text="特別清掃" size="normal" class="font-bold p-6p ml-3 mb-2" :class="row.classification=='特別清掃'?'':'bg-gray-400'" @click="cleaningTitle(i, '特別清掃')" />
                  <div class="text-xs text-gray-500 ml-3 my-auto">当てはまる清掃をクリックしてください。特別清掃の場合は、内容をご記入ください。</div>
                </div>
                <!-- 編集不可 -->
                <div v-else class="flex">
                  <!-- 毎月設定 -->
                  <div v-if="row.sortNo > 0" class="text-xs mt-auto mr-2 py-1 w-12 rounded text-white font-bold text-center" :class="monthlyBtnColor(row.monthly)">
                    {{ row.monthly || '毎月' }}
                  </div>
                  <!-- 清掃箇所 -->
                  <span class="text-blue-700 font-bold ">
                    {{ row.classification }}
                  </span>
                  <div v-if="row.sortNo < -1000 && canEdit" class="text-xs text-gray-500 ml-3 my-auto">作業後に確認して完了してください。</div>
                  <span v-if="tempId && row.detail1" class="text-blue-700">
                    （{{ row.detail1 }}）
                  </span>
                  <span v-if="tempId && row.detail2 && tempId!=8" class="text-blue-700">
                    （{{ row.detail2 }}）
                  </span>
                  </div>
              </div>

              <div class="flex" :class="row.value == 'N/A'?'mb-6':''">
                <!-- 項目 -->
                <!-- 大和ライフの鍵返却などの項目 -->
                <div v-if="tempId == 8 && ['鍵の返却', '門扉などの施錠'].includes(row.classification)" class="ml-8 mb-1">
                  <div v-if="canEdit" class="mb-1 text-gray-500 text-xs">当てはまる項目を選択してください。</div>
                  <SelectToStringComponent
                    ref="selectToString"
                    :value="row.item"
                    :canEdit="canEdit"
                    :selection="row.classification.indexOf('返却')>-1?['鍵の貸し出しなし', '管理人（ＦＭ）へ返却', 'キーボックスへ返却', '管理室内キーボックスに返却', 'その他']:['施錠を確認', '門扉の開閉なし', '管理人（ＦＭ）にて確認', 'その他']"
                    @change="changeDaiwaKey(row, $event)"
                  />
                </div>
                <!-- 一般的な項目 -->
                <div v-else-if="(nonCamera.includes(row.classification) || row.sortNo > 0) && canEdit && !(tempId == 8 && row.sortNo > 0)" class="ml-8 mb-1 w-full">
                  <input
                    :type="(row.classification.indexOf('人数')==-1&&row.classification.indexOf('建物番号')==-1)?'text':'number'"
                    v-model="row.item"
                    :placeholder="row.classification.indexOf('休憩時間')>-1?'〇時〇分～〇時〇分でご記入ください':''"
                    :disabled="row.sortNo==-1000&&row.classification=='定期清掃'"
                    :class="row.sortNo==-1000&&row.classification=='定期清掃'?'bg-gray-100 cursor-not-allowed':''"
                    class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                    @change="temporarilyRegister('item', row)"
                  />
                </div>
                <!-- 大和ライフネクストの清掃種別 -->
                <div v-else-if="canEdit && tempId == 8 && row.sortNo > 0" class="ml-8 mt-2">  
                  <div class="text-white text-sm text-center cursor-pointer flex">
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='床面清掃'||!row.detail1?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, '床面清掃')">床面清掃</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='ガラス(高所以外)'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'ガラス(高所以外)')">ガラス(高所以外)</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='ガラス(高所)'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'ガラス(高所)')">ガラス(高所)</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1" :class="row.detail1=='その他の清掃'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'その他の清掃')">その他の清掃</div>
                  </div>
                </div>
                <div v-else class="ml-8 mb-1" :class="row.value=='N/A'?'mb-6':''">
                  {{ row.item }}
                </div>
                
                <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA" class="ml-auto">
                  <div class="ml-8 flex h-9">
                    <!-- 該当なし -->
                    <PrimaryButton v-if="canEdit && !nonCamera.includes(row.classification) && !(tempId==8 && row.classification=='安全対策')" text="該当なし" size="normal" class="w-97px pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                    <PrimaryButton v-if="!canEdit && row.value=='N/A'" text="該当なし" size="normal" class="w-97px py-1 pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                    <!-- カメラ -->
                    <div v-if="!row.disabled && row.value!='N/A' && canEdit && start!='' && !nonCamera.includes(row.classification) && row.sortNo > -1000">
                      <div class="file-btn rounded w-70px bg-darkgreen h-full">
                        <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                          <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                        </PrimaryButton>
                        <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" @change="imageToBase64(i, $event)" multiple />
                      </div>
                    </div>
                    <!-- 非表示ボタン -->
                    <PrimaryButton v-if="canEdit && row.value=='N/A' && row.sortNo > 0" text="非表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p w-70px" @click="disabledNA(i)" />
                    <!-- 完了ボタン -->
                    <PrimaryButton v-if="canEdit && row.complete && compareDate(row.updateDate, list.today)" :text="formatDate(row.updateDate)" size="bg-cbl" class="text-white rounded font-bold p-6p ml-3 w-59px cursor-not-allowed relative z-50" />
                    <PrimaryButton v-else-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="comp-btn text-blue-800 border border-blue-800 rounded font-bold p-6p ml-3 w-59px" @click="complete(i)" :disabled="processing" >
                      <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                    </PrimaryButton>
                  </div>
                </div>
                <div v-if="!canEdit" class="text-sm text-gray-600 border border-gray-200 bg-gray-50 p-1 h-fit min-w-fit">{{ formatDate(row.updateDate) }} 完了</div>
              </div>

              <!-- 大和ライフネクストのみ -->
              <div v-if="row.sortNo > 0 && tempId == 8" class="ml-8">
                <!-- 作業工程 -->
                <div v-if="(!row.detail1 || row.detail1=='床面清掃') && canEdit && row.value!='N/A'" class="mb-1 w-full">
                  <div class="py-1.5 px-3 text-sm border border-gray-300 rounded cursor-pointer" @click="processClick(row)">
                    <div v-if="row.item">
                      {{ row.item }}
                    </div>
                    <div v-else class="font-bold text-red-600">
                      作業工程を入力してください。
                    </div>
                  </div>
                </div>
                <!-- 備考 -->
                <div v-if="row.value!='N/A'">
                  <div v-if="canEdit || row.detail2" class="text-xs my-auto font-bold text-light-blue-700 h-full w-10">
                    備考
                  </div>
                  <div v-if="canEdit" class="mb-1 w-full">
                    <input
                      :type="'text'"
                      placeholder="特筆することがあればご記入ください"
                      v-model="row.detail2"
                      class="border-gray-300 focus:bg-blue-50 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                      @change="temporarilyRegister('item', row)"
                    />
                  </div>
                  <div v-else class="ml-5 mb-1">
                    {{ row.detail2 }}
                  </div>
                </div>
              </div>

              <!-- 清掃中の写真の説明 -->
              <div v-if="row.sortNo > 0 && row.classification.indexOf('サービス作業') == -1 && row.value != 'N/A' && ![6, 7, 8].includes(tempId)" class="flex ml-8">
                <div class="test-sm my-auto font-bold text-green-600 w-fit">
                  清掃中：
                </div>
                <div v-if="canEdit" class="mb-1">
                  <WmsSuggestInput
                    name="cleaningText"
                    :embed="true"
                    displayField="val"
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="selectionItems"
                    class="embed border rounded border-gray-300"
                    v-model="row.cleaningText"
                    placeholder="ポリッシャー洗浄中など"
                    :clearable="true"
                    @selected="temporarilyRegister('item', row, $event, i)"
                  />
                </div>
                <div v-else>
                  {{ row.cleaningText }}
                </div>
              </div>
              <!-- C/D評価の特記事項 -->
              <div v-if="row.sortNo > 0 && tempId == 6 && (row.detail1 == 'C' || row.detail2 == 'D')" class="flex ml-8 w-full">
                <div class="test-sm my-auto font-bold text-green-600 w-52">
                  C・D評価の特記事項：
                </div>
                <div v-if="canEdit" class="mb-1 border border-gray-300 rounded w-full mr-8">
                  <input
                    class="focus:border focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 px-2 w-full"
                    v-model="row.cleaningText"
                    @change="temporarilyRegister('item', row)"
                  />
                </div>
                <div v-else>
                  {{ row.cleaningText }}
                </div>
              </div>
              <!-- 東急コミュニティーの場合 -->
              <div v-if="row.sortNo > 0 && tempId == 7 && row.value != 'N/A'" class="ml-8">
                <div v-if="canEdit">
                  <input
                    placeholder="使用洗剤等..."
                    type="text"
                    v-model="row.cleaningText"
                    class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                    @change="temporarilyRegister('item', row)"
                  />
                </div>
                <div v-else class="text-blue-700">
                  {{ row.cleaningText }}
                </div>
              </div>
              <!-- 大和ライフネクストの場合 -->
              <div v-if="tempId == 8 && !nonCamera.includes(row.classification) && row.value != 'N/A'" class="ml-8">
                <div v-for="(t, tIdx) in row.cleaningTextArr" :key="'t' + tIdx" class="mt-1">
                  <div v-if="canEdit || t" class="text-xs my-auto font-bold text-green-700 h-full w-32">
                    <p v-if="row.cleaningTextArr&&row.cleaningTextArr.length>1">
                      {{ tIdx + 1 }}枚目の写真説明
                    </p>
                    <p v-else>
                      写真説明
                    </p>
                  </div>
                  <div v-if="canEdit" class="mb-1 w-full">
                    <input
                      :type="'text'"
                      placeholder="特筆することがあればご記入ください"
                      v-model="row.cleaningTextArr[tIdx]"
                      class="border-gray-300 focus:bg-green-50 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                      @change="temporarilyRegister('item', row)"
                    />
                  </div>
                  <div v-else class="ml-5">
                    {{ t }}
                  </div>
                </div>
              </div>
            </div>

            <!-- 三菱地所の安全対策 -->
            <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.classification == 'safety' && !row.monthlyDisabled" :class="row.value == 'N/A'?'mb-9':''">
              <div class="font-bold text-blue-700">～安全対策～</div>
              <div class="">
                <div>
                  <div class="flex">
                    <div class="flex w-full">
                      <!-- 項目 -->
                      <div class="mb-1">
                        <input
                          type="text"
                          v-model="row.item"
                          :disabled="!canEdit||anzen.includes(row.item)"
                          class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 font-bold"
                          @change="temporarilyRegister('item', row)"
                        />
                      </div>
                      
                      <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
                        <div class="ml-8 flex h-9">
                          <!-- 該当なし -->
                          <PrimaryButton v-if="canEdit && !nonCamera.includes(row.classification)" text="該当なし" size="normal" class="w-97px pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                          <PrimaryButton v-if="!canEdit && row.value=='N/A'" text="該当なし" size="normal" class="w-97px py-1 pl-0 pr-0" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                          <!-- カメラ -->
                          <div v-if="!row.disabled && row.value!='N/A' && canEdit">
                            <div class="file-btn rounded w-70px bg-darkgreen h-full">
                              <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                                <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                              </PrimaryButton>
                              <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" @change="imageToBase64(i, $event)" multiple />
                            </div>
                          </div>
                          <!-- 非表示ボタン -->
                          <!-- <PrimaryButton v-if="canEdit && row.value=='N/A' && row.sortNo > 0" text="非表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p w-70px" @click="disabledNA(i)" /> -->
                          <!-- 完了ボタン -->
                          <PrimaryButton v-if="canEdit && row.complete && compareDate(row.updateDate, list.today)" :text="formatDate(row.updateDate)" size="bg-cbl" class="text-white rounded font-bold p-6p ml-3 w-59px cursor-not-allowed relative z-50" />
                          <PrimaryButton v-else-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="comp-btn text-blue-800 border border-blue-800 rounded font-bold p-6p ml-3 w-59px" @click="complete(i)" :disabled="processing" >
                            <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                          </PrimaryButton>
                          <!-- 削除 -->
                          <div @click="deleteSafety(i)" v-if="row.deleteFlg" class="ml-3 mt-auto ">
                            <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7" />
                          </div>
                        </div>
                      </div>
                      <div v-if="!canEdit" class="text-sm text-gray-600 border border-gray-200 bg-gray-50 p-1 h-fit ml-auto min-w-fit">{{ formatDate(row.updateDate) }} 完了</div>
                    </div>
                  </div>
                  <!-- 安全対策（ドロップダウン） -->
                  <div class="text-xs text-gray-400">安全対策を選択してください</div>
                  <div class="flex">
                    <select
                      name="safetyDetail1"
                      v-model="row.safetyDetail1"
                      class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs"
                      @change="temporarilyRegister('item', row)"
                    >
                      <option
                        v-for="list in safetyList(row.item)" :key='list.id'
                        :value="list.name"
                        class="bg-white">
                        {{ list.name }}
                      </option>
                    </select>

                    <select
                      name="safetyDetail2"
                      v-model="row.safetyDetail2"
                      class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs"
                      @change="temporarilyRegister('item', row)"
                    >
                      <option
                        v-for="list in safetyList(row.item)" :key='list.id'
                        :value="list.name"
                        class="bg-white">
                        {{ list.name }}
                      </option>
                    </select>

                    <select
                      name="safetyDetail3"
                      v-model="row.safetyDetail3"
                      class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs"
                      @change="temporarilyRegister('item', row)"
                    >
                      <option
                        v-for="list in safetyList(row.item)" :key='list.id'
                        :value="list.name"
                        class="bg-white">
                        {{ list.name }}
                      </option>
                    </select>
                  </div>

                  <!-- それ以外の安全対策 -->
                  <div>
                    <div class="text-xs text-gray-400">他の安全対策</div>
                    <input
                      type="text"
                      v-model="row.detail2"
                      class="text-center border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-2 px-2 mb-1 w-full text-xs"
                      @change="temporarilyRegister('item', row)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- 非表示の該当なしを表示した場合 -->
            <div v-if="row.value=='N/A' && row.disabled && toggleNA && !row.monthlyDisabled">
              <!-- No -->
              <div class="flex">
                <div class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
              </div>
              <!-- 項目 -->
              <div class="ml-8 mb-1">
                {{ row.item }}
              </div>
            </div>
            
            <!-- 非表示の該当なしを表示した場合 -->
            <div v-if="row.value=='N/A' && row.disabled && toggleNA && !row.monthlyDisabled">
              <PrimaryButton text="表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p mb-5 w-1/3 h-10 ml-8" @click="enableNA(i)" />
            </div>

            <div v-if="(!row.complete || !completeBtn) && !row.disabled && row.value != 'N/A' && !toggleNA && !row.monthlyDisabled">
              <!-- 添付画像 -->
              <div class="ml-8 mt-3 flex flex-wrap">
                <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3 w-30per mb-5">
                  <div class="">
                    <a :href="fullImage" :data-lightbox="i * 100 + j" @click="clickImage(checkList.items[i].sortNo, i, j)">
                      <img :src="p">
                    </a>
                    <div class="flex">
                      <!-- 左へボタン -->
                      <Icon
                        v-if="j!=0&&checkList.items[i].photo.length!=1&&canEdit"
                        iconName="ArrowNarrowLeft" 
                        :clickable="true" 
                        class="mt-0.5 cursor-pointer text-gray-600 border rounded w-fit py-1 px-3 border-gray-400 bg-gray-50"
                        @click="toLeft(i, j)"
                      />

                      <!-- 文言 -->
                      <div v-if="row.sortNo>0&&tempId!=6&&tempId!=8" class="text-center w-full text-sm text-gray-400">{{ photoText(j) }}</div>
                      <div v-else-if="row.sortNo>0&&tempId==6&&row.classification!='safety'" class="text-center w-full text-sm text-gray-400">{{ j==0?'清掃前':j==1?'清掃後':(row.detail1=='C'||row.detail2=='D')&&j>=2?'C・D評価':'' }}</div>
                      
                      <!-- 右へボタン -->
                      <Icon
                        v-if="j!=checkList.items[i].photo.length-1&&checkList.items[i].photo.length!=1&&canEdit"
                        iconName="ArrowNarrowRight" 
                        :clickable="true" 
                        class="mt-0.5 cursor-pointer text-gray-600 border rounded w-fit py-1 px-3 border-gray-400 bg-gray-50"
                        @click="toRight(i, j)"
                      />
                    </div>
                    
                  </div>
                  <!-- 削除 -->
                  <div @click="deletePhoto(i, j)" v-if="p && p != '' && canEdit" class="ml-0.5 mb-auto mt-1">
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7" />
                  </div>
                </div>
              </div>
              <div class="text-transparent">{{reload}}</div>
            </div>
          </div>
          <div v-if="!toggleNA" class="mt-3 w-11/12 m-auto mb-6">
            <!-- 使用機材（三菱地所のみ） -->
            <div v-if="tempId==6&&checkList.tools" class="mb-5">
              <div class="text-gray-700 text-sm">使用機材・用具</div>
              <div class="bg-blue-50 border rounded border-blue-700 p-2">
                <div v-for="tool in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]" :key="'tool' + tool">
                  <div>
                    <input
                      type="text"
                      v-model="checkList.tools['tool' + tool]"
                      :disabled="!canEdit"
                      class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                      @change="temporarilyRegister('tool', null)"
                    />
                  </div>
                </div>
                <div class="text-gray-700 text-sm mt-2">その他</div>
                <textarea 
                  name="checkListTool" 
                  :multiline="true"
                  :rows="toolCount"
                  class="text-gray-700 w-full border-gray-300 rounded"
                  v-model="checkList.tools['other']"
                  :disabled="!canEdit"
                  @change="temporarilyRegister('tool', null)"
                />
              </div>
            </div>

            <!-- 備考 -->
            <div v-if="tempId!=6&&tempId!=2&&tempId!=7&&tempId!=8" class="text-gray-700 text-sm">備考</div>
            <div v-else-if="tempId==2" class="text-gray-700 text-sm">指摘内容（緊急・問題）※何か記入された場合は「緊急・問題」の報告書が出力されます</div>
            <div v-else-if="tempId==6" class="text-gray-700 text-sm">作業完了に伴う所見</div>
            <div v-else-if="tempId==7" class="text-gray-700 text-sm">特記事項</div>
            <div v-else-if="tempId==8" class="text-gray-700 text-sm">報告事項（各写真ページ下に表示）</div>
            <textarea 
              name="checkListMemo" 
              :multiline="true"
              :rows="memoCount"
              class="text-gray-700 w-full border-gray-300 rounded"
              v-model="checkList.memo"
              :disabled="!canEdit"
              @change="temporarilyRegister('memo', checkList.memo)"
            />
          </div>
          <!-- 項目設定ダイアログ -->
          <ItemHistorySettingModal 
            v-model="openItemHistorySettingModal"
            :target="itemHistorySettingData"
            :tempId="tempId"
            :open="openItemHistorySettingModal"
            @modalClose="closeItemHistorySettingModal"
          />
          <!-- 作業工程モーダル（大和ライフネクスト） -->
          <ProcessModal
            v-model="openProcessModal"
            :classification="processData.classification"
            :item="processData.item"
            :selectCallback="processData.selectCallback"
            :open="openProcessModal"
          />
        </div>
      </template>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import Toggle from '@components/Toggle.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import ItemHistorySettingModal from './ItemHistorySettingModal.vue'
import SelectToStringComponent from './SelectToStringComponent.vue'
import ProcessModal from './ProcessModal.vue'
import LoadingIcon from '@assets/loading-circle.svg' 
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import moment from 'moment'
import * as logManager from '@managers/logManager'

const ITEMS = {
    // 区分
    classification: '',
    // 内容
    item: '',
    // 清掃中写真の説明
    cleaningText: '',
    // 該当なし履歴
    valueNA: false,
    // 該当なし非表示
    disabled: false,
    // 写真
    photo: [],
    // 完了ボタン
    complete: false,
    // 毎月設定
    monthly: '毎月'
  }

// 使用機材（三菱地所のみ）
const TOOLS = {
  tool1: '高圧洗浄機',
  tool2: 'ポリッシャー',
  tool3: 'バキューム',
  tool4: null,
  tool5: null,
  tool6: null,
  tool7: null,
  tool8: null,
  tool9: null,
  tool10: null,
  other: '扇風機・掃除機・\r\nフロアースクイージ・モップ・\r\nバケツ・ホース・箒・ちり取り・延長コード・\r\nハンドパッド・漏電ブレーカー・ラバーマット'
}

// 安全対策（三菱地所のみ）
const ANZEN = ['資材置き場', 'エレベーター', 'ホース、コード等延長']

export default {
  components: {
    Icon,
    TrashIcon,
    PrimaryButton,
    Toggle,
    WmsSuggestInput,
    ItemHistorySettingModal,
    ProcessModal,
    SelectToStringComponent,
    LoadingIcon
  },

  props: {
    // 区分名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃）
    type: {
      type: Number,
      default: null
    },
    // 親画面（予定一覧）
    routerName: {
      type: String,
      default: null
    },
    // 予約詳細から遷移した際の予定日
    scheduledDate: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      // 表示対象予定詳細情報
      target: {},
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // 位置情報
      location: '',
      // チェック項目
      checkList: {
        items: [
          utils.clone(ITEMS)
        ],
        memo: ''
      },
      // 並べ替え
      // sortable: false,
      // 編集できるか
      canEdit: true,
      // 備考の大きさ
      memoCount: 5,
      // 処理中
      processing: false,
      processingText: 'Loading...',
      // 写真更新
      reload: 0,
      // 完了ボタンが押されたか
      completeBtn: false,
      // 非表示の該当なしトグル
      toggleNA: false,
      // 添付画像 オリジナルデータ
      fullImage: '',
      // 全添付画像 オリジナルデータ
      fullImages: null,
      // 完了を非表示ボタン活性
      undoBtn: false,
      // 清掃中写真の説明候補
      selectionItems: [
        { val: 'ポリッシャー洗浄中' },
        { val: 'デッキブラシ洗浄中' },
        { val: '高圧洗浄中' },
        { val: '作業中' },
      ],
      // カメラボタン非表示項目
      nonCamera: [
        '定期清掃', '特別清掃', '人数', '責任者以外の人数', 'スタッフ名', '撮影者', '天候', '建物番号', '休憩時間', '鍵の返却', '門扉などの施錠'
      ],
      // テンプレートID
      tempId: null,
      // クレーム対応(定期清掃にクレーム対応はない)
      claim: false,
      // 三菱地所の使用機材その他の行数
      toolCount: 4,
      // 三菱地所の安全対策定値
      anzen: utils.clone(ANZEN),
      // 項目設定（履歴設定）モーダルopen
      openItemHistorySettingModal: false,
      // 項目設定（履歴設定）モーダルに渡すデータ
      itemHistorySettingData: {},
      // 作業工程モーダルopen（大和ライフネクストのみ）
      openProcessModal: false,
      // 作業工程モーダルに渡すデータ（大和ライフネクストのみ）
      processData: {},
      // 作業工程の中にその他があるか（大和ライフネクストのみ）
      processOtherFlg: false,
      // 作業工程のその他の内容（大和ライフネクストのみ）
      processOther: '',
      // プレビューか否か
      preview: false
    }
  },

  async created() {
    this.claim = false
    // booleanでない場合
    if (typeof this.$route.query.claim != 'boolean') {
      this.previous()
    }
    
    await this.getTarget()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.preview = false
      if (this.$route.query.scheduleId) {
        if (!this.scheduledDate) {
          // プレビューの場合
          if (this.$route.query.preview == 'true') {
            this.preview = true
            this.getPreviewData(this.$route.query)
          } else {
            this.previous()
          }
          return
        }
        this.processing = true
        this.processingText = 'Loading...'

        // スケジュールIDに紐づくデータを取得
        let schedule = await backend.searchData('patrolSchedule/getById', { id: this.$route.query.scheduleId, type: this.type, scheduledDate: moment(this.scheduledDate).format('YYYY-MM-DD') })

        if (schedule.data.data && schedule.data.data.length) {
          this.target = schedule.data.data[0]
        } else {
          this.processing = false
          await dialogs.showErrorDialog('エラー', '対象データがありません。')
          return
        }
        this.processing = false

        // テンプレートIDを取得
        await this.getTempId(this.target)

        this.list = this.target

        // 定期清掃済み
        if (this.target.completionDate && this.target.completionDate != '') {
          this.canEdit = false
          this.memoCount = 1

          // データ取得
          this.processing = true
          this.processingText = 'Loading...'
          const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate: moment(this.target.scheduledDate).format('YYYY-MM-DD'), type: this.type, claim: this.claim, tempId: this.tempId, completionDate: this.target.completionDate })
          this.getCompleteData(compR)
          this.processing = false

        // 定期清掃まだ
        } else {
          this.canEdit = true
          
          this.list.today = moment().format('YYYY/MM/DD')
          // 途中保存があり、途中から行う場合
          if (await this.temporary()) {
            return
          }
          
          await this.initCheck()
        }
        this.processing = false
      }
    },

    /**
     * 保存している結果をセット
     */
    setData(r) {
      if (r.result.length) {
        if (this.canEdit) {
          let result = r.result[0]
          this.start = result.startTime
          if (result.endTime) {
            this.end = result.endTime
          } else {
            this.end = ''
          }
          this.location = result.location
        } else {
          this.list.today = []
          this.start = []
          this.end = []
          this.location = ''
          const loc = []
          for (let i = 0; i < r.result.length; i++) {
            const res = r.result[i]
            this.list.today.push(moment(res.completionDate).format('YYYY/MM/DD'))
            this.start.push(res.startTime)
            this.end.push(res.endTime)
            loc.push(res.location)
          }
          if (this.list.today.length > 1) {
            this.location = '（1日目から順に）' + loc.join(' , ')
          } else {
            this.location = loc[0]
          }
        }
      }
      if (r.item) {
        for (let j = 0; j < r.item.length; j++) {
          const ri = r.item[j]
          if (ri.value === 'HIDE') {
            ri.disabled = true
          }

          // 三菱地所の安全対策の場合、データを整形する
          if (this.tempId == 6 && ri.classification == 'safety' && ri.detail1) {
            let safety = ri.detail1.split('@@@')
            if (safety && safety.length) {
              if (safety[0]) {
                ri.safetyDetail1 = safety[0]
              }
              if (safety[1]) {
                ri.safetyDetail2 = safety[1]
              }
              if (safety[2]) {
                ri.safetyDetail3 = safety[2]
              }
            }
          }
        }
        this.checkList.items = r.item

        // オリックス・ファシリティの場合、項目の開始時間を反映する
        if (this.tempId === 3) {
          for (let k = 0; k < this.checkList.items.length; k++) {
            const ori = this.checkList.items[k]
            // 開始・終了ボタンを押した形跡アリ
            if (ori.detail2) {
              // 10文字以上なら終了も押している
              if (ori.detail2.length >= 10) {
                ori.end = true
              } else {
                ori.start = true
              }
            }
          }

        // 三菱地所の場合、ソート番号がマイナス＞安全対策＞ソート番号がプラス、に並び替える
        } else if (this.tempId == 6) {
          // ソート番号がマイナス
          let info = []
          // ソート番号がプラス
          let items = []
          // 安全対策
          let safetys = []
          for (let k = 0; k < r.item.length; k++) {
            const item = r.item[k]
            if (item.classification == 'safety') {
              safetys.push(item)
            } else if (item.sortNo < 0) {
              info.push(item)
            } else {
              items.push(item)
            }
          }
          // 安全対策がある場合、並び替え
          if (safetys.length) {
            if (info.length) {
              // ソート番号がマイナス＞安全対策
              safetys = info.concat(safetys)
            }
            // 安全対策＞ソート番号がプラス
            this.checkList.items = safetys.concat(items)
          }
        }
      }
      if (r.memo && r.memo.length && r.memo[0].memo) {
        this.checkList.memo = r.memo[0].memo
        let c = 1
        if (this.checkList.memo != '') {
          let mIdx = 0
          for (let k = 0; k < this.checkList.memo.length; k++) {
            let memo = this.checkList.memo[k]
            if (memo == '\n') {
              mIdx = 0
            } else {
              mIdx++
              if (mIdx == 56) {
                const a = this.checkList.memo.slice(0, k)
                const b = this.checkList.memo.slice(k)
                this.checkList.memo = a + '\n' + b
                mIdx = 0
              }
            }
          }
          c = ( this.checkList.memo.match( /\n/g ) || [] ).length
        }
        this.memoCount = 5
        if (c && c + 2 > 5) {
          this.memoCount = c + 2
        }
      }
      // 使用機材（三菱地所・大和ライフネクストの場合）
      if (r.tool && r.tool[0]) {
        this.checkList.tools = r.tool[0]
      }
    },

    /**
     * 途中保存を取得
     */
    async temporary() {
      const temporaryS =  await backend.searchData('patrolTemporarySaving/isSaving', { patrolScheduleId: this.target.id })
      if (temporaryS && temporaryS.data && temporaryS.data.data.length) {
        const dialogR = await dialogs.showConfirmDialog('途中保存データあり', '途中保存しているデータがあります。\r\n途中から再開する場合➡「途中から」\r\n最初からやり直す➡「最初から」\r\nを押してください。', '途中から', '最初から')
        
        // 途中から再開する場合
        if (dialogR == 'YES') {
          this.processing = true
          this.processingText = 'Loading...'
          let temporaryR = []

          let param = { clientCode: this.target.clientCode, siteCode: this.target.siteCode, patrolScheduleId: this.target.id, type: this.type, claim: this.claim, ridgeCode: this.target.ridgeCode, tempId: this.tempId }

          temporaryR = await backend.searchData('patrolTemporarySaving/getByPatrolScheduleId', param)
          
          // 完了を表示ボタンを出す
          if (temporaryR.data.data.item && temporaryR.data.data.item.length) {
            for (let i = 0; i < temporaryR.data.data.item.length; i++) {
              const it = temporaryR.data.data.item[i]
              if (it.complete) {
                this.completeBtn = true
                break
              }
            }
          }
          
          this.setData(temporaryR.data.data)
          this.checkList.items = this.setWorkInfo(this.checkList.items)
          this.setTools()
          this.setCleaningTextArr(false)
          this.processing = false

          // 添付画像 取得
          this.getfullImages()

          return true
        
        // 最初からやり直す場合
        } else {
          // データを削除
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id, sub: this.$route.query.sub })
          return false
        }
      }
      return false
    },

    /**
     * 点検前の点検表セット
     */
    async initCheck() {
      let cheR
      this.processing = true
      this.processingText = 'Loading...'
      
      // 初日の場合
      if (!this.$route.query.sub || this.$route.query.sub == '0') {
        // 物件コードが変更になっていないか確認
        let isChangeCode = await backend.searchData('checkListHistory/isChangeCode', { siteCode: this.target.siteCode, type: this.type, claim: this.claim, siteAddress: this.target.siteAddress, siteName: this.target.siteName })
        if (isChangeCode.data && isChangeCode.data.data && isChangeCode.data.data.result) {
          this.processing = false
          const inf = isChangeCode.data.data.info
          const msg = `
            前回完了日：${inf.completionDate}
            物件名：${inf.siteName}
            住所：${inf.siteAddress}
          `
          const chagneCode = await dialogs.showConfirmDialog('物件コードが変わった可能性があります。', '過去の物件コードと新しい物件コードを一致させますか？\r\n新しい物件コードと一致させると過去のデータが引き継がれます。\r\n\r\n【同じと思われる過去のデータ】' + msg)
          this.processing = true
          // 過去の物件コードを変更する
          if (chagneCode == 'YES') {
            const updateRes = await backend.postData('checkListHistory/changingCode', { newSiteCode: this.target.siteCode, oldSiteCode: inf.siteCode, type: this.type, claim: this.claim, id: this.target.id })
            if (updateRes && updateRes.status == 200) {
              //ログ出力
              logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '物件コード変更', this.typeName + '（物件名：' + inf.siteName + '、物件コード：' + inf.siteCode + '、完了日：'+ inf.completionDate + '以前全て、予定ID：'+ inf.id +'以前全て）を（物件コード：' + this.target.siteCode + '）に変更しました。') 
            } else {
              alert('エラーが発生しました。')
              this.processing = false
            }
          }
        }

        cheR = await backend.searchData('checkListByClient/getByClientCode', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, type: this.type, ridgeCode: this.target.ridgeCode, tempId: this.tempId })
        this.checkList.items = cheR.data.data
        if (cheR.data.tool && cheR.data.tool[0]) {
          this.checkList.tools = cheR.data.tool[0]
        }
      
      // 連日の場合
      } else {
        let param = { clientCode: this.target.clientCode, siteCode: this.target.siteCode, patrolScheduleId: this.target.id, type: this.type, claim: this.claim, ridgeCode: this.target.ridgeCode, tempId: this.tempId }
        cheR = await backend.searchData('patrolTemporarySaving/getByPatrolScheduleId', param)
        // 完了を表示ボタンを出す
        this.completeBtn = true
        this.setData(cheR.data.data)
      }
      this.checkList.items = this.setWorkInfo(this.checkList.items)
      this.setTools()
      this.setCleaningTextArr(false)
      this.processing = false
    },

    /**
     * 作業日毎の必須項目
     * @param items 項目リスト
     */
    setWorkInfo(items) {
      let rows = []
      // 連日作業の何番目の日か（0は初日、2日目～subとなる）
      let sub = Number(this.$route.query.sub)

      let classification = ['定期清掃', '人数', 'スタッフ名', '撮影者', '天候', '集合写真', '建物全体', '館銘板']
      let item = ['', '', '', '', '', '集合写真を1枚添付してください。', '建物全体の写真を1枚添付してください。', '館銘板の写真を1枚添付してください。']

      // 「グリーンキャピタル第2上野毛」物件の場合（大京アステージだが、報告書はtempIdが3）
      if (this.tempId == 3 && utils.hankaku(this.list.siteName) == utils.hankaku('グリーンキャピタル第2上野毛')) {
      classification = ['集合写真', '建物全体', '館銘板']
      item = ['集合写真を1枚添付してください。', '建物全体の写真を1枚添付してください。', '館銘板の写真を1枚添付してください。']

      // 大和ライフネクスト
      } else if (this.tempId == 8) {
        classification = ['人数', '休憩時間', '鍵の返却', '門扉などの施錠', '安全対策', '高所ガラスの安全対策']
        item = ['', '', '', '', '安全対策の写真を添付してください。', '高所ガラスの安全対策の写真を添付してください。']
      }

      // 三井不動産、三菱地所、東急コミュニティーの場合
      if (this.tempId == 4 || this.tempId == 6 || this.tempId == 7) {
        classification[1] = '責任者以外の人数'
        // 初日の三菱地所
        if (sub == 0 && this.tempId == 6) {
          classification.splice(1, 0, '建物番号')
          item.splice(1, 0, '')
        }
      }

      // 2日目以降の場合、もしくは三井不動産、もしくは東急コミュニティー
      if (sub >= 2 || this.tempId == 4 || this.tempId == 7) {
        // 大和ライフネクストの場合、項目が違うので削除しない
        if (this.tempId != 8) {
          // 定期清掃は省く
          classification.splice(0, 1)
          item.splice(0, 1)
        }
      }

      // 大京アステージは、掲示用紙を追加
      if (this.tempId == 2) {
        classification.push('掲示用紙')
        item.push('掲示用紙の写真を1枚添付してください。')
      }

      for (let i = 0; i < classification.length; i++) {
        let row = utils.clone(ITEMS)
        // 三井不動産
        if (this.tempId == 4) {
          if (classification[i] == '建物全体') {
            classification[i] = 'ヒンジ養生'
            item[i] = item[i].replace('建物全体', 'ドアヒンジ養生中')
          }
        }
        row.classification = classification[i]
        row.item = item[i]
        row.value = sub

        // 表示順をマイナスにする
        let num = sub * 10
        // 三井不動産、東急コミュニティー、大和ライフネクスト、「グリーンキャピタル第2上野毛」物件は「定期清掃or特別清掃」がないため999から開始
        if (this.tempId == 4 || this.tempId == 7 || this.tempId == 8 || (this.tempId == 3 && utils.hankaku(this.list.siteName) == utils.hankaku('グリーンキャピタル第2上野毛'))) {
          num = 999 - num
        } else {
          num = 1000 - num
        }
        row.sortNo = i - num

        let flag = false
        for (let j = 0; j < items.length; j++) {
          const it = items[j]
          if (it.sortNo < 0) {
            // 同じデータが既にある場合は追加しない
            if (it.sortNo == row.sortNo) {
              flag = true
              break
            }
          } else {
            break
          }
        }
        if (!flag) {
          rows.push(row)
        }
      }

      // 三井不動産の場合、先頭に2つ追加
      if (this.tempId == 4) {
        let r1 = utils.clone(ITEMS)
        let r2 = utils.clone(ITEMS)
        r1.classification = '管理室、共用部扉等の施錠確認をしたか'
        r2.classification = '共用部の鍵を返却したか'
        r1.sortNo = -2001
        r2.sortNo = -2000

        let flag1 = false
        let flag2 = false
        for (let k = 0; k < items.length; k++) {
          const i = items[k]
          if (i.sortNo === -2001) {
            flag1 = true
          } else if (i.sortNo === -2000) {
            flag2 = true
          }
        }
        if (!flag1) {
          rows.splice(0, 0, r1)
        }
        if (!flag2) {
          rows.splice(0, 0, r2)
        }

      // 三菱地所
      } else if (this.tempId == 6) {
        const sf = this.getSafetyItems(items.length)
        const safeties = []

        for (let l = 0; l < sf.length; l++) {
          const s = sf[l]
          let sfFlg = false
          for (let m = 0; m < items.length; m++) {
            const it = items[m]
            if (it.classification == 'safety' && s.item == it.item) {
              sfFlg = true
              break
            }
          }
          if (!sfFlg) {
            safeties.push(s)
          }
        }
        if (safeties.length) {
          items = safeties.concat(items)
        }
      }
      let res = rows.concat(items)
      // 並べ替え
      res.sort(function (a, b) {
        return Number(a.sortNo) - Number(b.sortNo)
      })

      // 三菱地所の場合は、safetyをソートNoがマイナスとプラスの間に入れる
      if (this.tempId == 6) {
        let anzen = []
        let kihon = []
        let other = []
        for (let idx = 0; idx < res.length; idx++) {
          const re = res[idx]
          if (re.classification == 'safety') {
            anzen.push(re)
          } else {
            if (re.sortNo < 0) {
              kihon.push(re)
            } else {
              other.push(re)
            }
          }
        }
        res = kihon.concat(anzen).concat(other)
      }
      return res
    },

    /**
     * 三菱地所の安全対策
     */
    getSafetyItems(num) {
      let items = [
        { sortNo: num + 1, classification: 'safety', item: ANZEN[0], safetyDetail1: '―', safetyDetail2: '―', safetyDetail3: '―' },
        { sortNo: num + 2, classification: 'safety', item: ANZEN[1], safetyDetail1: '―', safetyDetail2: '―', safetyDetail3: '―' },
        { sortNo: num + 3, classification: 'safety', item: ANZEN[2], safetyDetail1: '―', safetyDetail2: '―', safetyDetail3: '―' }
      ]
      return items
    },

    /**
     * 初期化
     */
    initData() {
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          utils.clone(ITEMS)
        ],
        memo: ''
      }
      // 並べ替え
      // this.sortable = false
      // 編集できるか
      this.canEdit = true
      // 備考の大きさ
      this.memoCount = 5
      // 処理中
      this.processing = false
      // 完了ボタン
      this.completeBtn = false
      // 非表示の該当なしトグル
      this.toggleNA = false
      // 保存したデータ
      // this.registeredItem = []
      // 添付画像 オリジナルデータ
      this.fullImage = ''
      // 全添付画像 オリジナルデータ
      this.fullImages = null
      // 完了を非表示ボタン活性
      this.undoBtn = false
    },

    /**
     * 開始ボタン
     */
    async startBtn() {
      this.list.start = moment().format('HH:mm')
      this.start = moment().format('HH:mm')

      // 現在地取得
      this.location = await utils.patrolLocation()

      // 一時保存
      this.temporarilyRegister('start', null)
    },

    /**
     * 完了したデータを削除
     */
    async deleteCompData() {
      // 1度でも完了ボタンを押していたら確認ダイアログ出す
      if (this.completeBtn || this.undoBtn) {
        const canscelR = await dialogs.showConfirmDialog('キャンセル', '完了しているデータも元に戻ります。よろしいでしょうか？')
        if (canscelR != 'YES') {
          return false

        // 途中保存データを削除
        } else {
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id, sub: this.$route.query.sub })
          this.initData()
          this.list = this.target
          this.list.today = moment().format('YYYY/MM/DD')
          await this.initCheck()
          return false
        }
      }
      // まだ1度も完了した項目がなければ、値のみ空にする
      return true
    },

    /**
     * キャンセルボタン
     */
    async cancelBtn() {
      let cancelR = await this.deleteCompData()
      if (!cancelR) {
        return
      }
      
      this.start = ''
      this.completeBtn = false
      this.checkList.items.forEach(i =>{
        if (!i.valueNA) {
          i.disabled = false
        }
        i.complete = false
        i.photo = []
      })
    },

    /**
     * 終了ボタン
     */
    async endBtn() {
      // 連日作業が後日予定されているか確認
      const subRes = await backend.searchData('patrolSubDate/restDay', { patrolScheduleId: this.target.id, type: this.type })
      let next = null
      let sub = Number(this.$route.query.sub)
      if (subRes.data.data && subRes.data.data.length) {
        // 後日も作業がある場合
        if (sub && subRes.data.data.length >= 2) {
          next = subRes.data.data[1].scheduledDate
        } else if (!sub && subRes.data.data.length) {
          next = subRes.data.data[0].scheduledDate
        }
        }
      // 変更後に再保存していないもの
      let dirty = []
      // 未完了のもの
      let incomp = []
      // すべての値が選択されているか確認
      for (let i = 0; i < this.checkList.items.length; i++) {
        const ci = this.checkList.items[i]
        // 奇数月・偶数月に該当しないもの以外をチェック
        if (!ci.monthlyDisabled) {

          // 非表示は該当なしにする
          if (ci.disabled) {
            ci.value = 'N/A'
          }
          // 基本情報完了済みか確認
          if (ci.sortNo > -2000 && ci.sortNo < 0 && !ci.complete && !ci.disabled) {
            await dialogs.showErrorDialog('終了できません', `【${ci.classification}】の完了ボタンを押してください。`)
            return
          }

          // 後日作業がある場合
          if (next) {
            if (!ci.disabled && !ci.complete && ci.classification.indexOf('サービス作業') == -1 && ci.value != 'N/A') {
              if (ci.classification == 'safety') {
                incomp.push('安全対策(' + ci.item + ')')
              } else {
                incomp.push(ci.classification)
              }
            }
            
          // 後日作業がない場合
          } else {
            if (!ci.disabled && !ci.complete && ci.classification.indexOf('サービス作業') == -1) {
              await dialogs.showErrorDialog('終了できません', `【${ci.classification}】の完了ボタンを押してください。`)
              return
            }
            // 大和ライフネクストで、その他作業があり、その他作業の内容を入力していない場合
            if (this.tempId == 8 && this.processOtherFlg && !this.processOther) {
              let other = dialogs.showConfirmDialog('作業工程「その他」の内容未入力', '作業工程に「その他」が含まれていますが「その他」の内容が未入力です。よろしいでしょうか？')
              if (other != 'YES') {
                return
              }
            }
          }
          if (ci.changeFlag) {
            let resultMsg = await dialogs.showConfirmDialog('完了している項目を変更しましたか？', `${ci.classification}】を変更して「完了」していませんが変更したものに更新しますか？\r\n更新する場合は「はい」を押してください。`)
            if (resultMsg == 'YES') {
              this.convertCleaningText(i)
              // 再度保存するデータ
              dirty.push(ci)
            }
          }
          if (ci.classification.indexOf('サービス作業') != -1) {
            dirty.push(ci)
          }
        }
      }

      if (incomp.length) {
        let incompRes = await dialogs.showConfirmDialog(`次回は${moment(next).format('M月D日')}`, `${incomp.join(',')} が残っています。\r\n次回作業でよろしいでしょうか？`)
        if (incompRes != 'YES') {
          return
        }
      }
      this.end = moment().format('HH:mm')
      
      await this.allRegister(dirty)
    },

    /**
     * 保存時に必要なデータを生成
     */
    setSaveData(i) {
      let save = {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        ridgeCode: this.target.ridgeCode,
        completionDate: this.list.today,
        startTime: this.start,
        endTime: this.end,
        location: this.location,
        insertUser: this.$store.getters.user.id
      }

      // 三菱地所の場合
      if (this.tempId == 6) {
        save.tools = this.checkList.tools
      }
      // 大和ライフネクストの場合
      if (this.tempId == 8 && i != null) {
        this.convertCleaningText(i)
      }
      return save
    },

    /**
     * 終了ボタン押下時の最終保存
     */
    async allRegister(dirty) {
      this.processing = true
      this.processingText = '保存中...'
      
      try {
        let save = this.setSaveData(null)

        // 連日作業の何番目の日か（0は初日、2日目～subとなる）
        let sub = Number(this.$route.query.sub)
        // 巡回結果保存
        await backend.postData('patrolResult/save', { save, item: this.checkList.items, note: null, memo: this.checkList.memo, dirty, key: this.target.id, type: this.type, claim: this.claim, sub, tempId: this.tempId, other: this.processOther })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        // 再度、終了ボタンが押せるようにする
        this.end = ''
      }

      //ログ出力
      let name = this.list.siteName
      if (this.list.ridgeCode) {
        name = name + '（' + this.list.ridgeName + '）'
      }
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '完了', this.typeName + '（期限日：'+ moment(this.list.termDate).format('YYYY-MM-DD')  + '、担当者：'+ this.list.staff + '、物件名：'+ name +'）を完了しました。')  
      this.previous()
      this.initData()
    },
    
    /**
     * 一時保存処理
     * @param itemName 変更になった項目
     *   （開始ボタン：start、項目：item、備考：memo)
     * @param val 登録内容
     */
    temporarilyRegister(itemName, val, event, i) {
      try {
        let save = this.setSaveData(i)
        let item = null
        let memo = null
        let tool = false

        // 項目
        if (itemName == 'item') {
          item = val
          // 清掃中の場合
          if (event) {
            item = utils.clone(this.checkList.items[i])
            item.cleaningText = event.val
          }
          // 三菱地所の安全対策
          this.setSaftyDetail1(val)
        // 備考
        } else if (itemName == 'memo') {
          memo = val
        // 使用機材
        } else {
          tool = true
        }
        backend.postData('patrolResult/temporarily', { save, item, memo, tool, type: this.type, tempId: this.tempId })
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
      }
    },

    /**
     * 各項目の完了ごとの登録処理
     * @param i 対象項目インデックス
     */
    async register(i) {
      this.processing = true
      this.processingText = '保存中...'

      try {
        let save = this.setSaveData(i)

        // 完了した対象の項目を保存(値・指摘事項・写真)
        await backend.postData('patrolResult/itemAndNoteSave', { save, item: this.checkList.items[i], memo: this.checkList.memo })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        this.checkList.items[i].changeFlag = true
        this.checkList.items[i].complete = false
      }
    },

    /**
     * 写真添付Base64に変換
     * @param i index
     */
    async imageToBase64 (i, event) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      // 選択されたファイルの取得
      const file = event.target.files
      if (!this.checkList.items[i].photo) {
        this.checkList.items[i].photo = []
      }
      // Base64に変換する
      for (let j = 0; j < file.length; j++) {
        const reader = new FileReader()
        const f = file[j]
        reader.readAsDataURL(f)
        // 変換
        const compressedImage = await new Promise((resolve) => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const compressedDataUrl = this.compressImage(img)
              resolve(compressedDataUrl)
            }
          }
        })
        this.checkList.items[i].photo.push(compressedImage)
        // 大和ライフネクストの場合
        if (this.tempId == 8 && this.checkList.items[i].photo.length > this.checkList.items[i].cleaningTextArr.length) {
          this.checkList.items[i].cleaningTextArr.push('')
        }
        this.reload++
      }
    },

    /**
     * 画像圧縮
     * @param {dataURL} img 
     */
    compressImage(img) {
      // 画像のサイズを変更するためにリサイズ処理
      const maxWidth = 854 // リサイズ後の最大幅
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
      // 新しいキャンバスを作成
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // キャンバスに描画するためのコンテキストを取得
      const ctx = canvas.getContext('2d')
      // 画像をキャンバスに描画せずに直接リサイズ
      ctx.drawImage(img, 0, 0, width, height)
      // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 1.0) // 圧縮率を設定（0.7は例です）

      return compressedDataUrl
    },

    /**
     * 写真削除
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    deletePhoto(i, j) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      this.checkList.items[i].photo.splice(j, 1)
      // 大和ライフネクストの場合
      if (this.tempId == 8 && this.checkList.items[i].photo.length < this.checkList.items[i].cleaningTextArr.length) {
        this.checkList.items[i].cleaningTextArr.splice(j, 1)
      }
      this.reload++
    },

    /**
     * 該当なしボタン
     * @param value 値
     * @param idx インデックス
     */
    setPoint(value, idx) {
      if (this.checkList.items[idx].value == value) {
        this.checkList.items[idx].value = null
      } else {
        this.checkList.items[idx].value = value
      }
      this.reload++
    },

    /**
     * 非表示ボタン
     * @param i 対象項目
     */
    async disabledNA(i) {
      let txt = '次回からもこの項目は非表示にします。\r\n'
      if (this.checkList.items[i].sortNo < 0) {
        txt = ''
      }
      const dr = await dialogs.showConfirmDialog('非表示にします', txt + '設定変更する場合は、【「該当なし」を表示】ボタンを押してください。')
      if (dr != 'YES') {
        return
      }
      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].disabled = true
      this.reload++
    },

    /**
     * 該当なし表示に変更ボタン
     * @param i 対象項目
     */
    async enableNA(i) {
      this.checkList.items[i].disabled = false
      this.checkList.items[i].complete = false
    },

    /**
     * 完了ボタン
     * @param i 対象項目
     */
    async complete(i) {
      if (this.start == '') {
        await dialogs.showErrorDialog('開始ボタン', '開始ボタンを押してください。')
        return
      }

      if (!await this.validate(this.checkList.items[i])) {
        return
      }

      // 三菱地所の安全対策
      this.setSaftyDetail1(this.checkList.items[i])

      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].complete = true
      this.undo()
      this.reload++

      // 保存
      this.register(i)
    },

    /**
     * 三菱地所の安全対策を保存する形に成形
     * @param item 項目
     */
    setSaftyDetail1(item) {
      // 三菱地所の安全対策
      if (this.tempId == 6) {
        if (item.classification == 'safety') {
          item.detail1 = item.safetyDetail1 + '@@@' + item.safetyDetail2 + '@@@' + item.safetyDetail3
        }
      }
    },

    /**
     * チェック処理
     * @param row 対象データ
     */
    async validate(row) {
      // 該当なしは無条件でOK
      if (row.value == 'N/A') {
        return true
      }

      if (!row.classification) {
        await dialogs.showErrorDialog('作業箇所', '作業箇所を入力してください。')
        return false

      // 安全対策の場合（三菱地所のみ）
      } else if (row.classification == 'safety') {
        if (row.safetyDetail1 == '―' && row.safetyDetail2 == '―' && row.safetyDetail3 == '―' && !row.detail2) {
          await dialogs.showErrorDialog('安全対策の内容', '安全対策を1つ以上選択、もしくは入力してください。')
          return false
        }
      }

      // オリックス・ファシリティーズの場合
      if (this.tempId == 3 && row.sortNo > 0) {
        if (!row.detail1) {
          await dialogs.showErrorDialog('人数', '人数を入力してください。')
          return
        }
        if (!row.end && !row.complete) {
          if (!row.start) {
            await dialogs.showErrorDialog('開始ボタン', '開始ボタンを押してください。')
          } else {
            await dialogs.showErrorDialog('終了ボタン', '終了ボタンを押してください。')
          }
          return false
        }
        // 詳細を半角にする
        row.detail1 = utils.hankaku(row.detail1)
        row.detail2 = utils.hankaku(row.detail2)
      }

      if (!row.item && row.sortNo > -1000 && !row.disabled) {
        if (row.sortNo < 0 && row.classification != 'safety' && !['休憩時間', '鍵の返却', '門扉などの施錠'].includes(row.classification)) {
          await dialogs.showErrorDialog('未入力です', row.classification + 'を入力してください。')
          return false
        } else if (row.classification == 'safety') {
          await dialogs.showErrorDialog('未入力です', '安全対策場所を入力してください。')
          return false
        } else if (row.classification == '休憩時間') {
          let itemRes = await dialogs.showConfirmDialog('未入力です', '休憩時間は無しでよろしいでしょうか？')
          if (itemRes != 'YES') {
            return false
          }
        } else if (['鍵の返却', '門扉などの施錠'].includes(row.classification)) {
          let itemRes = await dialogs.showConfirmDialog('未選択です', row.classification + 'は未選択でよろしいでしょうか？')
          if (itemRes != 'YES') {
            return false
          }
        } else if (row.classification.indexOf('サービス作業') == -1 && this.tempId != 8) {
          let itemRes = await dialogs.showConfirmDialog('作業内容', '作業内容が未入力ですがよろしいでしょうか？')
          if (itemRes != 'YES') {
            return false
          }
        } else if (this.tempId == 8 && (!row.detail1 || row.detail1 == '床面清掃')) {
          let itemRes = await dialogs.showConfirmDialog('作業工程', '作業工程が未入力ですがよろしいでしょうか？')
          if (itemRes != 'YES') {
            return false
          }
        }
      }

      if (!row.cleaningText && this.tempId != 6 && this.tempId != 7 && this.tempId != 8) {
        row.cleaningText = '作業中'
        this.reload++
      } else if (this.tempId == 6 && (row.detail1 == 'C' || row.detail2 == 'D') && !row.cleaningText) {
        await dialogs.showErrorDialog('C・D評価の特記事項', 'CもしくはD評価のため、特記事項をご記入ください。')
        return false
      }

      // 大和ライフネクストの場合
      if (this.tempId == 8) {
        if (row.photo && row.photo.length) {
          // 写真の数分、コメントがあるか確認
          if (row.photo.length != row.cleaningTextArr.length) {
            const pl = row.photo.length
            const cl = row.cleaningTextArr.length
            // 写真の方が多い（おそらく、発生しない）
            if (pl > cl) {
              const dif = pl - cl
              for (let i = 0; i < dif; i++) {
                row.cleaningTextArr.push('')
              }
            // 前回の写真が複数あった場合あり得る
            } else if (pl < cl) {
              let daiwa = await dialogs.showConfirmDialog(row.classification + 'の写真枚数', '写真説明の数が写真の数より多いです。多い分は削除します。よろしいでしょうか？')
              if (daiwa != 'YES') {
                return false
              }
              row.cleaningTextArr = row.cleaningTextArr.slice(0, pl - 1)
            }
          }
        }
      }

      if (row.sortNo > 0 && !row.disabled && row.classification.indexOf('サービス作業') == -1) {
        if ((!row.photo || row.photo.length < 3) && this.tempId != 6 && this.tempId != 8 && !(this.tempId == 3 && utils.hankaku(this.list.siteName) == utils.hankaku('グリーンキャピタル第2上野毛'))) {
          const photoRes = await dialogs.showConfirmDialog('写真は基本３枚以上です', '写真の添付枚数が3枚以上ではありません。\r\nよろしいでしょうか？')
          if (photoRes != 'YES') {
            return false
          }
        } else if (this.tempId != 6 && this.tempId != 8 && !(this.tempId == 3 && utils.hankaku(this.list.siteName) == utils.hankaku('グリーンキャピタル第2上野毛')) && row.photo && row.photo.length % 3 != 0) {
          let text = ''
          switch (row.photo.length % 3) {
            case 1:
              text = '清掃前'
              break
            case 2:
              text = '清掃中'
              break
          }
          const photoRes = await dialogs.showConfirmDialog('写真が「' + text + '」で終わっています', '写真の添付が' + row.photo.length + '枚です。\r\nよろしいでしょうか？')
          if (photoRes != 'YES') {
            return false
          }
        
        } else if ((!row.photo || row.photo.length != 2) && this.tempId == 6 && row.classification != 'safety' && row.detail1 != 'C' && row.detail2 != 'D') {
          const photoRes2 = await dialogs.showConfirmDialog('写真は基本２枚です', '写真の添付枚数が2枚ではありません。\r\nよろしいでしょうか？')
          if (photoRes2 != 'YES') {
            return false
          }
        } else if ((!row.photo || row.photo.length < 3) && this.tempId == 6 && row.classification != 'safety' && (row.detail1 == 'C' || row.detail2 == 'D')) {
          const photoRes3 = await dialogs.showConfirmDialog('CもしくはD評価です', 'CもしくはD評価です。3枚目以降にその写真を添付しておりません。\r\nよろしいでしょうか？')
          if (photoRes3 != 'YES') {
            return false
          }
        } else if ((!row.photo || !row.photo.length) && this.tempId == 6 && row.classification == 'safety') {
          await dialogs.showErrorDialog('写真', row.item + 'の写真を添付してください。')
          return false
        } else if ((!row.photo || row.photo.length != 1) && this.tempId == 8) {
          let msg = '写真が添付されていません。'
          if (row.photo && row.photo.length) {
            msg = '写真が' + row.photo.length + '枚添付されています。'
          }
          const daiwaRes = await dialogs.showConfirmDialog('写真は基本1枚です', '大和ライフネクスト様は基本1枚です。\r\n' + msg + '\r\nよろしいでしょうか？')
          if (daiwaRes != 'YES') {
            return false
          }
        }
      } else if (row.item.indexOf('添付') != -1 && !row.disabled) {
        if (!row.photo || !row.photo.length) {
          await dialogs.showErrorDialog('写真', row.classification + 'の写真を添付してください。')
          return false
        }
      }

      // 三井不動産の場合
      // 作業後確認事項
      if (row.sortNo < -1000 && this.tempId == 4) {
        for (let i = 0; i < this.checkList.items.length; i++) {
          const r = this.checkList.items[i]
          if (!r.complete && r.value != 'N/A' && !r.disabled && r.sortNo >= -1000 && !r.monthlyDisabled ) {
            await dialogs.showErrorDialog('最後に完了', 'こちらの確認作業は一番最後に行ってください。')
            return false
          }
        }
      // 実施回数、清掃種類を確認
      } else if (row.sortNo > 0 && this.tempId == 4) {
        if (!row.detail1) {
          await dialogs.showErrorDialog('実施回数', '実施回数を入力してください。\r\n例：「年6回」や「スポット」など…')
          return false
        }
        if (row.detail2 != '定期' && row.detail2 != '特別') {
          await dialogs.showErrorDialog('清掃種類', '清掃種類は、「定期」か「特別」のどちらかをご記入ください。')
          return false
        }
      }

      // 大和ライフネクストの場合
      if (row.sortNo > 0 && this.tempId == 8) {
        if (!row.detail1) {
          row.detail1 = '床面清掃'
        }
        if (row.classification.indexOf('ガラス') > -1 || row.classification.indexOf('ｶﾞﾗｽ') > -1) {
          if (row.detail1.indexOf('ガラス') == -1) {
            const daiwaRes = await dialogs.showConfirmDialog('清掃名に「ガラス」が含まれています。', '清掃種別は「' + row.detail1 + '」になっております。\r\nよろしいでしょうか？')
            if (daiwaRes != 'YES') {
              return false
            }
          }
        }
      }
      return true
    },

    /**
     * 完了を表示ボタン
     */
    reDisplay() {
      this.completeBtn = false
      this.undoBtn = true
    },

    /**
     * 完了ボタン押下の状態に戻す
     */
    undo() {
      this.completeBtn = true
      this.undoBtn = false
    },

    // 添付画像 取得
    async getfullImages() {
      const res = await backend.searchData('patrolResult/getPhoto', { patrolId: this.target.id, type: this.type })
      if (res.data) this.fullImages = res.data
    },

    /**
     * 画像 クリック イベント
     * @param i 対象項目
     * @param j 画像インデックス
     */
    async clickImage(sortNo, i, j) {
      const key = this.target.id + '/' + sortNo + '/' + j
      if (this.fullImages && this.fullImages[key]) this.fullImage = this.fullImages[key]
      else this.fullImage = this.checkList.items[i].photo[j]
    },

    /**
     * 前の「予定一覧_詳細」画面に戻る
     */
    previous() {
      let route = this.routerName
      // 画面を更新などして、予定日が取得できなかった場合は前の画面に戻る
      if (!this.scheduledDate) {
        if (Number(this.$route.query.sub) == -1) {
          route = 'FixedCheckList'
        }
      }
      this.$router.push({ 
        name: route,
        params: {
          checkListDate: this.target.scheduledDate,
          userId: this.target.userId,
          name: this.target.staff
        },
        query: {
          checkList: 'true'
        }
      })
    },

    /**
     * 清掃内容ボタン
     * @param i インデックス
     * @param val 値
     */
    cleaningTitle(i, val) {
      this.checkList.items[i].classification = val
      this.reload++
    },

    /**
     * 更新日が今日よりも過去の場合、true
     * @param val1 更新日
     * @param val2 今日
     */
    compareDate(val1, val2) {
      let v1 = Number(moment(val1).format('YYYYMMDD'))
      let v2 = Number(moment(val2).format('YYYYMMDD'))
      return v1 < v2
    },

    /**
     * 日付フォーマット
     * @param val 日付
     */
    formatDate(val) {
      return moment(val).format('M/D')
    },

    /**
     * 左へ写真を移動
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    toLeft(i, j) {
      let val = this.checkList.items[i].photo[j]
      this.checkList.items[i].photo.splice(j-1, 0, val)
      this.checkList.items[i].photo.splice(j+1, 1)
      this.checkList.items[i].changeFlag = true
      this.reload++
    },

    /**
     * 右へ写真を移動
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    toRight(i, j) {
      let val = this.checkList.items[i].photo[j]
      this.checkList.items[i].photo.splice(j+2, 0, val)
      this.checkList.items[i].photo.splice(j, 1)
      this.checkList.items[i].changeFlag = true
      this.reload++
    },

    /**
     * テンプレートID取得
     * @param val データ
     */
    async getTempId(val) {
      this.tempId = utils.getTempId(val.clientName1, val.siteName)
      console.log(this.tempId)

      // テンプレートIDが取得できない場合、項目表設定テーブルから取得
      if (!this.tempId) {
        const temp = await backend.searchData('checkListByClient/getTempleteByClientCode', { clientCode: val.clientCode, type: this.type })
        if (temp.data.data && temp.data.data.length) {
          this.tempId = temp.data.data[0].templateId
        }
      }
    },

    /**
     * 詳細1，2のカラム
     * @param num 番号
     */
    placeholder(num, row) {
      let val = ''
      val = utils.patrolFixedCleanPlaceholder(num, row, this.tempId)
      return val
    },

    /**
     * 開始・終了ボタン
     */
    async itemStartEnd(row) {
      if (!this.start) {
        await dialogs.showErrorDialog('開始ボタン', '上部の開始ボタンを押してください。')
        return
      }
      if (row.end) {
        return
      }
      let time = moment().format('HH:mm')

      // 開始の場合
      if (!row.start) {
        row.start = true
        time = time + ' ~ '
        row.detail2 = time
      } else {
        row.end = true
        row.detail2 = row.detail2 + time
      }

      this.reload++
      // 一時保存
      this.temporarilyRegister('item', row)
    },

    /**
     * C・D評価ボタン
     * @param item 対象プロパティ
     * @param val 値
     */
    itemEvaluation(item, tar, val) {
      if (item[tar] == val) {
        item[tar] = ''
      } else {
        item[tar] = val
        if (val == 'C' && item.detail2) {
          item.detail2 = ''
        }
        if (val == 'D' && item.detail1) {
          item.detail1 = ''
        }
      }
    },

    /**
     * 安全対策ドロップダウンリスト
     * @param item 項目名
     */
    safetyList(item) {
      const list1 = [
          { id: 's1', name: '防護ガード（フェンス）' },
          { id: 's2', name: '安全蓋' },
          { id: 's3', name: '監視・誘導員' },
          { id: 's4', name: '―' },
        ]

      const list2 = [
          { id: 's5', name: '落下防止用具(セフティチェーン）' },
          { id: 's6', name: '作業直下階の区画' },
          { id: 's7', name: '監視・誘導員' },
          { id: 's8', name: '―' },
        ]

      const list3 =[
          { id: 's9', name: 'マットによる養生' },
          { id: 's10', name: 'テープによる固定' },
          { id: 's11', name: '注意看板' },
          { id: 's12', name: '通行路の区画' },
          { id: 's13', name: '―' },
        ]

      const list4 = [
          { id: 's14', name: '防護ガード（フェンス）' },
          { id: 's15', name: '安全蓋' },
          { id: 's16', name: '監視・誘導員' },
          { id: 's17', name: '落下防止用具(セフティチェーン）' },
          { id: 's18', name: '作業直下階の区画' },
          { id: 's19', name: 'マットによる養生' },
          { id: 's20', name: 'テープによる固定' },
          { id: 's21', name: '注意看板' },
          { id: 's22', name: '通行路の区画' },
          { id: 's23', name: '―' }
      ]

      if (item == '資材置き場') {
        return list1
      } else if (item == 'エレベーター') {
        return list2
      } else if (item == 'ホース、コード等延長') {
        return list3
      } else {
        return list4
      }
    },

    /**
     * 安全対策追加ボタン
     */
    addSafety() {
      let num = 0
      let idx = 0
      for (let i = 0; i < this.checkList.items.length; i++) {
        const item = this.checkList.items[i]
        if (item.sortNo > 0 && num < item.sortNo) {
          num = item.sortNo
          idx = i + 1
        }
      }
      this.checkList.items.splice(idx, 0, { sortNo: num + 1, classification: 'safety', item: '', safetyDetail1: '―', safetyDetail2: '―', safetyDetail3: '―', deleteFlg: true })
    },

    /**
     * 安全対策削除
     */
    deleteSafety(i) {
      this.checkList.items.splice(i, 1)
    },

    /**
     * 使用機材の初期値セット
     */
    setTools() {
      // 三菱地所のみ
      if (this.tempId == 6) {
        // 途中保存も履歴もない場合、初期値セット
        if (!this.checkList.tools || !Object.keys(this.checkList.tools).length) {
          this.checkList.tools = utils.clone(TOOLS)
        }
      }
    },

    /**
     * 写真のコメント
     * @param i インデックス
     */
    photoText(j) {
      const i = j + 1
      let text = ''
      switch (i % 3) {
        case 1:
          text = '清掃前'
          break
        case 2:
          text = '清掃中'
          break
        case 0:
          text = '清掃後'
          break
      }
      return text
    },

    /**
     * 項目設定ボタンクリックイベント
     */
    editItem() {
      this.itemHistorySettingData = {
        clientName1: this.target.clientName1,
        clientName2: this.target.clientName2,
        clientCode: this.target.clientCode,
        siteName: this.target.siteName,
        siteCode: this.target.siteCode,
        ridgeCode: this.target.ridgeCode,
        patrolScheduleId: this.target.id,
        items: this.checkList.items,
        tempId: this.tempId
      }
      this.openItemHistorySettingModal = true
    },

    /**
     * 項目設定ダイアログclose
     */
    async closeItemHistorySettingModal() {
      this.openItemHistorySettingModal = false
      await this.getTarget()
    },

    /**
     * 毎月設定ボタン
     * @param val
     */
    monthlyBtnColor(val) {
      if (val == '奇数月') {
        return 'monthly-green'
      } else if (val == '偶数月') {
        return 'bg-yellow-500'
      } else {
        return 'monthly-blue'
      }
    },

    /**
     * 毎月ボタンイベント
     * @param row 対象行
     */
    async changeMonthly(row) {
      if (row.complete) {
        await dialogs.showInfoDialog('変更不可', 'こちらの項目は既に完了しているため、変更不可です。\r\n終了後、「報告書項目設定」にて変更するか、次回こちらの項目を完了する前に変更してください。')
      } else {
        await dialogs.showInfoDialog('「項目設定」ボタンを押してください', '変更したい場合は、終了ボタンの下にある「項目設定」ボタンを押して変更して下さい。')
      }
    },

    /**
     * 清掃種類ボタンイベント
     * 得意先：大和ライフネクスト株式会社
     */
    cleaningType(row, val) {
      row.detail1 = val
      this.reload++
    },

    /**
     * 大和ライフネクストの場合、写真説明など報告書用に形を変換
     */
    setCleaningTextArr(completed) {
      // 大和ライフネクストの場合のみ
      if (this.tempId != 8) {
        return
      }
      this.processOtherFlg = false
      for (let i = 0; i < this.checkList.items.length; i++) {
        const row = this.checkList.items[i]
        row.cleaningTextArr = ['']
        if (row.cleaningText) {
          row.cleaningTextArr = row.cleaningText.split('@@@')
          if (row.cleaningTextArr && row.cleaningTextArr.length) {
            // console.log(row.classification + '→' + row.complete)
            if (!completed && !row.complete) {
              let res = row.cleaningTextArr.filter((c) => {
                return c
              })
              if (res && res.length) {
                row.cleaningTextArr = res
              }
            }
          }
        }
        // 工程にその他があるか
        this.isProcessOther(row.item)
        // その他の内容がある場合
        if (this.checkList.tools && this.checkList.tools.other) {
          this.processOther = this.checkList.tools.other
        }
      }
      if (!this.processOtherFlg) {
        this.processOther = ''
      }
      this.reload++
    },

    /**
     * 工程の中にその他があるか
     * @param item 工程
     */
    isProcessOther(item) {
      // 工程の中にその他があるか
      if (item.indexOf('P:') > -1) {
        this.processOtherFlg = true
      }
    },

    /**
     * 大和ライフネクストの場合、保存時に写真説明を変換
     */
    convertCleaningText(i) {
      if (this.tempId != 8) {
        return
      }
      const c = this.checkList.items[i]
      if (c.cleaningTextArr && c.cleaningTextArr.length) {
        c.cleaningText = c.cleaningTextArr.join('@@@')
      } else {
        c.cleaningText = ''
      }
    },

    /**
     * 作業工程クリックイベント（大和ライフネクスト）
     * @param row 対象行
     */
    processClick(row) {
      if (!this.canEdit) {
        return
      }
      this.processData = {
        classification: row.classification,
        item: row.item,
        selectCallback: (item) => {
          this.openProcessModal = false
          row.item = item
          this.temporarilyRegister('item', row)
          // 工程にその他があるか
          this.isProcessOther(row.item)
          this.reload++
        }
      }
      this.openProcessModal = true
    },

    /**
     * 作業工程その他変更イベント
     */
    async changeProcessOther() {
      try {
        let save = this.setSaveData(null)
        // 作業工程のその他を保存
        await backend.postData('patrolResultTool/saveOther', { save, type: this.type, tempId: this.tempId, other: this.processOther })

      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
      }
    },

    /**
     * 大和ライフネクストの場合、施錠項目関連変更イベント
     * @param row 対象項目
     * @param val 選択した値
     */
    changeDaiwaKey(row, val) {
      row.item = val
      this.reload++
    },

    /**
     * プレビュー画面遷移
     */
    async previewBtn() {
      // // 現在、プレビュー画面なら閉じる
      // if (this.preview) {
      //   window.close()
      // // 現在、報告書画面ならプレビューを開く
      // } else {
      //   // paramにスケジュールIDを渡す
      //   let scheduleId = this.target.id
      //   let resolvedRoute = this.$router.resolve({ 
      //     name: 'FixedCheckListTable',
      //     query: { scheduleId, claim: false, sub: -1, preview: 'true', scheduledDate: moment(this.scheduledDate).format('YYYY-MM-DD') },
      //   })
      //   window.open(resolvedRoute.href, '_blank')
      // }

      // 三井の場合は、何か一つでも完了していないとエラーになるためNG
      if (this.tempId == 4) {
        const comp = this.checkList.items.find((i) => {
          return i.sortNo > 0 && i.complete && i.value != 'N/A'
        })
        if (!comp || !comp.sortNo) {
          await dialogs.showErrorDialog('プレビュー不可', '清掃項目を少なくとも1つ完了してください。')
          return
        }
      }

      this.processing = true
      this.processingText = 'Loading...'
      // PDFを別タブで開く
      const param = { patrolScheduleId: this.target.id, preview: 'preview' }
      try {
        let res = await backend.postBlob('patrolDownload/downloadFixedClean', param)
        const uint8Array = res.data

        const fileURL = URL.createObjectURL(uint8Array)
        window.open(fileURL)
      } catch (e) {
        console.log(e)
        alert(e)
        this.processing = false
      }
      this.processing = false
    },

    /**
     * プレビュー表示
     * @param query クエリ
     */
    async getPreviewData(query) {
      const scheduleId = query.scheduleId
      const scheduledDate = query.scheduledDate
      this.canEdit = false
      // データ取得
      this.processing = true
      this.processingText = 'Loading...'

      // スケジュールIDに紐づくデータを取得
      let schedule = await backend.searchData('patrolSchedule/getById', { id: scheduleId, type: this.type, scheduledDate })
      if (schedule.data.data && schedule.data.data.length) {
        this.target = schedule.data.data[0]
        // テンプレートIDを取得
        await this.getTempId(this.target)
        this.list = this.target
        const compR = await backend.searchData('patrolResult/getPreviewData', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate: moment(this.target.scheduledDate).format('YYYY-MM-DD'), type: this.type, claim: this.claim, tempId: this.tempId, completionDate: this.target.completionDate })
        this.getCompleteData(compR)
        this.processing = false
      }
    },

    /**
     * 保存されている結果データを成形
     */
    getCompleteData(compR) {
      if (compR.data && compR.data.data) {
        this.setData(compR.data.data)
        this.setCleaningTextArr(true)
        // 添付画像 取得
        this.getfullImages()
      }
    }
  }
}
</script>
<style scoped>
/* safari用 */
_::-webkit-full-page-media,_:future,:root .mobile-content {
  width: 950px !important;
  /* height: 98vh !important; */
  min-height: 900px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  border: solid 1px rgb(206, 206, 246);
  border-radius: 10px;
}

.pc-content {
  width: 950px;
  height: calc(100vh - 20px);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  border: solid 1px rgb(206, 206, 246);
  border-radius: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.vsa-item {
  background-color: rgb(214, 238, 243);
}

.file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

.w-70px {
  width: 70px;
}

.file-type {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .file-type {
  height: 100%;
  top: 0;
  left: 0;
  width: 70px;
  opacity: 0;
  position: absolute;
}

/* safari用のスタイル */
_::-webkit-full-page-media,
_:future,
:root .comp-btn {
  position: relative;
  z-index: 50;
}

.mr-73px {
  margin-right: 73px;
}

.bg-darkblue {
  background: #010178;
}

.bg-darkgreen {
  background: #010178;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 96%;
}

.edit {
  border: 1px solid #053a3a;
}

.p-5p {
  padding: 5px;
}

.p-6p {
  padding: 6px;
}

.w-354 {
  width: 354px;
}

.w-370 {
  width: 370px;
}

.info-group {
  display: flex;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
  margin-left: auto;
}

.monthly-green {
  background: #69a669;
}

.monthly-blue {
  background: #5296c3;
}

::placeholder {
  color: rgb(167, 167, 167);
  text-align: left;
  font-size: small;
}

.preview-btn {
  height: 34px;
  background-color: white;
  z-index: 100;
}

/* safari用 */
_::-webkit-full-page-media,_:future,:root .preview-btn {
  height: 34px;
  background-color: white;
  z-index: 100;
}

@media screen and (max-width: 900px) {
  /* .mobile-content {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  } */

  .info-group {
    display: block;
  }

  .info1 {
    width: 100%;
  }

  .info2 {
    width: 100%;
    margin-right: auto;
  }

}
</style>