<template>
  <div>
    <PatrolInspectionComponent
      typeName="設備点検 点検項目設定"
      :type=4
      settionStrageName="FacilityInspectionStorageItemName">
    </PatrolInspectionComponent>
  </div>
</template>

<script>
import PatrolInspectionComponent from '../../Patrol/components/PatrolInspectionComponent.vue'

export default {
  components: {
    PatrolInspectionComponent
  },
}
</script>