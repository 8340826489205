import { render, staticRenderFns } from "./TermModal.vue?vue&type=template&id=120edae7&scoped=true&"
import script from "./TermModal.vue?vue&type=script&lang=js&"
export * from "./TermModal.vue?vue&type=script&lang=js&"
import style0 from "./TermModal.vue?vue&type=style&index=0&id=120edae7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120edae7",
  null
  
)

export default component.exports