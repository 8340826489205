<template>
  <input 
    :key="renderKey"
    ref="input"
    :name="name" 
    type="text" 
    autocomplete="off"
    :placeholder="placeholder"
    :value="formattedValue"
    :disabled="disabled"
    @change="onChange($event.target.value)"
    @keydown.enter="handleEnterKey"
  />
</template>
<script>
import * as utils from '@libs/utils'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    decimalPlace: {
      type: Number,
      default: 0
    },
    suffix: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      text: '',
      renderKey: 0
    }
  },

  created() {
    this.setValue(this.value)
  },

  watch: {
    value(newValue) {
      this.setValue(newValue)
    }
  },

  computed: {
    formattedValue() {
      if (this.text === '') {
        return this.text
      } else {
        if (this.format) {
          return `${utils.format(this.text, this.decimalPlace)}${this.suffix}`
        } else {
          return this.text
        }
      }
    }
  },

  methods: {
    setValue(value) {
      const hankaku = utils.hankaku(value || '')
      this.text = utils.sanitizeStringForNumber(hankaku)
      this.renderKey++
    },
    
    onChange(value) {
      console.log(`onChange : ${value}`)
      this.setValue(value)
      this.$emit('change', this.text)
    },

    handleEnterKey() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit('onEnterKey', { target: this.$refs.input })
        })
      })
    }
  }
}
</script>
<style scoped>
input:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
  opacity: 1;
  cursor: not-allowed;
}
</style>