import { render, staticRenderFns } from "./TextRenderer.vue?vue&type=template&id=49957a15&"
import script from "./TextRenderer.vue?vue&type=script&lang=js&"
export * from "./TextRenderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports