<template>
  <button 
    type="button"
    :class="size"
    @click="$emit('click')"
  >
    <div class="flex items-center justify-center w-full" v-if="buttonContents == '' || ''">
      <div class="-ml-0.5 mr-2">
        <slot name="before" />
      </div>
      {{ text }}
      <div class="-mr-0.5 ml-2" >
        <slot name="after" />
      </div>
    </div>

    <div class="flex items-center justify-center w-full" v-if="buttonContents == '要'">
      <div>
        <slot name="before" />
      </div>
      {{ text }}
      <div>
        <slot name="after" />
      </div>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    buttonContents: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
.xs {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.sm {
  @apply inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.normal {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.lg {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.xl {
  @apply inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
button:disabled {
  @apply bg-indigo-600 hover:bg-indigo-600 cursor-not-allowed;
}
.other {
  @apply inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
</style>
