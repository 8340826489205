<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="">
				<div class="text-sm text-gray-500 mb-5">
					色をお選びください。
				</div>

				<div class="flex">
					<div class="w-48">
						背景および得意先名の色
					</div>
					<div>
						<input
							type="color"
							v-model="bgColor"
						>
					</div>
				</div>
				
				<div class="flex">
					<div class="w-48">
						物件名および清掃箇所の色
					</div>
					<div>
						<input
							type="color"
							v-model="textColor"
						>
					</div>
				</div>
				
				<div class="flex">
					<div class="w-48">
						タイトルの色
					</div>
					<div>
						<input
							type="color"
							v-model="titleColor"
						>
					</div>
				</div>
				
				<PrimaryButton text="決定" class="w-full max-w-md mt-5" @click="saveColor()" ></PrimaryButton>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import Icon from '@components/Icon.vue'
// import * as backend from '@libs/backend'
// import * as utils from '@libs/utils'

export default {
	components: {
		Modal,
		PrimaryButton,
		// Icon,
	},

	props: ['open', 'color'],

	data() {
		return {
			bgColor: null,
			textColor: null,
			titleColor: null
		}
	},

	computed: {
	},

	watch: {
		open() {
			if (this.open) {
				this.bgColor = this.color.bgColor
				this.textColor = this.color.textColor
				this.titleColor = this.color.titleColor
			}
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
		},

		/**
		 * 色決定ボタン
		 */
		async saveColor() {
			this.$emit('close', { bgColor: this.bgColor, textColor: this.textColor, titleColor: this.titleColor })
		}
	}
}
</script>
<style scoped>
</style>