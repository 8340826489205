<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">

			<div class="toggle flex justify-start items-center mb-2 w-11/12 m-auto">
				<span class="text-gray-500 font-bold text-xs">並替え</span>
				<Toggle v-model="sortable" size="small"/>
			</div>

			<table>
				<thead class="">
					<tr class="">
						<th v-for="(c, i) in columns" :key="'m' + i" class="text-center pl-3 pr-3 pt-1 pb-1 whitespace-pre-line">{{ c }}</th>
					</tr>
				</thead>
				<draggable 
					key="staff"
					:list="list"
					direction="vertical"
					handle=".row-drag-handle"
					tag="tbody"
				>
					<!-- <tbody> -->
						<tr v-for="(row, i) in list" :key="'mr' + i">
							<!-- 並べ替え -->
							<td v-if="sortable" class="">
								<div class="flex justify-center items-center row-drag-handle cursor-pointer">
									<MenuIcon class="text-gray-400" />
								</div>
							</td>
							<!-- 表示順 -->
							<td v-else class="text-center pr-3 pb-1">{{ i + 1 }}</td>

							<!-- 担当者 -->
							<td class="pr-3 pb-1">{{ row.staff }}</td>

							<!-- 色 -->
							<td class="w-28 text-right pr-3 pb-1">
                <select
                  name="color"
                  v-model="row.color"
                  class="border-gray-300 w-full"
									:style="setColor(row.color)"
                >
                  <option
                    v-for="c in colorList" :key='c'
                    :value="c"
                    class="w-28"
										:style="setColor(c)"
									>
                  </option>
                </select>
							</td>

							<!-- 非表示固定 -->
							<td class="text-right px-3 pb-1 flex w-fit">
								<div class="font-bold text-white rounded cursor-pointer py-2 w-20 text-center mr-1" :class="!row.disabled?'bg-indigo-800':'bg-gray-300'" @click="setDisabled(row, false)">
									表示
								</div>
								<div class="font-bold text-white rounded cursor-pointer py-2 w-20 text-center" :class="row.disabled?'bg-indigo-800':'bg-gray-300'" @click="setDisabled(row, true)">
									非表示
								</div>
							</td>
							
							<!-- 削除 -->
							<td class="pb-1" >
								<div class="mx-auto w-fit">
									<TrashIcon @click="deleteSetting(i)" class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
								</div>
							</td>
						</tr>
				</draggable>
			</table>
			
			<PrimaryButton
				text="保存"
				class="m-3 save-btn"
				@click="save()"
			>
			</PrimaryButton>
		</div>
		<div class="text-transparent">{{reload}}</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import Icon from '@components/Icon.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
// import * as utils from '@libs/utils'

export default {
	components: {
		Modal,
		PrimaryButton,
		// Icon,
		Toggle,
		draggable,
		TrashIcon,
		MenuIcon
	},

	props: ['target', 'open'],

	data() {
		return {
			// カラム
			columns: [
				'表示順\r\n(左から順に)',
				'担当者',
				'色',
				'表示',
				'設定値削除'
			],
			// 表示データ
			list: [],
			// 並べ替え
			sortable: false,
			// 色リスト
			colorList: [
				// 黄色
				'#FFE4B5',
				'#FFCC00',
				// ピンク
				'#FFB6C1',
				'#FA8072',
				// オレンジ
				'#FF9900',
				// 赤
				'#CC3366',
				// 紫
				'#FF88FF',
				'#9370DB',
				// 緑
				'#9ACD32',
				'#66CDAA',
				// 青
				'#87CEFA',
				'#2C7CFF',
				// 紺
				'#00008B'
			],
			reload: 0
		}
	},

	computed: {
	},

	watch: {
		open() {
			this.getList()
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.list = []
		},

		/**
		 * データ取得
		 */
		async getList() {
			if (!this.open) {
				return
			}
			this.list = []
			let name = []
			let result = await backend.searchData('estimateCountingDisplay/getAll')
			if (result.data.data) {
				this.list = result.data.data
				name = this.list.map((l) => {
					return l.staff
				})
			}

			let add = []
			// 親画面で表示しているスタッフ
			for (let i = 0; i < this.target.length; i++) {
				const t = this.target[i]
				const staffName = t.staffName
				if (!name || !name.length || !name.includes(staffName)) {
					add.push({ staff: staffName, color: '' })
				}
			}

			this.list = this.list.concat(add)
		},

		/**
		 * 背景色
		 * @param val 色コード
		 */
		setColor(val) {
			let css = ''
			if (val) {
				css = 'background-color: ' + val + ' !important;'
			}
			return css
		},

		/**
		 * 表示設定
		 * @param row 対象行
		 * @param val 値
		 */
		setDisabled(row, val) {
			row.disabled = val
			this.reload++
		},

		/**
		 * 設定値削除
		 * @param i index
		 */
		deleteSetting(i) {
			this.list.splice(i, 1)
		},

		/**
		 * 保存
		 */
		async save() {
			await backend.postData('estimateCountingDisplay/save', { list: this.list, insertUser: this.$store.getters.user.id})
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
.save-btn {
	width: -webkit-fill-available;
}
</style>