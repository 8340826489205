<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <div class="content-container-app overflow-auto rounded main-height">
            <table class="mx-1 mb-12 main table-fixed">
                <thead class="main">
                    <tr>
                        <th v-for="(f, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                            <div v-if="f.title == 'お客様コード'" class="">
                                <input
                                    type="search"
                                    name="filterCode" 
                                    placeholder="お客様コード 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="filter.clientCode"
                                    @change="getData()"
                                />
                            </div>
                            <div v-else-if="f.title == 'お客様名'" class="">
                                <input
                                    type="search"
                                    name="filterName1" 
                                    placeholder="お客様名 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="filter.client"
                                    @change="getData()"
                                />
                            </div>
                            <div v-else-if="f.title == 'お客様部署・支店名'" class="">
                                <input
                                    type="search"
                                    name="filterName2" 
                                    placeholder="お客様部署・支店名 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="filter.clientDepartment"
                                    @change="getData()"
                                />
                            </div>
                            <div v-else></div>
                        </th>
                    </tr>
                    <tr v-if="list && list.length" class="border border-gray-200">
                        <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                            {{c.title}}
                        </th>
                    </tr>
                </thead>

                <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider h-10 cursor-pointer hover-color" @click="clickRow(row)">

                    <!-- 選択 -->
                    <td class="b-top px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 w-20">
                        <input
                            type="radio"
                            :id="row.clientCode"
                            :value="row"
                            class="cursor-pointer"
                            v-model="selectedClient"
                        >
                    </td>

                    <!-- 得意先コード -->
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                        {{ row.clientCode }}
                    </td>

                    <!-- 得意先名 -->
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                        {{ row.clientName }}
                    </td>

                    <!-- 得意先部署名 -->
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                        {{ row.clientDepartmentName }}
                    </td>
                </tr>
            </table>
            <div v-if="!loading && !list.length" class="main w-full flex justify-center items-center">
                <p class="text-gray-400 font-bold">対象データがありません</p>
            </div>
            <div>
                <PrimaryButton text="選択" size="normal px-10 py-3 place-items-end mt-3 btn" :disabled="selectedClient === null"  @click="selectedClientBtn()" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as estimateManager from '@managers/estimateManager'
export default {
    components: {
        Modal,
        PrimaryButton
    },

    props: ['open'],

    data() {
        return {
            // カラム
            columns: [
                { title: '選択' },
                { title: 'お客様コード' },
                { title: 'お客様名' },
                { title: 'お客様部署・支店名' }
            ],
            // お客様コード,お客様名,お客様部署名 検索
            filter: {
                clientCode: '',
                client: '',
                clientDepartment: ''
            },
            // 得意先一覧
            list: [],
            // 選択した得意先
            selectedClient: null,
            // ローディング
            loading: false
        }
    },

    computed: {
    },

    // async created() {
    //     await this.getData()
    // },

    watch: {
        async open() {
            if (this.open) {
                await this.getData()
            }
        }
    },

    methods: {
        /**
         * 得意先取得
         */
        async getData() {
            this.loading = true
            const result = await estimateManager.getClient(this.filter) 
            if (result && result.length) {
                this.list = result
            } else {
                this.list = []
            }
            this.loading = false
        },

        /**
         * 行クリックイベント
         */
        clickRow(target) {
            this.selectedClient = target
        },

        /**
         * 選択ボタンイベント
         */
        selectedClientBtn() {
            this.$emit('modalClientSelected', this.selectedClient)
            this.closed()
        },

        /**
         * 閉じる
         */
        closed() {
            // カラム
            this.columns = [
                { title: '選択' },
                { title: 'お客様コード' },
                { title: 'お客様名' },
                { title: 'お客様部署・支店名' }
            ]
            // お客様コード,お客様名,お客様部署名 検索
            this.filter = {
                clientCode: '',
                client: '',
                clientDepartment: ''
            }
            // 得意先一覧
            this.list = []
            // 選択した得意先
            this.selectedClient = null
            // ローディング
            this.loading = false
        }
    }
}
</script>
<style scoped>
.main-height {
    height: 70vh;
}

.main {
    min-width: 700px;
}

.min-300 {
    min-width: 300px;
}

.min-70 {
    min-width: 70px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: rgb(233, 244, 247);
}

.btn {
    width: 94%;
    display: flex;
    bottom: 19px;
    position: absolute;
    justify-content:space-between;
}

.hover-color:hover {
    background: rgb(233, 244, 247);
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>