<template>
  <VueFinalModal
    v-slot="{ params }"
    v-bind="$attrs"
    v-on="$listeners"
    :click-to-close="false"
    classes="flex justify-center items-center"
  >
    <div class="flex justify-center items-center">
      <div class="flex flex-col items-center space-y-4" style="width: 1000px;">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <LoadingIcon class="h-6 w-6 text-gray-700" />
              </div>
              <div class="ml-4 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ params.message }}
                </p>
                <p v-if="params.subMessage" class="mt-1 text-sm text-gray-500">
                  {{ params.subMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import LoadingIcon from '@assets/loading-circle.svg' 

export default {
  inheritAttrs: false,
  components: {
    VueFinalModal,
    LoadingIcon
  }
}
</script>
