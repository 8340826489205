import { render, staticRenderFns } from "./PriceFire.vue?vue&type=template&id=5040d528&scoped=true&"
import script from "./PriceFire.vue?vue&type=script&lang=js&"
export * from "./PriceFire.vue?vue&type=script&lang=js&"
import style0 from "./PriceFire.vue?vue&type=style&index=0&id=5040d528&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5040d528",
  null
  
)

export default component.exports