<template>
  <div>
    <PatrolInspectionComponent
      typeName="品質巡回 点検項目設定"
      :type="1"
      settionStrageName="InspectionStorageItemName">
    </PatrolInspectionComponent>
  </div>
</template>

<script>
import PatrolInspectionComponent from '../components/PatrolInspectionComponent.vue'

export default {
  components: {
    PatrolInspectionComponent
  },
}
</script>