<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="dustCountSelections"
    :multiSelect="false"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    cleanCount: {
      type: Array,
      default: () => []
    },

    category2: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    /**
     * 選択肢
     */
    dustCountSelections() {

      if (this.category2[0] && this.category2[0] == 'お客様直') {
        
        if (this.cleanCount[0]) {

          if (this.cleanCount[0] == '週 3.5 回') {
            return [
              {
                value: '週 4 回',
                text: '週 4 回'
              }
            ]
          } else if (this.cleanCount[0] == '週 4 回' || this.cleanCount[0] == '週 3 回') {
            return [
              {
                value: '週 4 回',
                text: '週 4 回'
              },
              {
                value: '週 4.5 回',
                text: '週 4.5 回'
              },
              {
                value: '週 5 回',
                text: '週 5 回'
              },
              {
                value: '週 5.5 回',
                text: '週 5.5 回'
              },
              {
                value: '週 6 回',
                text: '週 6 回'
              }
            ]
          } else if (this.cleanCount[0] == '週 4.5 回') {
            return [
              {
                value: '週 4.5 回',
                text: '週 4.5 回'
              }
            ]
          } else if (this.cleanCount[0] == '週 5 回') {
            return [
              {
                value: '週 5 回',
                text: '週 5 回'
              }
            ]
          } else if (this.cleanCount[0] == '週 6 回') {
            return [
              {
                value: '週 6 回',
                text: '週 6 回'
              }
            ]
          } else {
            return []
          }
        } else {
          return []
        }
      } else {
        return [
          {
            value: '週 1 回',
            text: '週 1 回'
          },
          {
            value: '週 2 回',
            text: '週 2 回'
          },
          {
            value: '週 3 回',
            text: '週 3 回'
          },
          {
            value: '週 3.5 回',
            text: '週 3.5 回'
          },
          {
            value: '週 4 回',
            text: '週 4 回'
          },
          {
            value: '週 4.5 回',
            text: '週 4.5 回'
          },
          {
            value: '週 5 回',
            text: '週 5 回'
          },
          {
            value: '週 5.5 回',
            text: '週 5.5 回'
          },
          {
            value: '週 6 回',
            text: '週 6 回'
          }
        ]
      }
    },

    internalValue() {
      if (!this.dustCountSelections.length) {
        return []
      }
      const value = this.value.map((v) => {
        return this.dustCountSelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>