<template>
	<div>
		<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
			<template #action>
				<div class="content-container-app overflow-auto">
					<div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
					<div class="text-lg text-blue-800 font-bold" v-else>編集</div>
					<div class="w-full mt-3">
						<!-- 区分 -->
						<div class="text-xs mb-1 text-gray-700 font-bold">区分<span class="-top-0.5 ml-1 text-red-500"> * </span></div>
						<div class="flex mx-auto w-fit">
							<label class="mr-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="1" v-model="sub.classification">
								受注以外
							</label>
							<label class="ml-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="2" v-model="sub.classification">
								受注
							</label>
						</div>

						<!-- サブタスク名 -->
						<div class="mt-5">
							<WmsTextInput 
								name="name" 
								caption="サブタスク名"
								:required="true"
								v-model="sub.name"
							/>
						</div>

						<!-- 担当部署 -->
						<div class="mt-5">
							<div class="text-xs mb-1 text-gray-700 font-bold">担当部署</div>
							<select
								v-model="sub.departmentName"
								class="border-gray w-full rounded py-1"
							>
							<option v-for="(row, index) in depList" :value="row.name" :key="'d' + index">
								{{ row.name }}
							</option>
							</select>
						</div>

						<!-- 担当スタッフ -->
						<div class="mt-5">
							<div class="text-xs mb-1 text-gray-700 font-bold">担当者</div>
							<select
								v-model="sub.toStaffName"
								class="border-gray w-full rounded py-1"
							>
							<option v-for="(row, index) in staffList(sub.departmentName)" :value="row.userName" :key="'s' + index">
								{{ row.userName }}
							</option>
							</select>
						</div>

						<!-- 期限 -->
						<div class="mt-5">
							<div class="text-xs mb-1 text-gray-700 font-bold">期限（●日後）</div>
							<div class="flex">
								<input type="number" class=" py-1 border-gray w-full rounded" v-model="sub.limit">
								<div class="font-bold ml-2 mt-auto w-10">日後</div>
							</div>
						</div>

						<!-- メモ -->
						<div class="mt-5">
							<WmsTextInput 
								name="memo" 
								caption="メモ"
								v-model="sub.memo"
							/>
						</div>

						<!-- 最終完了タスク -->
						<div class="mt-5">
							<input
								type="checkbox"
								id="finalCompletionTask"
								v-model="sub.finalCompletionTask"
							>
							<label for="finalCompletionTask" class="text-blue-600 font-bold text-sm py-1 px-2"> 最終完了タスク </label>
						</div>
						<div>
							<PrimaryButton text="登録" size="normal px-10 mb-5 mt-5 w-full" @click="register()" />
						</div>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'

const FORMID = 'task_master'

const SUB = {
	classification: '1',
	name: '',
	departmentName: '',
	toStaffName: '',
	limit: null,
	memo: '',
	finalCompletionTask: ''
}

export default {
	components: {
		Modal,
		PrimaryButton,
		WmsTextInput
	},

	props: {
		target: {
			type: Object
		},
		subList: {
			type: Array
		},
		open: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			// 新規作成
			isNew: false,
			// サブタスク
			sub: utils.clone(SUB)
		}
	},

	watch: {
		open() {
			if (!this.open) {
				return
			}
			this.getTarget()
		}
	},

	computed: {
		/**
		 * 部署リスト
		 */
		depList() {
			return this.$store.getters.departmentList
		},

		/**
		 * スタッフリスト
		 * @param name 部署名
		 */
		staffList() {
			return (name) => {
				let res = []
				if (name) {
					// 部署IDを取得
					const dep = this.depList.find((d) => {
						return d.name == name
					})
					// 部署IDが取得できた場合
					if (dep && dep.departmentId) {
						// 該当の部署に所属しているスタッフを取得
						const ud = this.$store.getters.userDepartment
						res = ud.filter((u) => {
							return u.departmentId == dep.departmentId
						})
						return res
					}
				}
				return res
			}
		}
	},

	methods: {
		/**
		 * 表示するデータ取得
		 */
		getTarget() {
			// 編集の場合
			if (this.target && Object.keys(this.target).length) {
				this.isNew = false
				this.sub = utils.clone(this.target)
			// 新規の場合
			} else {
				this.isNew = true
				this.sub = utils.clone(SUB)
			}
		},

		/**
		 * 初期化
		 */
		closed() {
			// 新規作成
			this.isNew = false
			// サブタスク
			this.sub = utils.clone(SUB)
			// スタッフリスト
			this.staffList = []
		},

		/**
		 * 登録処理
		 */
		async register() {
			// 必須
			if (!this.sub.classification) {
				await this.errRequired('区分')
				return
			}
			if (!this.sub.name) {
				await this.errRequired('サブタスク名')
				return
			}

			// サブタスク名がかぶっていないか
			const err = this.subList.find((s) => {
				return (s.name == this.sub.name && s._id != this.sub._id && s.classification == this.sub.classification)
			})
			if (err && err.name) {
				await dialogs.showErrorDialog('同じサブタスク名が既にあります。', '同じサブタスク名が既に存在します。\r\nサブタスク名を変更してください。')
				return
			}

			// 登録
			try {
				// 新規
				if (this.isNew) {
					await this.$pigeon.registerTask(FORMID, this.sub, {})
				// 更新
				} else {
					await this.$pigeon.updateTask(this.sub._id, this.sub, {})
				}

				// ログ登録
				logManager.recordingByRegist(
					this,
					this.isNew,
					'サブタスクマスタ',
					'サブタスク（' + this.sub.task + '）'
				)
			} catch (error) {
				alert(error)
			}
				this.$emit('modalClose')
				this.closed()
		},

		/**
		 * 必須項目アラート
		 * @param item 項目名
		 */
		async errRequired(item) {
				await dialogs.showErrorDialog(item + 'は必須です', item + 'が選択されていません。')
		}
	}
}

</script>
<style scoped>
.content-container-app {
	width: 700px;
	padding: 0 10px;
}

.border-gray {
	border: solid #d3cfcf 1px;
}

.drop {
	width: 92%;
	margin-right: auto;
}
</style>