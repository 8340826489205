<template>
  <div>
    <UnPatrolListComponent
      typeName="品質巡回 未点検一覧"
      :type="1"
      settionStrageName="UnpatrolListStorageItemName">
    </UnPatrolListComponent>
  </div>
</template>

<script>
import UnPatrolListComponent from '../components/UnPatrolListComponent.vue'

export default {
  components: {
    UnPatrolListComponent
  },
}
</script>