<template>
  <nav>
    <ol class="divide-y divide-gray-100 md:flex md:divide-y-0 overflow-x-auto overflow-y-hidden">
      
      <template v-for="(step, index) in steps">
        <li
          :key="index"
          class="progress-step relative md:flex-1 md:flex mr-3 last:mr-0 first:rounded-l last:rounded-r"
          :class="[step.status]"
          @click="stepValueIsMultiple(step) ? null : select(step.selection[0], step, index)"
          v-tooltip.left="step.data.memo"
        >
          <Popper
            trigger="clickToToggle"
            :append-to-body="true"
            :options="{
              placement: 'bottom'
            }"
            class="inline-block w-full z-10"
            :disabled="!stepValueIsMultiple(step)"
          >
            <a
              slot="reference"
              href="#" 
              class="group flex items-center w-full justify-center h-8 z-10 cursor-pointer"
            >
              <span class="px-3 py-2 flex items-center text-xs whitespace-pre ---font-medium">
                
                <span 
                  class="text-xs ---font-medium whitespace-pre flex"
                >
                  <Icon v-if="showCheckMarkForCompletedStep && step.status == 'done'" iconName="Check" class="w-4 h-4 mr-1" />
                  {{ progressText(step) }}
                </span>
              </span>
            </a>

            <ul class="z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm select" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
              <template v-for="val in step.selection">
                <li 
                  :key="val" 
                  class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                  @click="select(val, step)"
                >
                  <span class="font-normal block truncate">
                    {{ val }}
                  </span>
                </li>
              </template>
            </ul>      
          </Popper>
        </li>
      </template>
    </ol>
  </nav>
</template>
<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

import Icon from '@components/Icon.vue'

export default {
  components: {
    Popper,
    Icon
  },

  props: {
    steps: {
      type: Array,
      default: () => []
    },
    showCheckMarkForCompletedStep: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    progressText() {
      return (step) => {
        return step.displayText || step.value || ''
      }
    },

    stepValueIsMultiple() {
      return (step) => {
        return step.selection.length > 1
      }
    },
  },

  methods: {
    select(value, step) {
      this.$emit('change', value, step)
    }
  }
}
</script>

<style scoped>
li.progress-step {
  @apply bg-gray-300 text-gray-800;
}

/* li.progress-step.current, li.progress-step.current:before, li.progress-step.current:after {
  background-color: rgb(3, 45, 96);
  @apply text-white;
} */

li.progress-step.done, li.progress-step.done:before, li.progress-step.done:after {
  background-color: rgb(76, 155, 220);
  @apply text-white;
}

li.progress-step.open, li.progress-step.open:before, li.progress-step.open:after {
  background-color: rgb(40, 139, 63);
  @apply text-white;
}

li.progress-step.limitOut, li.progress-step.limitOut:before, li.progress-step.limitOut:after {
  background-color: rgb(222, 84, 89);
  @apply text-white;
}

li.progress-step.limitToday, li.progress-step.limitToday:before, li.progress-step.limitToday:after {
  background-color: rgb(238, 164, 27);
  @apply text-white;
}

li.progress-step:hover, li.progress-step:hover:before, li.progress-step:hover:after {
  @apply bg-gray-400;
}

/* li.progress-step.current:hover, li.progress-step.current:hover:before, li.progress-step.current:hover:after {
  background-color: rgb(3, 45, 96);
} */

li.progress-step.done:hover, li.progress-step.done:hover:before, li.progress-step.done:hover:after {
  background-color: rgb(50, 111, 160);
}

li.progress-step.open:hover, li.progress-step.open:hover:before, li.progress-step.open:hover:after {
  background-color: rgb(35, 102, 51);
}

li.progress-step.limitOut:hover, li.progress-step.limitOut:hover:before, li.progress-step.limitOut:hover:after {
  background-color: rgb(222, 63, 68);
}

li.progress-step.limitToday:hover, li.progress-step.limitToday:hover:before, li.progress-step.limitToday:hover:after {
  background-color: rgb(213, 155, 30);
}

li.progress-step:before {
  top: 0;
  height: calc((2rem / 2) + 0.0625rem);
  transform: skew(28deg) translate3d(0, 0, 0);
  box-sizing: border-box;
  @apply bg-gray-300;
}

li.progress-step:first-child:before, li.progress-step:first-child:after {
  left: 1.125rem;
}

li.progress-step:last-child:before, li.progress-step:last-child:after {
  right: .625rem;
}

li.progress-step:after {
  bottom: 0;
  height: 1rem;
  transform: skew(-30deg) translate3d(0, 0, 0);  
  box-sizing: border-box;
  @apply bg-gray-300;
}

li.progress-step:before, li.progress-step:after {
  content: '';
  position: absolute;
  left: -.25rem;
  right: -.3125rem;
  cursor: pointer;  
}

.select {
  top: 23px !important;
}

.text-xss {
  font-size: 11px
}
</style>