<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="newClassSelections"
    :multiSelect="false"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

const newClassSelections = [
  {
    value: '新築',
    text: '新築'
  },
  {
    value: '他社切替',
    text: '他社切替'
  },
  {
    value: '既存',
    text: '既存'
  }
]

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalValue() {
      const value = this.value.map((v) => {
        return this.newClassSelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  data() {
    return {
      newClassSelections
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>