<template>
  <div
    class="flex justify-between items-center cursor-pointer" 
    @click="setValue(!active)"
  >
    <div 
      class="w-9 h-4 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out relative" 
      :class="outerClasses"
    >
      <CheckIcon 
        v-show="active" 
        class="transition duration-500 absolute text-white"
        :class="markClasses"
      />
      <div 
        class="bg-white w-3 h-3 rounded-full shadow-md transform duration-300 ease-in-out" 
        :class="knobClasses"
      >
      </div>
    </div>
  </div>
</template>

const SIZE_TABLE = {
  small: [],
  normal: []
  large: []
}

<script>
import { CheckIcon } from '@vue-hero-icons/solid'

export default {
  components: {
    CheckIcon
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    color: {
      type: String,
      default: 'teal'
    }
  },

  data() {
    return {
      active: false,
    };
  },

  computed: {
    outerClasses() {
      const classes = []
      if (this.size ===  'small') {
        classes.push('w-9 h-4')
      } else if (this.size === 'large') {
        classes.push('w-14 h-6')
      } else {
        classes.push('w-11 h-5')
      }
      if (this.active) {
        classes.push(`bg-${this.color}-400`)
      }
      return classes
    },

    knobClasses() {
      const classes = []
      if (this.size ===  'small') {
        classes.push('w-3 h-3')
        if (this.active) {
          classes.push('translate-x-4')
        }
      } else if (this.size === 'large') {
        classes.push('w-5 h-5')
        if (this.active) {
          classes.push('translate-x-7')
        }
      } else {
        classes.push('w-4 h-4')
        if (this.active) {
          classes.push('translate-x-5')
        }
      }
      return classes
    },

    markClasses() {
      const classes = []
      if (this.size === 'small') {
        classes.push('w-3 h-3')
      } else if (this.size === 'large') {
        classes.push('w-5 h-5')
      } else {
        classes.push('w-4 h-4')
      }
      return classes
    }
  },

  created() {
    this.setValue(this.value)
  },

  methods: {
    setValue(value) {
      if (value !== this.active) {
        this.active = value
        this.$emit('change', this.active)
      }
    }
  },

  watch: {
    value(newValue) {
      this.setValue(newValue)
    }
  }
}
</script>