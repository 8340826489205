import RoundCleanApp from './RoundCleanApp.vue'
import RoundPatrolInspection from './pages/RoundPatrolInspection.vue'
import RoundTerm from './pages/RoundTerm.vue'
import RoundUnPatrolList from './pages/RoundUnPatrolList.vue'
import RoundScheduleListByStaff from './pages/RoundScheduleListByStaff.vue'
import RoundCheckList from './pages/RoundCheckList.vue'
// import Download from './pages/Download.vue'
import RoundCheckListTable from './pages/RoundCheckListTable.vue'
import RoundSiteListMaster from './pages/RoundSiteListMaster.vue'

export default {
  path: '/RoundClean',
  component: RoundCleanApp,
  children: [
    {
      path: '/RoundPatrolInspection',
      name: 'RoundPatrolInspection',
      component: RoundPatrolInspection,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書項目設定' },
      props: true
    },
    {
      path: '/RoundTerm',
      name: 'RoundTerm',
      component: RoundTerm,
      meta: { requiresAuth: true, title: 'apollo-RP - 期間設定' },
      props: true
    },
    {
      path: '/RoundUnPatrolList',
      name: 'RoundUnPatrolList',
      component: RoundUnPatrolList,
      meta: { requiresAuth: true, title: 'apollo-RP - 未点検一覧' },
      props: true
    },
    {
      path: '/RoundScheduleListByStaff',
      name: 'RoundScheduleListByStaff',
      component: RoundScheduleListByStaff,
      meta: { requiresAuth: true, title: 'apollo-RP - 予定一覧' },
      props: true
    },
    {
      path: '/RoundCheckList',
      name: 'RoundCheckList',
      component: RoundCheckList,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検一覧' },
      props: true
    },
    // {
    //   path: '/Download',
    //   name: 'Download',
    //   component: Download,
    //   meta: { requiresAuth: true, title: 'apollo-RP - ダウンロード' },
    //   props: true
    // },
    {
      path: '/RoundCheckListTable',
      name: 'RoundCheckListTable',
      component: RoundCheckListTable,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検表' },
      props: true
    },
    {
      path: '/RoundSiteListMaster',
      name: 'RoundSiteListMaster',
      component: RoundSiteListMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 対象物件マスタ' },
      props: true
    },
  ]
}
