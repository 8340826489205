<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="">
				<div class="font-medium text-sm text-gray-500 mb-1">
					新規作成
				</div>
				<div class="text-sm text-gray-500 mb-2">
					テンプレートを選択してください。
				</div>
				<div v-for="(row) in list" :key="row.key" class="mb-2 mx-2">
					<PrimaryButton :text="row.name" size="normal" class="w-full" @click="templateSelected(row)" />
				</div>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'

export default {
	components: {
		Modal,
		PrimaryButton,
		// Icon,
	},

	props: ['photoReportModalShow'],

	data() {
		return {
			list: [
				{ type: 1, key: 'FreeTemplate', name: 'フリー入力' },
				{ type: 2, key: 'StaticTemplate', name: '項目固定（現地調査）' },
				{ type: 3, key: 'BikeTemplate', name: '環境整備（バイク）' },
			],

		}
	},

	computed: {
	},

	watch: {
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
		},

		/**
		 * テンプレート選択イベント
		 */
		async templateSelected(row) {
			this.$router.push({ name: row.key })
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
</style>