<template>
  <div>
    <label 
      :for="name" 
      class="block text-xs font-bold text-gray-700"
      :class="captionColor || ''"
    >
      {{ caption }} <span v-if="required === true" class="-top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="mt-1 flex relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" />
      <input 
        v-if="!multiline"
        type="text" 
        :name="name" 
        :id="name" 
        :disabled="disabled ? 'disabled' : false" 
        class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
        :class="[disabled ? ['bg-gray-200'] : [], error ? 'error' : '', error ? 'WmsTextInputDisplay' : '']" 
        :value="value" 
        autocomplete="off"
        @change="$emit('change', $event.target.value)" />
      <textarea 
        v-else
        :name="name" 
        :id="name" 
        :rows="rows"
        :disabled="disabled ? 'disabled' : false" 
        class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded" 
        :class="[disabled ? ['bg-gray-200'] : [], error ? 'error' : '', error ? 'WmsTextInputDisplay' : '']"
        :value="value" 
        @change="$emit('change', $event.target.value)"
      ></textarea>
      <span v-if="suffix" class="ml-1 sm:text-sm text-gray-700">{{suffix}}</span>
        <div
          v-if="!!$slots['addon-content']"
          ref="addonButton"
          class="inset-y-0 mr-px my-px absolute right-0 inline-flex items-center space-x-2 px-4 ---border-l ---border-gray-300 text-sm font-medium rounded-r-md text-gray-700 ---bg-gray-50 ---hover:bg-gray-100 focus:outline-none ---focus:ring-1 ---focus:ring-indigo-500 ---focus:border-indigo-500"
        >
          <slot name="addon-content"></slot>
        </div>    
    </div>
  </div>
</template>
<script>
import InputIcon from '@components/InputIcon.vue'
export default {
  components: {
    InputIcon
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: [
    'name',
    'caption', 
    'disabled',
    'value',
    'multiline',
    'rows',
    'suffix',
    'captionColor',
    'error',
    'errorMessage',
    'errorColor',
    'required'
  ],
  watch: {
    value() {
      console.log(`${this.name} : change`)
      this.$emit('change', this.value)
    }
  }  
}
</script>
<style scoped>
input:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
  opacity: 1;
  cursor: not-allowed;
}

textarea:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
  opacity: 1;
  cursor: not-allowed;
}
</style>