<template>
  <div>
    <div class="mb-5 text-blue-800 font-bold">
      ※全て発注金額
    </div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column2" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white th-name">
            <div v-if="c.name" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td class="th-price">
            <div v-if="price[i] && c.value != '11'">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price[i].price"
                @change="changeData(price[i])"
              />
            </div>
            <div v-else class="ml-5 mt-3 mb-3 font-bold text-gray-500">
              1t増量ごとに 2,000 円追加
            </div>
          </td>
        </tr>
      </table>

      <!-- 駐車場代 -->
      <table class="ml-10 border-orange rounded">
        <tr class="orange">
          <th class="th-order">
            <div class="p-3">
              発注先
            </div>
          </th>
          <th class="th-eria">
            <div class="p-3">
              エリア
            </div>
          </th>
          <th>
            <div class="p-3">
              駐車場代
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column3" :key="i" class="hover-color2 text-right" :class="i==2?'border-orange-b':''">
          <th :rowspan="c.rowspan" v-if="c.name != ''" class="text-left bg-white">
            <div class="ml-5">
              {{ c.name }}
            </div>
          </th>
          <th>
            <div class="text-orange">
              {{ column4[i] }}
            </div>
          </th>
          <td>
            <div v-if="parking[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50 mr-5"
                :class="i==2 || i==4?'mb-3':i==3 || i ==0?'mt-3':''"
                v-model="parking[i].price"
                @change="changeData(parking[i])"
              />
            </div>
          </td>
        </tr>
      </table>

      <!-- 係数 -->
      <table class="ml-10 border-orange rounded">
        <tr class="orange">
          <th colspan=6>
            <div class="p-3">
              係数
            </div>
          </th>
        </tr>
        <tr v-for="(o, i) in other" :key="i" class="hover-color2 text-left">
          <th>
            <div class="ml-3 w-fit">
              {{o.name}}
            </div>
          </th>
          <td>
            <div class="mr-3 mt-2 mb-2">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50"
                v-model="o.price"
                :decimalPlace="2"
                @change="changeData(o)"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 貯水槽
 */
const CATEGORY = '12'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 水量カラム
      column1: [
        { name: '容量・内容', colspan: 1, class: 'ml-5 bg-teal-50 p-3'},
        { name: '単価', colspan: 1, class: 'ml-5 bg-teal-50 p-3 '},
      ],
      // 水量カラム
      column2: [
        { name: '3 t', value: '3' },
        { name: '4 t', value: '4' },
        { name: '5 t', value: '5' },
        { name: '6 t', value: '6' },
        { name: '7 t', value: '7' },
        { name: '8 t', value: '8' },
        { name: '9 t', value: '9' },
        { name: '10 t', value: '10' },
        { name: '11 t以上', value: '11' },
        { name: 'ポンプ点検', value: 'pump' },
        { name: '水質点検', value: 'inspection' },
      ],
      // 発注先カラム
      column3: [
        { name: 'ニッツ', rowspan: 3 },
        { name: '' },
        { name: '' },
        { name: 'ベスパ―', rowspan: 2 },
        { name: '', },
      ],
      // エリアカラム
      column4: [
        '東京23区', '千葉・神奈川', 'その他', '千葉・埼玉・神奈川', 'その他'
      ],
      // 単価
      price: [],
      // 駐車場
      parking: [],
      // 係数など
      other: []
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {
      this.other = []

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let p = []
      let pp = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode.split('-')

        // 駐車場代
        if (code[1]) {
          pp.push(d)
        // 単価、その他
        } else {
          p.push(d)
        }

        if (d.priceCode.indexOf('twoTank') != -1) {
          this.other.push(d)
        }
      }

      this.price = this.setSort(p)
      this.parking = this.setSort2(pp)

    },

    /**
     * 水量によるソート
     * @param target 対象データ
     */
    setSort(target) {
      let result = []
      for (let l = 0; l < this.column2.length; l++) {
        const sort = this.column2[l]
        if (sort.value == '11') {
          result.push('')
          continue
        }
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode == sort.value) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 発注先・エリアによるソート
     * @param target 対象データ
     */
    setSort2(target) {
      const sort = ['1-1', '1-2', '1-3', '2-1', '2-2']
      let result = []
      for (let l = 0; l < sort.length; l++) {
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode == sort[l]) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''

      if (code.length == 1) {

        // 係数
        if (code[0].indexOf('twoTank') != -1) {
          name1 = target.name + '（係数）'
          target.coef = true
          
        // 単価
        } else {
          name1 = code[0] + 't'

          if (code[0] == 'pump') {
            name1 = 'ポンプ点検'
          } else if (code[0] == 'inspection') {
            name1 = '水質点検'
          }
        }
        
      // 駐車場代
      } else {
        name1 = 'ニッツ'
        if (code[0] == '2') {
          name1 = 'ベスパ―'

          switch (code[1]) {
            case '1':
              name2 = '・千葉埼玉神奈川'
              break;
          
            case '2':
              name2 = '・その他'
              break;
            default:
              break;
          }
        } else {
          switch (code[1]) {
            case '1':
              name2 = '・東京23区'
              break;
          
            case '2':
              name2 = '・千葉神奈川'
              break;
          
            case '3':
              name2 = '・その他'
              break;
            default:
              break;
          }
        }
        name3 = '（駐車場代）'
      }

      target.itemName = name1 + name2 + name3
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}

.th-name {
  min-width: 120px;
}

.th-price {
  min-width: 200px;
}
.th-order {
  min-width: 80px;
}
.th-eria {
  min-width: 100px;
}
</style>
