<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr class="sticky top-8 z-20">
          <th v-for="(c, i) in column2" :key="i">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column3" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c }}
            </div>
          </th>
          <td>
            <div v-if="single1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single1[i].price"
                @change="changeData(single1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single2[i].price"
                @change="changeData(single2[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="multi1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="multi1[i].price"
                @change="changeData(multi1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="multi2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="multi2[i].price"
                @change="changeData(multi2[i])"
              />
            </div>
          </td>
        </tr>
      </table>

    <!-- 係数 -->
    <table class="ml-10 border-orange rounded">
      <tr class="orange">
        <th colspan=6>
          <div class="p-3">
            係数
          </div>
        </th>
      </tr>
      <tr v-for="(o, i) in other" :key="i" class="hover-color2 text-left">
        <th>
          <div class="ml-3 w-fit">
            {{o.name}}
          </div>
        </th>
        <td>
          <div class="mr-3 mt-2 mb-2">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50"
              v-model="o.price"
              :decimalPlace="1"
              @change="changeData(o)"
            />
          </div>
        </td>
      </tr>
    </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 排水管清掃
 */
const CATEGORY = '11'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 日数カラム
      column1: [
        { name: '', colspan: 1, class: 'bg-white'},
        { name: '単日', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: '複数日', colspan: 2, class: 'ml-5 bg-blue-50'},
      ],
      // 区分カラム
      column2: [
        { name: '' },
        { name: '平日', class: 'ml-5 bg-teal-50' },
        { name: '土日', class: 'ml-5 bg-teal-50' },
        { name: '平日', class: 'ml-5 bg-blue-50' },
        { name: '土日', class: 'ml-5 bg-blue-50' },
      ],
      // 戸数カラム
      column3: [
        '5世帯まで',
        '（1世帯につき追加料金）',
        '10世帯',
        '（1世帯につき追加料金）'
      ],
      // 単日・平日
      single1: [],
      // 単日・土日
      single2: [],
      // 複数日・平日
      multi1: [],
      // 複数日・土日
      multi2: [],
      // 係数など
      other: []
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {
      this.other = []

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let s1 = []
      let s2 = []
      let m1 = []
      let m2 = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode.split('-')
        switch (code[1]) {
          // 単数
          case '1':
            // 平日
            if (code[0] == '1') {
              s1.push(d)
            // 土日
            } else if (code[0] == '2') {
              s2.push(d)
            }
            break;

          // 複数
          case '2':
            // 平日
            if (code[0] == '1') {
              m1.push(d)
            // 土日
            } else if (code[0] == '2') {
              m2.push(d)
            }
            break;
        }

        if (d.priceCode.indexOf('drainage') != -1) {
          this.other.push(d)
        }
      }

      this.single1 = this.setSort(s1)
      this.single2 = this.setSort(s2)
      this.multi1 = this.setSort2(m1)
      this.multi2 = this.setSort2(m2)
    },

    /**
     * 単日のソート
     * @param target 対象データ
     */
    setSort(target) {
      const sortArr = ['1', '6', '11', '11']
      const sortArr2 = [undefined, 'add', undefined, 'add']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.minHouseholds == sort && er.priceCode.split('-')[2] == sortArr2[l]) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 複数日のソート
     * @param target 対象データ
     */
    setSort2(target) {
      const sortArr = [undefined, 'add']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[2] == sort) {
            result.push(er)
          }
        }
      }
      return result
    },


    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''

      if (code[1] == '1') {
        name2 = '・単日'
      } else if (code[1] == '2') {
        name2 = '・複数日'
      }

      if (code[0] == '1') {
        name3 = '・平日'
      } else if (code[0] == '2') {
        name3 = '・土日'
      }

      // 追加料金
      if (target.priceCode.indexOf('add') != -1) {
        name1 = '5世帯以降追加'
        if (target.minHouseholds == '11') {
          name1 = '10世帯以降追加'
        }
        
      // 係数
      } else if (target.priceCode.indexOf('drainage') != -1) {
        name1 = target.name
        target.coef = true

      // 単価
      } else {
        name1 = '5世帯まで'
        if (target.minHouseholds == '11') {
          name1 = '10世帯'
        }
      }

      target.itemName = name1 + name2 + name3
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
