<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #title>{{ title }}</template>
      <template #subtitle>{{ subtitle }}</template>
      <SearchBox 
        class="mb-4 mx-1"
        v-model="searchText"
        @change="searchTextChange"
      />
      <div class="content-container">
        <ListView 
          ref="listview" 
          :options="listviewOptions"
          :searchText="searchText"
          noDataMessageText="検索結果はありません。キーワードを入力して検索しましょう。"
          @selectionChange="selectionChange"
        />
      </div>
      <template #action>
        <div class="flex w-full justify-between">
          <PrimaryButton text="選択" class="ml-auto w-full" :disabled="selectedData === null"  @click="apply" />
        </div>
        <div class="flex w-full justify-between" v-if="deleteBtn">
          <PrimaryButton text="削除" class="ml-5 w-full bg-red-600" :disabled="selectedData === null"  @click="deleteClick" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@components/Modal.vue'
import ListView from '@components/ListView.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import SearchBox from '@components/SearchBox.vue'

import * as dialogs from '@libs/dialogs'

export default {
  components: {
    Modal,
    PrimaryButton,
    ListView,
    SearchBox
  },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: null
    },
    deleteBtn: {
      type: Boolean,
      default: false
    },
    selectCallback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      renderKey: 0,
      listviewOptions: null,
      selectedData: null,
      searchText: ''
    }
  },

  watch: {
    options: {
      handler: function(newValue) {
        this.listviewOptions = newValue
      },
      deep: true
    }
  },

  created() {
    this.listviewOptions = this.options
  },

  methods: {
    selectionChange(selectedData) {
      this.selectedData = selectedData
    },

    apply() {
      this.selectCallback(this.selectedData)
      // this.$emit('apply', this.selectedData)
    },

    async deleteClick() {
      const dialogResult = await dialogs.showWarningConfirmDialog('確認', '削除してよろしいですか？')
        if (dialogResult === 'YES') {
          this.selectedData['delete'] = true
        this.selectCallback(this.selectedData)
        }
    },

    closed() {
      console.log('DataSelectModal::closed')
      this.selectedData = null
      this.searchText = ''
      this.listviewOptions = null
      this.renderKey++
    },

    searchTextChange(searchText) {
      this.selectedData = null
      this.searchText = searchText
    }
  }
}
</script>
<style scoped>
.content-container {
  width: 80vw;
  height: 60vh;
  /* max-width: 1200px;
  height: 600px; */
}
</style>