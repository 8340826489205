<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div v-if="loading" class="flex justify-center items-center loading-dialog"></div>
		
		<div v-if="list && list.length">
			<div v-if="type!=4" class="text-xs">行をクリックすると参照画面が開きます</div>
			<div class="flex mb-1">
				<div v-if="type==4" class="text-xs">行をクリックすると参照画面が開きます</div>
				<div v-if="type==4" class="mx-auto">-- {{target.month}}月に承認された見積り（集計対象外含む）--</div>
				<div v-else class="ml-auto text-gray-800 text-xs">
					<span class="mr-10">※「提出」を含むサブを完了した日が提出日</span>
					<span class="mr-10">※目安・実績日数は営業日でカウント</span>
					<span >※見積り総件数は同じメインタスクに対して承認済の見積り件数</span>
				</div>
				<!-- Excelダウンロード -->
				<div class="mt-auto ml-8 mr-2">
					<PrimaryButton text="Excel" class="w-40" @click="download()">
						<Icon iconName="DocumentDownload" slot="before" class="h-4 w-4" />
					</PrimaryButton>
				</div>
			</div>

			<div class="flex">
				<!-- スタッフごとの平均・件数 -->
				<div>
					<!-- <div v-if="type!=4" class="font-bold text-xs text-blue-700">※担当者ごとの 平均日数、合計件数、平均金額、合計金額</div>
					<div v-else class="font-bold text-xs text-blue-700">※担当者ごとの 合計件数、平均金額、合計金額</div> -->
					<div class="flex flex-wrap">
						<div v-if="type!=4" class="tex-right mr-2 mt-27px text-blue-800 font-bold">
							<div>平均日数</div>
							<div>合計件数</div>
							<div>平均金額</div>
							<div>合計金額</div>
						</div>
						<div v-else class="tex-right mr-2 mt-27px text-blue-800 font-bold">
							<div>新規物件</div>
							<div>追加変更</div>
							<div>スポット</div>
							<div>合計件数</div>
							<div class="mt-2">平均金額</div>
							<div>合計金額</div>
						</div>
						<div v-for="(row, idx) in detailByStaff" :key="'staff' + idx" class="border border-blue-600 mr-1">
							<div class="text-center bg-blue-700 text-white px-2 font-bold">
								{{ row.name }}
							</div>
							<div class="font-bold w-full">
								<!-- 平均日数 -->
								<div v-if="type!=4" class="px-2 text-right">{{ row.ave }} <span class="font-normal text-xs text-gray-700">日</span></div>
								<!-- 新規件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countNew || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- 追加変更件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countAdd || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- スポット件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countSpot || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- 合計件数 -->
								<div class="px-2 text-right">{{ row.count }} <span class="font-normal text-xs text-gray-700">件</span></div>

								<div v-if="type==4" class="border border-blue-600 w-full my-1"></div>

								<!-- 平均金額 -->
								<div class="px-2 text-right">{{ row.avePrice }} <span class="font-normal text-xs text-gray-700">円</span></div>
								<!-- 合計金額 -->
								<div class="pl-3 pr-2 text-right">{{ row.price }} <span class="font-normal text-xs text-gray-700">円</span></div>
							</div>
						</div>
					</div>
				</div>
				
				
				<!-- 全体の件数 -->
				<div v-if="type==4" class="flex border border-black font-bold ml-auto h-w-fit min-w-fit mt-auto">
					<div class="text-xs m-auto px-2 py-3">
						{{target.month}}月承認件数
					</div>
					<div class="m-auto px-2 py-3 border-l border-black">
						{{ list.length }} <span class="font-normal text-xs text-gray-700">件</span>
					</div>
				</div>
				<!-- 全体の平均 -->
				<div v-else class="flex border border-black font-bold ml-auto h-w-fit mt-auto">
					<div class="text-xs m-auto px-2 py-3">
						平均実績日数
					</div>
					<div class="m-auto px-2 py-3 border-l border-black">
						{{ ave }}
					</div>
				</div>
			</div>
		</div>

		<div class="rounded overflow-auto" :class="type==4?'main-height1':'main-height2'">
			<table class="main mx-auto w-full">
				<thead class="main sticky top-0">
					<tr v-if="list && list.length" class="border border-gray-200 ">
						<th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu whitespace-pre" :class="c.class">
							{{c.title}}
						</th>
					</tr>
				</thead>

				<!-- 総数 -->
				<tbody v-if="type==4">
					<tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color " @click="toEstimateView(row.estimateNo)">
						<!-- お問い合わせ -->
						<td v-if="i==0||list[i-1].requestNo!=row.requestNo" :rowspan="row.countEstimate" class="border-t-4 b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-yellow-700 bg-white">
							{{ row.requestNo }}
						</td>

						<!-- 見積りNo -->
						<td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-blue-700" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.estimateNo }}
						</td>

						<!-- 担当者 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.staff }}
						</td>
						
						<!-- 区分 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.typeName }}
						</td>
						
						<!-- 物件名 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 font-bold" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.site }}
						</td>
						
						<!-- 承認日 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.approved }}
						</td>
						
						<!-- 税抜金額 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-right min-w-90px" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.withoutTaxPrice }}
						</td>
					</tr>
				</tbody>

				<!-- 総数以外 -->
				<tbody v-else>
					<tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color " @click="toEstimateView(row.estimateNo)">
						<!-- 見積りNo -->
						<td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-blue-700">
							{{ row.estimateNo }}
						</td>

						<!-- 担当者 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.staff }}
						</td>
						
						<!-- 得意先 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.client }}
						</td>
						
						<!-- 得意先住所 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.clientAddress }}
						</td>
						
						<!-- 物件名 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 font-bold">
							{{ row.site }}
						</td>
						
						<!-- 物件住所 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.siteAddress }}
						</td>
						
						<!-- 依頼日 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.request }}
						</td>
						
						<!-- 見積申請日（自己承認の場合は承認日と同じ。過去データには申請日がないので依頼日が保存されており申請日がないデータが自己承認と判断） -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.approvalPetition || row.request?row.approved:null }}
						</td>
						
						<!-- 承認日 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.approved }}
						</td>
						
						<!-- 提出日 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.submission }}
						</td>
						
						<!-- 目安日数 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.scheduledDays }}
						</td>
						
						<!-- 実績日数（土日祝除く） -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.actualDays }}
						</td>
						
						<!-- 税抜金額 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-right min-w-90px">
							{{ row.withoutTaxPrice }}
						</td>
						
						<!-- 見積り総件数（同じメインタスクに対しての見積り作成件数 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-80px">
							{{ row.countEstimate }}
						</td>
					</tr>
				</tbody>
			</table>
			<div v-if="loading" class="main w-full h-full flex justify-center items-center">
				<p class="text-gray-400 font-bold">Loading...</p>
			</div>
			<div v-else-if="(!list || !list.length)" class="main w-full h-full flex justify-center items-center">
				<p class="text-gray-400 font-bold">対象データがありません</p>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import * as utils from '@libs/utils'

const COLUMNS1 = [
				{title: '見積りNo', class: ''},
				{title: '担当者', class: ''},
				{title: '得意先', class: ''},
				{title: '得意先住所', class: ''},
				{title: '物件名', class: ''},
				{title: '物件住所', class: ''},
				{title: '依頼日', class: 'text-center'},
				{title: '承認 \r\n 申請日', class: 'text-center'},
				{title: '承認日', class: 'text-center'},
				{title: '提出日', class: 'text-center'},
				{title: '目安 \r\n 日数', class: 'text-center'},
				{title: '実績 \r\n 日数', class: 'text-center'},
				{title: '税抜金額', class: 'text-right pr-2'},
				{title: '見積り\r\n 総件数', class: ''},
			]

const COLUMNS2 = [
				{title: 'お問い合わせNo \r\n (メインタスク)', class: 'text-center'},
				{title: '見積りNo', class: ''},
				{title: '担当者', class: ''},
				{title: '区分', class: ''},
				{title: '物件名', class: ''},
				{title: '承認日', class: 'text-center'},
				{title: '税抜金額', class: 'text-right pr-2'},
			]
export default {
	components: {
		Modal,
		PrimaryButton,
		Icon
	},

	props: ['target', 'department', 'departmentName', 'type', 'typeName', 'open'],

	data() {
		return {
			// カラム
			columns: [],
			// 表示データ
			list: [],
			// 実績日数平均
			ave: null,
			// スタッフごとの情報
			detailByStaff: [],
			// ローディング中
			loading: false
		}
	},

	computed: {
	},

	watch: {
		async target() {
			await this.getList()
		},

		// open() {
		// 	this.getList()
		// }
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.$emit('closeCountingDetailShow')
		},

		/**
		 * データ取得
		 */
		async getList() {
			if (!this.open) {
				return
			}
			// カラム設定
			this.columns = utils.clone(COLUMNS1)
			// 総数
			if (this.type == 4) {
				this.columns = utils.clone(COLUMNS2)
			}
			this.list = []
			this.loading = true
			const param = { department: this.department, type: this.type, sMonth: this.target.sMonth, eMonth: this.target.eMonth }
			let result = await backend.searchData('estimateApproved/getByPeriod', param)
			if (result.data.data) {
				this.list = result.data.data
				// 平均など情報を取得
				this.getDetail()
			}
			this.loading = false
		},

		/**
		 * 詳細画面を開く
		 */
		toEstimateView(estimateNo) {
			if (estimateNo) {
				let resolvedRoute = this.$router.resolve({ 
					name: 'EstimateView',
					query: {
						estimateNo
					}
				})
				window.open(resolvedRoute.href, '_blank')
			}
		},

		/**
		 * 日付フォーマット
		 * @param val 対象日
		 */
		formatDate(val) {
			if (val) {
				return moment(val).format('YYYY/MM/DD')
			}
			return val
		},

		/**
		 * 平均などの情報を取得
		 */
		getDetail() {
			if (!this.list || !this.list.length) {
				return
			}

			this.ave = null
			this.detailByStaff = []


			// 総数
			if (this.type == 4) {
				// スタッフごと
				let staff = []
				// スタッフごと＆区分ごと
				let staffType = []

				this.list.forEach((l) => {
					if (!staff.includes(l.staff)) {
						// 平均金額（小数は四捨五入）
						let avePrice = null
						avePrice = Math.round(Number(l.averagePriceStaff)).toLocaleString()
						// 合計金額
						let price = null
						price = Number(l.priceStaff).toLocaleString()

						this.detailByStaff.push({ name: l.staff, count: Number(l.countStaff), avePrice, price })
						staff.push(l.staff)
					}

					// スタッフ＆区分ごとの件数
					if (!staffType.includes(l.staff + l.type)) {
						for (let i = 0; i < this.detailByStaff.length; i++) {
							const d = this.detailByStaff[i]
							if (d.name == l.staff) {
								if (l.typeName == '新規物件') {
									d.countNew = Number(l.countStaffType)
								} else if (l.typeName == '追加変更') {
									d.countAdd = Number(l.countStaffType)
								} else if (l.typeName == 'スポット') {
									d.countSpot = Number(l.countStaffType)
								}
							}
						}
						staffType.push(l.staff + l.type)
					}
				})
			// 総数以外
			} else {
				// 全体の平均実績日数
				if (this.list && this.list[0].average && !isNaN(this.list[0].average)) {
					this.ave = Number(this.list[0].average)
					// 表示は少数第二までにする
					this.ave = Math.floor(this.ave * 100) / 100
				}

				// スタッフごと
				let staff = []
				this.list.forEach((l) => {
					if (!staff.includes(l.staff)) {
						let ave = Number(l.averageStaff)
						// 表示は少数第二までにする
						ave = Math.floor(ave * 100) / 100
						// 平均金額（小数は四捨五入）
						let avePrice = null
						avePrice = Math.round(Number(l.averagePriceStaff)).toLocaleString()
						// 合計金額
						let price = null
						price = Number(l.priceStaff).toLocaleString()

						this.detailByStaff.push({ name: l.staff, ave, count: Number(l.countStaff), avePrice, price })
						staff.push(l.staff)
					}
				})
			}
		},

		/**
		 * エクセルダウンロード
		 */
		async download() {
			this.loading = true
			const month = utils.daySt(this.target.sMonth, 'YYYY年M月')
			const param = { list: this.list, ave: this.ave, detailByStaff: this.detailByStaff, month, departmentName: this.departmentName, typeName: this.typeName, type: this.type }
			const res = await backend.postBlob2('estimateCountingDownload/detail', param)

			try {
				const uint8Array = res.data
				const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				const a = document.createElement("a");
				a.href = (window.URL || window.webkitURL).createObjectURL(blob)
				a.download = '見積集計_' + this.departmentName + '_' + this.typeName + '_' + month + '.xlsx'
				a.click();
				a.remove();
			} catch (e) {
				console.log(e)
				alert(e)
				this.loading = false
			}
			this.loading = false
		}
	}
}
</script>
<style scoped>
.main-height1 {
	max-height: 66vh;
}

.main-height2 {
	max-height: 70vh;
}

.main {
	min-width: 700px;
}

.col-no {
	width: 70px;  
}

.min-300 {
	min-width: 300px;
}

.min-70 {
	min-width: 70px;
}

.b-top {
	border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
	background: #ebf3fb;
}

.hover-color:hover {
	background: rgba(183, 238, 236, 0.67);
	cursor: pointer;
}

.mt-27px {
	margin-top: 27px;
}

.mb-5px {
	margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
	.main {
		overflow: auto;
	}
}
</style>