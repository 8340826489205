import FixedCleanApp from './FixedCleanApp.vue'
import FixedPatrolInspection from './pages/FixedPatrolInspection.vue'
import FixedPatrolTerm from './pages/FixedPatrolTerm.vue'
import FixedUnPatrolList from './pages/FixedUnPatrolList.vue'
import FixedScheduleListByStaff from './pages/FixedScheduleListByStaff.vue'
import FixedRidge from './pages/FixedRidge.vue'
import FixedPoster from './pages/FixedPoster.vue'
import FixedCheckList from './pages/FixedCheckList.vue'
import FixedCheckListTable from './pages/FixedCheckListTable.vue'
// import CountUnPatrolByClient from './pages/CountUnPatrolByClient.vue'

export default {
  path: '/FixedClean',
  component: FixedCleanApp,
  children: [
    {
      path: '/FixedPatrolInspection',
      name: 'FixedPatrolInspection',
      component: FixedPatrolInspection,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書項目設定' },
      props: true
    },
    {
      path: '/FixedPatrolTerm',
      name: 'FixedPatrolTerm',
      component: FixedPatrolTerm,
      meta: { requiresAuth: true, title: 'apollo-RP - 期間設定' },
      props: true
    },
    {
      path: '/FixedUnPatrolList',
      name: 'FixedUnPatrolList',
      component: FixedUnPatrolList,
      meta: { requiresAuth: true, title: 'apollo-RP - 未完了一覧' },
      props: true
    },
    {
      path: '/FixedScheduleListByStaff',
      name: 'FixedScheduleListByStaff',
      component: FixedScheduleListByStaff,
      meta: { requiresAuth: true, title: 'apollo-RP - 予定一覧' },
      props: true
    },
    {
      path: '/FixedRidge',
      name: 'FixedRidge',
      component: FixedRidge,
      meta: { requiresAuth: true, title: 'apollo-RP - 棟設定' },
      props: true
    },
    {
      path: '/FixedPoster',
      name: 'FixedPoster',
      component: FixedPoster,
      meta: { requiresAuth: true, title: 'apollo-RP - 張り紙' },
      props: true
    },
    {
      path: '/FixedCheckList',
      name: 'FixedCheckList',
      component: FixedCheckList,
      meta: { requiresAuth: true, title: 'apollo-RP - 完了一覧' },
      props: true
    },
    {
      path: '/FixedCheckListTable',
      name: 'FixedCheckListTable',
      component: FixedCheckListTable,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書' },
      props: true
    },
    // {
    //   path: '/CountUnPatrolByClient',
    //   name: 'CountUnPatrolByClient',
    //   component: CountUnPatrolByClient,
    //   meta: { requiresAuth: true, title: 'apollo-RP - 得意先毎の未点検' },
    //   props: true
    // }
  ]
}
