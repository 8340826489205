<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div v-if="downloading" class="flex justify-center items-center loading-dialog2">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Downloading...</p>
    </div>
      <template #action>
        <div class="content-container-app">
          <div v-if="!loading" class="">
            <div class="flex justify-between mb-5">
              <div>
                <!-- 得意先名 -->
                <span class="text-lg font-bold text-indigo-700">{{list.clientName1}}</span>
                
                <!-- 支店名 -->
                <span v-if="list.clientName2" class="text-gray-500 text-sm">
                  （{{list.clientName2}}）
                </span>

                <!-- 物件名 -->
                <div class="font-bold">
                  {{list.siteName}}
                  <span v-if="list.ridgeCode" class="text-red-700">（{{ list.ridgeName }}）</span>
                </div>
              </div>
              <div v-if="list.insertDate" class="text-gray-500 text-xs">
                {{list.insertDate}} データ保存
              </div>
            </div>

            <div class="hidden">{{reload}}</div>

            <!-- 大京アステージの場合のみ -->
            <div v-if="list.posterType == 2" class="mb-2 mx-auto w-64">
              <PrimaryButton text="お知らせ" size="sm" class="mr-2" :class="downloadType==1?'':'bg-gray-400'" @click="changeDownloadType(1)"/>
              <PrimaryButton text="中 止" size="sm" class="mr-2" :class="downloadType==2?'':'bg-gray-400'" @click="changeDownloadType(2)"/>
              <PrimaryButton text="変 更" size="sm" class="" :class="downloadType==3?'':'bg-gray-400'" @click="changeDownloadType(3)"/>
            </div>

            <div class="flex-1 border border-gray-300 rounded height-full overflow-auto p-2 mb-3">
              <div v-if="list.posterType!=2||downloadType==1">
                <!-- 作業名 得意先が日商管理以外 -->
                <div v-if="list.posterType != 7">
                  <div class="text-blue-600 font-bold">タイトル</div>
                  <div>
                    <WmsSuggestInput
                      name="name"
                      :embed="true"
                      :clearable="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="nameList"
                      v-model="list.cleanName"
                    />
                  </div>
                </div>

                <!-- 作業内容 得意先が三井の場合 -->
                <div class="mt-6" v-if="[4].includes(list.posterType)">
                  <div class="text-blue-600 font-bold">作業名</div>
                  <WmsSuggestInput
                    name="name"
                    :embed="true"
                    :clearable="true"
                    :dynamic="false"
                    displayField="name"
                    :incrementalSearch="false"
                    :selectionItems="detailsList"
                    v-model="list.details"
                  />
                </div>

                <!-- 日時 -->
                <div v-for="(d, idx) in list.days" :key="idx" class="flex mt-6">
                  <div v-if="d.ridgeName" class="ml-2 mr-7 mt-6 text-red-700 font-bold">
                    {{d.ridgeName}}
                  </div>
                  <div class="ml-2 mr-7 mt-6 text-blue-600 font-bold">
                    {{d.date}}
                  </div>
                  <div>
                    <WmsTimeInput2
                      name="startTime" 
                      caption="開始時間"
                      :clearable="true"
                      v-model="d.startTime"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                    />
                  </div>
                  <div class="mt-6 mx-2 font-bold text-xl">
                    ～
                  </div>
                  <div>
                    <WmsTimeInput2
                      name="endTime" 
                      caption="終了時間"
                      :clearable="true"
                      v-model="d.endTime"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                    />
                  </div>
                </div>

                <!-- 作業箇所 得意先が日商管理以外 -->
                <div class="mt-6" v-if="list.posterType != 7">
                  <div class="text-blue-600 font-bold">作業箇所</div>
                  <WmsTextInput 
                    name="area" 
                    :multiline="true"
                    :rows="3"
                    class="text-gray-700"
                    v-model="list.area"
                  />
                  <!-- 作業箇所の選択肢 -->
                  <div class="flex flex-wrap">
                    <div v-for="(a, k) in areaList" :key="k">
                      <div class="rounded bg-blue-700 text-white text-xs py-1 px-3 mx-3 mt-2 text-center flex-1 cursor-pointer w-115px" @click="addArea(a)">
                        {{a}}
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- 注意事項 得意先が三井の場合 -->
                <div class="mt-6" v-if="list.posterType == 4">
                  <div class="text-blue-600 font-bold flex w-full mb-2 cursor-pointer" @click="add()">
                    <div class="m-auto w-full">
                      お願い事項
                    </div>
                    <div>
                      <PlusCircleIcon class="text-blue-500 w-8 h-8 ml-3 hover:text-blue-600"/>
                    </div>
                  </div>
                  <div v-for="(n, j) in list.note" :key="j" class="flex w-full mb-2">
                    <WmsSuggestInput
                      name="name"
                      :embed="true"
                      :clearable="true"
                      :dynamic="false"
                      displayField="name"
                      :incrementalSearch="false"
                      :selectionItems="noteList"
                      v-model="list.note[j]"
                      class="w-full"
                    />
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5 my-auto ml-2" @click="deleteNote(j)" />
                  </div>
                </div>

                <!-- 作業内容 得意先が野村の場合 -->
                <div class="mt-6" v-if="[8].includes(list.posterType)">
                  <div class="text-blue-600 font-bold">作業内容</div>
                  <WmsTextInput 
                    name="details" 
                    class="text-gray-700"
                    v-model="list.details"
                  />
                </div>
              </div>
              <div v-else-if="list.posterType==2&&downloadType==2">
                <div class="mt-6">
                  <div class="text-blue-600 font-bold">中止のお知らせ</div>
                  <WmsTextInput 
                    name="msg1" 
                    :multiline="true"
                    :rows="8"
                    class="text-gray-700"
                    v-model="list.msg1"
                  />
                </div>
              </div>
              <div v-else-if="list.posterType==2&&downloadType==3">
                <div class="mt-6">
                  <div class="text-blue-600 font-bold">変更のお知らせ</div>
                  <WmsTextInput 
                    name="msg2" 
                    :multiline="true"
                    :rows="8"
                    class="text-gray-700"
                    v-model="list.msg2"
                  />
                </div>
              </div>

              <!-- 掲載期間 得意先が大京アステージの場合 -->
              <div class="mt-6" v-if="[2].includes(list.posterType)">
                <div class="text-blue-600 font-bold">掲示期間</div>
                <div class="flex">
                  <WmsDateInput
                    name="date"
                    v-model="list.publish1"
                    :min="minDate"
                  />
                  <div class="font-bold mx-2 my-auto">～</div>
                  <WmsDateInput
                    name="date"
                    v-model="list.publish2"
                    :min="minDate"
                  />
                </div>
              </div>
            </div>
            
            <div>
              <PrimaryButton text="ダウンロード" size="normal" class="w-full mb-3" @click="download()"/>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
  import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/solid'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'
import * as moment from 'moment'

export default {
  components: {
    Modal,
    PrimaryButton,
    WmsSuggestInput,
    WmsTimeInput2,
    WmsTextInput,
    WmsDateInput,
    TrashIcon,
    PlusCircleIcon,
  },

  props: {
    // 対象データ（棟ごと・1行ごと）
    target: {
      type: Object
    },
    // 対象データ（棟をまとめて）
    target2: {
      type: Array
    },
    // 1行ごとかfalse、棟をまとめるかtrue
    bySite: {
      type: Boolean,
      default: false
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // データ取得中
      loading: false,
      // ダウンロード中
      downloading: false,
      // 表示データ
      list: {},
      // 作業名リスト
      nameList: [
        {name: '定期清掃'},
        {name: '特別清掃'},
        {name: '定期特別清掃'}
      ],
      // 作業箇所リスト
      areaList: [
        'エントランス',
        '共用廊下',
        '共用階段',
        '管理員室',
        'エレベーター床面',
        '正面玄関アプローチ',
        'エントランスガラス',
        'ドレン',
        '共用部各所',
        '他',
      ],
      // 三井の作業名
      detailsList: [
        {name: '定期清掃'},
        {name: '灯具清掃'},
        {name: '定期清掃・灯具清掃'},
      ],
      // 三井のお願い事項
      noteList: [
        {name: '作業中は、足元が滑りやすくなったり、つまずきやすくなったりする場合がありますので、ご通行の際はご注意ください。'},
        {name: '作業音等で、ご迷惑をおかけする場合がございます。'},
        {name: '共用廊下階段等には、自転車等の私物を置かれませんようご協力をお願いいたします。'},
        {name: '私物が置かれている場所は、清掃が実施できない場合がありますのでご了承いただきますようお願いいたします。'},
        {name: 'ポーチ内の私物は室内に保管していただきますようお願いいたします。'},
        {name: '廊下等で脚立を使用して作業をいたしますので、通行の際はご注意ください。'},
        {name: '共用扉開閉の際は、扉の反対側で作業をしている場合がございますのでご注意ください。'},
        {name: '照明を一部消灯して実施する場合がございますのでご了承いただきますようお願いいたします。'}
      ],
      // 時間
      hourItems: [
        { text: '5' },
        { text: '6' },
        { text: '7' },
        { text: '8' },
        { text: '9' },
        { text: '10' },
        { text: '11' },
        { text: '12' },
        { text: '13' },
        { text: '14' },
        { text: '15' },
        { text: '16' },
        { text: '17' },
        { text: '18' },
        { text: '19' },
        { text: '20' },
        { text: '21' },
        { text: '22' },
        { text: '23' }
      ],
      // 分
      minuteItems: [
        { text: '00' },
        { text: '15' },
        { text: '30' },
        { text: '45' }
      ],
      // 画面即時反映
      reload: 0,
      // 掲載開始日
      minDate: '',
      // ダウンロードするタイプ
      downloadType: 1
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getTarget()
      } else {
        return
      }
    },
    
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.list = {}
      let idList = []
      this.loading = true
      this.minDate = moment().format('YYYY-MM-DD')
      if (this.target && (Object.keys(this.target).length || this.target2.length)) {
        let info = {}
        // ダウンロード1行ごとの場合
        if (!this.bySite) {
          info = this.target
        // ダウンロード棟をまとめての場合
        } else {
          info = this.target2[0]

          this.target2.forEach((t) => {
            idList.push(t.id)
          })
        }

        this.list = {
          patrolScheduleId: info.id,
          clientCode: info.clientCode,
          siteCode: info.siteCode,
          clientName1: info.clientName1,
          clientName2: info.clientName2,
          siteName: info.siteName,
          staff: info.staff,

          // 得意先名から張り紙タイプを判断
          posterType: utils.getPosterType(info.clientName1)
        }
        if (!this.bySite) {
          this.list.ridgeCode = info.ridgeCode
          this.list.ridgeName = info.ridgeName
        }
        // 過去に保存した張り紙データ
        let posterData = []

        const saveData = await backend.searchData('patrolPoster/getByScheduleId', { id: info.id, bySite: this.bySite, idList: idList.join(',') })
        if (saveData.data && saveData.data.data && saveData.data.data.length) {
          posterData = saveData.data.data
        }
        if (posterData.length) {
          // 過去に保存されたデータと今の予定で日付が変わっている場合は、日付更新
          let days = []
          let scheduleDay = []
          let tar = []
          if (!this.bySite) {
            tar.push(this.target)
          } else {
            tar = utils.clone(this.target2)
          }

          for (let i = 0; i < tar.length; i++) {
            const ta = tar[i]
            let row = { id: ta.id, date: ta.main }
            // 棟がある場合、棟名をセット（ダウンロード棟をまとめて）
            if (this.bySite) {
              row.ridgeName = ta.ridgeName
            }
            scheduleDay.push(row)

            if (ta.sub) {
              for (let j = 0; j < ta.sub.length; j++) {
                const su = ta.sub[j]
                let subRow = { id: ta.id, date: su }
                
                if (this.bySite) {
                  subRow.ridgeName = ta.ridgeName
                }
                scheduleDay.push(subRow)
              }
            }
          }

          for (let i = 0; i < scheduleDay.length; i++) {
            const sc = scheduleDay[i]
            let flag = false
            for (let j = 0; j < posterData.length; j++) {
              const po = posterData[j]
              if (po.patrolScheduleId == sc.id && po.day == sc.date) {
                days.push({ patrolScheduleId: po.patrolScheduleId, date: po.day, startTime: po.startTime, endTime: po.endTime, ridgeName: sc.ridgeName })
                flag = true
              }
            }
            // スケジュールに変更があった場合
            if (!flag) {
              days.push({ patrolScheduleId: sc.id, date: sc.date, startTime: '', endTime: '', ridgeName: sc.ridgeName })
            }
          }

          let save = {}
          for (let k = 0; k < posterData.length; k++) {
            const pos = posterData[k]
            if (pos.cleanName) {
              save = pos
              break
            }
          }
          this.list.cleanName = save.cleanName
          this.list.days = days
          this.list.area = save.area
          this.list.details = save.details
          this.list.insertDate = save.insertDate
          this.list.msg1 = save.msg1
          this.list.msg2 = save.msg2
          this.list.publish1 = save.publish1
          this.list.publish2 = save.publish2

          // 三井不動産の場合
          if (this.list.posterType == 4) {
            this.list.note = []
            if (save.note) {
              this.list.note = save.note.split('@@@')
            }
          }
          
          // １行ごとなら1行ごとのデータ、棟をまとめてなら棟をまとめてのデータ自体が保存されていない場合
          if (!save.cleanName) {
            this.setInitData()
            this.list.insertDate = null
          }
        // 過去に保存したデータがない場合
        } else {
          let days = []
          // ダウンロード1行ごとの場合
          if (!this.bySite) {
            // 初日の作業日
            days.push({ patrolScheduleId: this.target.id, date: this.target.main, startTime: '', endTime: '' })
            // 複数日であれば、連結
            if (this.target.sub) {
              this.target.sub.forEach(s => {
                days.push({ patrolScheduleId: this.target.id, date: s, startTime: '', endTime: '' })
              })
            }

          // ダウンロード棟をまとめての場合
          } else {
            for (let i = 0; i < this.target2.length; i++) {
              const t = this.target2[i]
              // 初日の作業日
              days.push({ patrolScheduleId: t.id, ridgeName: t.ridgeName, date: t.main, startTime: '', endTime: '' })
              // 複数日であれば、連結
              if (t.sub) {
                t.sub.forEach(s => {
                  days.push({ patrolScheduleId: t.id, ridgeName: t.ridgeName, date: s, startTime: '', endTime: '' })
                })
              }
            }

          }
          // 初期値セット
          this.list.days = days
          this.setInitData()
        }
        
      } else {
        return
      }
      this.loading = false
    },

    /**
     * 初期値セット
     */
    setInitData() {
      this.list.cleanName = '定期清掃'
      this.list.area = 'エントランス、共用廊下、共用階段、他'

      // 大京アステージ
      if (this.list.posterType == 2) {
        this.list.details = '共用部分床面清掃'
        this.list.area = 'エントランス、アプローチ、共用廊下、共用階段、エレベーター、エントランスガラス…等'
      
      // 三井の場合
      } else if (this.list.posterType == 4) {
        this.list.cleanName = '定期特別清掃'
        this.list.details = '定期清掃'
        this.list.note = []
        for (let i = 0; i < 5; i++) {
          this.list.note.push(this.noteList[i].name)
        }

      // 三菱地所
      } else if (this.list.posterType == 6) {
        this.list.area = '共用部各所 (ｴﾝﾄﾗﾝｽ、廊下、階段、ﾄﾞﾚﾝ他)'

      // 野村不動産の場合
      } else if (this.list.posterType == 8) {
        this.list.details = '床面機械洗浄'
        this.list.area = 'エントランス、共用廊下、共用階段、管理員室、エレベーター床面、正面玄関アプローチ'

      // ケイズの場合
      } else if (this.list.posterType == 9) {
        this.list.area = '共用部各所'
      }
    },

    /**
     * 作業箇所ボタン
     * @param value 作業箇所
     */
    addArea(value) {
      let txt = ''
      if (this.list.area) {
        txt = '、'
      }
      txt += value
      this.list.area += txt
      this.reload++
    },

    /**
     * 注意事項追加ボタン
     */
    add() {
      if (!this.list.note) {
        this.list.note = []
      }
      this.list.note.push('')
      this.reload++
    },

    /**
     * 注意事項削除ボタン
     */
    deleteNote(i) {
      this.list.note.splice(i, 1)
      if (!this.list.note.length) {
        this.add()
      }
      this.reload++
    },

    /**
     * 入力チェック
     */
    async validate() {
      let d = this.list

      if (this.list.posterType == 2 && this.downloadType == 2) {
        if (this.list.msg1.indexOf('●') > -1) {
          await dialogs.showErrorDialog('理由を記入してください', '●が残っています。')
          return false
        }
      } else if (this.list.posterType == 2 && this.downloadType == 3) {
        if (this.list.msg2.indexOf('年月日') > -1) {
          await dialogs.showErrorDialog('日付を記入してください', '2行目に年月日と曜日を入力してください。')
          return false
        }
      } else {
        if (!d.cleanName) {
          await dialogs.showErrorDialog('作業名', '作業名を入力してください。')
          return false
        }
        for (let i = 0; i < d.days.length; i++) {
          const v = d.days[i]
          if (!v.startTime) {
            await dialogs.showErrorDialog(`${v.date}の開始時間`, '開始時間を入力してください。')
            return false
          }
          if (!v.endTime) {
            await dialogs.showErrorDialog(`${v.date}の終了時間`, '終了時間を入力してください。')
            return false
          }
          const s = v.startTime.replace(':', '')
          const e = v.endTime.replace(':', '')
          if (!s) {
            await dialogs.showErrorDialog(`${v.date}の開始時間`, '開始時間を入力してください。')
            return false
          }
          if (!e) {
            await dialogs.showErrorDialog(`${v.date}の終了時間`, '終了時間を入力してください。')
            return false
          }
          if (Number(s) >= Number(e)) {
            await dialogs.showErrorDialog(`${v.date}の時間が不正です`, '終了時間が開始時間より後になるように時間を入力して下さい。')
            return false
          }
        }
        if (!d.area) {
          await dialogs.showErrorDialog('作業箇所', '作業箇所を入力してください。')
          return false
        }

        // 三井不動産
        if (d.posterType == 4) {  
          let note = []
          for (let j = 0; j < d.note.length; j++) {
            const n = d.note[j]
            if (n) {
              note.push(n)
            }
          }
          if (!note.length) {
            await dialogs.showErrorDialog('お願い事項', 'お願い事項を入力してください。')
            return false
          }
        }

        if ([2, 8].includes(d.posterType)) {
          if (!d.details) {
            await dialogs.showErrorDialog('作業内容', '作業内容を入力してください。')
            return false
          }
        }
      }

      // 大京アステージ
      if (d.posterType == 2) {
        const today = Number(moment().format('YYYYMMDD'))
        const publish1 = Number(moment(d.publish1).format('YYYYMMDD'))
        const publish2 = Number(moment(d.publish2).format('YYYYMMDD'))
        if (!d.publish1) {
          await dialogs.showErrorDialog('掲載開始日', '掲載開始日を選択してください。')
          return false
        } else if (today > publish1) {
          const res = await dialogs.showConfirmDialog('掲載開始日', '掲載開始日が過去日になっていますが、よろしいでしょうか？')
          if (res != 'YES') {
            return false
          }
        }
        if (!d.publish2) {
          await dialogs.showErrorDialog('掲載終了日', '掲載終了日を選択してください。')
          return false
        } else if (publish1 > publish2) {
          await dialogs.showErrorDialog('掲載日', '掲載開始日が終了日よりも未来になっています。')
          return false
        }
      }

      return true
    },

    /**
     * ダウンロード ボタン
     */
    async download() {
      if (!await this.validate()) {
        return
      }
      const dlog = await dialogs.showConfirmDialog('入力を保存しますか？', '入力している内容を保存しますか？', 'はい', 'いいえ')
      if (dlog == 'YES') {
        // 入力値を保存
        // 棟ごとの場合は、日付と時間以外を保存し、日付と時間は、それぞれの棟ごとのデータにアップデートする
        backend.postData('patrolPoster/save', { ...this.list, insertUser: this.$store.getters.user.id, bySite: this.bySite })

        //ログ出力
        let update = '新規'
        if (this.list.insertDate) {
          update = '更新'
        }
        logManager.recording(this, logManager.Loglevel.INFO, this.typeName, update, this.typeName + '（スケジュールID：'+ this.list.patrolScheduleId  + '、得意先：'+ this.list.clientName1 + '、物件名：'+ this.list.siteName + '、予定日：' + this.list.main + '）を保存しました。')
      }

      // ダウンロード実装
      this.downloading = true
      let res

      res = await backend.postBlob('patrolDownload/fixedPoster', { ...this.list, downloadType: this.downloadType })

      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = ''
        if (this.list.clientName1) {
          name = this.list.clientName1.replace('株式会社', '')
        }
        if (this.list.siteName) {
          let r = ''
          if (this.list.ridgeCode) {
            r = '_' + this.list.ridgeName
          }
          name += '様【' + this.list.siteName + r + '】張り紙'
        }
        a.download = name + moment().format('YYYYMMDD') + '.xlsx'
        a.click();
        a.remove();
        this.downloading = false
      } catch (e) {
        console.log(e)
        this.downloading = false
      }
      let r = ''
      if (this.list.ridgeCode) {
        r = '_' + this.list.ridgeName
      }
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, 'ダウンロード', this.typeName + '（スケジュールID：'+ this.list.patrolScheduleId  + '、得意先：'+ this.list.clientName1 + '、物件名：'+ this.list.siteName + r + '、予定日：' + this.list.main + '）の張り紙をダウンロードしました。')
    },

    /**
     * 初期化
     */
    closed() {
      // 表示データ
      this.list = {
        cleanName: ''
      },
      this.downloadType = 1
      this.$emit('modalClose')
    },

    /**
     * ダウンロードするタイプを変更
     */
    changeDownloadType(val) {
      this.downloadType = val
      if (val == 2 && !this.list.msg1) {
        this.list.msg1 = 
          '日頃から当社の管理業務に対しましては、ご理解とご協力を賜り厚く御礼申し上げます。\r\n'
        + 'さて、本日予定しておりました定期清掃ですが、●●により中止させていただくこととなりました。\r\n'
        + 'なお、本日の清掃につきましては延期とし、実施日はまた別途お知らせ致しますので、ご協力の程、何卒宜しくお願い申し上げます。'
      } else if (val == 3 && !this.list.msg2) {
        this.list.msg2 =
          '日頃から当社の管理業務に対しましては、ご理解とご協力を賜り厚く御礼申し上げます。\r\n'
        + 'さて、年月日（）に予定しておりました定期清掃ですが、管理組合様のご要望により実施日を変更させていただくこととなりました。\r\n'
        + 'なお、実施日につきましては、また別途お知らせ致しますので、ご協力の程、何卒宜しくお願い申し上げます。\r\n'
      }
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  max-height: calc(100vh - 130px);
}

.height-full {
  height: calc(100vh - 300px);
}

@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    max-height: calc(100vh - 90px);
  }

}
</style>