
<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        
        <div class="m-10 ">
          <div v-if="loading" class="flex justify-center items-center loading-dialog">
            <p class="text-gray-400 font-bold animate-pulse" v-if="loading">Loading...</p>
          </div>

          <div class="mb-5">
            <div class="flex justify-between w-1280px mb-5 max-h-85vh">
              <!-- メール -->
              <div class="w-47per">
                <!-- 発注先名 -->
                <div class="text-lg font-bold text-blue-600 mb-5">
                  {{ row.supplier }}
                </div>
                <!-- 発注先アドレス -->
                <div class="mb-5">
                  <label class="block text-xs font-bold text-gray-700 mb-1">
                    メールアドレス
                  </label>
                  <div v-for="(m, j) in row.mail" :key="j" class="text-sm">
                    {{ m }}
                  </div>
                </div>
                <!-- 件名 -->
                <div class="mb-3">
                  <label class="block text-xs font-bold text-gray-700 mb-1">
                    件名
                  </label>
                  <div class="text-sm mb-5">
                    {{ row.title }}
                  </div>
                </div>
                <!-- 本文 -->
                <div class="mb-3">
                  <label class="block text-xs font-bold mb-1 mt-auto">
                    本文
                  </label>
                  <div class="h-60vh text-sm overflow-auto border border-gray-300">
                    <div v-html="row.content" class="p-3"></div>
                  </div>
                  
                </div>
              </div>

              <div class="w-47per p-2 border border-gray-200 rounded overflow-auto h-80vh">
                <PurchaseTemp
                  :purchase="row"
                  :info="info"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PurchaseTemp from '../components/PurchaseTemp.vue'
import * as purchaseManager from '@managers/purchaseManager'
import * as utils from '@libs/utils'


export default {
  components: {
    Modal,
    PurchaseTemp
  },

  props: {
    historyNo: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 送信内容
      row: {},
      // 基本情報
      info: {},
      // ローディング中
      loading: false
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (!this.open) {
        return
      }
      this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.loading = true
      this.row = {}
      this.info = {}
      // 発注Noに紐づく履歴を取得
      const res = await purchaseManager.getHistoryByHistoryNo(this, this.historyNo)
      if (res) {
        this.info = {
          site: res.site,
          siteAddress: res.site,
          client: res.client,
          staffId: res.staffId,
          staff: res.staff,
          orderNo: res.orderNo,
          sendDate: res.sendDate
        }

        let copy = utils.clone(res)
        delete copy.site
        delete copy.siteAddress
        delete copy.client
        delete copy.staffId
        delete copy.staff
        delete copy.orderNo
        delete copy.sendDate
        delete copy.type
        delete copy.user
        delete copy.permalink
        delete copy.status
        delete copy.createDate
        delete copy.upDate

        this.row = {
          ...copy
        }
      }
      this.loading = false
    },

    /**
     * 初期化
     */
    closed() {
      // 送信内容
      this.row = {}
      // 基本情報
      this.info = {}
      // ローディング中
      this.loading = false
      this.$emit('modalClose')
    },

  }
}
</script>
<style scoped>
</style>