<template>
  <div class="bg-white rounded-md overflow-auto h-full">
    <table class="main table-auto overflow-auto">
      <thead class="main sticky top-0 t-thead">
        <tr v-if="filteredData && filteredData.length" class="border border-gray-200">
          <th v-for="(col, idx) in columns" :key="idx" scope="col" class="px-6 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="col.class" @click="sortSwitching(col)">
            {{col.title}}
            <Icon v-if="col.canSort" :iconName="col.sortIcon" class="w-3 h-3 mr-0.5 inline"/>
          </th>
        </tr>
      </thead>

      <tr v-for="(row, idx) in filteredData" :key="idx" class="tracking-wider hover-color" v-tooltip="row.status=='remove'?'取消されています':''">
        <!-- 期限 -->
        <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 w-44">
          {{ getLimit(row) }}
        </td>
        <!-- タイトル -->
        <td class="px-6 py-3 text-sm text-gray-700">
          <div class="truncate w-56">{{ row.inquiry_title }}</div>
        </td>
        <!-- 担当者 -->
        <td class="px-6 py-3 text-sm text-gray-700" >
          <div class="truncate w-24">{{ row.toStaffName }}</div>
        </td>
        <!-- お問い合わせカテゴリー -->
        <td class="px-6 py-3 text-sm text-gray-700">
          <div class="truncate w-36">{{ row.categoryString }}</div>
        </td>
        <!-- 詳細 -->
        <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700">
          <a :href="`${getDetailUrl(row)}`" class="text-blue-600 hover:text-blue-900">詳細</a>
        </td>
        <!-- 進捗 -->
        <td class="px-6 py-3 text-sm text-gray-700">
          <TaskStatusTracker :record="row" style="width: fit-content; min-width: 300px;" @toParent="notification"></TaskStatusTracker> 
        </td>
      </tr>
    </table>
    <DataSelectorModal
      v-model="dataSelectorShow"
      :title="dataSelectorParams.title"
      :subtitle="dataSelectorParams.subtitle"
      :options="dataSelectorParams.options"
      :selectCallback="dataSelectorParams.selectCallback"
    />
  </div>
</template>

<script>
import * as requestPostManager from '@managers/requestPostManager'
import TaskStatusTracker from './TaskStatusTracker.vue'
import * as utils from '@libs/utils'
import DataSelectorModal from '@components/DataSelectorModal.vue'
import { PRIVATE_REQUEST_AUTH } from '@/config'
import Icon from '@components/Icon.vue'

export default {
  components: {
    DataSelectorModal,
    TaskStatusTracker,
    Icon
  },

  props: [ 
    'searchKeyword',
    'searchCategory',
    'searchToStaffName',
    'searchRequestNo',
    'searchInquiry_title',
    'searchLimitPeriod',
    'searchStatus',
    'searchMainStatus',
    'searchToDepartment',
    'period',
    'freeze',
    'category2Company',
    'category2Customer',
    'category2CustomerDirect',
    'mainTask',
    'orderTask',
    'employmentTask',
    'filterMainTaskUser',
    'filterSubTaskUser',
    'sortItem',
    'sortBy'
  ],

  data() {
    return {
      columns: [
                { name: 'limitDate', title: 'メインタスク期限', class: 'text-left th-limit_date', canSort: true, sortIcon: '' },
                { name: 'inquiry_title', title: 'タイトル', class: 'text-left th-inquiry_title', canSort: true, sortIcon: '' },
                { name: 'toStaffName', title: '担当者', class: 'text-left th-toStaff_name', canSort: true, sortIcon: '' },
                { name: 'categoryString', title: 'お問い合わせカテゴリー', class: 'text-left th-category_string', canSort: true, sortIcon: '' },
                { name: 'viewLink', fixedName: '詳細', title: '', class: 'text-left th-view_link', canSort: false, sortIcon: '' },
                { name: 'taskProgress', title: '進捗', type: 'taskStatusTracker th-task_progress', canSort: false, sortIcon: '' }
              ],
      filteredData: [],
      fetchDate: '',
      dataSelectorShow: false,
      dataSelectorParams: {
        title: '',
        subtitle: '',
        options: {
          formId: '',
          displayFields: [],
          sortField: '',
          selectable: true,
          filter: {}
        }
      },
      userName: '',
      // データが多すぎて表示できない場合
      overData: false
    }
  },

  created() {
    // ログインユーザー名を取得
    this.userName = this.$store.getters.user.user_name
    this.fetchRequestPosts()

    // 初期ソート
    if (this.sortItem) {
      let target = this.columns.find( col => col.name == this.sortItem )
      if (target) target.sortIcon = (this.sortBy == 1 ? 'ArrowNarrowUp' : 'ArrowNarrowDown')
    }
  },

  methods: {
    
    /**
     * データ取得
     */
    async fetchRequestPosts() {
      // let filterData = []
      if (!this.freeze) {
        this.$emit('search-start')
        this.fetchDate = utils.getToday()
        let key = []
        let loginUserName = ''
        let limitNum
        let titleTxt = ''
        let staffName = ''

        // キーワード検索を配列にする
        if (this.searchKeyword) {
          key = this.searchKeyword.split(/\s+/g)
        }

        // 人アイコン活性時、ログインユーザー名取得（カナ削除）
        if (this.filterSubTaskUser || this.filterMainTaskUser) {
          loginUserName = utils.deleteKana(this.userName)
        }

        // 本日までの期限
        if (this.period && this.period.end) {
          limitNum = Number(this.period.end.replace(/-/g, ''))
        }

        // タイトル（メイン）
        if (this.searchInquiry_title) {
          titleTxt = this.searchInquiry_title.replace(/（|\(|）|\)/g, '')
        }

        // 個別の担当者（メインorサブ）
        if (this.searchToStaffName) {
          staffName = this.searchToStaffName.replace(/（|\(|）|\)/g, '')
        }

        // メイン取得
        let main = []
        let mainRes = await requestPostManager.searchProgressMain(
          this,
          this.sortItem,
          this.sortBy,
          this.mainTask,
          this.orderTask,
          this.employmentTask,
          this.searchLimitPeriod,
          this.filterMainTaskUser,
          loginUserName,
          this.searchMainStatus,
          this.searchRequestNo
        )

        this.overData = false

        // エラーが発生した場合
        if (mainRes.error) {
          if (mainRes.msg) {
            if (mainRes.msg.indexOf('limit') > -1) {
              this.overData = true
            }
          }
          if (!this.overData) {
            alert('エラーが発生しました。\r\n' + mainRes.msg)
          }
        } else {
          main = mainRes
        }

        let mainIds = main.map((m) => {
          return m._id
        })

        // サブ取得
        let sub = await requestPostManager.searchProgressSub(
          this,
          mainIds,
          (this.mainTask || this.employmentTask),
          this.orderTask
        )

        // メインとサブを結合
        for (let i = 0; i < main.length; i++) {
          const m = main[i]
          
          for (let j = 0; j < sub.length; j++) {
            const s = sub[j]

            if ((this.mainTask || this.employmentTask) && m._id == s.requestPostId) {
              if (!m.taskRequest) {
                m.taskRequest = []
              }
              m.taskRequest.push(s)
            }
            if (this.orderTask && m._id == s.orderId) {
              if (!m.taskRequestOrder) {
                m.taskRequestOrder = []
              }
              m.taskRequestOrder.push(s)
            }
          }
        }

        // フィルター
        let res = []
        for (let k = 0; k < main.length; k++) {
          const m = main[k]
          // サブがあるもののみ
          if (m.taskRequest || m.taskRequestOrder) {
            // お問い合わせカテゴリーを文字列に変換
            if (m.category) {
              m.categoryString = m.category.join(',')
            } else {
              m.categoryString = ''
            }

            // 社内/お客様を文字列に変換
            if (m.category2) {
              m.category2String = m.category2.join(',')
            } else {
              m.category2String = ''
            }

            // 検索がある場合(キーワード検索、サブの人アイコン活性、本日まで、タイトル、個別の担当者、お問い合わせカテゴリー、サブの部署、カテゴリーが非公開)
            if (this.searchStatus || this.searchKeyword || this.filterSubTaskUser || (this.period && this.period.end) || this.searchInquiry_title || this.searchToStaffName || this.searchCategory || this.searchToDepartment || m.categoryString.indexOf('非公開') != -1) {

              // サブをまとめる
              let sub = []
              if (m.taskRequest) {
                sub = m.taskRequest
              } else if (m.taskRequestOrder) {
                sub = m.taskRequestOrder
              }
              // 検索
              if (this.KeyWord(m, key, sub, loginUserName, limitNum) && this.title(m, titleTxt) && this.staff(m, staffName, sub) && this.category(m) && this.isPrivate(m, sub)) {
                for (let l = 0; l < sub.length; l++) {
                  const s = sub[l]
                  if (this.subStaff(loginUserName, s) && this.statusOpen(s) && this.untilToday(s, limitNum) && this.department(s)) {
                    res.push(m)
                    break
                  }
                }
              }

            } else {
              res.push(m)
            }
          }
        }
        // 配列項目ソート対応
        if (this.sortItem == 'categoryString' || this.sortItem == 'category2String') {
          res = this.sortDataToArrayItem(res, this.sortItem, this.sortBy)
        }
        
        console.log(res.length)
        this.$emit('search-end', { res , overData: this.overData })
        this.filteredData = res
      }
    },

    /**
     * キーワード検索
     * @param m メイン
     * @param key 検索キーワード配列
     * @param sub サブ
     * @param loginUserName ログインユーザー名
     * @param limitNum 本日日付を数字に変換したもの
     */
    KeyWord(m, key, sub, loginUserName, limitNum) {
      if (this.searchKeyword) {
        // 「キーワード検索」対象プロパティ
        const mainKeys = ['category2String', 'toStaffName', 'toDepartment', 'categoryString', 'limitDate', 'inquiry_title', 'requestNo', 'interviewsKana', 'employmentKana', 'employmentBuildingName']
        const subKeys = ['toStaffName', 'departmentName', 'requestTask']
        for (let i = 0; i < key.length; i++) {
          const k = key[i]
          let flag = false
          for (let j = 0; j < mainKeys.length; j++) {
            const s = mainKeys[j]
            // メイン
            if (m[s] && m[s].indexOf(k) != -1) {
              flag = true
            }
          }
          // サブの項目をORで検索する場合
          if (!flag) {
            for (let n = 0; n < sub.length; n++) {
              const su = sub[n]
              // サブの検索対象プロパティー
              for (let l = 0; l < subKeys.length; l++) {
                const s2 = subKeys[l]
                if (su[s2].indexOf(k) != -1) {
                  // 以前、スクリプトで検索していた頃の結果と同じようにするため、個別検索以外のサブに関する検索をここで行う
                  if (this.subStaff(loginUserName, su) && this.statusOpen(su) && this.untilToday(su, limitNum)) {
                    flag = true
                  }
                }
              }
            }
          }
          // 複数のキーワードがある場合（スペース区切り）は、and検索のため、ヒットしないキーワードが一つでもあれば除外
          if (!flag) {
            return false
          }
        }
        return true
      } else {
        return true
      }
    },

    /**
     * 人アイコン（サブの担当者）
     * @param loginUserName ログインユーザー名
     * @param s サブ
     */
    subStaff(loginUserName, s) {
      if (this.filterSubTaskUser) {
        // 自分の担当と未定もヒット
        if (s.toStaffName.indexOf(loginUserName) != -1 || s.toStaffName == '未定') {
          return true
        }
        return false
      } else {
        return true
      }
    },

    /**
     * 時計アイコン 本日までの期限（サブタスク）検索
     * @param sub サブ
     */
    untilToday(sub, limitNum) {
      if (this.period && this.period.end) {
        const limit = Number(sub.limitDate.replace(/-/g, ''))
        if (limit <= limitNum) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    /**
     * 個別検索 タイトル（メイン）
     * @param m メイン
     * @param titleTxt 検索ワード
     */
    title(m, titleTxt) {
      if (this.searchInquiry_title) {
        // カッコを無視
        if (m.inquiry_title.replace(/（|\(|）|\)/g, '').indexOf(titleTxt) != -1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    /**
     * 個別検索 担当者（メインorサブ）
     * @param m メイン
     * @param staffName 検索ワード
     * @param sub サブ
     */
    staff(m, staffName, sub) {
      if (this.searchToStaffName) {
        if (m.toStaffName.replace(/（|\(|）|\)/g, '').indexOf(staffName) != -1) {
          return true
        }

        for (let i = 0; i < sub.length; i++) {
          const s = sub[i];
          if (s.toStaffName.indexOf(staffName) != -1) {
            return true
          }
        }
        return false

      } else {
        return true
      }
    },

    /**
     * 個別検索 お問い合わせカテゴリー
     * @param m メイン
     */
    category(m) {
      if (this.searchCategory) {
        if (m.categoryString.indexOf(this.searchCategory) != -1) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    /**
     * 非公開タスク
     * @param m 対象データ
     * @param sub サブ
     */
    isPrivate(m, sub) {
      if (m.categoryString.indexOf('非公開') != -1) {
        const uId = this.$store.getters.user.id
        // 権限がある人は無条件で表示
        if (PRIVATE_REQUEST_AUTH.includes(uId)) {
          return true
        }
        // メインの担当者、もしくは依頼者
        if (uId == m.toStaffId || uId == m.byUserId) {
          return true
        }

        // サブの担当者
        for (let i = 0; i < sub.length; i++) {
          const s = sub[i]
          if (uId == s.toStaffId) {
            return true
          }
        }
        return false

      } else {
        return true
      }
    },

    /**
     * 個別検索 部署（サブ）
     * @param s サブ
     */
    department(s) {
      if (this.searchToDepartment) {
        // for (let i = 0; i < sub.length; i++) {
          // const s = sub[i]
          if (s.departmentName.indexOf(this.searchToDepartment) != -1) {
            return true
          }
        // }
        return false
      } else {
        return true
      }
    },

    /**
     * 未完了（サブ）アイコン
     * @param sub サブ
     */
    statusOpen(sub) {
      if (this.searchStatus) {
        if (sub.status == this.searchStatus) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    /**
     * テーブルより通知イベント
     */
    notification(msg, param) {
      // 見積選択表示
      if (msg == 'showEstimateSelector') {
        this.dataSelectorParams = {
          title: '見積の選択',
          subtitle: '',
          options: {
            formId: 'rp_estimates',
            displayFields: [ 'estimateNo', 'subject', 'salesPersonName' ],
            searchFields: [ 'estimateNo', 'subject', 'salesPersonName' ],
            sortField: 'estimateNo',
            selectable: true,
            filter: {
              taskRequestId: param._id
            }
          },
          selectCallback: (selected) => {
            this.dataSelectorShow = false
            this.$router.push({ 
              name: 'EstimateForm',
              params: {
                copyEstimateNo: selected.estimateNo
              }
            })
          }
        }
        this.dataSelectorShow = true
      } else if (msg == 'refresh') {
        this.fetchRequestPosts()
      }
    },

    /*
     * 一覧データ 各項目のソート切り替え
     * @param {*} column
     */
     async changeSort(column) {
      // this.sortBy = sort
      // this.sortItem = column[0].name
      await this.$emit('change-sort', { item: column.name, by: column.sortIcon == 'ArrowNarrowUp' ? 1 : -1})
      this.fetchRequestPosts()
    },

    /**
     * 一覧データ 配列項目ソート
     * @param {*} data 一覧データ
     * @param {*} item ソート項目
     * @param {*} orderby ソート順
     */
    sortDataToArrayItem(data, item, orderby) {

      if (orderby == 1 ) {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString > b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString < b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      if (orderby == 1 ) {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String > b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String < b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      return data
    },

    /**
     * 表示内容取得 (期限)
     * @param {*} row 
     */
    getLimit(row) {
      if (row.limitDate) {
        const d = utils.stringToDate(row.limitDate)
        if (d != 'Invalid Date') {
          return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
        } else {
          return ''
        }
      } else {
        return ''
      }
    },

    /**
     * 詳細 URL 取得
     * @param {*} row 
     */
    getDetailUrl(row) {
      if (row.type == 'apollo_request_post') {
        return './taskRequest?requestPostId=' + row._id
      } else if (row.type == 'order') {
        return './taskRequest?orderId=' + row._id + '&mode=1'
      } else {
        return ''
      }
    },

    /*
     * ソート 切り替え
     */
    sortSwitching(column) {
      if (!column.canSort) return

      // 他ソートをクリア
      this.columns.forEach( col => { if(col.name != column.name) col.sortIcon = '' })

      // ソート切替
      if (column.sortIcon == 'ArrowNarrowUp') {
        column.sortIcon = 'ArrowNarrowDown'
      } else {
        column.sortIcon = 'ArrowNarrowUp'
      }

      // 親コンポーネントへの通知
      this.$emit('changeSort', column)

      // ソート
      this.changeSort(column)
    },
  }
}

</script>
<style scoped>
.main {
  width: 100%;
}

.t-thead {
  z-index: 20;
  height: 60px;
}

.bg-colu {
  background: #ebf0fb;
}

.col-no {
  width: 70px;  
}

.min-100 {
  min-width: 100px;
}

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  cursor: pointer;
}

.th-limit_date {
  min-width: 140px;
}

.th-inquiry_title {
  min-width: 100px;
}

.th-toStaff_name {
  min-width: 80px;
}

.th-category_string {
  min-width: 100px;
}

.th-task_progress {
}

.th-view_link {
  min-width: 80px;
}


tr:nth-child(2n+1) {
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important
}

@media screen and (max-width: 1900px) {
  .list {
    min-width: 1900px !important;
    bottom: auto !important;
  }
}
</style>