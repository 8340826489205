<template>
  <div>
    <CheckListTableComponent
      typeName='巡回清掃'
      :type=2
      routerName='RoundScheduleListByStaff'
    >
    </CheckListTableComponent>
  </div>
</template>

<script>
import CheckListTableComponent from '../components/RoundCheckListTableComponent.vue'

export default {
  components: {
    CheckListTableComponent,
  },
}
</script>