export async function listNote(context) {
  const searchCondition = {
    searchCondition: {
      type: 'estimate_note_master',
      status: { $ne: "remove" },
    },
    sort: { displayOrder: 1}
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const notes = responseData
    return notes
  } catch (error) {
    console.log('listNote error !!!')
    console.log(error)
    return []
  }
}
