<template>
  <div class="relative flex">
    <Popper
      ref="popper"
      trigger="clickToToggle"
      :append-to-body="true"
      :visible-arrow="false"
      :options="popperOptions"
      class="inline-block w-full z-10"
      :disabled="disabled || menuItems.length === 0"
    >
      <button 
        slot="reference"
        type="button" 
        class="---bg-gray-100 rounded-full flex items-center text-gray-100 hover:text-gray-300 focus:outline-none ---focus:ring-2 ---focus:ring-offset-2 ---focus:ring-offset-gray-100 ---focus:ring-indigo-500" 
        :class="[disabled ? ['bg-gray-200'] : [], strongColor ? ['text-gray-400'] : []]" 
        :disabled="disabled"
      >
        <span class="sr-only">Open options</span>
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
      <!-- <button 
        slot="reference"
        type="button" 
        :name="name"
        class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded cursor-default text-left bg-white px-3"
        :class="disabled ? ['bg-gray-200'] : []" 
        :disabled="disabled"
      >
        <span class="block truncate">
          {{ value ? value.text : '&nbsp;' }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </span>
      </button> -->
      <ul class="popper z-50 mt-1 bg-white shadow-lg ---max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 ---overflow-auto focus:outline-none sm:text-sm" 
        tabindex="-1" 
        role="listbox" 
        aria-labelledby="listbox-label" 
        aria-activedescendant="listbox-option-3"
      >
        <li class="px-4 pt-3 pb-3 text-left border-b border-gray-100">
          <slot name="header" />
        </li>
        <template v-for="menuItem in menuItems">
          <li 
            :key="menuItem.id" 
            class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-3 ---hover:bg-gray-100 hover:text-teal-600 text-left"
            @click="select(menuItem)"
          >
            <div class="font-normal truncate flex items-center">
              <Icon :iconName="menuItem.icon" class="mr-2 w-4 h-4 text-gray-400 hover:text-teal-600" />
              {{ menuItem.text }}
            </div>
          </li>
        </template>
      </ul>
    </Popper>      
  </div>
</template>
<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import Icon from '@components/Icon.vue'

// const sameWidth = {
//   name: 'sameWidth',
//   enabled: true,
//   fn: (data) => {
//     data.instance.popper.style.width = data.offsets.reference.width + 'px'
//     return data
//   }
// }

const popperOptions = {
  placement: 'bottom-end',
  // modifiers: {
  //   sameWidth
  // }
}

export default {
  components: {
    Popper,
    Icon
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    menuItems: {
      type: Array,
      default: () => []
    },

    strongColor: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      popperOptions,
      // showPopup: false
    }
  },

  methods: {
    select(menuItem) {
      console.log('Select.vue :: select')
      console.log(menuItem)
      this.$refs.popper.doClose()
      // this.showPopup = false
      this.$emit('select', menuItem)
    }
  }
}
</script>

<style scoped>
ul {
  min-width: 200px;
  border: none;
}
</style>

