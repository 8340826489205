<template>
  <div class="">
    <template>
      <div class="overflow-y-auto overflow-x-hidden w-full border border-gray-300 rounded" style="max-height: 78vh;">

        <template>
          <!-- 明細を引用 -->
          <EstimateView 
            :estimateData="estimateData" 
            :showDetailOnly="true" 
            :detailSelectable="true" 
            @selectionChange="selectionChange"
          />

          <!-- メモ・承認者へのメッセージを引用 -->
          <div v-if="estimateData.memo">
            <div class="m-6">
              <div class="text-sm mb-1 font-medium text-gray-500">
                メモ・承認者へのメッセージ
              </div>
              <table class="min-w-full divide-y divide-gray-200 table-fixed border-collapse">
                <tr class="border border-gray-200 px-4 py-2 text-sm text-gray-900 bg-pink-50">
                  <td class="border border-gray-200 text-center w-20">
                    <input 
                      type="checkbox" 
                      class="h-6 w-6 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                      v-model="memoSelected"
                      @change="memoChange"
                    />
                  </td>
                  <td class="whitespace-pre-wrap px-4 py-2 border border-gray-200">
                    {{ estimateData.memo }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import EstimateView from './EstimateView.vue'

import * as utils from '@libs/utils'

export default {
  components: {
    EstimateView
  },

  props: {
    estimateData: {
      type: Object
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      memoSelected: false
    }
  },

  computed: {
  },

  methods: {
    selectionChange(detail) {
      console.log('selectionChange')
      console.log(detail)
      this.emitSelectionChange()
    },

    emitSelectionChange() {
      this.$emit('selectionChange', this.getSelectedDetails())
    },

    /**
     * チェックがついたデータのみの配列を生成
     */
    getSelectedDetails() {
      const selected = {
        details: [],
        purchase: [],
        commission: []
      }

      const key = utils.getUniqueStr()
      
      if (this.estimateData) {
        const estimate = this.estimateData
        const unique = estimate.estimateNo + key
        console.log(unique)
        let groupNames = []
        const selectList = estimate.details.filter((detail) => {
          return detail.selected
        })
        
        for (let i = 0; i < selectList.length; i++) {
          groupNames.push(selectList[i].groupName + unique)
        }

        const details = estimate.details.filter((detail2) => {
          detail2.groupName = detail2.groupName + unique
          return groupNames.includes(detail2.groupName)
        })
        
        let purchase = []
        let rlsPlus = {}
        // 発注
        if (estimate.purchase) {
          // purchase = estimate.purchase.filter((p) => {
          //   p.groupName = p.groupName + unique
          //   return groupNames.includes(p.groupName)
          // })

          for (let j = 0; j < estimate.purchase.length; j++) {
            const p = estimate.purchase[j]
            p.groupName = p.groupName + unique
            // ラウンドプラスの場合
            if (p.categoryCode == '2') {
              if (groupNames.includes(p.groupName)) {
                if (rlsPlus[p.groupName]) {
                  rlsPlus[p.groupName].push(p)
                } else {
                  rlsPlus[p.groupName] = [p]
                }
              }
            // ラウンドプラス以外
            } else {
              if (groupNames.includes(p.groupName)) {
                purchase.push(p)
              }
            }
          }

          // ラウンドプラスがある場合は、配列にして渡す
          for (let k = 0; k < Object.keys(rlsPlus).length; k++) {
            const key = Object.keys(rlsPlus)[k]
            purchase.push(rlsPlus[key])
          }
        }
        
        let commission
        // 手数料
        if (estimate.commission) {
          commission = estimate.commission.filter((c) => {
            c.groupName = c.groupName + unique
            return groupNames.includes(c.groupName)
          })
        }

        utils.addToArray(selected.details, details)
        utils.addToArray(selected.purchase, purchase)
        utils.addToArray(selected.commission, commission)
      }

      return selected
    },

    /**
     * メモチェック変更イベント
     */
    memoChange() {
      let memo = ''
      
      if (this.memoSelected) {
        memo = this.estimateData.memo
      }
      this.$emit('selectionChangeMemo', memo)
    },
  }
}
</script>
<style scoped>

</style>