<template>
  <div v-tooltip="message" class="absolute inset-y-0 left-0 px-2 flex items-center cursor-default" style="z-index: 1;">
    <Icon :iconName="iconName" class="h-5 w-5" :class="classes" />
    <!-- <component :is="icon" class="h-5 w-5" :class="classes" /> -->
    <!-- <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
    </svg> -->
  </div>  
</template>
<script>
import Icon from '@components/Icon.vue'
// import { SOLID_ICONS } from '@libs/constants'
// import * as icons from '@vue-hero-icons/solid'
export default {
  components: {
    Icon
  },
  props: {
    position: {
      type: String,
      default: 'leading'
    },
    iconName: {
      type: String,
      default: 'leading'
    },
    iconColor: {
      type: String,
      default: 'gray'
    },
    message: {
      type: String,
      default: 'leading'
    }    
  },
  computed: {
    // icon() {
    //   return SOLID_ICONS[`${this.iconName}Icon`]
    // },
    classes() {
      return `text-${this.iconColor}-400`
    }
  }
}
</script>