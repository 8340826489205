<template>
  <form>
    <div v-if="loading" class="flex justify-center items-center loading-dialog2 w-full">
      <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">waiting...</p>
    </div>
    <PageHeader title="apollo-RP - Price" class="head" :class="setWidth()">
      <template #title-header-content>
        <div class="mr-auto ml-5 mt-4 flex">
          <PrimaryButton v-if="auth" text="更新" size="normal" class="new" :class="changeData.length==0?'cursor-not-allowed':''" v-tooltip="changeData.length==0?'変更した単価がありません':''" @click="save()"/>
          <div class="mr-auto mt-auto ml-5 text-white">
            ＊更新後に見積もりを作成する際は、見積作成前に1度画面をリロードしてください＊
          </div>
        </div>
      </template>
    </PageHeader>
    <main class="w-full h-full flex main" :class="setWidth()">

      <!-- サイドバーのタブ -->
      <span class="tab">
        <a v-for="tab in kind" 
          :key="tab.id" 
          class="flex items-center mx-1 cursor-pointer mb-2 whitespace-pre"
          :class="[tab.active ? 'bg-light-blue-800' : 'hover:bg-light-blue-800', 'bg-gray-300 rounded-md py-2 px-3 text-sm font-medium text-white']"
          @click="tabChanged(tab)"
        >
          {{ tab.name }}
        </a>
      </span>

      <!-- 単価表 -->
      <span class="price">
        <!-- RLS -->
        <div v-if="kindId=='1'">
          <PriceRLS
            @changePrice="changePrice"
          />
        </div>
        <!-- ラウンドプラス -->
        <div v-if="kindId=='2'">
          <PriceRLSPlus
            @changePrice="changePrice"
          />
        </div>
        <!-- 日常清掃 -->
        <div v-if="kindId=='3'">
          <PriceNormal
            @changePrice="changePrice"
          />
        </div>
        <!-- 管理員業務 -->
        <div v-if="kindId=='4'">
          <PriceMgt
            @changePrice="changePrice"
          />
        </div>
        <!-- 定期清掃 -->
        <div v-if="kindId=='5'">
          <PriceFixed
            @changePrice="changePrice"
          />
        </div>
        <!-- フロントサービス -->
        <div v-if="kindId=='6'">
          <PriceFrontService
            @changePrice="changePrice"
          />
        </div>
        <!-- フロントサポート -->
        <div v-if="kindId=='7'">
          <PriceFrontSupport
            @changePrice="changePrice"
          />
        </div>
        <!-- ラウンドトラッシュ -->
        <div v-if="kindId=='8'">
          <PriceRoundTrash
            @changePrice="changePrice"
          />
        </div>
        <!-- クオリティーレポート -->
        <div v-if="kindId=='9'">
          <PriceQualityReport
            @changePrice="changePrice"
          />
        </div>
        <!-- 排水管清掃 -->
        <div v-if="kindId=='11'">
          <PriceDrainage
            @changePrice="changePrice"
          />
        </div>
        <!-- 貯水槽清掃 -->
        <div v-if="kindId=='12'">
          <PriceWaterStrage
            @changePrice="changePrice"
          />
        </div>
        <!-- 特別清掃 -->
        <div v-if="kindId=='14'">
          <PriceSpecial
            @changePrice="changePrice"
          />
        </div>
        <!-- 消防 -->
        <div v-if="kindId=='15'">
          <PriceFire
            @changePrice="changePrice"
          />
        </div>
        <!-- 建築設備定期検査 -->
        <div v-if="kindId=='16'">
          <PriceArchitecture
            @changePrice="changePrice"
          />
        </div>
        <!-- 特定建築物定期調査 -->
        <div v-if="kindId=='17'">
          <PriceSpArchitecture
            @changePrice="changePrice"
          />
        </div>
        <!-- 共用部設備点検 -->
        <div v-if="kindId=='18'">
          <PriceCommon
            @changePrice="changePrice"
          />
        </div>
        <!-- EV点検 -->
        <div v-if="kindId=='19'">
          <PriceEV
            @changePrice="changePrice"
          />
        </div>
        <!-- 増圧 -->
        <div v-if="kindId=='20'">
          <PriceBooster
            @changePrice="changePrice"
          />
        </div>
        <!-- 給排水ポンプ・簡易専用水道 -->
        <div v-if="kindId=='21'">
          <PricePumpAndWater
            @changePrice="changePrice"
          />
        </div>
        <!-- 管球交換 -->
        <div v-if="kindId=='23'">
          <PriceBulb
            @changePrice="changePrice"
          />
        </div>
        <!-- MRS -->
        <div v-if="kindId=='29'">
          <PriceMRS
            @changePrice="changePrice"
          />
        </div>
        <!-- その他 -->
        <div v-if="kindId=='0'">
          <PriceOther
            @changePrice="changePrice"
          />
        </div>
      </span>
    </main>
  </form>
</template>
<script>

import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as estimateManager from '@managers/estimateManager'
import PriceRLS from '../components/PriceRLS.vue'
import PriceRLSPlus from '../components/PriceRLSPlus.vue'
import PriceNormal from '../components/PriceNormal.vue'
import PriceMgt from '../components/PriceMgt.vue'
import PriceFixed from '../components/PriceFixed.vue'
import PriceFrontService from '../components/PriceFrontService.vue'
import PriceFrontSupport from '../components/PriceFrontSupport.vue'
import PriceRoundTrash from '../components/PriceRoundTrash.vue'
import PriceQualityReport from '../components/PriceQualityReport.vue'
import PriceDrainage from '../components/PriceDrainage.vue'
import PriceWaterStrage from '../components/PriceWaterStrage.vue'
import PriceSpecial from '../components/PriceSpecial.vue'
import PriceFire from '../components/PriceFire.vue'
import PriceArchitecture from '../components/PriceArchitecture.vue'
import PriceSpArchitecture from '../components/PriceSpArchitecture.vue'
import PriceCommon from '../components/PriceCommon.vue'
import PriceEV from '../components/PriceEV.vue'
import PriceBooster from '../components/PriceBooster.vue'
import PricePumpAndWater from '../components/PricePumpAndWater.vue'
import PriceBulb from '../components/PriceBulb.vue'
import PriceMRS from '../components/PriceMRS.vue'
import PriceOther from '../components/PriceOther.vue'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'
import { ESTIMATE_PRICE_AUTH } from '@/config'

const session = 'session'

export default {
  components: {
    PrimaryButton,
    PageHeader,
    PriceRLS,
    PriceRLSPlus,
    PriceNormal,
    PriceMgt,
    PriceFixed,
    PriceFrontService,
    PriceFrontSupport,
    PriceRoundTrash,
    PriceQualityReport,
    PriceDrainage,
    PriceWaterStrage,
    PriceSpecial,
    PriceFire,
    PriceArchitecture,
    PriceSpArchitecture,
    PriceCommon,
    PriceEV,
    PriceBooster,
    PricePumpAndWater,
    PriceBulb,
    PriceMRS,
    PriceOther
  },

  data() {
    return {
      kind: [
        {id: '1', name: 'ラウンドサービス', active: true},
        {id: '2', name: 'ラウンドプラス', active: false},
        {id: '3', name: '日常清掃', active: false},
        {id: '4', name: '管理員業務', active: false},
        {id: '5', name: '定期清掃', active: false},
        {id: '6', name: 'フロントサービス', active: false},
        {id: '7', name: 'フロントサポートプラス', active: false},
        {id: '8', name: 'ラウンドトラッシュ', active: false},
        {id: '9', name: 'クオリティレポート', active: false},
        {id: '11', name: '排水管清掃', active: false},
        {id: '12', name: '貯水槽清掃', active: false},
        {id: '14', name: '特別清掃', active: false},
        {id: '15', name: '消防点検', active: false},
        {id: '16', name: '建築設備定期検査', active: false},
        {id: '17', name: '特定建築物定期調査', active: false},
        {id: '18', name: '共用部設備点検', active: false},
        {id: '19', name: 'EV点検', active: false},
        {id: '20', name: '増圧ポンプ点検', active: false},
        {id: '21', name: '給排水ポンプ点検\r\n 簡易専用水道', active: false},
        {id: '23', name: '管球交換', active: false},
        {id: '29', name: 'MRS', active: false},
        {id: '0', name: 'その他', active: false}
      ],
      // 選択した単価表
      kindId: '1',
      // 表示データ
      list: [],
      // 変更したデータ
      changeData: [],
      // 変更前のデータ
      original: [],
      // 保存中
      loading: false,
      // 編集権限
      auth: false
    }
  },

  created() {
    // 編集権限判定 configにて定義されているユーザーかどうか
    if (ESTIMATE_PRICE_AUTH && ESTIMATE_PRICE_AUTH.length) {
      let userId = this.$store.getters.user.id
      if (ESTIMATE_PRICE_AUTH.includes(userId)) {
        this.auth = true
      }
    }
    
    this.original = utils.clone(this.$store.getters.estimateItemPriceList)

    // 前回の活性タブを取得
    const tab = sessionStorage.getItem(session)
    if (tab && tab != '') {
      this.kindId = tab
      this.kind.forEach((k) => {
        if (k.id == this.kindId) {
          k.active = true
        } else {
          k.active = false
        }
      })
    }
  },

  methods: {

    /**
     * サイドバータブ選択イベント
     * @param tab タブ
     */
    async tabChanged(tab) {
      if (this.changeData.length) {
        let result = await dialogs.showWarningConfirmDialog('変更した単価があります', '変更を破棄しますか？')
        if (result == 'YES') {
          this.changeData = []
        } else {
          return
        }
      }
      this.kind.forEach((k) => {
        k.active = false
      })
      tab.active = true
      this.kindId = tab.id
      this.changeData = []
      sessionStorage.setItem(session, this.kindId)
    },

    /**
     * 価格変更イベント
     */
    changePrice(data) {
      // 同じデータを変更したことを考え、既にある同じidは捨てる
      let list = this.changeData.filter((c) => {
        return data._id != c._id
      })

      if (!list) {
        list = []
      }

      this.changeData = list
      this.changeData.push(data)
      console.log(this.changeData)
    },

    /**
     * 
     * 更新ボタンイベント
     */
    async save() {
      if (!this.changeData.length) {
        return
      }
      this.loading = true

      for (let i = 0; i < this.changeData.length; i++) {
        const c = this.changeData[i]
        // console.log(c)
        const result = await estimateManager.updateTasks(this, c._id, {_id: c._id, type: c.type, price: c.price})
        if (!result) {
          alert('更新中にエラーが発生しました。')
          return
        }
      }
      let msg = await this.setLog()
      this.loading = false
      await dialogs.showSuccessDialog('更新', msg + '\r\n※上記の内容はログ一覧でも確認できます。')
      this.changeData = []
      this.loading = true
      // リロード
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * ログ出力
     */
    async setLog() {
      let category = this.kind.find((k) => {
        return k.active
      }).name

      let msg = `${category}の単価を変更`
      if (this.kindId == '21') {
        msg = '給排水ポンプ点検・簡易専用水道の単価を変更'
      }
      for (let i = 0; i < this.changeData.length; i++) {
        const c = this.changeData[i]
        let origin = this.getOriginal(c)
        msg += '\n'
        let add = `●「${c.itemName}」 を ${origin} ${c.price} 円に変更しました。【parentCode:${c.parentCode},priceCode:${c.priceCode}】`
        // 係数の場合は「円」を消す
        if (c.coef) {
          add = add.replace(/円/g, '')
        }
        msg += add

      }
      await logManager.recording(this, logManager.Loglevel.INFO, '見積単価', '更新', msg)
      return msg
    },

    /**
     * 元データの金額を取得
     * @param targert 対象データ
     */
    getOriginal(targert) {
      for (let i = 0; i < this.original.length; i++) {
        const o = this.original[i]
        if (targert._id == o._id) {
          return o.price + ' 円から'
        }
      }
      return ''
    },

    /**
     * カテゴリによってヘッダーの広さを変更
     */
    setWidth() {
      switch (this.kindId) {
        case '1':
          return 'list1'
        case '3':
          return 'list3'
      
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.tab {
  display: inline-block;
  position: -webkit-sticky;
  position: sticky;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 161px;
}

.price {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.main {
  margin-top: 10px;
}

.head {
  padding-bottom: 0px !important;
}

.new {
  width: 200px;
  background: #4dacc6;
}

.new:hover {
  background: #408da2;
}

@media screen and (max-width: 3170px) {
  .list1 {
    min-width: 3170px !important;
  }
}

@media screen and (max-width: 2560px) {
  .list3 {
    min-width: 2560px !important;
  }
}

.modals-container >>> .vfm__content {
  max-height: 70vh;
  overflow: hidden;
}
</style>