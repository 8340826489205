<template>
  <div 
    class="sm:col-span-full text-sm font-bold border-b"
    :class="classes"
  >
    {{ caption }}
  </div>  
</template>

<script>
    export default {
        props: {
            caption: {
            type: String,
            default: ''
            },
            color: {
            type: String,
            default: 'black'
            }
        },
        computed: {
            classes() {
            const classes = []
            classes.push(`text-${this.color}-400`)
            classes.push(`border-${this.color}-300`)
            return classes
            }
        }
    }
</script>
