<template>
    <div>
        <div class="detail overflow-hidden border border-gray-300 rounded">
            <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead>
                    <tr class="divide-x divide-gray-200">
                        <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                            <!-- ラウンドプラスの見積り詳細画面では、「種別」 -->
                            <div v-if="typeName&&c.title=='カテゴリー'">
                                種別
                            </div>
                            <div v-else>
                                {{c.title}}
                            </div>
                        </th>
                    </tr>
                </thead>

                    <tr v-for="(row, i) in purchase" :key="i" class="divide-y divide-x divide-gray-200 bg-white tracking-wider">

                        <!-- No -->
                        <td v-if="row.rowspan!=-1&&!detailModal" :rowspan="getRowspan(row)" class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900 col-no cursor-not-allowed">
                            {{ num(row) }}
                        </td>
                        <td v-if="detailModal" :rowspan="getRowspan(row)" class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900 col-no cursor-not-allowed">
                            {{ i + 1 }}
                        </td>

                        <!-- カテゴリー名 -->
                        <td class="px-4 py-2 whitespace-pre-line break-words text-sm text-gray-500 w-80 cursor-not-allowed">
                            <!-- ラウンドプラスは、「種別」 -->
                            <div v-if="row.typeName">
                                {{ getNewLine(row.typeName) }}
                            </div>
                            <div v-else>
                                {{ getNewLine(row.categoryName) }}
                            </div>
                        </td>

                        <!-- 発注先 -->
                        <td v-if="!allDisabled" class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200">
                            <WmsSuggestInput
                                name="supplier"
                                :embed="true"
                                displayField="supplier"
                                :dynamic="false"
                                :incrementalSearch="false"
                                :selectionItems="supplierList"
                                :disabled="allDisabled"
                                class="embed"
                                v-model="row.supplier"
                                @selected="supplierSelected($event, row.categoryCode, row.type)"
                                @change="supplierChange()"
                            />
                        </td>
                        <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            {{ row.supplier }}
                        </td>
                        
                        <!-- 発注金額 -->
                        <td v-if="allDisabled" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed" :class="setBgColor(row.priceBgColor)">
                            <div >
                                {{ toLocale(row.price) }}
                            </div>
                        </td>
                        <!-- ラウンドプラス、貯水、消防、EV、増圧は編集不可 -->
                        <td v-else-if="(['2', '12', '15', '19', '20'].includes(row.categoryCode))" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            <div :class="[setBgColor(row.priceBgColor), detailModal?'py-1 pr-2':'']">
                                {{ toLocale(row.price) }}
                            </div>
                        </td>
                        <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-70">
                            <NumericTextInput
                                name="price"
                                v-model="row.price"
                                class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                                :class="setBgColor(row.priceBgColor)"
                                @change="priceChanged(i)"
                            />
                        </td>

                        <!-- 発注率 -->
                        <!-- 貯水、消防、EV、増圧は編集不可 -->
                        <td v-if="allDisabled" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed" :class="setBgColor(row.rateBgColor)">
                            {{ row.rate }}
                        </td>
                        <td v-else-if="(['12', '15', '19', '20'].includes(row.categoryCode))" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            <div :class="[setBgColor(row.priceBgColor), detailModal?'py-1 pr-2':'']">
                                {{ row.rate }}
                            </div>
                        </td>
                        <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-70">
                            <NumericTextInput
                                name="rate"
                                v-model="row.rate"
                                :decimalPlace="one"
                                class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent"
                                :class="setBgColor(row.rateBgColor)"
                                @change="rateChanged(i)"
                            />
                        </td>
                    </tr>
            </table>
        </div>
        <div class="text-xs text-transparent">
            {{ purchaseReload }}
        </div>
    </div>
    
</template>

<script>
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
// import * as dialogs from '@libs/dialogs'
import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'

export default {
    components: {
        WmsSuggestInput,
        NumericTextInput
    },

    props: ['purchase', 'allDisabled', 'purchaseModalShow', 'purchaseReload', 'typeName', 'detailModal'],

    data() {
        return {
            // カラム
            columns: [
                {title: 'No.'},
                {title: 'カテゴリー'},
                {title: '発注先'},
                {title: '発注金額 ※少数以下切り捨て'},
                {title: '発注率 (%)'},
            ],
            // 発注リスト 重複削除
            supplierList: [],
            // 小数第一位まで
            one: 1
        }
    },

    watch: {
        purchaseModalShow() {
            if (this.purchaseModalShow == false) {
                this.getSupplierList()
            }
        },
    },

    computed: {
        /**
         * 改行判定
         */
        getNewLine() {
            return (val) => {
                return utils.replaceNewLine(val)
            }
        }
    },

    created() {
        this.getSupplierList()
    },

    methods: {
        /**
         * 発注先リスト取得
         */
        async getSupplierList() {
            const result = await estimateManager.getPurchase(this)
            if (result.length) {
                this.supplierList = result
            } else {
                this.supplierList = []
            }
        },

        /**
         * 発注先選択イベント
         * @param e 選択したデータ
         * @param code カテゴリコード
         * @param type ラウンドプラスのタイプ
         */
        supplierSelected(e, code, type) {
            // ラウンドプラスの詳細画面の場合
            if (this.typeName) {
                this.$emit('changeInitRate', { code, sup: e.supplier, type })
            // それ以外
            } else {
                this.$emit('changeInitRate', { code, sup: e.supplier })
            }
            
        },

        /**
         * 発注金額変更イベント
         * @param idx インデックス
         */
        async priceChanged(idx) {
            // if (['2'].includes(this.purchase[idx].categoryCode)) {
            //     let name = 'ラウンドプラス'
            //     await dialogs.showInfoDialog('自動計算されません', `${name}は発注金額を変更しても手数料明細と単価2は変更されません。`)
            //     return
            // }
            // 発注率から金額を算出
            // ラウンドサービスの場合
            if (this.purchase[idx].categoryCode == '2') {
                this.$emit('getPurchasePrice', this.purchase[idx].groupName, true, false, this.purchase[idx].type)
            } else {
                this.$emit('getPurchasePrice', this.purchase[idx].groupName, true, false)
            }
        },

        /**
         * 発注率変更イベント
         * @param idx インデックス
         */
        rateChanged(idx) {
            // 発注率から金額を算出
            // ラウンドサービスの場合
            if (this.purchase[idx].categoryCode == '2') {
                this.$emit('getPurchasePrice', this.purchase[idx].groupName, false, true, this.purchase[idx].type)
            } else {
                this.$emit('getPurchasePrice', this.purchase[idx].groupName, false, true)
            }
        },

        /**
         * 発注率が超えている場合のセル強調色
         * @value 判定
         */
        setBgColor(value) {
            if (value) {
                return 'bg-yellow-200'
            } else {
                return ''
            }
        },

        /**
         * 発注先変更イベント
         */
        supplierChange() {
            this.$emit('changeOpenInit')
        },

        /**
         * 3桁区切りの表示
         * @param value 値
         */
        toLocale(value) {
            if (value && value != '') {
                return Number(value).toLocaleString()
            } else {
                return ''
            }
        },

        /**
         * 番号
         * @param row
         */
        num(row) {
            let idx = 0
            for (let i = 0; i < this.purchase.length; i++) {
                const p = this.purchase[i]
                if (!p.rowspan || p.rowspan > 0) {
                    idx++
                }
                if (p.groupName == row.groupName) {
                    return idx
                }
            }
        },

        /**
         * rowspanを取得
         * @param row 対象データ
         */
        getRowspan(row) {
            // ラウンドプラスの場合
            if (row.categoryCode == '2') {
                // 詳細画面でない
                if (!this.typeName) {
                    // rowspanがある場合(改修後のデータ)
                    if (row.rowspan) {
                        return row.rowspan
                    }
                }
            }
            return 1
        },
    }
}
</script>
<style scoped>
.col-no {
    width: 70px;  
}

.min-200 {
    min-width: 200px;
}

.min-70 {
    min-width: 70px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
    border-right: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: #ebfbec;
}

@media screen and (max-width: 1200px) {
    .detail {
        overflow: auto;
    }
}
</style>