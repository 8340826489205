<template>
  <div>
    <label :for="name" class="block text-xs font-bold text-gray-700 relative">
      {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="mt-1 flex relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" iconColor="red" :message="errorMessage" />
      <Select :name="name" v-bind="$attrs" v-on="$listeners" class="w-full" />
    </div>
  </div>
</template>
<script>
import Select from '@components/Select.vue'
import InputIcon from '@components/InputIcon.vue'
export default {
  inheritAttrs: false,

  components: {
    Select,
    InputIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>
