<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="glassCleanWorkWaySelections"
    :multiSelect="false"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

const glassCleanWorkWaySelections = [
  {
    value: '外面',
    text: '外面'
  },
  {
    value: '内面',
    text: '内面'
  },
  {
    value: '両面',
    text: '両面'
  }
]

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalValue() {
      const value = this.value.map((v) => {
        return this.glassCleanWorkWaySelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  data() {
    return {
      glassCleanWorkWaySelections
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>