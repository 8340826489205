<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr class="sticky top-8 z-20">
          <th v-for="(c, i) in column2" :key="i">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column3" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c.min }} ～ {{ c.max }} 戸
            </div>
          </th>
          <td>
            <div v-if="single1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single1[i].price"
                @change="changeData(single1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="family1[i].price"
                @change="changeData(family1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="single2[i].price"
                @change="changeData(single2[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="family2[i].price"
                @change="changeData(family2[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single3[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single3[i].price"
                @change="changeData(single3[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family3[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="family3[i].price"
                @change="changeData(family3[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single35[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="single35[i].price"
                @change="changeData(single35[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family35[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="family35[i].price"
                @change="changeData(family35[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single4[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single4[i].price"
                @change="changeData(single4[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family4[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="family4[i].price"
                @change="changeData(family4[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="single45[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="single45[i].price"
                @change="changeData(single45[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family45[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="family45[i].price"
                @change="changeData(family45[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="text-xs">※週3.5回以下の単価は、週4回の単価から1回減るごとに2割増し、最後に10円単位を切り上げしています。（週1～3.5回単価追加 2024年7月）</div>
    <div class="text-xs">（例：週1回＝週4回÷4×1.6）</div>
    <div class="text-xs">※週3.5回以下の見積りは承認を通すように設定しています。</div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * ラウンドトラッシュ
 */
const CATEGORY = '8'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 清掃回数カラム
      column1: [
        { name: '', colspan: 1, class: 'bg-white'},
        { name: '週 1 回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: '週 2 回', colspan: 2, class: 'ml-5 bg-blue-50'},
        { name: '週 3 回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: '週 3.5 回', colspan: 2, class: 'ml-5 bg-blue-50'},
        { name: '週 4 回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: '週 4.5 回', colspan: 2, class: 'ml-5 bg-blue-50'},
      ],
      // タイプカラム
      column2: [
        { name: '' },
        { name: 'シングル', class: 'ml-5 bg-teal-50' },
        { name: 'ファミリー', class: 'ml-5 bg-teal-50' },
        { name: 'シングル', class: 'ml-5 bg-blue-50' },
        { name: 'ファミリー', class: 'ml-5 bg-blue-50' },
        { name: 'シングル', class: 'ml-5 bg-teal-50' },
        { name: 'ファミリー', class: 'ml-5 bg-teal-50' },
        { name: 'シングル', class: 'ml-5 bg-blue-50' },
        { name: 'ファミリー', class: 'ml-5 bg-blue-50' },
        { name: 'シングル', class: 'ml-5 bg-teal-50' },
        { name: 'ファミリー', class: 'ml-5 bg-teal-50' },
        { name: 'シングル', class: 'ml-5 bg-blue-50' },
        { name: 'ファミリー', class: 'ml-5 bg-blue-50' }
      ],
      // 戸数カラム
      column3: [
        { min: '1', max: '20' },
        { min: '21', max: '25' },
        { min: '26', max: '30' },
        { min: '31', max: '35' },
        { min: '36', max: '40' },
        { min: '41', max: '45' },
        { min: '46', max: '50' }
      ],
      // 週1シングル
      single1: [],
      // 週1ファミリ
      family1: [],
      // 週2シングル
      single2: [],
      // 週2ファミリ
      family2: [],
      // 週3シングル
      single3: [],
      // 週3ファミリ
      family3: [],
      // 週3.5シングル
      single35: [],
      // 週3.5ファミリ
      family35: [],
      // 週4シングル
      single4: [],
      // 週4ファミリ
      family4: [],
      // 週4.5シングル
      single45: [],
      // 週4.5ファミリ
      family45: []
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let s1 = []
      let f1 = []
      let s2 = []
      let f2 = []
      let s3 = []
      let f3 = []
      let s35 = []
      let f35 = []
      let s4 = []
      let f4 = []
      let s45 = []
      let f45 = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode.split('-')
        switch (code[0]) {
          // シングル
          case '1':
            // 週1
            if (code[1] == '1') {
              s1.push(d)
            // 週2
            } else if (code[1] == '2') {
              s2.push(d)
            }
            // 週3
            if (code[1] == '3') {
              s3.push(d)
            // 週3.5
            } else if (code[1] == '3.5') {
              s35.push(d)
            }
            // 週4
            if (code[1] == '4') {
              s4.push(d)
            // 週4.5
            } else if (code[1] == '4.5') {
              s45.push(d)
            }
            break;

          // ファミリ
          case '2':
            // 週1
            if (code[1] == '1') {
              f1.push(d)
            // 週2
            } else if (code[1] == '2') {
              f2.push(d)
            }
            // 週3
            if (code[1] == '3') {
              f3.push(d)
            // 週3.5
            } else if (code[1] == '3.5') {
              f35.push(d)
            }
            // 週4
            if (code[1] == '4') {
              f4.push(d)
            // 週4.5
            } else if (code[1] == '4.5') {
              f45.push(d)
            }
            break;
        }
      }
      this.single1 = this.setSort(s1)
      this.family1 = this.setSort(f1)
      this.single2 = this.setSort(s2)
      this.family2 = this.setSort(f2)
      this.single3 = this.setSort(s3)
      this.family3 = this.setSort(f3)
      this.single35 = this.setSort(s35)
      this.family35 = this.setSort(f35)
      this.single4 = this.setSort(s4)
      this.family4 = this.setSort(f4)
      this.single45 = this.setSort(s45)
      this.family45 = this.setSort(f45)
    },

    /**
     * 戸数によるソート
     * @param target 対象データ
     */
    setSort(target) {
      const sortArr = ['1', '21', '26', '31', '36', '41', '46']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.minHouseholds == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''

      name1 = `${target.minHouseholds}~${target.maxHouseholds}戸`
      
      name2 = `・週${code[1]}回`
      
      name3 = '・シングル'
      if (code[0] == '2') {
        name3 = '・ファミリー'
      }

      target.itemName = name1 + name2 + name3
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
