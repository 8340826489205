<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container" v-if="selectItem">
          <h3 class="title">{{title}}</h3>
          <div class="item mb-8">
            <form v-for="(key, radioIndex) in Object.keys(selectItem)" :key='key.id'>
              <input
                type="radio"
                name="spRadio"
                v-model="selectedValue"
                :id="radioIndex"
                :value="key"
                class="mt-3 mb-3 radio-width"
                @change="calc()"
              />
              <label :for="radioIndex" class="radio-range">{{ selectItem[key] }}</label>
            </form>
          </div>

          <!-- ------------------------------------------------照明器具清掃 拭き上げ------------------------------------------------ -->
          <div v-if="title=='照明器具清掃'" class="formula">
            <div class="flex mb-5">
              <a 
                class="flex mx-1 cursor-pointer"
                :class="[tab.active ? 'bg-light-blue-800' : 'bg-gray-400', 'bg-gray-400 rounded-md py-2 px-3 text-sm font-medium text-white']" 
                @click="tabChanged(tab)"
              >
                {{ tab.name }}
              </a>
            </div>
          </div>
          <div v-if="title=='照明器具清掃' && tab.active" class="formula mt-5 mb-8">
            <div>
              <div class="price-width text-xs text-gray-500">戸数</div>
              <NumericTextInput
                name="house"
                v-model="house"
                @change="calc()"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width2"
              />
            </div>
            <span class="pt-4">×</span>
            <!-- 居住タイプ ドロップダウン -->
            <div class="inline">
              <div class="price-width text-xs text-gray-500">タイプ</div>
              <div class="type">
                <form>
                  <select
                    name="type"
                    v-model="type"
                    @change="calc()"
                    class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs"
                  >
                    <option
                      v-for="list in typeList" :key='list.id'
                      :value="list.id"
                      class="bg-white">
                      {{ list.name }}
                    </option>
                  </select>
                </form>
              </div>
            </div>
            <span class="pt-4">×</span>
            <!-- 単価 -->
            <div class="inline">
              <div class="price-width text-xs text-gray-500">単価</div>
              <NumericTextInput
                name="lightingUnit"
                v-model="lightingUnit"
                class="text-center py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 input border-gray price-width2"
                :class="[lightingUnitBgColor ? 'bg-yellow-200' : '']"
                @change="changeCalcAndPrice()"
              />
            </div>
            <span class="pt-4">＝</span>
            <div class="inline">
              <div class="text-xs text-gray-400">*10円単位を切上*</div>
              <NumericTextInput
                name="totalLightingPrice"
                v-model="totalLightingPrice"
                :disabled="true"
                class="text-center py-1 input disabled-back price-width2 mb-2 border-gray"
              />
            </div>
          </div>
          <!-- ---------------------------------------------照明器具清掃 拭き上げ---end--------------------------------------------- -->
          <!-- 決定ボタン -->
          <PrimaryButton text="決定" size="sm px-10 py-3 place-items-end block mt-3 btn" :disabled="selectedValue==''" @click="decisionItem" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as constants from '@libs/constantsEstimate'

export default {
  components: {
    Modal,
    PrimaryButton,
    NumericTextInput,
  },

  // inheritAttrs: false,

  props: {
    selectItem: {
      type: Object,
    },
    editData: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    households: {
      type: String,
      default: ''
    },
    residenceType: {
      type: String,
      default: ''
    },
    lighting: {
      type: String,
      default: ''
    },
    lightingBgColor: {
      type: Boolean,
      default: false
    },
    lightingTab: {
      type: Boolean,
      default: false
    },
    open: {
      type: Number,
      default: null
    },
    priceChange: {
      type: Function,
    },
    selectCallback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      selectedValue: '',
      // 照明器具清掃 拭き上げ で使用する係数
      typeList: constants.typeList,
      // 照明器具清掃 拭き上げ 単価
      lightingUnit: '',
      // 照明器具清掃 価格変更フラグ
      lightingUnitBgColor: false,
      // 照明器具清掃 拭き上げ 最終単価
      totalLightingPrice: '',
      // 戸数
      house: '',
      // 居住タイプ
      type: '',
      // 親画面に渡すデータ
      decisionData: {},
      // 照明 拭き上げ 個数判定
      tab : {id: '0', name: '箇所数 不明', active: false}
    }
  },

  watch: {
    open() {
      if (this.select == {}) {
        return
      }
      if (this.editData && this.editData != '') {
        this.selectedValue = this.editData
        // 居住タイプ(編集時はIdが入っている)
        if (this.residenceType && this.residenceType != '') {
          this.type = this.residenceType
        }
      } else {
        // 居住タイプ
        if (this.residenceType && this.residenceType != '') {
          let typeName = this.residenceType
          if (typeName != 'シングル') {
            typeName = 'ファミリー or 混合'
          }
          let value = this.typeList.find((t) => {
            return t.name == typeName
          }).id
          this.type = value
        }
      }
      if (this.title == '照明器具清掃') {
        // 戸数
        this.house = this.households
        // 拭き上げ、取り外し清掃が未選択の場合、取り外し清掃が初期値
        if (!this.selectedValue) {
          this.selectedValue = '2'
        }
        // 照明器具清掃 単価
        if (this.lighting != '') {
          this.lightingUnit = this.lighting
        }
        // 単価手動変更フラグ
        this.lightingUnitBgColor = this.lightingBgColor
        // タブ
        this.tab.active = this.lightingTab

        this.calc()
      }
    },
  },

  computed: {
  //   setItemWidth() {
  //     if (this.title == '照明器具清掃' && this.selectedValue == '1') {
  //       return 'item2'
  //     } else {
  //       return 'item'
  //     }
  //   }
  },

  methods: {
    closed() {
      this.selectedValue = ''
      // 照明器具清掃 拭き上げ 単価
      this.lightingUnit = '350',
      // 手動で価格変更フラグ
      this.lightingUnitBgColor = false
      // 照明器具清掃 拭き上げ 最終単価
      this.totalLightingPrice = '',
      // 戸数
      this.house = '',
      // 居住タイプ
      this.type = '',
      // 親画面に渡すデータ
      this.decisionData = {},
      // 照明 拭き上げ 個数判定
      this.tab = {id: '0', name: '箇所数 不明', active: false}
    },

    /**
     * 決定ボタンイベント
     */
    decisionItem() {
      // 親画面に渡すデータを生成
      this.decisionData = {
        selectedValue: this.selectedValue,
        house: this.house,
        type: this.type,
        lightingUnit: this.lightingUnit,
        totalLightingPrice: this.totalLightingPrice,
        lightingUnitBgColor: this.lightingUnitBgColor,
        lightingTab: this.tab.active
      }
      this.selectCallback(this.decisionData)
      this.closed()
    },

    /**
     * 照明器具清掃 計算
     */
    calc() {
      if (this.title == '照明器具清掃') {
        if (this.selectedValue != '') {
          let priceCode = 'lighting' + '-' + this.selectedValue
          // 単価表から単価を取得
          let list = []
          list = this.$store.getters.estimateItemPriceList.filter((l) => {
            if (l.parentCode == '14' && l.priceCode == priceCode) {
              return true
            }
          })

          if (list.length && list[0].price) {
            this.lightingUnit = list[0].price
          } else {
            this.lightingUnit = ''
          }
        } else {
          this.lightingUnit = ''
        }
        if (!this.tab.active) {
          this.totalLightingPrice = this.lightingUnit
        } else {
          let total = Number(this.house) * Number(this.type) * Number(this.lightingUnit)
          // 10円単位を切上
          total = Math.ceil(total / 100) * 100
          this.totalLightingPrice = String(total)
        }
      }
    },

    /**
     * 計算＆値段を手動で変更フラグ
     */
    changeCalcAndPrice() {
      this.calc()
      this.lightingUnitBgColor = true
    },

    tabChanged(tab) {
      tab.active = !tab.active
      this.calc()
    }
  }
}
</script>
<style scoped>
.content-container {
  min-width: 450px;
  max-width: calc(100vw - 185px);
  min-height: 150px;
  overflow: auto;
}

.item {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  width: 100%;
}

.title {
  font-weight: bold;
  color: #a7a7f2;
  margin-bottom: 20px;
}

.formula {
  /* margin-top: 30px; */
  display: flex;
  justify-content: center;
  text-align:center
}

.formula span {
  font-size: 17px;
  margin: 8px 15px;
}

.price-width {
  width: 90px;
}

.price-width2 {
  width: 90px;
  height: 37px;
}

.type {
  border: solid 1px gray;
  height: 37px;
}

.radio-range {
  padding: 10px 15px;
  cursor: pointer;
}

.radio-width {
  cursor: pointer;
}

.disabled-back {
  background: #f6f8fb;
}

</style>