<template>
    <WmsSelectionButton
      v-bind="$attrs"
      :selections="item"
      :multiSelect="multiSelect"
      :value="internalValue"
      @change="onChange"
    />
  </template>
  <script>
  import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'
  
  export default {
    inheritAttrs: false,
  
    components: {
      WmsSelectionButton
    },
    
    model: {
      prop: 'value',
      event: 'change'
    },
  
    props: {
      value: {
        type: Array,
        default: () => []
      },
      item: {
        type: Array,
        default: () => []
      },
      multiSelect: {
        type: Boolean,
        default: true
      }
    },
  
    computed: {
      internalValue() {
        const value = this.value.map((v) => {
          return this.item.find((selection) => {
            return selection.value === v
          })
        })
        return value
      }
    },
  
    // data() {
    //   return {
    //     workDaysSelections
    //   }
    // },
  
    methods: {
      onChange(selectionItems) {
        const value = selectionItems.map((item) => {
          return item.value
        })
        this.$emit('change', value)
      }
    }
  }
  </script>