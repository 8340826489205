<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto" :class="isMobileDevice?'mobile-content':'pc-content'">
          <div class="text-lg text-blue-800 font-bold">クレーム対応 項目設定</div>

          <div class="ml-12">

            <div class="mr-auto mt-3 flex">
              <div class="text-gray-600 font-bold mr-auto">
                得意先 : {{ list.clientName1 }} {{ list.clientName2 }}
              </div>
            </div>

            <div class="mr-auto mt-2 flex">
              <div class="text-gray-600 font-bold mr-auto">
                物件名 : {{ list.siteName }}
              </div>
            </div>
          </div>

          <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mr-3 hover:text-blue-600" @click="add()"/>

          <div class="toggle flex items-center mb-1">
            <div v-if="!isCompleted" class="flex">
              <span class="text-gray-500 font-bold text-xs">並べ替え</span>
              <Toggle v-model="sortable" size="small"/>
            </div>
            <div v-else>
              <span class="text-gray-500 font-bold text-xs">完了済の項目があるため、並べ替え機能は使用できません。</span>
            </div>

            <div class="ml-10 text-xs text-indigo-500 font-bold">＊この順番でエクセルにダウンロードします</div>
          </div>
          <div class="overflow-auto rounded border border-blue-500" :class="isMobileDevice?'table-mobile':'table-pc'">
            <table class="main table-auto ">
              <thead class="main sticky top-0 z-50 bg-white">
                <tr v-if="checkList && checkList.length">
                  <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

              <draggable 
                key="checkList"
                :list="checkList"
                direction="vertical"
                handle=".row-drag-handle"
                tag="tbody"
                class="bg-white"
              >

                <tr v-for="(row, i) in checkList" :key="'r'+i" class="ml-auto mr-auto" v-tooltip="row.complete?'完了済のため編集不可':''">

                  <!-- No -->
                  <td class="px-4 py-2 text-blue-700 font-bold col-no">
                    <div v-if="sortable" class="row-drag-handle cursor-pointer">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else>
                      {{ i + 1 }}.
                    </div>
                  </td>

                  <!-- 区分 -->
                  <td class="px-4 py-2 whitespace-prerap min-210" :class="isMobileDevice?'table-mb':''">
                    <WmsSuggestInput
                      id="classification-text"
                      name="classification"
                      :embed="true"
                      displayField="name"
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="classificationList"
                      class="embed border rounded"
                      :class="row.emptyClassification?'border-red-600':''"
                      v-model="row.classification"
                      :disabled="row.complete"
                    />
                  </td>
                  <!-- 項目 -->
                  <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-700 min-300" :class="isMobileDevice?'table-mb':''">
                    <input
                      autocomplete="off"
                      type="text" 
                      name="item"
                      v-model="row.item"
                      class="w-full border border-gray-200 rounded py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      :class="row.emptyItem?'border-red-600':row.complete?'bg-gray-100 cursor-not-allowed':''"
                      :disabled="row.complete"
                    />
                  </td>
                  <!-- 削除ボタン -->
                  <td class="px-4 py-0.5 text-center text-sm font-medium border-gray cursor-pointer hover:text-gray-500 active:text-gray-600" @click="deleteRow(i)">
                    <div class="flex justify-center">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5"/>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <PrimaryButton v-if="checkList&&checkList.length" text="登録" size="normal" class="w-full mt-1" @click="register()" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'

const ckeckListRow = {id: null, siteCode: '', sortNo: null, classification: '', item: ''}

export default {
  components: {
    Modal,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    PrimaryButton,
    WmsSuggestInput,
    Toggle,
    draggable
  },

  props: {
    target: {
      type: Object
    },
    items: {
      type: Array
    },
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 編集対象得意先データ
      list: {},
      // チェック項目
      checkList: [utils.clone(ckeckListRow)],
      // カラム
      columns: [
        {title: 'No.'},
        {title: '区分'},
        {title: 'チェックポイント'},
        {title: ''}
      ],
      // 区分リスト
      classificationList: [
        {name: 'エントランス周り'},
        {name: 'エントランスホール'},
        {name: '共用廊下'},
        {name: '共用階段'},
        {name: 'エレベーター'},
        {name: 'ゴミ置場'},
        {name: '外周'},
        {name: '自転車置場'},
        {name: '駐車場'},
        {name: '日常清掃員'},
        {name: '定期清掃'},
      ],
      // 並べ替え
      sortable: false,
      // 完了している項目が1つ以上ある
      isCompleted: false
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },

  watch: {
    async open() {
      if (!this.open) {
        return
      }
      await this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.isCompleted = false
      if (this.target && Object.keys(this.target).length) {
        this.list = this.target
        this.checkList = [utils.clone(ckeckListRow)]

        // 親画面の項目リストを取得
        if (this.items && Object.keys(this.items).length) {
          this.checkList = utils.clone(this.items)
          // エクセル出力順に並べ替え
          let sortList = this.checkList.sort(function(a, b) {
            return (Number(a.sortNo) > Number(b.sortNo)) ? 1 : -1
          })
          this.checkList = sortList
          for (let i = 0; i < this.checkList.length; i++) {
            const c = this.checkList[i]
            if (c.complete) {
              this.isCompleted = true
              break
            }
          }
        }
      }
    },

    /**
     * 初期化
     */
    closed() {
      this.list = {}
      // チェック項目
      this.checkList = []
      // 並べ替え
      this.sortable = false
    },

    /**
     * 登録処理
     */
    async register() {
      // 未入力チェック
      for (let vI = 0; vI < this.checkList.length; vI++) {
        const vC = this.checkList[vI]
        if (vC.classification == '') {
          this.errRequired('区分', vI)
          vC.emptyClassification = true
          return
        } else if (vC.item == '') {
          this.errRequired('チェックポイント', vI)
          vC.emptyItem = true
          return
        }
        // 出力順
        vC.sortNo = vI + 1
      }

      const param = {
        checkList: this.checkList,
        type: this.type,
        updateUser: this.$store.getters.user.id,
        key: this.list.siteCode,
        claim: true,
        patrolScheduleId: this.target.id,
        item: this.checkList
      }

      // 履歴更新に必要なパラム
      const save = {
        clientCode: this.list.clientCode,
        siteCode: this.list.siteCode,
        insertUser: this.$store.getters.user.id,
      }

      await backend.postData('claimCheckListBySite/save', { save, ...param })
      
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     * @param i インデックス
     */
    async errRequired(item, i) {
      const num = i +1
      await dialogs.showErrorDialog(item + 'は必須です', num + '番目が入力されていません。')
    },

    /**
     * 追加
     */
    add() {
      this.checkList.push(utils.clone(ckeckListRow))
    },

    /**
     * 削除
     */
    async deleteRow(i) {
      if (this.isCompleted) {
        await dialogs.showWarningDialog('削除できませません', '完了済みの項目があるため削除できません。')
        return
      }
      this.checkList.splice(i, 1)
      if (!this.checkList.length) {
        this.add()
      }
    },
  }
}
</script>
<style scoped>
.content-container-app {
  /* overflow: auto; */
  padding: 10px;
}

.mobile-content {
  max-width: 930px;
  min-width: 700px;
  -webkit-text-size-adjust: 100%;
  appearance: none;
}

.pc-content {
  max-width: 930px !important;
  height: calc(100vh - 130px);
}

.table-pc {
  height: calc(100vh - 350px);
}

.table-mobile {
  min-height: 800px!important;
}

.table-mb {
  /* transform: scale(1); */
  font-size: 16px;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.min-210 {
  min-width: 210px;
}

.min-300 {
  min-width: 300px;
}

#classification-text >>> input[type=text] {
  font-size: 16px !important;
}

/* @media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    overflow: auto;
  }

} */
</style>