<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <div class="overflow-hidden rounded main-height w-500px">
            <div class="flex">
                <!-- 大項目 -->
                <div class="w-48">
                    <div class="font-bold text-blue-700 text-sm w-fit mx-auto">大項目（作業内容区分名）</div>
                    <div class="max-h-450px overflow-auto border rounded">
                        <div v-for="(c , idx) in categorys" :key="'category' + idx">
                            <div @click="selectCategory(c)" :class="categoryColor(c.name)" class="cursor-pointer py-2 px-3 rounded m-2 text-center hover:bg-blue-50 hover:font-bold hover:text-black">
                                {{ c.name }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- 小項目 -->
                <div class="mx-auto w-72">
                    <div class="font-bold w-fit mx-auto text-blue-700 text-sm">小項目（作業内容名）</div>
                    <div class="max-h-450px overflow-auto border rounded">    
                        <div v-for="(d, i) in details" :key="i">
                            <div @click="selectDetail(d)" :class="detailColor(d.name)" class="cursor-pointer py-2 px-3 rounded m-2 text-center hover:bg-blue-50 hover:font-bold hover:text-black" v-tooltip="d.code">
                                {{ d.name }}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="mt-5">
            <PrimaryButton text="選択解除" size="normal w-full text-indigo-900 border-indigo-900 bg-indigo-50" @click="clear()" />
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as utils from '@libs/utils'
export default {
    components: {
        Modal,
        PrimaryButton
    },

    props: ['open', 'target', 'allList'],

    data() {
        return {
            // 大項目リスト
            categorys: [],
            // 小項目リスト
            details: [],
            // 選択した内容
            selectedData: {
                category: '',
                detail: '',
                code: ''
            }
        }
    },

    computed: {
    },

    watch: {
        open() {
            if (this.open) {
                this.getData()
            }
        }
    },

    methods: {
        /**
         * データ取得
         */
        async getData() {
            this.categorys = []
            this.details = []
            this.selectedData = {
                category: '',
                detail: '',
                code: ''
            }

            // 大項目リスト生成
            if (this.allList) {
                this.categorys = utils.clone(this.allList)
            }

            // 既に選択している場合
            if (this.target) {
                this.selectedData = utils.clone(this.target)
                // 小項目リスト取得
                for (let i = 0; i < this.categorys.length; i++) {
                    const c = this.categorys[i]
                    if (this.selectedData.category && c.name == this.selectedData.category) {
                        this.setDetailList(c)
                    }
                }
            }
        },

        /**
         * 大項目選択イベント
         * @param tar 対象カテゴリー
         */
        selectCategory(tar) {
            this.selectedData = {}
            // 選択した値をセット
            this.selectedData.category = tar.name

            // 小項目リスト生成
            this.setDetailList(tar)
        },

        /**
         * 小項目選択イベント
         * @param tar 対象詳細
         */
        selectDetail(tar) {
            // 選択した値をセット
            this.selectedData.detail = tar.name
            this.selectedData.code = tar.code
            this.$emit('selectWorkCodeClose', this.selectedData)
            this.closed()
        },

        /**
         * 選択を解除ボタン
         */
        clear() {
            this.$emit('selectWorkCodeClose', {})
            this.closed()
        },

        /**
         * 小項目リスト生成
         * @param tar 対象カテゴリー
         */
        setDetailList(tar) {
            this.details = []
            this.details = utils.clone(tar.details)
        },

        /**
         * 大項目選択済みの色
         * @param name 対象大項目名
         */
        categoryColor(name) {
            if (this.selectedData.category && name == this.selectedData.category) {
                return 'bg-blue-700 text-white font-bold'
            }
        },

        /**
         * 小項目選択済みの色
         * @param name 対象小項目名
         */
        detailColor(name) {
            if (this.selectedData.detail && name == this.selectedData.detail) {
                return 'bg-blue-700 text-white font-bold'
            }
        },

        /**
         * 初期化
         */
        closed() {
            this.categorys = []
            this.details = []
            this.selectedData = {
                category: '',
                detail: '',
                code: ''
            }
        },
    }
}
</script>
<style scoped>
.main-height {
    max-height: calc(100% - 200px);
}

.select-height {
    max-height: calc(100% - 250px);
}

.min-300 {
    min-width: 300px;
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>