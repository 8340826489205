<template>
  <!-- <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"> -->
    <!-- <div class="flex flex-col h-full">   -->
      <div class="flex flex-col h-full border border-gray-300 rounded bg-white">
        <div class="my-4">  
          <Tab 
            :tabs="classifications" 
            @change="classificationChange($event.name)"
          />
        </div>
        <div class="overflow-auto flex-1">
          <!-- <div class="align-middle inline-block min-w-full"> -->
            <!-- <div class="shadow border-b border-gray-200 sm:rounded-lg overflow-auto"> -->
              <table class="min-w-full divide-y divide-gray-200 table-auto">
                <thead class="bg-gray-50 hidden">
                  <tr>
                    <th scope="col" class="bg-gray-100 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                      <input 
                        type="checkbox" 
                        class="hidden h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                        v-model="allSelected"
                        @change="allSelectChange()"
                      />
                      選択
                    </th>
                    <th scope="col" class="bg-gray-100 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10">
                      特記事項
                    </th>
                    <th scope="col" class="bg-gray-100 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 w-24 text-center" v-if="filterClassificationName === '全て'">
                      大分類
                    </th>
                    <th scope="col" class="bg-gray-100 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 w-24 text-center">
                      中分類
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(note, index) in filteredNotes" :key="note._id" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <input 
                        name="note._id" 
                        type="checkbox" 
                        class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                        v-model="note.selected"
                        @change="noteSelectChange(note)"
                      />
                    </td>
                    <td class="px-6 py-4 text-sm font-medium text-gray-900 whitespace-pre-line">
                      <label :for="note._id" class="cursor-pointer">
                        {{ note.note }}
                      </label>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" v-if="filterClassificationName === '全て'">
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {{ note.classification }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {{ note.middleClassification }}
                      </span>
                    </td>
                    <!-- <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900" @click="editNote(note)">編集</a>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            <!-- </div> -->
          <!-- </div> -->
        </div>
        <!-- <div class="p-3 bg-gray-100 flex justify-end border-t border-gray-300">
          <PrimaryButton text="反映" size="sm" />
        </div> -->
      </div>
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import Tab from '@components/Tab.vue'
import * as noteManager from '@managers/noteManager'
import * as utils from '@libs/utils'
// import PrimaryButton from '../components/common/PrimaryButton.vue'

export default {
  components: {
    Tab,
    // PrimaryButton
  },

  data() {
    return {
      classifications: [],
      notes: [],
      filterClassificationName: '',
      allSelected: false,
      // renderKey: 0,
    }
  },

  computed: {
    filteredNotes() {
      return this.notes.filter((n) => {
        return this.filterClassificationName === '' || this.filterClassificationName === '全て' || n.classification === this.filterClassificationName
      })
    },

    selectedNotes() {
      return this.notes.filter((n) => {
        return n.selected
      })
    }
  },

  async created() {
    await this.fetchNotes()
    this.createClassifications()
  },

  methods: {
    async fetchNotes() {
      this.notes = await noteManager.listNote(this)
    },

    createClassifications() {
      const classificationNames = utils.unique(this.notes.map((note) => {
        note.classification = note.classification || 'その他'
        return note.classification
      }))
      const classifications = classificationNames.map((name) => {
        return {
          name,
          active: false,
          badge: 0
        }
      })
      classifications.unshift({
        name: '全て',
        active: true,
        badge: 0
      })
      this.classifications = classifications
    },

    classificationChange(classificationName) {
      this.filterClassificationName = classificationName
    },

    allSelectChange() {
      this.filteredNotes.forEach((note) => {
        note.selected = this.allSelected
      })
      this.emitSelectionChange()
    },

    noteSelectChange(note) {
      console.log('noteSelectChange')
      console.log(note)
      this.calcBadge()
      this.emitSelectionChange()
    },

    calcBadge() {
      const self = this
      self.classifications.forEach((c) => {
        c.badge = utils.count(self.notes, (note) => {
          return note.selected && (c.name === '全て' || note.classification === c.name)
        })
      })
    },

    editNote(note) {
      this.emitEditNote(note)
    },

    emitEditNote(note) {
      this.$emit('edit', note)
    },

    emitSelectionChange() {
      this.$emit('selectionChange', this.selectedNotes)
    },

    async refresh() {
      const selectedNoteIds = this.selectedNotes.map((note) => {
        return note._id
      })

      await this.fetchNotes()
      // const notes = await noteManager.listNote(this)
      
      this.notes.forEach((note) => {
        if (selectedNoteIds.includes(note._id)) {
          note.selected = true
        }
      })

      // this.notes = notes
      // this.renderKey++
      // console.log(`renderKey : ${this.renderKey}`)
      
      this.calcBadge()
      this.emitSelectionChange()
    }

    // resetSelection() {
    //   console.log('EstimateNoteSelector :: resetSelection')
    //   // this.allSelected = false
    //   // this.notes = this.notes.map((note) => {
    //   //   note.selected = false
    //   //   return note
    //   // })
    //   // this.notes.forEach((note) => {
    //   //   note.selected = false
    //   // })
    //   // this.calcBadge()
    // }
  }
}
</script>