<template>
  <div class="h-full">
    <VirtualDataTable 
      :columns="columns"
      :columnWidth="columnWidth" 
      :columnAlignment="columnAlignment" 
      :rowHeight="60" 
      :data="filteredData" 
      :selectable="true"
      :rowClassesFunc="rowClassesFunc"
      :isSort="true"
      :initSortItem="sortItem"
      :initSortCount="sortCount"
      @dblClickRow="dblClickRow"
      @changeSort="changeSort"
    />
  </div>
</template>

<script>
import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as requestPostManager from '@managers/requestPostManager'
// import * as taskRequestManager from '@managers/taskRequestManager'
import * as utils from '@libs/utils'

// カラム幅
const columnWidth = [
  '60px', // 未完了
  '90px', // ステータス
  // '150px', // 社内/お客様
  '150px', // お問い合わせカテゴリー
  '600px', // タイトル
  'auto', // お客様会社名
  'auto', // お客様担当者名
  // 'auto', // アポロ管財依頼先部署
  'auto', // 担当者
  // 'auto', // 依頼主
  '150px', // 期限
  '60px', // コピー
  '60px', // 編集
  '90px', // 依頼登録
]

// カラム位置
const columnAlignment = [
  'left',
  'center', // ステータス
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  // 'left',
  // 'left',
  // 'left',
]

// 検索対象プロパティ
const searchKeys = ['initialTransaction', 'category2', 'category', 'inquiry_title', 'clientName', 'clientPersonName', 'toDepartment', 'toStaffName', 'byUser', 'telephoneNumber', 'requestNo', 'interviewsKana', 'employmentKana']

// 日付絞込み対象プロパティ
const searchDateKey = ['limitDate']

export default {
  components: {
    VirtualDataTable
  },

  props: [ 
    'searchKeyword',
    'searchCategory',
    'searchClientName',
    'searchClientPersonName',
    'searchToDepartment',
    'searchToStaffName',
    'searchByUser',
    'searchLimitDate',
    'searchStatus',
    'period',
    'freeze',
    'mainTask',
    'orderTask',
    'employmentTask',
    'filterToStaffUser',
    'filterByUser',
    'withSub',
    'sortItem',
    'sortBy'
  ],

  data() {
    return {
      columns: [
        [
          {
            name: 'Incomplete',
            title: '操作',
            type: 'actionText',
            url: '',
            onclick: (rowData) => {
              this.change(rowData)
            },
            valueFunc: rowData => {
              if (rowData.type == 'apollo_request_post' || rowData.type == 'order') {
                if(rowData.status == 'open') {
                  return '未完了'
                } else if(rowData.status == 'done') {
                  return '完了済'
                }
              } else {
                return ''
              }
            }
          }
        ],
        [ { iconType: 'solid', title: 'ステータス', type: 'icon' } ],
        // [ { name: 'category2String', title: '社内/お客様', type: 'text', columnSortDisable: true } ],
        [ { name: 'categoryString', title: 'お問い合わせカテゴリー', type: 'text', columnSortDisable: true } ],
        [ { name: 'inquiry_title', title: 'タイトル', type: 'text', columnSortDisable: true } ],
        [ { name: 'clientName', title: 'お客様会社名', type: 'text', columnSortDisable: true } ],
        [ { name: 'clientPersonName', title: 'お客様担当者名', type: 'text', columnSortDisable: true }, ],
        // [ { name: 'toDepartment', title: 'アポロ管財依頼先部署', type: 'text', columnSortDisable: true } ],
        [ { name: 'toStaffName', title: '担当者', type: 'text', columnSortDisable: true } ],
        // [ { name: 'byUser', title: '依頼主', type: 'text', columnSortDisable: true } ],
        [ 
          { 
            name: 'limitDate', 
            title: '期限', 
            type: 'text',
            columnSortDisable: true,
            valueFunc: rowData => {
              const d = utils.stringToDate(rowData.limitDate)
              if (d != 'Invalid Date') {
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
              } else {
                return ''
              }
            }
          } 
        ],
        [
          // {
          //   name: 'operation',
          //   title: '操作',
          //   type: 'link',
          //   url: '/RequestPost?requestPostId=${record._id}&mode=2',
          //   valueFunc: requestPostData => {
          //     if (requestPostData.type == 'apollo_request_post') {
          //       return 'コピー'
          //     } else {
          //       return ''
          //     }
          //   }
          // }
          {
            name: 'operation',
            title: '操作',
            type: 'link',
            url: '/RequestPost?requestPostId=${record._id}&mode=3',
            valueFunc: requestPostData => {
              if (requestPostData.type == 'apollo_request_post') {
                return '複写'
              } else {
                return ''
              }
            }
          }
        ],
        [
          {
            name: 'editLink',
            title: '',
            type: 'link',
            url: rowData => {
              if (rowData.status != 'done') {
                if (rowData.type == 'apollo_request_post') {
                  return '/RequestPost?requestPostId=${record._id}&mode=1'
                } else if (rowData.type == 'order') {
                  return '/RequestPost?orderId=${record._id}&mode=6'
                }
              } else {
                return ''
              }
            },
            valueFunc: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                return '編集'
              } else {
                return ''
              }
            }
          }
        ],
        [
          {
            name: 'requestRegistration',
            title: '',
            type: 'link',
            url: rowData => {
              if (rowData.status != 'done') {
                if (rowData.type == 'apollo_request_post') {
                  if (rowData.temporarySaveFlag == true) {
                    return ''
                  } else {
                    return '/taskRequest?requestPostId=${record._id}'
                  }
                } else if (rowData.type == 'order') {
                  return '/taskRequest?orderId=${record._id}&mode=1'
                } else {
                  return ''
                }
              } else {
                return ''
              }
            },
            colorTable: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                if (rowData.temporarySaveFlag && rowData.temporarySaveFlag == true) {
                  return 'black'
                } else {
                  return 'blue'
                }
              } else {
                return ''
              }
            },
            valueFunc: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                if (rowData.temporarySaveFlag) {
                  return '仮登録'
                } else {
                  return '依頼登録'
                }
              } else {
                return ''
              }
            }
          }
        ],
      ],
      columnWidth,
      columnAlignment,
      data: [],
      filteredData: [],
      fetchDate: '',
      userName: '',
      // データが多すぎて表示不可
      overData: false,
      sortCount: 0
    }
  },

  watch: {
  },

  created() {
    this.userName = this.$store.getters.user.user_name
    this.fetchRequestPosts()

    // 初期ソート sortBy ⇒ sortCount 変換
    if (this.sortBy && this.sortItem) {
      this.sortCount = this.sortBy == -1 ? 0 : 1
    }
  },

  methods: {
    async fetchRequestPosts() {
      if (!this.freeze) {
        // console.log('RequestPostTable::fetchRequestPosts')
        this.$emit('search-start')
        this.fetchDate = utils.getToday()

        let key = this.searchKeyword
        let toStaffUserKey = ''
        let byUserKey = ''

        // ログインユーザーで担当者を絞込み
        if (this.filterToStaffUser) {
          toStaffUserKey = utils.deleteKana(this.userName)
        }

        // ログインユーザーで依頼主を絞込み
        if (this.filterByUser) {
          byUserKey = utils.deleteKana(this.userName)
        }

        // 期限フォーマット統一
        let limitDate = ''
        if (this.searchLimitDate) {
          limitDate = this.searchLimitDate.replace(/年/g, '-').replace(/月/g, '-').replace(/日/g, '')
        }

        this.data = await requestPostManager.searchRequestPosts(this, searchKeys, key, searchDateKey, this.period,
                              this.searchToDepartment,this.sortItem, this.sortBy, this.mainTask, this.withSub, this.orderTask, this.employmentTask,
                              toStaffUserKey, byUserKey, limitDate, this.searchCategory, this.searchClientName, this.searchClientPersonName,
                              this.searchToStaffName, this.searchByUser, this.searchStatus, this.$store.getters.user.id)

        this.overData = false
        if (this.data.error) {
          if (this.data.msg && this.data.msg.indexOf('limit') > -1) {
            this.overData = true
          } else {
            alert('絞り込み条件を追加してください。\r\n' + this.data.msg)
          }
          this.data = []
        }

        for (let index = 0; index < this.data.length; index++) {
          let category = this.data[index].category
          if (category) {
            this.data[index].categoryString = category.join(",")
          } else {
            this.data[index].categoryString = ''
          }

          let category2 = this.data[index].category2
          if (category2) {
            this.data[index].category2String = category2.toString()
          } else {
            this.data[index].category2String = ''
          }
        }

        // 配列項目ソート対応
        this.data = this.sortDataToArrayItem(this.data, this.sortItem, this.sortBy)

        // サブタスク表示
        if (this.withSub) {
          let s = []
          for (let index = 0; index < this.data.length; index++) {
            s.push(this.data[index])
            if (this.data[index].taskRequest) {
              for (let j = 0; j < this.data[index].taskRequest.length; j++) {
                let subTasks = this.data[index].taskRequest[j]
                subTasks.inquiry_title = this.data[index].inquiry_title + '　' + subTasks.requestTask
                s.push(subTasks)
              }
            }
            if(this.data[index].taskRequestOrder) {
              for (let j = 0; j < this.data[index].taskRequestOrder.length; j++) {
                let subTasks = this.data[index].taskRequestOrder[j]
                subTasks.inquiry_title = this.data[index].inquiry_title + '　' + subTasks.requestTask
                s.push(subTasks)
              }
            }
          }
          this.data = s
        }

        // ステータス列の表示アイコン切り替え
        let statusIcon
        statusIcon = this.data.map((original) => {
          if ((original.status == 'open' && original.limitDate < this.fetchDate)) {
            return { ...original, iconName: 'ExclamationCircle' }
          } else if(original.status == 'done') {
            return { ...original, iconName: 'CheckCircle' }
          } else if(original.status == 'open' && original.limitDate >= this.fetchDate) {
            return { ...original, iconName: '' }
          } else {
            return { ...original, iconName: 'MinusCircle' }
          }
        })
        this.data = statusIcon
        this.filteredData = this.data

        this.$emit('search-end', { res: this.data, overData: this.overData })
      }
    },

    rowClassesFunc(rowData) {
      if (rowData.limitDate < this.fetchDate && rowData.status == 'open') {
        return ['bg-red-100']
      } else if(rowData.status == 'done') {
        return ['bg-blue-100']
      } else if (rowData.limitDate == this.fetchDate && rowData.status == 'open') {
        return ['bg-yellow-100']
      } else {
        return ['bg-green-100']
      }
    },

    /**
     * リクエストポストID より タスク依頼IDを取得
     */
    // async getTaskRequestIdByRequestPostId(requestPostId) {
    //   // RPに紐づく、タスク依頼セットを検索
    //   const tr = await taskRequestManager.getByRequestPostId(this, requestPostId, null)
    //   // 見つかった場合、IDを返却
    //   if (tr) {
    //     return tr._id
    //   } else {
    //     return null
    //   }
    // },

    /**
     * 未完了ボタンを押下後、データを取得
     * @param {*} rowData 
     */
    change(rowData) {
      this.$emit('change', rowData)
    },

    /**
     * 行 ダブルクリック イベント
     * @param {*} item 
     */
     dblClickRow(item) {
      if (item.type == 'apollo_request_post') {
        this.$router.push({ name: 'RequestPost', query: { mode: 1, requestPostId: item._id }})
      } else if (item.type == 'order') {
        this.$router.push({ name: 'RequestPost', query: { mode: 6, orderId: item._id }})
      } else if (item.type == 'task_request') {
        this.$router.push({ name: 'RequestPost', query: { mode: 1, requestPostId: item.requestPostId }})
      } else if (item.type == 'task_request_order') {
        this.$router.push({ name: 'RequestPost', query: { mode: 6, orderId: item.orderId }})
      }
     },   

     /*
     * 一覧データ 各項目のソート切り替え
     * @param {*} column 
     * @param {*} sort 
     */
    async changeSort(column, sort) {
      // this.sortBy = sort
      // this.sortItem = column[0].name
      await this.$emit('change-sort', { item: column[0].name, by: sort})
      this.fetchRequestPosts()
    },


    /**
     * 一覧データ 配列項目ソート
     * @param {*} data 一覧データ
     * @param {*} item ソート項目
     * @param {*} orderby ソート順
     */
    sortDataToArrayItem(data, item, orderby) {

      if (orderby == 1 ) {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString > b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString < b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      if (orderby == 1 ) {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String > b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String < b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      return data
    }
  },
}
</script> 
