<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in price1" :key="i" class="hover-color text-right">
          <th class="th-name">
            <div v-if="c.name" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="price1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price1[i].price"
                @change="changeData(price1[i])"
              />
            </div>
          </td>
        </tr>
      </table>

      <table class="mb-5 ml-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column2" :key="i">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in price2" :key="i" class="hover-color text-right">
          <th class="th-name">
            <div v-if="c.name" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="price2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="price2[i].price"
                @change="changeData(price2[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 管球交換
 */
const CATEGORY = '23'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 種別カラム
      column1: [
        { name: '', class: 'bg-white'},
        { name: 'LED以外', class: 'ml-5 bg-teal-50'},
      ],
      // 種別カラム
      column2: [
        { name: '', class: 'bg-white'},
        { name: 'LED', class: 'ml-5 bg-blue-50'},
      ],
      // LED以外
      price1: [],
      // LED
      price2: [],
    }
  },

  created() {
    this.dataByCategory()
  },
  
  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      this.price1 = []
      this.price2 = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode
        switch (code) {
          // LED以外
          case '1':
            this.price1.push(d)
            break;

          // LED
          case '2':
            this.price2.push(d)
            break;
        }
      }
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode
      let name1 = ''
      let name2 = ''

      if (code == '1') {
        name1 = 'LED以外'
      } else {
        name1 = 'LED'
      }

      name2 = `（${target.name}）`
      target.itemName = name1 + name2
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}

.th-name {
  min-width: 150px;
}
</style>
