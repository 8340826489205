<template>
  <div>
    <PageHeader title="apollo-RP - 得意先毎の未点検" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            text="検索"
            class="ml-auto flex button-contents"
            @click="getData()"
            :buttonContents="'要'"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center ml-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="ml-2 flex">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex justify-between">
          <h1 class="font-bold text-white flex items-center">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">未点検数を得意先毎に表示</span>
          </h1>
          <!-- サイクル期限 -->
          <div class="ml-10 mr-auto">
            <div class="mr-3 mb-1 text-white items-center text-xs">
              サイクル期限
            </div>
            <div class="flex">
              <input type="number" name="termYearFilter" placeholder="サイクル期限（年）"
                v-tooltip="alertYear ? '4桁で入力してください' : ''"
                class="w-16 py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline"
                :class="alertYear ? 'border-red-600' : ''" v-model="termYearFilter" />
              <div class="ml-1 mr-2 my-auto text-white">年</div>
              <input type="text" name="termMonthFilter" placeholder="サイクル期限（月）"
                v-tooltip="alertMonth ? '不正な値が入っています' : 'スペースで複数月検索可能'"
                class=" py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 inline"
                :class="alertMonth ? 'border-red-600' : ''" v-model="termMonthFilter" />
              <div class="ml-1 mr-1 my-auto text-white">月</div>
            </div>
          </div>

          <div class="ml-auto mr-2 mt-5">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterClient" 
              placeholder="得意先名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterClient"
            />
          </div>
          <div class="mr-2 mt-5">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterBranch" 
              placeholder="支店名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterBranch"
            />
          </div>
          <div class="flex justify-center mt-5">
            <NumericTextInput
              name="startCount"
              placeholder="以上"
              v-model="startCount"
              class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 rounded-md" 
            />
            <div class="my-2 mx-3 text-base text-white">~</div>
            <NumericTextInput
              name="endCount"
              placeholder="以下"
              v-model="endCount"
              class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent border-gray-300 rounded-md"
            />
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 mt-5 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-show="!loading">

            <div class="overflow-hidden rounded main-height1">
              <table class="main table-auto">
                <thead class="main">
                  <tr v-if="list && list.length" class="border border-gray-200">
                    <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                      {{c.title}}
                    </th>
                  </tr>
                </thead>

                <tr v-for="(row, i) in list" :key="'r' + i" class="border border-gray-200 tracking-wider hover-color" :class="setBgColor(row)">
                  <!-- 得意先名 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-200"
                    v-tooltip="'得意先コード： ' + row.clientCode">
                    {{ row.clientName1 }}
                    <p v-if="row.dateCancellation && row.dateCancellation!='2999年12月'" class="text-xs text-blue-500">（解約月 : {{row.dateCancellation}}）</p>
                    <p v-else-if="row.cancelNextFlag&&row.cancelNextFlag=='1'" class="text-xs text-green-500">（次年度解約, 備考：{{row.cancelReason}}）</p>
                  </td>
                  <!-- 支店名 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-180">
                    {{ row.clientName2 }}
                  </td>
                  <!-- 未点検数 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-180 text-center">
                    {{ row.count }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as utils from '@libs/utils'
import * as moment from 'moment'
import * as backend from '@libs/backend'

// セッションストレージ保存名
const sessionStorageItemName = 'countUnPatrolByClientCondition'

// 区分 1：品質巡回、2：巡回清掃、3：定期清掃
const type = 1
// クレーム対応
const claim = false

export default {
  components: {
    Icon,
    PageHeader,
    NumericTextInput,
    PrimaryButton
  },

  data() {
    return {
      loading: false,

      // カラム
      columns: [
          {title: '得意先名', class: 'text-left'},
          {title: '支店名', class: 'text-left'},
          {title: '未点検数', class: 'text-center'},
      ],
      // サイクル期限絞り込み
      termYearFilter: moment().format('YYYY'),
      // サイクル期限不正入力
      alertYear: false,
      // サイクル期限絞り込みリスト
      termMonthFilter: '',
      // サイクル期限不正入力
      alertMonth: false,
      // 得意先名絞込み
      filterClient: '',
      // 支店名絞込み
      filterBranch: '',
      // 未点検数 開始
      startCount: '',
      // 未点検 終了
      endCount: '',
      // テーブル全データ
      original: [],
      // 表示するデータ
      list: [],
    }
  },

  watch: {
    /**
     *  サイクル期限（年）桁数
     */
    termYearFilter() {
      if (this.termYearFilter.length != 4) {
        this.alertYear = true
      } else {
        this.alertYear = false
      }
    },

    /**
     * サイクル期限（月）半角変換
     */
    termMonthFilter() {
      this.termMonthFilter = utils.hankaku(this.termMonthFilter)
      let tm = this.termMonthFilter
      if (tm == '') {
        this.alertMonth = false
        return
      }
      tm = tm.trim()
      let arr = tm.split(' ')
      for (let i = 0; i < arr.length; i++) {
        const a = arr[i]
        if (isNaN(a) || a < 1 || a > 12) {
          this.alertMonth = true
          return
        }
      }
      this.alertMonth = false
    },
  },

  async created() {
    let tmf = this.setDefaultMonth()
    this.termMonthFilter = tmf
    this.getState(tmf)
    await this.getData()
  },

  methods: {
    /**
     * リフレッシュボタン
     */
    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },
    /**
     * 未点検一覧データ取得
     */
    async getData() {
      // サイクル期限チェック
      if (this.termYearFilter == '') {
        alert('サイクル期限（年）を入力してください。')
        return
      }
      if (this.alertYear) {
        alert('サイクル期限（年）は4桁で入力してください。')
        return
      }
      if (this.termMonthFilter == '') {
        alert('サイクル期限（月）を入力してください。')
        return
      }
      if (this.alertMonth) {
        alert('サイクル期限（月）の値が不正です。')
        return
      }
      // 検索条件保存
      this.saveState()

      this.loading = true
      this.original = []
      this.termMonthFilter = this.termMonthFilter.trim()
      let arr = this.termMonthFilter.split(' ')
      // 重複削除
      arr = utils.unique(arr)
      // ソート
      arr.sort(function (a, b) {
        return a - b
      })
      const sd = ('00' + arr[0]).slice(-2)
      const startDay = moment(this.termYearFilter + '-' + sd + '-' + '01').format('YYYY-MM-DD')
      const ed = ('00' + arr[arr.length - 1]).slice(-2)
      const endDay = moment(this.termYearFilter + '-' + ed + '-' + '01').endOf('month').format('YYYY-MM-DD')

      // AKBSから得意先データ取得 物件ごと
      const akbs = await backend.searchData('akbs/getClientAndSite', { startDate: startDay, endDate: endDay, filterSite: null, filterClient: this.filterClient, filterBranch: this.filterBranch, filterAddress: null, type })
      // AKBSから得意先データ取得 得意先毎
      const akbsCancel = await backend.searchData('akbs/getPatrolClient', { startDate: startDay, endDate: endDay, filterClient: this.filterClient, filterBranch: this.filterBranch, type })
      
      let client = []
      if (akbs.data.data) {
        client = akbs.data.data
      }
      let cancelClient = []
      if (akbsCancel.data.data) {
        let cancel = akbsCancel.data.data

        if (cancel && cancel.length) {
          cancelClient = cancel.filter((c) => {
            return (c.dateCancellation != '' || c.cancelNextFlag != '' || c.cancelNextFlagYear != 9999)
          })
        }

      }
      // 期限、予定、完了日取得
      const termData = await backend.searchData('patrolTerm/getUnPatrolList', { year: this.termYearFilter, startDate: startDay, endDate: endDay, termMonthFilter: arr.join(','), type, claim })
      // デフォルト値取得
      let def = []
      let bySite = {}
      for (let k = 0; k < termData.data.data.base.length; k++) {
        const t2 = termData.data.data.base[k]
        if (t2.siteCode == '-1') {
          def.push(t2)

        // 物件コードごとにサイクル期限配列を生成
        } else {
          if (bySite[t2.siteCode]) {
            bySite[t2.siteCode].limitArr.push(t2.term)
            if (t2.isTerm) {
              bySite[t2.siteCode].isTerm = true
            }
          } else {
            bySite[t2.siteCode] = { limitArr: [t2.term], isTerm: t2.isTerm }
          }
        }
      }
      // データ結合 基本情報
      let result = []
      for (let i = 0; i < client.length; i++) {
        const c = client[i]
        let flag = false
        let termResult = termData.data.data.base
        for (let j = 0; j < termResult.length; j++) {
          const t = termResult[j]
          if (c.siteCode == t.siteCode) {
            flag = true
            c.limit = t.term
            c.scheduledDate = t.scheduledDate
            result.push(utils.clone(c))

            // サイクル期限が設定されていない物件（その他、weightや予定日等はデータがあるもの）
            if (!bySite[t.siteCode].isTerm) {
              for (let k = 0; k < def.length; k++) {
                const de = def[k]
                if (t.term != de.term) {
                  if (!bySite[t.siteCode].limitArr.includes(de.term)) {
                    c.limit = de.term
                    c.scheduledDate = null

                    bySite[t.siteCode].limitArr.push(de.term)
                    result.push(utils.clone(c))
                  }
                }
              }
            }
          }
        }
        // 物件コードがないものは、デフォルト値(3,6,9,12が含む場合のみ)
        if (!flag && def.length) {
          for (let di = 0; di < def.length; di++) {
            const d = def[di]
            c.limit = d.term
            c.scheduledDate = d.scheduledDate
            result.push(utils.clone(c))
          }
        }
      }

      // 表示対象のサイクル期限がある物件のみ取得
      let result2 = result.filter((c2) => {
        return c2.limit
      })

      // 得意先ごとに要点検数と未点検数を算出
      if (result2 && result2.length) {
        let result3 = []
        let clientCdArr = []
        for (let i = 0; i < result2.length; i++) {
          const r2 = result2[i]
          // all⇒要点検数、done⇒既に点検完了
          let done = 0
          if (r2.scheduledDate) {
            done = 1
          }
          if (clientCdArr.includes(r2.clientCode)) {
            for (let j = 0; j < result3.length; j++) {
              const r3 = result3[j]
              if (r3.clientCode == r2.clientCode) {
                r3.all++
                r3.done += done
              }
            }
          } else {
            let row = { clientCode: r2.clientCode, clientName1: r2.clientName1, clientName2: r2.clientName2, all: 1, done }

            // 解約データをジョイン
            for (let c = 0; c < cancelClient.length; c++) {
              const can = cancelClient[c]
              if (can.clientCode == r2.clientCode) {
                row.dateCancellation = can.dateCancellation
                row.cancelNextFlag = can.cancelNextFlag
                row.cancelNextFlagYear = can.cancelNextFlagYear
              }
            }
            result3.push(row)
            clientCdArr.push(r2.clientCode)
          }
        }
        result3.forEach((re) => {
          re.count = re.all - re.done
        })
        this.original = result3
      }
      this.filtering()
    },

    /**
     * 未点検数 絞り込み
     */
    filtering() {
      this.list = []
      if (this.startCount == '' && this.endCount == '') {
        this.list = this.original
        this.loading = false
        return
      }

      this.loading = true
      if (this.original.length) {
        let res = []
        let start = 0
        let end = null
        if (this.startCount != '') {
          start = Number(this.startCount)
        }
        if (this.endCount != '') {
          end = Number(this.endCount)
        }

        for (let i = 0; i < this.original.length; i++) {
          const o = this.original[i]
          if (end == null) {
            if (o.count >= start) {
              res.push(o)
            }
          } else {
            if (o.count >= start && o.count <= end) {
              res.push(o)
            }
          }
        }
        this.list = res
      }
      this.loading = false
    },

    /**
     * 検索条件を保存
     */
    saveState() {
      const newState = {}
      newState.termYearFilter = this.termYearFilter
      newState.termMonthFilter = this.termMonthFilter
      newState.filterClient = this.filterClient
      newState.filterBranch = this.filterBranch
      newState.startCount = this.startCount
      newState.endCount = this.endCount

      const searchStr = JSON.stringify(newState)
      sessionStorage.setItem(sessionStorageItemName, searchStr)
    },

    /**
     * 検索条件をセット
     */
    getState(term) {
      const searchJson = sessionStorage.getItem(sessionStorageItemName)
      let searchCondition
      if (searchJson) searchCondition = JSON.parse(searchJson)

      if (searchCondition) {
        // 前回の検索条件をロード
        this.termYearFilter = searchCondition.termYearFilter ? searchCondition.termYearFilter : moment().format('YYYY')
        this.termMonthFilter = searchCondition.termMonthFilter ? searchCondition.termMonthFilter : term
        this.filterClient = searchCondition.filterClient ? searchCondition.filterClient : ''
        this.filterBranch = searchCondition.filterBranch ? searchCondition.filterBranch : ''
        this.startCount = searchCondition.startCount ? searchCondition.startCount : ''
        this.endCount = searchCondition.endCount ? searchCondition.endCount : ''
      }
    },

    /**
     * 月絞り込みデフォルト設定
     */
    setDefaultMonth() {
      let tmf = ''
      const d = moment().format('MM')
      switch (d) {
        case '01':
        case '02':
        case '03':
          tmf = '1 2 3'
          break;

        case '04':
        case '05':
        case '06':
          tmf = '4 5 6'
          break;

        case '07':
        case '08':
        case '09':
          tmf = '7 8 9'
          break;

        case '10':
        case '11':
        case '12':
          tmf = '10 11 12'
          break;
      }
      return tmf
    },

    /**
     * 行の背景色をセット
     * @param row 対象データ
     */
    setBgColor(row) {
      // 解約月がある場合
      if (row.dateCancellation && row.dateCancellation != '') {
        let target = row.dateCancellation.replace(/年|月/g, '-') + '01'
        target = moment(target).format('YYYYMM')
        let today = moment().format('YYYYMM')
        // 解約月が今月より前の場合、グレー
        if (Number(target) < Number(today)) {
          return 'bg-gray-200'
        }
      }
      // 次年度解約フラグがある場合
      if (row.cancelNextFlag && row.cancelNextFlag == '1') {
        return 'bg-yellow-100'
      }
      return ''
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
  },
}
</script>
<style scoped>
.list {
  overflow: auto;
}

.main {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.col-no {
  width: 70px;
}

.min-200 {
  min-width: 200px;
}

.min-180 {
  min-width: 180px;
}

.min-164 {
  min-width: 164px;
}

.min-120 {
  min-width: 121px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.min-300 {
  min-width: 300px;
}

.w-70p {
  width: 70%;
}

.w-30p {
  width: 30%;
}

.main-height1 {
  height: 100%;
}

.hover-color:hover {
  background: rgb(236, 236, 251);
}

@media screen and (max-width: 1600px) {
  .list {
    min-width: 1600px !important;
  }

  .main-height1 {
    min-width: 1220px !important;
  }
}
</style>