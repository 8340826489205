import EstimationApp from './EstimationApp.vue'
import EstimateList from './pages/EstimateList.vue'
import EstimateForm from './pages/EstimateForm.vue'
import EstimateView from './pages/EstimateView.vue'
import EstimateApprovalMaster from './pages/EstimateApprovalMaster.vue'
import EstimatePriceMaster from './pages/EstimatePriceMaster.vue'
import EstimateChart from './pages/EstimateChart.vue'
import EstimateWorkCodeMaster from './pages/EstimateWorkCodeMaster.vue'

export default {
  path: '/Estimation',
  // name: "Estimation",
  component: EstimationApp,
  children: [
    {
      path: '/EstimateList',
      name: 'EstimateList',
      component: EstimateList,
      meta: { requiresAuth: true, title: 'apollo-RP - 見積リスト' },
      props: true
    },
    {
      path: '/Estimate',
      name: 'EstimateForm',
      component: EstimateForm,
      meta: { requiresAuth: true, title: 'apollo-RP - 見積作成' },
      props: true
    },
    {
      path: '/EstimateView',
      name: 'EstimateView',
      component: EstimateView,
      meta: { requiresAuth: true, title: 'apollo-RP - 見積参照' }
    },
    {
      path: '/EstimateApprovalMaster',
      name: 'EstimateApprovalMaster',
      component: EstimateApprovalMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 承認ルート' }
    },
    {
      path: '/EstimatePriceMaster',
      name: 'EstimatePriceMaster',
      component: EstimatePriceMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 見積り単価' }
    },
    {
      path: '/EstimateChart',
      name: 'EstimateChart',
      component: EstimateChart,
      meta: { requiresAuth: true, title: 'apollo-RP - 見積集計' }
    },
    {
      path: '/EstimateWorkCodeMaster',
      name: 'EstimateWorkCodeMaster',
      component: EstimateWorkCodeMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 作業内容' }
    },
  ]
}
