<template>
  <div>
    <CheckListComponent
      typeName="定期清掃 完了一覧"
      :type="3"
      :claim="false"
      settionStrageName="FixedCheckListStorageItemName">
    </CheckListComponent>
  </div>
</template>

<script>
import CheckListComponent from '../../Patrol/components/CheckListComponent.vue'

export default {
  components: {
    CheckListComponent
  },
}
</script>