<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5 mt-5">
        <tr class="sticky top-0 z-20">
          <th colspan="2" class="bg-teal-50 p-3">
            <div class="p-1 ml-1">
              単価
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="price[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price[i].price"
                @change="changeData(price[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 特定建築物定期調査
 */
const CATEGORY = '17'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 清掃回数カラム
      column: [
        { name: '最低金額' },
        { name: '標準金額' },
      ],
      // 単価
      price: [],
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      this.price = this.setSort(dbc)
    },

    /**
     * 戸数によるソート
     * @param target 対象データ
     */
    setSort(target) {
      const sortArr = ['1', '2']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[1] == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''

      if (code[1] == '1') {
        name1 = '最低金額'
      } else {
        name1 = '標準金額'
      }
      target.itemName = name1
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
