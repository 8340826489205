<template>
  <div>
    <label :for="name" class="block text-xs font-bold text-gray-700 relative">
      {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="mt-1 flex relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" />
      <DateInput 
        :value="value"
        class="w-full"
        :class="error ? 'error' : ''"
        v-bind="$attrs" 
        @change="$emit('change', $event)"
        :min="min"
        :max="max"
        :availableDate="availableDate"
      />
    </div>
  </div>
</template>
<script>
import InputIcon from '@components/InputIcon.vue'
import DateInput from '@components/DateInput.vue'
export default {
  inheritAttrs: false,
  components: {
    InputIcon,
    DateInput
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: [
    'name', 
    'caption', 
    'disabled', 
    'value',     
    'required',
    'error',
    'errorMessage',
    'errorColor',
    'min',
    'max',
    'availableDate'
  ],
  watch: {
    value() {
      console.log(`${this.name} : change`)
      this.$emit('change', this.value)
    }
  }  
}
</script>
