<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="evInspectContentSelections"
    :multiSelect="false"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

const evInspectContentSelections = [
  {
    value: 'POG',
    text: 'POG'
  },
  {
    value: 'フルメンテナンス',
    text: 'フルメンテナンス'
  }
]

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalValue() {
      const value = this.value.map((v) => {
        return this.evInspectContentSelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  data() {
    return {
      evInspectContentSelections
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>