<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <div v-if="loading" class="flex justify-center items-center loading-dialog"></div>
        <div class="overflow-hidden rounded main-height">
            <table class="main">
                <thead class="main">
                    <tr>
                        <th v-for="(f, index) in columns" :key="index" scope="col" class="px-6 text-center text-xs font-medium text-gray-500 tracking-wider" :class="f.class">
                            <div v-if="f.title == '部署'" class="flex">
                                <PrimaryButton text="受託" size="w-full normal px-1 py-1 btn app-btn-w mr-1" :class="department!=1?'bg-gray-300':''" @click="filterDepartment(1)" />
                                <PrimaryButton text="全体" size="w-full normal px-1 py-1 btn app-btn-w" :class="department!=2?'bg-gray-300':''" @click="filterDepartment(2)" />
                            </div>
                            <div v-if="f.title == '基準日(開始)'" class="flex ">
                                <WmsDateInput
                                    name="startDay"
                                    caption="基準日(開始) 検索"
                                    v-model="startDay"
                                    class="w-10/12" 
                                    @change="getList()"
                                />
                                <span class="mt-auto mx-auto mb-1">から</span>
                            </div>
                            <div v-if="f.title == '基準日(終了)'" class="flex">
                                <WmsDateInput
                                    name="endDay"
                                    caption="基準日(終了) 検索"
                                    v-model="endDay"
                                    class="w-10/12" 
                                    @change="getList()"
                                />
                                <span class="mt-auto mx-auto mb-1">まで</span>
                            </div>
                            <div v-if="f.title == '集計実行日'" class="">
                                <input
                                    type="search"
                                    name="insertDate" 
                                    placeholder="集計実行日 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="insertDate"
                                    @change="getList()"
                                />
                            </div>
                            <div v-if="f.title == '集計実行時間'" class="">
                                <input
                                    type="search"
                                    name="insertTime" 
                                    placeholder="集計実行時間 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="insertTime"
                                    @change="getList()"
                                />
                            </div>
                            <div v-if="f.title == '集計した人'" class="">
                                <input
                                    type="search"
                                    name="insertUser" 
                                    placeholder="集計した人 検索"
                                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                                    v-model="insertUser"
                                    @change="getList()"
                                />
                            </div>
                            <div v-else></div>
                        </th>
                    </tr>
                    <tr v-if="list && list.length" class="border border-gray-200">
                        <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                            {{c.title}}
                        </th>
                    </tr>
                </thead>

                    <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color" @dblclick="decide(row)">
                        <!-- 部署 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.department }}
                        </td>

                        <!-- 基準日開始 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.startDay }}
                        </td>
                        
                        <!-- 基準日終了 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.endDay }}
                        </td>
                        
                        <!-- 集計実行日 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.insertDay }}
                        </td>
                        
                        <!-- 集計実行時間 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.insertTime }}
                        </td>
                        
                        <!-- 名前 -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                            {{ row.insertUserName }}
                        </td>
                    </tr>
            </table>
            <div v-if="!list || !list.length" class="main w-full h-full flex justify-center items-center">
                <p class="text-gray-400 font-bold">対象データがありません</p>
            </div>
        </div>

        <!-- <div class="mt-5">
            <PrimaryButton text="決定" size="w-full normal px-5 py-3 btn app-btn-w" @click="decide()" />
        </div> -->
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import * as backend from '@libs/backend'
import * as moment from 'moment'
export default {
    components: {
        Modal,
        PrimaryButton,
        WmsDateInput
    },

    props: ['open'],

    data() {
        return {
            // カラム
            columns: [
                // {title: '集計単位'},
                {title: '部署', class: 'py-3'},
                // {title: '区分'},
                // {title: '基準年度'},
                {title: '基準日(開始)', class: 'pt-1 pb-6'},
                {title: '基準日(終了)', class: 'pt-1 pb-6'},
                {title: '集計実行日', class: 'py-3'},
                {title: '集計実行時間', class: 'py-3'},
                {title: '集計した人', class: 'py-3'}
            ],
            // データ
            // originalList: [],
            // 表示データ
            list: [],
            // 集計単位
            // unit: null,
            // 部署
            department: null,
            // 区分
            // type: null,
            // 基準年度
            // year: null,
            // 基準日
            startDay: moment().subtract(4, 'months').format('YYYY/MM/DD'),
            endDay: '',
            // 集計実行日
            insertDate: '',
            // 集計実行時間
            insertTime: '',
            // 集計した人
            insertUser: '',
            // ローディング中
            loading: false
        }
    },

    computed: {
    },

    // created() {
    //     this.getList()
    // },

    watch: {
        async department() {
            await this.getList()
        },
        
        // async startDay() {
        //     await this.getList()
        // },
        
        // async endDay() {
        //     await this.getList()
        // },
        
        // async insertDate() {
        //     await this.getList()
        // },
        
        // async insertTime() {
        //     await this.getList()
        // },
        
        // async insertUser() {
        //     await this.getList()
        // },

        open() {
            this.getList()
        }
    },

    methods: {
        /**
         * 初期化
         */
        closed() {

        },

        /**
         * データ取得
         */
        async getList() {
            // this.list = []
            this.loading = true
            let filter = { department: this.department, startDay: this.startDay, endDay: this.endDay, insertDate: this.insertDate, insertTime: this.insertTime, insertUser: this.insertUser }
            let result = await backend.searchData('weekHistoryKeyword/getData', filter)
            if (result.data.data) {
                this.list = result.data.data
            } else {
                this.list
            }
            this.loading = false
        },

        /**
         * 単位 検索
         */
        // filterUnit(val) {
        //     this.unit = val
        //     this.getList()
        // },

        /**
         * 部署 検索
         */
        filterDepartment(val) {
            this.department = val
            this.getList()
        },

        /**
         * 区分 検索
         */
        // filterType(val) {
        //     this.type = val
        //     this.getList()
        // },

        /**
         * 決定イベント
         * @param row 対象行
         */
        decide(row) {
            console.log(row)
            this.$emit('closeModal', row)
        }
    }
}
</script>
<style scoped>
.main-height {
    max-height: calc(100% - 100px);
}

.main {
    min-width: 700px;
}
.col-no {
    width: 70px;  
}

.min-300 {
    min-width: 300px;
}

.min-70 {
    min-width: 70px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: #ebf3fb;
}

.hover-color:hover {
    background: rgba(183, 238, 236, 0.67);
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>