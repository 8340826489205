<template>
  <div>
    <PageHeader :title="`apollo-RP - ${typeName}`" class="">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            text="検索"
            class="button-contents  ml-auto"
            @click="getData"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center mr-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>

          <div class="mr-2">
            <!-- 点検項目 未設定 -->
            <div class="ml-auto">
              <PrimaryButton  v-tooltip="'デフォルト以外で絞り込み'" text="点検項目 デフォルト以外" :class="filterSetItem ? 'clickButtonColor' : 'normalButtonColor'" @click="filterBtn()">
                点検項目 デフォルト以外
              </PrimaryButton>
            </div>
          </div>

          <PrimaryButton text="クリア" class="normalButtonColor flex mr-2" @click="clear" :buttonContents="'要'" />

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-2 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="" class="w-7 h-7 mr-2"/> <span class="mt-0">得意先一覧</span>
          </h1>
          
          <div class="rounded ml-10">
            <div class="flex items-center">
              <div class="font-bold text-xs mb-1 text-white ml-2 mt-2">
              契約期間
              <span v-if="disabledBtn" class="ml-1 text-red-500"> * 日付を選択してください </span>
            </div>
              <div class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="startDay"
                  v-model="startDay"
                />
              </div>
              <div class="text-white font-bold text-2xl">～</div>
              <div v-if="!startDay || startDay==''" class="rounded mx-5 mb-2">
                <WmsTextInput 
                  name="endDay" 
                  :disabled="true"
                />
              </div>
              
              <div v-else class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="endDay"
                  v-model="endDay"
                  :min="minEndDay"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-1 ml-8  justify-end">
            <div class="mr-2">
              <input
                v-tooltip="'記号・スペースは無視します'"
                type="search"
                name="filterClient" 
                placeholder="得意先名 検索"
                class="w-full mb-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                v-model="filterClient"
              />
            </div>

            <div class="">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterBranch" 
              placeholder="支店名 検索"
              class="w-full pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterBranch"
            />
            </div>
          </div>
        </div>
      </template>
    </PageHeader>

    <main class="absolute top-28 w-screen bottom-4 mt-4 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-if="!loading && list">

            <div class="rounded main-height">
              <table class="main table-auto overflow-auto">
                  <thead class="main sticky top-0">
                    
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'l'+i" class="border border-gray-200 bg-white tracking-wider" :class="setBgColor(row)">

                  <!-- No -->
                  <td class="b-top text-left px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no cursor-not-allowed">
                    {{ i + 1 }}.
                  </td>

                  <!-- 得意先名 -->
                  <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700 min-300" v-tooltip="'得意先コード： ' + row.clientCode">
                    {{ row.clientName1 }}
                    <p v-if="row.flagCancellation && row.flagCancellation == '1' && row.dateCancellation && row.dateCancellation != '' && row.dateCancellation != '2999年12月'" class="text-xs text-blue-500">（解約月 : {{row.dateCancellation}}）</p>
                    <p v-else-if="row.cancelNextFlag&&row.cancelNextFlag=='1'" class="text-xs text-green-500">（次年度解約）</p>
                  </td>
                  <!-- 支店名 -->
                  <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
                    {{ row.clientName2 }}
                  </td>
                  <!-- 点検項目 -->
                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-700 min-100">
                    <p v-if="row.item" class="box2">デフォルト以外</p>
                    <p v-else class="box">デフォルト</p>
                  </td>
                  <!-- 編集ボタン -->
                  <td class="px-6 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="flex justify-center">
                      <PrimaryButton text="編集" size=" normal px-2 py-2 btn app-btn-w" @click="edit(row)"/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 項目設定 -->
    <CheckListSettingModal 
      v-model="openCheckListSettingModal"
      :target="row"
      :typeName="typeName"
      :type="type"
      :open="openCheckListSettingModal"
      @modalClose="modalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import CheckListSettingModal from '../components/CheckListSettingModal'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import * as utils from '@libs/utils'

const APOLLO = {
  clientCode: '9999999999',
  clientName1: 'アポロ管財テスト用',
  clientName2: 'テスト'
}

export default {
  components: {
    Icon,
    PrimaryButton,
    WmsDateInput,
    WmsTextInput,
    PageHeader,
    CheckListSettingModal
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      loading: false,
      startDay: moment().format('YYYY-01-01'),
      endDay: moment().format('YYYY-12-31'),
      
      //検索保持用
      searchCondition: {
        startDay : '',
        endDay :  '',
        filterClient : '',
        filterBranch : '',
        filterSetItem : false
      },
      // カラム
      columns: [
          {title: 'No.'},
          {title: '得意先名'},
          {title: '支店名'},
          {title: '点検項目'},
          {title: ''},
      ],
      // 点検項目 デフォルト以外
      filterSetItem: false,
      // 得意先名絞込み
      filterClient: '',
      // 支店名絞込み
      filterBranch: '',
      // テーブルデータ
      original: [],
      // 表示するデータ
      list: [],
      // モーダル表示、非表示
      openCheckListSettingModal: false,
      // モーダルに渡すデータ
      row: {}
    }
  },

  computed: {
    /**
     * 検索ボタン 活性・非活性
     */
    disabledBtn() {
      let result = false
      if (!this.startDay || this.startDay == '' || !this.endDay || this.endDay == '') {
        result = true
      }
      return result
    },

    /**
     * 終了日の最小値
     */
    minEndDay() {
      let result = ''
      if (this.startDay == '') {
        return result
      } else {
        return this.startDay
      }
    }
  },

  created() {
    this.getCondition()
    this.getData()
  },

  methods: {
    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.getData()
      await utils.wait(500)
      this.loading = false
    },
    
    /**
     * セッションストレージデータ取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)

      if(getItem !== null && getItem !== undefined){
        this.startDay = getItem.startDay
        this.endDay = getItem.endDay
        this.filterClient = getItem.filterClient
        this.filterBranch = getItem.filterBranch
        this.filterSetItem = getItem.filterSetItem
      }
    },

    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      if (!this.startDay || !this.endDay) {
        await dialogs.showErrorDialog('契約期間', '契約期間の範囲指定をしてください。')
        return
      }

      // セッションストレージ保存用
      this.searchCondition.startDay = this.startDay
      this.searchCondition.endDay = this.endDay
      this.searchCondition.filterClient = this.filterClient
      this.searchCondition.filterBranch = this.filterBranch
      this.searchCondition.filterSetItem = this.filterSetItem
      
      // セッションストレージ保存
      this.setSessionStorage(this.searchCondition, this.settionStrageName)

      // AKBSから得意先データ取得
      const akbs = await backend.searchData('akbs/getPatrolClient', {startDate: this.startDay, endDate: this.endDay, filterClient: this.filterClient, filterBranch: this.filterBranch, type: this.type})
      
      let client = []
      if (APOLLO.clientName1.indexOf(this.filterClient) != -1 && APOLLO.clientName2.indexOf(this.filterBranch) != -1) {
        client = [APOLLO]
      }
      if (akbs.data.data) {
        client = client.concat(akbs.data.data)
        const getGroupByClient = await backend.searchData('checkListByClient/getGroupByClient', { type: this.type })
        if (getGroupByClient.data.data) {
          for (let i = 0; i < getGroupByClient.data.data.length; i++) {
            const gbc = getGroupByClient.data.data[i]
            for (let j = 0; j < client.length; j++) {
              const c = client[j]
              if (gbc.clientCode == c.clientCode) {
                c.item = true
                break
              }
            }
          }
        }
      }
      this.original = client
      this.filtering('item')
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    /**
     * ボタン切替
     */
    filterBtn() {
      this.filterSetItem = !this.filterSetItem
    },

    /**
     * 検索
     * @param target 絞込み対象
     */
    filtering(target) {
      // 点検項目
      if (target == 'item') {
        this.list = []
        if (this.original.length) {
          if (this.filterSetItem) {
            this.list = this.original.filter((o) => {
              return o.item
            })
          } else {
            this.list = this.original
          }
        } else {
          this.list = this.original
        }
      }
      this.loading = false
    },

    /**
     * 編集ボタンイベント
     * @param row 対象行
     */
    edit(row) {
      this.openCheckListSettingModal = true
      this.row = row
    },

    /**
     * モーダル閉じる
     */
    async modalClose() {
      this.openCheckListSettingModal = false
      await this.getData()
    },

    /**
     * 行の背景色をセット
     * @param row 対象データ
     */
    setBgColor(row) {
      // 解約月がある場合
      if (row.flagCancellation && row.flagCancellation == '1' && row.dateCancellation && row.dateCancellation != '' && row.dateCancellation != '2999年12月') {
        let target = row.dateCancellation.replace(/年|月/g, '-') + '01'
        target = moment(target).format('YYYYMM')
        let today = moment().format('YYYYMM')
        // 解約月が今月より前の場合、グレー
        if (Number(target) < Number(today)) {
          return 'bg-gray-200'
        }
      }
      // 次年度解約フラグがある場合
      if (row.cancelNextFlag && row.cancelNextFlag == '1') {
        return 'bg-yellow-100'
      }
      return ''
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
  },
}
</script>
<style scoped>
.list {
  overflow: auto;
}

.main {
  width: 100%;
}

.bg-colu {
    background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-300 {
    min-width: 300px;
}

.min-100 {
    min-width: 100px;
}

.box {
  font-weight: bold;
  color: #6091d3;
}

.box2 {
  font-weight: bold;
  color: #38bd28;
}

@media screen and (max-width: 1150px) {
  .list {
    min-width: 1150px !important;
    bottom: auto !important;
  }
}
</style>