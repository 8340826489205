<template>
<!-- safariブラウザではモーダルでの操作が困難なため、普通の画面に点検表を設けた2023/05/17リリース -->
  <div>
      <!-- <div v-if="processing" class="flex justify-center items-center loading-dialog2">
        <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">{{ processingText }}</p>
      </div> -->
      <template>
        <div class="overflow-auto" :class="isMobileDevice?'mobile-content':'pc-content'">
          <Icon v-if="!processing" slot="before" iconName="X" iconType="outline" :strokeWidth="2" class="h-8 w-8 ml-auto mr-1 mb-5" :clickable="true"  @click="previous()" />
          <LoadingIcon v-else slot="before" class="h-8 w-8 ml-auto mr-1 mb-5 text-gray-700" />
          <div class="text-lg text-blue-800 font-bold flex">
            <div class="ml-3">点検表</div>
            <div class="ml-auto mr-8">{{ point }} / {{ totalPoint }} 点</div>
            <div class="flex">
              <PrimaryButton text="履歴" size="normal " class="w-full mr-1 bg-orange" @click="openHistory()" />
            </div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold mr-auto info1">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold info2">
                物件名 : {{ list.siteName }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                巡回日 : {{ list.today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                巡回時間 : <span v-if="start!=''">{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                巡回指導員 : {{ list.staff }}
              </div>
              <div class="text-gray-600 font-bold info2">
                指導員講習受講番号 : {{ list.patrolId }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold w-full">
                位置情報 : {{ location }}
              </div>
            </div>

            <div v-if="canEdit" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="開始" size="normal" class="w-full mr-1" :class="start!=''?'bg-gray-400':''" v-if="start==''" @click="startBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="キャンセル" size="normal" class="w-full mr-1 bg-red-400 hover:bg-red-500" v-if="start!=''" @click="cancelBtn()" :disabled="processing" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
              <PrimaryButton text="終了" size="normal" class="w-full ml-1" :class="start==''||end!=''?'bg-gray-400':''" :disabled="start==''||end!=''||processing" @click="endBtn()" >
                <LoadingIcon v-if="processing" slot="before" class="h-4 w-4" />
              </PrimaryButton>
            </div>

            <div v-if="completeBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を再表示" size="normal" class="w-full mr-1 bg-teal-50 hover:bg-teal-50 edit text-teal-700" @click="reDisplay()" />
            </div>

            <div v-if="undoBtn" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="完了を非表示に戻す" size="normal" class="w-full mr-1 bg-yellow-50 hover:bg-yellow-50 edit text-yellow-700 border-yellow-600" @click="undo()" />
            </div>

            <div v-if="canEdit" class="flex justify-end mt-2">
              <div class="flex">
                <span class="text-gray-500 font-bold text-xs mr-1 ml-auto my-auto">非表示の「該当なし」</span>
                <Toggle v-model="toggleNA" size="small"/>
              </div>
              <div v-if="claim">
                <PrimaryButton text="項目設定" size="sm" class="ml-4" @click="editItem()" />
              </div>
            </div>

            <div v-if="!canEdit&&isToday" class="flex mt-5 ml-auto mr-auto w-full">
              <PrimaryButton text="再点検" size="normal" class="w-full mr-1 bg-teal-600 hover:bg-teal-700 edit" v-tooltip="'当日のみ点検やり直し可能'" @click="editBtn()" />
            </div>
          </div>

          <div v-if="!checkList.items || !checkList.items.length" class="text-center text-gray-500 font-bold text-sm animate-pulse">項目設定ボタンを押してください。</div>
          
          <!-- <div v-if="!toggleNA" class="toggle flex justify-start items-center mt-8 w-11/12 m-auto">
            <span class="text-gray-500 font-bold text-xs">特記事項並べ替え</span>
            <Toggle v-model="sortable" size="small"/>
          </div> -->

            <table v-if="!toggleNA" class="w-11/12 table-auto ml-auto mr-auto mt-3 mb-5">
              <thead class="bg-blue-100">
                <tr class="border border-gray-200">
                  <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

                <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
                  <!-- 時期 -->
                  <td :rowspan="noteLength" v-if="i==noteFirst"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24" @click="add()">
                    今回
                  </td>
                  <!-- No -->
                  <td v-if="row.num!=-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                    <div>
                      {{ row.num }}
                    </div>
                  </td>
                  <!-- 内容 -->
                  <td v-if="canEdit&&row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                    <textarea 
                      name="note" 
                      :rows="1"
                      class="text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-none rounded"
                      v-model="row.note"
                      @change="changeNoteList(row)"
                    />
                  </td>
                  <td v-else-if="row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                    {{ row.note }}
                  </td>
                </tr>
                <div></div>
                <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
                  <!-- 時期 -->
                  <td :rowspan="lastNoteLength" v-if="i==lastNoteFirst" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                    前回
                  </td>
                  <!-- No -->
                  <td v-if="row.num!=-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                    {{ row.num }}
                  </td>
                  <!-- 内容 -->
                  <td v-if="row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                    {{ row.note }}
                  </td>
                  <td v-if="row.num!=-1">
                  </td>
                </tr>
              <!-- </draggable> -->
            </table>

          <div v-if="!toggleNA" class="toggle flex justify-start items-center mt-8 w-11/12 m-auto mb-2">
            <span class="text-gray-500 font-bold text-xs">点検項目並べ替え</span>
            <Toggle v-model="sortableItem" size="small"/>
            <PrimaryButton text="項目順番確定" size="xs" class="py-1 ml-10" :disabled="!isChangeSort" :class="!isChangeSort?'bg-gray-300':'bg-indigo-700'" @click="setPatrolSortNo()" />
          </div>

          <draggable 
            key="item"
            :list="checkList.items"
            direction="vertical"
            handle=".row-drag-handle"
            @change="sortChanged"
          >
            <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
                
                <div class="flex">
                  <!-- No -->
                  <div class="w-8 text-blue-700 font-bold">
                    <span v-if="!sortableItem&&row.sortNo>-1">{{ row.sortNo }}.</span>
                    <span v-else-if="row.sortNo<=-1">◆</span>
                    <MenuIcon v-else class="text-gray-400 row-drag-handle cursor-pointer" />
                  </div>

                  <!-- 区分 -->
                  <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
                </div>

                <!-- 項目 -->
                <div class="ml-8 mb-1 whitespace-pre-line">
                  {{ row.item }}
                </div>
              </div>

              <!-- 非表示の該当なしを表示した場合 -->
              <div v-if="row.value=='N/A' && row.disabled && toggleNA">
                <!-- No -->
                <div class="flex">
                  <div class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                  <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
                </div>
                <!-- 項目 -->
                <div class="ml-8 mb-1">
                  {{ row.item }}
                </div>
              </div>

              <!-- 確認事項 -->
              <div class="ml-auto " v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.sortNo <= -1">
                <div class="flex justify-end items-end">
                  <!-- 指摘事項（該当なしの場合） -->
                  <div v-if="canEdit && row.value=='N/A' && (!row.complete || !completeBtn) && !row.disabled && !toggleNA" class="w-full ml-32p mr-4">
                    <textarea 
                      name="note" 
                      :rows="1"
                      placeholder="理由"
                      class="text-gray-700 py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                      v-model="row.note"
                      @change="temporarilyRegister('item', row)"
                      :ref="'note_'+i"
                    />
                  </div>
                  <div v-if="!canEdit && row.value=='N/A'" class="text-gray-700  mr-4 w-full ml-32p sm:text-sm border p-1.5 border-gray-300 rounded whitespace-pre-line">
                    {{ row.note }}
                  </div>

                  <!-- 該当なしボタン -->
                  <PrimaryButton v-if="canEdit" text="該当なし" size="normal w-32" class="" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                  <PrimaryButton v-if="!canEdit&&row.value=='N/A'" text="該当なし" size="normal" class="w-32 bg-teal-700 mr-2" :disabled="true" />

                  <!-- カメラ -->
                  <div v-if="!row.disabled && row.value!='N/A' && canEdit && start!=''" class="">
                    <div class="file-btn rounded w-70px bg-darkgreen h-full">
                      <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                        <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                      </PrimaryButton>
                      <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" @change="imageToBase64(i, $event)" multiple />
                    </div>
                  </div>

                  <!-- 完了ボタン -->
                  <PrimaryButton v-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="text-blue-800 border border-blue-800 rounded font-bold p-6p w-24 ml-3 comp-btn2" @click="complete(i)" :disabled="processing" >
                    <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                  </PrimaryButton>
                </div>
              </div>
                
              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA && row.sortNo > -1">
                <!-- 点数 -->
                <div class="ml-8 flex">
                  <PrimaryButton text="指摘多数" size="normal" class="w-full mr-2" :class="row.value!='0'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('0', i)" />
                  <PrimaryButton text="指摘あり" size="normal" class="w-full mr-2" :class="row.value!='1'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('1', i)" />
                  <PrimaryButton text="良好" size="normal" class="w-full mr-2" :class="row.value!='2'?'bg-gray-400':''" :disabled="start==''||row.disabled||!canEdit" @click="setPoint('2', i)" />
                  <PrimaryButton v-if="canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="start==''||row.disabled" @click="setPoint('N/A', i)" />
                  <PrimaryButton v-if="!canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
                  <!-- カメラ -->
                  <div v-if="!row.disabled && row.value!='N/A' && canEdit && start!=''" class="">
                    <div class="file-btn rounded w-70px bg-darkgreen h-full">
                      <PrimaryButton :buttonContents="'要'" class="bg-darkgreen hover:bg-darkgreen h-full w-70px">
                        <Icon slot="before" iconName="Camera" iconType="solid" :strokeWidth="2" class="text-white h-5 w-8 bg-darkgreen hover:bg-darkgreen" />
                      </PrimaryButton>
                      <input class="file-type w-70px bg-darkgreen" type="file" accept="image/*" @change="imageToBase64(i, $event)" multiple />
                    </div>
                  </div>
                  <!-- 非表示ボタン -->
                  <PrimaryButton v-if="canEdit && row.value=='N/A'" text="非表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p na-btn" @click="disabledNA(i)" />
                  <!-- 完了ボタン -->
                  <PrimaryButton v-if="canEdit" :text="processing?'':'完了'" size="bg-blue-50" class="text-blue-800 border border-blue-800 rounded font-bold p-6p w-370 ml-3 comp-btn" @click="complete(i)" :disabled="processing" >
                    <LoadingIcon v-if="processing" slot="before" class="h-4 w-4 text-blue-600" />
                  </PrimaryButton>
                </div>
              </div>
              
              <!-- 非表示の該当なしを表示した場合 -->
              <div v-if="row.value=='N/A' && row.disabled && toggleNA">
                <PrimaryButton text="表示" size="bg-dark-green" class="rounded text-xs text-white font-bold p-5p mb-5 w-1/3 h-10 ml-8" @click="enableNA(i)" />
              </div>

              <!-- 指摘事項 -->
              <div v-if="canEdit && (row.value=='0'||row.value=='1') && (!row.complete || !completeBtn) && !row.disabled && !toggleNA" class="mt-3 w-96pa ml-32p">
                <textarea 
                  name="note" 
                  :rows="1"
                  :placeholder="canEdit ? '指摘事項' : ''"
                  class="text-gray-700 py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                  v-model="row.note"
                  @change="[changeNote(i), temporarilyRegister('item', row)]"
                  :ref="'note_'+i"
                />
              </div>
              <div v-if="!canEdit && (row.value=='0'||row.value=='1')" class="text-gray-700 mt-3 w-96pa ml-32p sm:text-sm border p-1 border-gray-300 rounded whitespace-pre-line">
                {{ row.note }}
              </div>

              <div v-if="(!row.complete || !completeBtn) && !row.disabled && !toggleNA">
                <!-- 添付画像 -->
                <div class="ml-8 mt-3 flex">
                  <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3">
                    <a :href="fullImage" :data-lightbox="i * 100 + j" @click="clickImage(checkList.items[i].sortNo, i, j)">
                      <img :src="p" width="300">
                    </a>
                    <!-- 削除 -->
                    <div @click="deletePhoto(i, j)" v-if="p && p != '' && canEdit" class="ml-1 mt-auto mb-1">
                      <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7" />
                    </div>
                  </div>
                </div>
                <div class="text-transparent">{{reload}}</div>
              </div>
            </div>
          </draggable>
          
          <div v-if="!toggleNA" class="toggle flex justify-start items-center mt-8 w-11/12 m-auto">
            <!-- <span class="text-gray-500 font-bold text-xs">特記事項並べ替え</span>
            <Toggle v-model="sortable" size="small"/> -->
            
            <div class="flex ml-auto">
              <PrimaryButton v-if="canEdit" text="指摘" size="ml-3 bg-pink-50 border border-pink-800 rounded-md px-1 text-pink-700 cursor-pointer" @click="copyNg()" />
            </div>
          </div>

            <table v-if="!toggleNA" class="w-11/12 table-auto ml-auto mr-auto mt-3">
              <thead class="bg-blue-100">
                <tr class="border border-gray-200">
                  <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                    {{c.title}}
                  </th>
                </tr>
              </thead>

                <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
                  <!-- 時期 -->
                  <td :rowspan="noteLength" v-if="i==noteFirst"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24" @click="add()">
                    今回
                  </td>
                  <!-- No -->
                  <td  v-if="row.num!=-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                    <div>
                      {{ row.num }}
                    </div>
                  </td>
                  <!-- 内容 -->
                  <td v-if="canEdit&&row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                    <textarea 
                      name="note" 
                      :rows="1"
                      class="text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-none rounded"
                      v-model="row.note"
                      @change="changeNoteList(row)"
                    />
                  </td>
                  <td v-else-if="row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                    {{ row.note }}
                  </td>
                </tr>
                <div></div>
                <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
                  <!-- 時期 -->
                  <td :rowspan="lastNoteLength" v-if="i==lastNoteFirst" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                    前回
                  </td>
                  <!-- No -->
                  <td v-if="row.num!=-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                    {{ row.num }}
                  </td>
                  <!-- 内容 -->
                  <td v-if="row.num!=-1" class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                    {{ row.note }}
                  </td>
                  <td>
                  </td>
                </tr>
              <!-- </draggable> -->
            </table>
            <div v-if="!toggleNA" class="mt-3 w-11/12 m-auto mb-6">
              <div class="text-gray-700 text-sm">備考</div>
              <textarea 
                name="checkListMemo" 
                :multiline="true"
                :rows="memoCount"
                class="text-gray-700 w-full border-gray-300 rounded"
                v-model="checkList.memo"
                :disabled="!canEdit"
                @change="temporarilyRegister('memo', checkList.memo)"
              />
            </div>
        </div>
      </template>
    <!-- 履歴 -->
    <CheckListHistoryModal 
      v-model="historyModal"
      :target="target"
      :type="type"
      :claim="claim"
      :open="openHistoryModal"
      @modalClose="checkListHistoryModalClose"
    />
    <!-- クレーム対応項目設定ダイアログ -->
    <ClaimCheckListSettingModal 
      v-model="openClaimCheckListSettingModal"
      :target="target"
      :items="checkList.items"
      :type="type"
      :open="openClaimCheckListSettingModal"
      @modalClose="claimCheckListSettingModalClose"
    />
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import CheckListHistoryModal from '../components/CheckListHistoryModal.vue'
import ClaimCheckListSettingModal from '../components/ClaimCheckListSettingModal.vue'
import LoadingIcon from '@assets/loading-circle.svg' 
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import moment from 'moment'
import * as logManager from '@managers/logManager'

const ITEMS = {
    // 区分
    classification: '',
    // 内容
    item: '',
    // 結果
    value: '',
    // 該当なし履歴
    valueNA: false,
    // 該当なし非表示
    disabled: false,
    // 写真
    photo: [],
    // 完了ボタン
    complete: false
  }

export default {
  components: {
    Icon,
    TrashIcon,
    MenuIcon,
    PrimaryButton,
    Toggle,
    draggable,
    CheckListHistoryModal,
    ClaimCheckListSettingModal,
    LoadingIcon
  },

  props: {
    // 区分名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃）
    type: {
      type: Number,
      default: null
    },
    // 親画面（予定一覧）
    routerName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      // クレーム対応か否か
      claim: false,
      // 表示対象予定詳細情報
      target: {},
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // チェック項目
      checkList: {
        items: [
          utils.clone(ITEMS)
        ],
        
        note:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        lastNote:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        memo: ''
      },
      // 得点
      point: 0,
      totalPoint: 0,
      // カラム
      columns: [
          {title: '時期'},
          {title: 'No.'},
          {title: '指摘事項および指導内容'},
          {title: ''}
      ],
      // 位置情報
      location: '',
      // 点検表マスタ設定モーダル
      openCheckListSettingModal: false,
      openModal: 0,
      // 特記事項並べ替え
      // sortable: false,
      // 点検項目並べ替え
      sortableItem: false,
      // 履歴モーダル
      historyModal: false,
      openHistoryModal: 0,
      // 編集できるか
      canEdit: true,
      // 備考の大きさ
      memoCount: 5,
      // 処理中
      processing: false,
      processingText: 'Loading...',
      // 写真更新
      reload: 0,
      // 点検完了日当日か
      isToday: false,
      // 完了ボタンが押されたか
      completeBtn: false,
      // 非表示の該当なしトグル
      toggleNA: false,
      // 保存したデータ
      // registeredItem: [],
      // 添付画像 オリジナルデータ
      fullImage: '',
      // 全添付画像 オリジナルデータ
      fullImages: null,
      // 完了を非表示ボタン活性
      undoBtn: false,
      // クレーム対応項目設定ダイアログ表示
      openClaimCheckListSettingModal: false,
      // 項目の並びを変更したか
      isChangeSort: false,
    }
  },

  async created() {
    let val = this.$route.query.claim
    // booleanの場合
    if (typeof val === 'boolean') {
      this.claim = this.$route.query.claim
    } else {
      this.previous()
    }
    
    await this.getTarget()
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    /**
     * タブレット
     */
    // isTablet() {
    //   return utils.deviceInfo.isTablet
    // }

    /**
     * 今回の特記事項の長さ（確認事項以外）
     */
    noteLength() {
      let length = 1

      if (this.checkList.note && this.checkList.note.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.note.filter((l) => {
          return l.num != -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 今回の特記事項の時期を表示するインデックス
     */
    noteFirst() {
      if (this.checkList.note && this.checkList.note.length) {
        for (let i = 0; i < this.checkList.note.length; i++) {
          const n = this.checkList.note[i]
          if (n.num != -1) {
            return i
          }
        }
      }
      return 0
    },

    /**
     * 前回の特記事項の長さ（確認事項以外）
     */
    lastNoteLength() {
      let length = 1

      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.lastNote.filter((l) => {
          return l.num != -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 前回の特記事項の時期を表示するインデックス
     */
    lastNoteFirst() {
      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        for (let i = 0; i < this.checkList.lastNote.length; i++) {
          const n = this.checkList.lastNote[i]
          if (n.num != -1) {
            return i
          }
        }
      }
      return 0
    },
  },

  watch: {
    // sortable() {
    //   if (this.sortable) {
    //     this.columns = [
    //         {title: ''},
    //         {title: 'No.'},
    //         {title: '指摘事項および指導内容'},
    //         {title: ''}
    //     ]
    //   } else {
    //     this.columns = [
    //         {title: '時期'},
    //         {title: 'No.'},
    //         {title: '指摘事項および指導内容'},
    //         {title: ''}
    //     ]
    //   }
    // }
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.$route.query.scheduleId) {
        this.processing = true
        this.processingText = 'Loading...'

        // スケジュールIDに紐づくデータを取得
        let schedule = await backend.searchData('patrolSchedule/getById', { id: this.$route.query.scheduleId })

        if (schedule.data.data && schedule.data.data.length) {
          this.target = schedule.data.data[0]
        } else {
          await dialogs.showErrorDialog('エラー', '対象データがありません。')
          return
        }
        this.processing = false

        this.list = this.target
        this.list.today = moment().format('YYYY/MM/DD')

        // 巡回点検済み
        if (this.target.completionDate && this.target.completionDate != '') {
          this.canEdit = false
          this.list.today = moment(this.target.completionDate).format('YYYY/MM/DD')
          if (moment().format('YYYY/MM/DD') == this.list.today) {
            this.isToday = true
          } else {
            this.isToday = false
          }
          this.memoCount = 1

          // データ取得
          this.processing = true
          this.processingText = 'Loading...'
          let scheduledDate = moment().format('YYYY-MM-DD')
          // 完了している場合
          if (this.target.completionDate) {
            scheduledDate = moment(this.target.completionDate).format('YYYY-MM-DD')
          }
          const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate, type: this.type, claim: this.claim })
          if (compR.data && compR.data.data) {
            this.setData(compR.data.data)
            // 添付画像 取得
            this.getfullImages()
          }
          this.processing = false

        // 巡回点検まだ
        } else {
          this.canEdit = true
          // 途中保存があり、途中から行う場合
          if (await this.temporary()) {
            return
          }
          
          // データを削除
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id })
          await this.initCheck()
        }
        this.processing = false
      }
    },

    /**
     * 保存している結果をセット
     */
    setData(r) {
      if (r.result.length) {
        let result = r.result[0]
        this.start = result.startTime
        if (result.endTime) {
          this.end = result.endTime
        } else {
          this.end = ''
        }
        this.location = result.location
        this.point = result.getPoint
        this.totalPoint = result.totalPoint
      }
      if (r.item) {
        this.checkList.items = r.item
        if (!this.target.completionDate) {
          this.setCheckItem()
        }
      }
      if (r.note) {
        this.checkList.note = r.note
        for (let i = 0; i < this.checkList.items.length; i++) {
          const it = this.checkList.items[i]
          for (let j = 0; j < this.checkList.note.length; j++) {
            const no = this.checkList.note[j]
            if (it.sortNo == no.num) {
              it.note = no.note
              break
            }
          }
        }
      }
      if (r.lastNote) {
        this.checkList.lastNote = r.lastNote
      }
      if (r.memo && r.memo.length && r.memo[0].memo) {
        this.checkList.memo = r.memo[0].memo
        let c = 1
        if (this.checkList.memo != '') {
          let mIdx = 0
          for (let k = 0; k < this.checkList.memo.length; k++) {
            let memo = this.checkList.memo[k]
            if (memo == '\n') {
              mIdx = 0
            } else {
              mIdx++
              if (mIdx == 56) {
                const a = this.checkList.memo.slice(0, k)
                const b = this.checkList.memo.slice(k)
                this.checkList.memo = a + '\n' + b
                mIdx = 0
              }
            }
          }
          c = ( this.checkList.memo.match( /\n/g ) || [] ).length
        }
        
        this.memoCount = 5
        if (c) {
          if (c + 2 > 5) {
            this.memoCount = c + 2
          }
        }
      }
    },

    /**
     * 途中保存を取得
     */
    async temporary() {
      const temporaryS =  await backend.searchData('patrolTemporarySaving/isSaving', { patrolScheduleId: this.target.id })
      if (temporaryS && temporaryS.data && temporaryS.data.data.length) {
        const dialogR = await dialogs.showConfirmDialog('途中保存データあり', '途中保存しているデータがあります。\r\n途中から再開する場合➡「途中から」\r\n最初からやり直す➡「最初から」\r\nを押してください。', '途中から', '最初から')
        
        // 途中から再開する場合
        if (dialogR == 'YES') {
          this.processing = true
          this.processingText = 'Loading...'
          let temporaryR = []

          
          let scheduledDate = moment().format('YYYY-MM-DD')
          let param = { clientCode: this.target.clientCode, siteCode: this.target.siteCode, patrolScheduleId: this.target.id, scheduledDate, type: this.type, claim: this.claim }

          temporaryR =  await backend.searchData('patrolTemporarySaving/getByPatrolScheduleId', param)
          
          // 完了を表示ボタンを出す
          if (temporaryR.data.data.item && temporaryR.data.data.item.length) {
            for (let i = 0; i < temporaryR.data.data.item.length; i++) {
              const it = temporaryR.data.data.item[i]
              if (it.complete) {
                this.completeBtn = true
                break
              }
            }
          }
          
          this.setData(temporaryR.data.data)
          this.processing = false

          // 添付画像 取得
          this.getfullImages()

          return true
        
        // 最初からやり直す場合
        } else {
          return false
        }
      }
      return false
    },

    /**
     * 点検前の点検表セット
     */
    async initCheck() {
      this.processing = true
      this.processingText = 'Loading...'

      // 物件コードが変更になっていないか確認
      let isChangeCode = await backend.searchData('checkListHistory/isChangeCode', { siteCode: this.target.siteCode, type: this.type, claim: this.claim, siteAddress: this.target.siteAddress, siteName: this.target.siteName })
      if (isChangeCode.data && isChangeCode.data.data && isChangeCode.data.data.result) {
        this.processing = false
        const inf = isChangeCode.data.data.info
        const msg = `
          前回完了日：${inf.completionDate}
          物件名：${inf.siteName}
          住所：${inf.siteAddress}
        `
        const chagneCode = await dialogs.showConfirmDialog('物件コードが変わった可能性があります。', '過去の物件コードと新しい物件コードを一致させますか？\r\n新しい物件コードと一致させると過去のデータが引き継がれます。\r\n\r\n【同じと思われる過去のデータ】' + msg)
        this.processing = true
        // 過去の物件コードを変更する
        if (chagneCode == 'YES') {
          const updateRes = await backend.postData('checkListHistory/changingCode', { newSiteCode: this.target.siteCode, oldSiteCode: inf.siteCode, type: this.type, claim: this.claim, id: this.target.id })
          if (updateRes && updateRes.status == 200) {
            //ログ出力
            let cl = ''
            if (this.claim) {
              cl = '（クレーム点検）'
            }
            logManager.recording(this, logManager.Loglevel.INFO, this.typeName + cl, '物件コード変更', this.typeName + '（物件名：' + inf.siteName + '、物件コード：' + inf.siteCode + '、完了日：'+ inf.completionDate + '以前全て、予定ID：'+ inf.id +'以前全て）を（物件コード：' + this.target.siteCode + '）に変更しました。') 
          } else {
            alert('エラーが発生しました。')
            this.processing = false
          }
        }
      }

      // 通常点検
      if (!this.claim) {
        // 点検表
        let cheR = await backend.searchData('checkListByClient/getByClientCode', { clientCode: this.target.clientCode, siteCode: this.target.siteCode, type: this.type })
        if (cheR.data && cheR.data.data && cheR.data.data.length) {
          this.checkList.items = cheR.data.data
        }
      
      // クレーム点検
      } else {
        this.checkList.items = []
        let cheR = await backend.searchData('claimCheckListBySite/getBySiteCode', { clientCode: this.target.clientCode, siteCode: this.target.siteCode, type: this.type, claim: this.claim })
        if (cheR.data && cheR.data.data && cheR.data.data.length) {
          this.checkList.items = cheR.data.data
        }
      }

      // 過去の指摘事項
      let scheduledDate = moment().format('YYYY-MM-DD')
      // 完了している場合
      if (this.target.completionDate) {
        scheduledDate = moment(this.target.completionDate).format('YYYY-MM-DD')
      }
      const lasN = await backend.searchData('patrolResultNote/getByPatrolScheduleId', { clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate, type: this.type, claim: this.claim })
      if (lasN.data && lasN.data.data.length) {
        this.checkList.lastNote = lasN.data.data
      }
      this.setCheckItem()
      
      this.processing = false
    },

    /**
     * 確認事項追加
     */
    setCheckItem() {
      if (this.claim) {
        return
      }

      let check = []
      let normal = []
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (it.sortNo == -1) {
          check.push(it)
        } else {
          normal.push(it)
        }
      }

      // キーボックスの項目が無ければ、一番最初に追加
      if (!check || !check.length) {
        const row = [utils.clone(ITEMS)]
        row[0].sortNo = -1
        row[0].classification = '確認事項'
        row[0].item = '（品質管理部）キーボックス番号を確認し、概要書を更新した写真を添付して下さい。\r\n（巡回員）鍵をキーボックスに返却している写真・蓋を閉めた写真の2枚を添付してください。\r\n＊「該当なし」の場合は、コメント欄に理由を記載して下さい。'

        this.checkList.items = row.concat(this.checkList.items)
      } else {
        this.checkList.items = check.concat(normal)
      }
    },

    /**
     * 初期化
     */
    initData() {
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          {classification: '', item: '', value: '', valueNA: false, disabled: false, photo: [], complete: false}
        ],
        note: [
          {id: null, checklistId: null, num: '', note: ''},
        ],
        lastNote: [
          {id: null, checklistId: null, num: '', note: ''},
        ]
      }
      // 得点
      this.point = 0
      this.totalPoint = 0
      // カラム
      this.columns = [
          {title: '時期'},
          {title: 'No.'},
          {title: '指摘事項および指導内容'},
          {title: ''}
      ]
      // 位置情報
      this.location = ''
      // 点検表マスタ設定モーダル
      this.openCheckListSettingModal = false
      this.openModal = 0
      // 並べ替え
      // this.sortable = false
      // 編集できるか
      this.canEdit = true
      // 備考の大きさ
      this.memoCount = 5
      // 処理中
      this.processing = false
      // 点検完了当日か
      this.isToday = false
      // 完了ボタン
      this.completeBtn = false
      // 非表示の該当なしトグル
      this.toggleNA = false
      // 保存したデータ
      // this.registeredItem = []
      // 添付画像 オリジナルデータ
      this.fullImage = ''
      // 全添付画像 オリジナルデータ
      this.fullImages = null
      // 完了を非表示ボタン活性
      this.undoBtn = false
    },

    /**
     * 開始ボタン
     */
    async startBtn() {
      this.list.start = moment().format('HH:mm')
      this.start = moment().format('HH:mm')

      // 現在地取得
      this.location = await utils.patrolLocation()

      // 一時保存
      this.temporarilyRegister('start', null)
    },

    /**
     * 完了したデータを削除
     */
    async deleteCompData() {
      let flg = false
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (it.complete) {
          flg = true
          break
        }
      }
      // 1度でも完了ボタンを押していたら確認ダイアログ出す
      if (flg) {
        const canscelR = await dialogs.showConfirmDialog('キャンセル', '完了しているデータも元に戻ります。よろしいでしょうか？')
        if (canscelR != 'YES') {
          return false

        // 途中保存データを削除
        } else {
          await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id })
          this.initData()
          this.list = this.target
          this.list.today = moment().format('YYYY/MM/DD')
          await this.initCheck()
          return false
        }
      }
      // まだ1度も完了した項目がなければ、値のみ空にする
      return true
    },

    /**
     * キャンセルボタン
     */
    async cancelBtn() {
      let cancelR = await this.deleteCompData()
      if (!cancelR) {
        return
      }
      
      this.start = ''
      this.location = ''
      this.point = 0
      this.totalPoint = 0
      this.completeBtn = false
      this.checkList.items.forEach(i =>{
        if (!i.valueNA) {
          i.value = null
          i.disabled = false
        }
        i.note = ''
        i.complete = false
        i.photo = []
      })
      this.checkList.note = [
        {id: null, checklistId: null, num: '', note: ''}
      ]
    },

    /**
     * 終了ボタン
     */
    async endBtn() {
      // 変更後に再保存していないもの
      let dirty = []
      // 完了しているか
      let isComp = true
      for (let i = 0; i < this.checkList.items.length; i++) {
        const it = this.checkList.items[i]
        if (!it.complete && !it.disabled) {
          isComp = false
          break
        }
      }

      // 完了ボタンが1つも押されていない場合は終了不可
      if (!isComp) {
        await dialogs.showErrorDialog('終了できません', '全ての完了ボタンを押してください。')
        return
      }
      // すべての値が選択されているか確認
      for (let i = 0; i < this.checkList.items.length; i++) {
        const ci = this.checkList.items[i]
        if (!ci.disabled && !ci.complete) {
          await dialogs.showErrorDialog('終了できません', `No.${ci.sortNo}【${ci.classification}】の完了ボタンを押してください。`)
          return
        }
        if (ci.sortNo > 0 && (!ci.value || ci.value == '')) {
          await dialogs.showErrorDialog('完了できません', `No.${ci.sortNo}【${ci.classification}】の評価が選択されていません。`)
          return
        } 
        if (ci.value == '0' || ci.value == '1') {
          if (!ci.note || ci.note == '') {
            await dialogs.showErrorDialog('完了できません', `No.${ci.sortNo}【${ci.classification}】の指摘事項を記入してください。`)
            return
          }
        }
        if (ci.sortNo > 0 && ci.value != 'N/A' && ci.note.indexOf('●●') != -1) {
          await dialogs.showErrorDialog('完了できません', `No.${ci.sortNo}【${ci.classification}】の指摘事項に「●●」があります。\r\n指摘事項を完成させてください。`)
          return
        }
        if (ci.changeFlag) {
          let resultMsg = await dialogs.showConfirmDialog('完了している項目を変更しましたか？', `No.${ci.sortNo}【${ci.classification}】を変更して「完了」していませんが変更したものに更新しますか？\r\n更新する場合は「はい」を押してください。`)
          if (resultMsg == 'YES') {
            // 再度保存するデータ
            dirty.push(ci)
          }
        }
      }

      // 指摘が無い場合、「清掃状況は良好なので維持するように指導致しました。」自動設定
      if (!this.checkList.note.some(note => note.note && note.note.length > 0 && note.num != -1)) {
        let isStaff = false
        // 日常清掃員の項目の該当有無
        for (let i = 0; i < this.checkList.items.length; i++) {
          const it = this.checkList.items[i]
          if (it.classification.indexOf('日常清掃員') != -1 && it.item.indexOf('身だしなみ') != -1 && it.value != 'N/A') {
            isStaff = true
            break
          }
        }
        let note = "清掃状況は良好なので維持するように指導致しました。"
        if (!isStaff) {
          note = "清掃状況は良好なので維持するように指導致します。"
        }
        this.checkList.note.push({ note })
      }
      if (this.isChangeSort) {
        const dlog = await dialogs.showConfirmDialog('項目順番確定しておりません', '項目を並べ替えた後に「項目順番確定」ボタンを押していないようですが、確定せずに終了してよろしいでしょうか？')
        if (dlog != 'YES') {
          return
        }
      }

      this.end = moment().format('HH:mm')
      
      await this.allRegister(dirty)
    },

    /**
     * 保存時に必要なデータを生成
     */
    setSaveData() {
      return {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        completionDate: this.list.today,
        getPoint: this.point,
        totalPoint: this.totalPoint,
        startTime: this.start,
        endTime: this.end,
        location: this.location,
        insertUser: this.$store.getters.user.id
      }
    },

    /**
     * 終了ボタン押下時の最終保存
     */
    async allRegister(dirty) {
      this.processing = true
      this.processingText = '保存中...'
      
      try {
        let save = this.setSaveData()
        // 巡回結果保存
        await backend.postData('patrolResult/save', { save, item: this.checkList.items, note: this.checkList.note, memo: this.checkList.memo, dirty, key: this.target.id, type: this.type, claim: this.claim })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        // 再度、終了ボタンが押せるようにする
        this.end = ''
      }

      //ログ出力
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, '完了', this.typeName + '（期限日：'+ moment(this.list.termDate).format('YYYY-MM-DD')  + '、担当者：'+ this.list.staff + '、物件名：'+ this.list.siteName +'）を完了しました。')  
      this.previous()
      this.initData()
    },

    /**
     * クレーム対応項目設定ボタン
     */
    editItem() {
      this.openClaimCheckListSettingModal = true
    },

    /**
     * クレーム対応項目ダイアログクローズ
     */
    async claimCheckListSettingModalClose() {
      this.openClaimCheckListSettingModal = false
      this.initData()
      await this.getTarget()
    },

    /**
     * 一時保存処理
     * @param itemName 変更になった項目
     *   （開始ボタン：start、特記事項表：note、項目：item、備考：memo)
     * @param val 登録内容
     */
    temporarilyRegister(itemName, val) {
      try {
        let save = this.setSaveData()
        let note = null
        let item = null
        let memo = null

        // 特記事項の表
        if (itemName == 'note') {
          note = val
        // 項目
        } else if (itemName == 'item') {
          item = val
        // 備考
        } else if (itemName == 'memo') {
          memo = val
        }
        backend.postData('patrolResult/temporarily', { save, note, item, memo, type: this.type })
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
      }
    },

    /**
     * 各項目の完了ごとの登録処理
     * @param i 対象項目インデックス
     */
    async register(i) {
      this.processing = true
      this.processingText = '保存中...'

      try {
        let save = this.setSaveData()

        // 完了した対象の項目を保存(値・指摘事項・写真)
        await backend.postData('patrolResult/itemAndNoteSave', { save, item: this.checkList.items[i] })
        
        this.processing = false
      } catch (err) {
        alert("エラーが発生しました。")
        console.log('エラーが発生しました。')
        console.log(err)
        this.processing = false
        this.checkList.items[i].changeFlag = true
        this.checkList.items[i].complete = false
      }
    },

    /**
     * 再点検ボタン
     */
    async editBtn() {
      const editR = await dialogs.showConfirmDialog('再点検', 'データをリセットします。\r\n（該当なしはリセットされません。）\r\n本当に再点検しますか？')
      if (editR != 'YES') {
        return
      }
      this.initData()
      // 以前のデータを削除
      await backend.deleteDataByKey('patrolResult/deleteByPatrolScheduleId', { key: this.target.id })
      this.canEdit = true
      this.list = this.target
      this.list.today = moment().format('YYYY/MM/DD')
      await this.initCheck()
    },

    /**
     * 点数ボタン
     * @param value 点
     * @param idx 対象データインデックス
     */
    setPoint(value, idx) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[idx].changeFlag = true
      }
      if (this.checkList.items[idx].value == value) {
        this.checkList.items[idx].value = null
        // 特記事項コメント削除
        this.checkList.items[idx].note = ''
        this.changeNote(idx)
      } else {
        this.checkList.items[idx].value = value
      }

      // 点数計算
      let p = 0
      let minus = 0
      this.checkList.items.forEach(i => {
        if (!isNaN(i.value)) {
          p += Number(i.value)
        }
        if (i.value == 'N/A' || i.disabled || i.sortNo < 0) {
          minus++
        }
      })
      this.point = p
      minus = minus * 2
      this.totalPoint = this.checkList.items.length * 2 - minus

      // 2点以下は指摘事項に番号を入力
      if (this.checkList.items[idx].value == '1' || this.checkList.items[idx].value == '0') {
        // 特記事項にデフォルトコメント
        this.checkList.items[idx].note = '●●が見受けられたので除去するように指導致しました。'
        this.changeNote(idx)
      } else {
        // 特記事項コメント削除
        this.checkList.items[idx].note = ''
        this.changeNote(idx)
      }

      // 指摘事項へフォーカス設定
      this.$nextTick(() => {
        if (this.checkList.items[idx].value=='0' || this.checkList.items[idx].value=='1') {
          this.$refs['note_' + idx][0].focus()
          this.$refs['note_' + idx][0].setSelectionRange(0, 2)
        }
      })
    },

    /**
     * 特記事項変更イベント
     * @param i 項目インデックス
     */
    changeNote(i) {
      const item = this.checkList.items[i]
      for (let j = 0; j < this.checkList.note.length; j++) {
        const note = this.checkList.note[j]
        // 編集の場合
        if (note.num == item.sortNo) {
          note.note = item.note
          if (note.note == '') {
            this.checkList.note.splice(j, 1)
          }
          if (!this.checkList.note.length) {
            this.checkList.note.push({id: null, checklistId: null, num: '', note: ''})
          }
          this.sortNoteList()
          return
        }
      }

      // 空行を削除
      for (let k = this.checkList.note.length-1; k >= 0; k--) {
        const note2 = this.checkList.note[k]
        if (note2.num == '' && note2.note == '') {
          this.checkList.note.splice(k, 1)
        }
      }
      if (item.note != '') {
        // まだ下の表に同じ番号の記載がない場合
        this.checkList.note.push({id: null, checklistId: null, num: String(item.sortNo), note: item.note})
      }
      if (!this.checkList.note.length) {
        this.checkList.note.push({id: null, checklistId: null, num: '', note: ''})
      }
      this.sortNoteList()
    },

    /**
     * 指摘事項表変更イベント
     * @param note 対象指摘事項
     */
    async changeNoteList(note) {
      // 一時保存
      this.temporarilyRegister('note', this.checkList.note)

      if (!note.num) {
        return
      }
      const num = Number(note.num) - 1
      this.checkList.items[num].note = note.note
      this.reload++
    },

    /**
     * 特記事項自動並び替え
     */
    sortNoteList() {
      let note = utils.clone(this.checkList.note)
      if (note && note.length) {
        
        note.sort(function (a, b) {
          return a.num - b.num
        })
        this.checkList.note = note
      }
    },

    /**
     * 指摘文言コピー
     */
    copyNg() {
      navigator.clipboard.writeText('●●が見受けられたので除去するように指導致しました。')
    },

    /**
     * 写真添付Base64に変換
     * @param i index
     */
    async imageToBase64 (i, event) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      // 選択されたファイルの取得
      const file = event.target.files
      if (!this.checkList.items[i].photo) {
        this.checkList.items[i].photo = []
      }
      // Base64に変換する
      for (let j = 0; j < file.length; j++) {
        const reader = new FileReader()
        const f = file[j]
        reader.readAsDataURL(f)
        // 変換
        const compressedImage = await new Promise((resolve) => {
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            
            img.onload = () => {
              const compressedDataUrl = this.compressImage(img)
              resolve(compressedDataUrl)
            }
          }
        })
        this.checkList.items[i].photo.push(compressedImage)
        this.reload++
      }
    },
    /**
     * 画像圧縮
     * @param {dataURL} img 
     */
      compressImage(img) {
      // 画像のサイズを変更するためにリサイズ処理
      const maxWidth = 854 // リサイズ後の最大幅
      let width = img.width
      let height = img.height

      if (width > maxWidth) {
        height *= maxWidth / width
        width = maxWidth
      }
      // 新しいキャンバスを作成
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // キャンバスに描画するためのコンテキストを取得
      const ctx = canvas.getContext('2d')
      // 画像をキャンバスに描画せずに直接リサイズ
      ctx.drawImage(img, 0, 0, width, height)
      // キャンバスの画像を再度DataURLに変換して圧縮率を下げる
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 1.0) // 圧縮率を設定（0.7は例です）

      return compressedDataUrl
    },

    /**
     * 写真削除
     * @param i 項目インデックス
     * @param j 写真インデックス
     */
    deletePhoto(i, j) {
      // 完了を再表示している場合
      if (this.undoBtn) {
        // 変更したらフラグを立てる
        this.checkList.items[i].changeFlag = true
      }
      this.checkList.items[i].photo.splice(j, 1)
      this.reload++
    },

    /**
     * 追加
     */
    add() {
      this.checkList.note.push({id: null, checklistId: null, num: '', note: ''})
    },

    /**
     * 非表示ボタン
     * @param i 対象項目
     */
    async disabledNA(i) {
      const dr = await dialogs.showConfirmDialog('非表示にします', '次回からもこの項目は非表示にします。\r\n設定変更する場合は、【非表示の「該当なし」】ボタンを押してください。')
      if (dr != 'YES') {
        return
      }
      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].disabled = true
    },

    /**
     * 該当なし表示ボタン
     * @param i 対象項目
     */
    async enableNA(i) {
      this.checkList.items[i].disabled = false
      this.checkList.items[i].complete = false
    },

    /**
     * 完了ボタン
     * @param i 対象項目
     */
    async complete(i) {
      if (this.start == '') {
        await dialogs.showErrorDialog('開始ボタン', '開始ボタンを押してください。')
        return
      }
      const row = this.checkList.items[i]
      // クレーム対応の場合
      if (this.claim) {
        if (!row.classification) {
          await dialogs.showErrorDialog('完了できません', 'チェック場所を記入してください。')
          return
        }
        if (!row.item) {
          await dialogs.showErrorDialog('完了できません', 'チェック内容を記入してください。')
          return
        }
      }
      // 確認項目の場合
      if (row.sortNo == -1) {
        if (row.value != 'N/A' && (!row.photo || !row.photo.length)) {
          await dialogs.showErrorDialog('完了できません', '写真を添付してください。')
          return
        } else if (row.value == 'N/A' && !row.note) {
          await dialogs.showErrorDialog('完了できません', '「該当なし」の場合、理由を記載してください。')
          return
        }
      } else {
        if (!row.value || row.value == '') {
          await dialogs.showErrorDialog('完了できません', '評価が選択されていません。')
          return
        } 
        if (row.value == '0' || row.value == '1') {
          if (!row.note || row.note == '') {
            await dialogs.showErrorDialog('完了できません', '指摘事項を記入してください。')
            return
          }
        }
        if (row.value != 'N/A' && row.note.indexOf('●●') != -1) {
          await dialogs.showErrorDialog('完了できません', '指摘事項に「●●」があります。\r\n指摘事項を完成させてください。')
          return
        }
      }
        
      // 変更フラグをfalseにする
      this.checkList.items[i].changeFlag = false
      this.checkList.items[i].complete = true
      this.undo()
      
      this.reload++

      // 保存
      this.register(i)
    },

    /**
     * 完了を表示ボタン
     */
    reDisplay() {
      this.completeBtn = false
      this.undoBtn = true
    },

    /**
     * 完了ボタン押下の状態に戻す
     */
    undo() {
      this.completeBtn = true
      this.undoBtn = false
    },

    /**
     * 履歴モーダルオープン
     */
    openHistory() {
      this.historyModal = true
      this.openHistoryModal++
    },

    /**
     * 履歴モーダルクローズ
     */
    checkListHistoryModalClose() {
      this.historyModal = false
    },

    // 添付画像 取得
    async getfullImages() {
      const res = await backend.searchData('patrolResult/getPhoto', { patrolId: this.target.id, type: this.type })
      if (res.data) this.fullImages = res.data
    },

    /**
     * 画像 クリック イベント
     * @param i 対象項目
     * @param j 画像インデックス
     */
    async clickImage(sortNo, i, j) {
      const key = this.target.id + '/' + sortNo + '/' + j
      if (this.fullImages && this.fullImages[key]) this.fullImage = this.fullImages[key]
      else this.fullImage = this.checkList.items[i].photo[j]
    },

    /**
     * 前の「予定一覧_詳細」画面に戻る
     */
    previous() {
      this.$router.push({ 
        name: this.routerName,
        params: {
          checkListDate: this.target.scheduledDate,
          userId: this.target.userId,
          name: this.target.staff
        },
        query: {
          checkList: 'true'
        }
      })
    },

    /**
     * 項目順番確定ボタン
     */
    async setPatrolSortNo() {
      this.processing = true
      this.processingText = '保存中...'
      this.checkList.items.forEach((item, i) => {
        item.patrolSortNo = i
      })
      // データ保存
      const save = {
        patrolScheduleId: this.target.id,
        clientCode: this.target.clientCode,
        siteCode: this.target.siteCode,
        insertUser: this.$store.getters.user.id
      }
      await backend.postData('checkListHistory/saveSort', { save, item: this.checkList.items, type: this.type, claim: this.claim })
      this.processing = false
      this.isChangeSort = false
    },

    /**
     * 項目並べ替え変更イベント
     */
    sortChanged(arg) {
      if (arg.moved) {
        this.isChangeSort = true
      }
    }
  }
}
</script>
<style scoped>
.mobile-content {
  max-width: 1000px;
  min-width: 800px;
  min-height: 1000px;
  overflow: auto;
  padding: 10px;
  -webkit-text-size-adjust: 100%;
  /* appearance: none; */
}

.pc-content {
  max-width: 1000px;
  height: calc(100vh - 20px);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  border: solid 1px rgb(206, 206, 246);
  border-radius: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.vsa-item {
  background-color: rgb(214, 238, 243);
}

.file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

_::-webkit-full-page-media,
_:future,
:root .file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

_::-webkit-full-page-media,
_:future,
:root .comp-btn {
  position: relative;
  z-index: 50;
  width: 100%;
  min-width: 4rem !important;
}

_::-webkit-full-page-media,
_:future,
:root .comp-btn2 {
  position: relative;
  z-index: 50;
  width: 70px;
}

.file-type {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

_::-webkit-full-page-media,
_:future,
:root .file-type {
  height: 100%;
  top: 0;
  left: 0;
  width: 70px;
  opacity: 0;
  position: absolute;
}

.na-btn {
  width: -webkit-fill-available;
  max-width: fit-content;
  padding-left: 11px;
  padding-right: 11px;
}

.w-70px {
  width: 70px;
}

.mr-73px {
  margin-right: 73px;
}

.bg-darkblue {
  background: #010178;
}

.bg-darkgreen {
  background: #010178;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 96%;
}

.edit {
  border: 1px solid #053a3a;
}

.p-5p {
  padding: 5px;
}

.p-6p {
  padding: 6px;
}

.w-354 {
  width: 354px;
}

.w-370 {
  width: 370px;
}

.info-group {
  display: flex;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
  margin-left: auto;
}

/* @media screen and (max-width: 900px) { */
  /* .mobile-content {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  } */
/* 
  .info-group {
    display: block;
  }

  .info1 {
    width: 100%;
  }

  .info2 {
    width: 100%;
    margin-right: auto;
  } */

/* } */
</style>