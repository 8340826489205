<template>
  <div>
    <div v-for="s in selection" :key="s" class="px-2 py-1 mb-1 rounded text-sm font-bold cursor-pointer text-white" :class="value.indexOf(s)>-1?'bg-indigo-700':'bg-gray-300'">
      <div v-if="s.indexOf('その他')==-1" @click="selectedItem(s)">
        {{ s }}
      </div>
      <div v-else class="flex">
        <div class="h-full my-auto">
          その他：
        </div>
        <div id="other">
          <input
            placeholder="ご記入ください"
            type="text"
            v-model="other"
            :disabled="!canEdit"
            class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 w-full text-sm"
            @change="changeOther()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    canEdit: {
      type: Boolean,
      default: true
    },

    selection: {
      type: Array,
      default: () => []
    }
  },

  computed: {
  },

  watch: {
    value() {
      this.getOther()
    },
  },

  data() {
    return {
      other: ''
    }
  },

  created() {
    this.getOther()
  },

  methods: {
    /**
     * 項目選択イベント
     * @param v 選択した値
     */
    selectedItem(v) {
      if (!this.canEdit) {
        return
      }
      let item = this.value
      if (item.includes(v)) {
        item = item.replace('@@@' + v, '')
      } else {
        item += '@@@' + v
      }
      this.$emit('change', item)
    },

    /**
     * その他の内容を取得
     */
    getOther() {
      this.other = ''
      // その他の文言を取得
      if (this.value) {
        if (this.value.indexOf('その他') > -1) {
          let res = this.value.substring(this.value.indexOf('その他') + 3)
          if (res && res.indexOf('@@@') > -1) {
            res = res.substring(0, res.indexOf('@@@'))
          }
          if (res) {
            this.other = res
          }
        }
      }
    },

    /**
     * その他内容を変更イベント
     */
    changeOther() {
      let item = this.value
      // if (item.includes('その他')) {
      let res = this.value.substring(this.value.indexOf('その他') + 3)
      if (res) {
        res = res.substring(0, res.indexOf('@@@'))
      }
      res = '@@@その他' + res
      item = item.replace(res, '')
      item = item.replace(/@@@その他/g, '')
      // 変更した場合
      if (this.other) {
        item += '@@@その他' + this.other
      }
      this.$emit('change', item)
    }
  }
}
</script>

<style scoped>
#other ::placeholder {
  color: lightgray;
  font-size: small;
}
</style>