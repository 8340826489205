<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="estimationCleanCategorySelections"
    :multiSelect="true"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

const estimationCleanCategorySelections = [
  {
    value: 'ラウンドサービス',
    text: 'ラウンドサービス'
  },
  {
    value: 'ラウンドプラス',
    text: 'ラウンドプラス'
  },
  {
    value: '日常清掃',
    text: '日常清掃',
  },
  {
    value: '管理員業務',
    text: '管理員業務'
  },
  {
    value: '定期清掃',
    text: '定期清掃'
  },
  {
    value: 'ガラス清掃',
    text: 'ガラス清掃'
  },
  {
    value: '排水管清掃',
    text: '排水管清掃'
  },
  {
    value: '貯水槽清掃',
    text: '貯水槽清掃'
  },
  {
    value: '植栽剪定',
    text: '植栽剪定'
  },
  {
    value: '特別清掃',
    text: '特別清掃'
  },
  {
    value: 'ラウンドサブメニュー',
    text: 'ラウンドサブメニュー'
  },
  {
    value: 'マンションローテーションサービス（MRS）',
    text: 'マンションローテーションサービス（MRS）'
  }
]

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalValue() {
      const value = this.value.map((v) => {
        return this.estimationCleanCategorySelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  data() {
    return {
      estimationCleanCategorySelections
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>