<template>
    <div>
        <Modal v-bind="$attrs" v-on="$listeners" :xIconDisabled="true">
            <template #title>面接関連の選択</template>
            <template>
                <div v-if="loading" class="flex justify-center items-center loading-dialog3">
                    <p 
                        class="text-gray-600 font-bold animate-pulse" style="font-size: 30px">Loading...</p>
                </div>

                <div class="content-container-app">
                    <!-- 検索 -->
                    <div class="flex mb-1">
                        <div class="relative focus-within:text-gray-400 search-contents">
                            <input      
                                type="search" 
                                name="requestNo" 
                                placeholder="お問い合わせ番号"
                                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                                v-model="requestNo" />
                        </div>
                        <div  class="relative focus-within:text-gray-400 search-contents">
                            <input    
                                type="search" 
                                name="interviewsName" 
                                placeholder="応募者"
                                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                                v-model="interviewsName" />
                        </div>
                        <div class="relative focus-within:text-gray-400 search-contents">
                            <input
                                type="search"
                                name="clientName" 
                                placeholder="お客様会社名"
                                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm" 
                                v-model="clientName"
                            />
                        </div>
                        <div class="w-180px mr-3" v-tooltip="'作成日'">
                            <DateSelect 
                                id="periodCreate" 
                                :presetName="periodPresetName" 
                                ref="dateSelect"
                                :period="periodCreate" 
                                class="search-contents" 
                                @change="periodChange" />
                        </div>
                        <div class="relative focus-within:text-gray-400 search-contents">
                            <input 
                                type="search" 
                                name="toStaffName" 
                                placeholder="担当者"
                                class="block w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                                v-model="toStaffName" />
                        </div>
                        <div class="ml-auto mr-1">
                            <PrimaryButton text="検索" class="ml-auto" @click="getData()" />
                        </div>
                    </div>
                    <!-- 一覧 -->
                    <div class="content-container flex flex-col border border-gray-300 rounded bg-white relative">
                        <div class="overflow-auto flex-1 relative list">
                            <VirtualDataTable 
                                ref="vdt" 
                                :columns="columns" 
                                :columnWidth="columnWidth"
                                :columnAlignment="columnAlignment" 
                                :rowHeight="60" :data="list" :selectable="true"
                                @selectionChange="selectionChange" />
                        </div>
                        <p v-if="list.length === 0 && !loading"
                            class="absolute w-full h-full flex justify-center items-center text-gray-400">
                            表示する面接関連がありません
                        </p>
                    </div>

                    <div class="mt-3 flex">
                        <SecondaryButton 
                            text="選択しない" 
                            class="w-full border border-indigo-800 mr-2" 
                            @click="notSelect()"
                        />
                        <PrimaryButton 
                            text="選択" 
                            class="w-full ml-2" 
                            :disabled="selectedData === null"
                            @click="select()"
                        />
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import SecondaryButton from '@components/SecondaryButton.vue'
import VirtualDataTable from '@components/VirtualDataTable.vue'
import DateSelect from '@components/DateSelect.vue'
import * as requestPostManager from '@managers/requestPostManager'
import * as utils from '@libs/utils'
// import * as dialogs from '@libs/dialogs'

// カラム
const columns = [
    [{ name: 'requestNo', title: 'お問い合わせ番号', type: 'link', blank: true, url: '/RequestPost?requestPostId=${record._id}&mode=1' }],
    [{ name: 'interviewsName', title: '応募者', type: 'text', }],
    [{ name: 'clientName', title: 'お客様会社名', type: 'text' }],
    [
        {
            name: 'createDate',
            title: '作成日',
            type: 'text',
            valueFunc: rowData => {
                const d = utils.stringToDate(rowData.createDate)
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
            }
        },
        {
            name: 'limitDate',
            title: '期限',
            type: 'text',
            valueFunc: rowData => {
                const d = utils.stringToDate(rowData.limitDate)
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
            }
        }
    ],
    [
        {
            name: 'toStaffName',
            title: '担当者',
            type: 'text'
        },
        {
            name: 'employmentCreaterName',
            title: '作成者',
            type: 'text'
        },
    ],
]

// カラム幅
const columnWidth = [
    '150px', // お問い合わせ番号
    'auto', // 応募者
    'auto', // 得意先・お客様住所（建物名）
    '140px', // 作成日・期限
    '130px', // 担当者
]

// カラム位置
const columnAlignment = [
    'left', // お問い合わせ番号
    'left', // 応募者・お客様住所（建物名）
    'left', // 要件
    'center', // 作成日・期限
    'left', // 社内担当者
]

export default {
    components: {
        Modal,
        PrimaryButton,
        SecondaryButton,
        VirtualDataTable,
        DateSelect
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            // お問い合わせ一覧
            list: [],
            // カラム
            columns,
            // カラムの幅
            columnWidth,
            // カラムの位置
            columnAlignment,
            // お問い合わせ番号検索
            requestNo: '',
            // 応募者検索
            interviewsName: '',
             // 得意先検索
            clientName: '',
            // 作成日期間検索 表示期間名
            periodPresetName: '6_month',
            // 作成日期間検索
            periodCreate: {
                start: '',
                end: ''
            },
            // 担当者検索
            toStaffName: '',
            // 選択したデータ
            selectedData: null,
            // ローディング
            loading: false
        }
    },

    async created() {
        this.toStaffName = ''
    },

    watch: {
        open() {
            if (this.open) {
                this.getData()
            } else {
                return
            }
        }
    },

    computed: {
        /**
         * ユーザーリスト
         */
        // userList() {
        //     return this.$store.getters.userList
        // },
    },

    methods: {
        /**
         * データ取得
         */
        async getData() {
            this.loading = true
            // 面接関連データ取得
            const res = await requestPostManager.getInterviersForEmployment(this, this.requestNo, this.interviewsName, this.clientName, this.periodCreate, this.toStaffName)
            if (res && res.length) {
                this.list = res
            } else {
                this.list = []
            }
            this.loading = false
        },

        /**
         * 作成日期間変更イベント
         * @param range 範囲
         * @param presetName 期間名
         */
        periodChange(range, presetName) {
            this.periodCreate = range
            this.periodPresetName = presetName
        },

        /**
         * ラジオボタン選択イベント
         */
        selectionChange(selectedRow) {
            this.selectedData = selectedRow
        },

        /**
         * 選択しないボタンイベント
         */
        notSelect() {
            this.$emit('modalInterviewsSelected', null)
        },

        /**
         * 選択ボタンイベント
         */
        select() {
            this.$emit('modalInterviewsSelected', this.selectedData)
        },

        /**
         * 初期化
         */
        closed() {
            this.selectedData = null
            this.loading = false
        }
    }

}
</script>
<style scoped>
.content-container {
    width: calc(100vw - 185px);
    height: calc(100vh - 300px);
    overflow: auto;
}

#periodCreate>>>input[type=text] {
    background: white !important;
    color: gray !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    width: 180px !important;
}

#periodCreate>>>.pointer-events-none {
    color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.w-180px {
    width: 180px !important;
}

@media screen and (max-width: 1500px) {
    .content-container {
        width: 95vw;
        height: calc(100vh - 150px);
        overflow: auto;
    }

    .list {
        width: 1500px !important;
    }
}
</style>