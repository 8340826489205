<template>
  <Icon :iconName="setIcon" :iconType="iconType" v-bind:class="{ 'red': setIcon == 'ExclamationCircle', 'green': setIcon == 'CheckCircle', 'gray': setIcon == 'MinusCircle' }"/>
</template>
<script>
import Icon from './Icon.vue'
export default {
  components: {
    Icon,
  },
  props: ['iconType', 'record'],
  computed: {
    setIcon() {
      const name = this.record['iconName']
      return name
    },
  }
}
</script>

<style>
  .red {
    color: red;
    margin: auto;
  }

  .green {
    color: green;
    margin: auto;
  }

  .gray {
    color: gray;
    margin: auto;
  }
</style>