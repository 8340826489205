<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @before-close="closing" @closed="closed">
      <template #title>{{title}}</template>
      <div class="content-container">
        <iframe
          v-if="show"
          ref="pigeonWindow"
          class="w-full h-full border-0 border border-gray-300 rounded"
          :src="url"
        ></iframe>
        <iframe v-if="dummyFrameAlive" ref="dummyframe" class="hidden"></iframe>
      </div>
      <template #action>
        <div class="flex w-full justify-between">
          <PrimaryButton v-if="taskId" text="更新" class="w-full" size="lg" @click="update" />
          <PrimaryButton v-else text="登録" class="w-full" size="lg" @click="register" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { PIGEON_CONFIG } from '@/config'
import Modal from './Modal.vue'
import PrimaryButton from './PrimaryButton.vue'
import * as utils from '@libs/utils'

export default {
  inheritAttrs: false,

  components: {
    Modal,
    PrimaryButton,
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    formId: {
      type: String,
      default: ''
    },
    embed: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: true
    },
    taskId: {
      type: String,
      default: null
    },
    queryParams: {
      type: Object,
      defasult: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dummyFrameAlive: true
    }
  },

  computed: {
    url() {
      let url = `${PIGEON_CONFIG.baseUrl}form?formId=${this.formId}`
      
      if (this.taskId) {
        url = url + `&taskId=${this.taskId}`
      }

      if (this.embed) {
        url = url + '&embed=true'
      }

      if (this.fluid) {
        url = url + '&formFluid=true'
      }
      
      if (!this.header) {
        url = url + '&notHeader=true'
      }

      if (this.queryParams) {
        const q = utils.toQueryString(this.queryParams)
        url = url + `&${q}`
      }

      url = url + `&token=${this.$store.getters.user.token}`

      return url
    }
  },

  methods: {
    register() {
      this.postRegisterMessageToPigeon('register', this.registerCompletedHandler)
    },

    update() {
      this.postRegisterMessageToPigeon('update', this.updateCompletedHandler)
    },

    postRegisterMessageToPigeon(message, handler) {
      const win = this.$refs.pigeonWindow.contentWindow
      win.postMessage(message, '*');
      window.removeEventListener('message', handler);
      window.addEventListener('message', handler);
    },

    registerCompletedHandler(event) {
      if (utils.isObject(event.data)) {
        if (event.data.message === 'registerCompletedWithRegisteredData') {
          this.$emit('registered', event.data.registeredData)
        }
      // } else {
      //   if (event.data === 'registerCompleted') {
      //     console.log('registerCompleted')
      //     this.$emit('registered')
      //   }
      }
    },

    updateCompletedHandler(event) {
      if (utils.isObject(event.data)) {
        if (event.data.message === 'registerCompletedWithRegisteredData') {
          this.$emit('updated', event.data.registeredData)
        }
      // } else {
      //   if (event.data == "registerCompleted") {
      //     console.log('updateCompleted')
      //     this.$emit('updated')
      //   }
      }
    },

    closing() {
      console.log('PigeonFormModal::closing')
      const url = PIGEON_CONFIG.baseUrl
      this.$refs.dummyframe.src = url
      setTimeout(() => {
        this.$refs.dummyframe.src = ''
        this.dummyFrameAlive = false
        this.$nextTick(() => {
          this.dummyFrameAlive = true
        })
      }, 500)
    },

    closed() {
      console.log('PigeonFormModal::closed')
      this.$emit('closed')
    }
  }
}
</script>
<style scoped>
iframe {
  padding: 4px 0px 16px 0px;
  background-color: #fafafa!important;
}

.content-container {
  width: 90vw;
  height: 80vh;
  /* max-width: 1024px;
  height: 600px; */
}
</style>