
<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="flex justify-between">
            <div class="text-lg text-blue-800 font-bold">施工月設定 </div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="text-gray-600 font-bold mr-auto">
              得意先 :  {{ list.clientName1 }}
            </div>
            <div class="text-gray-600 font-bold mr-auto mt-5">
              物件名 :  {{ list.siteName }}
            </div>
            <div class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">Weight : </div>
              <div class="border border-blue-500 ml-5">
                <NumericTextInput
                  name="weight"
                  v-model="list.weight"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>
            <div class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">人数 : </div>
              <div class="border border-blue-500 ml-5">
                <NumericTextInput
                  name="people"
                  v-model="list.people"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>

            <div class="flex mt-5">
              <PrimaryButton text="月1回" size="normal" class="w-full mr-1 ml-1" :class="monthly==1?'':'bg-gray-400'" @click="setMonthly(1)" />
              <PrimaryButton text="月2回" size="normal" class="w-full mr-1" :class="monthly==2?'':'bg-gray-400'" @click="setMonthly(2)" />
            </div>
            
            <div class="border border-blue-200 p-1 mt-3">
              <div class="flex">
                <PrimaryButton text="1月" size="normal" class="w-full mr-1 ml-1" :class="monthes.includes(1)?'':'bg-gray-400'" @click="setMonthes(1)" />
                <PrimaryButton text="2月" size="normal" class="w-full mr-1" :class="monthes.includes(2)?'':'bg-gray-400'" @click="setMonthes(2)" />
                <PrimaryButton text="3月" size="normal" class="w-full mr-1" :class="monthes.includes(3)?'':'bg-gray-400'" @click="setMonthes(3)" />
                <PrimaryButton text="4月" size="normal" class="w-full mr-1" :class="monthes.includes(4)?'':'bg-gray-400'" @click="setMonthes(4)" />
                <PrimaryButton text="5月" size="normal" class="w-full mr-1" :class="monthes.includes(5)?'':'bg-gray-400'" @click="setMonthes(5)" />
                <PrimaryButton text="6月" size="normal" class="w-full mr-1" :class="monthes.includes(6)?'':'bg-gray-400'" @click="setMonthes(6)" />
                <PrimaryButton text="7月" size="normal" class="w-full mr-1" :class="monthes.includes(7)?'':'bg-gray-400'" @click="setMonthes(7)" />
                <PrimaryButton text="8月" size="normal" class="w-full mr-1" :class="monthes.includes(8)?'':'bg-gray-400'" @click="setMonthes(8)" />
                <PrimaryButton text="9月" size="normal" class="w-full mr-1" :class="monthes.includes(9)?'':'bg-gray-400'" @click="setMonthes(9)" />
                <PrimaryButton text="10月" size="normal" class="w-full mr-1" :class="monthes.includes(10)?'':'bg-gray-400'" @click="setMonthes(10)" />
                <PrimaryButton text="11月" size="normal" class="w-full mr-1" :class="monthes.includes(11)?'':'bg-gray-400'" @click="setMonthes(11)" />
                <PrimaryButton text="12月" size="normal" class="w-full mr-1" :class="monthes.includes(12)?'':'bg-gray-400'" @click="setMonthes(12)" />
              </div>
            </div>
          </div>
          <PrimaryButton text="登録" size="normal" class="w-full" @click="save()" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
// import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

const claim = false

export default {
  components: {
    Modal,
    PrimaryButton,
    NumericTextInput,
  },

  props: {
    target: {
      type: Object
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 表示データ
      list: { id: null, siteCode: null, weight: null, people: null, clientName1: '', siteName: '', monthly: 1 },
      // 月の回数
      monthly: 1,
      monthes: []
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getTarget()
      }
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.list = this.target
        // 月〇回
        this.monthly = Number(this.target.monthly)
        // 〇月
        if (this.list.monthes == '毎月') {
          this.monthes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else {
          // 念のため、重複しないように
          let montesNo = []
          for (let i = 0; i < this.list.monthes.split(',').length; i++) {
            const m = this.list.monthes.split(',')[i]
            if (!montesNo.includes(Number(m))) {
              montesNo.push(Number(m))
            }
          }
          this.monthes = montesNo
        }
      }
    },

    /**
     * 初期化
     */
    closed() {
      
      // 表示データ
      this.list = {}
      // 月の回数
      this.monthly = 1
      this.monthes = []
    },

    /**
     * サイクル期間変更イベント
     */
    setMonthly(val) {
      this.monthly = val
    },

    /**
     * 実践月変更イベント
     */
    setMonthes(value) {
      if (this.monthes.includes(value)) {
        let m = this.monthes.filter((mo => {
          return mo != value
        }))
        this.monthes = m
      } else {
        this.monthes.push(value)
      }
    },

    /**
     * 登録処理
     */
    async save() {
      if (!this.list.weight || isNaN(this.list.weight)) {
        await dialogs.showErrorDialog('Wightは必須です', 'Weightを入力してください。')
        return
      }
      // 月の値をソート
      if (this.monthes.length) {
        this.monthes.sort(function (a, b) {
          return a - b
        })

        // 期間がデフォルトと同じなら期間はnullで保存
        if (this.monthes.length == 12) {
          if (this.monthes.join(',') == [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].join(',')) {
            this.monthes = []
          }
        }
      } else {
        await dialogs.showErrorDialog('月を選択して下さい', '巡回清掃を実践する月を選択してください。')
        return
      }

      let save = []

      // 毎月でない場合
      if (this.monthes.length) {
        for (let i = 0; i < this.monthes.length; i++) {
          const m = this.monthes[i]
          let startMonthes = null
          const endMonthes = m
          // 期間ごとの開始月
          if (i == 0) {
            startMonthes = this.monthes[this.monthes.length-1] + 1
            if (startMonthes == 13) {
              startMonthes = 1
            }
          } else {
            startMonthes = this.monthes[i-1] + 1
          }
          let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, people: this.list.people, termMonth: true, termWeek: false, startMonthes: startMonthes, endMonthes: endMonthes, week: null, insertUser: this.$store.getters.user.id, monthly: this.monthly }
          saveData.claim = claim
          saveData.type = this.type
          save.push(saveData)
        }
      } else {
        save = [{ id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, people: this.list.people, termMonth: true, termWeek: false, startMonthes: null, endMonthes: null, week: null, insertUser: this.$store.getters.user.id, monthly: this.monthly, claim, type: this.type }]
      }

      await backend.postData('patrolTerm/save', save)

      //ログ出力
      await logManager.recordingByRegist(this, false, this.typeName, this.typeName + 'サイクル設定（物件名：' + this.target.siteName + '、得意先名：' + this.target.clientName1 + '）')
      this.$emit('modalClose')
      this.closed()
    },
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}
@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>