<template>
  <div id="el" class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-4 self-start">
    <div class="sm:col-span-full">
      <WmsTextInput 
        name="estimateNo" 
        caption="見積No" 
        :disabled="true"
        v-model="order.estimateNo"
      />
    </div>

    <div class="sm:col-span-4">
      <WmsTextInput
        name="caseName"
        caption="案件名" 
        v-model="order.caseName"
        :disabled="isDisabled || referenceMode"
      >
      </WmsTextInput>
    </div>

    <div class="sm:col-span-2" style="margin-top: auto" v-if="!isTaskRequestMode">
      <PrimaryButton text="見積参照" class="w-40" @click="referenceEstimate">
      </PrimaryButton>
    </div>

    <div class="sm:col-span-full" v-tooltip="'【物件番号】要記載得意先：東急・アートアベニュー・三菱・セキスイ・ナイス・長谷工・大和地所・大京、 【チーム】要記載得意先：東急（住宅リース以外）'">
      <WmsTextInput 
        name="clientOnbuildingName" 
        caption="物件情報"
        v-model="order.buildingName"
        :multiline="true"
        :rows="3"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full pt-4">
      <div class="flex">
        <div class="text-xs font-bold text-gray-700 my-auto">メモ</div>
        <PrimaryButton text="新規得意先" v-tooltip="'メモ欄に得意先情報を表示します'" size="xs" class="ml-5" @click="clientContents(mainData||orderData)" :disabled="isDisabled || referenceMode" :class="order.newClient?'':'bg-gray-300'"></PrimaryButton>
        <PrimaryButton text="既存得意先" v-tooltip="'メモ欄の得意先情報を削除します'" size="xs" class="ml-3" @click="deleteClientContents()" :disabled="isDisabled || referenceMode" :class="order.newClient?'bg-gray-300':''"></PrimaryButton>
      </div>
      <WmsTextInput 
        name="orderMemo" 
        :multiline="true"
        :rows="order.newClient?8:5"
        v-model="order.orderMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <!-- 新規得意先の場合 -->
    <div v-if="order.newClient" class="sm:col-span-full">
      <div class="flex w-full">
        <div class="w-1/2">  
          <OrderItemSelect 
            name="orderSendingDate" 
            caption="予定送付日"
            v-model="order.orderSendingDate"
            :multiSelect="false"
            :item="[{ value: '1', text: '20日' }, { value: '2', text: '変更あり' }]"
            :disabled="isDisabled || referenceMode"
          />
        </div>
        <div class="w-1/2 ml-3">
          <!-- 変更ありの場合 -->
          <WmsTextInput 
            name="orderSendingDateMemo" 
            caption="予定送付日 (変更ありの場合)"
            v-model="order.orderSendingDateMemo"
            :disabled="isDisabled || referenceMode"
            v-if="order.orderSendingDate&&order.orderSendingDate[0]=='2'"
            cl
          />
        </div>
      </div>
    </div>

    <!-- 新規得意先の場合 -->
    <div v-if="order.newClient" class="sm:col-span-full">
      <div class="flex w-full">
        <div class="w-1/2">  
          <OrderItemSelect 
            name="orderClosingDate" 
            caption="締め日"
            v-model="order.orderClosingDate"
            :multiSelect="false"
            :item="[{ value: '1', text: '末日' }, { value: '2', text: '変更あり' }]"
            :disabled="isDisabled || referenceMode"
          />
        </div>
        <div class="w-1/2 ml-3">
          <!-- 変更ありの場合 -->
          <WmsTextInput 
            name="orderClosingDateMemo" 
            caption="締め日 (変更ありの場合)"
            v-model="order.orderClosingDateMemo"
            :disabled="isDisabled || referenceMode"
            v-if="order.orderClosingDate&&order.orderClosingDate[0]=='2'"
            cl
          />
        </div>
      </div>
    </div>

    <!-- 新規得意先の場合 -->
    <div v-if="order.newClient" class="sm:col-span-full">
      <div class="flex w-full">
        <div class="w-1/2">  
          <OrderItemSelect 
            name="orderBillingDate" 
            caption="請求送付日"
            v-model="order.orderBillingDate"
            :multiSelect="false"
            :item="[{ value: '1', text: '末日' }, { value: '2', text: '変更あり' }]"
            :disabled="isDisabled || referenceMode"
          />
        </div>
        <div class="w-1/2 ml-3">
          <!-- 変更ありの場合 -->
          <WmsTextInput 
            name="orderBillingDateMemo" 
            caption="請求送付日 (変更ありの場合)"
            v-model="order.orderBillingDateMemo"
            :disabled="isDisabled || referenceMode"
            v-if="order.orderBillingDate&&order.orderBillingDate[0]=='2'"
            cl
          />
        </div>
      </div>
    </div>

    <!-- 新規得意先の場合 -->
    <div v-if="order.newClient" class="sm:col-span-full">
      <div class="flex w-full">
        <div class="w-1/2">  
          <OrderItemSelect 
            name="orderBillingName" 
            caption="請求宛先名"
            v-model="order.orderBillingName"
            :multiSelect="false"
            :item="[{ value: '1', text: '変更なし' }, { value: '2', text: '変更あり' }]"
            :disabled="isDisabled || referenceMode"
          />
        </div>
        <div class="w-1/2 ml-3">
          <!-- 変更ありの場合 -->
          <WmsTextInput 
            name="orderBillingNameMemo" 
            caption="請求宛先名 (変更ありの場合)"
            v-model="order.orderBillingNameMemo"
            :disabled="isDisabled || referenceMode"
            v-if="order.orderBillingName&&order.orderBillingName[0]=='2'"
            cl
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput 
        name="orderSummary" 
        caption="登録内容"
        :multiline="true"
        :rows="5"
        v-model="order.orderSummary"
        disabled="true"
        v-if="order.orderSummary"
      />
    </div>

    <div class="sm:col-span-2">
      <WmsDateInput
        name="contractDate"
        caption="契約日" 
        v-model="order.contractDate"
        :required="true"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        :error="!validateContractDate.result"
        :errorMessage="validateContractDate.message"
        @change="changeContractDate($event)"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="contractDate" 
        caption="契約日"
        :required="true"
        v-model="dispContractDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="sm:col-span-2">
      <WmsDateInput
        name="contractEndDate"
        caption="契約終了日"
        v-model="order.contractEndDate"
        :readonly="isModileDevice"
        :required="true"
        :clearable="isModileDevice"
        :error="!validateContractEndDate.result"
        :errorMessage="validateContractEndDate.message"
        @change="changeContractEndDate($event)"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="contractEndDate" 
        caption="契約終了日"
        v-model="dispContractEndDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full">
      <OrderItemSelect 
        name="classification" 
        caption="区分"
        v-model="order.classification"
        :required="true"
        :error="!validateClassification.result"
        :errorMessage="validateClassification.message"
        :multiSelect="false"
        :item="selectItemClassification"
        @change="changeClassification($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" id="button-w-sm">
      <EstimationDetailsCategorySelect
        name="estimationDetailsCategory"
        caption="見積明細カテゴリー" 
        v-model="order.estimationDetailsCategory"
        :disabled="true"
        :categoryName="order.categoryName"
        :categoryCode="order.estimationDetailsCategoryCode"
      />
    </div>

    <div v-for="(p, i) in property" :key="100 + i" class="sm:col-span-full" v-show="order[p] && order[p].length">
      <div class="sm:col-span-full"  v-if="order[p] && order[p].length" :class="['orderSpot', 'orderOthers'].includes(p) ? '':'border border-indigo-200'">
        <div v-for="(o, oI) in order[p]" v-bind:key="200 + oI" :class="o.file ? 'border border-indigo-200 mt-3':''">
          <div class="p-5">
            <InputGroupSubLabel
              :caption="o.name"
              id="sub-label"
              class="mt-4 sm:col-span-full"
            />

            <!-- RLSプラス 種別 -->
            <div class="sm:col-span-full" v-if="o.categoryCode=='2'">
              <OrderItemSelect 
                name="estimationRoundPlusCategorySelect" 
                v-model="o.estimationRoundPlusCategorySelect"
                :item="estimationRoundPlusCategorySelectItem"
                :disabled="true"
              />
            </div>

            <!-- 作業内容コード -->
            <div class="mt-osc" v-if="o.workCode && (o.categoryCode!='14' || (o.categoryCode == '14' && o.newData))">
              <div class="text-xs text-gray-600 font-bold">
                <label class="block text-xs font-bold text-gray-700">
                  作業内容コード
                </label>
                <div class="flex">
                  <div class="w-40 border border-gray-300 rounded h-8 p-2 mt-1" :class="(isDisabled || referenceMode)? 'cursor-not-allowed': 'bg-white cursor-pointer'" @click="openWorkCode(o)">
                    {{ o.workCode.code }}
                  </div>
                  <div v-if="o.workCode.code" class="my-auto text-gray-400 text-xs ml-3">
                    {{ o.workCode.category }} ⇒ {{ o.workCode.detail }}
                  </div>
                </div>
              </div>
            </div>

            <!-- 日常・管理・定期・特別の場合 -->
            <div v-if="['3', '4', '5', '14'].includes(o.categoryCode)" class="sm:col-span-full">
              <div v-for="(det, j) in o.details" :key="300 + j">
                <div v-if="det.content" class="sm:col-span-full mt-3" :class="{'mt-6': j > 0}">
                  <label
                    v-if="det.content.indexOf('調整費')==-1"
                    :name="p + 'Content'"
                    class="text-sm font-bold"
                  >
                    【{{det.content}}】
                  </label>
                </div>

                <!-- 特別清掃 -->
                <div class="mt-osc" v-if="o.categoryCode=='14'">
                  <div v-if="!o.newData" class="text-xs text-gray-600 font-bold">
                    <label v-if="det.workCode" class="block text-xs font-bold text-gray-700">
                      作業内容コード
                    </label>
                    <div v-if="det.workCode" class="flex">
                      <div class="w-40 border border-gray-300 rounded h-8 p-2 mt-1" :class="(isDisabled || referenceMode)? 'cursor-not-allowed': 'bg-white cursor-pointer'" @click="openWorkCode(det)">
                        {{ det.workCode.code }}
                      </div>
                      <div v-if="det.workCode.code" class="my-auto text-gray-400 text-xs ml-3">
                        {{ det.workCode.category }} ⇒ {{ det.workCode.detail }}
                      </div>
                    </div>
                  </div>

                  <!-- 特別清掃の作業項目（契約書に使用）調整費以外で表示 -->
                  <div v-if="det.content.indexOf('調整費')==-1" class="text-xs text-gray-600 font-bold mt-2">
                    <label
                      class="block text-xs font-bold text-gray-700"
                    >
                      作業項目 <span class="-top-0.5 ml-1 text-red-500"> * </span>
                    </label>
                    <div class="mt-1 flex relative">
                      <InputIcon v-if="!det.workItem" iconName="ExclamationCircle" :iconColor="'red'" message="作業項目は必須入力です。"/>
                      <form>
                        <select
                          name="specialCleanWorkItem" 
                          caption="作業項目" 
                          v-model="det.workItem"
                          :disabled="isDisabled || referenceMode"
                          class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop border-gray-300 "
                          :class="[isDisabled || referenceMode ? 'cursor-not-allowed' : '']"
                        >
                          <option
                            v-for="workItem in workItemList"
                            :key='workItem.id'
                            :value="workItem.id"
                            class="bg-white">
                            {{ workItem.value }}
                          </option>
                        </select>
                      </form>
                    </div>
                  </div>
                </div>

                <div v-if="['3', '4'].includes(o.categoryCode)" class="mt-3 sm:col-span-full" id="button-w-sm">
                  <OrderItemSelect 
                    :name="p + 'Count2'" 
                    caption="清掃希望回数"
                    v-model="det.count2"
                    :item="selectEstimationEverydayCountItemMonth"
                    :disabled="true"
                  />
                </div>

                <!-- 定期清掃 見積りの数量2 -->
                <div v-else-if="det.dispCount || det.dispCount==''" class="sm:col-span-full mt-3">
                  <WmsTextInput 
                    :name="p + 'Count'" 
                    caption="清掃希望回数"
                    v-model="det.dispCount"
                    :disabled="true"
                  />
                </div>

                <!-- 日常・管理 -->
                <div v-if="['3', '4'].includes(o.categoryCode)" class="mt-3">
                  <WmsDateInput
                    :name="p + 'startDate'"
                    caption="開始日" 
                    v-model="det.startDate"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-if="!isTaskRequestMode && !referenceMode"
                  />

                  <WmsTextInput
                    :name="p + 'startDate'"
                    caption="開始日"
                    v-model="det.startDate"
                    :disabled="true"
                    v-if="isTaskRequestMode || referenceMode"
                  />
                </div>

                <!-- 定期・特別 -->
                <div v-else>
                  <div class="mt-3 sm:col-span-full" v-if="det.firstWorkYear || det.firstWorkYear == ''">
                    <WmsSuggestInput
                      :name="p + 'FirstWorkYear'" 
                      caption="初回作業年" 
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="selectItemYear"
                      displayField="text"
                      :filter="{}"
                      :readonly="isModileDevice"
                      :clearable="isModileDevice"
                      v-model="det.firstWorkYear"
                      :disabled="isDisabled || referenceMode"
                    />
                  </div>

                  <div class="mt-3 sm:col-span-full" v-if="det.firstWorkMonth" id="button-w-sm3">
                    <OrderItemSelect 
                      :name="p + 'FirstWorkMonth'" 
                      caption="初回作業月"
                      v-model="det.firstWorkMonth"
                      :multiSelect="false"
                      :item="selectItemMonth"
                      :disabled="isDisabled || referenceMode"
                      @change="changeFirstWorkMonthDetails($event, j, o.details, o)"
                    />
                  </div>

                  <div class="sm:col-span-full mt-3" v-if="det.implementationMonth" id="button-w-sm3">
                    <OrderItemSelect 
                      :name="p + 'ImplementationMonth'" 
                      caption="実施月"
                      v-model="det.implementationMonth"
                      :item="selectItemMonth"
                      :disabled="isDisabled"
                    />
                  </div>

                  <div class="mt-3" v-if="det.unitPrice">
                    <WmsTextInput 
                      :name="p + 'UnitPrice'" 
                      caption="受注単価（税抜き）" 
                      v-model="det.unitPrice"
                      :disabled="true"
                    />
                  </div>

                  <div class="mt-3" v-if="det.amount">
                    <WmsTextInput 
                      :name="p + 'Amount'" 
                      caption="受注総額（税抜き）" 
                      v-model="det.amount"
                      :disabled="true"
                    />
                  </div>

                  <div v-if="o.categoryCode=='5'" class="flex grid grid-cols-6 gap-2">
                    <div class="mt-3 col-span-3">
                      <WmsSuggestInput
                        name="regularCleanTargetArea" 
                        caption="対象箇所" 
                        :dynamic="false"
                        :incrementalSearch="false"
                        :selectionItems="regularCleanTargetAreaSelectItem"
                        displayField="text"
                        :filter="{}"
                        :readonly="isModileDevice"
                        :clearable="isModileDevice"
                        v-model="det.area"
                        :disabled="isDisabled || referenceMode"
                      />
                    </div>

                    <div class="mt-3 col-span-3">
                      <WmsSuggestInput
                        name="regularCleanDescriptionWork" 
                        caption="作業内容" 
                        :dynamic="false"
                        :incrementalSearch="false"
                        :selectionItems="regularCleanDescriptionWorkSelectItem"
                        displayField="text"
                        :filter="{}"
                        :readonly="isModileDevice"
                        :clearable="isModileDevice"
                        v-model="det.workDetail"
                        :disabled="isDisabled || referenceMode"
                      />
                    </div>
                  </div>

                  <!-- <div v-if="o.categoryCode=='5'" class="mt-3 sm:col-span-1">
                    <OrderItemSelect 
                      name="regularCleanCarpet" 
                      caption="カーペット対象の場合はクリックして下さい"
                      v-model="det.carpet"
                      :item="selectItemCarpet"
                      :disabled="isDisabled || referenceMode"
                    />
                  </div> -->
                </div>

                <!-- 日常・管理の作業詳細（人数分） -->
                <div class="sm:col-span-full mt-3" v-if="det.remarks">
                  <div v-for="(remark, index) in det.remarks" v-bind:key="400 + index">
                    <div class="sm:col-span-full" :class="{'mt-6': index > 0}">
                      <label
                        name="idxLabel"
                        class="text-sm font-bold"
                      >
                        【{{index + 1}}】
                      </label>
                    </div>

                    <div class="sm:col-span-full" id="button-w-sm2">
                      <OrderItemSelect
                        name="remarkDays"
                        caption="作業曜日"
                        v-model="remark.workDays"
                        :item="selectItemWeek"
                        :disabled="isDisabled || referenceMode"
                      />
                    </div>

                    <div class="flex mt-ec">
                      <div class="startTime">
                        <WmsTimeInput2
                          name="remarkStartTime" 
                          caption="作業開始時間"
                          :clearable="true"
                          v-model="remark.startTime"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="isDisabled || referenceMode"
                        />
                      </div>

                      <div class="endTime">
                        <WmsTimeInput2
                          name="remarkEndTime" 
                          caption="作業終了時間"
                          :clearable="true"
                          v-model="remark.endTime"
                          :hourItems="hourItems"
                          :minuteItems="minuteItems"
                          :disabled="isDisabled || referenceMode"
                        />
                      </div>
                    </div>

                    <div class="deleteButton" v-if="!referenceMode && !isDisabled">
                      <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeWorkRemarks(det.remarks, index)">
                        <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                      </PrimaryButton>
                    </div>
                  </div>
                </div>

                <!-- 日常・管理 作業詳細追加ボタン -->
                <div class="sm:col-span-full addButton-contents mt-2" v-if="!referenceMode && !isDisabled && ['3', '4'].includes(o.categoryCode)">
                  <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'作業曜日・時間追加'" @click="addWorkRemarks(det.remarks)">
                    <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>
              </div>
            </div>

            <!-- RLS、RLSプラス、MRS -->
            <div v-if="o.count2" class=" mt-3 sm:col-span-full" id="button-w-sm">
              <OrderItemSelect 
                :name="p + 'Count2'" 
                caption="清掃希望回数"
                v-model="o.count2"
                :item="o.categoryCode=='29'?selectCleanCountItemMonthMRS:selectCleanCountItemMonth"
                :disabled="true"
              />
            </div>

            <!-- 見積りの数量2 -->
            <div v-if="!o.count2 && o.dispCount" class="sm:col-span-full mt-3">
              <WmsTextInput 
                :name="p + 'Count'" 
                caption="清掃希望回数"
                v-model="o.dispCount"
                :disabled="true"
              />
            </div>

            <div class="mt-3" v-if="(o.startDate || o.startDate == '') && !isTaskRequestMode && !referenceMode">
              <WmsDateInput
                :name="p + 'StartDate'"
                caption="開始日" 
                v-model="o.startDate"
                :readonly="isModileDevice || isTaskRequestMode || referenceMode"
                :clearable="isModileDevice"
              />
            </div>

            <div class="mt-3 sm:col-span-2" v-if="(o.startDate || o.startDate == '') && (isTaskRequestMode || referenceMode)">
              <WmsTextInput 
                name="estimationRoundStartDate" 
                caption="開始日"
                v-model="o.startDate"
                :disabled="true"
              />
            </div>

            <div class="mt-3 sm:col-span-full" v-if="o.workDays" id="button-w-sm2">
              <OrderItemSelect
                :name="p + 'WorkDays'"
                caption="作業曜日"
                v-model="o.workDays"
                :item="selectItemWeek"
                :disabled="isDisabled || referenceMode"
              />
            </div>

            <div class="mt-3 sm:col-span-full" v-if="o.firstWorkYear || o.firstWorkYear == ''">
              <WmsSuggestInput
                :name="p + 'FirstWorkYear'" 
                caption="初回作業年" 
                :dynamic="false"
                :incrementalSearch="false"
                :selectionItems="selectItemYear"
                displayField="text"
                :filter="{}"
                :readonly="isModileDevice"
                :clearable="isModileDevice"
                v-model="o.firstWorkYear"
                :disabled="isDisabled || referenceMode"
              />
            </div>

            <div class="mt-3 sm:col-span-full" v-if="o.firstWorkMonth" id="button-w-sm3">
              <OrderItemSelect 
                :name="p + 'FirstWorkMonth'" 
                caption="初回作業月"
                v-model="o.firstWorkMonth"
                :multiSelect="false"
                :item="selectItemMonth"
                :disabled="isDisabled || referenceMode"
                @change="changeFirstWorkMonth($event, oI, p)"
              />
            </div>

            <div class="sm:col-span-full mt-3" v-if="o.implementationMonth" id="button-w-sm3">
              <OrderItemSelect 
                :name="p + 'ImplementationMonth'" 
                caption="実施月"
                v-model="o.implementationMonth"
                :item="selectItemMonth"
                :disabled="isDisabled"
              />
            </div>

            <!-- RLSプラスの作業詳細（人数分） -->
            <div class="sm:col-span-full mt-3" v-if="['2'].includes(o.categoryCode)">
              <div v-for="(remark, index) in o.remarks" v-bind:key="500 + index">
                <div class="sm:col-span-full" :class="{'mt-6': index > 0}">
                  <label
                    name="idxLabel"
                    class="text-sm font-bold"
                  >
                    【{{index + 1}}】
                  </label>
                </div>

                <div class="sm:col-span-full" id="button-w-sm2">
                  <OrderItemSelect
                    name="remarkDays"
                    caption="作業曜日"
                    v-model="remark.workDays"
                    :item="selectItemWeek"
                    :disabled="isDisabled || referenceMode"
                  />
                </div>

                <div class="flex mt-ec">
                  <div class="startTime">
                    <WmsTimeInput2
                      name="remarkStartTime" 
                      caption="作業開始時間"
                      :clearable="true"
                      v-model="remark.startTime"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                      :disabled="isDisabled || referenceMode"
                    />
                  </div>

                  <div class="endTime">
                    <WmsTimeInput2
                      name="remarkEndTime" 
                      caption="作業終了時間"
                      :clearable="true"
                      v-model="remark.endTime"
                      :hourItems="hourItems"
                      :minuteItems="minuteItems"
                      :disabled="isDisabled || referenceMode"
                    />
                  </div>
                </div>

                <div class="deleteButton" v-if="!referenceMode && !isDisabled">
                  <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeWorkRemarks(o.remarks, index)">
                    <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
                  </PrimaryButton>
                </div>
              </div>
            </div>

            <!-- RLSプラス 作業詳細追加ボタン -->
            <div class="sm:col-span-full addButton-contents mt-2" v-if="!referenceMode && !isDisabled && ['2'].includes(o.categoryCode)">
              <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'作業曜日・時間追加'" @click="addWorkRemarks(o.remarks)">
                <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- スポット清掃 -->
            <div v-if="o.categoryCode == '28'">
              <div class="flex grid grid-cols-6 gap-2">
                <div class="mt-3 col-span-3">
                  <WmsSuggestInput
                    name="area" 
                    caption="対象箇所" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="regularCleanTargetAreaSelectItem"
                    displayField="text"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-model="o.area"
                    :disabled="isDisabled || referenceMode"
                  />
                </div>

                <div class="mt-3 col-span-3">
                  <WmsSuggestInput
                    name="work" 
                    caption="作業内容" 
                    :dynamic="false"
                    :incrementalSearch="false"
                    :selectionItems="regularCleanDescriptionWorkSelectItem"
                    displayField="text"
                    :filter="{}"
                    :readonly="isModileDevice"
                    :clearable="isModileDevice"
                    v-model="o.workDetail"
                    :disabled="isDisabled || referenceMode"
                  />
                </div>
              </div>

              <!-- <div class="mt-3 sm:col-span-1">
                <OrderItemSelect 
                  name="carpet" 
                  caption="カーペット"
                  v-model="o.carpet"
                  :item="selectItemCarpet"
                  :disabled="isDisabled || referenceMode"
                />
              </div> -->
            </div>

            <div v-if="o.unitPrice || o.unitPrice == ''" class="mt-3">
              <WmsTextInput 
                :name="p + 'UnitPrice'" 
                caption="受注単価（税抜き）" 
                v-model="o.unitPrice"
                :disabled="true"
              />
            </div>

            <div v-if="o.unitPrice || o.unitPrice == ''" class="mt-3">
              <WmsTextInput 
                :name="p + 'Amount'" 
                caption="受注総額（税抜き）" 
                v-model="o.amount"
                :disabled="true"
              />
            </div>

            <div class="sm:col-span-full mt-3" v-if="o.supplier || o.supplier == ''">
              <WmsTextInput 
                :name="p + 'Supplier'" 
                caption="発注先名" 
                v-model="o.supplier"
                :disabled="isDisabled"
              />
            </div>

            <div class="sm:col-span-full mt-3" v-if="o.orderPrice || o.orderPrice == ''">
              <WmsTextInput 
                :name="p + 'Price'" 
                caption="発注金額" 
                v-model="o.orderPrice"
                :disabled="true"
              />
            </div>

            <!-- RLSプラスの発注（配列） -->
            <div v-if="['2'].includes(o.categoryCode)">
              <!-- 新仕様のラウンドプラス発注明細の場合 2023.07.25から新仕様 -->
              <div v-if="o.purchase && o.purchase.length" class="sm:col-span-full mt-5 mb-5">
                <div v-for="(row, i) in o.purchase" v-bind:key="'rlsPuls' + i">

                  <div v-if="row.typeName" class="text-xs mt-3 font-bold text-gray-500">
                    --- {{row.typeName}} ---
                  </div>

                  <div class="mt-2">
                    <WmsTextInput 
                      name="RoundPlusPurchaseSupplier" 
                      caption="発注先名" 
                      v-model="row.supplier"
                      :disabled="isDisabled || referenceMode"
                    />
                  </div>

                  <div class="mt-2">
                    <WmsTextInput 
                      name="RoundPlusPurchasePrice" 
                      caption="発注金額" 
                      v-model="row.price"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-span-full mt-3">
              <OrderItemSelect
                :name="p + 'CommissionFlag'"
                caption="手数料有無"
                v-model="o.commissionFlag"
                :item="selectItemCommissionFlag"
                :disabled="true"
              />
            </div>

            <div class="sm:col-span-full mt-3" v-if="o.commissionFlag && o.commissionFlag.length && o.commissionFlag[0] == '1'">
              <WmsTextInput 
                :name="p + 'CommissionPrice'" 
                caption="手数料金額" 
                v-model="o.commissionPrice"
                :disabled="true"
              />
            </div>

            <div class="sm:col-span-full mt-3" o.memo>
              <WmsTextInput 
                :name="p + 'Memo'" 
                caption="メモ"
                :multiline="true"
                :rows="5"
                v-model="o.memo"
                :disabled="isDisabled || referenceMode"
              />
            </div>
            <div class="sm:col-span-full mt-3" v-if="i<10 && o.file">
              <label class="block text-xs font-bold text-gray-700">
                {{o.name}} 添付資料
              </label>
              <div class="mt-1">
                <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                  <WmsAttachment 
                    :name="p + 'CommonFile'" 
                    caption="添付資料"
                    :value="order[`${o.file}${i+1}`]" 
                    style="max-width: none!important;"
                    @change="attachmentChange(`${o.file}${i+1}`, $event)"
                    :disabled="isDisabled || referenceMode"
                    :readonly="isDisabled || referenceMode"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- 以下の添付はカテゴリ1つにつき添付1つ -->

        <div v-for="ps in propertySet" :key="ps.code">
          <div class="mt-3 mx-5 mb-3 sm:col-span-full" v-if="ps.file && p == ps.val">
            <label class="block text-xs font-bold text-gray-700">
              {{ps.name}} 添付資料
            </label>
            <div class="mt-1">
              <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                <WmsAttachment 
                  :name="ps.file" 
                  caption="添付資料"
                  :value="order[ps.file]" 
                  style="max-width: none!important;"
                  @change="attachmentChange(ps.file, $event)"
                  :disabled="isDisabled || referenceMode"
                  :readonly="isDisabled || referenceMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sm:col-span-full">
      <div class="flex">
        <div class="text-xs my-auto font-bold text-gray-700">配信用テンプレート</div>
        <PrimaryButton text="テンプレート更新" v-tooltip="'受注の内容をテンプレートに反映します'" size="xs" class="ml-3" @click="setTemplateHTL()" :disabled="isDisabled || referenceMode"></PrimaryButton>
      </div>
      <OrderRichTextInput
        name="template" 
        :value="templateHTML"
        v-model="templateHTML"
        @change="templateChange($event)"
        :disabled="isDisabled || referenceMode"
      >
      </OrderRichTextInput>
    </div>
    <!-- 作業内容コード選択モーダル -->
    <SelectWorkCode
      v-model="workCodeModalShow"
      :target="workCode.target"
      :open="workCodeModalShow"
      :allList="workCodeList"
      @selectWorkCodeClose="closeWorkCode"
    />
  </div>
</template>
  
<script>
  import PrimaryButton from '@components/PrimaryButton.vue'
  import Icon from '@components/Icon.vue'
  import OrderItemSelect from './OrderItemSelect.vue'
  import SelectWorkCode from './SelectWorkCode.vue'
  import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
  import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
  import * as utils from '@libs/utils'
  import * as orderManager from '@managers/orderManager'
  // import * as logManager from '@managers/logManager'
  import EstimationDetailsCategorySelect from './EstimationDetailsCategorySelect.vue'
  import InputGroupSubLabel from './InputGroupSubLabel.vue'
  import OrderRichTextInput from './OrderRichTextInput.vue'
  import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
  import InputIcon from '@components/InputIcon.vue'
  import * as constants from '@libs/constants'
  // import * as helper from '@libs/helper'
  import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
  import * as dialogs from '@libs/dialogs'
  import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
  import * as moment from 'moment'
  import * as estimateManager from '@managers/estimateManager'

  const thisYear = (new Date()).getFullYear() + '年'

  // 物件情報に物件番号が必要な得意先（新規のみ）
  const siteCodeForNewSite = ['東急', 'アートアベニュー', '三菱', 'セキスイ', 'ナイス', '長谷工', '大和地所', '大京']
  // 物件情報にチームが必要な得意先
  const teamForNewSite = ['東急']
  
  // 見積カテゴリのプロパティ
  const PROP = [
    // ラウンドサービス
    'orderRls',
    // ラウンドプラス
    'orderRlsPlus',
    // 日常清掃
    'orderEverydays',
    // 管理
    'orderManagerWorks',
    // 定期
    'orderRegular',
    // フロントサポート
    'orderFront',
    // フロントサポートプラス
    'orderFrontPlus',
    // ランドトラッシュ
    'orderRlsTrash',
    // クオリティーレポート
    'orderQuality',
    // ガラス清掃
    'orderGlass',
    // 排水管清掃
    'orderDrain',
    // 貯水槽清掃
    'orderWaterTank',
    // 植栽剪定
    'orderPlanting',
    // 特別清掃
    'orderSpecialCleans',
    // 消防設備点検
    'orderFire',
    // 建築設備定期検査
    'orderConstruction',
    // 特定建築物定期調査
    'orderSpConstruction',
    // 共用部設備点検
    'orderCommon',
    // エレベータ保守点検
    'orderEv',
    // 増圧ポンプ点検
    'orderPress',
    // 給排水ポンプ点検
    'orderWaterSupply',
    // 簡易専用水道
    'orderSimpleWater',
    // 管球対応
    'orderTube',
    // 粗大ごみ
    'orderBulkyTrash',
    // 物品販売
    'orderSale',
    // 緊急対応
    'orderEmergency',
    // 防火対象物点検
    'orderFireObj',
    // 汚水槽清掃
    'orderSewage',
    // 防火設備定期検査
    'orderFireFixed',
    // 連結送水管耐圧性能点検
    'orderConnented',
    // スポット清掃
    'orderSpot',
    // MRS
    'orderMRS',
    // カテゴリーなし
    'orderOthers'
  ]
  
  export default {
    components: {
      PrimaryButton,
      Icon,
      OrderItemSelect,
      SelectWorkCode,
      WmsDateInput,
      WmsTextInput,
      EstimationDetailsCategorySelect,
      InputGroupSubLabel,
      OrderRichTextInput,
      WmsSuggestInput,
      InputIcon,
      WmsAttachment,
      WmsTimeInput2
    },

    props: {
      estimateData: {
        type: Object,
        default: () => {}
      },
      orderData: {
        type: Object,
        default: () => {}
      },
      mainData: {
        type: Object,
        default: () => {}
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isTaskRequestMode: {
        type: Boolean,
        default: false
      },
      referenceMode: {
        type: Boolean,
        default: false
      },
      position: {
        type: String,
        default: ''
      }
    },
  
    data() {
      return {
        order: orderManager.createNewOrderModel(),
        originalOrder: {},
        isSaving: false,
        initialized: false,
        saved: false,
        detailSorting: false,
        estimate: {},
        selectItemWeek: [],
        selectItemMonth: [],
        selectItemMonth2: [],
        templateHTML: '',
        dispContractDate: '',
        dispContractEndDate: '',
        selectItemYear: [],
        estimationRoundPlusCategorySelectItem:[],
        selectItemCarpet: [],
        regularCleanTargetAreaSelectItem: [],
        regularCleanDescriptionWorkSelectItem: [],
        workItemList: [],
        selectItemClassification: [],
        selectItemCommissionFlag: [],
        hourItems: [],
        minuteItems: [],
        // 見積りカテゴリーのプロパティー
        property: PROP,
        // カテゴリーコード、カテゴリー名、プロパティー名、ファイル名のセット
        propertySet: constants.PROPSET,
        // 作業内容コードリスト
        workCodeList: [],
        // 作業内容モーダル表示
        workCodeModalShow: false,
        // 編集対象の作業内容
        workCode: {},
        // 区分
        // classification: ''
      }
    },

    mounted() {
      this.$nextTick(async () => {
        // サブ一覧から遷移した場合は、一番下にスクロール
        if (this.position && this.position == 'template') {
          await utils.wait(900)
          const el = document.getElementById('el');
          el.scrollIntoView(false)
        }
      })
    },

    watch: {
      /**
       * 区分
       */
      // classification: function (newVal, oldVal) {
      //   // 違う区分から新規物件になった場合
      //   if (newVal == '1' && oldVal != '1') {
      //     this.updateBuildingContents()
      //   // 新規物件から違う区分になった場合
      //   } else if (newVal != '1' && oldVal == '1') {
      //     this.updateBuildingContents()
      //   }
      // }
    },
  
    computed: {
      isModileDevice() {
        return utils.deviceInfo.isMobile
      },

      /**
       * 契約日バリデート
       */
      validateContractDate() {
        if (!this.order.contractDate) {
          return{
            result: false,
            message: '契約日は必須です。'
          }  
        } else {
          return {
            result: true,
            message: ''
          }
        }
      },

      /**
       * 契約終了日バリデート
       */
      validateContractEndDate() {
        if (this.order.contractEndDate != '' && this.order.contractDate != '' && this.order.contractDate > this.order.contractEndDate) {
          return{
            result: false,
            message: '契約日、契約終了日が不正です。'
          }
        } else if (!this.order.contractEndDate) {
          return{
            result: false,
            message: '契約終了日は必須です。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      },

      validateClassification() {
        if (!this.order.classification || this.order.classification.length == 0 || this.order.classification[0] == '2') {
          return {
            result: false,
            message: '区分は必須入力です。区分を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      },
    },
  
    async created() {
      await this.setOrder()
    },
  
    methods: {
      async setOrder() {
        this.selectItemWeek = [
          { text: '月', value: '月' },       
          { text: '火', value: '火' },
          { text: '水', value: '水' },
          { text: '木', value: '木' },
          { text: '金', value: '金' },
          { text: '土', value: '土' },
          { text: '日', value: '日' }
        ]

        this.selectItemMonth = [
          { text: '1月', value: '1月' },       
          { text: '2月', value: '2月' },
          { text: '3月', value: '3月' },
          { text: '4月', value: '4月' },
          { text: '5月', value: '5月' },
          { text: '6月', value: '6月' },
          { text: '7月', value: '7月' },
          { text: '8月', value: '8月' },
          { text: '9月', value: '9月' },
          { text: '10月', value: '10月' },
          { text: '11月', value: '11月' },
          { text: '12月', value: '12月' },
        ]

        this.selectItemMonth2 = [
          { text: '奇数月', value: '奇数月' },
          { text: '偶数月', value: '偶数月' }
        ]

        this.selectCleanCountItemMonth = [
          { value: '月 1 回', text: '月 1 回' },
          { value: '月 2 回', text: '月 2 回' },
          { value: '週 1 回', text: '週 1 回' },
          { value: '週 2 回', text: '週 2 回' },
          { value: '週 3 回', text: '週 3 回' },
          { value: '週 3.5 回', text: '週 3.5 回' },
          { value: '週 4 回', text: '週 4 回' },
          { value: '週 4.5 回', text: '週 4.5 回' },
          { value: '週 5 回', text: '週 5 回' },
          { value: '週 6 回', text: '週 6 回' }
        ]

        this.selectCleanCountItemMonthMRS = [
          { value: '週 3 回', text: '週 3 回' },
          { value: '週 3.5 回', text: '週 3.5 回' },
          { value: '週 4 回', text: '週 4 回' },
          { value: '週 4.5 回', text: '週 4.5 回' },
          { value: '週 5 回', text: '週 5 回' },
          { value: '週 5.5 回', text: '週 5.5 回' },
          { value: '週 6 回', text: '週 6 回' },
          { value: '週 6.5 回', text: '週 6.5 回' },
          { value: '週 7 回', text: '週 7 回' },
        ]

        this.selectEstimationEverydayCountItemMonth = [
          { value: '週 1 回', text: '週 1 回' },
          { value: '週 2 回', text: '週 2 回' },
          { value: '週 3 回', text: '週 3 回' },
          { value: '週 4 回', text: '週 4 回' },
          { value: '週 5 回', text: '週 5 回' },
          { value: '週 6 回', text: '週 6 回' },
          { value: '週 7 回', text: '週 7 回' },
          { value: '週 1.5 回', text: '週 1.5 回' },
          { value: '週 2.5 回', text: '週 2.5 回' },
          { value: '週 3.5 回', text: '週 3.5 回' },
          { value: '週 4.5 回', text: '週 4.5 回' },
          { value: '週 5.5 回', text: '週 5.5 回' },
          { value: '週 6.5 回', text: '週 6.5 回' },
        ]

        this.selectItemCarpet = [
          { value: 'カーペット', text: 'カーペット' }
        ]

        this.regularCleanTargetAreaSelectItem = [
          { value: 'エントランス', text: 'エントランス' },
          { value: 'ロビー', text: 'ロビー' },
          { value: '廊下', text: '廊下' },
          { value: '階段', text: '階段' },
          { value: '管理室', text: '管理室' },
        ]

        this.regularCleanDescriptionWorkSelectItem = [
          { value: '洗浄のみ', text: '洗浄のみ' },
          { value: 'ワックス', text: 'ワックス' },
          { value: 'カーペット', text: 'カーペット' }
        ]

        this.workItemList = constants.SPECIALCLEAN_WORK_ITEMS

        // 区分
        // 見積もりの区分とは意味合いが違うため、変更 2023/08/02
        // 「追加変更」を「追加」と「変更」に分ける
        this.selectItemClassification = [
          { value: '1', text: '新規物件' },
          // { value: '2', text: '追加変更' },
          { value: '4', text: '追加' },
          { value: '5', text: '変更' },
          { value: '3', text: 'スポット' },
        ]

        this.estimationRoundPlusCategorySelectItem = [
          { value: 'ラウンドクリーンプラス', text: 'ラウンドクリーンプラス' },
          { value: 'ラウンドウォッシュプラス', text: 'ラウンドウォッシュプラス' },
          { value: 'ラウンドダブルプラス', text: 'ラウンドダブルプラス' }
        ]
        
        this.selectItemCommissionFlag = [
          { value: '1', text: '有'},
          { value: '0', text: '無'}
        ]

        this.hourItems = [
          { text: '7' },
          { text: '8' },
          { text: '9' },
          { text: '10' },
          { text: '11' },
          { text: '12' },
          { text: '13' },
          { text: '14' },
          { text: '15' },
          { text: '16' },
          { text: '17' },
          { text: '18' },
          { text: '19' },
          { text: '20' },
          { text: '21' },
          { text: '22' },
          { text: '23' }
        ]

        this.minuteItems = [
          { text: '00' },
          { text: '15' },
          { text: '30' },
          { text: '45' }
        ]

        // 作業内容コードリスト取得
        await this.getWorkCode()

        // 初回作業年の選択内容
        for (let index = 0; index < 5; index++) {
          let selectYear = (new Date()).getFullYear() + index + '年'
          this.selectItemYear.push({ text: selectYear })
        }

        // 編集の場合
        if (Object.keys(this.orderData).length) {
          this.order = this.orderData
          this.templateHTML = this.orderData.template

          if (this.isTaskRequestMode || this.referenceMode) {
            // サブタスク依頼、参照モードの場合は契約日、契約終了日を年月日形式に変換
            this.dispContractDate = utils.formatDateJa(this.orderData.contractDate)
            this.dispContractEndDate = utils.formatDateJa(this.orderData.contractEndDate)
          }

        } else {
          // 新規登録
          this.order = this.createNewOrder()
          this.order.estimateNo = this.estimateData.estimateNo
          this.order.caseName = this.estimateData.buildingName + ' ' + '受注処理'
          // 区分は「スポット」のみ反映
          if (this.estimateData.classification == 3) {
            this.order.classification = [this.estimateData.classification]
            // 区分によって契約終了日の初期値を入れる
            this.setContractEndDate()
          }

          for (let index = 0; index < this.estimateData.details.length; index++) {
            const estimateD = this.estimateData.details[index]
            this.order.estimationDetailsCategory.push(estimateD.categoryName)

            this.setCategory(estimateD)

            let res = []

            // 防火対象物点検
            if ((estimateD.categoryCode == '27' || estimateD.categoryName.indexOf('防火対象物') != -1) && estimateD.rowspan && estimateD.rowspan > 0) {
              res = this.setObjectEstimateValue('防火対象物点検', estimateD, true)
              res.categoryCode = '27'
              this.order.orderFireObj.push(res)
            }

            // 汚水槽
            else if (estimateD.categoryName.indexOf('汚水槽') != -1 && estimateD.rowspan && estimateD.rowspan > 0) {
              res = this.setObjectEstimateValue('汚水槽清掃', estimateD, true)
              res.categoryCode = '101'
              this.order.orderSewage.push(res)
            }

            // 防火設備
            else if (estimateD.categoryName.indexOf('防火設備') != -1 && estimateD.rowspan && estimateD.rowspan > 0) {
              res = this.setObjectEstimateValue('防火設備定期検査', estimateD, true)
              res.categoryCode = '102'
              this.order.orderFireFixed.push(res)
            }

            // 連結送水管
            else if (estimateD.categoryName.indexOf('連結送水管') != -1 && estimateD.rowspan && estimateD.rowspan > 0) {
              res = this.setObjectEstimateValue('連結送水管耐圧性能点検', estimateD, true)
              res.categoryCode = '103'
              this.order.orderConnented.push(res)
            }

            // ラウンドサービス
            else if (estimateD.categoryCode == '1') {
              
              if (estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('ラウンドサービス', estimateD, false)
                res.count2 = []

                // 清掃回数
                if(Object.keys(estimateD.subItemModalData).length && estimateD.subItemModalData.toolTip) {
                  res.count2 = [estimateD.subItemModalData.toolTip.清掃回数]
                }
                this.order.orderRls.push(res)
              }
            }

            // ラウンドプラス
            else if (estimateD.categoryCode == '2') {
              if (Object.keys(estimateD.subItemModalData).length) {
                this.order.estimationDetailsCategory.push('ラウンドプラス')

                res = this.setObjectEstimateValue('ラウンドプラス', estimateD, false)
                // 清掃回数詳細
                res.count2 = estimateD.subItemModalData.toolTip.清掃回数_RLS.split(',')
                // 作業曜日・時間帯
                res.remarks = this.setRemarksVal(estimateD.subItemModalData)
                // 種別
                if (estimateD.categoryName.indexOf('クリーンプラス') > -1) {
                  res.estimationRoundPlusCategorySelect = ['ラウンドクリーンプラス']
                } else if (estimateD.categoryName.indexOf('ウォッシュプラス') > -1) {
                  res.estimationRoundPlusCategorySelect = ['ラウンドウォッシュプラス']
                } else if (estimateD.categoryName.indexOf('ダブルプラス') > -1) {
                  res.estimationRoundPlusCategorySelect = ['ラウンドダブルプラス']
                }
                // 発注データは配列
                res.purchase = []
                // 不要なものを削除
                delete res.supplier
                delete res.orderPrice
                delete res.workDays
                this.order.orderRlsPlus.push(res)
              }
            }

            // 日常清掃
            else if (estimateD.categoryCode == '3') {
              if(Object.keys(estimateD.subItemModalData).length) {
                res = this.setObjectEstimateValue('日常清掃', estimateD, false)
                res.details = this.getTabDetail(estimateD)
                // 不要なデータを削除
                delete res.dispCount
                delete res.startDate
                delete res.workDays
                this.order.orderEverydays.push(res)
              }
            }

            // 管理員業務
            else if (estimateD.categoryCode == '4') {
              if(Object.keys(estimateD.subItemModalData).length) {
                res = this.setObjectEstimateValue('管理員業務', estimateD, false)
                res.details = this.getTabDetail(estimateD)
                // 不要なデータを削除
                delete res.dispCount
                delete res.startDate
                delete res.workDays
                this.order.orderManagerWorks.push(res)
              }
            }

            // 定期清掃
            else if (estimateD.categoryCode == '5') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('定期清掃', estimateD, false)

                res.details = []
                // let content = []
                // let txt = []
                // let count = 0

                for (let j = 0; j < this.estimateData.details.length; j++) {
                  const detail = this.estimateData.details[j]

                  // 定期清掃年額の行以外
                  if (detail.categoryCode == '5' && detail.groupName == estimateD.groupName && detail.fixedRowspan != -1 && detail.type != 'fixedCalc') {
                    res.details.push(this.getTabDetail2(detail))
                  }

                  // 定期清掃年額の行以外でタブごとの内容を取得
                  // if (detail.categoryCode == '5' && detail.groupName == estimateD.groupName && detail.type != 'fixedCalc') {
                  //   if (detail.fixedRowspan > 0) {
                  //     count++
                  //     txt = []
                  //     if (detail.itemName) {
                  //       txt.push(detail.itemName)
                  //     }
                      
                  //     if (txt.length && count > 1) {
                  //       content.push(txt.join('・'))
                  //     } else if (count == 1 && this.estimateData.details[j+1] && this.estimateData.details[j+1].fixedRowspan != -1) {
                  //       content.push(txt.join('・'))
                  //     }
                      
                  //   } else {
                  //     if (detail.itemName) {
                  //       txt.push(detail.itemName)
                  //     }
                  //   }
                  // }
                }
                // content.push(txt.join('・'))

                // 内容と詳細を結合
                for (let k = 0; k < res.details.length; k++) {
                  const fixedD = res.details[k]
                  if (res.details.length > 1) {
                    fixedD.content = k + 1
                  } else {
                    fixedD.content = ''
                  }
                }
                this.order.orderRegular.push(res)
              }
            }

            // フロントサポート
            else if (estimateD.categoryCode == '6') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {  
                res = this.setObjectEstimateValue('フロントサポート', estimateD, true)
                res.count2 = []

                // 清掃回数
                if(Object.keys(estimateD.subItemModalData).length && estimateD.subItemModalData.toolTip) {
                  res.count2 = [estimateD.subItemModalData.toolTip.作業回数]
                }
                res.startDate = ''
                // 不要なデータを削除
                delete res.implementationMonth
                this.order.orderFront.push(res)
              }
            }

            // フロントサポートプラス
            else if (estimateD.categoryCode == '7') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('フロントサポートプラス', estimateD, true)
                res.count2 = []

                // 清掃回数
                if(Object.keys(estimateD.subItemModalData).length && estimateD.subItemModalData.toolTip) {
                  res.count2 = [estimateD.subItemModalData.toolTip.作業回数]
                }
                res.startDate = ''
                // 不要なデータを削除
                delete res.implementationMonth
                this.order.orderFrontPlus.push(res)
              }
            }

            // ラウンドトラッシュ
            else if (estimateD.categoryCode == '8') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('ラウンドトラッシュ', estimateD, true)
                res.count2 = []

                // 清掃回数
                if(Object.keys(estimateD.subItemModalData).length && estimateD.subItemModalData.toolTip) {
                  res.count2 = [estimateD.subItemModalData.toolTip.作業回数]
                }
                res.startDate = ''
                // 不要なデータを削除
                delete res.implementationMonth
                this.order.orderRlsTrash.push(res)
              }
            }

            // クオリティレポート
            else if (estimateD.categoryCode == '9') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('クオリティレポート', estimateD, true)
                // 不要なデータを削除
                delete res.implementationMonth
                this.order.orderQuality.push(res)
              }
            }

            // ガラス清掃
            else if (estimateD.categoryCode == '10') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('ガラス清掃', estimateD, true)
                this.order.orderGlass.push(res)
              }
            }

            // 排水管清掃
            else if (estimateD.categoryCode == '11') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('排水管清掃', estimateD, true)
                this.order.orderDrain.push(res)
              }
            }

            // 貯水槽清掃
            else if (estimateD.categoryCode == '12') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('貯水槽清掃', estimateD, true)
                this.order.orderWaterTank.push(res)
              }
            }

            // 植栽剪定
            else if (estimateD.categoryCode == '13') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('植栽剪定', estimateD, true)
                this.order.orderPlanting.push(res)
              }
            }

            // 特別清掃
            else if (estimateD.categoryCode == '14') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('特別清掃', estimateD, false)

                res.details = []

                let newData = estimateD.newData
                for (let j = 0; j < this.estimateData.details.length; j++) {
                  const detail = this.estimateData.details[j]

                  if (detail.categoryCode == '14' && detail.groupName == estimateD.groupName) {
                    res.details.push(this.getTabDetail3(detail, newData))
                  }
                }
                // 仕様変更後の特別清掃（見積りの単価1が結合）
                if (newData) {
                  res.unitPrice = estimateD.categoryUnitPrice
                  res.amount = estimateD.categoryAmount
                  res.newData = newData
                  res.count = estimateD.categoryQty
                  res.unit = estimateD.categoryUnitName
                  res.dispCount = estimateD.categoryQty + estimateD.categoryUnitName
                  res.workCode = this.getInitWorkCode(estimateD.itemName, '14')
                }
                this.order.orderSpecialCleans.push(res)
              }
            }

            // 消防設備点検
            else if (estimateD.categoryCode == '15') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('消防設備点検', estimateD, true)
                this.order.orderFire.push(res)
              }
            }

            // 建築設備定期検査
            else if (estimateD.categoryCode == '16') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('建築設備定期検査', estimateD, true)
                this.order.orderConstruction.push(res)
              }
            }

            // 特定建築物定期調査
            else if (estimateD.categoryCode == '17') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('特定建築物定期調査', estimateD, true)
                this.order.orderSpConstruction.push(res)
              }
            }

            // 共用部設備点検
            else if (estimateD.categoryCode == '18') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('共用部設備点検', estimateD, true)
                this.order.orderCommon.push(res)
              }
            }

            // エレベーター保守点検
            else if (estimateD.categoryCode == '19') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('エレベーター保守点検', estimateD, true)
                this.order.orderEv.push(res)
              }
            }

            // 増圧ポンプ点検
            else if (estimateD.categoryCode == '20') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('増圧ポンプ点検', estimateD, true)
                this.order.orderPress.push(res)
              }
            }

            // 給排水ポンプ点検
            else if (estimateD.categoryCode == '21') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('給排水ポンプ点検', estimateD, true)
                this.order.orderWaterSupply.push(res)
              }
            }

            // 簡易専用水道
            else if (estimateD.categoryCode == '22') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('簡易専用水道', estimateD, true)
                this.order.orderSimpleWater.push(res)
              }
            }

            // 管球対応
            else if (estimateD.categoryCode == '23') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('管球対応', estimateD, true)
                this.order.orderTube.push(res)
              }
            }

            // 粗大ごみ対応
            else if (estimateD.categoryCode == '24') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('粗大ごみ対応', estimateD, true)
                this.order.orderBulkyTrash.push(res)
              }
            }

            // 物品販売
            else if (estimateD.categoryCode == '25') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('物品販売', estimateD, true)
                this.order.orderSale.push(res)
              }
            }

            // 緊急対応
            else if (estimateD.categoryCode == '26') {
              if (estimateD.rowspan && estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('緊急対応', estimateD, true)
                this.order.orderEmergency.push(res)
              }
            }

            // スポット清掃
            else if (estimateD.categoryCode == '28') {
              if (estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue(estimateD.categoryName, estimateD, true)
                res.area = ''
                res.workDetail = ''
                // res.carpet = []
                res.file = 'spotCommonFile'

                this.order.orderSpot.push(res)
              }
            }

            // MRS
            else if (estimateD.categoryCode == '29') {
              if (estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue('マンションローテーションサービス（MRS）', estimateD, false)
                res.count2 = []

                // 清掃回数
                if(Object.keys(estimateD.subItemModalData).length && estimateD.subItemModalData.selectedValue) {
                  res.count2 = ['週 ' + estimateD.subItemModalData.selectedValue[1] + ' 回']
                }
                this.order.orderMRS.push(res)
              }
            }

            // カテゴリコードがないもの
            else {
              if (estimateD.rowspan > 0) {
                res = this.setObjectEstimateValue(estimateD.categoryName, estimateD, true)
                res.file = 'otherCommonFile'
                res.categoryCode = 'none'
                this.order.orderOthers.push(res)
              }
            }
          }

          // 発注
          if (this.estimateData.purchase && this.estimateData.purchase.length) {
            for (let index = 0; index < this.estimateData.purchase.length; index++) {
              const purchase = this.estimateData.purchase[index]
              // 見積りにはないカテゴリーを先に取得
              // 防火対象物点検（途中から見積りにも追加）
              if (purchase.categoryCode == '27' || purchase.categoryName.indexOf('防火対象物') != -1) {
                this.setPurchaseValue(purchase, 'orderFireObj')
              }

              // 汚水槽
              else if (purchase.categoryName.indexOf('汚水槽') != -1) {
                this.setPurchaseValue(purchase,  'orderSewage')
              }

              // 防火設備
              else if (purchase.categoryName.indexOf('防火設備') != -1) {
                this.setPurchaseValue(purchase, 'orderFireFixed')
              }

              // 連結送水管
              else if (purchase.categoryName.indexOf('連結送水管') != -1) {
                this.setPurchaseValue(purchase, 'orderConnented')
              }

              // 以下は見積りに元からあるカテゴリー
              else if (!isNaN(purchase.categoryCode) && Number(purchase.categoryCode) >= 1 && Number(purchase.categoryCode) <= 29) {
                for (let j = 0; j < this.propertySet.length; j++) {
                  const p = this.propertySet[j]
                  if (p.code == purchase.categoryCode) {
                    this.setPurchaseValue(purchase, p.val)
                  }
                }
              }

              // カテゴリコードがないもの、もしくは例外コード
              else {
                this.setPurchaseValue(purchase, 'orderOthers')
              }
            }
          }

          // 手数料
          if (this.estimateData.commission && this.estimateData.commission.length) {
            for (let index = 0; index < this.estimateData.commission.length; index++) {
              const commission = this.estimateData.commission[index]
              // 防火対象物点検
              if (commission.categoryCode == '27' || commission.categoryName.indexOf('防火対象物') != -1) {
                this.setCommission(commission, 'orderFireObj')
              }

              // 汚水槽
              else if (commission.categoryName.indexOf('汚水槽') != -1) {
                this.setCommission(commission, 'orderSewage')
              }

              // 防火設備
              else if (commission.categoryName.indexOf('防火設備') != -1) {
                this.setCommission(commission, 'orderFireFixed')
              }

              // 連結送水管
              else if (commission.categoryName.indexOf('連結送水管') != -1) {
                this.setCommission(commission, 'orderConnented')
              }

              // 以下は元から見積りにあるカテゴリー
              else if (!isNaN(commission.categoryCode) && Number(commission.categoryCode) >= 1 && Number(commission.categoryCode) <= 29) {
                for (let j = 0; j < this.propertySet.length; j++) {
                  const p = this.propertySet[j]
                  if (p.code == commission.categoryCode) {
                    this.setCommission(commission, p.val)
                  }
                }
              }

              // カテゴリコードがないもの、もしくは例外コード
              else {
                this.setCommission(commission, 'orderOthers')
              }
            }
          }
        }

        // 新規登録の場合
        if (!this.orderData || !Object.keys(this.orderData).length) {
          // 物件情報に「物件名、住所、郵便番号、階数、戸数」が表示されるよう生成
          const info = this.createBuildingContents()
          if (info) {
            this.order.buildingName = info
          }

          // 登録のない得意先（得意先コードがない）場合にアラート
          if (!this.estimateData.clientCode) {
            const dialogsRes = await dialogs.showConfirmDialog('登録のない得意先ですか？', '新規の得意先の場合は『はい』を押してください。\nメモ欄に得意先の情報を表示します。')
            if (dialogsRes == 'YES') {
              // メモ欄に反映
              this.clientContents(this.mainData)
            }
          }
        }

        // 受注仮登録、受注処理、編集の場合は親に受注データと入力チェックの結果を返却
        this.orderDataEmit()
      },

      /**
       * 物件情報 生成
       */
      createBuildingContents() {
        // 区分がある場合のみ
        // if (!this.order.classification.length) {
        //   return ''
        // }
        const res = []
        const buildingName = '物件名：' + this.estimateData.buildingName
        const code = '物件番号：'
        const team = 'チーム：'
        const kana = 'フリガナ：'
        let postalCode = '郵便番号：---'
        const address = '住所：' + this.estimateData.buildingAddress2
        let other = ''

        if (this.estimateData.buildingPostalCode && this.estimateData.buildingPostalCode != '') {
          postalCode = '郵便番号：' + this.estimateData.buildingPostalCode
        }
        if (this.estimateData.buildingInfo.stairs && this.estimateData.buildingInfo.stairs != '0') {
          other = '階数：' +  this.estimateData.buildingInfo.stairs
        }
        if (this.estimateData.buildingInfo.basement && this.estimateData.buildingInfo.basement != '0') {
          other += '　地下：' + this.estimateData.buildingInfo.basement
        }
        if (this.estimateData.buildingInfo.households && this.estimateData.buildingInfo.households != '0') {
          other += '　戸数：' + this.estimateData.buildingInfo.households
        }

        // 新規の場合
      // if (this.order.classification[0] == '1') {
        // 対象の得意先の場合、物件番号を入れる
        if (utils.includesTxt(this.mainData.clientName, siteCodeForNewSite)) {
          res.push(code)
        }
        // 既存に無い得意先で、対象の得意先の場合、チームを入れる
        if (!this.estimateData.clientCode && this.mainData.clientName.indexOf('東急住宅') == -1 && utils.includesTxt(this.mainData.clientName, teamForNewSite)) {
          res.push(team)
        }
      // }

        res.push(buildingName)

        // 新規の場合
        // if (this.order.classification[0] == '1') {
        // カナを入れる
        res.push(kana)
        // }

        res.push(postalCode)
        res.push(address)
        res.push(other)
        return res.join('\n')
      },

      /**
       * 更新の際の物件情報更新
       */
      updateBuildingContents() {
        // 更新の場合
        if (this.orderData && Object.keys(this.orderData).length) {
          // 区分が選択されている場合
          if (this.order.classification.length) {
            // 既存の物件情報が何もない場合
            if (!this.order.buildingName) {
              this.order.buildingName = this.createBuildingContents()
            }
            const newInfo = this.createBuildingContents()
            // 現在の物件情報を1行ごとに配列
            const oldRow = this.order.buildingName.split('\n')

            // 新規物件の変更した場合
            if (this.classification == '1') {
              // 物件番号が必要な場合
              if (newInfo.indexOf('物件番号') > -1) {
                // 既存に物件番号がない場合
                if (this.order.buildingName.indexOf('物件番号') == -1) {
                  // 物件番号を追加
                  this.order.buildingName = '物件番号：\n' + this.order.buildingName
                }
              }

              // チームが必要な場合
              if (newInfo.indexOf('チーム') > -1) {
                // 既存にチームがない場合
                if (this.order.buildingName.indexOf('チーム') == -1) {
                  // 物件名の前に追加
                  const str1 = this.order.buildingName.slice(0, this.order.buildingName.indexOf('物件名'))
                  const str2 = this.order.buildingName.slice(this.order.buildingName.indexOf('物件名'))
                  this.order.buildingName = str1 + 'チーム：\n' + str2
                }
              }

              // 既存にカナがない場合
              if (this.order.buildingName.indexOf('フリガナ') == -1) {
                // 郵便番号の前に追加
                  const str1 = this.order.buildingName.slice(0, this.order.buildingName.indexOf('郵便番号'))
                  const str2 = this.order.buildingName.slice(this.order.buildingName.indexOf('郵便番号'))
                  this.order.buildingName = str1 + 'フリガナ：\n' + str2
              }
            // 新規物件以外の場合、不要な項目を削除
            } else {
              let info = []
              for (let i = 0; i < oldRow.length; i++) {
                const row = oldRow[i]
                if ((row.indexOf('物件番号：') == -1 && row.indexOf('物件番号:') == -1) && (row.indexOf('チーム：') == -1 && row.indexOf('チーム:') == -1) && (row.indexOf('フリガナ：') == -1 && row.indexOf('フリガナ:') == -1)) {
                  info.push(row)
                }
              }
              this.order.buildingName = info.join('\n')
            }
          }
        }
      },

      /**
       * 新規得意先のための情報
       */
      clientContents(main) {
        // 新規得意先フラグ
        this.order.newClient = true

        let client = []
        if (main.clientName.indexOf('　') > -1) {
          client = main.clientName.split('　')
        } else {
          client = main.clientName.split(' ')
        }
        let name = main.clientName
        if (client && client.length && client[0]) {
          name = client[0]
        }
        let branch = ''
        if (client && client.length && client[1]) {
          branch = client[1]
        }

        let res = 
          '--得意先情報--\n'
          + '社名：' + name + '\n'
          + 'フリガナ：\n'
          + '支店：' + branch + '\n'
          + '郵便番号：' + this.estimateData.clientPostalCode + '\n'
          + '住所：' + this.estimateData.clientAddress1 + '\n'
          + 'TEL：' + this.estimateData.clientPhoneNumber + '\n'
          + 'FAX：' + this.estimateData.clientFax + '\n'
          + '--------------'

        this.order.orderMemo = res
      },

      deleteClientContents() {
        // 新規得意先フラグ
        this.order.newClient = false

        if (this.order.orderMemo && this.order.orderMemo.indexOf('--得意先情報') > -1) {
          let memo = this.order.orderMemo
          let str1 = memo.slice(0, memo.indexOf('--得意先情報'))
          let str2 = memo.slice(memo.indexOf('--------------') + 14)
          this.order.orderMemo = str1 + str2
        }
      },

      /**
       * 見積明細カテゴリーセット
       */
      setCategory(estimateD) {
        let code = estimateD.categoryCode
        if (estimateD.categoryName.indexOf('汚水槽') > -1) {
          code = '101'
        } else if (estimateD.categoryName.indexOf('防火設備') > -1) {
          code = '102'
        } else if (estimateD.categoryName.indexOf('連結送水管') > -1) {
          code = '103'
        } else if (estimateD.categoryName.indexOf('防火対象物') > -1) {
          code = '27'
        } else if (!estimateD.categoryCode || estimateD.categoryCode == '' || isNaN(estimateD.categoryCode)) {
          code = 'none'
        }
        this.order.estimationDetailsCategoryCode.push(code)

        // セルが結合しているものと判定する必要があるため、groupnameはgroupname+カテゴリコードとする
        this.order.categoryName.push({ name: estimateD.categoryName, groupName: estimateD.groupName + code})
      },

      /**
       * 基本情報を見積から取得
       * @param estimate 見積データ
       * @param firstWorkMonth 作業開始月の有無
       */
      setObjectEstimateValue(name, estimate, firstWorkMonth) {
        let res
        if ((name == '定期清掃' && estimate.categoryCode == '5') || (name == '特別清掃' && estimate.categoryCode == '14')) {
          res = {
            name,
            categoryCode: estimate.categoryCode,
            groupName: estimate.groupName,
            supplier: '',
            orderPrice: '',
            commissionFlag: [],
            commissionPrice: '---',
            memo: ''
          }
          if (name == '定期清掃') {
            res.workCode = this.getInitWorkCode(estimate.categoryName, estimate.categoryCode)
          }
          return res
        } else {
          res = {
            name,
            content: estimate.categoryName,
            categoryCode: estimate.categoryCode,
            count: estimate.categoryQty,
            unit: estimate.categoryUnitName,
            dispCount: estimate.categoryQty + estimate.categoryUnitName,
            unitPrice: estimate.categoryUnitPrice,
            amount: estimate.categoryAmount,
            groupName: estimate.groupName,
            supplier: '',
            orderPrice: '',
            commissionFlag: [],
            commissionPrice: '---',
            memo: '',
            workCode: this.getInitWorkCode(estimate.categoryName, estimate.categoryCode)
          }

          // 作業月が必要な場合
          if (firstWorkMonth) {
            let add = {
              // 初回年
              firstWorkYear: thisYear,
              // 初回月
              firstWorkMonth: [],
              // 実施月
              implementationMonth: [],
            }
            res = {...res, ...add}

          // 作業月ではない場合
          } else {
            let add = {
              // 開始日
              startDate: '',
              // 作業曜日
              workDays: []
            }
            res = {...res, ...add}
          }
        }

        return res
      },

      /**
       * 日常・管理のタブ内で生成された見積もりデータを取得
       */
      getTabDetail(estimate) {
        let res = []
        for (let i = 0; i < estimate.subItemModalData.modalTabData.length; i++) {
          const m = estimate.subItemModalData.modalTabData[i]
          // console.log(m.data)
          // 作業曜日、作業時間帯
          let remarks = this.setRemarksVal(m.data)
          
          // 詳細清掃回数
          let count2 = []
          if (m.data && m.data.selectedValue && m.data.selectedValue[7]) {
            let val = m.data.selectedValue[7]
            val = '週 ' + val + ' 回'
            count2 = [val]
          }
          res.push(
            {
              remarks,
              count2,
              startDate: '',
              id: m.id,
              content: ''
            }
          )
        }

        for (let j = 0; j < estimate.subItemModalData.display.length; j++) {
          const d = estimate.subItemModalData.display[j]
          for (let k = 0; k < res.length; k++) {
            const r = res[k]
            if (d.type.indexOf(r.id) != -1) {
              r.content = d.name
              break
            }
          }
        }
        // console.log(res)
        return res
      },

      /**
       * 定期のタブ内で生成された分、データを生成
       */
      getTabDetail2(estimate) {
        let res =
          {
            count: estimate.categoryQty,
            unit: estimate.categoryUnitName,
            dispCount: estimate.categoryQty + estimate.categoryUnitName,
            unitPrice: estimate.categoryUnitPrice,
            amount: estimate.categoryAmount,
            // 初回年
            firstWorkYear: thisYear,
            // 初回月
            firstWorkMonth: [],
            // 実施月
            implementationMonth: [],
            // 対象箇所
            area: '',
            // 作業内容
            workDetail: '',
            // カーペット
            // carpet: [],
            // type
            type: estimate.type,
            // 内容名
            content: ''
          }
        // console.log(res)
        return res
      },

      /**
       * 特別清掃のデータ生成
       */
      getTabDetail3 (estimate, newData) {
        // 該当の作業項目を取得
        let workItem = null
        for (let index = 0; index < this.workItemList.length; index++) {
          if (estimate.itemName != '') {
            if(estimate.itemName.indexOf(this.workItemList[index].value) > -1){
              workItem = this.workItemList[index].id
              break
            }
          }
        }
        let res =
          {
            // 初回年
            firstWorkYear: thisYear,
            // 初回月
            firstWorkMonth: [],
            // 実施月
            implementationMonth: [],
            // 見積もりの内容（項目名）
            content: estimate.itemName,
            // 作業項目コード
            workItem,
          }
          // 仕様変更前の特別清掃の場合（見積りの単価1が結合されていない）
          if (!newData) {
            res.unitPrice = estimate.categoryUnitPrice
            res.amount = estimate.categoryAmount
            res.count = estimate.categoryQty
            res.unit = estimate.categoryUnitName
            res.dispCount = estimate.categoryQty + estimate.categoryUnitName
            res.workCode = this.getInitWorkCode(estimate.itemName, estimate.categoryCode)
          } else {
            res.newData = newData
            res.rowspan = estimate.rowspan
          }
        // console.log(res)

        // 調整費の場合、画面には表示しないが、発注書でのrowspan調整のため残す
        if (estimate.itemName == '調整費') {
          // 不要なものを削除
          delete res.firstWorkYear
          delete res.firstWorkMonth
          delete res.implementationMonth
          delete res.workItem
        }
        return res
      },

      /**
       * 発注をセット
       * @param purchase 対象発注データ
       * @param s 発注先
       * @param p 発注金額
       */
      setPurchaseValue(purchase, tar) {
        for (let i = 0; i < this.order[tar].length; i++) {
          const o = this.order[tar][i]
          if (o.groupName == purchase.groupName) {
            // RLSプラスの場合
            if (['orderRlsPlus'].includes(tar)) {
              let res = {}
              res.typeName = ''
              if (purchase.typeName) {
                res.typeName = purchase.typeName
              }
              res.supplier = purchase.supplier
              res.price = purchase.price
              res.groupName = purchase.groupName

              o.purchase.push(res)

            // 定期・特別の場合
            } else if (['orderRegular', 'orderSpecialCleans'].includes(tar)) {
              o.supplier = purchase.supplier
              o.orderPrice = purchase.price
              // 発注率追加
              o.purchaseRate = purchase.rate

            } else {
              o.supplier = purchase.supplier
              o.orderPrice = purchase.price
            }
            break
          }
        }
      },

      /**
       * 手数料をセット
       * @param commission 手数料データ
       * @param tar 対象プロパティー
       */
      setCommission(commission, tar) {
        for (let i = 0; i < this.order[tar].length; i++) {
          const o = this.order[tar][i]
          if (o.groupName == commission.groupName) {
            this.setArrayCommissionValue(commission, o, tar)
            break
          }
        }
      },

      /**
       * 配列プロパティの手数料をセット
       * @param commission 対象手数料データ
       * @param category 対象カテゴリー
       */
      setArrayCommissionValue(commission, category, tar) {
        if (commission.commissionPrice && commission.commissionPrice != '0') {
          // 手数料金額が空か0でなければ、手数料有
          category.commissionFlag = ['1']
          // 手数料金額
          category.commissionPrice = commission.commissionPrice
        } else {
          // それ以外は手数料無
          category.commissionFlag = ['0']
          // 手数料金額
          category.commissionPrice = '0'
        }
        
        // 定期・特別の場合
        if (['orderRegular', 'orderSpecialCleans'].includes(tar)) {
          // 手数料率追加
          category.commissionRate = commission.rate
        }
      },

      storeOriginal() {
        const tempOrder = utils.clone(this.order)
        this.originalOrder = tempOrder
      },
  
      /**
       * 新規受注 生成
       */
      createNewOrder() {
        return orderManager.createNewOrderModel()
      },

      /**
       * 見積参照
       */
      referenceEstimate() {
        let resolvedRoute = this.$router.resolve({ 
          name: 'EstimateView',
          query: {
            estimateNo: this.order.estimateNo
          }
        });
        window.open(resolvedRoute.href, '_blank');
      },

      /**
       * 作業曜日、時間 削除ボタン
       * @param tar 対象データ
       * @param idx インデックス
       */
      removeWorkRemarks(tar, idx) {
        tar.splice(idx, 1)
        if (!tar || !tar.length) {
          this.addWorkRemarks(tar)
        }
      },

      /**
       * 作業曜日、時間 追加ボタン
       */
      addWorkRemarks(tar) {
        let element = {
          workDays: [],
          startTime: '',
          endTime: ''
        }
        tar.push(element)
      },

      /**
       * 作業曜日、時間をセット
       */
      setRemarksVal(data) {
          let res = []
        // 見積データにremarksがある場合
        if (data.remarks && (data.remarks.length > 0 || Object.keys(data.remarks).length > 0)) {
          let remarks = data.remarks
          if (Array.isArray(remarks)) {
            // remarksが配列の場合
            for (let index = 0; index < remarks.length; index++) {
                let r = remarks[index]
                let val = {
                  workDays: r.week,
                  startTime: r.start,
                  endTime: r.end
                }
                res.push(val)
            }
          } else {
              let val = {
                workDays: remarks.week,
                startTime: remarks.start,
                endTime: remarks.end
              }
              res.push(val)
          }
        } else {
          // 見積データにremarksがない場合は、配列に初期値で1つ要素をセット
          let val = {
            workDays: [],
            startTime: '',
            endTime: ''
          }
          res.push(val)
        }
        return res
      },

      /**
       * 実施月の割り当て
       */
      workMonthAutoInput(firstWorkMonth, cleanCount, val) {
        // 清掃希望回数を文字列から数値型に変換
        let count = Number(cleanCount)
        // 初回作業月を配列から文字列に変換
        let monthStr = firstWorkMonth.join()
        // 文字列に変換した初回作業月を数字のみに変換
        const regex = /[^0-9]/g
        const result = monthStr.replace(regex, "")
        const month = parseInt(result)
        // 割り当て月間隔
        let monthlyInterval = 12 / count
        if (Number.isInteger(monthlyInterval)) {
          let resultMonth = []
          for (let index = 1; index <= count; index++) {
            let total
            total = ( month + (monthlyInterval * (index-1)) ) % 12
            if (total == 0) {
              resultMonth.push('12月')
            } else {
              resultMonth.push(String(total) + '月')
            }
            
          }
          return resultMonth
        } else {
          return val
        }
      },

      /**
       * 添付ファイル
       */
      attachmentChange(targetAttachmentName, attachmentInfo) {
        if (!this.order[targetAttachmentName]) {
          this.order[targetAttachmentName] = orderManager.createEmptyAttachment()
        }
        if (attachmentInfo) {
          this.order[targetAttachmentName].originalName = attachmentInfo.filename
          this.order[targetAttachmentName].content = attachmentInfo.content
        } else {
          this.order[targetAttachmentName] = orderManager.createEmptyAttachment()
        }
      },

      /**
       * 配信用テンプレート 変更
       */
      templateChange({html}) {
        this.templateHTML = html
        this.order.template = this.templateHTML
      },

      /**
       * 配信用テンプレート更新
       */
      setTemplateHTL() {
        let temp = []

        // 物件情報
        let siteInfo = this.createBuildingContents()
        if (siteInfo) {
          temp = siteInfo.split('\n')
        }
        // 駐車場の有無
        let parking = '駐車場：'
        if (this.estimateData.buildingInfo && this.estimateData.buildingInfo.parking) {
          if (this.estimateData.buildingInfo.parking == 'あり') {
            parking += '有'
          } else if (this.estimateData.buildingInfo.parking == 'なし') {
            parking += '無'
          }
        }
        temp.push(parking)

        // 見積りの順番で反映
        for (let i = 0; i < this.estimateData.details.length; i++) {
          const e = this.estimateData.details[i]
          if (e.rowspan > 0) {
            for (let j = 0; j < PROP.length; j++) {
              const p = PROP[j]
              if (this.order[p]) {
                for (let k = 0; k < this.order[p].length; k++) {
                  const o = this.order[p][k]
                  // 受注にデータがある場合
                  if (e.groupName == o.groupName) {
                    temp.push('')
                    // カテゴリー名
                    temp.push('★' + e.categoryName)

                    // 発注先
                    if (o.supplier) {
                      temp.push('【作業協力会社】' + o.supplier)
                    
                    // 発注先がなく、purchaseがある（RLSプラス）
                    } else if (o.purchase) {
                      temp = this.setSupplier(o.purchase, temp)
                    }

                    // 単価
                    // if (o.unitPrice) {
                    //   temp.push('【受注単価】' + utils.numberToLocale(o.unitPrice) + '円（税抜）')
                    // }

                    // ラウンドプラスの曜日
                    if (e.categoryCode == '2') {
                      temp = this.getRlsPlusWeek(o, temp)
                    }

                    // 日常・管理の曜日
                    if (['3', '4'].includes(e.categoryCode)) {
                      temp = this.getWeek(o.details, temp)
                    }

                    // detailsがあるもの
                    if (o.details) {
                      for (let l = 0; l < o.details.length; l++) {
                        const d = o.details[l]
                        // 調整費の場合は表示しない
                        if (d.content.indexOf('調整費') > -1) {
                          continue
                        }
                        if (d.content) {
                          temp.push('※' + this.deleteMonthlyFee(d.content))
                        }
                        if (o.dispCount) {
                          temp.push('【回数】' + o.dispCount)
                        }
                        temp = this.setTemplateDetail(d, temp, e.categoryCode)
                      }
                    } else {
                      temp = this.setTemplateDetail(o, temp, e.categoryCode)
                    }
                  }
                }
              }
            }
          }
        }
        if (temp && temp.length) {
          this.templateHTML = temp.join('<br>')
          this.order.template = this.templateHTML
        }
      },

      /**
       * 文言から月額を省く
       * @param name 対象文言
       */
      deleteMonthlyFee(name) {
        if (name && name.indexOf('月額') > -1) {
          let res = name.split('月額')
          if (res && res[0]) {
            return res[0].trim()
          }
        }
        return name
      },

      /**
       * 配信テンプレートの回数、初回、実施月などを生成
       * @param o 受注データ
       * @param temp テンプレート内容
       * @param categoryCode カテゴリコード
       */
      setTemplateDetail(o, temp, categoryCode) {
        // 過去仕様の特別清掃
        // if (categoryCode == '14' && o.unitPrice) {
        //   temp.push('【受注単価】' + utils.numberToLocale(o.unitPrice) + '円（税抜）')
        // }
        // 回数（RLSプラス・日常・管理以外）
        if (!['2', '3', '4'].includes(categoryCode)) {
          let count = ''
          // 清掃希望回数(週●回)があるもの
          if (o.count2 && o.count2.length) {
            count = o.count2[0]
          // なければ、普通の回数
          } else if (o.dispCount) {
            count = o.dispCount
          }
          if (count) {
            temp.push('【回数】' + count)
          }
        }

        // 初回
        let start = ''
        // 開始日がある場合
        if (o.startDate) {
          start = moment(o.startDate).format('YYYY年M月D日')
        // 初回年月がある場合
        } else if (o.firstWorkYear || o.firstWorkMonth) {
          start = o.firstWorkYear
          if (o.firstWorkMonth[0]) {
            start += o.firstWorkMonth[0]
          }
        }
        temp.push('【初回】' + start)

        // 作業曜日
        if (o.workDays) {
          // 曜日を並べ替え
          let week = this.sortItem(o.workDays, 'week')
          if (week && week.length) {
            temp.push('【作業曜日】' + week.join('・'))
          }
        // 実施月
        } else if (o.implementationMonth) {
          // 月を並べ替え
          let month = this.sortItem(o.implementationMonth, 'month')
          if (month && month.length) {
            temp.push('【実施月】' + month.join('・') + '月')
          }
        }

        return temp
      },

    /**
     * 駐車場代を取得
     * @param v 見積データ
     */
    // getParking(v) {
    //   if (!v || v == undefined || !v.details) {
    //     return
    //   }
    //   let parking = []
    //   for (let i = 0; i < v.details.length; i++) {
    //     const e = v.details[i]
    //     if (e.rowspan > 0) {
    //       // 詳細モーダルデータがあるもののみ
    //       if (Object.keys(e.subItemModalData).length) {
    //         // 貯水・消防・増圧・スポット
    //         if (['12', '15', '20', '28'].includes(e.categoryCode)) {
    //           if (e.subItemModalData.conf && e.subItemModalData.conf.parking) {
    //             // 駐車場代がある場合➡駐車場は無し
    //             if (e.subItemModalData.conf.parking && !isNaN(e.subItemModalData.conf.parking) && Number(e.subItemModalData.conf.parking) > 0) {
    //               parking.push({ groupName: e.groupName, parking: '【駐車場】無' })
    //             } else {
    //               parking.push({ groupName: e.groupName, parking: '【駐車場】有' })
    //             }
    //           }
    //         // 定期
    //         } else if (e.categoryCode == '5') {
    //           if (e.subItemModalData.modalTabData && e.subItemModalData.modalTabData) {
    //             for (let j = 0; j < e.subItemModalData.modalTabData.length; j++) {
    //               const t = e.subItemModalData.modalTabData[j]
    //               // 同じ物件なので、同じのはずだが、駐車場無しでbreakする
    //               if (t.data && t.data.conf && t.data.conf.parking && !isNaN(t.data.conf.parking) && Number(t.data.conf.parking) > 0) {
    //                 parking.push({ groupName: e.groupName, parking: '【駐車場】無' })
    //                 break
    //               } else {
    //                 parking.push({ groupName: e.groupName, parking: '【駐車場】有' })
    //               }
    //             }
    //           }
    //         // ラウンドプラス（定期がある場合のみ）
    //         } else if (e.categoryCode == '2') {
    //           if (e.subItemModalData.tabId && e.subItemModalData.tabId != 'clean') {
    //             if (e.subItemModalData.conf && e.subItemModalData.conf.parkValue && !isNaN(e.subItemModalData.conf.parkValue) && Number(e.subItemModalData.conf.parkValue) > 0) {
    //               parking.push({ groupName: e.groupName, parking: '【駐車場】無' })
    //             } else {
    //               parking.push({ groupName: e.groupName, parking: '【駐車場】有' })
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return parking
    // },

      /**
       * RLSプラス用の発注先
       * @param purchase 発注データ
       * @param temp テンプレート
       */
      setSupplier(purchase, temp) {
        let sup = '【作業協力会社】'
        for (let i = 0; i < purchase.length; i++) {
          const p = purchase[i]
          if (i != 0) {
            sup += '、'
          }
          sup += p.typeName + '：' + p.supplier
        }
        temp.push(sup.replace(/（クリーンプラス）/g, '').replace(/（ウォッシュプラス）/g, '').replace(/（ダブルプラス）/g, ''))
        return temp
      },

      /**
       * 日常・管理用の作業曜日を重複削除して取得
       * @param details 対象データ
       * @param temp テンプレート内容
       */
      getWeek(details, temp) {
        let week = []
        for (let i = 0; i < details.length; i++) {
          const d = details[i]
          for (let j = 0; j < d.remarks.length; j++) {
            const r = d.remarks[j]
            if (r.workDays) {
              for (let k = 0; k < r.workDays.length; k++) {
                const w = r.workDays[k]
                if (!week.includes(w)) {
                  week.push(w)
                }
              }
            }
          }
        }
        // 取得した曜日を並べ替え
        week = this.sortItem(week, 'week')
        if (week && week.length) {
          temp.push('【回数】週' + week.length + '回')
          temp.push('【作業曜日】' + week.join('・'))
        }
        return temp
      },

      /**
       * ラウンドプラス用の曜日取得
       */
      getRlsPlusWeek(d, temp) {
        let week = []
        for (let j = 0; j < d.remarks.length; j++) {
          const r = d.remarks[j]
          if (r.workDays) {
            for (let k = 0; k < r.workDays.length; k++) {
              const w = r.workDays[k]
              if (!week.includes(w)) {
                week.push(w)
              }
            }
          }
        }
        // 取得した曜日を並べ替え
        week = this.sortItem(week, 'week')
        if (week && week.length) {
          temp.push('【回数】週' + week.length + '回')
          temp.push('【作業曜日】' + week.join('・'))
        }
        return temp
      },

      /**
       * 曜日、もしくは月を並べ替え
       * @param arr 対象データ
       * @param type 曜日か月か
       */
      sortItem(arr, type) {
        let res = []
        let sort = constants.WEEKS
        if (type == 'month') {
          sort = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        }
        for (let i = 0; i < sort.length; i++) {
          const s = sort[i]
          for (let j = 0; j < arr.length; j++) {
            let a = arr[j]
            if (type == 'month') {
              a = a.replace('月', '')
            }
            if (a == s) {
              res.push(a)
            }
          }
        }
        return res
      },

      /**
       * データ保存時の処理
       */
      orderDataEmit() {
        this.$emit('toOrderData', this.order)
        // this.$emit('changeValidateContractDate', this.validateContractDate.result, this.validateContractDate.message)
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
        this.$emit('changeValidateClassification', this.validateClassification.result, this.validateClassification.message)

      },

      /**
       * 契約日変更イベント
       */
      changeContractDate() {
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
        // 区分によって契約終了日の初期値を入れる
        this.setContractEndDate()
      },

      /**
       * 区分によって契約終了日の初期値を入れる
       */
      setContractEndDate() {
        // スポットの場合は、1か月後
        if (this.order.classification && this.order.classification.length && this.order.classification[0] == '3') {
          this.order.contractEndDate = moment(this.order.contractDate).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD')
        } else {
          this.order.contractEndDate = moment(this.order.contractDate).add(1, 'Y').subtract(1, 'd').format('YYYY-MM-DD')
        }
        this.changeContractEndDate()
      },

      changeContractEndDate() {
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
      },

      /**
       * 初回作業月変更イベント
       * @param value 値
       * @param i インデックス
       * @param tar 対象プロパティー
       */
      changeFirstWorkMonth(value, i, tar) {
        // 単位から数字を削除
        const unit = this.order[tar][i].unit.replace(/[0-9]/g, '')
        // 単位が「回/年」（「回/2年」なども）ならば自動で作業月を判定
        if (value && value.length && unit == '回/年' && (this.order[tar][i].implementationMonth)) {
          this.order[tar][i].implementationMonth = this.workMonthAutoInput(value, this.order[tar][i].count, this.order[tar][i].implementationMonth)
        }
        this.$set(this.order[tar][i], 'firstWorkMonth', value)
      },

      /**
       * 初回作業月変更イベント（details内の初回作業月の場合）
       * @param value 値
       * @param i インデックス
       * @param tar 対象プロパティー
       */
      changeFirstWorkMonthDetails(value, i, tar, order) {
        // 単位から数字を削除
        let unit = ''
        if (tar[i].unit) {
          unit = tar[i].unit.replace(/[0-9]/g, '')
        } else if (order.unit) {
          unit = order.unit.replace(/[0-9]/g, '')
        }
        // 単位が「回/年」（「回/2年」なども）ならば自動で作業月を判定
        if (value && value.length && unit == '回/年' && tar[i].implementationMonth) {
          let count = '0'
          if (tar[i].count) {
            count = tar[i].count
          } else if (order.count) {
            count = order.count
          }
          tar[i].implementationMonth = this.workMonthAutoInput(value, count, tar[i].implementationMonth)
        }
        this.$set(tar[i], 'firstWorkMonth', value)
      },


      /**
       * 区分変更イベント
       */
      changeClassification() {
        this.$emit('changeValidateClassification', this.validateClassification.result, this.validateClassification.message)
        // 区分によって契約終了日の初期値を入れる
        this.setContractEndDate()

        if (this.order.classification.length && this.order.classification[0] != '') {
          this.classification = this.order.classification[0]
        }

        // // 物件情報生成
        // // 新規登録の場合
        // if (!this.orderData || !Object.keys(this.orderData).length) {
        //   const info = this.createBuildingContents()
        //   if (info) {
        //     this.order.buildingName = info
        //   }
        // } else {
        //   this.updateBuildingContents()
        // }
      },



      /**
       * 作業内容コードマスタデータを取得
       */
      async getWorkCode() {
        this.workCodeList = [{ name: '', details: [{ name: '', code: '' }] }]
        let result = await estimateManager.getWorkCode(this)
        if (result && result.length) {
          // コード順に並べ替え
          let sortList = []
          sortList = result.sort(function(a, b) {
            return (Number(a.code) > Number(b.code)) ? 1 : -1;
          });
          result = utils.clone(sortList)

          let category = []
          // 大項目をリスト化
          for (let i = 0; i < result.length; i++) {
            const c = result[i].category
            if (!category.includes(c)) {
              category.push(c)
            }
          }

          this.workCodeList = []
          // データの形を生成
          for (let j = 0; j < category.length; j++) {
            const ca = category[j]
            let row = {}
            row.name = ca
            for (let k = 0; k < result.length; k++) {
              const r = result[k]
              if (ca == r.category) {
                if (!row.details) {
                  row.details = []
                }
                row.details.push({ name: r.detail, code: r.code })
              }
            }
            this.workCodeList.push(row)
          }
        }
      },

      /**
       * 作業内容コード初期値取得
       */
      getInitWorkCode(val, categoryCode) {
        // MRSの場合
        if (categoryCode == '29') {
          return {
            category: 'RLS',
            detail: '巡回清掃',
            code: '0001150'
          }
        } else {
          for (let i = 0; i < this.workCodeList.length; i++) {
            let name = this.workCodeList[i].name
              if (name == 'RLS') {
                name = 'ラウンド'
              }
              if (name && val.indexOf(name) > -1) {
                if (name == 'ラウンド') {
                  name = 'RLS'
                }
                return { category: name }
              }
          }
          // 該当なしの場合は空文字
          return {}
        }
      },

      /**
       * 作業内容モーダルopen
       * @param tar 対象データ
       */
      openWorkCode(tar) {
        if (this.isDisabled || this.referenceMode) {
          return
        }
        this.workCode.target = tar.workCode
        this.workCode.original = tar
        this.workCodeModalShow = true
      },

      /**
       * 作業内容モーダルcolse
       */
      closeWorkCode(res) {
        this.workCode.original.workCode = res
        this.workCode = {}
        this.workCodeModalShow = false
      }
    }
  }
</script>
  
<style>
#button-w-sm div button[type=button] {
  width: 19.5% !important;
}

#button-w-sm2 div button[type=button] {
  width: 14% !important;
}

#button-w-sm3 div button[type=button] {
  width: 16.2% !important;
}

#sub-label h3 {
  color: rgb(9, 9, 198);
}
</style>