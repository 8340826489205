<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app" :class="isMobileDevice?'mobile-content':'pc-content'">
          <div class="flex">
            <div class="text-lg text-blue-800 font-bold">定期清掃 項目設定</div>
            <div class="text-gray-500 text-xs ml-3 my-auto" v-if="list && !list.patrolScheduleId">現在、こちらの物件が実施中でないことをご確認ください。</div>
          </div>

          <div class="ml-12">
            <div class="mt-3 flex justify-start">
              <div class="text-gray-600 font-bold mt-auto">
                {{ list.siteName }}
              </div>
              <div v-if="list.clientName1" class="text-gray-600 text-xs font-bold ml-5 my-auto">
                【{{ list.clientName1 }} {{ list.clientName2 }}】
              </div>
            </div>
          </div>

          <div class="flex mt-1">
            <!-- 並べ替え（完了した項目が1つもない場合※sortNoが変わってしまうため） -->
            <div v-if="!notEdit||!notEdit.length" class="toggle flex items-center mb-1">
              <span class="text-gray-500 font-bold text-xs mr-1">並べ替え</span>
              <Toggle v-model="sortable" size="small"/>
            </div>
            <div v-else class="text-gray-500 font-bold text-xs">
              完了済の項目があるため、並べ替え機能は使用できません。
            </div>

            <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 ml-auto mr-3 hover:text-blue-600" @click="add()"/>
          </div>

          <div class="border border-blue-500 rounded w-full py-3 overflow-auto" :class="isMobileDevice?'mobile-content-table':'pc-content-table'">
            <draggable 
              key="checkList"
              :list="checkList"
              direction="vertical"
              handle=".row-drag-handle"
              tag="table"
              class="w-full"
            >
              <div v-for="(row, i) in checkList" :key="i" class="mb-6" v-tooltip.left="isDisabled(row.sortNo)?'完了済のため編集不可':''">
                <div class="flex">
                  <!-- No -->
                  <div class="px-3 py-2 text-blue-700 font-bold">
                    <div v-if="sortable" class="row-drag-handle cursor-pointer">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else>
                      {{ i + 1 }}.
                    </div>
                  </div>

                  <!-- 毎月・偶数月・奇数月 -->
                  <div class="mr-1 mt-auto mb-1">
                    <div
                      class="py-1 w-16 rounded text-white font-bold text-center cursor-pointer"
                      :class="monthlyBtnColor(row.monthly, row.sortNo)"
                      @click="changeMonthly(row, row.sortNo)"
                    >
                      {{ row.monthly || '毎月' }}
                    </div>
                  </div>

                  <!-- 清掃箇所 -->
                  <div class="mt-auto mb-1 mr-1">
                    <WmsSuggestInput
                      name="classification"
                      placeholder="清掃箇所を入力して下さい"
                      :embed="true"
                      displayField="name"
                      :dynamic="false"
                      :incrementalSearch="false"
                      :selectionItems="classificationList"
                      :required="true"
                      :disabled="isDisabled(row.sortNo)"
                      class="embed border rounded"
                      v-model="row.classification"
                    />
                  </div>

                  <!-- 詳細1 -->
                  <div v-if="tempId&&![7, 8].includes(tempId)&&(placeholder(1, row).indexOf('補修')==-1)&&row.classification.indexOf('サービス作業') == -1">
                    <div class="text-xs text-center text-gray-400">{{ placeholder(1, row) }}</div>
                    <input
                      type="text"
                      v-model="row.detail1"
                      class="text-center border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                      :class="isDisabled(row.sortNo)?'bg-gray-200 cursor-not-allowed':''"
                      :disabled="isDisabled(row.sortNo)"
                    />
                  </div>

                  <!-- 詳細2 -->
                  <div v-if="tempId&&![7, 8].includes(tempId)&&(placeholder(2, row).indexOf('ボタン')==-1)&&(placeholder(2, row).indexOf('補修')==-1)&&row.classification.indexOf('サービス作業') == -1">
                    <div class="text-xs text-center text-gray-400">{{ placeholder(2, row) }}</div>
                    <input
                      type="text"
                      v-model="row.detail2"
                      class="text-center border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                      :class="isDisabled(row.sortNo)?'bg-gray-200 cursor-not-allowed':''"
                      :disabled="isDisabled(row.sortNo)"
                    />
                  </div>

                  <!-- 削除ボタン（完了した項目が1つもない場合※sortNoが変わってしまうため） -->
                  <div v-if="!notEdit || !notEdit.length" class="ml-auto mr-8 my-auto"  @click="deleteRow(i)">
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5"/>
                  </div>
                </div>

                <div v-if="tempId!=8" class="flex">
                  <!-- 項目 -->
                  <div class="mx-8 mb-1 w-full">
                    <input
                      type="text"
                      placeholder="清掃内容を入力してください"
                      v-model="row.item"
                      class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                      :class="isDisabled(row.sortNo)?'bg-gray-200 cursor-not-allowed':''"
                      :disabled="isDisabled(row.sortNo)"
                    />
                  </div>
                </div>

                <!-- 大和ライフネクストのみ -->
                <div v-if="tempId == 8" class="ml-8 mr-8">
                  <div class="text-white text-sm text-center cursor-pointer flex">
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='床面清掃'||!row.detail1?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, '床面清掃')">床面清掃</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='ガラス(高所以外)'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'ガラス(高所以外)')">ガラス(高所以外)</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1 mr-1" :class="row.detail1=='ガラス(高所)'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'ガラス(高所)')">ガラス(高所)</div>
                    <div class="p-1 font-bold rounded mt-auto mb-1" :class="row.detail1=='その他の清掃'?'bg-indigo-500':'bg-gray-300'" @click="cleaningType(row, 'その他の清掃')">その他の清掃</div>
                  </div>
                  <!-- 作業工程 -->
                  <div v-if="!row.detail1 || row.detail1=='床面清掃'" class="mb-1 w-full">
                    <div class="py-1.5 px-3 text-sm border border-gray-300 rounded cursor-pointer" @click="processClick(row)">
                      <div v-if="row.item">
                        {{ row.item }}
                      </div>
                      <div v-else class="font-bold text-red-600">
                        作業工程を入力してください。
                      </div>
                    </div>
                  </div>
                  <!-- 備考 -->
                  <div class="text-xs my-auto font-bold text-light-blue-700 h-full w-10">
                    備考
                  </div>
                  <div class="mb-1 w-full">
                    <input
                      :type="'text'"
                      placeholder="特筆することがあればご記入ください"
                      v-model="row.detail2"
                      class="border-gray-300 focus:bg-blue-50 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                      :class="isDisabled(row.sortNo)?'bg-gray-200 cursor-not-allowed':''"
                      :disabled="isDisabled(row.sortNo)"
                    />
                  </div>
                  <!-- 写真説明 -->
                  <div v-for="(t, tIdx) in row.cleaningTextArr" :key="'t' + tIdx" class="">
                    <div class="text-xs my-auto font-bold text-green-700 h-full w-32">
                      <p v-if="row.cleaningTextArr&&row.cleaningTextArr.length>1">
                        {{ tIdx + 1 }}枚目の写真説明
                      </p>
                      <p v-else>
                        写真説明
                      </p>
                    </div>
                    <div class="mb-1 w-full">
                      <input
                        :type="'text'"
                        placeholder="特筆することがあればご記入ください"
                        v-model="row.cleaningTextArr[tIdx]"
                        class="border-gray-300 focus:bg-green-50 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 w-full"
                        :class="isDisabled(row.sortNo)?'bg-gray-200 cursor-not-allowed':''"
                        :disabled="isDisabled(row.sortNo)"
                      />
                    </div>
                  </div>
                </div>

                <!-- 清掃中の写真の説明 -->
                <div class="flex ml-8">
                  <div v-if="row.classification.indexOf('サービス作業') == -1 && ![6, 7, 8].includes(tempId)" class="flex mr-3">
                    <div class=" my-auto font-bold text-green-600 w-fit">
                      清掃中：
                    </div>
                    <div class="mb-1">
                      <WmsSuggestInput
                        name="cleaningText"
                        :embed="true"
                        displayField="val"
                        :dynamic="false"
                        :incrementalSearch="false"
                        :selectionItems="selectionItems"
                        class="embed border rounded border-gray-300 w-52"
                        v-model="row.cleaningText"
                        placeholder="ポリッシャー洗浄中など"
                        :clearable="true"
                        :disabled="isDisabled(row.sortNo)"
                      />
                    </div>
                  </div>
                  <div v-else-if="tempId==7">
                    <input
                      placeholder="使用洗剤等..."
                      type="text"
                      v-model="row.cleaningText"
                      class="border-gray-300 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded py-1 text-blue-700 mb-1"
                      :disabled="isDisabled(row.sortNo)"
                    />
                  </div>

                  <!-- 該当なし -->
                  <PrimaryButton text="該当なし" size="sm" class="w-97px h-8 mr-3" :class="row.value!='N/A'?'bg-gray-400':'bg-teal-700'" :disabled="isDisabled(row.sortNo)" @click="setPoint(row)" />
                  <!-- 非表示 -->
                  <PrimaryButton text="非表示" size="sm" class="w-97px h-8" :class="!row.disabled?'bg-gray-400':'bg-dark-green'" :disabled="isDisabled(row.sortNo)" @click="disabledNA(row)" />
                  
                </div>
              </div>
            </draggable>
          </div>
          <PrimaryButton v-if="checkList&&checkList.length" text="登録" size="normal" class="w-full mt-1" @click="register()" />
        </div>
        <div class="text-transparent">{{reload}}</div>
        <!-- 作業工程モーダル（大和ライフネクスト） -->
        <ProcessModal
          v-model="openProcessModal"
          :classification="processData.classification"
          :item="processData.item"
          :selectCallback="processData.selectCallback"
          :open="openProcessModal"
        />
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon, PlusCircleIcon, MenuIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import ProcessModal from './ProcessModal.vue'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'

const TYPE = 3

const ckeckListRow = {id: null, siteCode: '', sortNo: null, monthly: '毎月', classification: '', detail1: '', detail2: '', item: '', cleaningText: ''}

export default {
  components: {
    Modal,
    TrashIcon,
    PlusCircleIcon,
    MenuIcon,
    PrimaryButton,
    WmsSuggestInput,
    ProcessModal,
    Toggle,
    draggable
  },

  props: {
    target: {
      type: Object
    },
    tempId: {
      type: Number,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 編集対象得意先データ
      list: {},
      // チェック項目
      checkList: [utils.clone(ckeckListRow)],
      // 清掃箇所
      classificationList: utils.setClassification(TYPE),
      // 清掃中写真の説明候補
      selectionItems: [
        { val: 'ポリッシャー洗浄中' },
        { val: 'デッキブラシ洗浄中' },
        { val: '高圧洗浄中' },
        { val: '作業中' },
      ],
      // 並べ替え
      sortable: false,
      // 完了されているため編集できない項目番号
      notEdit: [],
      // 画面即時反映
      reload: 0,
      // 作業工程モーダルopen（大和ライフネクストのみ）
      openProcessModal: false,
      // 作業工程モーダルに渡すデータ（大和ライフネクストのみ）
      processData: {}
    }
  },

  computed: {
    /**
     * スマホ
     */
    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },
  },


  watch: {
    async open() {
      if (!this.open) {
        return
      }
      await this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.list = this.target
        this.checkList = [utils.clone(ckeckListRow)]
        this.notEdit = []
        let result

        // 完了した項目のインデックスを取得
        if (this.target.items) {
          for (let i = 0; i < this.target.items.length; i++) {
            const item = this.target.items[i]
            if (item.complete) {
              this.notEdit.push(item.sortNo)
            }
          }
        }

        // 物件の履歴データ取得
        const param = {
          // 得意先コード
          clientCode: this.list.clientCode,
          // 物件コード
          siteCode: this.list.siteCode,
          // 棟コード
          ridgeCode: this.list.ridgeCode,
          // type
          type: TYPE
        }
        result = await backend.searchData('checkListByClient/getByClientCode', param)
        
        if (result.data && result.data.data && result.data.data.length) {
          this.checkList = result.data.data
        }
        this.setCleaningTextArr()
      }
    },

    /**
     * 初期化
     */
    closed() {
      this.list = {}
      // チェック項目
      this.checkList = []
      // 並べ替え
      this.sortable = false
    },

    /**
     * 登録処理
     */
    async register() {
      // 未入力チェック
      for (let vI = 0; vI < this.checkList.length; vI++) {
        const vC = this.checkList[vI]
        if (!vC.classification) {
          await this.errRequired('清掃箇所', vI)
          return
        } else if (!vC.item && vC.classification.indexOf('サービス作業') == -1) {
          const itemRes = await dialogs.showConfirmDialog('清掃内容', 'No.' + (vI + 1) + '「' + vC.classification + '」の清掃内容が未入力ですがよろしいでしょうか？')
          if (itemRes != 'YES') {
            return
          }
        }
      }
      this.convertCleaningText()
      const save = {
        // 得意先コード
        clientCode: this.list.clientCode,
        // 物件コード
        siteCode: this.list.siteCode,
        // 棟コード
        ridgeCode: this.list.ridgeCode,
        // 作成者
        insertUser: this.$store.getters.user.id
      }
      this.checkList.forEach((c, i) => {
        c.sortNo = i + 1
      })
      await backend.postData('checkListHistory/save', { save, tempId: this.tempId, item: this.checkList, type: TYPE, claim: false })
      
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     * @param i インデックス
     */
    async errRequired(item, i) {
      const num = i +1
      await dialogs.showErrorDialog(item + 'は必須です', num + '番目が入力されていません。')
    },

    /**
     * 追加
     */
    add() {
      this.checkList.push(utils.clone(ckeckListRow))
    },

    /**
     * 削除
     */
    async deleteRow(i) {
      const res = await dialogs.showConfirmDialog('削除してよろしいですか', `「${this.checkList[i].classification}」を削除してよろしいでしょうか`)
      if (res == 'YES') {
        this.checkList.splice(i, 1)
        if (!this.checkList.length) {
          this.add()
        }
      }
    },

    /**
     * 詳細1，2のプレイスホルダー
     * @param num 番号
     */
    placeholder(num, row) {
      let val = ''
      val = utils.patrolFixedCleanPlaceholder(num, row, this.tempId)
      return val
    },

    /**
     * 毎月設定変更イベント
     * @param row 対象項目データ
     * @param sortNo ソート番号
     */
    changeMonthly(row, sortNo) {
      if (this.isDisabled(sortNo)) {
        return
      }
      let res = ''
      switch (row.monthly) {
        case '毎月':
          res = '奇数月'
          break;
        case '奇数月':
          res = '偶数月'
          break;
        case '偶数月':
          res = '毎月'
          break;
        default:
          res = '奇数月'
          break;
      }
      row.monthly = res
      this.reload++
    },

    /**
     * 毎月設定ボタン
     * @param val
     */
    monthlyBtnColor(val, sortNo) {
      if (this.isDisabled(sortNo)) {
        return 'bg-gray-400 cursor-not-allowed'
      }
      if (val == '奇数月') {
        return 'monthly-green'
      } else if (val == '偶数月') {
        return 'bg-yellow-500'
      } else {
        return 'monthly-blue'
      }
    },

    /**
     * 該当なしボタンイベント
     * @param row 対象行
     */
    setPoint(row) {
      if (row.value == 'N/A') {
        row.value = ''
        row.disabled = false
      } else {
        row.value = 'N/A'
      }
    },

    /**
     * 非表示ボタンイベント
     * @param row 対象行
     */
    disabledNA(row) {
      if (row.disabled) {
        row.disabled = false
      } else {
        row.disabled = true
        row.value = 'N/A'
      }
    },

    /**
     * 編集可能か判断
     * @param i インデックス
     */
    isDisabled(i) {
      // 完了している場合は、編集不可
      if (this.notEdit && this.notEdit.length && this.notEdit.includes(i)) {
        return true
      }
      return false
    },

    /**
     * 写真説明を配列に変換
     */
    setCleaningTextArr() {
      // 大和ライフネクストの場合のみ
      if (this.tempId != 8) {
        return
      }
      for (let i = 0; i < this.checkList.length; i++) {
        const row = this.checkList[i]
        if (row.cleaningText) {
          row.cleaningTextArr = row.cleaningText.split('@@@')
          if (!row.cleaningTextArr || !row.cleaningTextArr.length) {
            row.cleaningTextArr = ['']
          }
        } else {
          row.cleaningTextArr = ['']
        }
      }
    },

    /**
     * 大和ライフネクストの場合、写真説明を変換
     */
    convertCleaningText() {
      if (this.tempId != 8) {
        return
      }

      this.checkList.forEach((c) => {
        // 写真説明を配列に変換
        if (c.cleaningTextArr && c.cleaningTextArr.length) {
          c.cleaningText = c.cleaningTextArr.join('@@@')
        } else {
          c.cleaningText = ''
        }
      })
    },

    /**
     * 作業工程クリックイベント（大和ライフネクスト）
     * @param row 対象行
     */
    processClick(row) {
      if (this.isDisabled(row.sortNo)) {
        return
      }
      this.openProcessModal = true
      this.processData = {
        classification: row.classification,
        item: row.item,
        selectCallback: (item) => {
          this.openProcessModal = false
          row.item = item
        }
      }
    },

    /**
     * 清掃種類ボタンイベント
     * 得意先：大和ライフネクスト株式会社
     */
    cleaningType(row, val) {
      if (this.isDisabled(row.sortNo)) {
        return
      }
      row.detail1 = val
      this.reload++
    },
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  /* height: calc(100vh - 130px); */
  overflow: auto;
  padding: 10px;
}

/* .mobile-content {
  min-height: 900px;
} */

.pc-content {
  height: calc(100vh - 130px);
}

.mobile-content-table {
  min-height: 900px;
}

.pc-content-table {
  height: calc(100vh - 310px);
}

.monthly-green {
  background: #69a669;
}

.monthly-blue {
  background: #5296c3;
}

::placeholder {
  color: rgb(172, 172, 172);
  font-weight: normal;
}

</style>