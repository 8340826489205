import Vue from 'vue'
import VueRouter from 'vue-router'
import Signin from '../pages/Signin.vue'
import Launcher from '../pages/Launcher.vue'
import EstimationAppRoutes from '@Estimation/EstimationAppRoutes'
import RequestPostAppRoutes from '@RequestPost/RequestPostAppRoutes'
import RequestPost from '@RequestPost/pages/RequestPost.vue'
import LogAppRoutes from '@log/LogAppRoutes'
import UserAppRoutes from '@User/UserAppRoutes'
import DepartmentAppRoutes from '@Department/DepartmentAppRoutes'
import PatrolAppRoutes from '@Patrol/PatrolAppRoutes'
import RoundCleanAppRoutes from '@RoundClean/RoundCleanAppRoutes'
import FixedCleanAppRoutes from '@FixedClean/FixedCleanAppRoutes'
import FacilityPatrolAppRoutes from '@FacilityPatrol/FacilityPatrolAppRoutes'
import PhotoReportAppRoutes from '@PhotoReport/PhotoReportAppRoutes'
import MasterAppRoutes from '@Master/MasterAppRoutes'

import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: { isPublic: true, title: 'RP - サインイン' }
  },
  {
    path: '/',
    name: 'Launcher',
    component: Launcher,
    meta: { requiresAuth: true, title: 'RP - メニュー' }
  },
  {
    path: '/customersRequestPost',
    name: 'customersRequestPost',
    component: RequestPost,
    meta: { isPublic: true, title: 'RP - お客様メインタスク' }
  },
  {
    ...EstimationAppRoutes
  },
  {
    ...RequestPostAppRoutes
  },
  {
    ...LogAppRoutes
  },
  {
    ...UserAppRoutes
  },
  {
    ...DepartmentAppRoutes
  },
  {
    ...PatrolAppRoutes
  },
  {
    ...RoundCleanAppRoutes
  },
  {
    ...FixedCleanAppRoutes
  },
  {
    ...FacilityPatrolAppRoutes
  },
  {
    ...PhotoReportAppRoutes
  },
  {
    ...MasterAppRoutes
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isSignin) {
    next({ path: '/signin', query: { redirect: to.fullPath } });
  } else {
    next();
  }
})

router.afterEach((to) => {
  document.title = to.meta.title || 'apollo-RP'
  if (to.path.indexOf('/FreeTemplate') >= 0 || to.path.indexOf('/StaticTemplate') >= 0 || to.path.indexOf('/BikeTemplate') >= 0 || to.path.indexOf('/PhotoReportList') >= 0 ) {
    document.querySelector("meta[name='viewport']").setAttribute('content', '')
  } else {
    document.querySelector("meta[name='viewport']").setAttribute('content', 'width=device-width')
  }
})

export default router
