<template>
  <div class="relative overflow-hidden header-contents" :class="colorCode">
    <nav
      :class="['bg-transparent', 'relative z-10 border-b border-teal-500 border-opacity-25 lg:bg-transparent lg:border-none']">
      <div class="--max-w-7xl mx-auto pl-2 sm:pl-4 lg:pl-8">
        <div class="relative h-16 flex items-center justify-between">
          <div class="px-2 flex items-center lg:px-0">
            <div class="flex-shrink-0">
              <img v-if="!unauthorized" class="block h-8 w-auto cursor-pointer" src="images/logo.svg"
                @click="$router.push({ name: 'Launcher' })" />
              <img v-if="unauthorized" class="block h-8 w-auto" src="images/logo.svg" />
            </div>
            <div class="ml-4 lg:space-x-4">
              <div class="flex items-center text-xl">
                <h1 class="mr-4 text-white">{{ title }}</h1>
                <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-black bg-opacity-25' : 'hover:bg-light-blue-800', 'rounded-md py-2 px-3 text-sm font-medium text-white']">{{ item.name }}</a> -->
              </div>
            </div>
          </div>
          <slot name="title-header-content"></slot>
          <div class="mr-2 ml-4">
            <UserMenu v-if="isUserMenu" />
          </div>
        </div>
      </div>
    </nav>
    <div
      :class="['inset-y-0', 'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0']"
      aria-hidden="true">
      <div class="flex-grow bg-light-blue-900 bg-opacity-75" />
      <svg class="flex-shrink-0" width="1750" height="308" viewBox="0 0 1750 308" xmlns="http://www.w3.org/2000/svg">
        <path opacity=".75" d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#075985" />
        <path opacity=".75" d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0c4a6e" />
      </svg>
      <div class="flex-grow bg-light-blue-800 bg-opacity-75" />
    </div>
    <header class="relative py-2 px-2 sm:px-4 lg:px-8">
      <slot name="page-header-content"></slot>
    </header>
  </div>
</template>
<script>
import UserMenu from '@components/UserMenu.vue'
import { PIGEON_CONFIG } from '@/config'
export default {
  components: {
    UserMenu
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isUserMenu: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      unauthorized: false,
      colorCode: PIGEON_CONFIG.colorCode
    }
  },

  computed: {
    appVersion() {
      return this.$store.getters.appVersion
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    async initialize() {
      let path = location.pathname
      if (path.indexOf('/customersRequestPost') > -1) this.unauthorized = true
    }
  }
}
</script>