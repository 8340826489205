<template>
  <component :is="icon"/>
</template>
<script>
import * as iconNameResolver from './iconNameResolver'
export default {
  props: {
    mimetype: {
      type: String,
      default: ''
    }
  },
  computed: {
  //   iconName() {
  //     return iconNameResolver.resolveIconName(this.mimetype)
  //   },
    icon() {
      // return () => import(`./icons/${this.iconName}.svg`)
      const iconName = iconNameResolver.resolveIconName(this.mimetype)
      // return () => import(`../../../assets/icons/${iconName}.svg`)
      return () => import(`./icons/${iconName}.svg`)
    }
  },
  data() {
    return {
      // icon: null
    }
  },
  // watch: {
  //   mimetype(newValue) {
  //     const iconName = iconNameResolver.resolveIconName(newValue)
  //     this.icon = () => import(`../../../assets/icons/${iconName}.svg`)
  //   }
  // }
}
</script>