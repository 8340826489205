<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div>
      <div class="overflow-auto rounded main-height">
        <div v-if="loading" class="w-full h-full flex justify-center items-center loading">
          <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
        </div>
        <div v-if="!loading && !list.length" class="main w-full flex justify-center items-center">
          <p class="text-gray-400 font-bold">対象データがありません</p>
        </div>
        <table class="mx-1 mb-12">
          <thead class="sticky top-0 bg-white">
            <tr>
              <th v-for="(f, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                <div v-if="f.title == '物件名'" class="">
                  <input
                    type="search"
                    name="site" 
                    placeholder="物件名 検索"
                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.site"
                    @change="getData()"
                  />
                </div>
                <div v-else-if="f.title == '得意先名'" class="">
                  <input
                    type="search"
                    name="client" 
                    placeholder="得意先名 検索"
                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.client"
                    @change="getData()"
                  />
                </div>
                <div v-else-if="f.title == '清掃員'" class="">
                  <input
                    type="search"
                    name="staff" 
                    placeholder="清掃員 検索"
                    class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.staff"
                    @change="getData()"
                  />
                </div>
                <div v-else></div>
              </th>
            </tr>
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider h-10 cursor-pointer hover-color" @click="clickRow(row)">

            <!-- 選択 -->
            <td class="b-top px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 w-20">
              <input
                type="radio"
                :id="row.siteCode"
                :value="row"
                class="cursor-pointer"
                v-model="selectedSite"
              >
            </td>

            <!-- 物件名 -->
            <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
              {{ row.nameSite }}
            </td>

            <!-- 得意先名 -->
            <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700 min-300">
              {{ row.nameCustomer }}
              <p class="text-xs text-gray-400">{{ row.nameCustomer2 }}</p>
            </td>

            <!-- 清掃員 -->
            <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-300">
              {{ row.nameStaff }}
            </td>
          </tr>
        </table>
      </div>
      <div>
        <PrimaryButton text="選択" size="normal mt-3 w-full" :disabled="selectedSite === null"  @click="selectedBtn()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'
export default {
  components: {
    Modal,
    PrimaryButton
  },

  props: ['open'],

  data() {
    return {
      // カラム
      columns: [
        { title: '選択' },
        { title: '物件名' },
        { title: '得意先名' },
        { title: '清掃員' },
      ],
      // 検索
      filter: {
        site: '',
        client: '',
        staff: ''
      },
      // 一覧
      list: [],
      // 選択した物件
      selectedSite: null,
      // ローディング
      loading: false
    }
  },

  computed: {
  },

  watch: {
    async open() {
      if (this.open) {
        await this.getData()
      }
    }
  },

  methods: {
    /**
     * 得意先取得
     */
    async getData() {
      this.loading = true
      const result = await backend.searchData('akbsTable/getSiteList', this.filter)
      if (result && result.data.data && result.data.data.length) {
        this.list = result.data.data
      } else {
        this.list = []
      }
      this.loading = false
    },

    /**
     * 行クリックイベント
     */
    clickRow(target) {
      this.selectedSite = target
    },

    /**
     * 選択ボタンイベント
     */
    selectedBtn() {
      this.$emit('modalSiteSelected', this.selectedSite)
      this.closed()
    },

    /**
     * 閉じる
     */
    closed() {
      // 検索
      this.filter = {
        site: '',
        client: '',
        client2: '',
        staff: ''
      }
      // 得意先一覧
      this.list = []
      // 選択した物件
      this.selectedSite = null
      // ローディング
      this.loading = false
    }
  }
}
</script>
<style scoped>
  .main-height {
    height: 70vh;
  }

  .min-300 {
    min-width: 300px;
  }

  .min-70 {
    min-width: 70px;
  }

  .b-top {
    border-top: 1px solid rgb(228, 227, 227);
  }

  .bg-colu {
    background: rgb(233, 244, 247);
  }

  .hover-color:hover {
    background: rgb(233, 244, 247);
  }
</style>