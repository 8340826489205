import * as utils from './utils'
import { PIGEON_CONFIG } from '@/config'

const AttachmentSchema = {
  originalName: '',
  url: '',
  content: ''
}

export function estimateIsExpired(estimate, specficDate) {
  if (!specficDate) {
    specficDate = utils.getToday()
  }
  if (estimate.estimateStatus !== '受注' && estimate.expireDate < specficDate) {
    return true
  } else {
    return false
  }
}

export function buildAttachmentUrl(attachmentUrl, token) {
  if (attachmentUrl && token) {
    const pigeonBaseUrl = PIGEON_CONFIG.baseUrl
    const url = `${pigeonBaseUrl}${attachmentUrl}?token=${token}`
    return url
  } else {
    return ''
  }
}

export function createEmptyAttachment() {
  const emptyAttachment = utils.clone(AttachmentSchema)
  return emptyAttachment
}

export function taskExpired(data, specficDate) {
  if (!specficDate) {
    specficDate = utils.getToday()
  }
  if (data.status == 'open' && data.limitDate <= specficDate) {
    return true
  } else {
    return false
  }
}

