<template>
  <router-link :to="path" class="---mr-4" :class="classes" @click.native="click" >
    {{ valueFunc ? valueFunc(record) : value }}
  </router-link>
</template>
<script>
import { evaluate } from '@libs/utils'

export default {
  props: ['url', 'value', 'valueFunc', 'record', 'onclick', 'color'],

  computed: {
    path() {
      const record = this.record
      return evaluate({ record }, '`' + this.url + '`')
    },

    classes() {
      const classes = []
      classes.push(`text-${this.color || 'blue'}-600`)
      classes.push(`hover:text-${this.color || 'blue'}-900`)
      return classes
    }
  },

  methods: {
    click() {
      if (this.onclick) {
        this.onclick(this.record)
      }
    }
  }
}
</script>