
import MessageDialog from '@components/MessageDialog.vue'
import LoadingModal from '@components/LoadingModal.vue'
import { $vfm } from 'vue-final-modal'

import { wait } from '@libs/utils'

export function showMesssageDialog(title, message, icon, buttons) {
  return new Promise((resolve) => {
    $vfm.show({
      component: MessageDialog,
      on: {
        click(button, close) {
          close()
          resolve(button.result)
        },
      }
    }, {
      title,
      content: message,
      icon,
      buttons,
      callback: (button, close) => {
        close()
        resolve(button.result)
      }
    })
  })
}

export function showLoading(message, subMessage) {
  $vfm.show({
    component: LoadingModal
  }, {
    message,
    subMessage
  })
}

export async function hideLoading() {
  await wait(300)
  $vfm.hideAll()
}

export function showInfoDialog(title, message, buttonText) {
  return showMesssageDialog(title, message, 'info', [
    {
      kind: 'primary',
      text: buttonText || 'OK',
      result: 'OK'
    }
  ])
}

export function showSuccessDialog(title, message, buttonText) {
  return showMesssageDialog(title, message, 'success', [
    {
      kind: 'primary',
      text: buttonText || 'OK',
      result: 'OK'
    }
  ])
}

export function showWarningDialog(title, message, buttonText) {
  return showMesssageDialog(title, message, 'warning', [
    {
      kind: 'primary',
      text: buttonText || 'OK',
      result: 'OK'
    }
  ])
}

export function showErrorDialog(title, message, buttonText) {
  return showMesssageDialog(title, message, 'error', [
    {
      kind: 'primary',
      text: buttonText || 'OK',
      result: 'OK'
    }
  ])
}

export function showConfirmDialog(title, message, positiveButtonText, negativeButtonText) {
  return showMesssageDialog(title, message, 'question', [
    {
      kind: 'primary',
      text: positiveButtonText || 'はい',
      result: 'YES'
    },
    {
      kind: 'normal',
      text: negativeButtonText || 'キャンセル',
      result: 'CANCEL'
    }
  ])
}

export function showWarningConfirmDialog(title, message, positiveButtonText, negativeButtonText) {
  return showMesssageDialog(title, message, 'warning', [
    {
      kind: 'danger',
      text: positiveButtonText || 'はい',
      result: 'YES'
    },
    {
      kind: 'normal',
      text: negativeButtonText || 'キャンセル',
      result: 'CANCEL'
    }
  ])
}
