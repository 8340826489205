
// バックエンド（Pigeon）接続情報
const _PIGEON_CONFIG = {
  // 開発環境(localhost)
  development: {
    baseUrl: 'http://localhost:3006/',
    apiEndpointBase: 'http://localhost:3006/api',
    printApiEndpointBase: 'http://localhost:3022',
    apiProxyServiceEndpoint: 'https://develop.media-labo.co.jp/api-proxy',
    requestPostEndpoint: 'http://localhost:3331',
    colorCode: 'bg-pink-400',
    akbsApiEndpointBase: 'http://localhost:8080/api/v1'
  },
  // 検証環境
  verification: {
    baseUrl: 'https://requestpost-systems.com/tms2/',
    apiEndpointBase: 'https://requestpost-systems.com/tms2/api',
    printApiEndpointBase: 'https://requestpost-systems.com/webcap2',
    apiProxyServiceEndpoint: 'https://requestpost-systems.com/api-proxy',
    requestPostEndpoint: 'https://requestpost-systems.com/api2',
    colorCode: 'bg-yellow-400',
    akbsApiEndpointBase: 'https://akbs-systems.com/api/v1'
  },
  // 本番環境
  production: {
    baseUrl: 'https://requestpost-systems.com/tms/',
    apiEndpointBase: 'https://requestpost-systems.com/tms/api',
    printApiEndpointBase: 'https://requestpost-systems.com/webcap',
    apiProxyServiceEndpoint: 'https://requestpost-systems.com/api-proxy',
    requestPostEndpoint: 'https://requestpost-systems.com/api',
    colorCode: 'bg-light-blue-700',
    akbsApiEndpointBase: 'https://akbs-systems.com/api/v1'
  }
}

// メインタスク登録時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_MESSAGE_TEMPLATE = 
`\${mension}
[info][title]メインタスクに投稿がありました。[/title]
■ お問い合わせカテゴリー：\${category}
■ タイトル：\${inquiry_title}
■ 御見積_清掃カテゴリー：\${estimationCleanCategory}
■ 御見積_点検カテゴリー：\${estimationInspectCategory}
■ 御見積_その他カテゴリー：\${estimationOtherCategory}
■ 依頼主：\${sender}

● メインタスク：\${viewUrl}[/info]`

// ----------------------------------------------------------------------

// デンタツ（メインタスク）登録時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION_MESSAGE_TEMPLATE = 
`\${mension}
[info][title]メインタスクに投稿がありました。[/title]
■ カテゴリー：\${dentatsuCategory}
■ お問い合わせ内容：\${inquiry_title}
■ お客様会社名（物件名）：\${clientName}
■ お客様名：\${clientPersonName}
■ 従業員名：\${employeeName}
■ 電話番号：\${telephoneNumber}
■ 依頼主：\${sender}

● メインタスク：\${viewUrl}[/info]`

// ----------------------------------------------------------------------

// AKBS休み登録（メインタスク）登録時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_AKBS_MESSAGE_TEMPLATE = 
`\${mension}
[info][title]メインタスクに投稿がありました。[/title]
■ お問い合わせカテゴリー：\${category}
■ タイトル：\${inquiry_title}
■ 依頼主：\${sender}

● メインタスク：\${viewUrl}[/info]`

// ----------------------------------------------------------------------

// タスク依頼時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_REQUESTING_TASKS_MESSAGE_TEMPLATE = 
`\${mension}
[info][title]サブタスク[/title]タスクが確定しました。依頼されたタスクを行ってください。

■ タスク：\${requestTask}
■ 期限：\${limitDate}
■ タイトル：\${inquiry_title}
■ 依頼主：\${sender}

● サブタスク：\${viewUrl}[/info]`
// ----------------------------------------------------------------------

// サブタスク変更時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_SUB_CHANGED_TEMPLATE = 
`\${mension}
[info][title]サブタスク[/title]タスクが変更されました。変更されたタスクを確認してください。

\${changedDetails}

■ タスク：\${requestTask}
■ 期限：\${limitDate}
■ タイトル：\${inquiry_title}
■ タスク変更者：\${loginUser}

● サブタスク：\${viewUrl}[/info]`
// ----------------------------------------------------------------------

// サブタスク削除時のChatWorkメッセージ
// ----------------------------------------------------------------------
const _SEND_CHATWORK_MESSAGE_WHEN_SUB_DELETE_TEMPLATE = 
`\${mension}
[info][title]サブタスク[/title]タスクが削除されました。

■ タスク：\${requestTask}
■ 期限：\${limitDate}
■ タイトル：\${inquiry_title}
■ タスク削除者：\${loginUser}

● サブタスク：\${viewUrl}[/info]`
// ----------------------------------------------------------------------

// 見積り_承認依頼
const _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE_TEMPLATE = 
`\${mension}

[info][title]見積り承認依頼[/title]見積りの承認をお願い致します。

■ 申請者：\${user}
■ 見積りNo：\${estimateNo}
■ タイトル：\${requestPostName}
■ 得意先：\${clientName}
■ 建物名：\${buildingName}
■ メッセージ：\${message}

●  承認画面：\${viewUrl}[/info]`

// APIトークン
const _ESTIMATE_APPROVAL_API_TOKEN = '12e6247b22aa5826875a69997587ea01'

// ----------------------------------------------------------------------

// 見積り_差戻
const _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE_TEMPLATE = 
`\${mension}

[info][title]見積り\${type}[/title]申請した見積りが\${type}されました。

■ 見積りNo：\${estimateNo}
■ タイトル：\${requestPostName}
■ 得意先：\${clientName}
■ 理由：
\${reason}

●  見積り画面：\${viewUrl}[/info]`

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// 見積り_承認OK
const _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVED_OK_MESSAGE_TEMPLATE = 
`\${mension}

[info][title]見積り\${type}[/title]申請した見積りが\${type}されました。

■ 見積りNo：\${estimateNo}
■ タイトル：\${requestPostName}
■ 得意先：\${clientName}

●  見積り参照画面：\${viewUrl}[/info]`

// ----------------------------------------------------------------------

/**
 * 以下exportされるもの
 */

// ターゲット環境
export const TARGET_CONFIG = process.env.VUE_APP_TARGET_ENV

// バックエンド接続情報
export const PIGEON_CONFIG = _PIGEON_CONFIG[TARGET_CONFIG]

// 各種機能設定
export const FEATURES = {
  ESTIMATION: {
    DETAIL_INPUT_ENTER_KEY_TO_MOVE: false,
    AUTO_SET_SUPPLIER_WHEN_INPUT_SALES_PRODUCT: true,
    PRINT_BUTTON: 'EACH', // EACH | EACH_DROPDOWN | ONE
    ENABLE_PRINT_ESTIMATE_WITH_COVERPAGE_BUTTON: false,
    COVER_PAGE: {
      TITLE: 'ＦＡＸ送付のご案内',
      INTRODUCTION_TEXT: 'いつもお世話になっております。\n\nこの度は、弊社製品のお問い合わせいただきありがとうございます。',
      BODY_TEXT: 'ご依頼の見積書をお送り致します。\nご査収くださいますようお願い申し上げます。',
      CONCLUSION_TEXT: 'ご不明な点などございましたら、\nお手数ですが、ご連絡ください。\n宜しくお願い申し上げます。'
    }
  }
}

// メインタスク各種機能設定
export const REQUEST_POST_FEATURES = {
  REQUEST_POST: {
    SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_MESSAGE_TEMPLATE
    }
  }
}

// デンタツ/AKBS休み登録（メインタスク）各種機能設定
export const REQUEST_POST_TRANSMISSION_FEATURES = {
  REQUEST_POST_TRANSMISSION: {
    SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_TRANSMISSION_MESSAGE_TEMPLATE,
      messageTemplateAKBS: _SEND_CHATWORK_MESSAGE_WHEN_REQUEST_POST_AKBS_MESSAGE_TEMPLATE
    }
  }
}

// サブタスク依頼各種機能設定
export const TASK_REQUEST_FEATURES = {
  TASK_REQUEST: {
    SEND_CHATWORK_MESSAGE_WHEN_REQUESTING_TASKS: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_REQUESTING_TASKS_MESSAGE_TEMPLATE
    }
  }
}

// サブタスク変更メッセージ
export const SUB_CHANGED = _SEND_CHATWORK_MESSAGE_WHEN_SUB_CHANGED_TEMPLATE

// サブタスク削除メッセージ
export const SUB_DELETE = _SEND_CHATWORK_MESSAGE_WHEN_SUB_DELETE_TEMPLATE

// 見積り_承認依頼
export const ESTIMATE_FEATURES = {
  ESTIMATION: {
    SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVAL_MESSAGE_TEMPLATE,
      chatworkApiToken: _ESTIMATE_APPROVAL_API_TOKEN
    }
  }
}

// 見積り_差戻
export const ESTIMATE_SENDING_BACK_FEATURES = {
  ESTIMATION: {
    SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_SENDING_BACK_MESSAGE_TEMPLATE
    }
  }
}

// 見積り_承認OK
export const ESTIMATE_SENDING_APPROVED_OK = {
  ESTIMATION: {
    SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVED_OK_MESSAGE: {
      enable: true,
      messageTemplate: _SEND_CHATWORK_MESSAGE_WHEN_ESTIMATE_APPROVED_OK_MESSAGE_TEMPLATE
    }
  }
}

// ----------------------------------------------------------------------
// 見積単価編集権限 ユーザーID
export const ESTIMATE_PRICE_AUTH = [
  '6244', // 大野様
  '6261', // 管理者ユーザ
  '6412', // 鈴木諒様
]

// 非公開依頼 編集権限 ユーザーID
export const PRIVATE_REQUEST_AUTH = [
  '6244', // 大野様
  '6261', // 管理者ユーザ
]
