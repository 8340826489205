<template>
  <Attachment :name="name" :readonly="readonly" :view="view" :value="fileInfo" @change="onChange" :disabled="disabled" :errorMessage="errorMessage"/>
</template>
<script>
import Attachment from '@components/Attachment.vue'
import * as helper from '@libs/helper'
export default {
  components: {
    Attachment
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileInfo: {
        filename: '',
        url: ''
      }
    }
  },
  watch: {
    value: {
      handler: function(newValue) {
        this.setValue(newValue)
      },
      deep: true
    },
  },
  created() {
    this.setValue(this.value)
  },
  methods: {
    setValue(value) {
      if (value) {
        const filename = value.originalName || ''
        let token = ''
        if (this.$store.getters.user) token = this.$store.getters.user.token
        const attachmentUrl = helper.buildAttachmentUrl(value.url, token)
        if (this.fileInfo.filename !== filename || this.fileInfo.url !== attachmentUrl) {
          this.fileInfo.filename = filename
          this.fileInfo.url = attachmentUrl
        }
      }
    },

    // clear() {
    //   this.fileInfo.filename = ''
    //   this.fileInfo.url = ''
    // },

    onChange(attachment) {
      this.$emit('change', attachment)
    } 
  }
}
</script>