<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #title>特記事項の追加</template>
      <div class="content-container">
        <EstimateNoteSelector
          :key="renderKey"
          ref="estimateNoteSelector"
          @classificationChange="classificationChange"
          @selectionChange="selectionChange"
          @edit="editNote"
        >
        </EstimateNoteSelector>
      </div>
      <template #action>
        <div class="w-full">
          <!-- <SecondaryButton text="特記事項を新規登録" size="sm" @click="newNote" /> -->
          <PrimaryButton class="w-full" text="選択した特記事項を見積りに反映" size="sm" @click="apply" />
        </div>
      </template>
    </Modal>
    <PigeonFormModal
      v-model="pigeonFormShow"
      :title="pigeonFormTitle"
      formId="estimate_note_master"
      :taskId="editNoteId"
      :embed="true"
      :fluid="true"
      :header="false"
      :queryParams="queryParams"
      :show="pigeonFormShow"
      @registered="refresh"
      @updated="refresh"
    />
  </div>
</template>
<script>
import Modal from '@components/Modal.vue'
import EstimateNoteSelector from './EstimateNoteSelector.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import SecondaryButton from '@components/SecondaryButton.vue'
import PigeonFormModal from '@components/PigeonFormModal.vue'

export default {
  components: {
    Modal,
    EstimateNoteSelector,
    PrimaryButton,
    // SecondaryButton,
    PigeonFormModal
  },
  inheritAttrs: false,
  data() {
    return {
      selectedNotes: [],
      renderKey: 0,
      editNoteId: null,

      pigeonFormTitle: '',
      pigeonFormShow: false,

      selectedClassificationName: ''
    }
  },
  computed: {
    queryParams() {
      if (this.selectedClassificationName) {
        return {
          classification: this.selectedClassificationName
        }
      } else {
        return null
      }
    }
  },
  methods: {
    editNote(note) {
      this.pigeonFormShow = false
      this.pigeonFormTitle = '特記事項の編集'
      this.editNoteId = note._id
      this.pigeonFormShow = true
    },

    newNote() {
      this.pigeonFormShow = false
      this.pigeonFormTitle = '特記事項の新規登録'
      this.editNoteId = null
      this.pigeonFormShow = true
    },

    classificationChange(classificationName) {
      if (classificationName === '全て') {
        this.selectedClassificationName = ''
      } else {
        this.selectedClassificationName = classificationName
      }
    },

    selectionChange(selectedNotes) {
      this.selectedNotes = selectedNotes
    },

    apply() {
      this.$emit('apply', this.selectedNotes)
    },

    closed() {
      console.log('EstimateNoteSelectorModal::closed')
      this.renderKey++
    },

    refresh() {
      console.log('EstimateNoteSelectorModal::refresh')
      this.pigeonFormShow = false
      this.$refs.estimateNoteSelector.refresh()
    }
  }
}
</script>
<style scoped>
.content-container {
  max-width: 1224px;
  height: 600px;
}

@media screen and (max-width: 1200px) {
  .content-container {
    width: 95vw;
  }
}
</style>