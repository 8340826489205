<template>
  <div id="app">
    <router-view />
    <modals-container></modals-container>
  </div>
</template>
<script>
import { ModalsContainer } from 'vue-final-modal'
export default {
  components: {
    ModalsContainer
  },
  created() {
    console.log('Application created.')
  }
}
</script>
