
<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="flex justify-between">
            <div class="text-lg text-green-800 font-bold">クレーム期間設定 </div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="text-gray-600 font-bold mr-auto">
              得意先 :  {{ list.clientName1 }}
            </div>
            <div class="text-gray-600 font-bold mr-auto mt-5">
              物件名 :  {{ list.siteName }}
            </div>
            <div class="mr-auto flex mt-5">
              <div class="text-gray-600 font-bold">Weight : </div>
              <div class="border border-teal-500 ml-5">
                <NumericTextInput
                  name="cWeight"
                  v-model="list.weight"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>
            <div class="mr-auto mt-5 flex">
              <div class="text-gray-600 font-bold">人数 : </div>
              <div class="border border-teal-500 ml-5">
                <NumericTextInput
                  name="people"
                  v-model="list.people"
                  class="text-right w-24 py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                />
              </div>
            </div>

          <!-- クレーム対応 -->
            <div class="text-gray-600 font-bold mt-5 mr-auto flex">
              <div>期間</div>
            </div>
            <div class="border border-green-200 p-1">
              <div class="m-3">
                <input type="checkbox" id="month" value="1" v-model="termMonth">
                <label for="month" class="month pl-1">月ごと</label>
              </div>
              <div class="flex">
                <PrimaryButton text="1月" size="normal" class="w-full mr-1 focus:ring-teal-500 ml-1 py-3" :class="monthes.includes(1)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(1)" />
                <PrimaryButton text="2月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(2)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(2)" />
                <PrimaryButton text="3月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(3)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(3)" />
                <PrimaryButton text="4月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(4)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(4)" />
                <PrimaryButton text="5月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(5)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(5)" />
                <PrimaryButton text="6月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(6)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(6)" />
                <PrimaryButton text="7月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(7)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(7)" />
                <PrimaryButton text="8月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(8)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(8)" />
                <PrimaryButton text="9月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(9)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(9)" />
                <PrimaryButton text="10月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(10)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(10)" />
                <PrimaryButton text="11月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(11)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(11)" />
                <PrimaryButton text="12月" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="monthes.includes(12)?'bg-teal-700':'bg-gray-400'" @click="setMonthes(12)" />
              </div>
            </div>
            <div class="border border-green-200 mt-3 p-1">
              <div class="m-3">
                <input type="checkbox" id="week" value="1" v-model="termWeek">
                <label for="week" class="week pl-1">週ごと</label>
              </div>
              <div class="flex">
                <PrimaryButton text="月" size="normal" class="w-full mr-1 focus:ring-teal-500 ml-1" :class="week=='月'?'bg-teal-700':'bg-gray-400'" @click="setWeek('月')" />
                <PrimaryButton text="火" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='火'?'bg-teal-700':'bg-gray-400'" @click="setWeek('火')" />
                <PrimaryButton text="水" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='水'?'bg-teal-700':'bg-gray-400'" @click="setWeek('水')" />
                <PrimaryButton text="木" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='木'?'bg-teal-700':'bg-gray-400'" @click="setWeek('木')" />
                <PrimaryButton text="金" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='金'?'bg-teal-700':'bg-gray-400'" @click="setWeek('金')" />
                <PrimaryButton text="土" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='土'?'bg-teal-700':'bg-gray-400'" @click="setWeek('土')" />
                <PrimaryButton text="日" size="normal" class="w-full mr-1 focus:ring-teal-500" :class="week=='日'?'bg-teal-700':'bg-gray-400'" @click="setWeek('日')" />
              </div>
            </div>
          </div>
          <div class="flex">
            <PrimaryButton text="削除" size="normal" class="w-full bg-teal-100 hover:border-teal-300 focus:ring-teal-500 text-teal-800 mr-2" @click="cancelClaim(true)" />
            <PrimaryButton text="登録" size="normal" class="w-full bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 ml-2" @click="save()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

const claim = true

export default {
  components: {
    Modal,
    PrimaryButton,
    NumericTextInput,
  },

  props: {
    target: {
      type: Object
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃）
    type: {
      type: Number,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      list: { id: null, siteCode: null, weight: null, people: null, clientName1: '', siteName: '' },
      monthes: [],
      week: '',
      termMonth: null,
      termWeek: null,
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (!this.open) {
        return
      }
      this.getTarget()
    },

    termMonth() {
      if (this.termMonth == '1') {
        this.termWeek = null
        this.week = null
      }
    },

    termWeek() {
      if (this.termWeek == '1') {
        this.termMonth = null
        this.monthes = []
      }
    }
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        console.log(this.target)
        this.list = this.target
        this.list.weight = this.target.claimWeight
        this.list.people = '1'
        if (this.target.claimPeople) {
          this.list.people = String(this.target.claimPeople)
        }
        // 該当する期間データを取得
        let result = await backend.searchData('patrolTerm/getBySiteCode', { siteCode: this.list.siteCode, claim, type: this.type })
        if (result.data && result.data.data.length) {
          const rd = result.data.data[0]
          this.list.id = rd.id
          if (rd.monthes) {
            this.termMonth = '1'
            let montesNo = rd.monthes.split(',').map((rm) => {
              return Number(rm)
            })
            this.monthes = montesNo
          } else {
            this.termMonth = null
          }
          if (rd.week || rd.week == '0') {
            this.termWeek = '1'
            this.week = utils.toDayOfWeek(rd.week)
          } else {
            this.termWeek = null
          }
        }
      }
    },

    /**
     * 初期化
     */
    closed() {
      
      // 表示データ
      this.list = [],
      this.monthes = [],
      this.week = '',
      this.termMonth = null,
      this.termWeek = null
    },

    /**
     * 登録処理
     */
    async save() {
      // 期間が何も選択されていない
      if (!this.monthes.length && (!this.week || this.week == '')) {
        let dlog = await dialogs.showConfirmDialog('期間選択なし', '期間が選択されていません。\r\nクレーム期間削除ということでよろしいですか？')
        if (dlog != 'YES') {
          return
        }
        await this.cancelClaim(false)
        return
      }

      // weight未入力
      if (!this.list.weight || isNaN(this.list.weight)) {
        let dlog = await dialogs.showConfirmDialog('Wight', 'Weightが未入力ですがよろしいですか？')
        if (dlog != 'YES') {
          return
        }
      }

      // 月の値をソート
      if (this.monthes.length) {
        this.monthes.sort(function (a, b) {
          return a - b
        })
      } else {
        this.termMonth = null
      }
      // 週の期間判定
      if (!this.week || this.week == '') {
        this.termWeek = null
      }

      // 月の場合
      let save = []
      if (this.termMonth) {
        for (let i = 0; i < this.monthes.length; i++) {
          const m = this.monthes[i]
          let startMonthes = null
          const endMonthes = m
          // 期間ごとの開始月
          if (i == 0) {
            startMonthes = this.monthes[this.monthes.length-1] + 1
            if (startMonthes == 13) {
              startMonthes = 1
            }
          } else {
            startMonthes = this.monthes[i-1] + 1
          }
          let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, people: this.list.people, termMonth: true, termWeek: false, startMonthes: startMonthes, endMonthes: endMonthes, week: null, insertUser: this.$store.getters.user.id }
          saveData.claim = claim
          saveData.type = this.type
          save.push(saveData)
        }
        await backend.postData('patrolTerm/save', save)
      
      // 週の場合
      } else {
        let saveData = { id: this.list.id, siteCode: this.list.siteCode, weight: this.list.weight, people: this.list.people, termMonth: false, termWeek: this.termWeek?true:false, startMonthes: null, endMonthes: null, week: this.week!='' ? utils.toWeekNumber(this.week) : null, insertUser: this.$store.getters.user.id }
        saveData.claim = claim
        saveData.type = this.type
        if (this.termWeek) {
          await backend.postData('patrolTerm/save', [saveData])
        }
      }
      // ログ出力
      await logManager.recordingByRegist(this, false, 'クレームサイクル設定', 'クレームサイクル設定（物件名：' + this.target.siteName + '、得意先名：' + this.target.clientName1 + '）')
      this.$emit('modalClose')
      this.closed()
    },

    /**
     * 月設定
     */
    setMonthes(value) {
      if (this.monthes.includes(value)) {
        let m = this.monthes.filter((mo => {
          return mo != value
        }))
        this.monthes = m
      } else {
        this.monthes.push(value)
        this.termMonth = '1'
      }
    },

    /**
     * 曜日設定
     */
    setWeek(value) {
      this.week = value
      this.termWeek = '1'
    },

    /**
     * クレームキャンセルボタン
     * @param dialog ダイアログ有無
     */
    async cancelClaim(dialog) {
      if (dialog) {
        let dlog = await dialogs.showConfirmDialog('クレーム期間削除', '削除してよろしいですか？')
        if (dlog != 'YES') {
          return
        }
      }
      // 既存の期間データを削除
      await backend.deleteDataByKey('patrolTerm/delete', { key: this.list.siteCode, claim, type: this.type }, false)
      this.$emit('modalClose')
      this.closed()
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-light-green-50 {
  background: rgb(250 255 250 / 71%);
}
@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

}
</style>