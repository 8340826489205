<template>
  <Badge v-if="text" :color="colorName">{{ text }}</Badge>
  <!-- <span v-if="text" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
    <svg class="mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="3" />
    </svg>
    {{ text }}
  </span> -->
</template>
<script>
import Badge from './Badge.vue'
export default {
  components: {
    Badge,
  },
  props: ['value', 'valueFunc', 'record', 'color', 'colorTable', 'name'],
  computed: {
    text() {
      const text = this.valueFunc ? this.valueFunc(this.record) : this.value
      return text
    },
    colorName() {
      if (this.colorTable) {
        return this.colorTable[this.text]
      } else {
        return this.color
      }
    },
    // tooltip() {
    //   const tip = this.record[this.name + 'Tooltip']
    //   return tip
    // }
  }
}
</script>