<template>
  <div class="flex-1 p-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-4 self-start">
    <div class="sm:col-span-full">
      <WmsTextInput 
        name="estimateNo" 
        caption="見積No" 
        :disabled="true"
        v-model="order.estimateNo"
      />
    </div>

    <div class="sm:col-span-4">
      <WmsTextInput
        name="caseName"
        caption="案件名" 
        v-model="order.caseName"
        :disabled="isDisabled || referenceMode"
      >
      </WmsTextInput>
    </div>

    <div class="sm:col-span-2" style="margin-top: auto" v-if="!isTaskRequestMode">
      <PrimaryButton text="見積参照" class="w-40" @click="referenceEstimate">
      </PrimaryButton>
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput 
        name="clientOnbuildingName" 
        caption="物件情報"
        v-model="order.buildingName"
        :multiline="true"
        :rows="3"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full pt-4">
      <WmsTextInput 
        name="orderMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.orderMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <WmsTextInput 
        name="orderSummary" 
        caption="登録内容"
        :multiline="true"
        :rows="5"
        v-model="order.orderSummary"
        disabled="true"
        v-if="order.orderSummary"
      />
    </div>

    <div class="sm:col-span-2">
      <WmsDateInput
        name="contractDate"
        caption="契約日" 
        v-model="order.contractDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        @change="changeContractDate($event)"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="contractDate" 
        caption="契約日"
        v-model="dispContractDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="sm:col-span-2">
      <WmsDateInput
        name="contractEndDate"
        caption="契約終了日"
        v-model="order.contractEndDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        :error="!validateContractEndDate.result"
        :errorMessage="validateContractEndDate.message"
        @change="changeContractEndDate($event)"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="contractEndDate" 
        caption="契約終了日"
        v-model="dispContractEndDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full">
      <OrderItemSelect 
        name="classification" 
        caption="区分"
        v-model="order.classification"
        :required="true"
        :error="!validateClassification.result"
        :errorMessage="validateClassification.message"
        :multiSelect="false"
        :item="selectItemClassification"
        @change="changeClassification($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <EstimationDetailsCategorySelect
        name="estimationDetailsCategory"
        caption="見積明細カテゴリー" 
        v-model="order.estimationDetailsCategory"
        @change="estimationDetailsCategorySelected($event)"
        :disabled="true"
        :categoryName="categoryName"
        :categoryCode="categoryCode"
      />
    </div>

    <InputGroupSubLabel
      name="estimationRoundLabel"
      caption="ラウンドサービス"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('1')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <OrderItemSelect 
        name="estimationRoundCount" 
        caption="清掃希望回数"
        v-model="order.estimationRoundCount"
        :item="selectCleanCountItemMonth"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsDateInput
        name="estimationRoundStartDate"
        caption="開始日" 
        v-model="order.estimationRoundStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="estimationRoundStartDate" 
        caption="開始日"
        v-model="dispEstimationRoundStartDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <OrderItemSelect
        name="roundServiceWorkDays"
        caption="作業曜日"
        v-model="order.roundServiceWorkDays"
        :item="selectItemWeek"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsTextInput 
        name="estimationRoundUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.estimationRoundUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsTextInput 
        name="estimationRoundAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.estimationRoundAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsTextInput 
        name="estimationRoundSupplier" 
        caption="発注先名" 
        v-model="order.estimationRoundSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsTextInput 
        name="estimationRoundPrice" 
        caption="発注金額" 
        v-model="order.estimationRoundPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <OrderItemSelect
        name="estimationRoundCommissionFlag"
        caption="手数料有無"
        v-model="order.estimationRoundCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1') && isShowCommissionFlagItem('estimationRoundCommissionFlag', '1')">
      <WmsTextInput 
        name="estimationRoundCommissionPrice" 
        caption="手数料金額" 
        v-model="order.estimationRoundCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('1')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="estimationRoundCommonFile" 
            caption="添付資料"
            :value="order.estimationRoundCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('estimationRoundCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('1')">
      <WmsTextInput 
        name="estimationRoundMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.estimationRoundMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="estimationRoundPlusLabel"
      caption="ラウンドプラス"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('2')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <OrderItemSelect 
        name="estimationRoundPlusCategorySelect" 
        v-model="order.estimationRoundPlusCategorySelect"
        :item="estimationRoundPlusCategorySelectItem"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <OrderItemSelect 
        name="estimationRoundPlusCount" 
        caption="清掃希望回数"
        v-model="order.estimationRoundPlusCount"
        :item="selectCleanCountItemMonth"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('2')">
      <WmsDateInput
        name="estimationRoundPlusStartDate"
        caption="開始日" 
        v-model="order.estimationRoundPlusStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="estimationRoundPlusStartDate" 
        caption="開始日"
        v-model="dispEstimationRoundPlusStartDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="sm:col-span-full">
      <div v-for="(rpWorkRemark, index) in order.estimationRoundPlusWorkRemarks" v-bind:key="rpWorkRemark.id" v-show="isShowEstimationDetailsCategoryItem('2')">
        <div class="sm:col-span-full" :class="{'mt-6': index > 0}">
          <label
            name="estimationRoundPlusContentsIndexLabel"
            class="text-sm font-bold"
          >
            【{{index + 1}}】
          </label>
        </div>

        <div class="sm:col-span-full">
          <OrderItemSelect
            name="estimationRoundPlusWorkDays"
            caption="作業曜日"
            v-model="rpWorkRemark.workDays"
            :item="selectItemWeek"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="flex mt-ec">
          <div class="startTime">
            <WmsTimeInput2
              name="estimationRoundPlusStartTime" 
              caption="作業開始時間"
              :clearable="true"
              v-model="rpWorkRemark.startTime"
              :hourItems="hourItems"
              :minuteItems="minuteItems"
              :disabled="isDisabled || referenceMode"
            />
          </div>

          <div class="endTime">
            <WmsTimeInput2
              name="estimationRoundPlusEndTime" 
              caption="作業終了時間"
              :clearable="true"
              v-model="rpWorkRemark.endTime"
              :hourItems="hourItems"
              :minuteItems="minuteItems"
              :disabled="isDisabled || referenceMode"
            />
          </div>
        </div>

        <div class="deleteButton" v-if="!referenceMode && !isDisabled">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeWorkRemarks(index, 'estimationRoundPlusWorkRemarks')">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationDetailsCategoryItem('2')" v-if="!referenceMode && !isDisabled">
      <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'作業曜日・時間追加'" @click="addWorkRemarks('estimationRoundPlusWorkRemarks', false)">
        <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
      </PrimaryButton>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <WmsTextInput 
        name="estimationRoundPlusUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.estimationRoundPlusUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <WmsTextInput 
        name="estimationRoundPlusAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.estimationRoundPlusAmount"
        :disabled="true"
      />
    </div>

    <!-- 新仕様のラウンドプラス発注明細の場合 2023.07.25から新仕様 -->
    <div v-if="order.estimationRoundPlusPurchase && order.estimationRoundPlusPurchase.length" class="sm:col-span-full mb-3">
      <div v-for="(row, i) in order.estimationRoundPlusPurchase" v-bind:key="i">
        <div v-show="isShowEstimationDetailsCategoryItem('2')" class="text-xs mt-3 font-bold text-gray-500">
          --- {{row.typeName}} ---
        </div>
        <div v-show="isShowEstimationDetailsCategoryItem('2')" class="mt-2">
          <WmsTextInput 
            name="RoundPlusPurchaseSupplier" 
            caption="発注先名" 
            v-model="row.supplier"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div v-show="isShowEstimationDetailsCategoryItem('2')" class="mt-2">
          <WmsTextInput 
            name="RoundPlusPurchasePrice" 
            caption="発注金額" 
            v-model="row.price"
            :disabled="true"
          />
        </div>
      </div>
    </div>

    <!-- 過去のラウンドプラス発注明細の場合 -->
    <div v-else class="sm:col-span-full">
      <div v-for="(rpSupplier, index) in order.estimationRoundPlusSupplier" v-bind:key="rpSupplier.id">
        <div v-show="isShowEstimationDetailsCategoryItem('2')">
          <WmsTextInput 
            name="estimationRoundPlusSupplier" 
            caption="発注先名" 
            v-model="rpSupplier.supplier"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="rpSupplierDeleteButton" v-show="isShowEstimationDetailsCategoryItem('2')" v-if="!referenceMode && !isDisabled">
          <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeRpSupplier(index)">
            <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>

      <div class="sm:col-span-full addButton-contents" v-show="isShowEstimationDetailsCategoryItem('2')" v-if="!referenceMode && !isDisabled">
        <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'発注先名追加'" @click="addRpSupplier()">
          <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
        </PrimaryButton>
      </div>

      <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
        <WmsTextInput 
          name="estimationRoundPlusPrice" 
          caption="発注金額" 
          v-model="order.estimationRoundPlusPrice"
          :disabled="true"
        />
      </div>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <OrderItemSelect
        name="estimationRoundPlusCommissionFlag"
        caption="手数料有無"
        v-model="order.estimationRoundPlusCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2') && isShowCommissionFlagItem('estimationRoundPlusCommissionFlag', '1')">
      <WmsTextInput 
        name="estimationRoundPlusCommissionPrice" 
        caption="手数料金額" 
        v-model="order.estimationRoundPlusCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('2')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="estimationRoundPlusCommonFile" 
            caption="添付資料"
            :value="order.estimationRoundPlusCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('estimationRoundPlusCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('2')">
      <WmsTextInput 
        name="estimationRoundPlusMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.estimationRoundPlusMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="estimationEverydayLabel"
      caption="日常清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('3')"
    />

    <div class="sm:col-span-full">
      <div v-for="(estimationEveryday, index) in order.orderEstimationEverydays" v-bind:key="estimationEveryday.id">
        <div class="sm:col-span-full" :class="{'mt-6': index > 0}" v-show="isShowEstimationDetailsCategoryItem('3')">
          <label
            name="everydayCleanContentsLabel"
            class="text-sm font-bold"
          >
            【{{estimationEveryday.everydayCleanContents}}】
          </label>
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
          <OrderItemSelect 
            name="estimationEverydayCount" 
            caption="清掃希望回数"
            v-model="estimationEveryday.estimationEverydayCount"
            :item="selectEstimationEverydayCountItemMonth"
            :disabled="true"
          />
        </div>

        <div class="mt-3 w-1/2" v-show="isShowEstimationDetailsCategoryItem('3')">
          <WmsDateInput
            name="everydayStartDate"
            caption="開始日" 
            v-model="estimationEveryday.everydayStartDate"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-if="!isTaskRequestMode && !referenceMode"
          />

          <WmsTextInput 
            name="everydayStartDate" 
            caption="開始日"
            v-model="estimationEveryday.dispEverydayStartDate"
            :disabled="true"
            v-if="isTaskRequestMode || referenceMode"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <div v-for="(ecWorkRemark, remarkIdx) in order.orderEstimationEverydays[index].everydayCleanWorkDays" v-bind:key="ecWorkRemark.id" v-show="isShowEstimationDetailsCategoryItem('3')">
            <div class="sm:col-span-full" :class="{'mt-6': remarkIdx > 0}">
              <label
                name="everydayCleanContentsIndexLabel"
                class="text-sm font-bold"
              >
                【{{remarkIdx + 1}}】
              </label>
            </div>

            <div class="sm:col-span-full">
              <OrderItemSelect
                name="everydayCleanWorkDays"
                caption="作業曜日"
                v-model="ecWorkRemark.workDays"
                :item="selectItemWeek"
                :disabled="isDisabled || referenceMode"
              />
            </div>

            <div class="flex mt-ec">
              <div class="startTime">
                <WmsTimeInput2
                  name="everydayCleanStartTime" 
                  caption="作業開始時間"
                  :clearable="true"
                  v-model="ecWorkRemark.startTime"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="isDisabled || referenceMode"
                />
              </div>

              <div class="endTime">
                <WmsTimeInput2
                  name="everydayCleanEndTime" 
                  caption="作業終了時間"
                  :clearable="true"
                  v-model="ecWorkRemark.endTime"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="isDisabled || referenceMode"
                />
              </div>
            </div>

            <div class="deleteButton" v-if="!referenceMode && !isDisabled">
              <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeWorkRemarks(index, 'everydayCleanWorkDays', remarkIdx, 'orderEstimationEverydays')">
                <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div class="sm:col-span-full addButton-contents mt-3" v-show="isShowEstimationDetailsCategoryItem('3')" v-if="!referenceMode && !isDisabled">
          <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'作業曜日・時間追加'" @click="addWorkRemarks('everydayCleanWorkDays', false, index, 'orderEstimationEverydays')">
            <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <WmsTextInput 
        name="estimationEverydayUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.estimationEverydayUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <WmsTextInput 
        name="estimationEverydayAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.estimationEverydayAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <WmsTextInput 
        name="estimationEverydaySupplier" 
        caption="発注先名" 
        v-model="order.estimationEverydaySupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <WmsTextInput 
        name="estimationEverydayPrice" 
        caption="発注金額" 
        v-model="order.estimationEverydayPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <OrderItemSelect
        name="estimationEverydayCommissionFlag"
        caption="手数料有無"
        v-model="order.estimationEverydayCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3') && isShowCommissionFlagItem('estimationEverydayCommissionFlag', '1')">
      <WmsTextInput 
        name="estimationEverydayCommissionPrice" 
        caption="手数料金額" 
        v-model="order.estimationEverydayCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('3')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="estimationEverydayCommonFile" 
            caption="添付資料"
            :value="order.estimationEverydayCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('estimationEverydayCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('3')">
      <WmsTextInput 
        name="estimationEverydayMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.estimationEverydayMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="managerWorkLabel"
      caption="管理員業務"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('4')"
    />

    <div class="sm:col-span-full">
      <div v-for="(managerWork, index) in order.orderManagerWorks" v-bind:key="managerWork.id">
        <div class="sm:col-span-full" :class="{'mt-6': index > 0}" v-show="isShowEstimationDetailsCategoryItem('4')">
          <label
            name="managerWorkContentsLabel"
            class="text-sm font-bold"
          >
            【{{managerWork.managerWorkContents}}】
          </label>
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
          <OrderItemSelect 
            name="managerWorkCount" 
            caption="清掃希望回数"
            v-model="managerWork.managerWorkCount"
            :item="selectManagerWorkCountItemMonth"
            :disabled="true"
          />
        </div>

        <div class="mt-3 w-1/2" v-show="isShowEstimationDetailsCategoryItem('4')">
          <WmsDateInput
            name="managerWorkStartDate"
            caption="開始日" 
            v-model="managerWork.managerWorkStartDate"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-if="!isTaskRequestMode && !referenceMode"
          />

          <WmsTextInput 
            name="managerWorkStartDate" 
            caption="開始日"
            v-model="managerWork.dispManagerWorkStartDate"
            :disabled="true"
            v-if="isTaskRequestMode || referenceMode"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <div v-for="(mwWorkRemark, remarkIdx) in order.orderManagerWorks[index].managerWorkDays" v-bind:key="mwWorkRemark.id" v-show="isShowEstimationDetailsCategoryItem('4')">
            <div class="sm:col-span-full" :class="{'mt-6': remarkIdx > 0}">
              <label
                name="managerContentsIndexLabel"
                class="text-sm font-bold"
              >
                【{{remarkIdx + 1}}】
              </label>
            </div>

            <div class="sm:col-span-full">
              <OrderItemSelect
                name="managerWorkDays"
                caption="作業曜日"
                v-model="mwWorkRemark.workDays"
                :item="selectItemWeek"
                :disabled="isDisabled || referenceMode"
              />
            </div>

            <div class="flex mt-ec">
              <div class="startTime">
                <WmsTimeInput2
                  name="managerStartTime" 
                  caption="作業開始時間"
                  :clearable="true"
                  v-model="mwWorkRemark.startTime"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="isDisabled || referenceMode"
                />
              </div>

              <div class="endTime">
                <WmsTimeInput2
                  name="managerEndTime" 
                  caption="作業終了時間"
                  :clearable="true"
                  v-model="mwWorkRemark.endTime"
                  :hourItems="hourItems"
                  :minuteItems="minuteItems"
                  :disabled="isDisabled || referenceMode"
                />
              </div>
            </div>

            <div class="deleteButton" v-if="!referenceMode && !isDisabled">
              <PrimaryButton class="bg-red-500" size="other" :buttonContents="'要'" v-tooltip="'削除'" @click="removeWorkRemarks(index, 'managerWorkDays', remarkIdx, 'orderManagerWorks')">
                <Icon slot="before" iconName="MinusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div class="sm:col-span-full addButton-contents mt-3" v-show="isShowEstimationDetailsCategoryItem('4')" v-if="!referenceMode && !isDisabled">
          <PrimaryButton class="bg-green-500" size="other" :buttonContents="'要'" v-tooltip="'作業曜日・時間追加'" @click="addWorkRemarks('managerWorkDays', false, index, 'orderManagerWorks')">
            <Icon slot="before" iconName="PlusCircle" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
          </PrimaryButton>
        </div>
      </div>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <WmsTextInput 
        name="managerWorkUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.managerWorkUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <WmsTextInput 
        name="managerWorkAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.managerWorkAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <WmsTextInput 
        name="managerWorkSupplier" 
        caption="発注先名" 
        v-model="order.managerWorkSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <WmsTextInput 
        name="managerWorkPrice" 
        caption="発注金額" 
        v-model="order.managerWorkPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <OrderItemSelect
        name="managerWorkCommissionFlag"
        caption="手数料有無"
        v-model="order.managerWorkCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4') && isShowCommissionFlagItem('managerWorkCommissionFlag', '1')">
      <WmsTextInput 
        name="managerWorkCommissionPrice" 
        caption="手数料金額" 
        v-model="order.managerWorkCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('4')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="managerWorkCommonFile" 
            caption="添付資料"
            :value="order.managerWorkCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('managerWorkCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('4')">
      <WmsTextInput 
        name="managerWorkMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.managerWorkMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="regularCleanLabel"
      caption="定期清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('5')"
    />

    <div class="sm:col-span-full">
      <div v-for="(regularClean, index) in order.orderRegularCleans" v-bind:key="regularClean.id">
        <div class="sm:col-span-full" :class="{'mt-6': index > 0}" v-show="isShowEstimationDetailsCategoryItem('5')">
          <label
            name="regularCleanContentsLabel"
            class="text-sm font-bold"
          >
            【{{regularClean.regularCleanContents}}】
          </label>
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <WmsTextInput 
            name="regularCleanCount" 
            caption="清掃希望回数"
            v-model="regularClean.dispRegularCleanCount"
            :disabled="true"
          />
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <WmsSuggestInput
            name="regularCleanFirstWorkYear" 
            caption="初回作業年" 
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="selectItemYear"
            displayField="text"
            :filter="{}"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-model="regularClean.regularCleanFirstWorkYear"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <OrderItemSelect 
            name="regularCleanFirstWorkMonth" 
            caption="初回作業月"
            v-model="regularClean.regularCleanFirstWorkMonth"
            :multiSelect="false"
            :item="selectItemMonth"
            @change="changeRegularCleanFirstWorkMonth($event, index)"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <OrderItemSelect 
            name="regularCleanImplementationMonth" 
            caption="実施月"
            v-model="regularClean.regularCleanImplementationMonth"
            :item="selectItemMonth"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <WmsTextInput 
            name="regularCleanUnitPrice" 
            caption="受注単価（税抜き）" 
            v-model="regularClean.regularCleanUnitPrice"
            :disabled="true"
          />
        </div>

        <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
          <WmsTextInput 
            name="regularCleanAmount" 
            caption="受注総額（税抜き）" 
            v-model="regularClean.regularCleanAmount"
            :disabled="true"
          />
        </div>

        <div class="flex grid grid-cols-6 gap-2">
          <div class="mt-3 col-span-3" v-show="isShowEstimationDetailsCategoryItem('5')">
            <WmsSuggestInput
              name="regularCleanTargetArea" 
              caption="対象箇所" 
              :dynamic="false"
              :incrementalSearch="false"
              :selectionItems="regularCleanTargetAreaSelectItem"
              displayField="text"
              :filter="{}"
              :readonly="isModileDevice"
              :clearable="isModileDevice"
              v-model="regularClean.regularCleanTargetArea"
              :disabled="isDisabled || referenceMode"
            />
          </div>

          <div class="mt-3 col-span-3" v-show="isShowEstimationDetailsCategoryItem('5')">
            <WmsSuggestInput
              name="regularCleanDescriptionWork" 
              caption="作業内容" 
              :dynamic="false"
              :incrementalSearch="false"
              :selectionItems="regularCleanDescriptionWorkSelectItem"
              displayField="text"
              :filter="{}"
              :readonly="isModileDevice"
              :clearable="isModileDevice"
              v-model="regularClean.regularCleanDescriptionWork"
              :disabled="isDisabled || referenceMode"
            />
          </div>
        </div>

        <div class="mt-3 sm:col-span-1" v-show="isShowEstimationDetailsCategoryItem('5')">
          <OrderItemSelect 
            name="regularCleanCarpet" 
            caption="カーペット"
            v-model="regularClean.regularCleanCarpet"
            :item="selectItemCarpet"
            :disabled="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
      <WmsTextInput 
        name="regularCleanSupplier" 
        caption="発注先名" 
        v-model="order.regularCleanSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
      <WmsTextInput 
        name="regularCleanPrice" 
        caption="発注金額" 
        v-model="order.regularCleanPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
      <OrderItemSelect
        name="regularCleanCommissionFlag"
        caption="手数料有無"
        v-model="order.regularCleanCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5') && isShowCommissionFlagItem('regularCleanCommissionFlag', '1')">
      <WmsTextInput 
        name="regularCleanCommissionPrice" 
        caption="手数料金額" 
        v-model="order.regularCleanCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('5')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="regularCleanCommonFile" 
            caption="添付資料"
            :value="order.regularCleanCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('regularCleanCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('5')">
      <WmsTextInput 
        name="regularCleanMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.regularCleanMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="frontSupportLabel"
      caption="フロントサポート"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('6')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <OrderItemSelect 
        name="frontSupportCount" 
        caption="清掃希望回数"
        v-model="order.frontSupportCount"
        :item="selectCleanCountItemMonth"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsDateInput
        name="frontSupportStartDate"
        caption="開始日" 
        v-model="order.frontSupportStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="frontSupportStartDate" 
        caption="開始日"
        v-model="dispFrontSupportStartDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsSuggestInput
        name="frontSupportFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.frontSupportFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <OrderItemSelect 
        name="frontSupportFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.frontSupportFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsTextInput 
        name="frontSupportUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.frontSupportUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsTextInput 
        name="frontSupportAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.frontSupportAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsTextInput 
        name="frontSupportSupplier" 
        caption="発注先名" 
        v-model="order.frontSupportSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsTextInput 
        name="frontSupportPrice" 
        caption="発注金額" 
        v-model="order.frontSupportPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <OrderItemSelect
        name="frontSupportCommissionFlag"
        caption="手数料有無"
        v-model="order.frontSupportCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6') && isShowCommissionFlagItem('frontSupportCommissionFlag', '1')">
      <WmsTextInput 
        name="frontSupportCommissionPrice" 
        caption="手数料金額" 
        v-model="order.frontSupportCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('6')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="frontSupportCommonFile" 
            caption="添付資料"
            :value="order.frontSupportCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('frontSupportCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('6')">
      <WmsTextInput 
        name="frontSupportMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.frontSupportMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="frontSupportPlusLabel"
      caption="フロントサポートプラス"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('7')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <OrderItemSelect 
        name="frontSupportPlusCount" 
        caption="清掃希望回数"
        v-model="order.frontSupportPlusCount"
        :item="selectCleanCountItemMonth"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsDateInput
        name="frontSupportPlusStartDate"
        caption="開始日" 
        v-model="order.frontSupportPlusStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="frontSupportPlusStartDate" 
        caption="開始日"
        v-model="dispFrontSupportPlusStartDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsSuggestInput
        name="frontSupportPlusFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.frontSupportPlusFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <OrderItemSelect 
        name="frontSupportPlusFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.frontSupportPlusFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsTextInput 
        name="frontSupportPlusUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.frontSupportPlusUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsTextInput 
        name="frontSupportPlusAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.frontSupportPlusAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsTextInput 
        name="frontSupportPlusSupplier" 
        caption="発注先名" 
        v-model="order.frontSupportPlusSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsTextInput 
        name="frontSupportPlusPrice" 
        caption="発注金額" 
        v-model="order.frontSupportPlusPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <OrderItemSelect
        name="frontSupportPlusCommissionFlag"
        caption="手数料有無"
        v-model="order.frontSupportPlusCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7') && isShowCommissionFlagItem('frontSupportPlusCommissionFlag', '1')">
      <WmsTextInput 
        name="frontSupportPlusCommissionPrice" 
        caption="手数料金額" 
        v-model="order.frontSupportPlusCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('7')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="frontSupportPlusCommonFile" 
            caption="添付資料"
            :value="order.frontSupportPlusCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('frontSupportPlusCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('7')">
      <WmsTextInput 
        name="frontSupportPlusMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.frontSupportPlusMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="roundTrashLabel"
      caption="ラウンドトラッシュ"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('8')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <OrderItemSelect 
        name="roundTrashCount" 
        caption="清掃希望回数"
        v-model="order.roundTrashCount"
        :item="selectCleanCountItemMonth"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsDateInput
        name="roundTrashStartDate"
        caption="開始日" 
        v-model="order.roundTrashStartDate"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-if="!isTaskRequestMode && !referenceMode"
      />

      <WmsTextInput 
        name="roundTrashStartDate" 
        caption="開始日"
        v-model="dispRoundTrashStartDate"
        :disabled="true"
        v-if="isTaskRequestMode || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsSuggestInput
        name="roundTrashFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.roundTrashFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <OrderItemSelect 
        name="roundTrashFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.roundTrashFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsTextInput 
        name="roundTrashUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.roundTrashUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsTextInput 
        name="roundTrashAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.roundTrashAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsTextInput 
        name="roundTrashSupplier" 
        caption="発注先名" 
        v-model="order.roundTrashSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsTextInput 
        name="roundTrashPrice" 
        caption="発注金額" 
        v-model="order.roundTrashPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <OrderItemSelect
        name="roundTrashCommissionFlag"
        caption="手数料有無"
        v-model="order.roundTrashCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8') && isShowCommissionFlagItem('roundTrashCommissionFlag', '1')">
      <WmsTextInput 
        name="roundTrashCommissionPrice" 
        caption="手数料金額" 
        v-model="order.roundTrashCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('8')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="roundTrashCommonFile" 
            caption="添付資料"
            :value="order.roundTrashCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('roundTrashCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('8')">
      <WmsTextInput 
        name="roundTrashMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.roundTrashMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="qualityReportLabel"
      caption="クオリティレポート"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('9')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportCount" 
        caption="清掃希望回数"
        v-model="dispQualityReportCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsSuggestInput
        name="qualityReportFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.qualityReportFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <OrderItemSelect 
        name="qualityReportFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.qualityReportFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.qualityReportUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.qualityReportAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportSupplier" 
        caption="発注先名" 
        v-model="order.qualityReportSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportPrice" 
        caption="発注金額" 
        v-model="order.qualityReportPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <OrderItemSelect
        name="qualityReportCommissionFlag"
        caption="手数料有無"
        v-model="order.qualityReportCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9') && isShowCommissionFlagItem('qualityReportCommissionFlag', '1')">
      <WmsTextInput 
        name="qualityReportCommissionPrice" 
        caption="手数料金額" 
        v-model="order.qualityReportCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('9')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="qualityReportCommonFile" 
            caption="添付資料"
            :value="order.qualityReportCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('qualityReportCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('9')">
      <WmsTextInput 
        name="qualityReportMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.qualityReportMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="glassCleanLabel"
      caption="ガラス清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('10')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanCount" 
        caption="清掃希望回数"
        v-model="dispGlassCleanCount"
        :disabled="true"
      />
    </div>
    
    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsSuggestInput
        name="glassCleanFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.glassCleanFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <OrderItemSelect 
        name="glassCleanFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.glassCleanFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeGlassCleanFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <OrderItemSelect 
        name="glassCleanImplementationMonth" 
        caption="実施月"
        v-model="order.glassCleanImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.glassCleanUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.glassCleanAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanSupplier" 
        caption="発注先名" 
        v-model="order.glassCleanSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanPrice" 
        caption="発注金額" 
        v-model="order.glassCleanPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <OrderItemSelect
        name="glassCleanCommissionFlag"
        caption="手数料有無"
        v-model="order.glassCleanCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10') && isShowCommissionFlagItem('glassCleanCommissionFlag', '1')">
      <WmsTextInput 
        name="glassCleanCommissionPrice" 
        caption="手数料金額" 
        v-model="order.glassCleanCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('10')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="glassCleanCommonFile" 
            caption="添付資料"
            :value="order.glassCleanCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('glassCleanCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('10')">
      <WmsTextInput 
        name="glassCleanMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.glassCleanMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="estimationDorainPipeLabel"
      caption="排水管清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('11')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipeCount" 
        caption="清掃希望回数"
        v-model="dispEstimationDorainPipeCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsSuggestInput
        name="estimationDorainPipeFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.estimationDorainPipeFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <OrderItemSelect 
        name="estimationDorainPipeFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.estimationDorainPipeFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeEstimationDorainPipeFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <OrderItemSelect 
        name="estimationDorainPipeImplementationMonth" 
        caption="実施月"
        v-model="order.estimationDorainPipeImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipeUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.estimationDorainPipeUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipeAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.estimationDorainPipeAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipeSupplier" 
        caption="発注先名" 
        v-model="order.estimationDorainPipeSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipePrice" 
        caption="発注金額" 
        v-model="order.estimationDorainPipePrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <OrderItemSelect
        name="estimationDorainPipeCommissionFlag"
        caption="手数料有無"
        v-model="order.estimationDorainPipeCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11') && isShowCommissionFlagItem('estimationDorainPipeCommissionFlag', '1')">
      <WmsTextInput 
        name="estimationDorainPipeCommissionPrice" 
        caption="手数料金額" 
        v-model="order.estimationDorainPipeCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('11')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="estimationDorainPipeCommonFile" 
            caption="添付資料"
            :value="order.estimationDorainPipeCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('estimationDorainPipeCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('11')">
      <WmsTextInput 
        name="estimationDorainPipeMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.estimationDorainPipeMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="estimationWaterTankLabel"
      caption="貯水槽清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('12')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankCount" 
        caption="清掃希望回数"
        v-model="dispEstimationWaterTankCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsSuggestInput
        name="estimationWaterTankFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.estimationWaterTankFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <OrderItemSelect 
        name="estimationWaterTankFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.estimationWaterTankFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeEstimationWaterTankFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <OrderItemSelect 
        name="estimationWaterTankImplementationMonth" 
        caption="実施月"
        v-model="order.estimationWaterTankImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.estimationWaterTankUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.estimationWaterTankAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankSupplier" 
        caption="発注先名" 
        v-model="order.estimationWaterTankSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankPrice" 
        caption="発注金額" 
        v-model="order.estimationWaterTankPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <OrderItemSelect
        name="estimationWaterTankCommissionFlag"
        caption="手数料有無"
        v-model="order.estimationWaterTankCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12') && isShowCommissionFlagItem('estimationWaterTankCommissionFlag', '1')">
      <WmsTextInput 
        name="estimationWaterTankCommissionPrice" 
        caption="手数料金額" 
        v-model="order.estimationWaterTankCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('12')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="estimationWaterTankCommonFile" 
            caption="添付資料"
            :value="order.estimationWaterTankCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('estimationWaterTankCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('12')">
      <WmsTextInput 
        name="estimationWaterTankMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.estimationWaterTankMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="plantingLabel"
      caption="植栽剪定"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('13')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingCount" 
        caption="清掃希望回数"
        v-model="dispPlantingCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsSuggestInput
        name="plantingFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.plantingFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <OrderItemSelect 
        name="plantingFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.plantingFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changePlantingFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <OrderItemSelect 
        name="plantingImplementationMonth" 
        caption="実施月"
        v-model="order.plantingImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.plantingUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.plantingAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingSupplier" 
        caption="発注先名" 
        v-model="order.plantingSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingPrice" 
        caption="発注金額" 
        v-model="order.plantingPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <OrderItemSelect
        name="plantingCommissionFlag"
        caption="手数料有無"
        v-model="order.plantingCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13') && isShowCommissionFlagItem('plantingCommissionFlag', '1')">
      <WmsTextInput 
        name="plantingCommissionPrice" 
        caption="手数料金額" 
        v-model="order.plantingCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('13')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="plantingCommonFile" 
            caption="添付資料"
            :value="order.plantingCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('plantingCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('13')">
      <WmsTextInput 
        name="plantingMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.plantingMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="specialCleanLabel"
      caption="特別清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('14')"
    />

    <!-- <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
      <label
        name="specialCleanLightingLabel"
        class="text-base font-bold"
      >
        【照明】
      </label>
    </div>

    <div class="sm:col-span-2" v-show="isShowEstimationDetailsCategoryItem('14')">
      <OrderItemSelect 
        name="specialCleanLighting"
        v-model="order.specialCleanLighting"
        :item="selectItemLighting"
        @change="changeSpecialCleanLighting($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <NumericTextInput2 
        name="lightingCleanCount" 
        caption="【照明】清掃希望回数"
        v-model="order.lightingCleanCount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <WmsSuggestInput
        name="lightingFirstWorkYear" 
        caption="【照明】初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.lightingFirstWorkYear"
        :disabled="isDisabled || referenceMode"
        @change="changeLightingFirstWorkYear($event)"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <OrderItemSelect 
        name="lightingFirstWorkMonth" 
        caption="【照明】初回作業月"
        v-model="order.lightingFirstWorkMonth"
        :item="selectItemMonth"
        @change="changeLightingFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <OrderItemSelect 
        name="lightingCleanImplementationMonth" 
        caption="【照明】実施月"
        v-model="order.lightingCleanImplementationMonth"
        :item="selectItemMonth"
        @change="changeLightingCleanImplementationMonth($event)"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <WmsTextInput 
        name="lightingCleanUnitPrice" 
        caption="【照明】受注単価（税抜き）" 
        v-model="order.lightingCleanUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <WmsTextInput 
        name="lightingCleanAmount" 
        caption="【照明】受注総額（税抜き）" 
        v-model="order.lightingCleanAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <WmsTextInput 
        name="lightingCleanSupplier" 
        caption="【照明】発注先名" 
        v-model="order.lightingCleanSupplier"
        :disabled="isDisabled || referenceMode"
        @change="changeLightingCleanSupplier($event)"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowspecialCleanLightingItem('照明')">
      <WmsTextInput 
        name="lightingCleanPrice" 
        caption="【照明】発注金額" 
        v-model="order.lightingCleanPrice"
        :disabled="true"
      />
    </div> -->

    <!-- <div class="mt-5 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
      <label
        name="specialCleanLabel"
        class="text-base font-bold"
      >
        【特別清掃】
      </label>
    </div> -->

    <div class="sm:col-span-full">
      <div v-for="(specialClean, index) in order.orderSpecialCleans" v-bind:key="specialClean.id">
        <div class="sm:col-span-full" :class="{'mt-6': index > 0}" v-show="isShowEstimationDetailsCategoryItem('14')">
          <label
            name="specialCleanContentsLabel"
            class="text-sm font-bold"
          >
            【{{specialClean.specialCleanContents}}】
          </label>
        </div>

        <div class="mt-osc select-form" v-show="isShowEstimationDetailsCategoryItem('14')">
          <div class="text-xs text-gray-600 font-bold">
            <label
              class="block text-xs font-bold text-gray-700"
            >
              作業項目 <span class="-top-0.5 ml-1 text-red-500"> * </span>
            </label>
            <div class="mt-1 flex relative">
              <InputIcon v-if="!validateSpecialCleanWorkItem(index).result" iconName="ExclamationCircle" :iconColor="'red'" :errorMessage="validateSpecialCleanWorkItem(index).message"/>
              <form>
                <select
                  name="specialCleanWorkItem" 
                  caption="作業項目" 
                  v-model="specialClean.specialCleanWorkItem"
                  :disabled="isDisabled || referenceMode"
                  @change="changeSpecialCleanWorkItem($event, index)"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-transparent w-full text-xs drop border-gray-300 "
                  :class="[isDisabled || referenceMode ? 'cursor-not-allowed' : '']"
                >
                  <option
                    v-for="workItem in workItemList"
                    :key='workItem.id'
                    :value="workItem.id"
                    class="bg-white">
                    {{ workItem.value }}
                  </option>
                </select>
              </form>
            </div>
          </div>
        </div>

        <div class="sm:col-span-full mt-osc" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsTextInput 
            name="specialCleanCount" 
            caption="清掃希望回数"
            v-model="specialClean.dispSpecialCleanCount"
            :disabled="true"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsSuggestInput
            name="specialCleanFirstWorkYear" 
            caption="初回作業年" 
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="selectItemYear"
            displayField="text"
            :filter="{}"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-model="specialClean.specialCleanFirstWorkYear"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <OrderItemSelect 
            name="specialCleanFirstWorkMonth" 
            caption="初回作業月"
            v-model="specialClean.specialCleanFirstWorkMonth"
            :multiSelect="false"
            :item="selectItemMonth"
            @change="changeSpecialCleanFirstWorkMonth($event, index)"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <OrderItemSelect 
            name="specialCleanImplementationMonth" 
            caption="実施月"
            v-model="specialClean.specialCleanImplementationMonth"
            :item="selectItemMonth"
            :disabled="isDisabled"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsTextInput 
            name="specialCleanUnitPrice" 
            caption="受注単価（税抜き）" 
            v-model="specialClean.specialCleanUnitPrice"
            :disabled="true"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsTextInput 
            name="specialCleanAmount" 
            caption="受注総額（税抜き）" 
            v-model="specialClean.specialCleanAmount"
            :disabled="true"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsTextInput 
            name="specialCleanSupplier" 
            caption="発注先名" 
            v-model="specialClean.specialCleanSupplier"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <WmsTextInput 
            name="specialCleanPrice" 
            caption="発注金額" 
            v-model="specialClean.specialCleanPrice"
            :disabled="true"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
          <OrderItemSelect
            name="specialCleanCommissionFlag"
            caption="手数料有無"
            v-model="specialClean.specialCleanCommissionFlag"
            :item="selectItemCommissionFlag"
            :disabled="true"
          />
        </div>

        <div class="mt-osc sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14') && isShowCommissionFlagItem('specialCleanCommissionFlag', '1', index, 'specialClean')">
          <WmsTextInput 
            name="specialCleanCommissionPrice" 
            caption="手数料金額" 
            v-model="specialClean.specialCleanCommissionPrice"
            :disabled="true"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('14')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="specialCleanCommonFile" 
            caption="添付資料"
            :value="order.specialCleanCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('specialCleanCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('14')">
      <WmsTextInput 
        name="specialCleanMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.specialCleanMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="fireInspectLabel"
      caption="消防設備点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('15')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectCount" 
        caption="清掃希望回数"
        v-model="dispFireInspectCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsSuggestInput
        name="fireInspectFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.fireInspectFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <OrderItemSelect 
        name="fireInspectFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.fireInspectFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeFireInspectFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <OrderItemSelect 
        name="fireInspectMonth" 
        caption="実施月"
        v-model="order.fireInspectMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.fireInspectUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.fireInspectAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectSupplier" 
        caption="発注先名" 
        v-model="order.fireInspectSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectPrice" 
        caption="発注金額" 
        v-model="order.fireInspectPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <OrderItemSelect
        name="fireInspectCommissionFlag"
        caption="手数料有無"
        v-model="order.fireInspectCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15') && isShowCommissionFlagItem('fireInspectCommissionFlag', '1')">
      <WmsTextInput 
        name="fireInspectCommissionPrice" 
        caption="手数料金額" 
        v-model="order.fireInspectCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('15')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="fireInspectCommonFile" 
            caption="添付資料"
            :value="order.fireInspectCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('fireInspectCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('15')">
      <WmsTextInput 
        name="fireInspectMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.fireInspectMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="constructionEquipmentInspectLabel"
      caption="建築設備定期検査"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('16')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectCount" 
        caption="清掃希望回数"
        v-model="dispConstructionEquipmentInspectCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsSuggestInput
        name="constructionEquipmentFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.constructionEquipmentFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <OrderItemSelect 
        name="constructionEquipmentInspectFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.constructionEquipmentInspectFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeConstructionEquipmentInspectFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <OrderItemSelect 
        name="constructionEquipmentInspectImplementationMonth" 
        caption="実施月"
        v-model="order.constructionEquipmentInspectImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.constructionEquipmentInspectUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.constructionEquipmentInspectAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectSupplier" 
        caption="発注先名" 
        v-model="order.constructionEquipmentInspectSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectPrice" 
        caption="発注金額" 
        v-model="order.constructionEquipmentInspectPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <OrderItemSelect
        name="constructionEquipmentInspectCommissionFlag"
        caption="手数料有無"
        v-model="order.constructionEquipmentInspectCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16') && isShowCommissionFlagItem('constructionEquipmentInspectCommissionFlag', '1')">
      <WmsTextInput 
        name="constructionEquipmentInspectCommissionPrice" 
        caption="手数料金額" 
        v-model="order.constructionEquipmentInspectCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('16')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="constructionEquipmentInspectCommonFile" 
            caption="添付資料"
            :value="order.constructionEquipmentInspectCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('constructionEquipmentInspectCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('16')">
      <WmsTextInput 
        name="constructionEquipmentInspectMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.constructionEquipmentInspectMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="specificInspectLabel"
      caption="特定建築物定期調査"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('17')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectCount" 
        caption="清掃希望回数"
        v-model="dispSpecificInspectCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsSuggestInput
        name="specificInspectFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.specificInspectFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <OrderItemSelect 
        name="specificInspectFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.specificInspectFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeSpecificInspectFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <OrderItemSelect 
        name="specificInspectImplementationMonth" 
        caption="実施月"
        v-model="order.specificInspectImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.specificInspectUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.specificInspectAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectSupplier" 
        caption="発注先名" 
        v-model="order.specificInspectSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectPrice" 
        caption="発注金額" 
        v-model="order.specificInspectPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <OrderItemSelect
        name="specificInspectCommissionFlag"
        caption="手数料有無"
        v-model="order.specificInspectCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17') && isShowCommissionFlagItem('specificInspectCommissionFlag', '1')">
      <WmsTextInput 
        name="specificInspectCommissionPrice" 
        caption="手数料金額" 
        v-model="order.specificInspectCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('17')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="specificInspectCommonFile" 
            caption="添付資料"
            :value="order.specificInspectCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('specificInspectCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('17')">
      <WmsTextInput 
        name="specificInspectMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.specificInspectMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="commonAreaFacilityInspectionLabel"
      caption="共用部設備点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('18')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionCount" 
        caption="清掃希望回数"
        v-model="dispCommonAreaFacilityInspectionCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsSuggestInput
        name="commonAreaFacilityInspectionFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.commonAreaFacilityInspectionFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <OrderItemSelect 
        name="commonAreaFacilityInspectionFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.commonAreaFacilityInspectionFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.commonAreaFacilityInspectionUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.commonAreaFacilityInspectionAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionSupplier" 
        caption="発注先名" 
        v-model="order.commonAreaFacilityInspectionSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionPrice" 
        caption="発注金額" 
        v-model="order.commonAreaFacilityInspectionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <OrderItemSelect
        name="commonAreaFacilityInspectionCommissionFlag"
        caption="手数料有無"
        v-model="order.commonAreaFacilityInspectionCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18') && isShowCommissionFlagItem('commonAreaFacilityInspectionCommissionFlag', '1')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionCommissionPrice" 
        caption="手数料金額" 
        v-model="order.commonAreaFacilityInspectionCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('18')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="commonAreaFacilityInspectionCommonFile" 
            caption="添付資料"
            :value="order.commonAreaFacilityInspectionCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('commonAreaFacilityInspectionCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('18')">
      <WmsTextInput 
        name="commonAreaFacilityInspectionMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.commonAreaFacilityInspectionMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="evInspectLabel"
      caption="エレベーター保守点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('19')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectCount" 
        caption="清掃希望回数"
        v-model="dispEvInspectCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsSuggestInput
        name="evInspectFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.evInspectFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <OrderItemSelect 
        name="evInspectFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.evInspectFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <OrderItemSelect 
        name="evInspectImplementationMonth" 
        caption="実施月"
        v-model="order.evInspectImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.evInspectUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.evInspectAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectSupplier" 
        caption="発注先名" 
        v-model="order.evInspectSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectPrice" 
        caption="発注金額" 
        v-model="order.evInspectPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <OrderItemSelect
        name="evInspectCommissionFlag"
        caption="手数料有無"
        v-model="order.evInspectCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19') && isShowCommissionFlagItem('evInspectCommissionFlag', '1')">
      <WmsTextInput 
        name="evInspectCommissionPrice" 
        caption="手数料金額" 
        v-model="order.evInspectCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('19')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="evInspectCommonFile" 
            caption="添付資料"
            :value="order.evInspectCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('evInspectCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('19')">
      <WmsTextInput 
        name="evInspectMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.evInspectMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="pressPumpInspectLabel"
      caption="増圧ポンプ点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('20')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectCount" 
        caption="清掃希望回数"
        v-model="dispPressPumpInspectCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsSuggestInput
        name="pressPumpInspectFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.pressPumpInspectFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <OrderItemSelect 
        name="pressPumpInspectFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.pressPumpInspectFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changePressPumpInspectFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <OrderItemSelect 
        name="pressPumpInspectImplementationMonth" 
        caption="実施月"
        v-model="order.pressPumpInspectImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.pressPumpInspectUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.pressPumpInspectAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectSupplier" 
        caption="発注先名" 
        v-model="order.pressPumpInspectSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectPrice" 
        caption="発注金額" 
        v-model="order.pressPumpInspectPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <OrderItemSelect
        name="pressPumpInspectCommissionFlag"
        caption="手数料有無"
        v-model="order.pressPumpInspectCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20') && isShowCommissionFlagItem('pressPumpInspectCommissionFlag', '1')">
      <WmsTextInput 
        name="pressPumpInspectCommissionPrice" 
        caption="手数料金額" 
        v-model="order.pressPumpInspectCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('20')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="pressPumpInspectCommonFile" 
            caption="添付資料"
            :value="order.pressPumpInspectCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('pressPumpInspectCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('20')">
      <WmsTextInput 
        name="pressPumpInspectMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.pressPumpInspectMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="waterSupplyDrainagePumpInspectionLabel"
      caption="給排水ポンプ点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('21')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionCount" 
        caption="清掃希望回数"
        v-model="dispWaterSupplyDrainagePumpInspectionCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsSuggestInput
        name="waterSupplyDrainagePumpInspectionFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.waterSupplyDrainagePumpInspectionFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <OrderItemSelect 
        name="waterSupplyDrainagePumpInspectionFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.waterSupplyDrainagePumpInspectionFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeWaterSupplyDrainagePumpInspectionFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <OrderItemSelect 
        name="waterSupplyDrainagePumpInspectionImplementationMonth" 
        caption="実施月"
        v-model="order.waterSupplyDrainagePumpInspectionImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.waterSupplyDrainagePumpInspectionUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.waterSupplyDrainagePumpInspectionAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionSupplier" 
        caption="発注先名" 
        v-model="order.waterSupplyDrainagePumpInspectionSupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionPrice" 
        caption="発注金額" 
        v-model="order.waterSupplyDrainagePumpInspectionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <OrderItemSelect
        name="waterSupplyDrainagePumpInspectionCommissionFlag"
        caption="手数料有無"
        v-model="order.waterSupplyDrainagePumpInspectionCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21') && isShowCommissionFlagItem('waterSupplyDrainagePumpInspectionCommissionFlag', '1')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionCommissionPrice" 
        caption="手数料金額" 
        v-model="order.waterSupplyDrainagePumpInspectionCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('21')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="waterSupplyDrainagePumpInspectionCommonFile" 
            caption="添付資料"
            :value="order.waterSupplyDrainagePumpInspectionCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('waterSupplyDrainagePumpInspectionCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('21')">
      <WmsTextInput 
        name="waterSupplyDrainagePumpInspectionMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.waterSupplyDrainagePumpInspectionMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="simpleWaterSupplyLabel"
      caption="簡易専用水道"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('22')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplyCount" 
        caption="清掃希望回数"
        v-model="dispSimpleWaterSupplyCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsSuggestInput
        name="simpleWaterSupplyFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.simpleWaterSupplyFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <OrderItemSelect 
        name="simpleWaterSupplyFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.simpleWaterSupplyFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeSimpleWaterSupplyFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <OrderItemSelect 
        name="simpleWaterSupplyImplementationMonth" 
        caption="実施月"
        v-model="order.simpleWaterSupplyImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplyUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.simpleWaterSupplyUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplyAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.simpleWaterSupplyAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplySupplier" 
        caption="発注先名" 
        v-model="order.simpleWaterSupplySupplier"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplyPrice" 
        caption="発注金額" 
        v-model="order.simpleWaterSupplyPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <OrderItemSelect
        name="simpleWaterSupplyCommissionFlag"
        caption="手数料有無"
        v-model="order.simpleWaterSupplyCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22') && isShowCommissionFlagItem('simpleWaterSupplyCommissionFlag', '1')">
      <WmsTextInput 
        name="simpleWaterSupplyCommissionPrice" 
        caption="手数料金額" 
        v-model="order.simpleWaterSupplyCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('22')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="simpleWaterSupplyCommonFile" 
            caption="添付資料"
            :value="order.simpleWaterSupplyCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('simpleWaterSupplyCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('22')">
      <WmsTextInput 
        name="simpleWaterSupplyMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.simpleWaterSupplyMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="tubeBulbSupportLabel"
      caption="管球対応"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('23')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportCount" 
        caption="清掃希望回数"
        v-model="dispTubeBulbSupportCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsSuggestInput
        name="tubeBulbSupportFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.tubeBulbSupportFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <OrderItemSelect 
        name="tubeBulbSupportFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.tubeBulbSupportFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeTubeBulbSupportFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <OrderItemSelect 
        name="tubeBulbSupportImplementationMonth" 
        caption="実施月"
        v-model="order.tubeBulbSupportImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.tubeBulbSupportUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.tubeBulbSupportAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportSupplier" 
        caption="発注先名" 
        v-model="order.tubeBulbSupportSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportPrice" 
        caption="発注金額" 
        v-model="order.tubeBulbSupportPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <OrderItemSelect
        name="tubeBulbSupportCommissionFlag"
        caption="手数料有無"
        v-model="order.tubeBulbSupportCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23') && isShowCommissionFlagItem('tubeBulbSupportCommissionFlag', '1')">
      <WmsTextInput 
        name="tubeBulbSupportCommissionPrice" 
        caption="手数料金額" 
        v-model="order.tubeBulbSupportCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('23')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="tubeBulbSupportCommonFile" 
            caption="添付資料"
            :value="order.tubeBulbSupportCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('tubeBulbSupportCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('23')">
      <WmsTextInput 
        name="tubeBulbSupportMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.tubeBulbSupportMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="bulkyWasteSupportLabel"
      caption="粗大ごみ対応"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('24')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportCount" 
        caption="清掃希望回数"
        v-model="dispBulkyWasteSupportCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsSuggestInput
        name="bulkyWasteSupportFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.bulkyWasteSupportFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <OrderItemSelect 
        name="bulkyWasteSupportFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.bulkyWasteSupportFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeBulkyWasteSupportFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <OrderItemSelect 
        name="bulkyWasteSupportImplementationMonth" 
        caption="実施月"
        v-model="order.bulkyWasteSupportImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.bulkyWasteSupportUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.bulkyWasteSupportAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportSupplier" 
        caption="発注先名" 
        v-model="order.bulkyWasteSupportSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportPrice" 
        caption="発注金額" 
        v-model="order.bulkyWasteSupportPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <OrderItemSelect
        name="bulkyWasteSupportCommissionFlag"
        caption="手数料有無"
        v-model="order.bulkyWasteSupportCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24') && isShowCommissionFlagItem('bulkyWasteSupportCommissionFlag', '1')">
      <WmsTextInput 
        name="bulkyWasteSupportCommissionPrice" 
        caption="手数料金額" 
        v-model="order.bulkyWasteSupportCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('24')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="bulkyWasteSupportCommonFile" 
            caption="添付資料"
            :value="order.bulkyWasteSupportCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('bulkyWasteSupportCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('24')">
      <WmsTextInput 
        name="bulkyWasteSupportMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.bulkyWasteSupportMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="merchandiseSalesLabel"
      caption="物品販売"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('25')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesCount" 
        caption="清掃希望回数"
        v-model="dispMerchandiseSalesCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsSuggestInput
        name="merchandiseSalesFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.merchandiseSalesFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <OrderItemSelect 
        name="merchandiseSalesFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.merchandiseSalesFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeMerchandiseSalesFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <OrderItemSelect 
        name="merchandiseSalesImplementationMonth" 
        caption="実施月"
        v-model="order.merchandiseSalesImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.merchandiseSalesUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.merchandiseSalesAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesSupplier" 
        caption="発注先名" 
        v-model="order.merchandiseSalesSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesPrice" 
        caption="発注金額" 
        v-model="order.merchandiseSalesPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <OrderItemSelect
        name="merchandiseSalesCommissionFlag"
        caption="手数料有無"
        v-model="order.merchandiseSalesCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25') && isShowCommissionFlagItem('merchandiseSalesCommissionFlag', '1')">
      <WmsTextInput 
        name="merchandiseSalesCommissionPrice" 
        caption="手数料金額" 
        v-model="order.merchandiseSalesCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('25')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="merchandiseSalesCommonFile" 
            caption="添付資料"
            :value="order.merchandiseSalesCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('merchandiseSalesCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('25')">
      <WmsTextInput 
        name="merchandiseSalesMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.merchandiseSalesMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="emergencyResponseLabel"
      caption="緊急対応"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('26')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponseCount" 
        caption="清掃希望回数"
        v-model="dispEmergencyResponseCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsSuggestInput
        name="emergencyResponseFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.emergencyResponseFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <OrderItemSelect 
        name="emergencyResponseFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.emergencyResponseFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        @change="changeEmergencyResponseFirstWorkMonth($event)"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <OrderItemSelect 
        name="emergencyResponseImplementationMonth" 
        caption="実施月"
        v-model="order.emergencyResponseImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponseUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.emergencyResponseUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponseAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.emergencyResponseAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponseSupplier" 
        caption="発注先名" 
        v-model="order.emergencyResponseSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponsePrice" 
        caption="発注金額" 
        v-model="order.emergencyResponsePrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <OrderItemSelect
        name="emergencyResponseCommissionFlag"
        caption="手数料有無"
        v-model="order.emergencyResponseCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26') && isShowCommissionFlagItem('emergencyResponseCommissionFlag', '1')">
      <WmsTextInput 
        name="emergencyResponseCommissionPrice" 
        caption="手数料金額" 
        v-model="order.emergencyResponseCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('26')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="emergencyResponseCommonFile" 
            caption="添付資料"
            :value="order.emergencyResponseCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('emergencyResponseCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('26')">
      <WmsTextInput 
        name="emergencyResponseMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.emergencyResponseMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="firePreventionObjectInspectionLabel"
      caption="防火対象物点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('27')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="dispfirePreventionObjectInspectionCount" 
        caption="清掃希望回数"
        v-model="order.dispFirePreventionObjectInspectionCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsSuggestInput
        name="firePreventionObjectInspectionFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.firePreventionObjectInspectionFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <OrderItemSelect 
        name="firePreventionObjectInspectionFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.firePreventionObjectInspectionFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <OrderItemSelect 
        name="firePreventionObjectInspectionImplementationMonth" 
        caption="実施月"
        v-model="order.firePreventionObjectInspectionImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="firePreventionObjectInspectionUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.firePreventionObjectInspectionUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="firePreventionObjectInspectionAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.firePreventionObjectInspectionAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="firePreventionObjectInspectionSupplier" 
        caption="発注先名" 
        v-model="order.firePreventionObjectInspectionSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="firePreventionObjectInspectionPrice" 
        caption="発注金額" 
        v-model="order.firePreventionObjectInspectionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <OrderItemSelect
        name="firePreventionObjectInspectionCommissionFlag"
        caption="手数料有無"
        v-model="order.firePreventionObjectInspectionCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27') && isShowCommissionFlagItem('firePreventionObjectInspectionCommissionFlag', '1')">
      <WmsTextInput 
        name="firePreventionObjectInspectionCommissionPrice" 
        caption="手数料金額" 
        v-model="order.firePreventionObjectInspectionCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('27')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="firePreventionObjectInspectionCommonFile" 
            caption="添付資料"
            :value="order.firePreventionObjectInspectionCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('firePreventionObjectInspectionCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('27')">
      <WmsTextInput 
        name="firePreventionObjectInspectionMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.firePreventionObjectInspectionMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="sewageTankCleaningLabel"
      caption="汚水槽清掃"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('101')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="dispSewageTankCleaningCount" 
        caption="清掃希望回数"
        v-model="order.dispSewageTankCleaningCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsSuggestInput
        name="sewageTankCleaningFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.sewageTankCleaningFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <OrderItemSelect 
        name="sewageTankCleaningFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.sewageTankCleaningFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <OrderItemSelect 
        name="sewageTankCleaningImplementationMonth" 
        caption="実施月"
        v-model="order.sewageTankCleaningImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="sewageTankCleaningUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.sewageTankCleaningUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="sewageTankCleaningAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.sewageTankCleaningAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="sewageTankCleaningSupplier" 
        caption="発注先名" 
        v-model="order.sewageTankCleaningSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="sewageTankCleaningPrice" 
        caption="発注金額" 
        v-model="order.sewageTankCleaningPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <OrderItemSelect
        name="sewageTankCleaningCommissionFlag"
        caption="手数料有無"
        v-model="order.sewageTankCleaningCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101') && isShowCommissionFlagItem('sewageTankCleaningCommissionFlag', '1')">
      <WmsTextInput 
        name="sewageTankCleaningCommissionPrice" 
        caption="手数料金額" 
        v-model="order.sewageTankCleaningCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('101')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="sewageTankCleaningCommonFile" 
            caption="添付資料"
            :value="order.sewageTankCleaningCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('sewageTankCleaningCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('101')">
      <WmsTextInput 
        name="sewageTankCleaningMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.sewageTankCleaningMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="fireProtectionInspectionLabel"
      caption="防火設備定期検査"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('102')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="dispFireProtectionInspectionCount" 
        caption="清掃希望回数"
        v-model="order.dispFireProtectionInspectionCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsSuggestInput
        name="fireProtectionInspectionFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.fireProtectionInspectionFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <OrderItemSelect 
        name="fireProtectionInspectionFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.fireProtectionInspectionFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <OrderItemSelect 
        name="fireProtectionInspectionImplementationMonth" 
        caption="実施月"
        v-model="order.fireProtectionInspectionImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="fireProtectionInspectionUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.fireProtectionInspectionUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="fireProtectionInspectionAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.fireProtectionInspectionAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="fireProtectionInspectionSupplier" 
        caption="発注先名" 
        v-model="order.fireProtectionInspectionSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="fireProtectionInspectionPrice" 
        caption="発注金額" 
        v-model="order.fireProtectionInspectionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <OrderItemSelect
        name="fireProtectionInspectionCommissionFlag"
        caption="手数料有無"
        v-model="order.fireProtectionInspectionCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102') && isShowCommissionFlagItem('fireProtectionInspectionCommissionFlag', '1')">
      <WmsTextInput 
        name="fireProtectionInspectionCommissionPrice" 
        caption="手数料金額" 
        v-model="order.fireProtectionInspectionCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('102')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="fireProtectionInspectionCommonFile" 
            caption="添付資料"
            :value="order.fireProtectionInspectionCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('fireProtectionInspectionCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('102')">
      <WmsTextInput 
        name="fireProtectionInspectionMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.fireProtectionInspectionMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <InputGroupSubLabel
      name="connectingWaterPipePressureInspectionLabel"
      caption="連結送水管耐圧性能点検"
      class="mt-4 sm:col-span-full"
      v-show="isShowEstimationDetailsCategoryItem('103')"
    />

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="dispConnectingWaterPipePressureInspectionCount" 
        caption="清掃希望回数"
        v-model="order.dispConnectingWaterPipePressureInspectionCount"
        :disabled="true"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsSuggestInput
        name="connectingWaterPipePressureInspectionFirstWorkYear" 
        caption="初回作業年" 
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="selectItemYear"
        displayField="text"
        :filter="{}"
        :readonly="isModileDevice"
        :clearable="isModileDevice"
        v-model="order.connectingWaterPipePressureInspectionFirstWorkYear"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="mt-3 sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <OrderItemSelect 
        name="connectingWaterPipePressureInspectionFirstWorkMonth" 
        caption="初回作業月"
        v-model="order.connectingWaterPipePressureInspectionFirstWorkMonth"
        :multiSelect="false"
        :item="selectItemMonth"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <OrderItemSelect 
        name="connectingWaterPipePressureInspectionImplementationMonth" 
        caption="実施月"
        v-model="order.connectingWaterPipePressureInspectionImplementationMonth"
        :item="selectItemMonth"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionUnitPrice" 
        caption="受注単価（税抜き）" 
        v-model="order.connectingWaterPipePressureInspectionUnitPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionAmount" 
        caption="受注総額（税抜き）" 
        v-model="order.connectingWaterPipePressureInspectionAmount"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionSupplier" 
        caption="発注先名" 
        v-model="order.connectingWaterPipePressureInspectionSupplier"
        :disabled="isDisabled"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionPrice" 
        caption="発注金額" 
        v-model="order.connectingWaterPipePressureInspectionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <OrderItemSelect
        name="connectingWaterPipePressureInspectionCommissionFlag"
        caption="手数料有無"
        v-model="order.connectingWaterPipePressureInspectionCommissionFlag"
        :item="selectItemCommissionFlag"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103') && isShowCommissionFlagItem('connectingWaterPipePressureInspectionCommissionFlag', '1')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionCommissionPrice" 
        caption="手数料金額" 
        v-model="order.connectingWaterPipePressureInspectionCommissionPrice"
        :disabled="true"
      />
    </div>

    <div class="sm:col-span-full" v-show="isShowEstimationDetailsCategoryItem('103')">
      <label class="block text-xs font-bold text-gray-700">
        添付資料
      </label>
      <div class="mt-1">
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
          <WmsAttachment 
            name="connectingWaterPipePressureInspectionCommonFile" 
            caption="添付資料"
            :value="order.connectingWaterPipePressureInspectionCommonFile" 
            style="max-width: none!important;"
            @change="attachmentChange('connectingWaterPipePressureInspectionCommonFile', $event)"
            :disabled="isDisabled || referenceMode"
            :readonly="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full"  v-show="isShowEstimationDetailsCategoryItem('103')">
      <WmsTextInput 
        name="connectingWaterPipePressureInspectionMemo" 
        caption="メモ"
        :multiline="true"
        :rows="5"
        v-model="order.connectingWaterPipePressureInspectionMemo"
        :disabled="isDisabled || referenceMode"
      />
    </div>

    <!-- スポット清掃 -->
    <div class="sm:col-span-full"  v-show="order.orderSpot && order.orderSpot.length">
      <div v-for="(s, i) in order.orderSpot" v-bind:key="s.id">
        <InputGroupSubLabel
          name="spotLabel"
          :caption="s.name"
          class="mt-4 sm:col-span-full"
        />

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="spotCount" 
            caption="清掃希望回数"
            v-model="s.dispCount"
            :disabled="true"
          />
        </div>

        <div class="mt-3 sm:col-span-full">
          <WmsSuggestInput
            name="spotFirstWorkYear" 
            caption="初回作業年" 
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="selectItemYear"
            displayField="text"
            :filter="{}"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-model="s.firstWorkYear"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-3 sm:col-span-full">
          <OrderItemSelect 
            name="spotFirstWorkMonth" 
            caption="初回作業月"
            v-model="s.firstWorkMonth"
            :multiSelect="false"
            :item="selectItemMonth"
            :disabled="isDisabled || referenceMode"
            @change="changeSpotFirstWorkMont($event, i)"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <OrderItemSelect 
            name="spotImplementationMonth" 
            caption="実施月"
            v-model="s.implementationMonth"
            :item="selectItemMonth"
            :disabled="isDisabled"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="spotUnitPrice" 
            caption="受注単価（税抜き）" 
            v-model="s.unitPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="spotAmount" 
            caption="受注総額（税抜き）" 
            v-model="s.amount"
            :disabled="true"
          />
        </div>
        
        <div class="flex grid grid-cols-6 gap-2">
          <div class="mt-3 col-span-3">
            <WmsSuggestInput
              name="spotArea" 
              caption="対象箇所" 
              :dynamic="false"
              :incrementalSearch="false"
              :selectionItems="regularCleanTargetAreaSelectItem"
              displayField="text"
              :filter="{}"
              :readonly="isModileDevice"
              :clearable="isModileDevice"
              v-model="s.spotArea"
              :disabled="isDisabled || referenceMode"
            />
          </div>

          <div class="mt-3 col-span-3">
            <WmsSuggestInput
              name="spotContent" 
              caption="作業内容" 
              :dynamic="false"
              :incrementalSearch="false"
              :selectionItems="regularCleanDescriptionWorkSelectItem"
              displayField="text"
              :filter="{}"
              :readonly="isModileDevice"
              :clearable="isModileDevice"
              v-model="s.spotContent"
              :disabled="isDisabled || referenceMode"
            />
          </div>
        </div>

        <div class="mt-3 sm:col-span-1">
          <OrderItemSelect 
            name="spotCleanCarpet" 
            caption="カーペット"
            v-model="s.spotCarpet"
            :item="selectItemCarpet"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="spotSupplier" 
            caption="発注先名" 
            v-model="s.supplier"
            :disabled="isDisabled"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="spotPrice" 
            caption="発注金額" 
            v-model="s.orderPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <OrderItemSelect
            name="spotCommissionFlag"
            caption="手数料有無"
            v-model="s.commissionFlag"
            :item="selectItemCommissionFlag"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3" v-show="isShowCommissionFlagItem('commissionFlag', '1', i, 'spot')">
          <WmsTextInput 
            name="spotCommissionPrice" 
            caption="手数料金額" 
            v-model="s.commissionPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3" v-if="i<10">
          <label class="block text-xs font-bold text-gray-700">
            添付資料
          </label>
          <div class="mt-1">
            <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
              <WmsAttachment 
                name="spotCommonFile" 
                caption="添付資料"
                :value="order[`spotCommonFile${i+1}`]" 
                style="max-width: none!important;"
                @change="attachmentChange(`spotCommonFile${i+1}`, $event)"
                :disabled="isDisabled || referenceMode"
                :readonly="isDisabled || referenceMode"
              />
            </div>
          </div>
        </div>

        <div class="sm:col-span-full mt-3" >
          <WmsTextInput 
            name="spotMemo" 
            caption="メモ"
            :multiline="true"
            :rows="5"
            v-model="s.memo"
            :disabled="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <!-- カテゴリコードがないもの -->
    <div class="sm:col-span-full"  v-show="order.orderOthers && order.orderOthers.length">
      <div v-for="(o, i) in order.orderOthers" v-bind:key="o.id">
        <InputGroupSubLabel
          name="otherLabel"
          :caption="o.name"
          class="mt-4 sm:col-span-full"
        />

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="otherCount" 
            caption="清掃希望回数"
            v-model="o.dispCount"
            :disabled="true"
          />
        </div>

        <div class="mt-3 sm:col-span-full">
          <WmsSuggestInput
            name="otherFirstWorkYear" 
            caption="初回作業年" 
            :dynamic="false"
            :incrementalSearch="false"
            :selectionItems="selectItemYear"
            displayField="text"
            :filter="{}"
            :readonly="isModileDevice"
            :clearable="isModileDevice"
            v-model="o.firstWorkYear"
            :disabled="isDisabled || referenceMode"
          />
        </div>

        <div class="mt-3 sm:col-span-full">
          <OrderItemSelect 
            name="otherFirstWorkMonth" 
            caption="初回作業月"
            v-model="o.firstWorkMonth"
            :multiSelect="false"
            :item="selectItemMonth"
            :disabled="isDisabled || referenceMode"
            @change="changeOtherFirstWorkMonth($event, i)"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <OrderItemSelect 
            name="otherImplementationMonth" 
            caption="実施月"
            v-model="o.implementationMonth"
            :item="selectItemMonth"
            :disabled="isDisabled"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="otherUnitPrice" 
            caption="受注単価（税抜き）" 
            v-model="o.unitPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="otherAmount" 
            caption="受注総額（税抜き）" 
            v-model="o.amount"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="otherSupplier" 
            caption="発注先名" 
            v-model="o.supplier"
            :disabled="isDisabled"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <WmsTextInput 
            name="otherPrice" 
            caption="発注金額" 
            v-model="o.orderPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3">
          <OrderItemSelect
            name="otherCommissionFlag"
            caption="手数料有無"
            v-model="o.commissionFlag"
            :item="selectItemCommissionFlag"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3" v-show="isShowCommissionFlagItem('commissionFlag', '1', i, 'other')">
          <WmsTextInput 
            name="otherCommissionPrice" 
            caption="手数料金額" 
            v-model="o.commissionPrice"
            :disabled="true"
          />
        </div>

        <div class="sm:col-span-full mt-3" v-if="i<10">
          <label class="block text-xs font-bold text-gray-700">
            添付資料
          </label>
          <div class="mt-1">
            <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
              <WmsAttachment 
                name="otherCommonFile" 
                caption="添付資料"
                :value="order[`otherCommonFile${i+1}`]" 
                style="max-width: none!important;"
                @change="attachmentChange(`otherCommonFile${i+1}`, $event)"
                :disabled="isDisabled || referenceMode"
                :readonly="isDisabled || referenceMode"
              />
            </div>
          </div>
        </div>

        <div class="sm:col-span-full mt-3" >
          <WmsTextInput 
            name="otherMemo" 
            caption="メモ"
            :multiline="true"
            :rows="5"
            v-model="o.memo"
            :disabled="isDisabled || referenceMode"
          />
        </div>
      </div>
    </div>

    <div class="sm:col-span-full">
      <OrderRichTextInput
        name="template" 
        caption="配信用テンプレート"
        :value="templateHTML"
        v-model="templateHTML"
        @change="templateChange($event)"
        :disabled="isDisabled || referenceMode"
      >
        <!-- <template #additionalToolbar>
          <button class="w-auto py-0 text-sm ml-auto text-indigo-800 hover:text-indigo-500" @click="selectNote">特記事項を追加</button>
        </template> -->
      </OrderRichTextInput>
    </div>
  </div>
</template>
  
<script>
  import PrimaryButton from '@components/PrimaryButton.vue'
  import Icon from '@components/Icon.vue'
  import OrderItemSelect from '../components/OrderItemSelect.vue'
  import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
  import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
  import * as utils from '@libs/utils'
  import * as orderManager from '@managers/orderManager'
  // import * as logManager from '@managers/logManager'
  import EstimationDetailsCategorySelect from '../components/EstimationDetailsCategorySelect.vue'
  import InputGroupSubLabel from '../components/InputGroupSubLabel.vue'
  import OrderRichTextInput from '../components/OrderRichTextInput.vue'
  import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
  import InputIcon from '@components/InputIcon.vue'
  import * as constants from '@libs/constants'
  // import * as helper from '@libs/helper'
  import WmsAttachment from '@wmscomponents/WmsAttachment.vue'
  import * as dialogs from '@libs/dialogs'
  import WmsTimeInput2 from '@wmscomponents/WmsTimeInput2.vue'
  import * as moment from 'moment'

  const thisYear = (new Date()).getFullYear() + '年'
  
  export default {
    components: {
      PrimaryButton,
      Icon,
      OrderItemSelect,
      WmsDateInput,
      WmsTextInput,
      EstimationDetailsCategorySelect,
      InputGroupSubLabel,
      OrderRichTextInput,
      WmsSuggestInput,
      InputIcon,
      WmsAttachment,
      WmsTimeInput2
    },

    props: {
      estimateData: {
        type: Object,
        default: () => {}
      },
      orderData: {
        type: Object,
        default: () => {}
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isTaskRequestMode: {
        type: Boolean,
        default: false
      },
      referenceMode: {
        type: Boolean,
        default: false
      }
    },
  
    data() {
      return {
        order: orderManager.createNewOrderModel(),
        originalOrder: {},
        isSaving: false,
        initialized: false,
        saved: false,
        detailSorting: false,
        estimate: {},
        selectItemWeek: [],
        selectItemMonth: [],
        selectItemMonth2: [],
        templateHTML: '',
        dispContractDate: '',
        dispContractEndDate: '',
        categoryCode: [],
        categoryName: [],
        selectItemYear: [],
        estimationRoundPlusCategorySelectItem:[],
        // selectItemLighting: [],
        selectItemCarpet: [],
        regularCleanTargetAreaSelectItem: [],
        regularCleanDescriptionWorkSelectItem: [],
        workItemList: [],
        selectItemClassification: [],
        dispEstimationRoundStartDate: '',
        dispEstimationRoundPlusStartDate: '',
        dispEverydayStartDate: '',
        dispManagerWorkStartDate: [],
        dispFrontSupportStartDate: '',
        dispFrontSupportPlusStartDate: '',
        dispRoundTrashStartDate: '',
        dispQualityReportCount: '',
        dispGlassCleanCount: '',
        dispEstimationDorainPipeCount: '',
        dispEstimationWaterTankCount: '',
        dispPlantingCount: '',
        dispFireInspectCount: '',
        dispConstructionEquipmentInspectCount: '',
        dispSpecificInspectCount: '',
        dispCommonAreaFacilityInspectionCount: '',
        dispEvInspectCount: '',
        dispPressPumpInspectCount: '',
        dispWaterSupplyDrainagePumpInspectionCount: '',
        dispSimpleWaterSupplyCount: '',
        dispTubeBulbSupportCount: '',
        dispBulkyWasteSupportCount: '',
        dispMerchandiseSalesCount: '',
        dispEmergencyResponseCount: '',
        dispRegularCleanCount: [],
        dispSpecialCleanCount: [],
        selectItemCommissionFlag: [],
        hourItems: [],
        minuteItems: [],
      }
    },
  
    computed: {
      isModileDevice() {
        return utils.deviceInfo.isMobile
      },

      // validateContractDate() {
      //   if (!this.order.contractDate) {
      //     return {
      //       result: false,
      //       message: '契約日は必須入力です。契約日を入力してください。'
      //     }
      //   } else {
      //     return {
      //       result: true,
      //       message: ''
      //     }
      //   }
      // },

      // validateContractEndDate() {
      //   if (!this.order.contractEndDate) {
      //     return {
      //       result: false,
      //       message: '契約終了日は必須入力です。契約終了日を入力してください。'
      //     }
      //   } else if (this.order.contractDate > this.order.contractEndDate) {
      //     return{
      //       result: false,
      //       message: '契約日、契約終了日が不正です。'
      //     }  
      //   } else {
      //     return {
      //       result: true,
      //       message: ''
      //     }
      //   }
      // },

      validateContractEndDate() {
        if (this.order.contractEndDate != '' && this.order.contractDate != '' && this.order.contractDate > this.order.contractEndDate) {
          return{
            result: false,
            message: '契約日、契約終了日が不正です。'
          }  
        } else {
          return {
            result: true,
            message: ''
          }
        }
      },

      validateClassification() {
        if (!this.order.classification || this.order.classification.length == 0 || this.order.classification[0] == '2') {
          return {
            result: false,
            message: '区分は必須入力です。区分を選択してください。'
          }
        } else {
          return {
            result: true,
            message: ''
          }
        }
      },

      validateSpecialCleanWorkItem: function() {
        return function(index) {
          if (!this.order.orderSpecialCleans.length) {
            return false
          } else if (!this.order.orderSpecialCleans[index].specialCleanWorkItem) {
            return {
              result: false,
              message: '特別清掃の業務項目は必須入力です。業務項目を入力してください。'
            }
          } else {
            return {
              result: true,
              message: ''
            }
          }
        }
      },
    },
  
    async created() {
      await this.setOrder()
    },
  
    methods: {
        async setOrder() {
          this.selectItemWeek = [
            { text: '月', value: '月' },       
            { text: '火', value: '火' },
            { text: '水', value: '水' },
            { text: '木', value: '木' },
            { text: '金', value: '金' },
            { text: '土', value: '土' },
            { text: '日', value: '日' }
          ]

          this.selectItemMonth = [
            { text: '1月', value: '1月' },       
            { text: '2月', value: '2月' },
            { text: '3月', value: '3月' },
            { text: '4月', value: '4月' },
            { text: '5月', value: '5月' },
            { text: '6月', value: '6月' },
            { text: '7月', value: '7月' },
            { text: '8月', value: '8月' },
            { text: '9月', value: '9月' },
            { text: '10月', value: '10月' },
            { text: '11月', value: '11月' },
            { text: '12月', value: '12月' },
          ]

          this.selectItemMonth2 = [
            { text: '奇数月', value: '奇数月' },
            { text: '偶数月', value: '偶数月' }
          ]

          this.selectCleanCountItemMonth = [
            { value: '月 1 回', text: '月 1 回' },
            { value: '月 2 回', text: '月 2 回' },
            { value: '週 1 回', text: '週 1 回' },
            { value: '週 2 回', text: '週 2 回' },
            { value: '週 3 回', text: '週 3 回' },
            { value: '週 3.5 回', text: '週 3.5 回' },
            { value: '週 4 回', text: '週 4 回' },
            { value: '週 4.5 回', text: '週 4.5 回' },
            { value: '週 5 回', text: '週 5 回' },
            { value: '週 6 回', text: '週 6 回' }
          ]

          this.selectEstimationEverydayCountItemMonth = [
            { value: '週 1 回', text: '週 1 回' },
            { value: '週 2 回', text: '週 2 回' },
            { value: '週 3 回', text: '週 3 回' },
            { value: '週 4 回', text: '週 4 回' },
            { value: '週 5 回', text: '週 5 回' },
            { value: '週 6 回', text: '週 6 回' },
            { value: '週 7 回', text: '週 7 回' },
            { value: '週 1.5 回', text: '週 1.5 回' },
            { value: '週 2.5 回', text: '週 2.5 回' },
            { value: '週 3.5 回', text: '週 3.5 回' },
            { value: '週 4.5 回', text: '週 4.5 回' },
            { value: '週 5.5 回', text: '週 5.5 回' },
            { value: '週 6.5 回', text: '週 6.5 回' },
          ]

          this.selectManagerWorkCountItemMonth = [
            { value: '週 4 回', text: '週 4 回' },
            { value: '週 5 回', text: '週 5 回' },
            { value: '週 6 回', text: '週 6 回' },
            { value: '週 7 回', text: '週 7 回' },
            { value: '週 4.5 回', text: '週 4.5 回' },
            { value: '週 5.5 回', text: '週 5.5 回' },
            { value: '週 6.5 回', text: '週 6.5 回' },
          ]

          // this.selectItemLighting = [
          //   { value: '照明', text: '照明' }
          // ]

          this.selectItemCarpet = [
            { value: 'カーペット', text: 'カーペット' }
          ]

          this.regularCleanTargetAreaSelectItem = [
            { value: 'エントランス', text: 'エントランス' },
            { value: 'ロビー', text: 'ロビー' },
            { value: '廊下', text: '廊下' },
            { value: '階段', text: '階段' },
            { value: '管理室', text: '管理室' },
          ]

          this.regularCleanDescriptionWorkSelectItem = [
            { value: '洗浄のみ', text: '洗浄のみ' },
            { value: 'ワックス', text: 'ワックス' },
            { value: 'カーペット', text: 'カーペット' }
          ]

          this.workItemList = constants.SPECIALCLEAN_WORK_ITEMS

          // 区分
          // 見積もりの区分とは意味合いが違うため、変更 2023/08/02
          // 「追加変更」を「追加」と「変更」に分ける
          this.selectItemClassification = [
            { value: '1', text: '新規物件' },
            // { value: '2', text: '追加変更' },
            { value: '4', text: '追加' },
            { value: '5', text: '変更' },
            { value: '3', text: 'スポット' },
          ]

          this.estimationRoundPlusCategorySelectItem = [
            { value: 'ラウンドクリーンプラス', text: 'ラウンドクリーンプラス' },
            { value: 'ラウンドウォッシュプラス', text: 'ラウンドウォッシュプラス' },
            { value: 'ラウンドダブルプラス', text: 'ラウンドダブルプラス' }
          ]
          
          this.selectItemCommissionFlag = [
            { value: '1', text: '有'},
            { value: '0', text: '無'}
          ]

          this.hourItems = [
            { text: '7' },
            { text: '8' },
            { text: '9' },
            { text: '10' },
            { text: '11' },
            { text: '12' },
            { text: '13' },
            { text: '14' },
            { text: '15' },
            { text: '16' },
            { text: '17' },
            { text: '18' },
            { text: '19' },
            { text: '20' },
            { text: '21' },
            { text: '22' },
            { text: '23' }
          ]

          this.minuteItems = [
            { text: '00' },
            { text: '15' },
            { text: '30' },
            { text: '45' }
          ]

          // 初回作業年の選択内容
          for (let index = 0; index < 5; index++) {
            let selectYear = (new Date()).getFullYear() + index + '年'
            this.selectItemYear.push({ text: selectYear })
          }

          if (Object.keys(this.orderData).length) {
            this.order = this.orderData
            this.templateHTML = this.orderData.template

            // 過去の受注データで、ラウンドプラスの発注先名が文字列の場合は配列に変換
            if (typeof this.order.estimationRoundPlusSupplier === 'string') {
              let arr = this.order.estimationRoundPlusSupplier.split()
              let obj = {}
              arr.forEach((val) => {
                obj['supplier'] = val
              })
              this.order.estimationRoundPlusSupplier = [obj]
            }

            // 過去の受注データで、ラウンドプラスの作業曜日、作業時間が無ければ配列の要素を追加
            if (this.order.estimationDetailsCategoryCode.includes('2') && (!this.order.estimationRoundPlusWorkRemarks || this.order.estimationRoundPlusWorkRemarks.length == 0)) {
              this.addWorkRemarks('estimationRoundPlusWorkRemarks', true)
            }

            // 過去の受注データで、日常清掃の作業曜日、作業時間が無ければ配列の要素を追加
            if (this.order.orderEstimationEverydays && this.order.orderEstimationEverydays.length > 0) {
              this.historicalDataRemarksRegeneration('orderEstimationEverydays', 'everydayCleanWorkDays')
              for (let index = 0; index < this.order.orderEstimationEverydays.length; index++) {
                if (!this.order.orderEstimationEverydays[index].everydayCleanContents) {
                  this.setLabel('orderEstimationEverydays', 'everydayCleanContents', '3', index)
                }
              }
            }

            // 過去の受注データで、管理員業務の作業曜日、作業時間が無ければ配列の要素を追加
            if (this.order.orderManagerWorks && this.order.orderManagerWorks.length > 0) {
              this.historicalDataRemarksRegeneration('orderManagerWorks', 'managerWorkDays')
              for (let index = 0; index < this.order.orderManagerWorks.length; index++) {
                if (!this.order.orderManagerWorks[index].managerWorkContents) {
                  this.setLabel('orderManagerWorks', 'managerWorkContents', '4', index)
                }
              }
            }

            // 編集モード、サブタスク依頼モードの場合に、単位が無い場合は単位を追加
            for (let index = 0; index < this.estimateData.details.length; index++) {
              const estimate = this.estimateData.details[index]
              this.setCategory(estimate)

              // 定期清掃
              if (this.estimateData.details[index].categoryCode == '5' && this.estimateData.details[index].fixedRowspan != -1 && this.estimateData.details[index].type != 'fixedCalc' && this.order.orderRegularCleans) {
                for (let i = 0; i < this.order.orderRegularCleans.length; i++) {
                  if (!this.order.orderRegularCleans[i].regularCleanUnit) {
                    // 単位
                    if (this.estimateData.details[index].categoryUnitName != '') {
                      this.order.orderRegularCleans[i].regularCleanUnit = this.estimateData.details[index].categoryUnitName
                    }
                  }
                }
              } 

              // クオリティレポート
              if (this.estimateData.details[index].categoryCode == '9' && !this.order.qualityReportUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.qualityReportUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // ガラス清掃
              if (this.estimateData.details[index].categoryCode == '10' && !this.order.glassCleanUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.glassCleanUnit = this.estimateData.details[index].categoryUnitName
                }
              }
              
              // 排水管清掃
              if (this.estimateData.details[index].categoryCode == '11' && !this.order.estimationDorainPipeUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.estimationDorainPipeUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 貯水槽清掃
              if (this.estimateData.details[index].categoryCode == '12' && !this.order.estimationWaterTankUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.estimationWaterTankUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 植栽剪定
              if (this.estimateData.details[index].categoryCode == '13' && !this.order.plantingUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.plantingUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 特別清掃
              if (this.estimateData.details[index].categoryCode == '14' && this.order.orderSpecialCleans) {
                for (let i = 0; i < this.order.orderSpecialCleans.length; i++) {
                  if (!this.order.orderSpecialCleans[i].specialCleanUnit) {
                    // 単位
                    if (this.estimateData.details[index].categoryUnitName != '') {
                      this.order.orderSpecialCleans[i].specialCleanUnit = this.estimateData.details[index].categoryUnitName
                    }
                  }
                }
              }

              // 消防設備点検
              if (this.estimateData.details[index].categoryCode == '15' && !this.order.fireInspectUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.fireInspectUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 建築設備定期検査
              if (this.estimateData.details[index].categoryCode == '16' && !this.order.constructionEquipmentInspectUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.constructionEquipmentInspectUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 特定建築物定期調査
              if (this.estimateData.details[index].categoryCode == '17' && !this.order.specificInspectUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.specificInspectUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 共用部設備点検
              if (this.estimateData.details[index].categoryCode == '18' && !this.order.commonAreaFacilityInspectionUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.commonAreaFacilityInspectionUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // エレベーター保守点検
              if (this.estimateData.details[index].categoryCode == '19' && !this.order.evInspectUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.evInspectUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 増圧ポンプ点検
              if (this.estimateData.details[index].categoryCode == '20' && !this.order.pressPumpInspectUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.pressPumpInspectUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 給排水ポンプ点検
              if (this.estimateData.details[index].categoryCode == '21' && !this.order.waterSupplyDrainagePumpInspectionUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.waterSupplyDrainagePumpInspectionUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 簡易専用水道
              if (this.estimateData.details[index].categoryCode == '22' && !this.order.simpleWaterSupplyUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.simpleWaterSupplyUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 管球対応
              if (this.estimateData.details[index].categoryCode == '23' && !this.order.tubeBulbSupportUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.tubeBulbSupportUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 粗大ごみ対応
              if (this.estimateData.details[index].categoryCode == '24' && !this.order.bulkyWasteSupportUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.bulkyWasteSupportUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 物品販売
              if (this.estimateData.details[index].categoryCode == '25' && !this.order.merchandiseSalesUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.merchandiseSalesUnit = this.estimateData.details[index].categoryUnitName
                }
              }

              // 緊急対応
              if (this.estimateData.details[index].categoryCode == '26' && !this.order.emergencyResponseUnit) {
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.emergencyResponseUnit = this.estimateData.details[index].categoryUnitName
                }
              }
            }

            // 過去のデータに漏れがないか確認
            let dif = []
            let difFlag = false
            let undefinedCategory = []
            for (let i = 0; i < this.order.estimationDetailsCategoryCode.length; i++) {
              const old = this.order.estimationDetailsCategoryCode[i]
              const c = this.categoryCode[i]
              if (old != c && old == '') {
                difFlag = true
                if (!dif.includes(this.categoryName[i].name)) {
                  dif.push(this.categoryName[i].name)
                }
              }

              // 見積に無いカテゴリを作成者が勝手に活性している場合、そのカテゴリも活性する
              if (c == undefined) {
                undefinedCategory.push(old)
              }
            }
            if (undefinedCategory.length) {
              this.categoryCode = this.categoryCode.concat(undefinedCategory)
            }

            if (dif && dif.length && difFlag) {
              await dialogs.showInfoDialog('反映されていない見積あり', dif.join('、') + 'が反映されていませんでしたので反映します。')

              this.order.estimationDetailsCategoryCode = utils.clone(this.categoryCode)

              this.order.orderOthers = []
              for (let i = 0; i < this.estimateData.details.length; i++) {
                const es = this.estimateData.details[i]

                // 過去のデータに反映されていないカテゴリを反映

                // 防火対象物点検
                if (es.categoryCode == '27' || es.categoryName.indexOf('防火対象物') != -1) {
                  this.setEstimateValue(es, 'firePreventionObjectInspectionCount', 'firePreventionObjectInspectionUnit', 'dispFirePreventionObjectInspectionCount', 'firePreventionObjectInspectionFirstWorkYear', 'firePreventionObjectInspectionUnitPrice', 'firePreventionObjectInspectionAmount')
                }

                // 汚水槽
                else if (es.categoryName.indexOf('汚水槽') != -1) {
                  this.setEstimateValue(es, 'sewageTankCleaningCount', 'sewageTankCleaningUnit', 'dispSewageTankCleaningCount', 'sewageTankCleaningFirstWorkYear', 'sewageTankCleaningUnitPrice', 'sewageTankCleaningAmount')
                }

                // 防火設備
                else if (es.categoryName.indexOf('防火設備') != -1) {
                  this.setEstimateValue(es, 'fireProtectionInspectionCount', 'fireProtectionInspectionUnit', 'dispFireProtectionInspectionCount', 'fireProtectionInspectionFirstWorkYear', 'fireProtectionInspectionUnitPrice', 'fireProtectionInspectionAmount')
                }

                // 連結送水管
                else if (es.categoryName.indexOf('連結送水管') != -1) {
                  this.setEstimateValue(es, 'connectingWaterPipePressureInspectionCount', 'connectingWaterPipePressureInspectionUnit', 'dispConnectingWaterPipePressureInspectionCount', 'connectingWaterPipePressureInspectionFirstWorkYear', 'connectingWaterPipePressureInspectionUnitPrice', 'connectingWaterPipePressureInspectionAmount')
                }

                // カテゴリがないもの
                else if ((!es.categoryCode || es.categoryCode == '') && es.rowspan > 0) {
                  this.order.orderOthers.push({
                    name: es.categoryName,
                    count: es.categoryQty,
                    unit: es.categoryUnitName,
                    dispCount: es.categoryQty + es.categoryUnitName,
                    firstWorkYear: thisYear,
                    firstWorkMonth: [],
                    implementationMonth: [],
                    unitPrice: es.categoryUnitPrice,
                    amount: es.categoryAmount,
                    groupName: es.groupName,
                    supplier: '',
                    orderPrice: '',
                    commissionFlag: [],
                    commissionPrice: '',
                    memo: ''
                  })
                }
              }
              // 発注
              if (this.estimateData.purchase && this.estimateData.purchase.length) {
                for (let pi = 0; pi < this.estimateData.purchase.length; pi++) {
                  const purchase = this.estimateData.purchase[pi]
                  // 防火対象物点検
                  if (purchase.categoryCode == '27' || purchase.categoryName.indexOf('防火対象物') != -1) {
                    this.setPurchaseValue(purchase, 'firePreventionObjectInspectionSupplier', 'firePreventionObjectInspectionPrice')
                  }

                  // 汚水槽
                  else if (purchase.categoryName.indexOf('汚水槽') != -1) {
                    this.setPurchaseValue(purchase,  'sewageTankCleaningSupplier', 'sewageTankCleaningPrice')
                  }

                  // 防火設備
                  else if (purchase.categoryName.indexOf('防火設備') != -1) {
                    this.setPurchaseValue(purchase, 'fireProtectionInspectionSupplier', 'fireProtectionInspectionPrice')
                  }

                  // 連結送水管
                  else if (purchase.categoryName.indexOf('連結送水管') != -1) {
                    this.setPurchaseValue(purchase, 'connectingWaterPipePressureInspectionSupplier', 'connectingWaterPipePressureInspectionPrice')
                  }

                  // カテゴリがないもの
                  else if (!purchase.categoryCode || purchase.categoryCode == '') {
                    for (let i = 0; i < this.order.orderOthers.length; i++) {
                      const o = this.order.orderOthers[i]
                      if (o.groupName == purchase.groupName) {
                        o.supplier = purchase.supplier
                        o.orderPrice = purchase.price
                        break
                      }
                    }
                  }
                }
              }

              // 手数料
              if (this.estimateData.commission && this.estimateData.commission.length) {
                for (let ci = 0; ci < this.estimateData.commission.length; ci++) {
                  const commission = this.estimateData.commission[ci]
                  // 防火対象物点検
                  if (commission.categoryCode == '27' || commission.categoryName.indexOf('防火対象物') != -1) {
                    this.setCommissionValue(commission, 'firePreventionObjectInspectionCommissionFlag', 'firePreventionObjectInspectionCommissionPrice')
                  }

                  // 汚水槽
                  else if (commission.categoryName.indexOf('汚水槽') != -1) {
                    this.setCommissionValue(commission,  'sewageTankCleaningCommissionFlag', 'sewageTankCleaningCommissionPrice')
                  }

                  // 防火設備
                  else if (commission.categoryName.indexOf('防火設備') != -1) {
                    this.setCommissionValue(commission, 'fireProtectionInspectionCommissionFlag', 'fireProtectionInspectionCommissionPrice')
                  }

                  // 連結送水管
                  else if (commission.categoryName.indexOf('連結送水管') != -1) {
                    this.setCommissionValue(commission, 'connectingWaterPipePressureInspectionCommissionFlag', 'connectingWaterPipePressureInspectionCommissionPrice')
                  }

                  // カテゴリがないもの
                  else if (!commission.categoryCode || commission.categoryCode == '') {
                    for (let i = 0; i < this.order.orderOthers.length; i++) {
                      const o = this.order.orderOthers[i]
                      if (o.groupName == commission.groupName) {
                        if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
                          // 手数料金額が空か0でなければ、手数料有
                          o.commissionFlag = ['1']
                          // 手数料金額
                          o.commissionPrice = commission.commissionPrice
                        } else {
                          // それ以外は手数料無
                          o.commissionFlag = ['0']
                          // 手数料金額
                          o.commissionPrice = '0'
                        }
                        break
                      }
                    }
                  }
                }
              }
            }

            if (this.order.status != 'done') {
              if (!this.order.isConfirmationCommissionFlag && this.estimateData.commission && this.estimateData.commission.length > 0) {
                const dialogResult = await dialogs.showWarningConfirmDialog('手数料の確認', '手数料有無、手数料金額の項目がありませんが見積から反映しますか？')
                // 「はい」を選択した場合のみ、手数料有無、手数料金額を書き換え
                // 「キャンセル」を選択した場合は何もしない
                if (dialogResult === 'YES') {
                  for (let ci = 0; ci < this.estimateData.commission.length; ci++) {
                    const commission = this.estimateData.commission[ci]
                    // ラウンドサービス
                    if (this.estimateData.commission[ci].categoryCode == '1' && (!this.order.estimationRoundCommissionFlag || !this.order.estimationRoundCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationRoundCommissionFlag', 'estimationRoundCommissionPrice')
                    }

                    // ラウンドプラス
                    else if (this.estimateData.commission[ci].categoryCode == '2' && (!this.order.estimationRoundPlusCommissionFlag || !this.order.estimationRoundPlusCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationRoundPlusCommissionFlag', 'estimationRoundPlusCommissionPrice')
                    }

                    // 日常清掃
                    else if (this.estimateData.commission[ci].categoryCode == '3' && (!this.order.estimationEverydayCommissionFlag || !this.order.estimationEverydayCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationEverydayCommissionFlag', 'estimationEverydayCommissionPrice')
                    }

                    // 管理員業務
                    else if (this.estimateData.commission[ci].categoryCode == '4' && (!this.order.managerWorkCommissionFlag || !this.order.managerWorkCommissionPrice)) {
                      this.setCommissionValue2(commission, 'managerWorkCommissionFlag', 'managerWorkCommissionPrice')
                    }

                    // 定期清掃
                    else if (this.estimateData.commission[ci].categoryCode == '5' && (!this.order.regularCleanCommissionFlag || !this.order.regularCleanCommissionPrice)) {
                      this.setCommissionValue2(commission, 'regularCleanCommissionFlag', 'regularCleanCommissionPrice')
                    }

                    // フロントサポート
                    else if (this.estimateData.commission[ci].categoryCode == '6' && (!this.order.frontSupportCommissionFlag || !this.order.frontSupportCommissionPrice)) {
                      this.setCommissionValue2(commission, 'frontSupportCommissionFlag', 'frontSupportCommissionPrice')
                    }

                    // フロントサポートプラス
                    else if (this.estimateData.commission[ci].categoryCode == '7' && (!this.order.frontSupportPlusCommissionFlag || !this.order.frontSupportPlusCommissionPrice)) {
                      this.setCommissionValue2(commission, 'frontSupportPlusCommissionFlag', 'frontSupportPlusCommissionPrice')
                    }

                    // ラウンドトラッシュ
                    else if (this.estimateData.commission[ci].categoryCode == '8' && (!this.order.roundTrashCommissionFlag || !this.order.roundTrashCommissionPrice)) {
                      this.setCommissionValue2(commission, 'roundTrashCommissionFlag', 'roundTrashCommissionPrice')
                    }

                    // クオリティレポート
                    else if (this.estimateData.commission[ci].categoryCode == '9' && (!this.order.qualityReportCommissionFlag || !this.order.qualityReportCommissionPrice)) {
                      this.setCommissionValue2(commission, 'qualityReportCommissionFlag', 'qualityReportCommissionPrice')
                    }

                    // ガラス清掃
                    else if (this.estimateData.commission[ci].categoryCode == '10' && (!this.order.glassCleanCommissionFlag || !this.order.glassCleanCommissionPrice)) {
                      this.setCommissionValue2(commission, 'glassCleanCommissionFlag', 'glassCleanCommissionPrice')
                    }
                    
                    // 排水管清掃
                    else if (this.estimateData.commission[ci].categoryCode == '11' && (!this.order.estimationDorainPipeCommissionFlag || !this.order.estimationDorainPipeCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationDorainPipeCommissionFlag', 'estimationDorainPipeCommissionPrice')
                    }

                    // 貯水槽清掃
                    else if (this.estimateData.commission[ci].categoryCode == '12' && (!this.order.estimationWaterTankCommissionFlag || !this.order.estimationWaterTankCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationWaterTankCommissionFlag', 'estimationWaterTankCommissionPrice')
                    }

                    // 植栽剪定
                    else if (this.estimateData.commission[ci].categoryCode == '13' && (!this.order.plantingCommissionFlag || !this.order.plantingCommissionPrice)) {
                      this.setCommissionValue2(commission, 'plantingCommissionFlag', 'plantingCommissionPrice')
                    }

                    // 特別清掃
                    else if (this.estimateData.commission[ci].categoryCode == '14' && this.order.orderSpecialCleans) {
                      for (let i = 0; i < this.order.orderSpecialCleans.length; i++) {
                        if (this.order.orderSpecialCleans[i].groupName && this.order.orderSpecialCleans[i].groupName == commission.groupName && (!this.order.orderSpecialCleans[i].specialCleanCommissionFlag || !this.order.orderSpecialCleans[i].specialCleanCommissionPrice)) {
                          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
                            // 手数料金額が空か0でなければ、手数料有
                            this.$set(this.order.orderSpecialCleans[i], 'specialCleanCommissionFlag', ['1'])
                            // 手数料金額
                            this.$set(this.order.orderSpecialCleans[i], 'specialCleanCommissionPrice', commission.commissionPrice)
                          } else {
                            // それ以外は手数料無
                            this.$set(this.order.orderSpecialCleans[i], 'specialCleanCommissionFlag', ['0'])
                            // 手数料金額
                            this.$set(this.order.orderSpecialCleans[i], 'specialCleanCommissionPrice', '0')
                          }
                        }
                      }
                    }

                    // 消防設備点検
                    else if (this.estimateData.commission[ci].categoryCode == '15' && (!this.order.fireInspectCommissionFlag || !this.order.fireInspectCommissionPrice)) {
                      this.setCommissionValue2(commission, 'fireInspectCommissionFlag', 'fireInspectCommissionPrice')
                    }

                    // 建築設備定期検査
                    else if (this.estimateData.commission[ci].categoryCode == '16' && (!this.order.estimationRoundCommissionFlag || !this.order.estimationRoundCommissionPrice)) {
                      this.setCommissionValue2(commission, 'estimationRoundCommissionFlag', 'estimationRoundCommissionPrice')
                    }

                    // 特定建築物定期調査
                    else if (this.estimateData.commission[ci].categoryCode == '17' && (!this.order.specificInspectCommissionFlag || !this.order.specificInspectCommissionPrice)) {
                      this.setCommissionValue2(commission, 'specificInspectCommissionFlag', 'specificInspectCommissionPrice')
                    }

                    // 共用部設備点検
                    else if (this.estimateData.commission[ci].categoryCode == '18' && (!this.order.commonAreaFacilityInspectionCommissionFlag || !this.order.commonAreaFacilityInspectionCommissionPrice)) {
                      this.setCommissionValue2(commission, 'commonAreaFacilityInspectionCommissionFlag', 'commonAreaFacilityInspectionCommissionPrice')
                    }

                    // エレベーター保守点検
                    else if (this.estimateData.commission[ci].categoryCode == '19' && (!this.order.evInspectCommissionFlag || !this.order.evInspectCommissionPrice)) {
                      this.setCommissionValue2(commission, 'evInspectCommissionFlag', 'evInspectCommissionPrice')
                    }

                    // 増圧ポンプ点検
                    else if (this.estimateData.commission[ci].categoryCode == '20' && (!this.order.pressPumpInspectCommissionFlag || !this.order.pressPumpInspectCommissionPrice)) {
                      this.setCommissionValue2(commission, 'pressPumpInspectCommissionFlag', 'pressPumpInspectCommissionPrice')
                    }

                    // 給排水ポンプ点検
                    else if (this.estimateData.commission[ci].categoryCode == '21' && (!this.order.waterSupplyDrainagePumpInspectionCommissionFlag || !this.order.waterSupplyDrainagePumpInspectionCommissionPrice)) {
                      this.setCommissionValue2(commission, 'waterSupplyDrainagePumpInspectionCommissionFlag', 'waterSupplyDrainagePumpInspectionCommissionPrice')
                    }

                    // 簡易専用水道
                    else if (this.estimateData.commission[ci].categoryCode == '22' && (!this.order.simpleWaterSupplyCommissionFlag || !this.order.simpleWaterSupplyCommissionPrice)) {
                      this.setCommissionValue2(commission, 'simpleWaterSupplyCommissionFlag', 'simpleWaterSupplyCommissionPrice')
                    }

                    // 管球対応
                    else if (this.estimateData.commission[ci].categoryCode == '23' && (!this.order.tubeBulbSupportCommissionFlag || !this.order.tubeBulbSupportCommissionPrice)) {
                      this.setCommissionValue2(commission, 'tubeBulbSupportCommissionFlag', 'tubeBulbSupportCommissionPrice')
                    }

                    // 粗大ごみ対応
                    else if (this.estimateData.commission[ci].categoryCode == '24' && (!this.order.bulkyWasteSupportCommissionFlag || !this.order.bulkyWasteSupportCommissionPrice)) {
                      this.setCommissionValue2(commission, 'bulkyWasteSupportCommissionFlag', 'bulkyWasteSupportCommissionPrice')
                    }

                    // 物品販売
                    else if (this.estimateData.commission[ci].categoryCode == '25' && (!this.order.merchandiseSalesCommissionFlag || !this.order.merchandiseSalesCommissionPrice)) {
                      this.setCommissionValue2(commission, 'merchandiseSalesCommissionFlag', 'merchandiseSalesCommissionPrice')
                    }

                    // 緊急対応
                    else if (this.estimateData.commission[ci].categoryCode == '26'  && (!this.order.emergencyResponseCommissionFlag || !this.order.emergencyResponseCommissionPrice)) {
                      this.setCommissionValue2(commission, 'emergencyResponseCommissionFlag', 'emergencyResponseCommissionPrice')
                    }

                    // スポット清掃
                    else if (this.estimateData.commission[ci].categoryCode == '28') {
                      for (let i = 0; i < this.order.orderSpot.length; i++) {
                        const o = this.order.orderSpot[i]
                        if (o.groupName == commission.groupName && (!o.commissionFlag || !o.commissionPrice)) {
                          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
                            // 手数料金額が空か0でなければ、手数料有
                            this.$set(this.order.orderSpot[i], 'commissionFlag', ['1'])
                            // 手数料金額
                            this.$set(this.order.orderSpot[i], 'commissionPrice', commission.commissionPrice)
                          } else {
                            // それ以外は手数料無
                            this.$set(this.order.orderSpot[i], 'commissionFlag', ['0'])
                            // 手数料金額
                            this.$set(this.order.orderSpot[i], 'commissionPrice', '0')
                          }
                          break
                        }
                      }
                    }

                    // カテゴリがないもの
                    else if (!this.estimateData.commission[ci].categoryCode || this.estimateData.commission[ci].categoryCode == '') {
                      for (let i = 0; i < this.order.orderOthers.length; i++) {
                        const o = this.order.orderOthers[i]
                        if (o.groupName == commission.groupName && (!o.commissionFlag || !o.commissionPrice)) {
                          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
                            // 手数料金額が空か0でなければ、手数料有
                            this.$set(this.order.orderOthers[i], 'commissionFlag', ['1'])
                            // 手数料金額
                            this.$set(this.order.orderOthers[i], 'commissionPrice', commission.commissionPrice)
                          } else {
                            // それ以外は手数料無
                            this.$set(this.order.orderOthers[i], 'commissionFlag', ['0'])
                            // 手数料金額
                            this.$set(this.order.orderOthers[i], 'commissionPrice', '0')
                          }
                          break
                        }
                      }
                    }
                  }
                  this.$emit('changeOrderSummary')
                }
              } else if (!this.order.isConfirmationCommissionFlag && (!this.estimateData.commission || this.estimateData.commission.length == 0)) {
                const dialogResult = await dialogs.showWarningConfirmDialog('手数料の確認', '見積に手数料明細がありません。\n登録内容に手数料有無、手数料金額を「---」で表示しますが宜しいでしょうか？')
                // 「はい」を選択した場合のみ、登録内容の手数料有無、手数料金額を「---」に書き換え
                // 「キャンセル」を選択した場合は何もしない
                if (dialogResult === 'YES') {
                  this.$emit('changeOrderSummary')
                }
              }
            }

            // 表示用の清掃希望回数（回数+単位）を生成
            if (this.order.orderRegularCleans) {
              for (let i = 0; i < this.order.orderRegularCleans.length; i++) {
                this.dispRegularCleanCount.push(this.order.orderRegularCleans[i].regularCleanCount + this.order.orderRegularCleans[i].regularCleanUnit)
              }
            }
            this.dispQualityReportCount = this.order.qualityReportCount + this.order.qualityReportUnit
            this.dispGlassCleanCount = this.order.glassCleanCount + this.order.glassCleanUnit
            this.dispEstimationDorainPipeCount = this.order.estimationDorainPipeCount + this.order.estimationDorainPipeUnit
            this.dispEstimationWaterTankCount = this.order.estimationWaterTankCount + this.order.estimationWaterTankUnit
            this.dispPlantingCount = this.order.plantingCount + this.order.plantingUnit
            if (this.order.orderSpecialCleans) {
              for (let i = 0; i < this.order.orderSpecialCleans.length; i++) {
                this.dispSpecialCleanCount.push(this.order.orderSpecialCleans[i].specialCleanCount + this.order.orderSpecialCleans[i].specialCleanUnit)
              }
            }
            this.dispFireInspectCount = this.order.fireInspectCount + this.order.fireInspectUnit
            this.dispConstructionEquipmentInspectCount = this.order.constructionEquipmentInspectCount + this.order.constructionEquipmentInspectUnit
            this.dispSpecificInspectCount = this.order.specificInspectCount + this.order.specificInspectUnit
            this.dispCommonAreaFacilityInspectionCount = this.order.commonAreaFacilityInspectionCount + this.order.commonAreaFacilityInspectionUnit
            this.dispEvInspectCount = this.order.evInspectCount + this.order.evInspectUnit
            this.dispPressPumpInspectCount = this.order.pressPumpInspectCount + this.order.pressPumpInspectUnit
            this.dispWaterSupplyDrainagePumpInspectionCount = this.order.waterSupplyDrainagePumpInspectionCount + this.order.waterSupplyDrainagePumpInspectionUnit
            this.dispSimpleWaterSupplyCount = this.order.simpleWaterSupplyCount + this.order.simpleWaterSupplyUnit
            this.dispTubeBulbSupportCount = this.order.tubeBulbSupportCount + this.order.tubeBulbSupportUnit
            this.dispBulkyWasteSupportCount = this.order.bulkyWasteSupportCount + this.order.bulkyWasteSupportUnit
            this.dispMerchandiseSalesCount = this.order.merchandiseSalesCount + this.order.merchandiseSalesUnit
            this.dispEmergencyResponseCount = this.order.emergencyResponseCount + this.order.emergencyResponseUnit

            if (this.isTaskRequestMode || this.referenceMode) {
              // サブタスク依頼、参照モードの場合は契約日、契約終了日を年月日形式に変換
              this.dispContractDate = utils.formatDateJa(this.orderData.contractDate)
              this.dispContractEndDate = utils.formatDateJa(this.orderData.contractEndDate)

              // サブタスク依頼、参照モードの場合は開始日を年月日形式に変換
              // ラウンドサービス
              this.dispEstimationRoundStartDate = utils.formatDateJa(this.orderData.estimationRoundStartDate)
              // ラウンドプラス
              this.dispEstimationRoundPlusStartDate = utils.formatDateJa(this.orderData.estimationRoundPlusStartDate)
              // 日常清掃
              if (this.orderData.orderEstimationEverydays) {
                for (let index = 0; index < this.orderData.orderEstimationEverydays.length; index++) {
                  this.dispEverydayStartDate = utils.formatDateJa(this.orderData.orderEstimationEverydays[index].everydayStartDate)
                  this.orderData.orderEstimationEverydays[index].dispEverydayStartDate = this.dispEverydayStartDate
                }
              }
              // 管理員業務
              if (this.orderData.orderManagerWorks) {
                for (let index = 0; index < this.orderData.orderManagerWorks.length; index++) {
                  this.dispManagerWorkStartDate = utils.formatDateJa(this.orderData.orderManagerWorks[index].managerWorkStartDate)
                  this.orderData.orderManagerWorks[index].dispManagerWorkStartDate = this.dispManagerWorkStartDate
                }
              }
              // フロントサポート
              this.dispFrontSupportStartDate = utils.formatDateJa(this.orderData.frontSupportStartDate)
              // フロントサポートプラス
              this.dispFrontSupportPlusStartDate = utils.formatDateJa(this.orderData.frontSupportPlusStartDate)
              // ラウンドトラッシュ
              this.dispRoundTrashStartDate = utils.formatDateJa(this.orderData.roundTrashStartDate)

            }
          } else {
            // 新規登録
            this.order = this.createNewOrder()
            this.order.estimateNo = this.estimateData.estimateNo
            this.order.caseName = this.estimateData.buildingName + ' ' + '受注処理'
            this.order.contractDate = utils.getToday()
            this.order.contractEndDate = utils.addDays(this.order.contractDate, 365)
            // 区分は「スポット」のみ反映
            if (this.estimateData.classification == 3) {
              this.order.classification = [this.estimateData.classification]
              // 区分によって契約終了日の初期値を入れる
              this.setContractEndDate()
            }
            // 初回作業年の初期値は本年度
            this.order.frontSupportFirstWorkYear = thisYear
            this.order.frontSupportPlusFirstWorkYear = thisYear
            this.order.roundTrashFirstWorkYear = thisYear
            this.order.qualityReportFirstWorkYear = thisYear
            this.order.glassCleanFirstWorkYear = thisYear
            this.order.estimationDorainPipeFirstWorkYear = thisYear
            this.order.estimationWaterTankFirstWorkYear = thisYear
            this.order.plantingFirstWorkYear = thisYear
            this.order.specialCleanFirstWorkYear = thisYear
            this.order.lightingFirstWorkYear = thisYear
            this.order.fireInspectFirstWorkYear = thisYear
            this.order.constructionEquipmentFirstWorkYear = thisYear
            this.order.specificInspectFirstWorkYear = thisYear
            this.order.commonAreaFacilityInspectionFirstWorkYear = thisYear
            this.order.evInspectFirstWorkYear = thisYear
            this.order.pressPumpInspectFirstWorkYear = thisYear
            this.order.waterSupplyDrainagePumpInspectionFirstWorkYear = thisYear
            this.order.simpleWaterSupplyFirstWorkYear = thisYear
            this.order.tubeBulbSupportFirstWorkYear = thisYear
            this.order.bulkyWasteSupportFirstWorkYear = thisYear
            this.order.merchandiseSalesFirstWorkYear = thisYear
            this.order.emergencyResponseFirstWorkYear = thisYear
            this.order.sewageTankCleaningFirstWorkYear = thisYear
            this.order.fireProtectionInspectionFirstWorkYear = thisYear
            this.order.connectingWaterPipePressureInspectionFirstWorkYear = thisYear
            this.order.firePreventionObjectInspectionFirstWorkYear = thisYear

            // 新規登録の場合のみ、配列の要素の追加を行う
            this.addRpSupplier()

            let regularCleans = []
            let regularCleanContents = []
            let estimationEverydays = []
            let managerWorks = []
            let specialCleans = []

            for (let index = 0; index < this.estimateData.details.length; index++) {
              const estimateD = this.estimateData.details[index]
              this.order.estimationDetailsCategory.push(this.estimateData.details[index].categoryName)

              this.setCategory(estimateD)

              // 防火対象物点検
              if (estimateD.categoryCode == '27' || estimateD.categoryName.indexOf('防火対象物') != -1) {
                this.setEstimateValue(estimateD, 'firePreventionObjectInspectionCount', 'firePreventionObjectInspectionUnit', 'dispFirePreventionObjectInspectionCount', 'firePreventionObjectInspectionFirstWorkYear', 'firePreventionObjectInspectionUnitPrice', 'firePreventionObjectInspectionAmount')
              }

              // 汚水槽
              else if (estimateD.categoryName.indexOf('汚水槽') != -1) {
                this.setEstimateValue(estimateD, 'sewageTankCleaningCount', 'sewageTankCleaningUnit', 'dispSewageTankCleaningCount', 'sewageTankCleaningFirstWorkYear', 'sewageTankCleaningUnitPrice', 'sewageTankCleaningAmount')
              }

              // 防火設備
              else if (estimateD.categoryName.indexOf('防火設備') != -1) {
                this.setEstimateValue(estimateD, 'fireProtectionInspectionCount', 'fireProtectionInspectionUnit', 'dispFireProtectionInspectionCount', 'fireProtectionInspectionFirstWorkYear', 'fireProtectionInspectionUnitPrice', 'fireProtectionInspectionAmount')
              }

              // 連結送水管
              else if (estimateD.categoryName.indexOf('連結送水管') != -1) {
                this.setEstimateValue(estimateD, 'connectingWaterPipePressureInspectionCount', 'connectingWaterPipePressureInspectionUnit', 'dispConnectingWaterPipePressureInspectionCount', 'connectingWaterPipePressureInspectionFirstWorkYear', 'connectingWaterPipePressureInspectionUnitPrice', 'connectingWaterPipePressureInspectionAmount')
              }

              // ラウンドサービス
              else if (this.estimateData.details[index].categoryCode == '1') {
                // 清掃回数
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  this.order.estimationRoundCount = this.estimateData.details[index].subItemModalData.toolTip.清掃回数.split(',')
                }
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.estimationRoundUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.estimationRoundAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // ラウンドプラス
              else if (this.estimateData.details[index].categoryCode == '2') {
                this.order.estimationDetailsCategory.push('ラウンドプラス')
                if (this.estimateData.details[index].subItemModalData && Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  this.order.estimationRoundPlusCount = this.estimateData.details[index].subItemModalData.toolTip.清掃回数_RLS.split(',')
                  this.setRemarksVal(this.estimateData.details[index].subItemModalData, 'estimationRoundPlusWorkRemarks')
                }
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.estimationRoundPlusUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.estimationRoundPlusAmount = this.estimateData.details[index].categoryAmount
                }

                if (this.estimateData.details[index].categoryName == 'ラウンドクリーンプラス') {
                  this.order.estimationRoundPlusCategorySelect = ['ラウンドクリーンプラス']
                } else if (this.estimateData.details[index].categoryName == 'ラウンドウォッシュプラス') {
                  this.order.estimationRoundPlusCategorySelect = ['ラウンドウォッシュプラス']
                } else if (this.estimateData.details[index].categoryName == 'ラウンドダブルプラス') {
                  this.order.estimationRoundPlusCategorySelect = ['ラウンドダブルプラス']
                }
              }

              // 日常清掃
              else if (this.estimateData.details[index].categoryCode == '3') {
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  for (let idx in this.estimateData.details[index].subItemModalData.modalTabData) {
                    estimationEverydays.push(this.estimateData.details[index].subItemModalData.modalTabData[idx])
                  }
                }

                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.estimationEverydayUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.estimationEverydayAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 管理員業務
              else if (this.estimateData.details[index].categoryCode == '4') {
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  for (let idx in this.estimateData.details[index].subItemModalData.modalTabData) {
                    managerWorks.push(this.estimateData.details[index].subItemModalData.modalTabData[idx])
                  }
                }

                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.managerWorkUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.managerWorkAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 定期清掃
              else if (this.estimateData.details[index].categoryCode == '5') {
                // 見積明細のセル結合の1行目のみ変数に追加し、2行目以降は破棄
                if (this.estimateData.details[index].fixedRowspan != -1 && this.estimateData.details[index].type != 'fixedCalc') {
                  regularCleans.push(this.estimateData.details[index])
                  
                  let rcContents = []
                  // 見積明細でセル結合されている場合、結合されているセルの「内容」を抽出
                  if (this.estimateData.details[index].fixedRowspan > 1) {
                    for (let idx = 0; idx < this.estimateData.details.length; idx++) {
                      if (this.estimateData.details[idx].categoryCode == '5' && this.estimateData.details[idx].groupName == this.estimateData.details[index].groupName && this.estimateData.details[idx].type != 'fixedCalc') {
                        rcContents.push(this.estimateData.details[idx].itemName)
                      }
                    }
                  } else {
                    rcContents.push(this.estimateData.details[index].itemName)
                  }
                  regularCleanContents.push(rcContents)
                }
              }
              

              // フロントサポート
              else if (this.estimateData.details[index].categoryCode == '6') {
                // 清掃回数
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  this.order.frontSupportCount = this.estimateData.details[index].subItemModalData.toolTip.作業回数.split(',')
                }
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.frontSupportUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.frontSupportAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // フロントサポートプラス
              else if (this.estimateData.details[index].categoryCode == '7') {
                // 清掃回数
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  this.order.frontSupportPlusCount = this.estimateData.details[index].subItemModalData.toolTip.作業回数.split(',')
                }
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.frontSupportPlusUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.frontSupportPlusAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // ラウンドトラッシュ
              else if (this.estimateData.details[index].categoryCode == '8') {
                // 清掃回数
                if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                  if (this.estimateData.details[index].subItemModalData.selectedValue[2] == '4') {
                    this.order.roundTrashCount = ['週 4 回']
                  } else if (this.estimateData.details[index].subItemModalData.selectedValue[2] == '4.5') {
                    this.order.roundTrashCount = ['週 4.5 回']
                  }
                }
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.roundTrashUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.roundTrashAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // クオリティレポート
              else if (this.estimateData.details[index].categoryCode == '9') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.qualityReportCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.qualityReportUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispQualityReportCount = this.order.qualityReportCount + this.order.qualityReportUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.qualityReportUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.qualityReportAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // ガラス清掃
              else if (this.estimateData.details[index].categoryCode == '10') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.glassCleanCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.glassCleanUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispGlassCleanCount = this.order.glassCleanCount + this.order.glassCleanUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.glassCleanUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.glassCleanAmount = this.estimateData.details[index].categoryAmount
                }
              }
              
              // 排水管清掃
              else if (this.estimateData.details[index].categoryCode == '11') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.estimationDorainPipeCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.estimationDorainPipeUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispEstimationDorainPipeCount = this.order.estimationDorainPipeCount + this.order.estimationDorainPipeUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.estimationDorainPipeUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.estimationDorainPipeAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 貯水槽清掃
              else if (this.estimateData.details[index].categoryCode == '12') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.estimationWaterTankCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.estimationWaterTankUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispEstimationWaterTankCount = this.order.estimationWaterTankCount + this.order.estimationWaterTankUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.estimationWaterTankUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.estimationWaterTankAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 植栽剪定
              else if (this.estimateData.details[index].categoryCode == '13') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.plantingCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.plantingUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispPlantingCount = this.order.plantingCount + this.order.plantingUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.plantingUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.plantingAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 特別清掃
              else if (this.estimateData.details[index].categoryCode == '14') {
                // 見積明細のセルを変数に追加
                specialCleans.push(this.estimateData.details[index])
                
                // // 特別清掃に照明器具清掃がある場合、照明に清掃回数等を反映
                // if (this.estimateData.details[index].itemName == '照明器具清掃') {
                //   this.order.specialCleanLighting = ['照明']
                //   // 清掃回数
                //   if (this.estimateData.details[index].categoryQty != '') {
                //     this.order.lightingCleanCount = this.estimateData.details[index].categoryQty
                //   }
                //   // 受注単価（税抜き）
                //   if (this.estimateData.details[index].categoryUnitPrice != '') {
                //     this.order.lightingCleanUnitPrice = this.estimateData.details[index].categoryUnitPrice
                //   }
                //   // 受注総額（税抜き）
                //   if (this.estimateData.details[index].categoryAmount != '0') {
                //     this.order.lightingCleanAmount = this.estimateData.details[index].categoryAmount
                //   }

                //   // 発注先名、発注金額
                //   if(Object.keys(this.estimateData.details[index].subItemModalData).length) {
                //     if (this.estimateData.details[index].subItemModalData.purchase) {
                //       this.order.lightingCleanSupplier = this.estimateData.details[index].subItemModalData.purchase.supplier
                //     }
                //     if (this.estimateData.details[index].subItemModalData.purchase) {
                //       this.order.lightingCleanPrice = this.estimateData.details[index].subItemModalData.purchase.price
                //     }
                //   } else {
                //     if (this.estimateData.purchase && this.estimateData.purchase.length) {
                //       for (let index = 0; index < this.estimateData.purchase.length; index++) {
                //         if (this.estimateData.purchase[index].categoryCode == '14' && this.estimateData.purchase[index].groupName == this.estimateData.details[index].groupName) {
                //           this.order.lightingCleanSupplier = this.estimateData.purchase[index].supplier
                //           this.order.lightingCleanPrice = this.estimateData.purchase[index].price
                //         }
                //       }
                //     }
                //   }
                // }
              }

              // 消防設備点検
              else if (this.estimateData.details[index].categoryCode == '15') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.fireInspectCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.fireInspectUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispFireInspectCount = this.order.fireInspectCount + this.order.fireInspectUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.fireInspectUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.fireInspectAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 建築設備定期検査
              else if (this.estimateData.details[index].categoryCode == '16') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.constructionEquipmentInspectCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.constructionEquipmentInspectUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispConstructionEquipmentInspectCount = this.order.constructionEquipmentInspectCount + this.order.constructionEquipmentInspectUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.constructionEquipmentInspectUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.constructionEquipmentInspectAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 特定建築物定期調査
              else if (this.estimateData.details[index].categoryCode == '17') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.specificInspectCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.specificInspectUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispSpecificInspectCount = this.order.specificInspectCount + this.order.specificInspectUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.specificInspectUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.specificInspectAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 共用部設備点検
              else if (this.estimateData.details[index].categoryCode == '18') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.commonAreaFacilityInspectionCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.commonAreaFacilityInspectionUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispCommonAreaFacilityInspectionCount = this.order.commonAreaFacilityInspectionCount + this.order.commonAreaFacilityInspectionUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.commonAreaFacilityInspectionUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.commonAreaFacilityInspectionAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // エレベーター保守点検
              else if (this.estimateData.details[index].categoryCode == '19') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.evInspectCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.evInspectUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispEvInspectCount = this.order.evInspectCount + this.order.evInspectUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.evInspectUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.evInspectAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 増圧ポンプ点検
              else if (this.estimateData.details[index].categoryCode == '20') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.pressPumpInspectCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.pressPumpInspectUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispPressPumpInspectCount = this.order.pressPumpInspectCount + this.order.pressPumpInspectUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.pressPumpInspectUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.pressPumpInspectAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 給排水ポンプ点検
              else if (this.estimateData.details[index].categoryCode == '21') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.waterSupplyDrainagePumpInspectionCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.waterSupplyDrainagePumpInspectionUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispWaterSupplyDrainagePumpInspectionCount = this.order.waterSupplyDrainagePumpInspectionCount + this.order.waterSupplyDrainagePumpInspectionUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.waterSupplyDrainagePumpInspectionUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.waterSupplyDrainagePumpInspectionAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 簡易専用水道
              else if (this.estimateData.details[index].categoryCode == '22') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.simpleWaterSupplyCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.simpleWaterSupplyUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispSimpleWaterSupplyCount = this.order.simpleWaterSupplyCount + this.order.simpleWaterSupplyUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.simpleWaterSupplyUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.simpleWaterSupplyAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 管球対応
              else if (this.estimateData.details[index].categoryCode == '23') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.tubeBulbSupportCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.tubeBulbSupportUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispTubeBulbSupportCount = this.order.tubeBulbSupportCount + this.order.tubeBulbSupportUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.tubeBulbSupportUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.tubeBulbSupportAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 粗大ごみ対応
              else if (this.estimateData.details[index].categoryCode == '24') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.bulkyWasteSupportCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.bulkyWasteSupportUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispBulkyWasteSupportCount = this.order.bulkyWasteSupportCount + this.order.bulkyWasteSupportUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.bulkyWasteSupportUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.bulkyWasteSupportAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 物品販売
              else if (this.estimateData.details[index].categoryCode == '25') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.merchandiseSalesCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.merchandiseSalesUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispMerchandiseSalesCount = this.order.merchandiseSalesCount + this.order.merchandiseSalesUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.merchandiseSalesUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.merchandiseSalesAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // 緊急対応
              else if (this.estimateData.details[index].categoryCode == '26') {
                // 清掃回数
                if (this.estimateData.details[index].categoryQty != '') {
                  this.order.emergencyResponseCount = this.estimateData.details[index].categoryQty
                }
                // 単位
                if (this.estimateData.details[index].categoryUnitName != '') {
                  this.order.emergencyResponseUnit = this.estimateData.details[index].categoryUnitName
                }
                this.dispEmergencyResponseCount = this.order.emergencyResponseCount + this.order.emergencyResponseUnit
                // 受注単価（税抜き）
                if (this.estimateData.details[index].categoryUnitPrice != '') {
                  this.order.emergencyResponseUnitPrice = this.estimateData.details[index].categoryUnitPrice
                }
                // 受注総額（税抜き）
                if (this.estimateData.details[index].categoryAmount != '0') {
                  this.order.emergencyResponseAmount = this.estimateData.details[index].categoryAmount
                }
              }

              // スポット清掃
              else if (estimateD.categoryCode == '28') {
                if (estimateD.rowspan > 0) {
                  let spot = this.setObjectEstimateValue(estimateD)
                  spot.spotArea = ''
                  spot.spotContent = ''
                  spot.spotCarpet = []

                  this.order.orderSpot.push(spot)
                }
              }

              // カテゴリコードがないもの
              else {
                if (estimateD.rowspan > 0) {
                  this.order.orderOthers.push(this.setObjectEstimateValue(estimateD))
                }
              }
            }

            this.order.estimationDetailsCategoryCode = utils.clone(this.categoryCode)

            console.log('this.order.estimationDetailsCategory')
            console.log(this.order.estimationDetailsCategory)
            console.log('this.order.estimationDetailsCategoryCode')
            console.log(this.order.estimationDetailsCategoryCode)

            // 日常清掃
            if (estimationEverydays.length) {
              for (let idx in estimationEverydays) {
                let estimationEverydayCountVal = []
                let val = estimationEverydays[idx].data.selectedValue[7]
                if (val == '1') {
                  estimationEverydayCountVal = ['週 1 回']
                } else if (val == '1.5') {
                  estimationEverydayCountVal = ['週 1.5 回']
                } else if (val == '2') {
                  estimationEverydayCountVal = ['週 2 回']
                } else if (val == '2.5') {
                  estimationEverydayCountVal = ['週 2.5 回']
                } else if (val == '3') {
                  estimationEverydayCountVal = ['週 3 回']
                } else if (val == '3.5') {
                  estimationEverydayCountVal = ['週 3.5 回']
                } else if (val == '4') {
                  estimationEverydayCountVal = ['週 4 回']
                } else if (val == '4.5') {
                  estimationEverydayCountVal = ['週 4.5 回']
                } else if (val == '5') {
                  estimationEverydayCountVal = ['週 5 回']
                } else if (val == '5.5') {
                  estimationEverydayCountVal = ['週 5.5 回']
                } else if (val == '6') {
                  estimationEverydayCountVal = ['週 6 回']
                } else if (val == '6.5') {
                  estimationEverydayCountVal = ['週 6.5 回']
                } else if (val == '7') {
                  estimationEverydayCountVal = ['週 7 回']
                }

                let estimationEveryday = {
                  everydayCleanContents: '',
                  estimationEverydayCount: estimationEverydayCountVal,
                  everydayStartDate: '',
                  everydayCleanWorkDays: []
                }
                this.order.orderEstimationEverydays.push(estimationEveryday)
                this.setRemarksVal(estimationEverydays[idx].data, 'everydayCleanWorkDays', 'orderEstimationEverydays', idx)
                this.setLabel('orderEstimationEverydays', 'everydayCleanContents', '3', idx)
              }
            }

            // 管理員業務
            if (managerWorks.length) {
              for (let idx in managerWorks) {
                let managerWorkCountVal = []
                let val = managerWorks[idx].data.selectedValue[7]
                if (val == '4') {
                  managerWorkCountVal = ['週 4 回']
                } else if (val == '4.5') {
                  managerWorkCountVal = ['週 4.5 回']
                } else if (val == '5') {
                  managerWorkCountVal = ['週 5 回']
                } else if (val == '5.5') {
                  managerWorkCountVal = ['週 5.5 回']
                } else if (val == '6') {
                  managerWorkCountVal = ['週 6 回']
                } else if (val == '6.5') {
                  managerWorkCountVal = ['週 6.5 回']
                } else if (val == '7') {
                  managerWorkCountVal = ['週 7 回']
                }

                let managerWork = {
                  managerWorkContents: '',
                  managerWorkCount: managerWorkCountVal,
                  managerWorkStartDate: '',
                  managerWorkDays: []
                }
                this.order.orderManagerWorks.push(managerWork)
                this.setRemarksVal(managerWorks[idx].data, 'managerWorkDays', 'orderManagerWorks', idx)
                this.setLabel('orderManagerWorks', 'managerWorkContents', '4', idx)
              }
            }

            // 定期清掃があれば、見積明細（定期清掃）の行数分の項目を表示
            if (regularCleans.length) {
              for (let idx in regularCleans) {
                let dispRcContents = regularCleanContents[idx].join(', ')
                // 文字数が20文字より多い場合、20文字以降は「・・・」に変更
                if (dispRcContents.length > 20) {
                  dispRcContents = dispRcContents.substring(0, 20) + '・・・'
                }

                if (regularCleans[idx].categoryQty != '' || regularCleans[idx].categoryUnitName != '') {
                  this.dispRegularCleanCount.push(regularCleans[idx].categoryQty + regularCleans[idx].categoryUnitName)
                }

                let regularClean = {
                  regularCleanContents: dispRcContents,
                  regularCleanCount: regularCleans[idx].categoryQty,
                  regularCleanUnit: regularCleans[idx].categoryUnitName,
                  regularCleanFirstWorkYear: thisYear,
                  regularCleanFirstWorkMonth: [],
                  regularCleanImplementationMonth: [],
                  regularCleanUnitPrice: regularCleans[idx].categoryUnitPrice,
                  regularCleanAmount: regularCleans[idx].categoryAmount,
                  regularCleanTargetArea: '',
                  regularCleanDescriptionWork: '',
                  regularCleanCarpet: []
                }
                this.order.orderRegularCleans.push(regularClean)
              }
            }

            // 特別清掃があれば、見積明細（特別清掃）の行数分の項目を表示
            if (specialCleans.length) {
              for (let idx in specialCleans) {
                let spWorkItem = ''
                for (let index = 0; index < this.workItemList.length; index++) {
                  if (specialCleans[idx].itemName != '') {
                    if(this.workItemList[index].value.indexOf(specialCleans[idx].itemName) > -1){
                      spWorkItem = this.workItemList[index].id
                    }
                  }
                }

                if (specialCleans[idx].categoryQty != '' || specialCleans[idx].categoryUnitName != '') {
                  this.dispSpecialCleanCount.push(specialCleans[idx].categoryQty + specialCleans[idx].categoryUnitName)
                }

                let specialClean = {
                  specialCleanContents: specialCleans[idx].itemName,
                  specialCleanWorkItem: spWorkItem,
                  specialCleanCount: specialCleans[idx].categoryQty,
                  specialCleanUnit: specialCleans[idx].categoryUnitName,
                  specialCleanFirstWorkYear: thisYear,
                  specialCleanFirstWorkMonth: [],
                  specialCleanImplementationMonth: [],
                  specialCleanUnitPrice: specialCleans[idx].categoryUnitPrice,
                  specialCleanAmount: specialCleans[idx].categoryAmount,
                  specialCleanSupplier: '',
                  specialCleanPrice: '',
                  specialCleanCommissionFlag: [],
                  specialCleanCommissionPrice: '',
                  groupName: specialCleans[idx].groupName
                }
                this.order.orderSpecialCleans.push(specialClean)
              }
            }

            // 発注
            if (this.estimateData.purchase && this.estimateData.purchase.length) {
              for (let index = 0; index < this.estimateData.purchase.length; index++) {
                const purchase = this.estimateData.purchase[index]
                // 防火対象物点検
                if (purchase.categoryCode == '27' || purchase.categoryName.indexOf('防火対象物') != -1) {
                  this.setPurchaseValue(purchase, 'firePreventionObjectInspectionSupplier', 'firePreventionObjectInspectionPrice')
                }

                // 汚水槽
                else if (purchase.categoryName.indexOf('汚水槽') != -1) {
                  this.setPurchaseValue(purchase,  'sewageTankCleaningSupplier', 'sewageTankCleaningPrice')
                }

                // 防火設備
                else if (purchase.categoryName.indexOf('防火設備') != -1) {
                  this.setPurchaseValue(purchase, 'fireProtectionInspectionSupplier', 'fireProtectionInspectionPrice')
                }

                // 連結送水管
                else if (purchase.categoryName.indexOf('連結送水管') != -1) {
                  this.setPurchaseValue(purchase, 'connectingWaterPipePressureInspectionSupplier', 'connectingWaterPipePressureInspectionPrice')
                }

                // ラウンドサービス
                else if (this.estimateData.purchase[index].categoryCode == '1') {
                  this.order.estimationRoundSupplier = this.estimateData.purchase[index].supplier
                  this.order.estimationRoundPrice = this.estimateData.purchase[index].price
                }

                // ラウンドプラス
                else if (purchase.categoryCode == '2') {
                  // 新仕様の発注明細
                  if (purchase.type) {
                    this.order.estimationRoundPlusPurchase.push(
                      {
                        typeName: purchase.typeName,
                        supplier: purchase.supplier,
                        price: purchase.price,
                        groupName: purchase.groupName
                      }
                    )
                  // 過去の発注明細
                  } else {
                    this.order.estimationRoundPlusSupplier[0].supplier = this.estimateData.purchase[index].supplier
                    this.order.estimationRoundPlusPrice = this.estimateData.purchase[index].price
                  }
                }

                // 日常清掃
                else if (this.estimateData.purchase[index].categoryCode == '3') {
                  this.order.estimationEverydaySupplier = this.estimateData.purchase[index].supplier
                  this.order.estimationEverydayPrice = this.estimateData.purchase[index].price
                }

                // 管理員業務
                else if (this.estimateData.purchase[index].categoryCode == '4') {
                  this.order.managerWorkSupplier = this.estimateData.purchase[index].supplier
                  this.order.managerWorkPrice = this.estimateData.purchase[index].price
                }

                // 定期清掃
                else if (this.estimateData.purchase[index].categoryCode == '5') {
                  this.order.regularCleanSupplier = this.estimateData.purchase[index].supplier
                  this.order.regularCleanPrice = this.estimateData.purchase[index].price
                }

                // フロントサポート
                else if (this.estimateData.purchase[index].categoryCode == '6') {
                  this.order.frontSupportSupplier = this.estimateData.purchase[index].supplier
                  this.order.frontSupportPrice = this.estimateData.purchase[index].price
                }

                // フロントサポートプラス
                else if (this.estimateData.purchase[index].categoryCode == '7') {
                  this.order.frontSupportPlusSupplier = this.estimateData.purchase[index].supplier
                  this.order.frontSupportPlusPrice = this.estimateData.purchase[index].price
                }

                // ラウンドトラッシュ
                else if (this.estimateData.purchase[index].categoryCode == '8') {
                  this.order.roundTrashSupplier = this.estimateData.purchase[index].supplier
                  this.order.roundTrashPrice = this.estimateData.purchase[index].price
                }

                // クオリティレポート
                else if (this.estimateData.purchase[index].categoryCode == '9') {
                  this.order.qualityReportSupplier = this.estimateData.purchase[index].supplier
                  this.order.qualityReportPrice = this.estimateData.purchase[index].price
                }

                // ガラス清掃
                else if (this.estimateData.purchase[index].categoryCode == '10') {
                  this.order.glassCleanSupplier = this.estimateData.purchase[index].supplier
                  this.order.glassCleanPrice = this.estimateData.purchase[index].price
                }
                
                // 排水管清掃
                else if (this.estimateData.purchase[index].categoryCode == '11') {
                  this.order.estimationDorainPipeSupplier = this.estimateData.purchase[index].supplier
                  this.order.estimationDorainPipePrice = this.estimateData.purchase[index].price
                }

                // 貯水槽清掃
                else if (this.estimateData.purchase[index].categoryCode == '12') {
                  this.order.estimationWaterTankSupplier = this.estimateData.purchase[index].supplier
                  this.order.estimationWaterTankPrice = this.estimateData.purchase[index].price
                }

                // 植栽剪定
                else if (this.estimateData.purchase[index].categoryCode == '13') {
                  this.order.plantingSupplier = this.estimateData.purchase[index].supplier
                  this.order.plantingPrice = this.estimateData.purchase[index].price
                }

                // 特別清掃
                else if (this.estimateData.purchase[index].categoryCode == '14') {
                  for (let i = 0; i < this.order.orderSpecialCleans.length; i++) {
                    if (this.order.orderSpecialCleans[i].groupName && this.order.orderSpecialCleans[i].groupName == purchase.groupName) {
                      this.order.orderSpecialCleans[i].specialCleanSupplier = purchase.supplier
                      this.order.orderSpecialCleans[i].specialCleanPrice = purchase.price
                    }
                  }
                }

                // 消防設備点検
                else if (this.estimateData.purchase[index].categoryCode == '15') {
                  this.order.fireInspectSupplier = this.estimateData.purchase[index].supplier
                  this.order.fireInspectPrice = this.estimateData.purchase[index].price
                }

                // 建築設備定期検査
                else if (this.estimateData.purchase[index].categoryCode == '16') {
                  this.order.constructionEquipmentInspectSupplier = this.estimateData.purchase[index].supplier
                  this.order.constructionEquipmentInspectPrice = this.estimateData.purchase[index].price
                }

                // 特定建築物定期調査
                else if (this.estimateData.purchase[index].categoryCode == '17') {
                  this.order.specificInspectSupplier = this.estimateData.purchase[index].supplier
                  this.order.specificInspectPrice = this.estimateData.purchase[index].price
                }

                // 共用部設備点検
                else if (this.estimateData.purchase[index].categoryCode == '18') {
                  this.order.commonAreaFacilityInspectionSupplier = this.estimateData.purchase[index].supplier
                  this.order.commonAreaFacilityInspectionPrice = this.estimateData.purchase[index].price
                }

                // エレベーター保守点検
                else if (this.estimateData.purchase[index].categoryCode == '19') {
                  this.order.evInspectSupplier = this.estimateData.purchase[index].supplier
                  this.order.evInspectPrice = this.estimateData.purchase[index].price
                }

                // 増圧ポンプ点検
                else if (this.estimateData.purchase[index].categoryCode == '20') {
                  this.order.pressPumpInspectSupplier = this.estimateData.purchase[index].supplier
                  this.order.pressPumpInspectPrice = this.estimateData.purchase[index].price
                }

                // 給排水ポンプ点検
                else if (this.estimateData.purchase[index].categoryCode == '21') {
                  this.order.waterSupplyDrainagePumpInspectionSupplier = this.estimateData.purchase[index].supplier
                  this.order.waterSupplyDrainagePumpInspectionPrice = this.estimateData.purchase[index].price
                }

                // 簡易専用水道
                else if (this.estimateData.purchase[index].categoryCode == '22') {
                  this.order.simpleWaterSupplySupplier = this.estimateData.purchase[index].supplier
                  this.order.simpleWaterSupplyPrice = this.estimateData.purchase[index].price
                }

                // 管球対応
                else if (this.estimateData.purchase[index].categoryCode == '23') {
                  this.order.tubeBulbSupportSupplier = this.estimateData.purchase[index].supplier
                  this.order.tubeBulbSupportPrice = this.estimateData.purchase[index].price
                }

                // 粗大ごみ対応
                else if (this.estimateData.purchase[index].categoryCode == '24') {
                  this.order.bulkyWasteSupportSupplier = this.estimateData.purchase[index].supplier
                  this.order.bulkyWasteSupportPrice = this.estimateData.purchase[index].price
                }

                // 物品販売
                else if (this.estimateData.purchase[index].categoryCode == '25') {
                  this.order.merchandiseSalesSupplier = this.estimateData.purchase[index].supplier
                  this.order.merchandiseSalesPrice = this.estimateData.purchase[index].price
                }

                // 緊急対応
                else if (this.estimateData.purchase[index].categoryCode == '26') {
                  this.order.emergencyResponseSupplier = this.estimateData.purchase[index].supplier
                  this.order.emergencyResponsePrice = this.estimateData.purchase[index].price
                }

                // スポット清掃
                else if (purchase.categoryCode == '28') {
                  for (let i = 0; i < this.order.orderSpot.length; i++) {
                    const s = this.order.orderSpot[i]
                    if (s.groupName == purchase.groupName) {
                      s.supplier = purchase.supplier
                      s.orderPrice = purchase.price
                      break
                    }
                  }
                }

                // カテゴリコードがないもの
                else {
                  for (let i = 0; i < this.order.orderOthers.length; i++) {
                    const o = this.order.orderOthers[i]
                    if (o.groupName == purchase.groupName) {
                      o.supplier = purchase.supplier
                      o.orderPrice = purchase.price
                      break
                    }
                  }
                }
              }
            }

            // 手数料
            if (this.estimateData.commission && this.estimateData.commission.length) {
              for (let index = 0; index < this.estimateData.commission.length; index++) {
                const commission = this.estimateData.commission[index]
                // 防火対象物点検
                if (commission.categoryCode == '27' || commission.categoryName.indexOf('防火対象物') != -1) {
                  this.setCommissionValue(commission, 'firePreventionObjectInspectionCommissionFlag', 'firePreventionObjectInspectionCommissionPrice')
                }

                // 汚水槽
                else if (commission.categoryName.indexOf('汚水槽') != -1) {
                  this.setCommissionValue(commission,  'sewageTankCleaningCommissionFlag', 'sewageTankCleaningCommissionPrice')
                }

                // 防火設備
                else if (commission.categoryName.indexOf('防火設備') != -1) {
                  this.setCommissionValue(commission, 'fireProtectionInspectionCommissionFlag', 'fireProtectionInspectionCommissionPrice')
                }

                // 連結送水管
                else if (commission.categoryName.indexOf('連結送水管') != -1) {
                  this.setCommissionValue(commission, 'connectingWaterPipePressureInspectionCommissionFlag', 'connectingWaterPipePressureInspectionCommissionPrice')
                }

                // ラウンドサービス
                else if (commission.categoryCode == '1') {
                  this.setCommissionValue(commission, 'estimationRoundCommissionFlag', 'estimationRoundCommissionPrice')
                }

                // ラウンドプラス
                else if (commission.categoryCode == '2') {
                  this.setCommissionValue(commission, 'estimationRoundPlusCommissionFlag', 'estimationRoundPlusCommissionPrice')
                }

                // 日常清掃
                else if (commission.categoryCode == '3') {
                  this.setCommissionValue(commission, 'estimationEverydayCommissionFlag', 'estimationEverydayCommissionPrice')
                }

                // 管理員業務
                else if (commission.categoryCode == '4') {
                  this.setCommissionValue(commission, 'managerWorkCommissionFlag', 'managerWorkCommissionPrice')
                }

                // 定期清掃
                else if (commission.categoryCode == '5') {
                  this.setCommissionValue(commission, 'regularCleanCommissionFlag', 'regularCleanCommissionPrice')
                }

                // フロントサポート
                else if (commission.categoryCode == '6') {
                  this.setCommissionValue(commission, 'frontSupportCommissionFlag', 'frontSupportCommissionPrice')
                }

                // フロントサポートプラス
                else if (commission.categoryCode == '7') {
                  this.setCommissionValue(commission, 'frontSupportPlusCommissionFlag', 'frontSupportPlusCommissionPrice')
                }

                // ラウンドトラッシュ
                else if (commission.categoryCode == '8') {
                  this.setCommissionValue(commission, 'roundTrashCommissionFlag', 'roundTrashCommissionPrice')
                }

                // クオリティレポート
                else if (commission.categoryCode == '9') {
                  this.setCommissionValue(commission, 'qualityReportCommissionFlag', 'qualityReportCommissionPrice')
                }

                // ガラス清掃
                else if (commission.categoryCode == '10') {
                  this.setCommissionValue(commission, 'glassCleanCommissionFlag', 'glassCleanCommissionPrice')
                }
                
                // 排水管清掃
                else if (commission.categoryCode == '11') {
                  this.setCommissionValue(commission, 'estimationDorainPipeCommissionFlag', 'estimationDorainPipeCommissionPrice')
                }

                // 貯水槽清掃
                else if (commission.categoryCode == '12') {
                  this.setCommissionValue(commission, 'estimationWaterTankCommissionFlag', 'estimationWaterTankCommissionPrice')
                }

                // 植栽剪定
                else if (commission.categoryCode == '13') {
                  this.setCommissionValue(commission, 'plantingCommissionFlag', 'plantingCommissionPrice')
                }

                // 特別清掃
                else if (commission.categoryCode == '14') {
                  for (let i = 0; i < this.order.orderSpecialCleans.length; i++) {
                    if (this.order.orderSpecialCleans[i].groupName && this.order.orderSpecialCleans[i].groupName == commission.groupName) {
                      if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
                        // 手数料金額が空か0でなければ、手数料有
                        this.order.orderSpecialCleans[i].specialCleanCommissionFlag = ['1']
                        // 手数料金額
                        this.order.orderSpecialCleans[i].specialCleanCommissionPrice = commission.commissionPrice
                      } else {
                        // それ以外は手数料無
                        this.order.orderSpecialCleans[i].specialCleanCommissionFlag = ['0']
                        // 手数料金額
                        this.order.orderSpecialCleans[i].specialCleanCommissionPrice = '0'
                      }
                    }
                  }
                }

                // 消防設備点検
                else if (commission.categoryCode == '15') {
                  this.setCommissionValue(commission, 'fireInspectCommissionFlag', 'fireInspectCommissionPrice')
                }

                // 建築設備定期検査
                else if (commission.categoryCode == '16') {
                  this.setCommissionValue(commission, 'constructionEquipmentInspectCommissionFlag', 'constructionEquipmentInspectCommissionPrice')
                }

                // 特定建築物定期調査
                else if (commission.categoryCode == '17') {
                  this.setCommissionValue(commission, 'specificInspectCommissionFlag', 'specificInspectCommissionPrice')
                }

                // 共用部設備点検
                else if (commission.categoryCode == '18') {
                  this.setCommissionValue(commission, 'commonAreaFacilityInspectionCommissionFlag', 'commonAreaFacilityInspectionCommissionPrice')
                }

                // エレベーター保守点検
                else if (commission.categoryCode == '19') {
                  this.setCommissionValue(commission, 'evInspectCommissionFlag', 'evInspectCommissionPrice')
                }

                // 増圧ポンプ点検
                else if (commission.categoryCode == '20') {
                  this.setCommissionValue(commission, 'pressPumpInspectCommissionFlag', 'pressPumpInspectCommissionPrice')
                }

                // 給排水ポンプ点検
                else if (commission.categoryCode == '21') {
                  this.setCommissionValue(commission, 'waterSupplyDrainagePumpInspectionCommissionFlag', 'waterSupplyDrainagePumpInspectionCommissionPrice')
                }

                // 簡易専用水道
                else if (commission.categoryCode == '22') {
                  this.setCommissionValue(commission, 'simpleWaterSupplyCommissionFlag', 'simpleWaterSupplyCommissionPrice')
                }

                // 管球対応
                else if (commission.categoryCode == '23') {
                  this.setCommissionValue(commission, 'tubeBulbSupportCommissionFlag', 'tubeBulbSupportCommissionPrice')
                }

                // 粗大ごみ対応
                else if (commission.categoryCode == '24') {
                  this.setCommissionValue(commission, 'bulkyWasteSupportCommissionFlag', 'bulkyWasteSupportCommissionPrice')
                }

                // 物品販売
                else if (commission.categoryCode == '25') {
                  this.setCommissionValue(commission, 'merchandiseSalesCommissionFlag', 'merchandiseSalesCommissionPrice')
                }

                // 緊急対応
                else if (commission.categoryCode == '26') {
                  this.setCommissionValue(commission, 'emergencyResponseCommissionFlag', 'emergencyResponseCommissionPrice')
                }

                // スポット清掃
                else if (commission.categoryCode == '28') {
                  for (let i = 0; i < this.order.orderSpot.length; i++) {
                    const s = this.order.orderSpot[i]
                    if (s.groupName == commission.groupName) {
                      this.setArrayCommissionValue(commission, s)
                      break
                    }
                  }
                }

                // カテゴリコードがないもの
                else {
                  for (let i = 0; i < this.order.orderOthers.length; i++) {
                    const o = this.order.orderOthers[i]
                    if (o.groupName == commission.groupName) {
                      this.setArrayCommissionValue(commission, o)
                      break
                    }
                  }
                }
              }
            }

            this.estimationDetailsCategorySelected()
          }
          
          // 物件情報に「物件名、住所、郵便番号、階数、戸数」が表示されるよう生成
          if (this.orderData && Object.keys(this.orderData).length > 0) {
            let dialogResult = 'CANCEL'
            // 編集モード、サブタスク依頼モード、参照モードでひらいた時に物件情報に「物件名：」が無い場合
            if (!this.order.buildingName || this.order.buildingName.indexOf('物件名：') == -1) {
              // ダイアログを表示
              dialogResult = await dialogs.showWarningConfirmDialog('物件情報の確認', '物件情報がありません。\n物件情報を反映しますか？')            
            } else if (this.order.buildingName && this.order.buildingName.indexOf('郵便番号：') == -1) {
              // 編集モード、サブタスク依頼モード、参照モードでひらいた時に物件情報に「郵便番号：」が無い場合
              // ダイアログを表示
              dialogResult = await dialogs.showWarningConfirmDialog('郵便番号の確認', '郵便番号がありません。\n物件情報を更新しますか？')
            }
            // 「はい」を選択した場合のみ、物件情報を書き換え
            // 「キャンセル」を選択した場合は何もしない
            if (dialogResult === 'YES') {
              this.order.buildingName = this.createBuildingContents()
              // 登録内容にも郵便番号を追加
              this.$emit('changeOrderSummary')
            }
          } else {
            // 新規登録の場合
            this.order.buildingName = this.createBuildingContents()
          }

          // 受注仮登録、受注処理、編集の場合は親に受注データと入力チェックの結果を返却
          this.orderDataEmit()

          if (this.order.orderRegularCleans) {
            for (let index = 0; index < this.order.orderRegularCleans.length; index++) {
              this.order.orderRegularCleans[index].dispRegularCleanCount = this.dispRegularCleanCount[index]
            }
          }
          if (this.order.orderSpecialCleans) {
            for (let index = 0; index < this.order.orderSpecialCleans.length; index++) {
              this.order.orderSpecialCleans[index].dispSpecialCleanCount = this.dispSpecialCleanCount[index]
            }
          }
        },

        /**
         * 物件情報 生成
         */
        createBuildingContents() {
          let stairs = ''
          let households = ''
          let postalCode = '---'
          if (this.estimateData.buildingInfo.stairs && this.estimateData.buildingInfo.stairs != '0' && this.estimateData.buildingInfo.stairs != '') {
            stairs = '\n階数：' +  this.estimateData.buildingInfo.stairs
          }
          if (this.estimateData.buildingInfo.households && this.estimateData.buildingInfo.households != '0' && this.estimateData.buildingInfo.households != '') {
            households = '　戸数：' + this.estimateData.buildingInfo.households
          }
          if (this.estimateData.buildingPostalCode && this.estimateData.buildingPostalCode != '') {
            postalCode = this.estimateData.buildingPostalCode
          }
          let buildingContents = '物件名：' + this.estimateData.buildingName + '\n郵便番号：' + postalCode + '\n住所：' + this.estimateData.buildingAddress2 + stairs + households
          return buildingContents
        },

        /**
         * 見積明細カテゴリーセット
         */
        setCategory(estimateD) {
          let code = estimateD.categoryCode
          if (estimateD.categoryName.indexOf('汚水槽') > -1) {
            code = '101'
          } else if (estimateD.categoryName.indexOf('防火設備') > -1) {
            code = '102'
          } else if (estimateD.categoryName.indexOf('連結送水管') > -1) {
            code = '103'
          } else if (estimateD.categoryName.indexOf('防火対象物') > -1) {
            code = '27'
          } else if (!estimateD.categoryCode || estimateD.categoryCode == '') {
            code = 'none'
          }
          this.categoryCode.push(code)
          // セルが結合しているものと判定する必要があるため、groupnameはgroupname+カテゴリコードとする
          this.categoryName.push({ name: estimateD.categoryName, groupName: estimateD.groupName + code})
        },

        /**
         * 基本情報を見積から引っ張る
         * @param estimate 見積データ
         * @param count 清掃回数
         * @param unit 単位
         * @param dispCount 表示上の清掃回数
         * @param unitPrice 単価
         * @param amount 総額
         */
        setEstimateValue(estimate, count, unit, dispCount, year, unitPrice, amount) {
          if (estimate.rowspan && estimate.rowspan > 0) {
            // 清掃回数
            this.order[count] = estimate.categoryQty
            // 単位
            this.order[unit] = estimate.categoryUnitName
            // 表示上の清掃回数
            this.order[dispCount] = estimate.categoryQty + estimate.categoryUnitName
            // 初回作業年
            this.order[year] = thisYear
            // 受注単価（税抜き）
            this.order[unitPrice] = estimate.categoryUnitPrice
            // 受注総額（税抜き）
            this.order[amount] = estimate.categoryAmount
          }
        },

        /**
         * 基本情報を見積から引っ張る
         * @param estimate 見積データ
         */
        setObjectEstimateValue(estimate) {
          return {
            name: estimate.categoryName,
            count: estimate.categoryQty,
            unit: estimate.categoryUnitName,
            dispCount: estimate.categoryQty + estimate.categoryUnitName,
            firstWorkYear: thisYear,
            firstWorkMonth: [],
            implementationMonth: [],
            unitPrice: estimate.categoryUnitPrice,
            amount: estimate.categoryAmount,
            groupName: estimate.groupName,
            supplier: '',
            orderPrice: '',
            commissionFlag: [],
            commissionPrice: '',
            memo: ''
          }
        },

        /**
         * 発注をセット
         * @param purchase 対象発注データ
         * @param s 発注先
         * @param p 発注金額
         */
        setPurchaseValue(purchase, s, p) {
          this.order[s] = purchase.supplier
          this.order[p] = purchase.price
        },

        /**
         * 手数料をセット
         * @param commission 対象手数料データ
         * @param f 手数料有無
         * @param p 手数料金額
         */
         setCommissionValue(commission, f, p) {
          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
            // 手数料金額が空か0でなければ、手数料有
            this.order[f] = ['1']
            // 手数料金額
            this.order[p] = commission.commissionPrice
          } else {
            // それ以外は手数料無
            this.order[f] = ['0']
            // 手数料金額
            this.order[p] = '0'
          }
        },

        /**
         * 手数料をセット（プロパティが無いデータの場合）
         * @param commission 対象手数料データ
         * @param f 手数料有無
         * @param p 手数料金額
         */
         setCommissionValue2(commission, flagName, priceName) {
          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
            // 手数料金額が空か0でなければ、手数料有
            this.$set(this.order, flagName, ['1'])
            // 手数料金額
            this.$set(this.order, priceName, commission.commissionPrice)
          } else {
            // それ以外は手数料無
            this.$set(this.order, flagName, ['0'])
            // 手数料金額
            this.$set(this.order, priceName, '0')
          }
        },

        /**
         * 配列プロパティの手数料をセット
         * @param commission 対象手数料データ
         * @param category
         */
         setArrayCommissionValue(commission, category) {
          if (commission.commissionPrice != '' && commission.commissionPrice != '0') {
            // 手数料金額が空か0でなければ、手数料有
            category.commissionFlag = ['1']
            // 手数料金額
            category.commissionPrice = commission.commissionPrice
          } else {
            // それ以外は手数料無
            category.commissionFlag = ['0']
            // 手数料金額
            category.commissionPrice = '0'
          }
        },

      storeOriginal() {
        const tempOrder = utils.clone(this.order)
        this.originalOrder = tempOrder
      },
  
      /**
       * 新規受注 生成
       */
      createNewOrder() {
        return orderManager.createNewOrderModel()
      },

      /**
       * 見積参照
       */
      referenceEstimate() {
        let resolvedRoute = this.$router.resolve({ 
          name: 'EstimateView',
          query: {
            estimateNo: this.order.estimateNo
          }
        });
        window.open(resolvedRoute.href, '_blank');
      },

      /**
       * 見積明細カテゴリー 隠し項目 表示/非表示
       * @param {*} category 
       */
      isShowEstimationDetailsCategoryItem(categoryCode) {
        if (this.order.estimationDetailsCategoryCode && this.order.estimationDetailsCategoryCode.length > 0) {
          return this.order.estimationDetailsCategoryCode.indexOf(categoryCode) > -1
        } else {
          return false
        }
      },

      /**
       * 手数料有無 隠し項目 表示/非表示
       * @param {*} name 
       * @param {*} flag 
       * @param {*} worksIdx
       * @param {*} categoryName
       */
      isShowCommissionFlagItem(name, flag, worksIdx, categoryName) {
        if (worksIdx || worksIdx == '0') {
          if (categoryName == 'specialClean' && this.order.orderSpecialCleans) {
            // 特別清掃
            if (this.order.orderSpecialCleans[worksIdx][name] && this.order.orderSpecialCleans[worksIdx][name].length > 0) {
              return this.order.orderSpecialCleans[worksIdx][name].indexOf(flag) > -1
            } else {
              return false
            }
          } else if (categoryName == 'spot' && this.order.orderSpot) {
            // スポット清掃
            if (this.order.orderSpot[worksIdx][name] && this.order.orderSpot[worksIdx][name].length > 0) {
              return this.order.orderSpot[worksIdx][name].indexOf(flag) > -1
            } else {
              return false
            }
          } else if (categoryName == 'other' && this.order.orderOthers) {
            // カテゴリーがないもの
            if (this.order.orderOthers[worksIdx][name] && this.order.orderOthers[worksIdx][name].length > 0) {
              return this.order.orderOthers[worksIdx][name].indexOf(flag) > -1
            } else {
              return false
            }
          }
        } else {
          if (this.order[name] && this.order[name].length > 0) {
            return this.order[name].indexOf(flag) > -1
          } else {
            return false
          }
        }
      },

      // /**
      //  * 照明 隠し項目 表示/非表示
      //  * @param {*} value 
      //  */
      //  isShowspecialCleanLightingItem(value) {
      //   if (this.order.specialCleanLighting && this.order.specialCleanLighting.length > 0) {
      //     return this.order.specialCleanLighting.indexOf(value) > -1
      //   } else {
      //     return false
      //   }
      // },

      changeSpecialCleanWorkItem() {
        for (let index = 0; index < this.order.orderSpecialCleans.length; index++) {
          this.$emit('changeValidateSpecialCleanWorkItem', this.validateSpecialCleanWorkItem(index).result, this.validateSpecialCleanWorkItem(index).message)
          if (!this.validateSpecialCleanWorkItem(index).result) {
            return
          }
        }
      },

    /**
     * 発注先名削除ボタン
     */
     removeRpSupplier(idx) {
      if (this.order.estimationRoundPlusSupplier.length == 1) {
        this.order.estimationRoundPlusSupplier[0].supplier = ''
        return
      }
      this.order.estimationRoundPlusSupplier.splice(idx, 1)
    },

    /**
     * 発注先名追加ボタン
     */
     addRpSupplier() {
      let r = {
        supplier: ''
      }
      this.order.estimationRoundPlusSupplier.push(r)
    },

    /**
     * 作業曜日、時間 削除ボタン
     */
     removeWorkRemarks(idx, contents, remarkIdx, arrContents) {
      if (arrContents) {
        // 日常清掃、管理員業務の場合
        if (this.order[arrContents][idx][contents].length == 1) {
          this.order[arrContents][idx][contents][0].workDays = []
          this.order[arrContents][idx][contents][0].startTime = ''
          this.order[arrContents][idx][contents][0].endTime = ''
          return
        }
        this.order[arrContents][idx][contents].splice(remarkIdx, 1)
      } else {
        // ラウンドプラスの場合
        if (this.order[contents].length == 1) {
          this.order[contents][0].workDays = []
          this.order[contents][0].startTime = ''
          this.order[contents][0].endTime = ''
          return
        }
        this.order[contents].splice(idx, 1)
      }
    },

    /**
     * 作業曜日、時間 追加ボタン
     */
     addWorkRemarks(contents, isSetFlag, idx, arrContents) {
      let element = {
        workDays: [],
        startTime: '',
        endTime: ''
      }

      if (arrContents) {
        // 日常清掃、管理員業務の場合
        if (isSetFlag) {
          this.order[arrContents][idx][contents] = []
        }
        this.order[arrContents][idx][contents].push(element)
      } else {
        // ラウンドプラスの場合
        if (isSetFlag) {
          this.$set(this.order, contents, [])
        }
        this.order[contents].push(element)
      }
    },

    /**
     * 作業曜日、時間をセット
     */
    setRemarksVal(data, contents, arrContents, idx) {
      // 見積データにremarksがある場合
      if (data.remarks && (data.remarks.length > 0 || Object.keys(data.remarks).length > 0)) {
        let remarks = data.remarks
        if (Array.isArray(remarks)) {
          // remarksが配列の場合
          for (let index = 0; index < remarks.length; index++) {
            if (arrContents) {
              // 日常清掃、管理員業務の場合
              this.addWorkRemarks(contents, false, idx, arrContents)
              this.order[arrContents][idx][contents][index].workDays = remarks[index].week
              this.order[arrContents][idx][contents][index].startTime = remarks[index].start
              this.order[arrContents][idx][contents][index].endTime = remarks[index].end
            } else {
              // ラウンドプラスの場合
              this.addWorkRemarks(contents, false)
              this.order[contents][index].workDays = remarks[index].week
              this.order[contents][index].startTime = remarks[index].start
              this.order[contents][index].endTime = remarks[index].end
            }
          }
        } else {
          // remarksがオブジェクトの場合
          if (arrContents) {
            // 日常清掃、管理員業務の場合
            this.addWorkRemarks(contents, false, idx, arrContents)
            this.order[arrContents][idx][contents][0].workDays = remarks.week
            this.order[arrContents][idx][contents][0].startTime = remarks.start
            this.order[arrContents][idx][contents][0].endTime = remarks.end
          } else {
            // ラウンドプラスの場合
            this.addWorkRemarks(contents, false)
            this.order[contents][0].workDays = remarks.week
            this.order[contents][0].startTime = remarks.start
            this.order[contents][0].endTime = remarks.end
          }
        }
      } else {
        // 見積データにremarksがない場合は、配列に初期値で1つ要素をセット
        if (arrContents) {
          // 日常清掃、管理員業務の場合
          this.addWorkRemarks(contents, false, idx, arrContents)
        } else {
          // ラウンドプラスの場合
          this.addWorkRemarks(contents, false)
        }
      }
    },

    /**
     * 過去の受注データで作業曜日、作業時間が無ければ配列の要素を追加
     */
    historicalDataRemarksRegeneration(arrName, contentsName) {
      for (let index = 0; index < this.order[arrName].length; index++) {
        // 配列内の要素が文字列や空の場合のみ配列に要素を追加
        if (this.order[arrName][index][contentsName] && (typeof this.order[arrName][index][contentsName][0] === "string" || this.order[arrName][index][contentsName].length == 0)) {
          // 過去の受注データの作業曜日の値を退避
          let workDays = this.order[arrName][index][contentsName]
          // 配列に作業曜日、作業時間の要素を追加
          this.addWorkRemarks(contentsName, true, index, arrName)
          // 退避していた作業曜日をセット
          this.order[arrName][index][contentsName][0].workDays = workDays
        }
      }
    },

    /**
     * ラベルをセット
     */
    setLabel(arrName, labelName, categoryCode, idx) {
      let cnt = -1
     for (let index = 0; index < this.estimateData.details.length; index++) {
        if (this.estimateData.details[index].categoryCode == categoryCode) {
          cnt++
          if (cnt == idx) {
            if (this.order[arrName][idx]) {
              // 文字数が20文字より多い場合、20文字以降は「・・・」に変更
              if (this.estimateData.details[index].itemName.length > 20) {
                this.order[arrName][idx][labelName] = this.estimateData.details[index].itemName.substring(0, 20) + '・・・'
              } else {
                this.order[arrName][idx][labelName] = this.estimateData.details[index].itemName
              }
            }
            return
          }
        }
      }
    },


 
    /**
     * 実施月の割り当て
     */
      workMonthAutoInput(firstWorkMonth, cleanCount) {
        // 清掃希望回数を文字列から数値型に変換
        let count = Number(cleanCount)
        // 初回作業月を配列から文字列に変換
        let monthStr = firstWorkMonth.join()
        // 文字列に変換した初回作業月を数字のみに変換
        const regex = /[^0-9]/g
        const result = monthStr.replace(regex, "")
        const month = parseInt(result)
        // 割り当て月間隔
        let monthlyInterval = 12 / count
        if (Number.isInteger(monthlyInterval)) {
          let resultMonth = []
          for (let index = 1; index <= count; index++) {
            let total
            total = ( month + (monthlyInterval * (index-1)) ) % 12
            if (total == 0) {
              resultMonth.push('12月')
            } else {
              resultMonth.push(String(total) + '月')
            }
            
          }
          return resultMonth
        }
      },

      /**
       * 添付ファイル
       */
      attachmentChange(targetAttachmentName, attachmentInfo) {
        if (!this.order[targetAttachmentName]) {
          this.order[targetAttachmentName] = orderManager.createEmptyAttachment()
        }
        if (attachmentInfo) {
          this.order[targetAttachmentName].originalName = attachmentInfo.filename
          this.order[targetAttachmentName].content = attachmentInfo.content
        } else {
          this.order[targetAttachmentName] = orderManager.createEmptyAttachment()
        }
      },

      /**
       * 配信用テンプレート 変更
       */
       templateChange({html}) {
        this.templateHTML = html
        this.order.template = this.templateHTML
      },

      /**
       * 配信用テンプレート
       */
      async estimationDetailsCategorySelected(val) {
        this.templateHTML = ''
        let estimationWaterTankCategoryQty
        let estimationWaterTankCategoryUnitPrice
        let pressPumpInspectCategoryQty
        let pressPumpInspectCategoryUnitPrice
        let estimationDorainPipeCategoryQty
        let estimationDorainPipeCategoryUnitPrice
        let estimationDorainPipeEffectiveDate
        let isSelection
        let glassCleanCategoryQty
        let glassCleanCategoryUnitPrice
        let plantingItemName
        let plantingCategoryQty
        let plantingCategoryUnitPrice
        let evInspectCategoryUnitPrice
        let isParking
        let isClientFax
        let clientAddress

        if (val) {
          this.order.estimationDetailsCategory = val.names
          this.order.estimationDetailsCategoryCode = val.codes
          this.categoryCode = val.codes
        }

        for (let index = 0; index < this.estimateData.details.length; index++) {
          // 貯水槽清掃
          if (this.estimateData.details[index].categoryCode == '12') {
            if (this.estimateData.details[index].categoryQty != '') {
              estimationWaterTankCategoryQty = this.estimateData.details[index].categoryQty
            }
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              estimationWaterTankCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
            if (Object.keys(this.estimateData.details[index].subItemModalData).length && this.estimateData.details[index].subItemModalData.toolTip.駐車場代追加 == 'なし') {
              isParking = '有'
            } else {
              isParking = '無'
            }
          }

          // 増圧ポンプ点検
          if (this.estimateData.details[index].categoryCode == '20') {
            if (this.estimateData.details[index].categoryQty != '') {
              pressPumpInspectCategoryQty = this.estimateData.details[index].categoryQty
            }
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              pressPumpInspectCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
          }

          // 排水管清掃
          if (this.estimateData.details[index].categoryCode == '11') {
            if (this.estimateData.details[index].categoryQty != '') {
              estimationDorainPipeCategoryQty = this.estimateData.details[index].categoryQty
            }
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              estimationDorainPipeCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
            if (Object.keys(this.estimateData.details[index].subItemModalData).length && this.estimateData.details[index].subItemModalData.toolTip.作業日 == '土日祝日' && this.estimateData.details[index].subItemModalData.toolTip.施工日数 == '単日') {
              estimationDorainPipeEffectiveDate = '土日祝日1日'
              isSelection = ''
            } else if (Object.keys(this.estimateData.details[index].subItemModalData).length && this.estimateData.details[index].subItemModalData.toolTip.作業日 == '平日' && this.estimateData.details[index].subItemModalData.toolTip.施工日数 == '単日') {
              estimationDorainPipeEffectiveDate = '平日1日'
              isSelection = ''
            } else {
              estimationDorainPipeEffectiveDate = '【土日祝日2日間／土日祝日1日／平日1日／平日1日＋予備日（土日祝日）1日】'
              isSelection = '※選択する'
            }
          }

          // ガラス清掃
          if (this.estimateData.details[index].categoryCode == '10') {
            if (this.estimateData.details[index].categoryQty != '') {
              glassCleanCategoryQty = this.estimateData.details[index].categoryQty
            }
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              glassCleanCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
          }

          // 植栽剪定
          if (this.estimateData.details[index].categoryCode == '13') {
            if (Object.keys(this.estimateData.details[index].subItemModalData).length) {
              plantingItemName = this.estimateData.details[index].subItemModalData.display[0].name
            }
            if (this.estimateData.details[index].categoryQty != '') {       
              plantingCategoryQty = this.estimateData.details[index].categoryQty
            }
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              plantingCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
          }

          // エレベーター保守点検
          if (this.estimateData.details[index].categoryCode == '19') {
            if (this.estimateData.details[index].categoryUnitPrice != '') {
              evInspectCategoryUnitPrice = this.estimateData.details[index].categoryUnitPrice
            }
          }
        }

        if (this.estimateData.clientFax != '') {
          isClientFax = `ＦＡＸ: ${this.estimateData.clientFax}`
        } else {
          isClientFax = ``
        }

        if (!this.estimateData.clientAddress1 && !this.estimateData.clientAddress2) {
          clientAddress = ``
        } else if (this.estimateData.clientAddress1 && !this.estimateData.clientAddress2) {
          clientAddress = this.estimateData.clientAddress1
        } else {
          clientAddress = this.estimateData.clientAddress1 + this.estimateData.clientAddress2
        }

        // 貯水槽清掃のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('貯水槽清掃') > -1) {
          let template =
          `★貯水槽清掃
          【作業協力会社】
          ○○○○
          【業務内容】
          ・貯水槽清掃（水質検査、ポンプ点検含む）
          年${estimationWaterTankCategoryQty}回 施工月〇〇月※初回〇〇年〇〇月
          ${estimationWaterTankCategoryUnitPrice}円/回
          駐車場(有・無)：${isParking}`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }

        // 増圧ポンプ点検のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('増圧ポンプ点検') > -1) {
          let template =
          `★増圧ポンプ点検
          【作業協力会社】
          ○○○○
          【業務内容】
          ・増圧ポンプ点検
          年${pressPumpInspectCategoryQty}回 施工月〇〇月※初回〇〇年〇〇月
          ${pressPumpInspectCategoryUnitPrice}円/回
          駐車場(有・無)：`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }

        // 排水管清掃のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('排水管清掃') > -1) {
          let template = 
          `★排水管清掃
          【作業協力会社】
          ○○○○
          【業務内容】
          ・排水管清掃
          年${estimationDorainPipeCategoryQty}回 施工月〇〇月※初回〇〇年〇〇月
          ${estimationDorainPipeCategoryUnitPrice}円/回
          駐車場(有・無)：

          その他 ：${estimationDorainPipeEffectiveDate}の想定です。${isSelection}
          前回清掃実績 〇〇年〇〇月 ※あれば記載`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }

        // ガラス清掃のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('ガラス清掃') > -1) {
          let template =
          `★ガラス清掃
          【作業協力会社】
          ○○○○
          【業務内容】
          ・ガラス清掃
          年${glassCleanCategoryQty}回 施工月○○月※初回○○年○○月
          ${glassCleanCategoryUnitPrice}円/回
          駐車場(有・無)：

          【色塗り図面／清掃仕様書／過去の報告書／現地写真】を添付致します。※添付資料を選択`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }

        // 植栽剪定のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('植栽剪定') > -1) {
          let template =
          `★植栽作業
          【作業協力会社】
          ○○○○
          【作業協力会社】
          ○○○○
          【業務内容】
          ・${plantingItemName}
          年${plantingCategoryQty}回 施工月○○月※初回○○年○○月
          ${plantingCategoryUnitPrice}円/回
          駐車場(有・無)：

          【現地写真／過去の報告書／図面】を添付致します。※添付資料を選択`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }
        
        // エレベーター保守点検のテンプレート
        if (this.order.estimationDetailsCategory.indexOf('エレベーター保守点検') > -1) {
          let template =
          `★EV保守
          【作業協力会社】
          ○○○○
          【業務内容】
          ・EV保守点検 【FM／PDG契約】※選択する
          初回○○年○○月
          ${evInspectCategoryUnitPrice}円/回
          駐車場(有・無)：

          過去の報告書を添付致します。※あれば`

          template = template.split(/\r\n|\n/).join('<br>') + '<br>'
          if (this.templateHTML != '') {
            this.templateHTML = this.templateHTML + '<br>' + template
          } else {
            this.templateHTML = this.templateHTML + template
          }
        }

        // お客様宛のテンプレート
        let template =
        `★お客様宛
        ${this.estimateData.clientName} ${this.estimateData.clientPersonName}様より以下の物件の発注をいただきました。
        お忙しい所申し訳ございませんが、ご対応の程よろしくお願い致します。

        【物件情報】
        ・物件名：${this.estimateData.buildingName}
        ・住所：${this.estimateData.buildingAddress2}
        ・物件規模：${this.estimateData.buildingInfo.stairs}階 ${this.estimateData.buildingInfo.households}戸
        ・管理室：警備あり
        ・入館方法：〇〇
        【作業協力会社】
        ○○○○
        【業務内容】
        ・【消防設備点検／建築物定期調査／特定建築物定期調査／防火設備定期検査／巡回設備点検】※どれかを選択
        年〇〇回 施工月〇〇月※初回〇〇年〇〇月
        〇〇円/回
        駐車場(有・無)：

        【過去の報告書／設備一覧】を添付致します。→消防
        【過去の報告書／面積表及び確認済証】を添付致します。→建築、特建
        【過去の報告書／報告書書式】→巡回設備点検
        ※作業によって添付資料を選択

        得意先：${this.estimateData.clientName}
        ${clientAddress}
        ＴＥＬ: ${this.estimateData.clientPhoneNumber}
        ${isClientFax}`

        template = template.split(/\r\n|\n/).join('<br>') + '<br>'
        if (this.templateHTML != '') {
          this.templateHTML = this.templateHTML + '<br>' + template
        } else {
          this.templateHTML = this.templateHTML + template
        }

        this.order.template = this.templateHTML
      },

      orderDataEmit() {
        this.$emit('toOrderData', this.order)
        // this.$emit('changeValidateContractDate', this.validateContractDate.result, this.validateContractDate.message)
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
        this.$emit('changeValidateClassification', this.validateClassification.result, this.validateClassification.message)
        if (this.order.orderSpecialCleans) {
          for (let index = 0; index < this.order.orderSpecialCleans.length; index++) {
            this.$emit('changeValidateSpecialCleanWorkItem', this.validateSpecialCleanWorkItem(index).result, this.validateSpecialCleanWorkItem(index).message, index)
            if (!this.validateSpecialCleanWorkItem(index).result) {
              return
            }
          }
        }
      },

      /**
       * 契約日変更イベント
       */
      changeContractDate() {
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
        // 区分によって契約終了日の初期値を入れる
        this.setContractEndDate()
      },

      /**
       * 区分によって契約終了日の初期値を入れる
       */
      setContractEndDate() {
        // スポットの場合は、1か月後
        if (this.order.classification && this.order.classification.length && this.order.classification[0] == '3') {
          this.order.contractEndDate = moment(this.order.contractDate).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD')
        } else {
          this.order.contractEndDate = moment(this.order.contractDate).add(1, 'Y').subtract(1, 'd').format('YYYY-MM-DD')
        }
        this.changeContractEndDate()
      },

      changeContractEndDate() {
        this.$emit('changeValidateContractEndDate', this.validateContractEndDate.result, this.validateContractEndDate.message)
      },

      changeRegularCleanFirstWorkMonth(value, index) {
        if (value && value.length > 0 && this.order.orderRegularCleans[index].regularCleanUnit == '回/年') {
          this.order.orderRegularCleans[index].regularCleanImplementationMonth = this.workMonthAutoInput(value, this.order.orderRegularCleans[index].regularCleanCount)
        }
      },

      changeGlassCleanFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.glassCleanUnit == '回/年') {
          this.order.glassCleanImplementationMonth = this.workMonthAutoInput(value, this.order.glassCleanCount)
        }
      },

      changeEstimationDorainPipeFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.estimationDorainPipeUnit == '回/年') {
          this.order.estimationDorainPipeImplementationMonth = this.workMonthAutoInput(value, this.order.estimationDorainPipeCount)
        }
      },

      changeEstimationWaterTankFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.estimationWaterTankUnit == '回/年') {
          this.order.estimationWaterTankImplementationMonth = this.workMonthAutoInput(value, this.order.estimationWaterTankCount)
        }
      },

      changePlantingFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.plantingUnit == '回/年') {
          this.order.plantingImplementationMonth = this.workMonthAutoInput(value, this.order.plantingCount)
        }
      },

      changeSpecialCleanFirstWorkMonth(value, index) {
        if (value && value.length > 0 && this.order.orderSpecialCleans[index].specialCleanUnit == '回/年') {
          this.order.orderSpecialCleans[index].specialCleanImplementationMonth = this.workMonthAutoInput(value, this.order.orderSpecialCleans[index].specialCleanCount)
        }
      },

      changeFireInspectFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.fireInspectUnit == '回/年') {
          this.order.fireInspectMonth = this.workMonthAutoInput(value, this.order.fireInspectCount)
        }
      },

      changeConstructionEquipmentInspectFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.constructionEquipmentInspectUnit == '回/年') {
          this.order.constructionEquipmentInspectImplementationMonth = this.workMonthAutoInput(value, this.order.constructionEquipmentInspectCount)
        }
      },

      changeSpecificInspectFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.specificInspectUnit == '回/年') {
          this.order.specificInspectImplementationMonth = this.workMonthAutoInput(value, this.order.specificInspectCount)
        }
      },

      changePressPumpInspectFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.pressPumpInspectUnit == '回/年') {
          this.order.pressPumpInspectImplementationMonth = this.workMonthAutoInput(value, this.order.pressPumpInspectCount)
        }
      },

      changeWaterSupplyDrainagePumpInspectionFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.waterSupplyDrainagePumpInspectionUnit == '回/年') {
          this.order.waterSupplyDrainagePumpInspectionImplementationMonth = this.workMonthAutoInput(value, this.order.waterSupplyDrainagePumpInspectionCount)
        }
      },

      changeSimpleWaterSupplyFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.simpleWaterSupplyUnit == '回/年') {
          this.order.simpleWaterSupplyImplementationMonth = this.workMonthAutoInput(value, this.order.simpleWaterSupplyCount)
        }
      },
      
      changeTubeBulbSupportFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.tubeBulbSupportUnit == '回/年') {
          this.order.tubeBulbSupportImplementationMonth = this.workMonthAutoInput(value, this.order.tubeBulbSupportCount)
        }
      },

      changeBulkyWasteSupportFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.bulkyWasteSupportUnit == '回/年') {
          this.order.bulkyWasteSupportImplementationMonth = this.workMonthAutoInput(value, this.order.bulkyWasteSupportCount)
        }
      },

      changeMerchandiseSalesFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.merchandiseSalesUnit == '回/年') {
          this.order.merchandiseSalesImplementationMonth = this.workMonthAutoInput(value, this.order.merchandiseSalesCount)
        }
      },

      changeEmergencyResponseFirstWorkMonth(value) {
        if (value && value.length > 0 && this.order.emergencyResponseUnit == '回/年') {
          this.order.emergencyResponseImplementationMonth = this.workMonthAutoInput(value, this.order.emergencyResponseCount)
        }
      },

      /**
       * スポット清掃初回作業月変更イベント
       * @param value 値
       * @param i インデックス
       */
      changeSpotFirstWorkMont(value, i) {
        if (value && value.length > 0 && this.order.orderSpot[i].unit == '回/年') {
          this.order.orderSpot[i].implementationMonth = this.workMonthAutoInput(value, this.order.orderSpot[i].count)
        }
      },

      /**
       * カテゴリコードがない初回作業月変更イベント
       * @param value 値
       * @param i インデックス
       */
      changeOtherFirstWorkMonth(value, i) {
        if (value && value.length > 0 && this.order.orderOthers[i].unit == '回/年') {
          this.order.orderOthers[i].implementationMonth = this.workMonthAutoInput(value, this.order.orderOthers[i].count)
        }
        this.$set(this.order.orderOthers[i], 'firstWorkMonth', value)
      },

      /**
       * 区分変更イベント
       */
      changeClassification() {
        this.$emit('changeValidateClassification', this.validateClassification.result, this.validateClassification.message)
        // 区分によって契約終了日の初期値を入れる
        this.setContractEndDate()
      },
    }
  }
  </script>