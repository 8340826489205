<template>
  <div>
    <label :for="name" class="block text-xs font-bold text-gray-700 relative">
      {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="flex relative">
      <WmsSuggestInput
        id="hour-input"
        ref="hourSuggest"
        name="hour"
        caption=""
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="hourItems"
        value="text"
        displayField="text"
        :filter="{}"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="false"
        :required="required"
        v-model="hourValue"
        :error="error"
        :errorMessage="errorMessage"
        :errorColor="errorColor"
        class="suggest-input"
        @change="onHourChange"
        @blur="onHourBlur"
        :style="getHourStyle()"
      />
      <span class="separate">：</span>
      <WmsSuggestInput
        id="minute-input"
        ref="minuteSuggest"
        name="minute"
        caption=""
        :dynamic="false"
        :incrementalSearch="false"
        :selectionItems="minuteItems"
        value="text"
        displayField="text"
        :filter="{}"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="false"
        v-model="minuteValue"
        :errorColor="errorColor"
        class="suggest-input"
        @change="onMinuteChange"
        @blur="onMinuteBlur"
      />
    </div>
  </div>
</template>
<script>
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'

export default {
  inheritAttrs: false,

  components: {
    WmsSuggestInput
  },
  
  props: {
    value: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    caption: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: true
    },

    hourItems: {
      type: [],
      default: () => [
        { text: '1' },
        { text: '2' },
        { text: '3' },
        { text: '4' },
        { text: '5' },
        { text: '6' },
        { text: '7' },
        { text: '8' },
        { text: '9' },
        { text: '10' },
        { text: '11' },
        { text: '12' },
        { text: '13' },
        { text: '14' },
        { text: '15' },
        { text: '16' },
        { text: '17' },
        { text: '18' },
        { text: '19' },
        { text: '20' },
        { text: '21' },
        { text: '22' },
        { text: '23' }
      ]
    },

    minuteItems: {
      type: [],
      default: () => [
        { text: '00' },
        { text: '15' },
        { text: '30' },
        { text: '45' }
      ]
    },

    error: {
      type: Boolean,
      default: false
    },

    errorMessage: {
      type: String,
      default: ''
    },

    errorColor: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      hourValue: '',
      minuteValue: ''
    }
  },

  created() {
    this.setValue(this.value)
  },

  watch: {
    value(newValue) {
      this.setValue(newValue)
    }
  },

  methods: {
    /**
     * 時 Change イベント
     */
    onHourChange() {
      this.setParentValue()
    },

    /**
     * 時 Blur イベント
     */
    onHourBlur() {
      // 入力不可値は空とする
      if (this.hourValue.length > 0 && !this.isHour(this.hourValue)) {
        this.$refs.hourSuggest.clear()
        this.hourValue = ''
      }

      this.setParentValue()
    },

    /**
     * 分 Change イベント
     */
    onMinuteChange() {
      this.setParentValue()
    },

    /**
     * 分 Blur イベント
     */
    onMinuteBlur() {
      // 入力不可値は空とする
      if (this.minuteValue.length > 0 && !this.isMinute(this.minuteValue)) {
        this.$refs.minuteSuggest.clear()
        this.minuteValue = ''
      }

      // ゼロパティング
      if (this.minuteValue.length == 1) this.minuteValue = '0' + this.minuteValue

      this.setParentValue()
    },

    /**
     * 値設定（parent設定）
     */
    setValue(value) {
      if (!value || value == '') {
        this.hourValue = '';
        this.minuteValue = '';
      } else {
        const vals = value.split(':')
        if (vals.length == 2) {
          this.hourValue = vals[0];
          this.minuteValue = vals[1];
        }
      }
    },

    /**
     * 値設定（parentへ返却）
     */
    setParentValue() {
      let value = ''
      if (this.hourValue.length > 0 || this.minuteValue.length > 0) {
        value = this.hourValue + ':' + this.minuteValue
      }

      this.$emit('input', value)
      this.$emit('change', value)
    },

    /**
     * 時チェック
     */
    isHour(value) {
      if ( value != '' && !isNaN(Number(value)) ) {
        const ret = this.hourItems.some(item => item.text === value)
        if (ret) return true
      }
      return false
    },

    /**
     * 分チェック
     */
    isMinute(value) {
      const min = Number(value)
      if ( !isNaN(min) && min >= 0 && min < 60) {
        return true
      } else {
        return false
      }
    },

    /**
     * 時 CSS取得
     */
    getHourStyle() {
      if (this.required) {
        return 'width: 65px;'
      } else {
        return 'width: 45px;'
      }
    }
  }
}
</script>
<style scoped>
#minute-input {
  width: 45px;
}
.suggest-input >>> input {
  text-align: right;
  min-width: 45px;
}
.separate {
  margin-top: auto;
  margin-bottom: auto;
  padding-top:4px;
}
</style>