<template>
  <div>
    <!-- <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.active">{{ tab.name }}</option>
      </select>
    </div> -->
    <div class="px-4">
      <div class="border-b border-gray-200 overflow-auto">
        <nav class="flex space-x-4 h-7">
          <a 
            v-for="tab in tabList" 
            :key="tab.name" 
            :class="[tab.active ? 'border-indigo-500 text-indigo-600 font-bold' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap flex items-center pb-2 px-1 border-b-2 font-medium text-xs cursor-pointer']"
            @click="tabSelected(tab)"
          >
            {{ tab.name }}
            <span v-if="tab.badge" :class="[tab.active ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{ tab.badge }}</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// const tabs = [
//   { name: 'My Account', href: '#', current: false },
//   { name: 'Company', href: '#', current: false },
//   { name: 'Team Members', href: '#', current: true },
//   { name: 'Billing', href: '#', current: false },
// ]

export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tabList: []
    }
  },

  watch: {
    tabs() {
      this.createTabList()
    }
  },

  created() {
    this.createTabList()
  },

  methods: {
    createTabList() {
      this.tabList = [ ...this.tabs ]
      const activeTab = this.tabList.find((tab) => {
        return tab.active
      })
      if (activeTab) {
        this.tabSelected(activeTab)
      }
    },

    tabSelected(tab) {
      this.tabList.forEach((t) => {
        t.active = false
      })
      tab.active = true
      this.$emit('change', tab)
    }
  }
}
</script>