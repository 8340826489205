<template>
  <div>
    <div class="mb-5 text-blue-800 font-bold">
      ※全て発注金額
    </div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1 p-3">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column2" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div v-if="c.min" class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c.min }} ～ {{ c.max }} 戸
            </div>
            <div v-else class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="single[i] && single[i] != ''">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single[i].price"
                @change="changeData(single[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="family[i].price"
                @change="changeData(family[i])"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="text-center">
            <!-- 駐車場代加算エリア -->
            <div class="font-bold text-blue-700">
              （駐車場加算エリア：{{ eriaName.replace(/\r\n/g, '・') }}）
            </div>
          </td>
        </tr>
      </table>


      
      <!-- 追加料金 -->
      <table class="ml-10 border-orange rounded">
        <tr class="orange">
          <th  colspan="2">
            <div class="p-3">
              追加料金・係数など
            </div>
          </th>
        </tr>
        <tr v-for="(a, i) in add" :key="i" class="hover-color2 text-right" :class="i%2!=0?'border-orange-b':''">
          <th class="text-left th-add-name">
            <div class="ml-5">
              {{ a.name }}
            </div>
          </th>
          <td>
            <div v-if="a.priceCode.indexOf('recheck')!=-1">
              <NumericTextInput
                name="price"
                class="mt-2 mb-2 text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50 mr-5"
                v-model="a.price"
                :decimalPlace="1"
                @change="changeData(a)"
              />
            </div>
            <div v-else>
              <NumericTextInput
                name="price"
                class="mt-2 mb-2 text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50 mr-5"
                v-model="a.price"
                @change="changeData(a)"
              />
            </div>
          </td>
        </tr>
      </table>

      <!-- エリア追加料金 -->
      <table class="ml-10 border-orange rounded">
        <tr class="orange">
          <th  colspan="2" class="th-area-add">
            <div class="p-3">
              千葉・埼玉・城東・城北エリア
            </div>
          </th>
        </tr>
        <tr class="hover-color2 text-right">
          <th class="text-left bg-white">
            <div class="ml-10 whitespace-pre">
              {{ eria }}
            </div>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as eriaManager from '@managers/eriaManager'
import * as utils from '@libs/utils'

/**
 * 消防
 */
const CATEGORY = '15'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // タイプカラム
      column1: [
        { name: '' },
        { name: 'シングル', class: 'ml-5 bg-teal-50' },
        { name: 'ファミリー', class: 'ml-5 bg-blue-50' },
      ],
      // 戸数カラム
      column2: [
        { name: '消火器・避難器具のみ' },
        { min: '1', max: '10' },
        { min: '11', max: '15' },
        { min: '16', max: '20' },
        { min: '21', max: '25' },
        { min: '26', max: '30' },
        { name: '駐車場代' },
      ],
      // 追加金額カラム
      column3: [
        { name: '消火栓' },
        { name: 'スプリンクラー' },
      ],
      // 単価
      single: [],
      family: [],
      // エリア追加
      eriaPrice: [],
      // エリア名
      eriaName: '',
      eria: '',
      // 追加その他
      add: []
    }
  },

  created() {
    this.dataByCategory()
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let s1 = []
      let f1 = []
      let only = {}
      let pp = {}
      let other = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode
        switch (code) {
          // シングル
          case '1':
            s1.push(d)
            break;

          // ファミリ
          case '2':
            if (d.min == '') {
              only = d
            } else {
              f1.push(d)
            }
            break;

          // 駐車場代
          case 'parking':
            pp = d
            break;
          
          default:
            other.push(d)
            break;
        }
      }

      this.single = this.setSort(s1)
      // 空行を入れる
      this.single.unshift('')

      this.family = this.setSort(f1)
      // 消火器・避難器具のみを1行目に追加
      this.family.unshift(only)
      // 駐車場代を追加
      this.family.push(pp)
      this.add = this.setSort2(other)

      // エリア範囲
      let tokio = await eriaManager.getFire(this, '東京都')
      // let chiba = await eriaManager.getFire(this, '千葉県')
      // let saitama = await eriaManager.getFire(this, '埼玉県')

      
        let r1 = []
        let r2 = []
      for (let j = 0; j < tokio.length; j++) {
        const t = tokio[j]
        if (t.eria == '港区・中央区・千代田区') {
          r1.push(t)
        } else {
          r2.push(t)
        }
      }

      this.eriaName = this.getEria(r1)
      this.eria = this.getEria(r2)
      this.eria += '\r\n千葉県\r\n埼玉県'
    },

    /**
     * 戸数によるソート
     * @param target 対象データ
     */
    setSort(target) {
      let result = []
      for (let l = 0; l < this.column2.length; l++) {
        const sort = this.column2[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (sort.min && er.min != '' && er.min == sort.min) {
            result.push(er)
          }
        }
      }
      return result
    },
    
    /**
     * 項目によるソート
     * @param target 対象データ
     */
    setSort2(target) {
      const sortArr = ['extinguisher', 'sprinkler', 'extinguisherOver20', 'sprinklerOver20', 'recheck0', 'recheck1', 'reduction0', 'reduction1', 'eria2']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * エリア名生成
     */
    getEria(target) {
      let result = []
      for (let i = 0; i < target.length; i++) {
        const e = target[i]
        result.push(e.city + ' ' + e.street)
      }
      return result.join('\r\n')
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode
      let name1 = ''
      let name2 = ''

      switch (code) {
        case 'extinguisher':
        case 'sprinkler':
          name1 = target.name
          break;

        case 'parking':
          name1 = '駐車場代'
          break;
      
        case '1':
        case '2':
          name1 = `${target.min}~${target.max}戸`
          name2 = '・シングル'
          if (code == '2') {
            name2 = 'ファミリー'
          }
          break;

        default:
          name1 = target.name
          if (code.indexOf('recheck') != -1) {
            target.coef = true
            name1 += '（係数）'
          }
          break;
      }
      target.itemName = name1 + name2

      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}

.th-add-name {
  min-width: 110px;
}
.th-area-add {
  min-width: 130px;
}
</style>
