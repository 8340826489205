<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app">

          <!-- 承認フロー -->
          <div class="app-flow">

            <!-- 優先順位 -->
            <div class="my-8 drop ml-12">
              <span class="text-sm text-gray-500">優先順位（使用頻度）<br> <span class="text-xs">数値が大きいほど上にきます</span></span>
              <NumericTextInput
                name="priority"
                v-model="approval.priority"
                class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block w-1/3 rounded"
              />
            </div>

            <!-- 承認 ルート名 -->
            <div class="text-sm text-gray-500 ml-12">ルート名</div>
            <div class="px-1 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider border-gray rounded ml-12 mr-16 mb-10">
              <input
                type="text" 
                name="routeName"
                v-model="approval.routeName"
                class="py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent rounded w-full"
              />
            </div>

            <!-- 承認1 -->
            <div class="con">
              <div class="w-2/5 ml-2">
                <table class="table-auto table">
                  <thead>
                    <tr>
                      <th class="py-2 text-center text-xs font-medium text-gray-400 tracking-wider border-gray w-full app1-color">
                        承認者1
                      </th>
                      <th>
                        <!-- プラスボタン -->
                        <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowApproval(1)" />
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(flow1, index) in approval.approver1" :key="index">
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-700 tracking-wider border-gray td-height w-full">
                      <form>
                        <select
                          name="approvalFlow1"
                          v-model="flow1.userId"
                          @change="selectedApprover(index, flow1.userId, 1)"
                          class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-gray w-1/2 text-base rounded pl-2 w-full border-transparent"
                        >
                          <option
                            v-for="value in approverList1" :key='value.id'
                            :value="value.userId"
                            class="bg-white text-l">
                            {{ value.userName }}
                          </option>
                        </select>
                      </form>
                    </td>
                    <td class="px-0.5 py-0.5 whitespace-nowrap text-sm font-medium">
                      <a href="#" @click="deleteApprovalRow(index, 1)">
                        <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                      </a>
                    </td>
                  </tr>
                  <span class="text-sm text-gray-500 p-3" v-if="!approval.approver1.length">1番目の承認者は設定されていません</span>
                </table>
              </div>
              
              <!-- 承認2 -->
              <div class="w-2/5 ml-2">
                <table class="table-auto table">
                  <thead>
                    <tr>
                      <th class="py-2 text-center text-xs font-medium text-gray-400 tracking-wider border-gray w-full app1-color">
                        承認者2
                      </th>
                      <th>
                        <!-- プラスボタン -->
                        <PlusCircleIcon class="text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer col-actions" @click="addRowApproval(2)" />
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(flow2, index) in approval.approver2" :key="index">
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-700 tracking-wider border-gray td-height">
                      <form>
                        <select
                          name="approver2"
                          v-model="flow2.userId"
                          @change="selectedApprover(index, flow2.userId, 2)"
                          class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-gray w-1/2 text-base rounded pl-2 w-full border-transparent"
                        >
                          <option
                            v-for="value in approverList2" :key='value.id'
                            :value="value.userId"
                            class="bg-white text-l">
                            {{ value.userName }}
                          </option>
                        </select>
                      </form>
                    </td>
                    <td class="px-0.5 py-0.5 whitespace-nowrap text-sm font-medium">
                      <a href="#" @click="deleteApprovalRow(index, 2)">
                        <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                      </a>
                    </td>
                  </tr>
                  <span class="text-sm text-gray-500 p-3" v-if="!approval.approver2.length">2番目の承認者は設定されていません</span>
                </table>
              </div>
            </div>
          </div>
          <div>
            <PrimaryButton text="登録" size="normal px-10 py-3 place-items-end mt-3 btn" @click="register()"/>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/solid'
import PrimaryButton from '@components/PrimaryButton.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as estimateManager from '@managers/estimateManager'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import * as logManager from '@managers/logManager'

export default {
  components: {
    Modal,
    TrashIcon,
    PlusCircleIcon,
    PrimaryButton,
    NumericTextInput
  },

  props: {
    target: {
      type: Object
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      // 登録する内容
      approval: {
        // 優先度
        priority: '0',
        // ルート名
        routeName: '',
        // 承認者1
        approver1: [
          {
            userId: '',
            userName: '',
            chatworkAccountId: '',
            // chatworkApiToken: ''
          }
        ],
        // 承認者2
        approver2: [
          {
            userId: '',
            userName: '',
            chatworkAccountId: '',
            // chatworkApiToken: ''
          }
        ],
      },
      // 承認権限者の前データ
      allApprovers: []
    }
  },

  async created() {
    let all = await backend.getData('user/getAllByEstimateApprove')
    this.allApprovers = all.data.data
  },

  computed: {

    /**
     * 承認者リスト取得
     */
    approverList1() {
      let result = []
      for (let i = 0; i < this.allApprovers.length; i++) {
        let ap = this.allApprovers[i]
        if (ap.estimateApproveAuth) {
          // result.push({userId: ap.mstUserId, userName: ap.userName, chatworkAccountId: ap.chatworkAccountId, chatworkApiToken: '12e6247b22aa5826875a69997587ea01'})
          result.push({userId: ap.mstUserId, userName: ap.userName, chatworkAccountId: ap.chatworkAccountId})
        }
      }
      if (!result.length) {
        result.push({userId:'', userName: '承認権限者がいません', chatworkAccountId: ''})
      }
      return result
    },

    approverList2() {
      let result = []
      for (let i = 0; i < this.allApprovers.length; i++) {
        let ap = this.allApprovers[i]
        if (ap.estimateApproveAuth) {
          // result.push({userId: ap.mstUserId, userName: ap.userName, chatworkAccountId: ap.chatworkAccountId, chatworkApiToken: '12e6247b22aa5826875a69997587ea01'})
          result.push({userId: ap.mstUserId, userName: ap.userName, chatworkAccountId: ap.chatworkAccountId})
        }
      }
      if (!result.length) {
        result.push({userId:'', userName: '承認権限者がいません', chatworkAccountId: ''})
      }
      return result
    },
  },

  watch: {
    open() {
      this.getTarget()
    }
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    getTarget() {
      // 編集の場合
      if (this.target && Object.keys(this.target).length) {
        this.approval = this.target
        // ルームIDは別のを使用するので、削除
        for (let aI = 0; aI < this.approval.approver1.length; aI++) {
          const ap1 = this.approval.approver1[aI];
          delete ap1.chatworkRoomId
        }
        for (let pI = 0; pI < this.approval.approver2.length; pI++) {
          const ap2 = this.approval.approver2[pI];
          delete ap2.chatworkRoomId
        }
      }
    },

    /**
     * 承認者選択イベント
     */
    async selectedApprover(index, userId, sequentialOrder) {
      if (userId == '') {
        return
      }

      let appData = {
        userId: '',
        userName: '',
        chatworkAccountId: ''
      }

      switch (sequentialOrder) {
        case 1: {
          let result = this.approverList1.filter((a) => {
            return a.userId == userId
          })

          for (let i = 0; i < this.approval.approver1.length; i++) {
            if (i != index) {
              if (this.approval.approver1[i].userId == userId) {
                await dialogs.showErrorDialog('選択不可', '同じ承認者が既に選択されています。')
                this.approval.approver1.splice(index, 1 , appData)
                return
              }
            }
          }

          this.approval.approver1[index]['userName'] = result[0].userName
          this.approval.approver1[index]['chatworkAccountId'] = result[0].chatworkAccountId
          // this.approval.approver1[index]['chatworkApiToken'] = result[0].chatworkApiToken
          break;
        }
        case 2: {
          let result = this.approverList2.filter((a) => {
            return a.userId == userId
          })

          for (let i = 0; i < this.approval.approver2.length; i++) {
            if (i != index) {
              if (this.approval.approver2[i].userId == userId) {
                await dialogs.showErrorDialog('選択不可', '同じ承認者が既に選択されています。')
                this.approval.approver2.splice(index, 1 , appData)
                return
              }
            }
          }
          
          this.approval.approver2[index]['userName'] = result[0].userName
          this.approval.approver2[index]['chatworkAccountId'] = result[0].chatworkAccountId
          // this.approval.approver2[index]['chatworkApiToken'] = result[0].chatworkApiToken
          break;
        }
      
        default:
          break;
      }
    },

    /**
     * 削除ボタンイベント
     * @param index 行番号
     * @param sequentialOrder 承認順番
     */
    deleteApprovalRow(index, sequentialOrder) {
      switch (sequentialOrder) {
        case 1:
          this.approval.approver1.splice(index, 1)
          break;
        case 2:
          this.approval.approver2.splice(index, 1)
          break;
      
        default:
          break;
      }
    },

    /**
     * 追加ボタンイベント
     * @param sequentialOrder 承認順番
     */
    addRowApproval(sequentialOrder) {
      let appData = {
        userId: '',
        userName: '',
        chatworkAccountId: '',
        // chatworkApiToken: ''
      }

      switch (sequentialOrder) {
        case 1:
          this.approval.approver1.push(appData)
          break;
        case 2:
          this.approval.approver2.push(appData)
          break;
      
        default:
          break;
      }
    },

    /**
     * 初期化
     */
    closed() {
      // 登録する内容
      this.approval = {
        // 優先度
        priority: '0',
        // ルート名
        routeName: '',
        // 承認者1
        approver1: [
          {
            userId: '',
            userName: '',
            chatworkAccountId: '',
            // chatworkApiToken: ''
          }
        ],
        // 承認者2
        approver2: [
          {
            userId: '',
            userName: '',
            chatworkAccountId: '',
            // chatworkApiToken: ''
          }
        ],
      }
    },

    /**
     * 登録処理
     */
    async register() {
      // ルート名が入力されているかチェック
      if (this.approval.routeName == '') {
        await dialogs.showErrorDialog('登録不可', 'ルート名が入力されていません。')
        return
      }
      // 同じルート名がないかチェック
      let list = await estimateManager.getApprover(this)

      for (let i = 0; i < list.length; i++) {
        if (list[i]._id != this.approval._id && list[i].routeName == this.approval.routeName) {
          await dialogs.showErrorDialog('登録不可', '同じルート名が存在します。\r\n違うルート名に変更してください。')
          return
        }
      }
      // 承認1と承認2に同じ人物が登録されていないかチェック
      if (this.approval.approver2.length && this.approval.approver2.length > 0) {
        for (let i = 0; i < this.approval.approver1.length; i++) {
          for (let j = 0; j < this.approval.approver2.length; j++) {
            if (this.approval.approver1[i].userId == this.approval.approver2[j].userId) {
              await dialogs.showErrorDialog('登録不可', '承認1と2に同じ人が登録されています。')
              return
            }
          }
        }
      }

      // 空行は削除
      let number1 = []
      let number2 = []
      for (let i = 0; i < this.approval.approver1.length; i++) {
        if (this.approval.approver1[i].userId != '') {
          number1.push(this.approval.approver1[i])
        }
      }
      for (let i = 0; i < this.approval.approver2.length; i++) {
        if (this.approval.approver2[i].userId != '') {
          number2.push(this.approval.approver2[i])
        }
      }
      
      this.approval.approver1 = number1
      this.approval.approver2 = number2

      // 承認1が1人以上設定されているか確認
      if (this.approval.approver1.length < 1) {
        await dialogs.showErrorDialog('登録不可', '承認1が設定されていません。')
        return
      }

      // 優先順位
      if (this.approval.priority == '') {
        this.approval.priority = '0'
      }
      
      // 不要なものを削除
      delete this.approval.approver1List
      delete this.approval.approver2List

      // 承認ルートを承認マスタに登録
      if (this.approval._id) {
        let registApp = await estimateManager.updateTasks(this, this.approval._id, this.approval)
        if (!registApp) {
          await dialogs.showErrorDialog('エラー', '更新できませんでした。')
          return
        } else {
          await dialogs.showSuccessDialog('承認ルートの更新', '更新しました。')
          logManager.recordingByRegist(this, false, '承認ルート', '承認ルート（' + this.approval.routeName + '）')
          this.$emit('modalClose')
        }
      } else {
        let registApp = await estimateManager.registerTasks(this, 'estimate_approver_master', this.approval, {})
        if (!registApp) {
          await dialogs.showErrorDialog('エラー', '保存できませんでした。')
          return
        } else {
          await dialogs.showSuccessDialog('承認ルートの保存', '保存しました。')
          logManager.recordingByRegist(this, true, '承認ルート', '承認ルート（' + this.approval.routeName + '）')
          this.$emit('modalClose')
        }
      }
      this.closed()
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 900px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding: 10px;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.input {
  height: 50px;
}

.table {
  width: auto;
  margin: auto;
}

.table-width {
  width: 350px;
}

.number {
  width: 40px;
}

.add {
    margin-left: auto;
    margin-right: 35px;
    width: 24px;
}

.btn {
  width: 94%;
  display: flex;
  bottom: 19px;
  position: absolute;
  justify-content:space-between;
}

.app-flow {
  height: 80%;
  overflow: auto;
}

.app1-color {
  background-color: rgb(253 237 237 / 53%);
}

.department {
  display: flex;
}

.con {
  display: flex;
  justify-content: space-around;
}

.drop {
  width: 92%;
  margin-right: auto;
}

.route {
  height: 20px;
}

@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

  .btn {
    position: static;
    width: 100%;
  }

  .app-flow {
    margin-bottom: 30px;
  }
}
</style>