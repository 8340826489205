<template>
  <!-- 添付内容 ※PDFに変換するため、cssは全てstyleで書いています※ -->
  <!-- 注意!! 比較的新しいCSSはPDFに反映されないので、flexなどは使用しない。 -->
  <div>

    <table style="width: 100%; table-layout: fixed;">
      <tr>
        <td></td>
        <td style="text-align: center;">
          <div style="font-weight: bold; font-size: x-large; text-decoration: underline; width: fit-content; margin: auto;">
            発&emsp;注&emsp;書
          </div>
        </td>
        <td style="text-align: right;">
          <div style="width: fit-content; margin-left: auto;">
            <div style="text-decoration: underline; width: fit-content;">
              No. {{ purchase.purchaseNo }}
            </div>
            <div>
              {{ info.sendDate }}
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table style="width: 100%; margin-top: 28px;">
      <tr>
        <td style="vertical-align: top;">
          <div style="font-size: small;">請負者</div>
          <div style="font-size: larger; margin-top: 4px; font-weight: bold;">{{ setPurchaseName() }}&emsp;御中</div>
          <div style="font-size: x-small; width: 330px; margin-top: 20px;">
            下記の通り発注致します。お引き受けのときは、約定をご承諾いただき、別紙注文請書にご捺印の上、FAXで返信ください。
          </div>
        </td>

        <td style="text-align: center; min-width: 210px;">
          <div style="width: 250px; margin-left: auto;">
            <div style="font-size: small; margin-right: auto; text-align: left;">発注者</div>

            <div style="position: relative;">
              <img width="80px" alt="社印" :src="stamp" style="margin: auto; padding-right: 4px;" />
              <p style="position: absolute; font-size: small; top: 5px; left:5px; text-align: left;">
                アポロ管財株式会社
                <br>
                〒201-0015&nbsp;東京都狛江市猪方3-36-3
                <br>
                TEL.03-5438-8804&thinsp;/&thinsp;FAX.03-5438-8809
              </p>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <!-- 物件情報 -->
    <table style="border-collapse: collapse; width: 100%; font-size: small; margin-top: 15px;">
      <tr>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold;">物件名</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">{{ info.site }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold;">施工場所</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">{{ info.siteAddress }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold;">施工期間</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">{{ formatDate(purchase.startDay) }}&emsp;~</td>
      </tr>
      <tr>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold;">発注金額</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">{{ numberToLocale(purchase.amount) }}&thinsp;円&emsp;（内消費税&emsp;{{ numberToLocale(purchase.tax) }}&thinsp;円）</td>
      </tr>
      <tr>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold;"></td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">{{ info.client }}</td>
      </tr>
    </table>

    <!-- 発注内容 -->
    <table style="border-collapse: collapse; width: 100%; font-size: small; margin-top: 15px;">
      <tr>
        <td colspan="4" style="text-align: center; border: 1px solid #c1bebe; padding: 2px 10px; font-weight: bold; height: 25px;">発&emsp;注&emsp;内&emsp;訳</td>
      </tr>

      <tr style="text-align: center; font-weight: bold height: 25px;;">
        <td style="border: 1px solid #c1bebe; padding: 2px 10px;">内容</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; width: 80px">年間回数</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; width: 120px">作業単価</td>
        <td style="border: 1px solid #c1bebe; padding: 2px 10px; width: 120px">契約金額</td>
      </tr>
      
      <tr v-for="(row, i) in purchase.detail" :key="i" style="height: 25px;">
        <td v-if="i < purchase.detail.length-2" style="border: 1px solid #c1bebe; padding: 2px 10px; text-align: left;">{{ row.category }}</td>
        <td v-else style="border: 1px solid #c1bebe; padding: 2px 10px; text-align: center;">{{ row.category }}</td>
        <td :rowspan="getRowspan(row)" v-if="idShowTd(row)" style="border: 1px solid #c1bebe; padding: 2px 10px; text-align: right;">{{ row.num }}</td>
        <!-- 特別清掃の場合、同じグループは結合 -->
        <td :rowspan="getRowspan(row)" v-if="idShowTd(row)" style="border: 1px solid #c1bebe; padding: 2px 10px; text-align: right;">{{ numberToLocale(row.price) }}</td>
        <td :rowspan="getRowspan(row)" v-if="idShowTd(row)" style="border: 1px solid #c1bebe; padding: 2px 10px; text-align: right;">{{ numberToLocale(row.amount) }}</td>
      </tr>
    </table>

    <!-- 備考 -->
    <table style="border-collapse: collapse; border: 1px solid #c1bebe; width: 100%; font-size: x-small; margin-top: 15px;">
      <tr><td style="font-weight: bold; padding: 2px 10px;">【備考】</td></tr>
      <tr>
        <td style="padding: 2px 10px;">
          1.&nbsp;振込手数料は、請負者の負担とします。<br>
          2.&nbsp;作業終了後は、速やかに報告書の提出をお願いいたします。<br>
          3.&nbsp;変更がある場合は、随時連絡いたします。<br>
        </td>
      </tr>
    </table>
    
    <!-- 約定 -->
    <table style="border-collapse: collapse; border: 1px solid #c1bebe; width: 100%; font-size: x-small; margin-top: 10px;">
      <tr><td style="font-weight: bold; padding: 2px 10px;">【約定】</td></tr>
      <tr>
        <td style="padding: 2px 10px;">
          1.&nbsp;品質不良及び数量不足の場合は、直ちに補充または交換を行うものとします。<br>
          2.&nbsp;受注者は、労働者に対し、労働基準法及び安全衛生規則等遵守するよう管理監督する責任を負うものとします。<br>
          3.&nbsp;注文者以外の追加は、承認なきものは認めません。<br>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

import * as utils from '@libs/utils'
import * as moment from 'moment'


export default {
  components: {
  },
  
  props: [
    // 発注書内容データ
    'purchase',
    // 基本情報
    'info'
  ],

  data() {
    return {
      // 社印
      stamp: `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}stamps/company.png`,
    }
  },

  async created() {
  },

  methods: {
    async initialize() {
      console.log(this.purchase)
    },

    /**
     * 発注書記載社名
     */
    setPurchaseName() {
      if (this.purchase.purchaseOrderName && this.purchase.purchaseOrderName != '') {
        return this.purchase.purchaseOrderName
      } else {
        return this.purchase.supplier
      }
    },

    /**
     * 3桁区切りの数字に変換
     * @param val 対象数字
     */
    numberToLocale(val) {
      return utils.numberToLocale(val)
    },

    /**
     * 日本語日付に変換
     */
    formatDate(val) {
      if (!val || val == '') {
        return ''
      }
      return moment(val).format('YYYY年M月D日')
    },

    /**
     * 特別清掃の場合、セル結合
     * @param val 対象データ
     */
    getRowspan(val) {
      // 特別清掃の場合
      if (val.code == '14' && val.rowspan) {
        return val.rowspan
      } else {
        return 1
      }
    },

    /**
     * セル結合で見えないセル判定
     * @param val 対象データ
     */
    idShowTd(val) {
      // 特別清掃の場合
      if (val.code == '14' && val.rowspan <= -1) {
        return false
      } else {
        return true
      }
    },
  }
}
</script>

<style>
</style>