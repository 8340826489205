<template>
  <span 
    class="inline-flex justify-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium relative"
    :class="classes"
    @click="click"
  >
    <svg v-if="dot" class="absolute -ml-0.5 mr-1.5 h-3 w-3 text-indigo-400" fill="currentColor" viewBox="0 0 8 8" style="top: -4px; right: -8px;">
      <circle cx="4" cy="4" r="3" />
    </svg>
    <slot></slot>
  </span>
</template>
<script>
const COLOR_TABLE = {
  gray : ['bg-gray-200 text-gray-800'],
  red : ['red'],
  yellow : ['yellow'],
  green : ['green'],
  blue : ['bg-blue-100 text-blue-800'],
  indigo : ['bg-indigo-100 text-indigo-800'],
  purple : ['bg-purple-100 text-purple-800'],
  purple2 : ['purple2'],
  pink : ['pink'],
  white : ['bg-white text-gray-600 border border-gray-200'],
  teal : ['bg-teal-600 text-teal-100'],
  blueGray: ['blue-gray'],
  orange: ['orange'],
  lightGreen: ['light-green'],
  black: ['black']
}
export default {
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    dot: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    // tooltip: {
    //   type: String,
    //   default: ''
    // }
  },
  computed: {
    classes() {
      const classes = []
      classes.push(COLOR_TABLE[this.color] || COLOR_TABLE.gray)
      if (this.clickable) {
        classes.push('cursor-pointer')
      }
      return classes
    }
  },
  methods: {
    click() {
      if (this.clickable) {
        this.$emit('click')
      }
    }
  }
}
</script>
<style scoped>
.blue-gray {
  background-color: #ecf8fd;
  color: #264770;
  border: 1px solid #3d7dcd;
}

.green {
  background: cadetblue;
  color: aliceblue;
}

.orange {
  background: #e1891c;
  color: #fffcf6;
}

.pink{
  background-color: #e97b8f;
  color: #f1e7e7;
}

.red {
  background: #fbefef;
  color: #eb4f4f;
  border-color: lightcoral;
  border: 1px solid lightcoral;
}

.yellow {
  background: #ebefb7;
  color: #27250c;
  border: 1px solid #27250c;
}

.purple2 {
  background: #5a21b0;
  color: #eee5f7;
  /* border: 1px solid #3e0d72; */
}

.light-green {
  background: #ebf5e8;
  color: #648f71;
  border: 1px solid #648f71;
}

.black {
  background: #e3e3e39e;
  color: #3e3e3e;
  border: 1px solid #3e3e3e;
}
</style>
