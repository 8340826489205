<template>
  <div>
    <label :for="name" class="block text-xs font-bold text-gray-700">
      {{ caption }}
    </label>
    <div class="mt-1">
      <quillEditor
        :value="value"
        :pasteHtml="pasteHtml"
        @change="onEditorChange($event)"
      >
        <template #toolbar>
          <slot name="toolbar">
            <div id="toolbar" class="flex">
              <span class="ql-formats">
                <button v-if="toolbarEnable.bold" class="ql-bold"></button>
                <button v-if="toolbarEnable.italic" class="ql-italic"></button>
                <button v-if="toolbarEnable.underline" class="ql-underline"></button>
                <button v-if="toolbarEnable.strike" class="ql-strike"></button>
              </span>
              <span class="ql-formats">
                <select v-if="toolbarEnable.size" class="ql-size" style="width: 80px;">
                  <option value="small"></option>
                  <option selected></option>
                  <option value="large"></option>
                  <option value="huge"></option>
                </select>
              </span>
              <span class="ql-formats">
                <select v-if="toolbarEnable.color" class="ql-color"></select>
                <select v-if="toolbarEnable.background" class="ql-background"></select>
              </span>
              <slot name="additionalToolbar" />
              <!-- <SecondaryButton text="特記事項選択" /> -->
              <!-- <button class="w-auto py-0 text-sm ml-auto text-indigo-800 hover:text-indigo-500">特記事項選択</button> -->
            </div>
          </slot>
        </template>
      </quillEditor>
    </div>
  </div>
</template>
<script>
import quillEditor from '@components/Quill.vue'
import * as utils from '@libs/utils'

const defaultToolbars = {
  bold: true,
  italic: true,
  underline: true,
  strike: true,
  size: true,
  color: true,
  background: true
}

export default {
  components: {
    quillEditor
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    toolbars: {
      type: Object,
      default: () => defaultToolbars
    },
    pasteHtml: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        }
      }
    }
  },

  computed: {
    toolbarEnable() {
      return utils.addObject(defaultToolbars, this.toolbars)
    }
  },

  methods: {
    onEditorChange({ html, text, delta }) {
      this.$emit('change', { html, text, delta })
    }
  }
}
</script>
