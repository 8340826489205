<template>
  <span>
    <router-link v-if="blank" :to="path" target="_blank" class="---mr-4" :class="classes" @click.native="click" >
      {{ valueFunc ? valueFunc(record) : value }}
    </router-link>

    <router-link v-else-if="path" :to="path" class="---mr-4" :class="classes" @click.native="click" >
      {{ valueFunc ? valueFunc(record) : value }}
    </router-link>

    <span v-else :text="path" class="---mr-4" :class="classes">
      {{ valueFunc ? valueFunc(record) : value }}
    </span>
  </span>
</template>
<script>
import { evaluate } from '@libs/utils'

export default {
  props: ['url', 'value', 'valueFunc', 'record', 'onclick', 'color', 'colorTable', 'blank'],

  computed: {
    path() {
      let path = ''
      if ((typeof this.url)== 'function') {
        path = this.url(this.record)
      } else {
        path = this.url
      }
      const record = this.record
      return evaluate({ record }, '`' + path + '`')
    },

    classes() {
      const classes = []
      if (this.colorTable) {
        classes.push(`text-${this.colorTable(this.record)}-600`)
        classes.push(`hover:text-${ this.colorTable(this.record)}-900`)
      } else {
        classes.push(`text-${this.color || 'blue'}-600`)
        classes.push(`hover:text-${this.color || 'blue'}-900`)
      }
      return classes
    }
  },

  methods: {
    click() {
      if (this.onclick) {
        this.onclick(this.record)
      }
    }
  }
}
</script>