import { Pigeon } from '@libs/pigeon'

export default {
  install(Vue, options) {
    const baseUrl = options ? options.baseUrl || '' : ''
    const router = options ? options.router : null
    const store = options ? options.store : null
    const pigeon = new Pigeon(baseUrl, router, store)
    Vue.prototype.$pigeon = pigeon
  }
}