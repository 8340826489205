import UserApp from './UserApp.vue'
import UserMaster from './pages/UserMaster.vue'

export default {
  path: '/User',
  component: UserApp,
  children: [
    {
      path: '/UserMaster',
      name: 'UserMaster',
      component: UserMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - ユーザー設定' },
      props: true
    }
  ]
}
