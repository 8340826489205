<template>
  <!-- <div :class="classes">{{ formattedValue }}</div> -->
  <div>{{ formattedValue }}</div>
</template>
<script>
const FormatterTable = {
  'number': new Intl.NumberFormat(),
  'currency': new Intl.NumberFormat({ style: 'currency', currency: 'JPY' })
}

export default {
  props: ['value', 'formatter', 'align', 'valueFunc', 'record'],
  inheritAttrs: false,
  computed: {
    // classes() {
    //   const classes = []
    //   const align = this.align || 'left'
    //   classes.push(`text-${align}`)
    //   return classes
    // },

    formattedValue() {
      const value = this.valueFunc ? this.valueFunc(this.record) : this.value
      if (typeof this.formatter === 'function') {
        return this.formatter(value)
      } else {
        const formatter = FormatterTable[this.formatter]
        if (formatter) {
          return formatter.format(Number(value))
        } else {
          return value
        }
      }
    }
  }
}
</script>