<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app">
          <div v-if="priceChange" class="text-gray-800">
            単価が変更、もしくは調整費が入力されています。<br>
            承認申請をする場合は、設定を確認してください。
          </div>

          <!-- 承認フロー -->
          <div class="app-flow">
            
            <!-- 承認 ルート名 -->
            <div class="my-8 drop">
              <form>
                <span class="text-sm text-gray-500">ルート名</span>
                <select
                  name="route"
                  v-model="estimate.approvalInfo.route"
                  class="text-gray-700 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block border-gray w-full text-base rounded"
                  @change="getApproverList()"
                >
                  <option
                    v-for="value in route" :key='value.id'
                    :value="value.routeName"
                    class="bg-white text-l">
                    {{ value.routeName }}
                  </option>
                </select>
              </form>
            </div>

            <div class="con" v-if="estimate.approvalInfo.route!=''">
              <div class="w-2/5 ml-2">
                <table class="table-auto table">
                  <thead>
                    <tr>
                      <th class="py-2 text-center text-xs font-medium text-gray-400 tracking-wider border-gray w-full app1-color">
                        承認者1
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(flow1, index) in approverList1" :key="index">
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-700 tracking-wider border-gray td-height w-full">
                      {{flow1.userName}}
                    </td>
                  </tr>
                  <span class="text-sm text-gray-500 p-3" v-if="!approverList1.length">1番目の承認者は設定されていません</span>
                  
                  <!-- チャット送信設定 -->
                  <div class="my-6" v-if="approverList1.length">
                    <input
                      type="checkbox"
                      id="chatwork1"
                      v-model="estimate.approvalInfo.chatwork1"
                    >
                    <label for="chatwork1" class="text-gray-600 ml-1"> 承認者1にチャットを送る </label>
                  </div>

                  <!-- 承認者1へのメモ -->
                  <div v-if="estimate.approvalInfo.chatwork1">
                    <span class="text-sm text-gray-400">承認者1へのメッセージ</span>
                    <textarea 
                      type="text" 
                      name="memo"
                      rows="6"
                      class="py-1 sm:text-sm border-gray-300 rounded w-full mt-3"
                      v-model="estimate.approvalInfo.message1"
                    />
                  </div>
                </table>
              </div>
              
              <div class="w-2/5 ml-2">
                <table class="table-auto table">
                  <thead>
                    <tr>
                      <th class="py-2 text-center text-xs font-medium text-gray-400 tracking-wider border-gray w-full app1-color">
                        承認者2
                      </th>
                    </tr>
                  </thead>

                  <tr v-for="(flow2, index) in approverList2" :key="index">
                    <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-700 tracking-wider border-gray td-height">
                      {{flow2.userName}}
                    </td>
                  </tr>
                  <span class="text-sm text-gray-500 p-3" v-if="!approverList2.length">2番目の承認者は設定されていません</span>

                  <!-- チャット送信設定 -->
                  <div class="my-6" v-if="approverList2.length">
                    <input
                      type="checkbox"
                      id="chatwork2"
                      v-model="estimate.approvalInfo.chatwork2"
                    >
                    <label for="chatwork2" class="text-gray-600 ml-1"> 承認者2にチャットを送る</label>
                  </div>
                  
                  <!-- 承認者2へのメモ -->
                  <div v-if="estimate.approvalInfo.chatwork2">
                    <span class="text-sm text-gray-400">承認者2へのメッセージ<br>*承認者1が全員承認した後にチャットが送られます*</span>
                    <textarea 
                      type="text" 
                      name="memo"
                      rows="6"
                      class="py-1 sm:text-sm border-gray-300 rounded w-full mt-3"
                      v-model="estimate.approvalInfo.message2"
                    />
                  </div>

                </table>
              </div>
            </div>
          </div>
          <div class="app-btn">
            <!-- 承認申請しない -->
            <PrimaryButton text="承認申請しない" size="normal bg-indigo-200 text-indigo-700 hover:bg-indigo-300 px-10 py-3 place-items-start mt-3 btn app-btn-w" @click="register('notApproval')" />
            <!-- 承認申請する -->
            <PrimaryButton text="承認申請する" size="normal px-10 py-3 place-items-end mt-3 btn app-btn-w" @click="register('doApproval')" />
          </div>

        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as estimateManager from '@managers/estimateManager'

export default {
  components: {
    Modal,
    PrimaryButton,
  },

  props: {
    priceChange: {
      type: Boolean
    },
    open: {
      type: Boolean
    },
    estimate: {
      type: Object
    },
    routeName: {
      type: String
    }
  },

  data() {
    return {
      // departmentsList: {},
      // 承認者1リスト
      approverList1: [],
      // 承認者2リスト
      approverList2: [],
      // ルートリスト
      route: [],
      // 承認マスターデータ
      approverMst: [],
      // 選択したルートデータ
      routeData: ''
    }
  },

  created() {
    this.getDepartmentsList()
  },
  
  watch: {
    'open': {
      handler: function() {
        if (this.open) {
          // 承認者取得
          this.getApproverList()
        }
      }
    }
  },

  methods: {
    /**
     * 部署名リスト,承認者候補リストを取得
     */
    async getDepartmentsList() {
      
      // 承認マスタからデータを取得
      this.approverMst = await estimateManager.getApprover(this)
      // ルート名リスト取得
      this.getRouteList()
    },

    /**
     * ルート名取得
     */
    getRouteList() {
      let routeList = []

      // 優先度が高い順に並べ替え
      for (let i = 0; i < this.approverMst.length; i++) {
        routeList = this.approverMst.sort(function(a, b) {
          return (Number(a.priority) > Number(b.priority)) ? -1 : 1;
        });
      }
      this.route = routeList
    },

    /**
     * 承認者候補リスト取得
     */
    getApproverList() {
      let info = this.estimate.approvalInfo

      // ルート名が空文字でない場合
      if (info.route != '') {

        // 承認マスタからルート名が合致するデータを取得
        let list = this.approverMst.find((u) => {
          if (info.route == u.routeName) {
          return true
          }
        })

        if (list) {
          // 承認者1データ取得
          if (list.approver1 && list.approver1.length) {
            this.approverList1 = list.approver1
          } else {
            this.approverList1 = []
          }
          
          // 承認者2データ取得
          if (list.approver2 && list.approver2.length) {
            this.approverList2 = list.approver2
          } else {
            this.approverList2 = []
          }

          // ルートデータ
          this.routeData = list
        }
      }
    },

    closed() {
      console.log('EstimateApprovalModal::closed')
    },

    /**
     * 登録処理（申請しないボタン、申請するボタン クリックイベント）
     * @param btn ボタン名
     */
    async register(btn) {

      // 申請しないボタン押下時、承認ルートを空にする
      if (btn == 'notApproval') {
        this.estimate.approvalFlow1 = []
        this.estimate.approvalFlow2 = []
        this.$emit('notApproval', false)

      // 申請するボタン押下時、承認ルートの確認＆形成
      } else {

        let flow1 = this.approverList1.map((a) => {
          return {sequentialOrder: '1', userId: a.userId, userName: a.userName, chatworkAccountId: a.chatworkAccountId, chatworkRoomId: a.chatworkRoomId, chatworkApiToken: a.chatworkApiToken, status: '未承認'}
        })

        this.estimate.approvalFlow1 = flow1

        let flow2 = this.approverList2.map((a) => {
          return {sequentialOrder: '2', userId: a.userId, userName: a.userName, chatworkAccountId: a.chatworkAccountId, chatworkRoomId: a.chatworkRoomId, chatworkApiToken: a.chatworkApiToken, status: '未承認'}
        })

        this.estimate.approvalFlow2 = flow2

        // 承認者2へチャットワーク送信がONになり、かつメッセージが入っている場合は、見積作成者の名前を付ける
        if (this.estimate.approvalInfo.chatwork2 && this.estimate.approvalInfo.message2 != '') {
          this.estimate.approvalInfo.message2 = this.estimate.approvalInfo.message2 + '\n' + '* 見積り作成者： ' + this.$store.getters.user.user_name + ' *\n' + '----------------------------------'
        }

        // 優先度カウントアップ
        this.routeData.priority = String(Number(this.routeData.priority) + 1)
        this.$emit('doApproval', this.routeData)
      }
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 800px;
  height: calc(100vh - 300px);
  overflow: auto;
  padding: 10px;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.input {
  height: 40px;
}

.table {
  width: 300px;
  margin: auto;
}

.number {
  width: 40px;
}

.add {
    margin-left: auto;
    margin-right: 35px;
    width: 24px;
}

.app-btn {
  width: 94%;
  display: flex;
  bottom: 19px;
  position: absolute;
  justify-content:space-between;
}

.app-btn-w {
  width: 49%;
}

.app-flow {
  height: 80%;
  overflow: auto;
}

.app1-color {
  background-color: rgb(253 237 237 / 53%);
}

.department {
  display: flex;
}

.con {
  display: flex;
  justify-content: space-around;
}

.drop {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
}
</style>