<template>
  <div>
    <PatrolTermComponent
      typeName="定期清掃 施工月設定"
      :type="3"
      settionStrageName="FixedPatrolTermStorageItemName">
    </PatrolTermComponent>
  </div>
</template>

<script>
import PatrolTermComponent from '../../Patrol/components/PatrolTermComponent.vue'

export default {
  components: {
    PatrolTermComponent
  },
}
</script>