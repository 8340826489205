<template>
  <div>
    <CheckListTableComponent
      typeName='品質巡回'
      :type=1
      routerName='ScheduleListByStaff'
    >
    </CheckListTableComponent>
  </div>
</template>

<script>
import CheckListTableComponent from '../components/CheckListTableComponent.vue'

export default {
  components: {
    CheckListTableComponent,
  },
}
</script>