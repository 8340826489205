import FacilityPatrolApp from './FacilityPatrolApp.vue'
import FacilityPatrolInspection from './pages/FacilityPatrolInspection.vue'
import FacilityTerm from './pages/FacilityTerm.vue'
import FacilityUnPatrolList from './pages/FacilityUnPatrolList.vue'
import FacilityScheduleListByStaff from './pages/FacilityScheduleListByStaff.vue'
import FacilityCheckList from './pages/FacilityCheckList.vue'
import FacilityDownload from './pages/FacilityDownload.vue'
import FacilityCheckListTable from './pages/FacilityCheckListTable.vue'
import FacilityTeamNameMaster from './pages/FacilityTeamNameMaster.vue'

export default {
  path: '/FacilityPatrol',
  component: FacilityPatrolApp,
  children: [
    {
      path: '/FacilityPatrolInspection',
      name: 'FacilityPatrolInspection',
      component: FacilityPatrolInspection,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検項目設定' },
      props: true
    },
    {
      path: '/FacilityTerm',
      name: 'FacilityTerm',
      component: FacilityTerm,
      meta: { requiresAuth: true, title: 'apollo-RP - 期間設定' },
      props: true
    },
    {
      path: '/FacilityUnPatrolList',
      name: 'FacilityUnPatrolList',
      component: FacilityUnPatrolList,
      meta: { requiresAuth: true, title: 'apollo-RP - 未点検一覧' },
      props: true
    },
    {
      path: '/FacilityScheduleListByStaff',
      name: 'FacilityScheduleListByStaff',
      component: FacilityScheduleListByStaff,
      meta: { requiresAuth: true, title: 'apollo-RP - 予定一覧' },
      props: true
    },
    {
      path: '/FacilityCheckList',
      name: 'FacilityCheckList',
      component: FacilityCheckList,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検一覧' },
      props: true
    },
    {
      path: '/FacilityDownload',
      name: 'FacilityDownload',
      component: FacilityDownload,
      meta: { requiresAuth: true, title: 'apollo-RP - ダウンロード' },
      props: true
    },
    {
      path: '/FacilityCheckListTable',
      name: 'FacilityCheckListTable',
      component: FacilityCheckListTable,
      meta: { requiresAuth: true, title: 'apollo-RP - 点検表' },
      props: true
    },
    {
      path: '/FacilityTeamNameMaster',
      name: 'FacilityTeamNameMaster',
      component: FacilityTeamNameMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - チーム名' },
      props: true
    }
  ]
}
