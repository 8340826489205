<template>
  <div class="h-full">
    
    <div class="rounded main-height">
      <table class="main table-auto list overflow-auto">
          <thead class="main sticky top-0">
            <tr>
              <th v-for="(c, index) in columns" :key="'cu'+index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-100">
                {{c.title}}
              </th>
            </tr>
          </thead>

        <tr v-for="(row, i) in filteredData" :key="'l'+i" class="border border-gray-200 bg-white tracking-wider" :class="i%2!=0?'bg-gray-50':''">

          <!-- ログインID -->
          <td class="px-6 py-3 whitespace-prerap text-sm text-gray-700" :style="`min-width: ${columnWidth[0]}`">
            {{ row.loginId }}
          </td>
          <!-- ユーザー名 -->
          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700" :style="`min-width: ${columnWidth[1]}`">
            {{ row.userName }}
          </td>
          <!-- サービス名 -->
          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700" :style="`min-width: ${columnWidth[2]}`">
            {{ row.serviceName }}
          </td>
          <!-- 操作カテゴリ -->
          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700" :style="`min-width: ${columnWidth[3]}`">
            {{ row.operation }}
          </td>
          <!-- ログテキスト -->
          <td class="px-6 py-3 whitespace-pre-wrap text-sm text-gray-700" :style="`width: ${columnWidth[4]}`">
            {{ row.logText }}
          </td>
          <!-- IPアドレス -->
          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700" :style="`min-width: ${columnWidth[5]}`">
            {{ row.ipAddress }}
          </td>
          <!-- 記録日時 -->
          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-700" :style="`min-width: ${columnWidth[6]}`">
            {{ row.recordingDateTime }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as logManager from '@managers/logManager'
import * as utils from '@libs/utils'

// カラム幅
const columnWidth = [
  '120px',
  '300px',
  '150px',
  '150px',
  '100%',
  '180px',
  '180px'
]

// 検索対象プロパティ
const searchKeys = ['loginId', 'userName', 'serviceName', 'operation', 'logText', 'ipAddress', 'recordingDateTime']

// 日付絞込み対象プロパティ
const searchDateKey = ['createDate']

export default {
  components: {
    // VirtualDataTable
  },

  props: [ 
    'searchKeyword',
    'period',
    'freeze'
  ],

  data() {
    return {
      columns: [
        { name: 'loginId', title: 'ログインID', type: 'text' },
        { name: 'userName', title: 'ユーザー名', type: 'text' },
        { name: 'serviceName', title: 'サービス名', type: 'text' },
        { name: 'operation', title: '操作カテゴリ', type: 'text' },
        { name: 'logText', title: 'ログテキスト', type: 'text' },
        { name: 'ipAddress', title: 'IPアドレス', type: 'text' },
        { name: 'recordingDateTime', title: '記録日時', type: 'text' }
      ],
      columnWidth,
      data: [],
      filteredData: [],
      fetchDate: ''
    }
  },

  watch: {
    searchKeyword() {
      console.log('LogTable::watch::searchKeyword')
      this.fetchLogs()
    },

    period: {
      handler() {
        console.log('LogTable::watch::period')
        this.fetchLogs() 
      },
      deep: true
    }
  },

  created() {
    this.fetchLogs()
  },

  methods: {
    async fetchLogs() {
      if (!this.freeze) {
        console.log('LogTable::fetchLogs')
        this.$emit('search-start')
        this.fetchDate = utils.getToday()
        this.data = await logManager.get(this, searchKeys, this.searchKeyword, searchDateKey, this.period)
        this.filteredData = this.data
        this.$emit('search-end', this.data)
      }
    }
  }
}

</script>
