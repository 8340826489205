<template>
  <div>
    <PageHeader title="Department Master" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="ClipboardList" class="w-7 h-7 mr-2" /> <span class="mt-0">部署・課</span>
          </h1>
        </div>
      </template>
    </PageHeader>

    
    <main class="absolute top-32 w-screen bottom-4 -mt-2 leading-8 row list">
      <div class="px-2 sm:px-4 lg:px-8 h-full flex justify-around">
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full w-47p form">
          <div class="cont border-b">
            <table class="table">
              <thead class="th sticky top-0">
                <tr class="column">
                  <th class="th2">表示順</th>
                  <th class="th2">部署</th>
                </tr>
              </thead>
              <tbody v-for="(row, index) in departmentAllList" :key="index">
                <tr>
                  <!-- 表示順 -->
                  <td class="td2 cursor-pointer w-20" :class="setColor(row.id, 'selectedDepartment')" @click="select(row, 1)">{{row.sortNo}}</td>
                  <!-- 部署名 -->
                  <td class="td2 cursor-pointer" :class="setColor(row.id, 'selectedDepartment')" @click="select(row, 1)">{{row.name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div class="flex ml-3 mt-5">
            <div>
              <PrimaryButton text="新規" size="normal hover:bg-blue-900" class="new mx-1" @click="add(1)"/>
            </div>
            <div v-if="selectedDepartment.id && !editDepartment">
              <PrimaryButton text="編集" size="normal hover:bg-teal-50" class="edit mx-1" @click="edit(1)"/>
            </div>
            <div v-if="selectedDepartment.id">
              <PrimaryButton text="削除" size="normal hover:bg-red-700" class="remove mx-1" @click="remove(1)"/>
            </div>
          </div>
          
          <!-- 編集枠 -->
          <div v-if="editDepartment" class="mx-6 mt-5 rounded border-blue p-5 border edit-f1">
            <div class="w-full">
              
              <!-- 表示順 -->
              <div class="w-2/4">
                <div class="text-xs text-gray-600 font-bold">表示順<br> <span class="font-normal">数値が小さいほど上にきます</span></div>
                <WmsTextInput
                  name="sortNo"
                  v-model="selectedDepartment.sortNo"
                  class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded"
                />
              </div>

            </div>
            <div class="flex justify-between justify-items-stretch mt-3 w-full">
              
              <!-- 名 -->
              <div class="w-2/4 mr-1">
                <WmsTextInput
                  name="name"
                  caption="部署名" 
                  v-model="selectedDepartment.name"
                  :required="true"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>
              
              <!-- チャットワークAPI -->
              <div class="w-2/4">
                <WmsTextInput
                  name="chatworkApiToken"
                  caption="チャットワークAPI Token" 
                  v-model="selectedDepartment.chatworkApiToken"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>

            </div>
            <div class="flex justify-between justify-items-stretch mt-3">
              <!-- チャットワークroom -->
              <div class="w-2/4 mr-1">
                <WmsTextInput
                  name="chatworkRoomId"
                  caption="チャットワークroom ID" 
                  v-model="selectedDepartment.chatworkRoomId"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>

              <!-- チャットワークWebhook -->
              <div class="w-2/4">
                <WmsTextInput
                  name="chatworkWebhookToken"
                  caption="チャットワークWebhook Token" 
                  v-model="selectedDepartment.chatworkWebhookToken"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>
            </div>
            
            <div class="flex justify-between justify-items-stretch mt-3">
              <div class="w-32 mt-3 mr-1">
                <span>
                  <input type="checkbox" id="transmission" value="デンタツ用部署" v-model="selectedDepartment.transmissionFlag">
                  <label for="transmission" class="text-xs text-gray-600 font-bold companyLabel">デンタツ用部署</label>
                </span>
              </div>
              <!-- デンタツに紐づく部署 -->
              <div class="w-full" v-if="selectedDepartment.transmissionFlag">
                <div class="text-xs text-gray-600 font-bold">紐づく部署を選択してください<span v-if="selectedDepartment.transmissionFlag" class="-top-0.5 ml-1 text-red-500"> * </span></div>
                <form class="mt-1">
                  <select
                    name="departmentName"
                    v-model="selectedDepartment.transDepartment"
                    @change="ChangeDepartment()"
                    class="text-center focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs drop"
                  >
                    <option
                      v-for="list in departmentList" :key='list.id'
                      :value="list.departmentId"
                      class="bg-white">
                      {{ list.name }}
                    </option>
                  </select>
                </form>
              </div>
            </div>
            
            <div class="mt-5 flex justify-end">
              <PrimaryButton text="保存" size="normal hover:bg-teal-900" class="reg mx-1" @click="register(1)"/>
              <PrimaryButton text="キャンセル" size="normal hover:bg-teal-50" class="edit mx-1" @click="cancel(1)"/>
            </div>
          </div>
        </div>
        
        <!-- 課・エリア -->
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full w-47p form">
          <div class="cont2 border-b">
            <table class="table">
              <thead class="th sticky top-0">
                <tr class="column2">
                  <th class="th2 w-20">表示順</th>
                  <th class="th2">課・エリア</th>
                </tr>
              </thead>
              <tbody v-for="(row, index) in subList" :key="index">
                <tr>
                  <!-- 表示順 -->
                  <td class="td2 cursor-pointer w-20" :class="setColor(row.id, 'selectedSub')" @click="select(row, 2)">{{row.sortNo}}</td>
                  <!-- 部署名 -->
                  <td class="td2 cursor-pointer" :class="setColor(row.id, 'selectedSub')" @click="select(row, 2)">{{row.name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div v-if="!subList.length && this.selectedDepartment.id" class="ml-5 mt-10 text-green-800">
            {{this.selectedDepartment.name}}に課は登録されていません。
          </div>

          <div class="flex ml-3 mt-5" v-if="this.selectedDepartment.id">
            <div>
              <PrimaryButton text="新規" size="normal hover:bg-blue-900" class="new mx-1" @click="add(2)"/>
            </div>
            <div v-if="selectedSub.id && !editSub">
              <PrimaryButton text="編集" size="normal hover:bg-teal-50" class="edit mx-1" @click="edit(2)"/>
            </div>
            <div v-if="selectedSub.id">
              <PrimaryButton text="削除" size="normal hover:bg-red-700" class="remove mx-1" @click="remove(2)"/>
            </div>
          </div>
          
          <!-- 編集枠 -->
          <div v-if="editSub" class="mx-6 mt-5 rounded border-blue p-5 border edit-f2">
            <div class="w-full">
              
              <!-- 表示順 -->
              <div class="w-2/4">
                <div class="text-xs text-gray-600 font-bold">表示順<br> <span class="font-normal">数値が小さいほど上にきます</span></div>
                <WmsTextInput
                  name="sortNo"
                  v-model="selectedSub.sortNo"
                  class="border-gray text-center focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 rounded"
                />
              </div>
              
              <!-- 名 -->
              <div class="w-2/4 mr-1">
                <WmsTextInput
                  name="name"
                  caption="課名" 
                  v-model="selectedSub.name"
                  :required="true"
                  class="focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block rounded "
                />
              </div>

            </div>
            
            <div class="mt-5 flex justify-end">
              <PrimaryButton text="保存" size="normal hover:bg-teal-900" class="reg mx-1" @click="register(2)"/>
              <PrimaryButton text="キャンセル" size="normal hover:bg-teal-50" class="edit mx-1" @click="cancel(2)"/>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
// import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'

export default {
  components: {
    Icon,
    PageHeader,
    PrimaryButton,
    WmsTextInput
  },

  data() {
    return {
      loading: false,
      initial: true,
      freeze: true,
      // 編集権限
      auth: true,
      // 部署全データ
      departmentAllList: [],      
      // 課データ
      allSubList: [],
      subList: [],
      // 選択した部署
      selectedDepartment: {
        id: null,
        sortNo: null,
        name: '',
        chatworkApiToken: '',
        chatworkRoomId: '',
        chatworkWebhookToken: '',
        transmissionFlag: false
      },
      // 選択した課
      selectedSub: {
        id: null,
        sortNo: null,
        name: ''
      },
      // 部署編集
      editDepartment: false,
      // 新規課
      editSub: false
    }
  },

  created() {
    this.freeze = true
    this.getData()
  },

  mounted() {
    this.$nextTick(() => {
      this.freeze = false
      this.refresh()
    })
  },

  computed: {
    /**
     * 部署リスト取得
     */
    departmentList() {
      return this.$store.getters.departmentList
    },
  },

  methods: {

    async refresh() {
      this.loading = true
      // 部署データ
      this.departmentAllList = []
      // 課データ
      this.allSubList = []
      this.subList = []
      // 選択した部署
      this.selectedDepartment = {
        id: null,
        sortNo: null,
        name: '',
        chatworkApiToken: '',
        chatworkRoomId: '',
        chatworkWebhookToken: '',
        transmissionFlag: false,
        transDepartment: ''
      }
      // 選択した課
      this.selectedSub = {
        id: null,
        sortNo: null,
        name: ''
      }
      // 部署編集
      this.editDepartment = false
      // 新規課
      this.editSub = false
      await this.getData()
      this.loading = false
    },

    /**
     * データ取得
     */
    async getData() {
      let resD = await backend.getData('department/get')
      let resDT = await backend.getData('department/getTrans')
      this.departmentAllList = resD.data.data.concat(resDT.data.data)
      let resS = await backend.getData('departmentSub/get')
      this.allSubList = resS.data.data
    },

    /**
     * 背景色設定
     * @param id ID
     * @param value 対象データ
     */
    setColor(id, value) {
      if (this[value].id && this[value].id == id) {
        if (value == 'selectedDepartment') {
          return 'bg-blue'
        } else {
          return 'bg-green'
        }
      }
    },

    /**
     * 部署クリックイベント
     * @param data 編集内容
     */
    select(data, target) {
      // 部署クリック
      if (target == 1) {
        this.selectedDepartment = {}
        this.selectedSub = {}
        this.selectedDepartment = data

        // 課リスト生成
        this.subList = []
        this.subList = this.allSubList.filter((s) => {
          return s.departmentId == data.departmentId
        })

        this.editSub = false
      
      // 課クリック
      } else if (target == 2) {
        this.selectedSub = {}
        this.selectedSub = data
      }
    },

    /**
     * 追加ボタンイベント
     * @param target 1:部署、2：課
     */
    add(target) {
      if (target == 1) {
        this.editDepartment = true
        this.selectedDepartment = {
          sortNo: null,
          name: '',
          chatworkApiToken: '',
          chatworkRoomId: '',
          chatworkWebhookToken: '',
          transmissionFlag: false,
          transDepartment: ''
        }
      } else if (target == 2) {
        this.editSub = true
        this.selectedSub = {
          sortNo: null,
          name: ''
        }
      }
    },

    /**
     * 編集ボタンイベント
     * @param target 1:部署、2：課
     */
    edit(target) {
      if (target == 1) {
        this.editDepartment = true
      } else if (target == 2) {
        this.editSub = true
      }
    },

    /**
     * 保存ボタンイベント
     * @param target 1:部署、2：課
     */
    async register(target) {
      if (target == 1) {
        const newFlag = this.selectedDepartment.id == null
        if (!this.selectedDepartment.name || this.selectedDepartment.name == '') {
          await this.errRequired('部署名')
          return
        }
        if (!await this.checkNumber(this.selectedDepartment.sortNo, '表示順')) {
          return
        }
        if (!await this.checkNumber(this.selectedDepartment.chatworkRoomId, 'チャットワーク room ID ')) {
          return
        }
        if (this.selectedDepartment.transmissionFlag && (!this.selectedDepartment.transDepartment || this.selectedDepartment.transDepartment == '')) {
          await this.errRequired('デンタツに紐づく部署')
          return
        } else if (this.selectedDepartment.transmissionFlag) {
          const td = await backend.getData('department/getTrans')
          for (let i = 0; i < td.data.data.length; i++) {
            const t = td.data.data[i]
            if (t.transDepartment == this.selectedDepartment.transDepartment && t.id != this.selectedDepartment.id) {
              const dialogsR = await dialogs.showConfirmDialog('既にデンタツがあります', '既に同じ「紐づく部署」のデンタツがあります。\r\nこのまま保存してよろしいですか？')
              if (dialogsR != 'YES') {
                return
              } else {
                break
              }
            }
          }
        }
        
        await backend.postData('department/save', this.selectedDepartment)
        logManager.recordingByRegist(this, newFlag, '部署設定', '部署（' + this.selectedDepartment.name + '）')
        this.editDepartment = false
        await this.getData()

      } else if (target == 2) {
        const newFlag = this.selectedSub.id == null
        if (!this.selectedSub.name || this.selectedSub.name == '') {
          await this.errRequired('課名')
          return
        }
        if (!await this.checkNumber(this.selectedSub.sortNo, '表示順')) {
          return
        }
        this.selectedSub.departmentId = this.selectedDepartment.departmentId
        await backend.postData('departmentSub/save', this.selectedSub)
        logManager.recordingByRegist(this, newFlag, '部署設定', '課（' + this.selectedSub.name + '）')
        this.editSub = false
        
        await this.getData()
        this.subList = this.allSubList.filter((s) => {
          return s.departmentId == this.selectedDepartment.departmentId
        })
      }
    },

    /**
     * 必須項目アラート
     * @param item 項目名
     */
    async errRequired(item) {
      await dialogs.showErrorDialog(item + 'は必須です', item + 'が入力されていません。')
    },

    /**
     * キャンセルボタンイベント
     * @param target 1:部署、2：課
     */
    async cancel(target) {
      await this.getData()
      if (target == 1) {
        this.editDepartment = false
        this.selectedDepartment = {}
      } else if (target == 2) {
        this.editSub = false
        this.selectedSub = {}
      }
    },

    /**
     * 半角数値チェック
     * @param value 対象値
     * @param item 項目名
     */
    async checkNumber(value, item) {
      if (!value || value == '') {
        return true
      }
      // 半角数値チェック
      if (!String(value).match(/^[0-9]+$/)) {
        await dialogs.showErrorDialog('入力確認', item + 'が半角数字ではありません。')
        return false
      } else {
        return true
      }
    },

    /**
     * 削除
     * @param target 1:部署、2：課
     */
    async remove(target) {
      if (target == 1) {
        let result = await backend.deleteData('department/delete', this.selectedDepartment, true)
        if (!result) {
          return
        }
        // 関連する課も削除
        await backend.deleteData('departmentSub/deleteByDepartmentId', this.selectedDepartment, false)
        logManager.recording(this, logManager.Loglevel.INFO, '部署設定', '削除', '部署（' + this.selectedDepartment.name + '）を削除しました。')
        this.selectedDepartment = []
        this.editDepartment = false
        await this.refresh()
      } else if (target == 2) {
        let result = await backend.deleteData('departmentSub/delete', this.selectedSub, true)
        if (!result) {
          return
        }
        logManager.recording(this, logManager.Loglevel.INFO, '部署設定', '削除', '課（' + this.selectedSub.name + '）を削除しました。')
        this.selectedSub = {}
        this.editSub = false
        await this.getData()
        this.subList = this.allSubList.filter((s) => {
          return s.departmentId == this.selectedDepartment.departmentId
        })
      }
    },

    ChangeDepartment() {
      console.log(this.selectedDepartment.transDepartment)
    }
  }
}
</script>

<style scoped>
.table {
  width: 100%;
}

.column th {
  height: 50px;
  background: #edecf7;
}

.column2 th {
  height: 50px;
  background: #ecf6f7;
}

.bg-blue {
  background: rgb(162 219 235 / 44%);
}

.bg-green {
  background: rgb(219, 244, 219);
}

.th1 {
  width: 200px;
  text-align: center;
}

.th2 {
  text-align: left;
  padding-left: 30px;
}

.td1 {
  text-align: center;
}

.td2 {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.reg {
  width: 100px;
  background: #1e876d;
}

.edit {
  border: 1px solid #053a3a;
  color: #053a3a;
  width: 120px;
  background: #e9efea;
}

.remove {
  width: 100px;
  background: #c23a3a;
}

.w-fit {
  width: fit-content;
}

.w-47p {
  width: 47%;
}

.new {
  width: 100px;
  background: #3063af;
}

.edit-f1 {
  position: static;
  /* bottom: 30px; */
  background: #edecf7;
}

.edit-f2 {
  /* position: sticky;
  bottom: 30px; */
  background: #ecf6f7;
}

.cont {
  max-height: calc(100% - 468px);
  overflow: auto;
}

.cont2 {
  max-height: calc(100% - 320px);
  overflow: auto;
}


@media screen and (max-width: 1400px) {
  .list {
    min-width: 1400px !important;
  }
}

@media screen and (max-width: 820px) {
  .edit-f1 {
    position: static;
    bottom: 0%;
  }

  .edit-f2 {
    position: static;
    bottom: 0%;
  }

  .cont {
    max-height: 500px;
    overflow: auto;
  }

  .cont2 {
    max-height: 500px;
    overflow: auto;
  }

  .form {
    min-height: 800px !important;
  }
}
</style>