<template>
  <div>
    <SiteListMasterComponent
      typeName="巡回清掃 対象物件マスタ"
      :type="2"
      settionStrageName="RoundSiteListMasterStorageItemName">
    </SiteListMasterComponent>
  </div>
</template>

<script>
import SiteListMasterComponent from '../components/SiteListMasterComponent.vue'

export default {
  components: {
    SiteListMasterComponent
  },
}
</script>