<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="selectionsItem"
    :multiSelect="true"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

// 文言変更
// 2023.06.27
// 契約依頼（発注・解約・変更）⇒変更処理（解約・変更）
// 受注処理一覧⇒受注処理（新規・金額変更）

const categorySelections = [
  {
    value: '業務依頼',
    text: '業務依頼'
  },
  {
    value: '確認・質問',
    text: '確認・質問'
  },
  {
    value: '見積依頼',
    text: '見積依頼',
  },
  {
    value: '変更処理（解約・変更）',
    text: '変更処理（解約・変更）'
  },
  {
    value: '受注処理（新規・金額変更）',
    text: '受注処理（新規・金額変更）'
  },
  {
    value: 'クレーム',
    text: 'クレーム'
  },
  {
    value: 'その他',
    text: 'その他'
  },
  {
    value: '追加依頼',
    text: '追加依頼'
  },
  {
    value: 'デンタツ',
    text: 'デンタツ'
  },
  {
    value: '非公開依頼',
    text: '非公開依頼'
  },
  {
    value: '張り紙',
    text: '張り紙'
  },
  {
    value: '面接関連',
    text: '面接関連'
  },
  {
    value: '雇い入れ',
    text: '雇い入れ'
  },
  {
    value: '期限切れ募集',
    text: '期限切れ募集'
  },
  {
    value: 'AKBS休み登録',
    text: 'AKBS休み登録'
  }
]

const transmissionCategorySelections = [
  {
    value: 'デンタツ',
    text: 'デンタツ'
  }
]

const privateCategorySelections = [
  {
    value: '非公開依頼',
    text: '非公開依頼'
  }
]

const employmentCategorySelections = [
  {
    value: '雇い入れ',
    text: '雇い入れ'
  }
]

const interviewsCategorySelections = [
  {
    value: '面接関連',
    text: '面接関連'
  }
]

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    isOrder: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    internalValue() {
      const value = this.value.map((v) => {
        return this.categorySelections.find((selection) => {
            return selection.value === v
        })
      })
      return value
    },

    selectionsItem() {
      // 排他選択
      if (this.value.includes(transmissionCategorySelections[0].value)) {
        // デンタツ
        return transmissionCategorySelections
      }
      if (this.value.includes(privateCategorySelections[0].value)) {
        // 非公開依頼
        return privateCategorySelections
      }
      if (this.value.includes(employmentCategorySelections[0].value)) {
        // 雇い入れ
        return employmentCategorySelections
      }
      if (this.value.includes(interviewsCategorySelections[0].value)) {
        // 面接関連
        return interviewsCategorySelections
      }
      return categorySelections
    }
  },

  data() {
    return {
      categorySelections,
      transmissionCategorySelections
    }
  },

  methods: {
    onChange(selectionItems) {
      // 排他選択
      if (selectionItems.some(item => item.value == transmissionCategorySelections[0].value)) {
        // デンタツ
        selectionItems = transmissionCategorySelections
      }
      if (selectionItems.some(item => item.value == privateCategorySelections[0].value)) {
        // 非公開依頼
        selectionItems = privateCategorySelections
      }
      if (selectionItems.some(item => item.value == employmentCategorySelections[0].value)) {
        // 雇い入れ
        selectionItems = employmentCategorySelections
      }
      if (selectionItems.some(item => item.value == interviewsCategorySelections[0].value)) {
        // 面接関連
        selectionItems = interviewsCategorySelections
      }

      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>