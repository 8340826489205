
<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app overflow-auto">
          <div>
            <div class="text-lg text-blue-800 font-bold">{{target.siteName}}</div>
            <div class="text-sm text-blue-800">{{target.clientName1}}</div>
          </div>

          <div class="my-3">
            {{target.selectedDate}} の他に作業する日を選択してください。
          </div>

          <div class="w-fit mx-auto">
            <v-date-picker
              mode="multiple"
              :value="targetDate"
              :min-date="minLimitDate"
              @input="selected" >
            </v-date-picker>
          </div>

          <div class="h-52 overflow-auto border border-gray-300 mt-3 rounded w-64 mx-auto">
            <div v-for="(d, i) in selectedDate" :key="i" class="flex rounded-md bg-skybl text-blue-50 font-bold px-3 py-1 mx-auto my-1 w-fit">
              <div>{{d}}</div>
              <div class="my-auto ml-3">
                <TrashIcon class="text-gray-300 cursor-pointer hover:text-gray-50 active:text-gray-600 w-5 h-5" @click="deleteDate(i)"/>
              </div>
            </div>
          </div>
          

          <div class="flex m-3">
            <PrimaryButton text="全て削除" size="normal" class="w-full bg-light-blue-200 text-light-blue-800 mr-2" @click="allDelete(true)" />
            <PrimaryButton text="決定" size="normal" class="w-full ml-2" @click="save()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as moment from 'moment'

export default {
  components: {
    Modal,
    PrimaryButton,
    TrashIcon,
    // WmsDateInput
  },

  props: {
    target: {
      type: Object
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 作業日
      targetDate: '',
      // 選択した日
      selectedDate: [],
      // カレンダー最少日
      minLimitDate: ''
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (!this.open) {
        return
      }
      this.minLimitDate = moment(this.target.selectedDate).add(1, 'd').format('YYYY-MM-DD')
      this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target.subDate) {
        for (let i = 0; i < this.target.subDate.length; i++) {
          const s = this.target.subDate[i]
          this.selected(s)
        }
      }
    },

    /**
     * 初期化
     */
    closed() {
      // 作業日
      this.targetDate = null,
      // 選択した日
      this.selectedDate = [],
      // カレンダー最少日
      this.minLimitDate = ''
      this.$emit('modalClose', false)
    },

    /**
     * カレンダー選択
     * @param val 選択日付
     */
    selected(val) {
      moment.locale("ja", { weekdaysShort: ["日","月","火","水","木","金","土"], })
      if (!val) {
        return
      }
      const date = moment(val).format('YYYY-MM-DD (ddd)')
      if (this.selectedDate.includes(date)) {
        return
      }
      this.selectedDate.push(date)
    },

    /**
     * 日付削除
     * @param i インデックス
     */
    deleteDate(i) {
      this.selectedDate.splice(i, 1)
    },

    /**
     * すべて削除
     */
    allDelete() {
      this.selectedDate = []
    },

    /**
     * 決定処理
     */
    async save() {
      let save = []
      // フォーマット変更
      for (let i = 0; i < this.selectedDate.length; i++) {
        const s = this.selectedDate[i]
        save.push(moment(s).format('YYYY-MM-DD'))
      }
      // 昇順に並べ替え
      save = save.sort()

      this.$emit('selectedMulti', {save, idx: this.target.idx})
      this.closed()
    },
  }
}
</script>