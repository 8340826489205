<template>
  <div class="w-72">
    <!-- <VPopover
      :open.sync="showPopup"
      :auto-hide="false"
      placement="bottom-end"
      popoverClass="date-select-popover"
    > -->
    <Popper
      trigger="clickToToggle"
      :append-to-body="true"
      :force-show="showPopup"
      :options="{
        placement: 'bottom-end'
      }"
    >

      <!-- <template slot="popover"> -->
        <div ref="popup" class="popper ---relative bg-white rounded-md border border-gray-200 flex flex-col shadow-md" style="width: 690px;">
          <div class="flex gap-4 divide-x p-4">
            <div>
              <nav class="space-y-1 py-4">
                <template v-for="item in presets">
                  <a
                    :key="item.id"
                    href="#" 
                    class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md"
                    :class="item.selected ? ['bg-gray-100', 'text-gray-900', 'font-bold'] : []"
                    @click="presetSelect(item)"
                  >
                    <span class="truncate" v-if="titleText == '' || ''">
                      {{ item.text }}
                    </span>

                    <span class="truncate" v-if="titleText">
                      {{ item.text }}
                    </span>
                  </a>
                </template>
              </nav>
            </div>
            <div class="pl-4 py-2 relative">
              <v-date-picker
                :key="calendarRenderkey" 
                ref="calendar"
                v-model="range" 
                :model-config="modelConfig" 
                is-range
                :disabled="true"
                :columns="2"
                :masks="{title:'YYYY年 MMMM'}"
                class="border-0"
                @input="input"
              />
              <!-- <div v-if="temporaryPreset.name === 'all'" class="absolute inset-0 top-0 left-0 right-0 bottom-0 opacity-50 bg-white z-50"></div> -->
            </div>
          </div>
          <div class="p-2 bg-gray-50 flex justify-end flex items-center">
            <a 
              href="#" 
              class="text-gray-400 hover:text-gray-500 text-sm mr-4"
              @click="cancel"
            >
              キャンセル
            </a>
            <SecondaryGreenButton
              v-if="none"
              class="mr-2"
              text="期間なし" 
              @click="apply(1)"
            />
            <SecondaryButton
              text="適用" 
              @click="apply(2)"
            />
          </div>
        </div>
      <!-- </template> -->

      <!-- <template slot="reference"> -->
        <div slot="reference" ref="input" class="relative text-light-blue-100 focus-within:text-gray-400">
          <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <CalendarIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" v-if="titleText == '' || ''" />
            <span class="flex-shrink-0" v-if="titleText">{{ titleText }}</span>
          </div>
          <input 
            type="text"
            :value="displayText" 
            readonly
            class="tooltip-target block w-full cursor-pointer bg-light-blue-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:border-gray-400 focus:placeholder-gray-500 sm:text-sm"
            @click="showPopup = !showPopup"
            v-if="titleText == '' || ''"
          />

          <input 
            type="text"
            :value="displayText" 
            readonly
            class="tooltip-target block w-full cursor-pointer bg-light-blue-700 bg-opacity-50 py-2 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:border-gray-400 focus:placeholder-gray-500 sm:text-sm"
            :class="titleText.length > 2 ? 'moreThanThreeCharacters' : 'otherwiseCharacters'"
            @click="showPopup = !showPopup"
            v-if="titleText"
          />
        </div>
      <!-- </template> -->

    <!-- </VPopover> -->
    </Popper>
  </div>
</template>
<script>
import * as utils from '@libs/utils'
import SecondaryButton from './SecondaryButton.vue'
import SecondaryGreenButton from './SecondaryGreenButton.vue'
import { CalendarIcon } from '@vue-hero-icons/solid'

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

const presets = [
  { 
    name: 'custom', 
    text: 'カスタム', 
    selected: false
  },
  { 
    name: '1_month', 
    text: '直近１ヶ月', 
    start:'1 month ago', 
    end: 'today', 
    selected: false 
  },
  { 
    name: '2_month', 
    text: '直近２ヶ月', 
    start:'2 month ago', 
    end: 'today', 
    selected: true 
  },
  { 
    name: '3_month', 
    text: '直近３ヶ月', 
    start:'3 month ago', 
    end: 'today', 
    selected: false 
  },
  { 
    name: '6_month', 
    text: '直近６ヶ月', 
    start:'6 month ago', 
    end: 'today', 
    selected: false 
  },
  { 
    name: 'this_year', 
    text: '今年', 
    start:'first day of this year', 
    end: 'today', 
    selected: false 
  },
  { 
    name: 'last_year', 
    text: '昨年', 
    start: 'first day of last year',
    end: 'last day of last year',
    selected: false 
  },
  // { 
  //   name: 'all', 
  //   text: '全期間', 
  //   start: '',
  //   end: '',
  //   selected: false 
  // },
]

const modelConfig = {
  type: 'string',
  mask: 'YYYY-MM-DD',
}

const popperOptions = {
  modifiers: {
    arrow: {
      enable: false
    }
  }
}

export default {
  props: {
    customPresets: {
      type: Array,
      default: null
    },
    presetName: {
      type: String,
      default: null
    },
    period: {
      type: Object,
      default: null
    },
    titleText: {
      type: String,
      default: ''
    },
    // 期間指定なしボタン表示
    none: {
      type: Boolean,
      default: false
    },
  },

  components: {
    // VPopover,
    // PrimaryButton,
    SecondaryButton,
    SecondaryGreenButton,
    CalendarIcon,
    Popper,
  },

  data() {
    return {
      // displayText: '',
      modelConfig,
      showPopup: false,
      presets: utils.clone(presets),
      currentPreset: null,
      range: {
        start: null,
        end: null
      },
      calendarRenderkey: 0,
      temporaryPreset: null,
      temporaryRange: {
        start: null,
        end: null
      },
      popperOptions
    }
  },

  computed: {
    // displayText() {
    //   if (this.currentPreset.name === 'custom') {
    //     return `${this.range.start} 〜 ${this.range.end}`
    //   } else if (this.currentPreset.name === 'all') {
    //     return '全期間'
    //   } else {
    //     return this.currentPreset.text
    //   }
    // }
    displayText() {
      if (this.currentPreset.name === 'custom') {
        if (this.range && this.range.start && this.range.end) {
          return `${this.range.start} 〜 ${this.range.end}`
        } else {
          return ''
        }
      } else {
        return this.currentPreset.text
      }
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      if (this.customPresets) {
        this.presets = utils.clone(this.customPresets)
      }
 
      if (this.presetName) {
        this.presets.forEach((p) => {
          p.selected = false
        })
        this.currentPreset = this.presets.find((p) => {
          return p.name === this.presetName
        })
        if (this.currentPreset) {
          this.currentPreset.selected = true
        }
      }

      if (!this.currentPreset) {
        this.currentPreset = this.presets.find((p) => {
          return p.selected
        })
      }

      if (this.currentPreset.name === 'custom') {
        this.setRange(this.period)
      } else {
        this.setRange(this.currentPreset)
      }

      this.temporaryPreset = {...this.currentPreset}
      this.fireEvent()
    },

    setRange(preset, presetName, isGet) {
      this.range.start = utils.getDate(preset.start)
      this.range.end = utils.getDate(preset.end)
      
      if (isGet) {
        if (presetName) {
          this.currentPreset = this.presets.find((p) => {
            return p.name === presetName
          })
        }
          
        if (!this.currentPreset) {
          this.currentPreset = this.presets.find((p) => {
            return p.selected
          })
        }
      } else {
        if (presetName) {
          this.currentPreset.name = presetName
        }
      }
      this.calendarRenderkey++
    },

    presetSelect(preset) {
      this.presets.forEach((p) => {
        p.selected = false
      })
      preset.selected = true
      this.temporaryPreset = { ...preset }
      this.setRange(this.temporaryPreset)
    },

    input(e) {
      console.log(e)
      if (this.currentPreset.name !== 'custom') {
        this.presets.forEach((p) => {
          p.selected = false
        })
        const customPreset = this.presets.find((p) => {
          return p.name === 'custom'
        })
        customPreset.selected = true
        this.temporaryPreset = { ...customPreset }
      }
    },

    // rangeChange() {
    //   console.log('rangeChange')
    //   this.fireEvent()
    // },

    fireEvent(type) {
      // 期間なし
      if (type == 1) {
        this.range = {
          start: '',
          end: ''
        }
        this.currentPreset.name = 'custom'
      }
      this.$emit('change', { ...this.range }, this.currentPreset.name)
    },

    apply(type) {
      this.$emit("onApply")
      this.currentPreset = this.temporaryPreset
      this.fireEvent(type)
      this.showPopup = false
    },

    cancel() {
      this.reset()
      this.showPopup = false
    },

    reset() {
      const self = this
      this.presets.forEach((p) => {
        if (p.name === self.currentPreset.name) {
          p.selected = true
          self.currentPreset = p
        } else {
          p.selected = false
        }
      })
      if (this.currentPreset.name !== 'custom') {
        this.setRange(this.currentPreset)
      }
    },

    /**
     * 期間設定
     * @param range 期間
     * @param name プリセット名
     */
    setValue(range, name) {
      this.range = range
      this.currentPreset.name = name
    },
  }
}
</script>
<style>
.trigger {
  width: 100%;
}
</style>

