<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app">
          <div class="text-gray-800">
            承認者2が設定されています。<br>
            チャット送信の設定を確認し、確認ボタンを押してください。
          </div>

          <!-- 承認フロー -->
          <div class="app-flow mt-6">
            
            <div class="flex mx-auto">
              <table class="table-auto table mt-2 w-4/5 mx-auto">
                <thead>
                  <tr>
                    <th class="py-2 text-center text-xs font-medium text-gray-400 tracking-wider border-gray w-full app1-color">
                      承認者2
                    </th>
                  </tr>
                </thead>

                <tr v-for="(flow2, index) in estimate.approvalFlow2" :key="index">
                  <td class="text-center px-0.5 py-0.5 text-xs font-medium text-gray-500 tracking-wider border-gray td-height">
                    {{flow2.userName}}
                  </td>
                </tr>

                <!-- チャット送信設定 -->
                <div class="my-6">
                  <input
                    type="checkbox"
                    id="chatwork2"
                    v-model="chatwork2"
                  >
                  <label for="chatwork2" class="text-gray-600 ml-1"> 承認者2にチャットを送る</label>
                </div>
                
                <!-- 承認者2へのメモ -->
                <div v-if="chatwork2">
                  <span class="text-sm text-gray-400">承認者2へのメッセージ</span>
                  <textarea 
                    type="text" 
                    name="memo"
                    rows="6"
                    class="py-1 sm:text-sm border-gray-300 rounded w-full mt-3"
                    v-model="estimate.approvalInfo.message2"
                  />
                </div>

              </table>
            </div>
          </div>
          <div class="app-btn mx-auto">
            <!-- 確認 -->
            <PrimaryButton text="確認" size="mx-auto normal px-10 py-3 btn app-btn-w" @click="register()" />
          </div>

        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'

export default {
  components: {
    Modal,
    PrimaryButton,
  },

  props: {
    estimate: {
      type: Object,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chatwork2: false
    }
  },

  watch: {
    'open': {
      handler: function() {
        if (this.open) {
          this.chatwork2 = this.estimate.approvalInfo.chatwork2
        }
      }
    }
  },

  methods: {
    closed() {
      this.chatwork2 = false
    },

    /**
     * 確認ボタンイベント
     */
    async register() {
      this.estimate.approvalInfo.chatwork2 = this.chatwork2
      if (!this.estimate.approvalInfo.chatwork2) {
        this.estimate.approvalInfo.message2 = ''
      }
      // 承認者2へチャットワーク送信がONになり、かつメッセージが入っている場合は、承認者1の名前を付ける
      if (this.estimate.approvalInfo.chatwork2 && this.estimate.approvalInfo.message2 != '') {
        this.estimate.approvalInfo.message2 = this.estimate.approvalInfo.message2 + '\n' + '* 承認者1： ' + this.$store.getters.user.user_name + ' *\n'
      }

      this.$emit('doApproval', false)
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 600px;
  height: 700px;
  overflow: auto;
  padding: 10px;
}

.border-gray {
  border: solid #d3cfcf 1px;
}

.td-height {
  height: 40px;
}

.app-btn {
  width: 91%;
  bottom: 19px;
  position: absolute;
}

.app-btn-w {
  width: 100%;
}

.app-flow {
  height: 80%;
  overflow: auto;
}

.app1-color {
  background-color: rgb(253 237 237 / 53%);
}
</style>