<template>
  <VueFinalModal
    v-slot="{ close, params }"
    v-bind="$attrs"
    v-on="$listeners"
    :click-to-close="false"
    classes="flex justify-center items-center"
  >
    <div class="min-320 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-30vw sm:w-full sm:p-6">
      <div class="sm:flex sm:items-start">

        <!-- warning icon -->
        <div
          v-if="params.icon == 'warning'" 
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <ExclamationIcon 
            class="h-6 w-6 text-yellow-600"
          />
        </div>

        <!-- error icon -->
        <div
          v-if="params.icon == 'error'" 
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <XCircleIcon
            class="h-6 w-6 text-red-600"
          />
        </div>

        <!-- success icon -->
        <div
          v-if="params.icon == 'success'" 
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <CheckCircleIcon
            class="h-6 w-6 text-green-600"
          />
        </div>

        <!-- info icon -->
        <div
          v-if="params.icon == 'info'" 
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <InformationCircleIcon
            class="h-6 w-6 text-blue-600"
          />
        </div>

        <!-- question icon -->
        <div
          v-if="params.icon == 'question'" 
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <QuestionMarkCircleIcon
            class="h-6 w-6 text-blue-600"
          />
        </div>

        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            <slot name="title">
              {{ params.title }}
            </slot>
          </h3>
          <div class="mt-2 overflow-auto max-h-80p">
            <p class="text-sm text-gray-500 whitespace-pre-line">
              <slot name="content">
                {{ params.content }}
              </slot>
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <template v-for="(button, index) in params.buttons">
          <button 
            v-if="button.kind === 'primary'"
            :key="index"
            type="button" 
            class="min-100 mx-1 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
            @click="buttonClick(button, close, params.callback)"
          >
            {{ button.text }}
          </button>
          <button
            v-else-if="button.kind === 'danger'"
            :key="index"
            type="button" 
            class="min-100 mx-1 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            @click="buttonClick(button, close, params.callback)"
          >
            {{ button.text }}
          </button>
          <button 
            v-else
            :key="index"
            type="button" 
            class="min-100 mx-1 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            @click="buttonClick(button, close, params.callback)"
          >
            {{ button.text }}
          </button>
        </template>
      </div>
    </div>

  </VueFinalModal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import { ExclamationIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from '@vue-hero-icons/outline'

export default {
  inheritAttrs: false,
  components: {
    VueFinalModal,
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
    QuestionMarkCircleIcon
  },
  props: {
    icon: {
      type: String,
      default: 'info'
    },
    buttons: Array,
    default: []
  },
  methods: {
    buttonClick(button, close, callback) {
      if (callback) {
        callback(button, close)
      }
    }
  }
}
</script>
<style scoped>
.min-100 {
  min-width: 100px;
}
.min-320 {
  min-width: 320px;
}
.max-h-80p {
  max-height: 50vh;
}
.max-30vw {
  max-width: 30vw;
}
</style>