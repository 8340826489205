<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5 mt-5">
        <tr v-for="(c, i) in column1" :key="i" class="hover-color text-left">
          <th>
            <div v-if="c.name" :class="c.class" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="price1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price1[i].price"
                @change="changeData(price1[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * 給排水ポンプ
 */
const PUMP = '21'

/**
 * 簡易専用水道
 */
const WARTER = '22'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // カラム
      column1: [
        { name: '給排水ポンプ' },
        { name: '簡易専用水道' }
      ],
      // 単価
      price1: [],
    }
  },

  created() {
    this.dataByCategory()
  },
  
  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == PUMP
      })
      const dbc2 = list.filter((l) => {
        return l.parentCode == WARTER
      })

      this.price1 = dbc.concat(dbc2)
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let name1 = ''
      if (target.parentCode == '21') {
        name1 = '給排水ポンプ'
      } else {
        name1 = '簡易専用水道'
      }
      target.itemName = name1
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
