import PhotoReportApp from './PhotoReportApp.vue'
import BikeTemplate from './pages/BikeTemplate.vue'
import FreeTemplate from './pages/FreeTemplate.vue'
import StaticTemplate from './pages/StaticTemplate.vue'
import PhotoReportItemSetting from './pages/PhotoReportItemSetting.vue'
import PhotoReportList from './pages/PhotoReportList.vue'

export default {
  path: '/PhotoReport',
  component: PhotoReportApp,
  children: [
    {
      path: '/FreeTemplate',
      name: 'FreeTemplate',
      component: FreeTemplate,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書(フリー)' },
      props: true
    },
    {
      path: '/StaticTemplate',
      name: 'StaticTemplate',
      component: StaticTemplate,
      meta: { requiresAuth: true, title: 'apollo-RP - 報告書(固定)' },
      props: true
    },
    {
      path: '/BikeTemplate',
      name: 'BikeTemplate',
      component: BikeTemplate,
      meta: { requiresAuth: true, title: 'apollo-RP - バイク点検' },
      props: true
    },
    {
      path: '/PhotoReportItemSetting',
      name: 'PhotoReportItemSetting',
      component: PhotoReportItemSetting,
      meta: { requiresAuth: true, title: 'apollo-RP - 項目設定' },
      props: true
    },
    {
      path: '/PhotoReportList',
      name: 'PhotoReportList',
      component: PhotoReportList,
      meta: { requiresAuth: true, title: 'apollo-RP - 写真報告一覧' },
      props: true
    },
  ]
}
