<template>
  <li v-if="view === 'list'" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
    <div class="w-0 flex-1 flex items-center">
      <LoadingIcon v-if="isLoading && !readonly" style="color: darkgray !important;" class="h-4 w-4" />
      <LoadingIcon v-else-if="isLoading && readonly" style="color: darkgray !important;" class="h-4 w-4" />
      <div v-else>
        <FileIcon 
          :mimetype="mimetype" 
          class="w-6 h-6 text-gray-400 mx-auto fill-current transform hover:scale-110 duration-200 cursor-pointer" 
          @click.native="openAttachment"
        />
        <span class="ml-2 flex-1 w-0 truncate">
          <label 
            :for="name" 
            class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 break-words transform hover:scale-110 duration-200"
            @click="openAttachment"
          >
            <span>{{ file ? file.name : ''}}</span>
          </label>
        </span>
      </div>
    </div>
    <div class="ml-4 flex-shrink-0">
      <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
        {{ fileSize }}
      </span>          
    </div>
  </li>
  <div 
    v-else
    class="max-w-lg flex justify-center px-6 pt-5 pb-6 border ---border-gray-400 ---border-dashed rounded-md bg-white relative"
    :class="file ? ['border', 'border-gray-300'] : ['border-dashed', 'border-gray-400']"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <InputIcon
      v-if="errorMessage"
      iconName="ExclamationCircle"
      iconColor="red"
      :message="errorMessage"
      class="absolute top-1 left-1"
    />

    <XCircleIcon
      v-if="file && !readonly"
      class="text-gray-300 cursor-pointer hover:text-gray-400 active:text-gray-700 w-5 h-5 absolute top-1 right-1"
      @click="clear"
    />
    
    <div class="space-y-1 text-center">      
      <LoadingIcon v-if="isLoading" style="color: darkgray !important;  margin-top: 20px;" class="h-4 w-4" />
      <template v-else-if="file">
        <FileIcon
          :mimetype="mimetype" 
          class="w-10 h-10 text-gray-400 mx-auto fill-current mb-2 transform hover:scale-110 duration-200 cursor-pointer" 
          @click.native="openAttachment"
        />
        <div class="flex flex-col items-center text-xs text-gray-600">
          <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mb-2">
            {{ fileSize }}
          </span>          
          <label 
            :for="name" 
            class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 break-words transform hover:scale-110 duration-200"
            @click="openAttachment"
          >
            <span>{{file.name}}</span>
          </label>
        </div>
      </template>
      <template v-else-if="!file && !disabled">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div class="flex flex-col text-xs text-gray-600">
          <label :for="name" class="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <span>ファイルを選択</span>
            <input ref="file" :id="name" :name="name" type="file" class="sr-only" @change="fileChange">
          </label>
          <p class="pl-1 pt-1">または ファイルをドロップ</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
const mimetypeMapping = require('@libs/file-extension-to-mime-types.json')
  import InputIcon from '@components/InputIcon.vue'
import { XCircleIcon } from '@vue-hero-icons/solid'
import FileIcon from './FileIcon/FileIcon.vue'
import * as helper from '@libs/helper'
import * as utils from '@libs/utils'
import LoadingIcon from '@assets/loading-circle.svg' 

export default {
  components: {
    InputIcon,
    XCircleIcon,
    FileIcon,
    LoadingIcon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    /*
    {
      filename: string
      url: string
    }
    */
    value: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      file: null,
      dataUrl: null,
      objectUrl: null,
      // ローディング表示
      isLoading: false,
    }
  },

  computed: {
    fileSize() {
      if (!this.file) {
        return ''
      } else {
        return utils.formatBytes(this.file.size)
      }
    },

    mimetype() {
      let mimetype = ''
      if (this.dataUrl) {
        const arr = this.dataUrl.split(',')
        const matches = arr[0].match(/:(.*?);/)
        if (matches) {
          mimetype = matches[1] || ''
        }
      }
      if (!mimetype || mimetype === 'application/octet-stream') {
        mimetype = this.filenameTomimetype(this.file ? this.file.name : this.value.filename)
      }
      return mimetype
    }
  },

  watch: {
    value: {
      handler: function(newValue) {
        this.setValue(newValue)
      },
      deep: true
    },
  },
  
  async created() {
    await this.setValue(this.value)
  },

  destroyed() {
    this.revokeObjectUrl()
  },

  methods: {
    async setValue(value) {
      if (value && value.url && value.filename) {
        this.isLoading = true
        try {
          this.file = await utils.urlToFile(value.url, value.filename)
          this.dataUrl = await utils.fileToDataUrl(this.file)
          
          if (!this.file.size) {
            alert(value.filename + 'のファイルが添付できておりません。再度添付をお願い致します。')
          }
        } catch (error) {
          alert('添付ファイルが読み取れませんでした。')
          console.log(error)
        }
        this.isLoading = false
      } else {
        // this.file = null
        // this.dataUrl = null
      }
    },

    async openAttachment() {
      this.revokeObjectUrl()
      if (this.value.url) {
        window.open(this.value.url)
      } else {
        const dataUrl = `data:${this.mimetype};base64,${this.dataUrl.split(',')[1]}`
        const file = await utils.dataUrlToFile(dataUrl, this.value.filename)
        console.log(file)
        this.objectUrl = URL.createObjectURL(file)
        window.open(this.objectUrl)
      }
    },

    async fileChange(args) {
      const file = args.target.files[0]

      if (file.size > 400000000) {
        alert('400M を超えるファイルは添付できません。')
        return
      }

      this.setFile(file)
    },

    async setFile(file) {
      this.file = file
      this.dataUrl = await utils.fileToDataUrl(this.file)
      this.emitChange()
    },

    extractExtensionFromFileName(fileName) {
      const matches = /\.[^/.]+$/.exec(fileName)
      if (!matches) {
        return ''
      } else {
        return matches[0]
      }
    },

    filenameTomimetype(fileName) {
      const ext = this.extractExtensionFromFileName(fileName).toLowerCase()
      const mimetype = mimetypeMapping[ext]
      return mimetype
    },

    clear() {
      this.file = null
      this.dataUrl = null
      this.emitChange()
    },

    revokeObjectUrl() {
      if (this.objectUrl) {
        console.log(`cleanup object url : ${this.objectUrl}`)
        URL.revokeObjectURL(this.objectUrl)
      }
    },

    emitChange() {
      if (this.file && this.dataUrl) {
        this.$emit('change', {
          filename: this.file.name,
          content: this.dataUrl
        })
        if (!this.file.size) {
          alert('ファイルの添付に失敗しました。')
        }
      } else {
        this.$emit('change', null)
      }
    },

    dragover(event) {
      event.stopPropagation()
      event.preventDefault()
      
      if (this.readonly) {
        return
      }

      event.currentTarget.classList.add('bg-gray-200')
    },

    dragleave(event) {
      event.stopPropagation()
      event.preventDefault()
      event.currentTarget.classList.remove('bg-gray-200')
    },

    drop(event) {
      event.stopPropagation()
      event.preventDefault()

      if (this.readonly) {
        return
      }

      event.currentTarget.classList.remove('bg-gray-200')
      const file = event.dataTransfer.files[0]

      if (file.size > 400000000) {
        alert('400M を超えるファイルは添付できません。')
        return
      }

      this.setFile(file)
    },

    /**
     * データ保存後にPigeonで生成された添付情報を確認
     * @param val 対象データ
     */
    async checkBytes(val) {
      if (val && val.originalName) {
        let token = this.$store.getters.user.token
        const url = helper.buildAttachmentUrl(val.url, token)
        this.file = await utils.urlToFile(url, val.originalName)
        if (!this.file.size) {
          alert(val.originalName + ' が添付に失敗しました。再度添付をお願い致します。')
          return true
        }
      }
      return false
    }
  }
}
</script>