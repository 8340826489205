<template>
    <WmsSelectionButton
      v-bind="$attrs"
      :selections="list"
      :multiSelect="true"
      :value="internalValue"
      @change="onChange"
    />
  </template>
  <script>
  import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'
  
  const estimationDetailsCategorySelections = [
    {
      value: 'ラウンドサービス',
      text: 'ラウンドサービス',
      code: '1'
    },
    {
      value: 'ラウンドプラス',
      text: 'ラウンドプラス',
      code: '2'
    },
    {
      value: '日常清掃',
      text: '日常清掃',
      code: '3'
    },
    {
      value: '管理員業務',
      text: '管理員業務',
      code: '4'
    },
    {
      value: '定期清掃',
      text: '定期清掃',
      code: '5'
    },
    // {
    //   value: '定期清掃（カーペット）',
    //   text: '定期清掃（カーペット）'
    // },
    {
      value: 'フロントサポート',
      text: 'フロントサポート',
      code: '6'
    },
    {
      value: 'フロントサポートプラス',
      text: 'フロントサポートプラス',
      code: '7'
    },
    {
      value: 'ラウンドトラッシュ',
      text: 'ラウンドトラッシュ',
      code: '8'
    },
    {
      value: 'クオリティレポート',
      text: 'クオリティレポート',
      code: '9'
    },
    {
      value: 'ガラス清掃',
      text: 'ガラス清掃',
      code: '10'
    },
    {
      value: '排水管清掃',
      text: '排水管清掃',
      code: '11'
    },
    {
      value: '貯水槽清掃',
      text: '貯水槽清掃',
      code: '12'
    },
    {
      value: '植栽剪定',
      text: '植栽剪定',
      code: '13'
    },
    {
      value: '特別清掃',
      text: '特別清掃',
      code: '14'
    },
    {
      value: '消防設備点検',
      text: '消防設備点検',
      code: '15'
    },
    {
      value: '建築設備定期検査',
      text: '建築設備定期検査',
      code: '16'
    },
    {
      value: '特定建築物定期調査',
      text: '特定建築物定期調査',
      code: '17'
    },
    {
      value: '共用部設備点検',
      text: '共用部設備点検',
      code: '18'
    },
    {
      value: 'エレベーター保守点検',
      text: 'エレベーター保守点検',
      code: '19'
    },
    {
      value: '増圧ポンプ点検',
      text: '増圧ポンプ点検',
      code: '20'
    },
    {
      value: '給排水ポンプ点検',
      text: '給排水ポンプ点検',
      code: '21'
    },
    {
      value: '簡易専用水道',
      text: '簡易専用水道',
      code: '22'
    },
    {
      value: '管球対応',
      text: '管球対応',
      code: '23'
    },
    {
      value: '粗大ゴミ対応',
      text: '粗大ゴミ対応',
      code: '24'
    },
    {
      value: '物品販売',
      text: '物品販売',
      code: '25'
    },
    {
      value: '緊急対応',
      text: '緊急対応',
      code: '26'
    },
    {
      value: '防火対象物点検',
      text: '防火対象物点検',
      code: '27'
    },
    {
      value: '汚水槽清掃',
      text: '汚水槽清掃',
      code: '101'
    },
    {
      value: '防火設備定期検査',
      text: '防火設備定期検査',
      code: '102'
    },
    {
      value: '連結送水管耐圧性能点検',
      text: '連結送水管耐圧性能点検',
      code: '103'
    },
  ]
  
  export default {
    inheritAttrs: false,
  
    components: {
      WmsSelectionButton
    },
    
    model: {
      prop: 'value',
      event: 'change'
    },
  
    props: {
      value: {
        type: Array,
        default: () => []
      },
      categoryName: {
        type: Array,
        default: () => []
      },
      categoryCode: {
        type: Array,
        default: () => []
      }
    },
  
    computed: {
      internalValue() {
        let name = []
        for (let i = 0; i < this.categoryCode.length; i++) {
          const c = this.categoryCode[i]
          const n = this.categoryName[i]
          if (['none', '28', '29'].includes(c)) {
            name.push(n.groupName)
          } else {
            name.push(c)
          }
        }
        const value = name.map((c) => {
          return this.list.find((selection) => {
            return selection.code === c
          })
        })
        return value
      },

      list() {
        let other = []
        let gName = []
        for (let i = 0; i < this.categoryCode.length; i++) {
          const c = this.categoryCode[i]
          const n = this.categoryName[i]
          if (['none', '28', '29'].includes(c)) {
            if (gName.includes(n.groupName)) {
              continue
            }
            other.push({ value: n.name, text: n.name, code: n.groupName })
            gName.push(n.groupName)
          }
        }
        let res = []
        res = this.estimationDetailsCategorySelections.map((e) => {
          return e.value
        })

        if (other && other.length) {
          other.forEach((o) => {
            if (res.includes(o.value)) {
              o.value += ' '
              o.text += ' '
            }
          })
        }
        console.log(other)

        return this.estimationDetailsCategorySelections.concat(other)
      }
    },
  
    data() {
      return {
        estimationDetailsCategorySelections
      }
    },
  
    methods: {
      onChange(selectionItems) {
        const value = selectionItems.map((item) => {
          return item.value
        })
        const code = selectionItems.map((item) => {
          return item.code
        })
        this.$emit('change', 
        {
          codes: code,
          names: value
        })
      }
    }
  }
  </script>