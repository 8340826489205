<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      
      <template #title>{{ title }}</template>
      <template #subtitle>{{ subtitle }}</template>

      <div class="flex search-form">
        <span class="sm:text-sm my-auto text-gray-500">担当者：</span>
        <div class="relative focus-within:text-gray-500 border border-gray-300 rounded search">
          <input 
            type="text" 
            name="searchTaskStaff" 
            class="text-center w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent rounded-md"
            v-model="searchTaskStaff"
            @change="changeStaff"
          />
        </div>
      </div>
      <template>

        <div v-if="loading" class="w-full h-full flex justify-center items-center">
          <p class="text-gray-400 font-bold animate-pulse">サブタスク取得中...</p>
        </div>

        <div class="content-container flex flex-col border border-gray-300 rounded bg-white relative">
          <div class="overflow-auto flex-1 relative list">
            <VirtualDataTable
              ref="vdt"
              :columns="columns" 
              :columnWidth="columnWidth" 
              :columnAlignment="columnAlignment" 
              :rowHeight="36"
              :data="list" 
              :selectable="true"
              :tip="tip"
              @selectionChange="selectionChange"
            />
          </div>
          <p 
            v-if="list.length === 0 && !loading"
            class="absolute w-full h-full flex justify-center items-center text-gray-400"
          >
            表示する依頼がありません
          </p>
        </div>
      </template>
      <template>
        <div class="flex w-full justify-between mt-3">
          <PrimaryButton text="選択" class="ml-auto w-full" :disabled="selectedData === null"  @click="apply" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import VirtualDataTable from '@components/VirtualDataTable.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as utils from '@libs/utils'

// カラム
const columns = [
  [ { 
      name: 'taskLimit', 
      title: '期限', 
      type: 'text',
      valueFunc: rowData => {
        if (rowData.taskLimit != '') {
          const d = utils.stringToDate(rowData.taskLimit)
          return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
        } else {
          return ''
        }
      }
  } ],
  [ { name: 'departmentName', title: '担当部署', type: 'text', } ],
  [ { name: 'taskStaff', title: '担当者', type: 'text', } ],
  [ { name: 'taskCategory', title: 'カテゴリー', type: 'text', } ],
  [ { name: 'clientName', title: '得意先名', type: 'text', } ],
  [ { name: 'inquiry_title', title: 'タイトル', type: 'text' } ],
]

// カラム幅
const columnWidth = [
  '150px', // 期限
  '210px',  // 担当部署
  '210px',  // 担当者
  'auto',  // カテゴリー
  'auto',  // 得意先名
  'auto',  // タイトル
]

// カラム位置
const columnAlignment = [
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
]

// 検索対象プロパティ
const searchKeys = [
  'taskLimit',
  'departmentName',
  'taskStaff',
  'taskCategory',
  'clientName',
  'inquiry_title'
  ]


export default {
  components: {
    Modal,
    VirtualDataTable,
    PrimaryButton,
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    rows: {
      type: Array
    },
    selectCallback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      columns,
      columnWidth,
      columnAlignment,
      searchKeys,
      selectedData: null,
      searchTaskStaff: '',
      loading: false,
      list: [],
      tip: []
    }
  },

  watch: {
    'rows': {
      handler: function() {
        this.list = this.rows
        this.changeStaff()
      }
    },
  },

  created() {
    this.searchTaskStaff = utils.deleteKana(this.$store.getters.user.user_name)
  },

  methods: {
    /**
     * ラジオボタン選択イベント
     */
    selectionChange(selectedRow) {
      this.selectedData = selectedRow
    },

    /**
     * 選択ボタンイベント
     */
    apply() {
      this.selectCallback(this.selectedData)
      this.closed()
    },

    /**
     * 閉じるボタンイベント
     */
    closed() {
      this.selectedData = null
    },

    /**
     * 担当者フィルター スペースは無視して検索
     */
    changeStaff() {
      this.selectedData = null
      this.loading = true

      let result = []
      let search = ''
      if (this.searchTaskStaff != '') {
        search = this.searchTaskStaff.replace(/\s+/g, '')
      }
      if (this.rows.length) {
        result = this.rows.filter((r) => {
          let name = ''
          if (r.taskStaff != '') {
            name = r.taskStaff.replace(/\s+/g, '')
          }
          return name.indexOf(search) != -1
        })
      }
      this.list = result
      
      this.tip = this.list.map((l) => {
        return l.taskCategory.replaceAll(', ', '<br>')
      })
      this.loading = false
    }
  }
}
</script>
<style scoped>
  .content-container {
    width: calc(100vw - 185px);
    height: calc(100vh - 300px);
    overflow: auto;
  }

  .search {
    width: 200px;
  }

  .search-form {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1500px) {
  .content-container {
    width: 95vw;
    height: calc(100vh - 150px);
    overflow: auto;
  }

  .list {
    width: 1500px !important;
  }
}
</style>