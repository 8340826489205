<template>
    <WmsSelectionButton
      v-bind="$attrs"
      :selections="dentatsuCategorySelections"
      :multiSelect="true"
      :value="internalValue"
      @change="onChange"
    />
  </template>
  <script>
  import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'
  
  const dentatsuCategorySelections = [
    {
      value: '要連絡',
      text: '要連絡'
    },
    {
      value: '要対応',
      text: '要対応'
    },
    {
      value: '年調未処理',
      text: '年調未処理',
    },
    {
      value: '管球交換',
      text: '管球交換'
    },
    {
      value: '報告',
      text: '報告'
    },
    {
      value: '休み',
      text: '休み'
    },
    {
      value: '休み(AKBS無)',
      text: '休み(AKBS無)'
    },
    {
      value: '休み(有休)',
      text: '休み(有休)'
    },
    {
      value: '休み(変更･取消)',
      text: '休み(変更･取消)'
    },
    {
      value: '用具(注文外)',
      text: '用具(注文外)'
    },
    {
      value: '給与相違',
      text: '給与相違'
    },
    {
      value: '応募',
      text: '応募'
    },
    {
      value: '応募辞退',
      text: '応募辞退'
    },
    {
      value: '応募(催促)',
      text: '応募(催促)'
    },
    {
      value: '採否確認',
      text: '採否確認'
    },
    {
      value: '不在',
      text: '不在'
    },
    {
      value: '時間変更',
      text: '時間変更'
    },
    {
      value: '日時指定',
      text: '日時指定'
    },
    {
      value: '有休残確認',
      text: '有休残確認'
    },
    {
      value:'★至急★',
      text:'★至急★',
    }
  ]
  
  export default {
    inheritAttrs: false,
  
    components: {
      WmsSelectionButton
    },
    
    model: {
      prop: 'value',
      event: 'change'
    },
  
    props: {
      value: {
        type: Array,
        default: () => []
      }
    },
  
    computed: {
      internalValue() {
        const value = this.value.map((v) => {
          return this.dentatsuCategorySelections.find((selection) => {
            return selection.value === v
          })
        })
        return value
      }
    },
  
    data() {
      return {
        dentatsuCategorySelections
      }
    },
  
    methods: {
      onChange(selectionItems) {
        const value = selectionItems.map((item) => {
          return item.value
        })
        this.$emit('change', value)
      }
    }
  }
  </script>