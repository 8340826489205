<template>
  <div class="flex items-start main">
    <table class="mb-5">
      <tr class="sticky top-0 z-20">
        <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
          <div :class="c.class" class="p-3">
            {{ c.name }}
          </div>
        </th>
      </tr>
      <tr class="sticky top-8 z-20">
        <th v-for="(c, i) in column2" :key="i">
          <div :class="c.class" class="p-1">
            {{ c.name }}
          </div>
        </th>
      </tr>
      <tr v-for="(time, i) in cleaningTimes" :key="i" class="hover-color text-right">
        <th class="sticky left-0 z-10 bg-white">
          週{{time}}回
        </th>
        <th class="sticky left-20 z-10 bg-white">
          <div class="ml-3 mr-2">
            {{min[i]}}~{{max[i]}}戸
          </div>
        </th>
        <td>
          <div v-if="noTrashSingle[i]">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
              v-model="noTrashSingle[i].price"
              @change="changeData(noTrashSingle[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="noTrashFamily[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-teal-50"
              v-model="noTrashFamily[i].price"
              @change="changeData(noTrashFamily[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash3Single[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
              v-model="trash3Single[i].price"
              @change="changeData(trash3Single[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash3Family[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-blue-50"
              v-model="trash3Family[i].price"
              @change="changeData(trash3Family[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash3HalfSingle[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
              v-model="trash3HalfSingle[i].price"
              @change="changeData(trash3HalfSingle[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash3HalfFamily[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-teal-50"
              v-model="trash3HalfFamily[i].price"
              @change="changeData(trash3HalfFamily[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash4Single[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
              v-model="trash4Single[i].price"
              @change="changeData(trash4Single[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash4Family[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-blue-50"
              v-model="trash4Family[i].price"
              @change="changeData(trash4Family[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash4HalfSingle[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
              v-model="trash4HalfSingle[i].price"
              @change="changeData(trash4HalfSingle[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash4HalfFamily[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-teal-50"
              v-model="trash4HalfFamily[i].price"
              @change="changeData(trash4HalfFamily[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash5Single[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
              v-model="trash5Single[i].price"
              @change="changeData(trash5Single[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash5Family[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-blue-50"
              v-model="trash5Family[i].price"
              @change="changeData(trash5Family[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash5HalfSingle[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
              v-model="trash5HalfSingle[i].price"
              @change="changeData(trash5HalfSingle[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash5HalfFamily[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-teal-50"
              v-model="trash5HalfFamily[i].price"
              @change="changeData(trash5HalfFamily[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash6Single[i]">
            <NumericTextInput
              name="singlePrice"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
              v-model="trash6Single[i].price"
              @change="changeData(trash6Single[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
        <td>
          <div v-if="trash6Family[i]">
            <NumericTextInput
              name="familyPrice"
              class="text-right rounded border-gray-300 w-28 bg-blue-50"
              v-model="trash6Family[i].price"
              @change="changeData(trash6Family[i], `週${time}回・${min[i]}~${max[i]}戸`, null)"
            />
          </div>
        </td>
      </tr>
    </table>

    <!-- ゴミ出し簡易 -->
    <table class="ml-10 border-orange rounded">
      <tr class="orange">
        <th colspan=6>
          <div class="p-3">
            ゴミ0回
          </div>
        </th>
      </tr>
      <tr class="sticky top-0 z-20">
        <th v-for="(c, i) in column11" :key="i" :colspan="c.colspan">
          <div :class="c.class" class="p-3">
            {{ c.name }}
          </div>
        </th>
      </tr>
      <tr class="sticky top-8 z-20">
        <th v-for="(c, i) in column22" :key="i">
          <div :class="c.class" class="p-1">
            {{ c.name }}
          </div>
        </th>
      </tr>
      <tr v-for="(time, i) in cleaningTimes2" :key="i" class="hover-color2 text-right">
        <th>
          <div v-if="time.indexOf('m') != -1">
            月{{time.replace('m', '')}}回
          </div>
          <div v-else>
            週{{time}}回
          </div>
        </th>
        <th>
          <div class="ml-3">
            {{min2[i]}}~{{max2[i]}}戸
          </div>
        </th>
        <td>
          <div v-if="simpleSingle0[i]">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-yellow-50"
              v-model="simpleSingle0[i].price"
              @change="changeData(simpleSingle0[i], `${min[i]}~${max[i]}戸`, time)"
            />
          </div>
        </td>
        <td>
          <div v-if="simpleSingleFamily0[i]">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 bg-yellow-50"
              v-model="simpleSingleFamily0[i].price"
              @change="changeData(simpleSingleFamily0[i], `${min[i]}~${max[i]}戸`, time)"
            />
          </div>
        </td>
        <td>
          <div v-if="simpleSingle1[i]">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 ml-5 bg-red-50"
              v-model="simpleSingle1[i].price"
              @change="changeData(simpleSingle1[i], `${min[i]}~${max[i]}戸`, time)"
            />
          </div>
        </td>
        <td>
          <div v-if="simpleSingleFamily1[i]">
            <NumericTextInput
              name="price"
              class="text-right rounded border-gray-300 w-28 bg-red-50"
              v-model="simpleSingleFamily1[i].price"
              @change="changeData(simpleSingleFamily1[i], `${min[i]}~${max[i]}戸`, time)"
            />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * ラウンドサービス
 */
const CATEGORY = '1'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      // 清掃時間
      cleaningTimes: [
        '3',
        '3',
        '3.5',
        '3.5',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '4.5',
        '4.5',
        '4.5',
        '4.5',
        '4.5',
        '4.5',
        '4.5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '5',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
        '6',
      ],
      // 最少戸数
      min: [
        // 週3
        '1',
        '21',
        // 週3.5
        '1',
        '21',
        // 週4
        '1',
        '21',
        '26',
        '31',
        '36',
        '41',
        '46',
        // 週4.5
        '1',
        '21',
        '26',
        '31',
        '36',
        '41',
        '46',
        // 週5
        '1',
        '21',
        '26',
        '31',
        '36',
        '41',
        '46',
        // 週6
        '1',
        '21',
        '26',
        '31',
        '36',
        '41',
        '46'
      ],
      // 最大戸数
      max: [
        // 週3
        '20',
        '25',
        // 週3.5
        '20',
        '25',
        // 週4
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        // 週4.5
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        // 週5
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        // 週6
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50'
      ],
      // 1段目のカラム
      column1: [
        { name: '清掃回数', colspan: 1, class: 'bg-white sticky w-24 left-0 z-20'},
        { name: '戸数', colspan: 1, class: 'bg-white w-24'},
        { name: 'ゴミ0回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: 'ゴミ3回', colspan: 2, class: 'ml-5 bg-blue-50'},
        { name: 'ゴミ3.5回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: 'ゴミ4回', colspan: 2, class: 'ml-5 bg-blue-50'},
        { name: 'ゴミ4.5回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: 'ゴミ5回', colspan: 2, class: 'ml-5 bg-blue-50'},
        { name: 'ゴミ5.5回', colspan: 2, class: 'ml-5 bg-teal-50'},
        { name: 'ゴミ6回', colspan: 2, class: 'ml-5 bg-blue-50'}
      ],
      // 2段目のカラム
      column2: [
        { name: '', class: 'bg-white'},
        { name: '', class: 'bg-white'},
        { name: 'シングル', class: 'ml-5 bg-teal-50'}, { name: 'ファミリー', class: 'bg-teal-50'},
        { name: 'シングル', class: 'ml-5 bg-blue-50'}, { name: 'ファミリー', class: 'bg-blue-50'},
        { name: 'シングル', class: 'ml-5 bg-teal-50'}, { name: 'ファミリー', class: 'bg-teal-50'},
        { name: 'シングル', class: 'ml-5 bg-blue-50'}, { name: 'ファミリー', class: 'bg-blue-50'},
        { name: 'シングル', class: 'ml-5 bg-teal-50'}, { name: 'ファミリー', class: 'bg-teal-50'},
        { name: 'シングル', class: 'ml-5 bg-blue-50'}, { name: 'ファミリー', class: 'bg-blue-50'},
        { name: 'シングル', class: 'ml-5 bg-teal-50'}, { name: 'ファミリー', class: 'bg-teal-50'},
        { name: 'シングル', class: 'ml-5 bg-blue-50'}, { name: 'ファミリー', class: 'bg-blue-50'}
      ],
      // ゴミ0回リスト
      noTrash: [],
      // ゴミ3回リスト
      trash3: [],
      // ゴミ3.5回リスト
      trash3Half: [],
      // ゴミ4回リスト
      trash4: [],
      // ゴミ4.5回リスト
      trash4Half: [],
      // ゴミ5回リスト
      trash5: [],
      // ゴミ5.5回リスト
      trash5Half: [],
      // ゴミ6回リスト
      trash6: [],

      /**
       * ゴミ出し簡易
       */
      
      // 清掃時間
      cleaningTimes2: [
        'm1',
        'm1',
        'm2',
        'm2',
        '1',
        '2'
      ],
      // 最少戸数
      min2: [
        '1',
        '10',
        '1',
        '10',
        '1',
        '1'
      ],
      // 最大戸数
      max2: [
        '9',
        '20',
        '9',
        '20',
        '20',
        '20'
      ],
      // 1段目のカラム
      column11: [
        { name: '清掃回数', colspan: 1, class: 'bg-white w-24'},
        { name: '戸数', colspan: 1, class: 'bg-white w-24'},
        { name: '点検なし', colspan: 2, class: 'ml-5 bg-yellow-50'},
        { name: '点検あり', colspan: 2, class: 'ml-5 bg-red-50'}
      ],
      // 2段目のカラム
      column22: [
        { name: '', class: 'bg-white'},
        { name: '', class: 'bg-white'},
        { name: 'シングル', class: 'ml-5 bg-yellow-50'}, { name: 'ファミリー', class: 'bg-yellow-50'},
        { name: 'シングル', class: 'ml-5 bg-red-50'}, { name: 'ファミリー', class: 'bg-red-50'}
      ],
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {

  /**
   * ゴミ0 シングル
   */
    noTrashSingle() {
      let result = []
      result = this.generateArr(1, this.noTrash)
      return result
    },

  /**
   * ゴミ0ファミリー
   */
    noTrashFamily() {
      let result = []
      result = this.generateArr(2, this.noTrash)
      return result
    },
    
  /**
   * ゴミ3 シングル
   */
    trash3Single() {
      let result = []
      result = this.generateArr(1, this.trash3)
      return result
    },

  /**
   * ゴミ3 ファミリー
   */
    trash3Family() {
      let result = []
      result = this.generateArr(2, this.trash3)
      return result
    },
    
  /**
   * ゴミ3.5 シングル
   */
    trash3HalfSingle() {
      let result = []
      result = this.generateArr(1, this.trash3Half)
      return result
    },

  /**
   * ゴミ3.5ファミリー
   */
    trash3HalfFamily() {
      let result = []
      result = this.generateArr(2, this.trash3Half)
      return result
    },
    
  /**
   * ゴミ4 シングル
   */
    trash4Single() {
      let result = []
      result = this.generateArr(1, this.trash4)
      return result
    },

  /**
   * ゴミ4 ファミリー
   */
    trash4Family() {
      let result = []
      result = this.generateArr(2, this.trash4)
      return result
    },
    
  /**
   * ゴミ4.5 シングル
   */
    trash4HalfSingle() {
      let result = []
      result = this.generateArr(1, this.trash4Half)
      return result
    },

  /**
   * ゴミ4.5 ファミリー
   */
    trash4HalfFamily() {
      let result = []
      result = this.generateArr(2, this.trash4Half)
      return result
    },
    
  /**
   * ゴミ5 シングル
   */
    trash5Single() {
      let result = []
      result = this.generateArr(1, this.trash5)
      return result
    },

  /**
   * ゴミ5 ファミリー
   */
    trash5Family() {
      let result = []
      result = this.generateArr(2, this.trash5)
      return result
    },
    
  /**
   * ゴミ5.5 シングル
   */
    trash5HalfSingle() {
      let result = []
      result = this.generateArr(1, this.trash5Half)
      return result
    },

  /**
   * ゴミ5.5 ファミリー
   */
    trash5HalfFamily() {
      let result = []
      result = this.generateArr(2, this.trash5Half)
      return result
    },
    
  /**
   * ゴミ6 シングル
   */
    trash6Single() {
      let result = []
      result = this.generateArr(1, this.trash6)
      return result
    },

  /**
   * ゴミ6 ファミリー
   */
    trash6Family() {
      let result = []
      result = this.generateArr(2, this.trash6)
      return result
    },

  /**
   * 簡易 点検なし シングル
   */
    simpleSingle0() {
      let result = []
      result = this.generateArrSingle(1, this.noTrash, 0)
      return result
    },

  /**
   *  簡易 点検なし ファミリー
   */
    simpleSingleFamily0() {
      let result = []
      result = this.generateArrSingle(2, this.noTrash, 0)
      return result
    },

  /**
   * 簡易 点検あり シングル
   */
    simpleSingle1() {
      let result = []
      result = this.generateArrSingle(1, this.noTrash, 1)
      return result
    },

  /**
   *  簡易 点検あり ファミリー
   */
    simpleSingleFamily1() {
      let result = []
      result = this.generateArrSingle(2, this.noTrash, 1)
      return result
    },
  },

  methods: {
    /**
     * 単価データを取得
     */
    dataByCategory() {
      // if (this.list.length) {
        // カテゴリーに関するデータ取得
        const list = utils.clone(this.$store.getters.estimateItemPriceList)
        const dbc = list.filter((l) => {
          return l.parentCode == CATEGORY
        })
        if (dbc && dbc.length) {
          for (let i = 0; i < dbc.length; i++) {
            const l = dbc[i]
            let code = l.priceCode.split('-')
            switch (code[2]) {
              case '0':
                this.noTrash.push(l)
                break;
            
              case '3':
                this.trash3.push(l)
                break;
            
              case '3.5':
                this.trash3Half.push(l)
                break;
            
              case '4':
                this.trash4.push(l)
                break;
            
              case '4.5':
                this.trash4Half.push(l)
                break;
            
              case '5':
                this.trash5.push(l)
                break;
            
              case '5.5':
                this.trash5Half.push(l)
                break;
            
              case '6':
                this.trash6.push(l)
                break;
            }
          }
        }
      // }
    },

    /**
     * 1列ごとの単価データ取得
     * @param mi 最少戸数
     * @param ma 最大戸数
     * @param ty 居住タイプ 1：シングル、2：ファミリー
     * @param ti 清掃回数
     * @param target 対象データ
     */
    getPrice(mi, ma, ty, ti, target) {
      if (!target || !target.length) {
        return null
      }

      let min = String(mi)
      let max = String(ma)
      let type = String(ty)
      let times = String(ti)

      let data = null

      data = target.find((n) => {
        let code = n.priceCode.split('-')
        if (n.minHouseholds == min && n.maxHouseholds == max && code[0] == type && code[1] == times) {
          return true
        }
      })

      return data
    },

    /**
     * 配列を生成
     * @param type 居住タイプ
     * @param target 対象データ
     */
    generateArr(type, target) {
      let result = []
      if (target.length && target.length > 0) {
        for (let i = 0; i < this.cleaningTimes.length; i++) {
          result.push(this.getPrice(this.min[i], this.max[i], type, this.cleaningTimes[i], target))
        }
      }
      return result
    },

    /**
     * 1列ごとの単価データ取得 
     * @param mi 最少戸数
     * @param ma 最大戸数
     * @param ty 居住タイプ 1：シングル、2：ファミリー
     * @param ti 清掃回数
     * @param ins 点検の有無
     * @param target 対象データ
     */
    getPriceSingle(mi, ma, ty, ti, ins, target) {
      if (!target || !target.length) {
        return null
      }

      let min = String(mi)
      let max = String(ma)
      let type = String(ty)
      let times = String(ti)
      let insp = String(ins)

      let data = null

      data = target.find((n) => {
        let code = n.priceCode.split('-')
        if (n.minHouseholds == min && n.maxHouseholds == max && code[0] == type && code[1] == times && code[3] == insp) {
          return true
        }
      })
      return data
    },
    
    /**
     * 配列を生成
     * @param type 居住タイプ
     * @param target 対象データ
     * @param ins 点検有無
     */
    generateArrSingle(type, target, ins) {
      let result = []
      if (target.length && target.length > 0) {
        for (let i = 0; i < this.cleaningTimes2.length; i++) {
          result.push(this.getPriceSingle(this.min2[i], this.max2[i], type, this.cleaningTimes2[i], ins, target))
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target, name, time) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let type = ''
      let trash = ''
      let clean = ''
      let check = ''

      if (code[0] == '1') {
        type = '・シングル'
      } else if (code[0] == '2') {
        type = '・ファミリー'
      }

      // 清掃週3回以上
      if (!time) {

        if (code[2]) {
          trash = `・ゴミ${code[2]}回`
        }
        target.itemName = name + type + trash

      // 清掃週2回以下
      } else {
        if (time.indexOf('m') != -1) {
          clean = `月${time.replace('m', '')}回・`
        } else {
          clean = `週${time}回・`
        }

        if (code[3] == '0') {
          check = '・点検なし'
        } else if (code[3] == '1') {
          check = '・点検あり'
        }
        target.itemName = clean + name + type + check
      }

      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}
</style>
