<template>
  <div>
    <PageHeader :title="`apollo-RP - ${typeName}`" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">
          <PrimaryButton
            text="検索"
            class="ml-auto flex button-contents"
            :buttonContents="'要'"
            :disabled="disabledBtn"
            @click="getData()"
          >
          </PrimaryButton>

          <h1 class="text-lg font-bold text-white flex items-center ml-2">
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0 ">{{ list.length }} 件</span>
          </h1>
          <div class="ml-2 flex">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="pl-4 flex items-center">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="" class="w-7 h-7 mr-2" /> <span class="mt-0">チーム名を変更したら保存ボタンをクリックしてください</span>
          </h1>
          
          <div class="rounded ml-10">
            <div class="font-bold text-xs mb-1 text-white ml-2 mt-2">
              契約期間
              <span v-if="disabledBtn" class="ml-1 text-red-500"> * 日付を選択してください </span>
            </div>
            <div class="flex items-center">
              <div class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="startDay"
                  v-model="startDay"
                />
              </div>

              <div class="text-white font-bold text-2xl">～</div>

              <div v-if="!startDay || startDay==''" class="rounded mx-5 mb-2">
                <WmsTextInput 
                  name="endDay" 
                  :disabled="true"
                />
              </div>
              
              <div v-else class="rounded mx-5 mb-2">
                <WmsDateInput
                  name="endDay"
                  v-model="endDay"
                  :min="minEndDay"
                />
              </div>
            </div>
          </div>

          <div class="ml-auto mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterSite" 
              placeholder="物件名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterSite"
            />
          </div>
          <div class="mt-5 mr-2">
            <input
              v-tooltip="'記号・スペースは無視します'"
              type="search"
              name="filterClient" 
              placeholder="得意先名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filterClient"
            />
          </div>
        </div>     
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 mt-8 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 一覧 -->
          
          <div v-if="loading" class="w-full h-full flex justify-center items-center">
            <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
          </div>

          <div v-if="!loading">

            <div class="rounded main-height">
              <table class="main table-auto  overflow-auto">
                  <thead class="main sticky top-0 index-100">
                    <tr v-if="list && list.length" class="border border-gray-200">
                      <th v-for="(c, index) in columns" :key="'c2'+index" scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="c.class">
                        {{c.title}}
                      </th>
                    </tr>
                  </thead>

                <tr v-for="(row, i) in list" :key="'r'+i" class="border border-gray-200 bg-white tracking-wider hover-color" :class="setBgColor(row)">

                  <!-- No -->
                  <td class="b-top text-left px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no">
                    {{ i + 1 }}.
                  </td>

                  <!-- 物件名 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-300" v-tooltip="'物件コード： ' + row.siteCode">
                    {{ row.siteName }}
                    <p v-if="row.dateCancellation" class="text-xs text-blue-500">（解約月 : {{row.dateCancellation}}）</p>
                    <p v-else-if="row.cancelNextFlag&&row.cancelNextFlag=='1'" class="text-xs text-green-500">（次年度解約, 備考：{{row.cancelReason}}）</p>
                  </td>
                  <!-- 得意先名 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-200" v-tooltip="'得意先コード： ' + row.clientCode">
                    {{ row.clientName1 }}
                    <div v-if="row.clientName2" class="text-gray-400 text-xs">
                      {{ row.clientName2 }}
                    </div>
                  </td>
                  <!-- 報告書用物件コード -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 min-200 text-center">
                    {{ row.siteName2 }}
                  </td>
                  <!-- チーム名 -->
                  <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700 my-auto min-400">
                    <div class="flex justify-center mx-auto text-center">
                      <div class="my-auto mr-2">チーム</div>
                      <WmsTextInput 
                        class="w-32"
                        name="teamName" 
                        v-model="row.teamName"
                        @change="row.change=true"
                      />
                    </div>
                  </td>
                  <!-- 保存ボタン -->
                  <td class="px-6 py-0.5 text-center text-sm font-medium border-gray">
                    <div class="w-24 m-auto">
                      <PrimaryButton text="保存" size="normal" class="w-24" :disabled="!row.change" :class="row.change?'':'bg-gray-400'" @click="save(row)"/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as moment from 'moment'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'

export default {
  components: {
    Icon,
    PrimaryButton,
    PageHeader,
    WmsDateInput,
    WmsTextInput
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    // セッションストレージ名
    settionStrageName: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      startDay: moment().format('YYYY-01-01'),
      endDay: moment().format('YYYY-12-31'),
      loading: false,
      
      searchCondition: {
        startDay : '',
        endDay :  '',
        filterSite : '',
        filterClient : '',
      },
      // カラム
      columns: [
          {title: 'No.', class: 'text-left'},
          {title: '物件名', class: 'text-left'},
          {title: '得意先名', class: 'text-left'},
          {title: '報告書用物件コード', class: 'text-center'},
          {title: 'チーム名', class: 'text-center'},
          {title: '', class: 'text-center'},
      ],
      // 物件名絞込み
      filterSite: '',
      // 得意先名絞込み
      filterClient: '',
      // テーブルデータ
      original: [],
      // 表示するデータ
      list: [],
      // 画面即時反映用
      reload: 0
    }
  },

  computed: {
    /**
     * 検索ボタン 活性・非活性
     */
    disabledBtn() {
      let result = false
      if (!this.startDay || this.startDay == '' || !this.endDay || this.endDay == '') {
        result = true
      }
      return result
    },

    /**
     * 終了日の最小値
     */
    minEndDay() {
      let result = ''
      if (this.startDay == '') {
        return result
      } else {
        return this.startDay
      }
    }
  },

  created() {
    this.getCondition()
    this.getData()
  },

  methods: {
    async refresh() {
      this.loading = true
      await this.getData()
      this.loading = false
    },

    /**
     * セッションストレージデータ取得
     */
    getCondition(){
      const searchJson = sessionStorage.getItem(this.settionStrageName)
      const getItem = JSON.parse(searchJson)
      
      if(getItem !== null && getItem !== undefined){
        this.startDay = getItem.startDay
        this.endDay = getItem.endDay
        this.filterSite = getItem.filterSite
        this.filterClient = getItem.filterClient
      }
    },
    /**
     * データ取得
     */
    async getData() {
      this.loading = true
      this.original = []
      if (!this.startDay || !this.endDay) {
        await dialogs.showErrorDialog('契約期間', '契約期間の範囲指定をしてください。')
        return
      }
      //セッションストレージ保存用
      this.searchCondition.startDay = this.startDay
      this.searchCondition.endDay = this.endDay
      this.searchCondition.filterSite = this.filterSite
      this.searchCondition.filterClient = this.filterClient
      //セッションストレージ保存
      this.setSessionStorage(this.searchCondition, this.settionStrageName)

      // AKBSから得意先データ取得
      const akbs = await backend.searchData('akbs/getClientAndSite', {startDate: this.startDay, endDate: this.endDay, filterSite: this.filterSite, filterClient: this.filterClient, filterBranch: '', filterAddress: '', type: 4 })
      let client = []
      this.list = []
      
      if (akbs.data.data && akbs.data.data.length) {
        client = akbs.data.data
        // ※AKBSにない物件は表示しない（AKBSが親である）
        const rp = await backend.searchData('patrolSiteList/get', { type: this.type })
        if (rp.data.data && rp.data.data.length) {
          for (let i = 0; i < client.length; i++) {
            const c = client[i]
            for (let j = 0; j < rp.data.data.length; j++) {
              const r = rp.data.data[j]
              if (r.siteCode == c.siteCode) {
                c.teamName = r.teamName
                c.teamNameId = r.id
                break
              }
            }
          }
        }
        this.list = client
        this.loading = false
      }
    },

    /**
     * 検索条件をセッションストレージへ保存
     * @param {Object} searchCondition 
     * @param {String} storageName 
     */
    setSessionStorage(searchCondition,storageName) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(storageName, searchStr)
    },

    /**
     * 対象ボタンイベント
     * @param row 対象データ
     */
    async save(row) {
      await backend.postData('patrolSiteList/save', { ...row, type: this.type, insertUser: this.$store.getters.user.id })
      row.change = false
      this.reload++

      let log = '新規登録'
      if (row.teamNameId) {
        log = '更新'
      }
      
      //ログ出力
      logManager.recording(this, logManager.Loglevel.INFO, this.typeName, log, '設備点検のチーム名(物件名：' + row.siteName + '、チーム名：' + row.teamName + ')を設定しました。')
    },

    /**
     * 行の背景色をセット
     * @param row 対象データ
     */
    setBgColor(row) {
      // 解約月がある場合
      if (row.dateCancellation && row.dateCancellation != '') {
        let target = row.dateCancellation.replace(/年|月/g, '-') + '01'
        target = moment(target).format('YYYYMM')
        let today = moment().format('YYYYMM')
        // 解約月が今月より前の場合、グレー
        if (Number(target) < Number(today)) {
          return 'bg-gray-200'
        }
      }
      // 次年度解約フラグがある場合
      if (row.cancelNextFlag && row.cancelNextFlag == '1') {
        return 'bg-yellow-100'
      }
      return ''
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },
  },
}
</script>
<style scoped>

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
}

.list {
  overflow: auto;
}

.main {
  width: 100%;
}

.bg-colu {
    background: #ebf0fb;
}

.col-no {
    width: 70px;  
}

.min-300 {
    min-width: 300px;
}

.min-164 {
  min-width: 164px;
}

.min-120 {
  min-width: 121px;
}

.min-100 {
    min-width: 100px;
}

.min-200 {
    min-width: 200px;
}

.min-400 {
    min-width: 400px;
}

.icon-green {
  color: darkcyan;
}

.index-100 {
  z-index: 100;
}

@media screen and (max-width: 1500px) {
  .list {
    min-width: 1500px !important;
  }
}
</style>