<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" :xIconDisabled="true">
      <template #title>複写する見積を選択してください</template>
      <template>
        <div class="content-container-app">
          <!-- 一覧 -->
          <div class="content-container flex flex-col border border-gray-300 rounded bg-white relative">
            <div class="overflow-auto flex-1 relative list">
              <VirtualDataTable
                ref="vdt"
                :columns="columns" 
                :columnWidth="columnWidth" 
                :columnAlignment="columnAlignment" 
                :rowHeight="60"
                :data="list" 
                :selectable="true"
                :multiSelect="true"
                @selectionChange="selectionChange"
              />
            </div>
          </div>

          <div class="mt-3">
            <PrimaryButton text="完了" class="ml-auto w-full" @click="select()"/>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

// カラム
const columns = [
  [ { name: 'estimateNo', title: '見積番号', type: 'link', blank: true, url: '/estimate?estimateNo=${record.estimateNo}' } ],
  [ { name: 'totalAmount', title: '見積金額', type: 'number', formatter: 'currency', align: '' } ],
  [ { name: 'viewLink', fixedName: '参照', title: '', type: 'link', blank: true, url: '/EstimateView?estimateNo=${record.estimateNo}' } ],
]

// カラム幅
const columnWidth = [
  '150px', // 見積番号
  '', // 見積金額
  '80px', // 参照
]

// カラム位置
const columnAlignment = [
  'left', // 見積番号
  '', // 見積金額
  'center' // 参照
]

export default {
  components: {
    Modal,
    PrimaryButton,
    VirtualDataTable
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    estimates: {
      type: Array,
      default: null
    },
    selectCallback: {
      type: Function,
      default: () => {}
    },
  },

  data() {
    return {
      // 見積一覧
      list: [],
      // カラム
      columns,
      // カラムの幅
      columnWidth,
      // カラムの位置
      columnAlignment,
      // 選択したデータ
      selectedData: null,
    }
  },

  async created() {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      } else {
        return
      }
    }
  },
  
  computed: {
  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      // 見積データ取得
      this.list = []
      if (this.estimates) {
        this.list = utils.clone(this.estimates)
      }
    },

    /**
     * 選択イベント
     */
    selectionChange(selectedRow) {
      this.selectedData = selectedRow
    },

    /**
     * 選択ボタンイベント
     */
    async select() {
      if (this.selectedData && this.selectedData.length) {
        let res = await dialogs.showConfirmDialog('複写する見積もりは' + this.selectedData.length + '件', '選択した見積もりを複写してよろしいでしょうか？')
        if (res != 'YES') {
          return
        }
        this.selectCallback(this.selectedData)
      } else {
        let res = await dialogs.showConfirmDialog('見積りの複写は不要ですか？', '未選択なので、見積りは複写しません。よろしいでしょうか？')
        if (res != 'YES') {
          return
        }
        this.selectCallback(null)
      }
    },

    /**
     * 初期化
     */
    closed() {
      this.selectedData = null
      this.list = []
    }
  }
  
}
</script>
<style scoped>
  .content-container {
    /* width: 800px; */
    height: calc(100vh - 300px);
    overflow: auto;
  }
  
  #periodCreate >>> input[type=text] {
    background: white !important;
    color: gray !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    width: 180px !important;
  }
  
  #periodCreate >>> .pointer-events-none {
    color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .w-180px {
    width: 180px !important;
  }
</style>