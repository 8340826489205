<template>
  <div>
    <PageHeader title="apollo-RP - ダウンロード" class="list">
      <template #page-header-content>
        <div class="pl-4 flex justify-between">
          <h1 class="text-lg font-bold text-white flex items-center">            
            <Icon iconName="" class="w-7 h-7 mr-2" />
            <span class="mt-0">{{typeName}} 点検表ダウンロード</span>
          </h1>
        </div>     
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2 list">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 overflow-auto h-full">
          <!-- 報告書ダウンロード -->
          <div class="bg-white rounded-md border border-blue-300 ---overflow-auto w-800px mr-auto ml-auto mt-5 p-5">
            
            <div class="items-center mt-3">
              <div class="">サイクル期限日</div>
              <div>
                <WmsDateInput
                  name="date"
                  caption="" 
                  v-model="date"
                />
              </div>
            </div>
            
            <div class="mt-5">得意先</div>
            <div class="p-1 rounded-md border border-gray-300" v-tooltip="date==''?'先にサイクル期限を選択してください':''">
              <WmsSuggestInput
                name="client"
                :embed="true"
                displayField="clientName"
                :dynamic="false"
                :incrementalSearch="false"
                :selectionItems="clientList"
                :disabled="date==''||disabledClient"
                class="embed"
                :class="date==''||disabledClient?'cursor-pointer':''"
                v-model="client.name"
                @selected="selectClient($event)"
                @change="changeClient()"
              />
            </div>

            <div class="mt-10 w-750">
              <PrimaryButton text="報告書 ダウンロード" size="normal px-2 py-2  " class="w-full" @click="download()" />
            </div>
          </div>

          <!-- 未巡回 ダウンロード -->
          <div class="bg-white rounded-md border border-green-400 ---overflow-auto w-800px mr-auto ml-auto my-8 p-5">
            <!-- 出力範囲期限 -->
            <div class="">
              <div class="flex mr-3 mb-1 items-center text-sm">
                <div class="text-gray-400 font-bold">
                  点検日
                </div>
                <div class="ml-5 text-xs">
                  <PrimaryButton text="期間なし" size="xs" class="bg-teal-600 hover:bg-teal-700 py-1" @click="clearStart()" :buttonContents="'要'" />
                </div>
              </div>
              <div class="flex">
                <select
                  name="year"
                  v-model="year"
                  class="border-gray-300"
                >
                  <option
                    v-for="y in yearList" :key="y + 'year'"
                    :value="y"
									>
                    {{ y }}
                  </option>
                </select>
                <div class="ml-1 mr-2 my-auto">年</div>
                <select
                  name="month"
                  v-model="month"
                  class="border-gray-300"
                >
                  <option
                    v-for="m in monthList" :key="m + 'month'"
                    :value="m"
									>
                    {{ m }}
                  </option>
                </select>
                <div class="ml-1 mr-1 my-auto">月</div>
                <div class="my-auto ml-5">
                  ~ 本日まで
                </div>
              </div>
            </div>
            
            <div class="mt-5 mb-1 text-sm text-gray-400 font-bold">出力するエリアを選択してください</div>
            <div class="flex mb-2">
              <div class="bg-teal-600 text-white font-bold rounded px-3 py-1 mr-3 cursor-pointer" @click="selectedAll()">全て選択</div>
              <div class="bg-teal-600 text-white font-bold rounded px-3 py-1 mr-3 cursor-pointer" @click="removeAll()">全て解除</div>
            </div>
            <div class="p-1 rounded-md border border-gray-300 flex flex-wrap">
              <div v-for="(e, i) in areaList" :key='e.id' class="w-1/3">
                <div class="m-2">
                  <input 
                    type="checkbox" 
                    :id="e.id"
                    class="h-4 w-4 border-gray-400 rounded cursor-pointer" 
                    v-model="areaList[i].checked"
                  />
                  <label :for="e.id" class="p-2 cursor-pointer">{{ e.name }}</label>
                </div>
              </div>
            </div>

            <div class="mt-10 w-750">
              <PrimaryButton text="未巡回リスト ダウンロード" size="normal px-2 py-2" class="w-full bg-green-700 hover:bg-green-800" @click="downloadSiteList()" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'

export default {
  components: {
    Icon,
    WmsSuggestInput,
    PrimaryButton,
    WmsDateInput,
    PageHeader
  },

  props: {
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    // 区分
    type: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      // 得意先一覧
      clientList: [
        { clientCode: '', clientName: '' }
      ],
      // 得意先
      client: { clientCode: '', name: '' },
      // サイクル期限日
      date: '',
      // 得意先非活性
      disabledClient: false,
      // 点検日
      year: Number(moment().subtract(1, 'years').format('YYYY')),
      month: moment().format('MM'),
      yearList: [],
      monthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      // 出力エリア
      areaList: [],
      // 画面即時反映
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    /**
     * サイクル
     */
    async date() {
      this.client = { clientCode: '', name: '' }
      await this.getClientList()
    }
  },

  async created() {
    this.generateSelectedList()
    await this.getArea()
  },

  methods: {

    /**
     * 得意先選択イベント
     * @param e 対象
     */
    selectClient(e) {
      this.client.clientCode = e.clientCode
    },

    /**
     * 得意先変更イベント
     */
    changeClient() {
      if (this.client.name == '') {
        this.client = { clientCode: '', name: '' }
      }
    },

    /**
     * 得意先リストを取得
     */
    async getClientList() {
      if (this.date == '') {
        return
      }
      const cR = await backend.searchData('patrolResult/getGroupingClientByTerm', { date: this.date, type: this.type })
      if (cR.data && cR.data.data.length) {
        this.clientList = cR.data.data
        this.disabledClient = false
      } else {
        this.clientList = [{ clientCode: '', clientName: '' }]
        await dialogs.showInfoDialog('該当データなし', '選択したサイクル期限では点検完了した物件がありません。')
        this.disabledClient = true
      }
    },

    /**
     * 報告書ダウンロードイベント
     */
    async download() {
      if (this.date == '') {
        await dialogs.showErrorDialog('サイクル期限', 'サイクル期限を選択してください。')
        return
      } else if (!this.client.clientCode || this.client.clientCode == '') {
        await dialogs.showErrorDialog('得意先', '得意先を選択してください。')
        return
      }

      const param = { date: this.date, clientCode: this.client.clientCode, type: this.type, claim: false }
      const res = await backend.postBlob('patrolDownload/downloadByClient', param)
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = ''
        if (this.client.name && this.client.name != '') {
          name = this.client.name.replace('株式会社', '')
        }
        a.download = name + moment().format('YYYY-MM-DD') + '.xlsx'
        a.click();
        a.remove();
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * 年の選択肢作成
     */
    generateSelectedList() {
      // 品質巡回は2023年3月にリリースのため、2023年から今年まで生成
      const firstYear = 2023
      this.yearList = [firstYear]

      const thisYear = Number(moment().format('YYYY'))
      const num = thisYear - firstYear

      if (num) {
        for (let i = 1; i < num + 1; i++) {
          let y = firstYear + i
          this.yearList.push(y)
        }
      }
    },

    /**
     * エリアリスト取得
     */
    async getArea() {
      this.areaList = []
      const res = await backend.searchData('patrolDepartmentArea/getGroupByArea')
      if (res.data && res.data.data && res.data.data.length) {
        this.areaList = res.data.data
        // this.areaList.push({ id: -1, name: 'エリア未設定', checked: false })
      }
    },

    /**
     * 検索期間の点検日削除
     */
    clearStart() {
      this.year = null
      this.month = null
    },

    /**
     * 全選択
     */
    selectedAll() {
      this.areaList.forEach((a) => {
        a.checked = true
      })
      this.reload++
    },

    /**
     * 全解除
     */
    removeAll() {
      this.areaList.forEach((a) => {
        a.checked = false
      })
      this.reload++
    },

    /**
     * 物件一覧ダウンロードイベント
     */
    async downloadSiteList() {
      // エリアが1つ以上選択されているか確認
      let flg = false
      for (let i = 0; i < this.areaList.length; i++) {
        const are = this.areaList[i]
        if (are.checked) {
          flg = true
          break
        }
      }
      if (!flg) {
        await dialogs.showErrorDialog('エリアが未選択です', 'エリアを1つ以上選択してください。')
        return
      }
      this.loading = true

      let start = null
      if (this.year && this.month) {
        start = this.year + '-' + this.month + '-01'

        // 本日よりも未来を選択していないか確認
        if (this.year == Number(moment().format('YYYY'))) {
          if (Number(this.month) > Number(moment().format('M'))) {
            await dialogs.showErrorDialog('点検日が未来日になっています', '点検日を過去に変更してください。')
            return
          }
        }
      }
      const param = { areaList: this.areaList, start }
      const res = await backend.postBlob2('patrolDownload/downloadSiteList', param)
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const a = document.createElement("a");
        a.href = (window.URL || window.webkitURL).createObjectURL(blob)
        let name = '未巡回リスト'
        a.download = name + moment().format('YYYY-MM-DD') + '.xlsx'
        a.click();
        a.remove();
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },

  },
}
</script>
<style scoped>
.main {
  width: 100%;
}

.w-800px {
  width: 800px;
}

.w-750 {
  width: 750px;
}

#filterDate {
  width: 100% !important;
}

#filterDate >>> div {
  color: rgb(70, 70, 70) !important;
}

#filterDate >>> input[type=text] {
  background: white !important;
}
</style>