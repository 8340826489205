<template>
  <div>
    <div class="text-gray-500 mb-2">
      スポット清掃もこちらの定期清掃と同じです。
    </div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in cleaningTimes" :key="i" class="hover-color text-right" :class="c=='wash追加'?'border-b border-gray-300':''">
          <th v-if="eria[i].name" :rowspan="eria[i].rowspan" class="sticky left-0 z-10 bg-white text-black">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{eria[i].name}}
            </div>
          </th>
          <th class="sticky left-100p z-10 bg-white th-year-count">
            <div class="ml-3 mr-2" :class="[c=='wash追加'?'mb-3':'', c=='1'?'mt-3':'']">
              {{c}}<span v-if="c!='wash追加'"> 回/年</span>
            </div>
          </th>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                :class="[c=='wash追加'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="less200[i].price"
                @change="changeData(less200[i])"
              />
            </div>
          </td>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-1 bg-blue-50"
                :class="[c=='wash追加'?'mb-3':'', c=='1'?'mt-3':'']"
                v-model="less300[i].price"
                @change="changeData(less300[i])"
              />
            </div>
          </td>
        </tr>
      </table>
      
      <!-- 300㎡超え -->
      <table class="ml-10">
        <tr class="bg-purple-50">
          <th colspan=4>
            <div class="p-1 ml-1">
              300㎡超え（基本単価）
            </div>
          </th>
        </tr>
        <tr v-for="(e, i) in exceed300" :key="i" class="hover-color text-right" :class="cleaningTimes300[i]=='6'?'border-b border-gray-300':''">
          <th v-if="eria300[i].name" :rowspan="eria300[i].rowspan" class="whitespace-pre w-20 bg-white text-black mt-3 mb-3">
            <div>
              {{ eria300[i].name }}
            </div>
          </th>
          <th>
            <div class="ml-3 whitespace-pre mt-3 mb-3">
              {{ cleaningTimes300[i] }} 回/年
            </div>
          </th>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 mr-5 bg-purple-50 mt-3 mb-3"
                v-model="e.price"
                @change="changeData(e)"
              />
            </div>
          </td>
        </tr>
      </table>
      
      <!-- 駐車場代 -->
      <table class="ml-10 border-orange rounded">
        <tr class="orange">
          <th colspan=4>
            <div class="p-3">
              駐車場代
            </div>
          </th>
        </tr>
        <tr v-for="(p, i) in parking" :key="i" class="hover-color2 text-right" :class="parkingTimes[i].indexOf('1～2') != -1?'border-orange-b':''">
          <th v-if="parkingEria[i].name" :rowspan="parkingEria[i].rowspan" class="w-20 bg-white text-black mt-3 mb-3 th-parking-area">
            <div>
              {{ parkingEria[i].name }}
            </div>
          </th>
          <th>
            <div class="ml-3 whitespace-pre mt-3 mb-3 text-orange">
              {{ parkingTimes[i] }}
            </div>
          </th>
          <td>
            <div>
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 mr-5 bg-yellow-50 mt-3 mb-3"
                v-model="p.price"
                @change="changeData(p)"
              />
            </div>
          </td>
          <td v-if="parkingEria[i].name" :rowspan="parkingEria[i].rowspan" class="whitespace-pre text-left bg-white">
            <div class="mr-5 mt-3 mb-3">
              {{ getEria(parkingEria[i].name) }}
            </div>
          </td>
        </tr>
      </table>

      <!-- その他 -->
      <table v-if="other&&other.length" class="ml-10 border-orange rounded">
        <tr class="orange">
          <th colspan=2>
            <div class="p-3">
              その他
            </div>
          </th>
        </tr>
        <tr v-for="ot in other" :key="ot.priceCode" class="hover-color2 text-right">
          <th class="pl-5">
            {{ ot.name }}
          </th>
          <td>
            <div>
              <NumericTextInput
                :name="ot.priceCode"
                class="text-right rounded border-gray-300 w-28 ml-5 mr-5 bg-yellow-50 mt-3 mb-3"
                v-model="ot.price"
                @change="changeOther(ot)"
              />
            </div>
          </td>
        </tr>

      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as eriaManager from '@managers/eriaManager'
import * as utils from '@libs/utils'

/**
 * 定期清掃
 */
const CATEGORY = '5'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 清掃回数
      cleaningTimes: [],
      // エリアのカラム
      eria: [
        { name: '超超都心', rowspan: 6, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '超都心', rowspan: 6, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '都心', rowspan: 6, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: 'その他', rowspan: 6, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
      ],
      // ㎡数カラム
      column1: [
        { name: '', class: 'bg-white'},
        { name: '', class: 'bg-white'},
        { name: '200㎡以下', class: 'ml-5 bg-teal-50'},
        { name: '300㎡以下', class: 'bg-blue-50'}
      ],
      // 200㎡以下
      less200: [],
      // 300㎡以下
      less300: [],

      // 300㎡超え
      exceed300: [],
      // 300㎡超えエリアのカラム
      eria300: [
        { name: '超超都心', rowspan: 5, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '超都心', rowspan: 5, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: '都心', rowspan: 5, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: 'その他', rowspan: 5, class: 'ml-5 bg-teal-50'},
        { name: ''},
        { name: ''},
        { name: ''},
        { name: ''},
      ],
      // 300㎡超え清掃回数カラム
      cleaningTimes300: [],

      // 駐車場エリアカラム
      parkingEria: [
        { name: '超超都心', rowspan: 3},
        { name: ''},
        { name: ''},
        { name: '超都心', rowspan: 3},
        { name: ''},
        { name: ''},
        { name: '都心', rowspan: 3},
        { name: ''},
        { name: ''},
        { name: 'その他', rowspan: 3},
        { name: ''},
        { name: ''},
        { name: ''},
      ],
      // 駐車場作業時間カラム
      parkingTimes: [],
      // 駐車場データ
      parking: [],
      // エリア範囲
      nameEria: [],
      // その他
      other: []
    }
  },

  created() {
    this.dataByCategory()
  },
  
  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {
      this.setCleaningTimes()
      this.setParkingTimes()

      // その他
      this.other = []

      // 駐車場
      let park = []

      // 超超都心・超都心 200㎡以下
      let sEria200 = []
      // 超超都心・超都心 300㎡以下
      let sEria300 = []
      // 都心・その他 200㎡以下
      let oEria200 = []
      // 都心・その他 300㎡以下
      let oEria300 = []
      // 300㎡超え
      let e300 = []

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })
      if (dbc && dbc.length) {
        for (let i = 0; i < dbc.length; i++) {
          const l = dbc[i]
          let code = l.priceCode.split('-')

          // 発電機
          if (l.priceCode == 'generator') {
            this.other.push(l)
            continue
          }

          // 駐車場代
          else if (!code[2]) {
            park.push(l)
            continue
          }

          switch (code[0]) {
            // 超超都心・超都心エリア
            case '1':
            case '2':
              switch (code[1]) {
                // 200㎡以下
                case '1':
                  sEria200.push(l)
                  break;
                // 300㎡以下
                case '2':
                  sEria300.push(l)
                  break;
              }
              break;
          
            // 都心・その他エリア
            case '3':
            case '4':
              switch (code[1]) {
                // 200㎡以下
                case '1':
                  oEria200.push(l)
                  break;
                // 300㎡以下
                case '2':
                  oEria300.push(l)
                  break;
              }
              break;
          }

          // 300㎡超え
          if (l.priceCode.indexOf('exceed300') != -1) {
            e300.push(l)
          }
        }
        sEria200 = this.setTimesSort(sEria200, ['1', '2'])
        oEria200 = this.setTimesSort(oEria200, ['3', '4'])
        sEria300 = this.setTimesSort(sEria300, ['1', '2'])
        oEria300 = this.setTimesSort(oEria300, ['3', '4'])
        this.less200 = sEria200.concat(oEria200)
        this.less300 = sEria300.concat(oEria300)
      }

      // 300㎡超え
      // let ex300eria1 = []
      // let ex300eria2 = []
      // e300.forEach((e) => {
      //   if (e.priceCode.split('-')[0] == '1') {
      //     ex300eria1.push(e)
      //   } else if (e.priceCode.split('-')[0] == '2') {
      //     ex300eria2.push(e)
      //   }
      // })
      this.exceed300 = this.setTimesSort300(e300)

      let p1 = []
      let p2 = []
      let p3 = []
      let p4 = []

      for (let j = 0; j < park.length; j++) {
        const p = park[j]
        let codeP = p.priceCode.split('-')
        switch (codeP[0]) {
          // 超超都心
          case '1':
            p1.push(p)
            break;
          // 超都心
          case '2':
            p2.push(p)
            break;
          // 都心
          case '3':
            p3.push(p)
            break;
          // その他
          case '4':
            p4.push(p)
            break;
        }
      }

      p1 = this.setParkingSort(p1)
      p2 = this.setParkingSort(p2)
      p3 = this.setParkingSort(p3)
      p4 = this.setParkingSort(p4)
      this.parking = p1.concat(p2).concat(p3).concat(p4)

      // エリア範囲
      this.nameEria = await eriaManager.getFixed(this, '東京都')

    },

    /**
     * 清掃回数のソート
     * @param target 対象データ
     */
    setTimesSort(target, eria) {
      const times = ['1', '2', '3', '4', '6', 'wash']
      let timeSort = []
      let result = []

      for (let l = 0; l < times.length; l++) {
        const time = times[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[2] == time) {
            timeSort.push(er)
          }
        }
      }
      
      for (let l = 0; l < eria.length; l++) {
        const e = eria[l]
        for (let m = 0; m < timeSort.length; m++) {
          const eri = timeSort[m]
          if (eri.priceCode.split('-')[0] == e) {
            result.push(eri)
          }
        }
      }
      return result
    },

    /**
     * 清掃回数カラム生成
     */
    setCleaningTimes() {
      this.cleaningTimes = []
      for (let i = 0; i < 4; i++) {
        this.cleaningTimes = this.cleaningTimes.concat([
          '1',
          '2',
          '3',
          '4',
          '6',
          'wash追加'
        ])
      }
      this.cleaningTimes300 = this.cleaningTimes.filter((c) => {
        return c != 'wash追加'
      })
    },

    /**
     * 300㎡超えのソート
     */
    setTimesSort300(target) {
      const times = ['1', '2', '3', '4', '6']
      const eria = ['1', '2', '3', '4']

      let result = []
      let result2 = []
      for (let l = 0; l < times.length; l++) {
        const time = times[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[1] == time) {
            result.push(er)
          }
        }
      }
      for (let l = 0; l < eria.length; l++) {
        const e = eria[l]
        for (let m = 0; m < result.length; m++) {
          const re = result[m]
          if (re.priceCode.split('-')[0] == e) {
            result2.push(re)
          }
        }
      }
      return result2
    },

    /**
     * 駐車場のソート
     * @param target 対象データ
     */
    setParkingSort(target) {
      const times = ['3', '2', '1']
      let result = []
      for (let l = 0; l < times.length; l++) {
        const time = times[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[1] == time) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 駐車場作業時間カラム生成
     */
    setParkingTimes() {
      this.parkingTimes = []
      for (let i = 0; i < 4; i++) {
        this.parkingTimes = this.parkingTimes.concat([
          '1日作業\r\n(4時間以上)',
          '半日作業\r\n(3時間以内)\r\n(21～60戸)',
          '1～2時間\r\n(300㎡以下)'
        ])
      }
    },

    /**
     * エリア範囲
     * @param value 対象
     */
    getEria(value) {
      let result = []
      if (value != '') {
        if (value == 'その他') {
          return '上記以外のエリア'
        }
        for (let i = 0; i < this.nameEria.length; i++) {
          const e = this.nameEria[i]
          if (e.eria == value) {
            result.push(e.city + ' ' + e.street)
          }
        }
      }
      return result.join('\r\n')
    },
    
    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''

      switch (code[0]) {
        case '1':
          name1 = '超超都心'
          break;
      
        case '2':
          name1 = '超都心'
          break;
          
        case '3':
          name1 = '都心'
          break;
          
        case '4':
          name1 = 'その他'
          break;
      }

      if (target.priceCode.indexOf('exceed300') == -1) {
        if (target.name.indexOf('駐車場') == -1) {

          switch (code[1]) {
            case '1':
              name2 = '・200㎡以下'
              break;
          
            case '2':
              name2 = '・300㎡以下'
              break;
          }

          if (code[2].indexOf('wash') == -1) {
            name3 = `・${code[2]}回/年`
          } else {
            name3 = '・wash追加'
          }

        } else {
          switch (code[1]) {
            case '1':
              name2 = '・1~2時間'
              break;
          
            case '2':
              name2 = '・半日作業'
              break;

            case '3':
              name2 = '・1日作業'
              break;
          }
          name3 = '（駐車場代）'
        }
      } else {
        name2 = `・300㎡越え・${code[1]}回/年`
      }
      target.itemName = name1 + name2 + name3
      this.$emit('changePrice', target)
    },

    changeOther(target) {
      target.itemName = target.name
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}

.th-year-count {
  min-width: 100px;
}
.th-parking-area {
  min-width: 80px;
}
</style>
