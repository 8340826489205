<template>
  <div>
    <div class="mb-5 text-blue-800 font-bold">
      ※全て発注金額
    </div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column2" :key="i" class="hover-color text-right">
          <th class="th-name">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
          <td>
            <div v-if="price1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="price1[i].price"
                @change="changeData(price1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="price2[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="price2[i].price"
                @change="changeData(price2[i])"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * EV点検
 */
const CATEGORY = '19'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 契約内容カラム
      column1: [
        { name: '', class: 'bg-white'},
        { name: 'POG', class: 'ml-5 bg-teal-50'},
        { name: 'フルメンテナンス', class: 'ml-5 bg-blue-50'},
      ],
      // 回数カラム
      column2: [
        { name: '年 4 回' },
        { name: '年 12 回' },
      ],
      // POG
      price1: [],
      // フルメンテ
      price2: [],
    }
  },

  created() {
    this.dataByCategory()
  },
  
  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let p1 = []
      let p2 = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode.split('-')
        switch (code[0]) {
          // POG
          case '1':
            p1.push(d)
            break;

          // フルメンテ
          case '2':
            p2.push(d)
            break;
        }
      }

      this.price1 = this.setSort(p1)
      this.price2 = this.setSort(p2)
    },

    /**
     * 金額設定よるソート
     * @param target 対象データ
     */
    setSort(target) {
      const sortArr = ['4', '12']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.priceCode.split('-')[1] == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''

      name1 = `年${code[1]}回`

      if (code[0] == '1') {
        name2 = '・POG'
      } else {
        name2 = '・フルメンテナンス'
      }
      target.itemName = name1 + name2

      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}

.th-name {
  min-width: 80px;
}

</style>
