<template>
  <div>
    <PatrolTermComponent
      typeName="品質巡回 サイクル設定"
      :type="1"
      settionStrageName="PatrolTermStorageItemName">
    </PatrolTermComponent>
  </div>
</template>

<script>
import PatrolTermComponent from '../components/PatrolTermComponent.vue'

export default {
  components: {
    PatrolTermComponent
  },
}
</script>