import LogApp from './LogApp.vue'
import LogList from './pages/LogList.vue'

export default {
  path: '/log',
  component: LogApp,
  children: [
    {
      path: '/LogList',
      name: 'LogList',
      component: LogList,
      meta: { requiresAuth: true, title: 'apollo-RP - ログ一覧' },
      props: true
    }
  ]
}
