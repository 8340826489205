import DepartmentApp from './DepartmentApp.vue'
import DepartmentMaster from './pages/DepartmentMaster.vue'

export default {
  path: '/Department',
  component: DepartmentApp,
  children: [
    {
      path: '/DepartmentMaster',
      name: 'DepartmentMaster',
      component: DepartmentMaster,
      meta: { requiresAuth: true, title: 'apollo-RP - 部署設定' },
      props: true
    }
  ]
}
