<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #action>
        <div class="content-container-app">
          <div class="">
            <div>
              <!-- 得意先名 -->
              <span class="text-lg font-bold text-indigo-700">{{list.clientName1}}</span>
              
              <!-- 支店名 -->
              <span v-if="list.clientName2" class="text-gray-500 text-sm">
                （{{list.clientName2}}）
              </span>

              <!-- 物件名 -->
              <div class="font-bold">
                {{list.siteName}}
              </div>
            </div>

            <div class="text-blue-500 text-sm font-bold mt-5">
              ※【施工月】<br>棟を新規登録した際、その棟の施工月は<span class="text-red-600">デフォルト</span>です。<br>
                施工月設定画面で変更して下さい。
            </div>

            <div class="text-blue-500 text-sm font-bold mt-5">
              ※【棟のコード】<br>棟の名称を変更しても棟のコードが同じであれば同じ棟と見なします。
            </div>

            <div class="mt-5 height-full overflow-auto p-2 mb-3">
              <table class="table w-full">
                <thead class="th sticky top-0 bg-white">
                  <tr class="column2">
                    <th class="text-gray-500">棟のコード</th>
                    <th class="text-gray-500">棟の名称</th>
                    <th @click="add()">
                      <div class="flex justify-center">
                        <PlusCircleIcon class="cursor-pointer text-blue-500 w-8 h-8 hover:text-blue-600" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(row, i) in list.ridge" :key="i">
                  <tr>
                    <!-- 棟コード -->
                    <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm font-bold text-blue-700 w-20 text-center cursor-not-allowed">
                      <!-- <input 
                        type="number"
                        class="font-bold text-blue-700 cursor-text text-center border-none w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500"
                        v-model="row.ridgeCode"
                      /> -->
                      {{ row.ridgeCode }}
                    </td>
                    <!-- 棟の名称 -->
                    <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300">
                      <input 
                        type="text"
                        class="cursor-text border-none w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500"
                        v-model="row.name"
                      />
                    </td>
                    <!-- 削除 -->
                    <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 w-10">
                      <div class="flex justify-center">
                        <a href="#" @click="deleteRow(i)">
                          <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-transparent">{{reload}}</div>
            </div>
            <div>
              <PrimaryButton text="保存" size="normal" class="w-full mb-3" @click="save()"/>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/solid'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'

export default {
  components: {
    Modal,
    PrimaryButton,
    TrashIcon,
    PlusCircleIcon
  },

  props: {
    // 対象データ
    target: {
      type: Object
    },
    // 区分（1：品質巡回、2：巡回清掃、3：定期清掃、4：設備点検）
    type: {
      type: Number,
      default: null
    },
    // タイトル名
    typeName: {
      type: String,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      // 表示データ
      list: {},
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    async open() {
      if (this.open) {
        await this.getTarget()
      } else {
        return
      }
    },
    
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      this.list = {}
      if (this.target && Object.keys(this.target).length) {
        this.list = {
          clientName1: this.target.clientName1, 
          clientName2: this.target.clientName2,
          clientCode: this.target.clientCode,
          siteName: this.target.siteName,
          siteCode: this.target.siteCode
        }

        if (this.target.ridge) {
          this.list.ridge = utils.clone(this.target.ridge)
        }
        
        if (!this.list.ridge || !this.list.ridge.length) {
          this.add({ ridgeCode: 1, name: '' })
          this.add({ ridgeCode: 2, name: '' })
          this.add({ ridgeCode: 3, name: '' })
        }
      } else {
        return
      }
    },

    /**
     * 棟追加ボタン
     */
    add() {
      if (!this.list.ridge) {
        this.list.ridge = []
      }
      // 棟コードを自動生成
      let ridgeCode = 1
      this.list.ridge.forEach(r => {
        if (r.ridgeCode >= ridgeCode) {
          ridgeCode = r.ridgeCode + 1
        }
      })
      this.list.ridge.push({ ridgeCode, name: '' })
      this.reload++
    },

    /**
     * 棟削除ボタン
     */
    deleteRow(i) {
      this.list.ridge.splice(i, 1)
      if (!this.list.ridge || !this.list.ridge.length) {
        this.add()
      }
      this.reload++
    },

    /**
     * 保存ボタン
     */
    async save() {
      let noName = false
      let ridgeCode = []
      for (let i = 0; i < this.list.ridge.length; i++) {
        const r = this.list.ridge[i]
        if (r.name) {
          // 棟コード入力しているか確認（0はNG）
          if (!r.ridgeCode) {
            await dialogs.showErrorDialog('コード未入力・NGコード', r.name + 'のコードを「0（ゼロ）」以外の数字で入力してください。')
            return
          }
          // 棟コードが重複していないか確認
          if (ridgeCode.includes(Number(r.ridgeCode))) {
            await dialogs.showErrorDialog('コードの重複', r.name + 'のコードが重複しています。')
            return
          } else {
            ridgeCode.push(Number(r.ridgeCode))
          }
        } else {
          noName = true
        }
      }
      if (noName) {
        const res = await dialogs.showConfirmDialog('棟の名称 未記入あり', '棟の名称が記入されているものしか保存されません。\r\nよろしいでしょうか？')
        if (res != 'YES') {
          return
        }
      }
      this.list.type = Number(this.type)
      this.list.insertUser = this.$store.getters.user.id
      backend.postData('patrolRidge/save', this.list)

      //ログ出力
      let update = '新規'
      if (this.target.ridge) {
        update = '更新'
      }
      await logManager.recording(this, logManager.Loglevel.INFO, this.typeName, update, this.typeName + '（棟設定：'+'得意先：'+ this.target.clientName1 + '、物件名：'+ this.target.siteName + '、' +  this.list.ridge.length +'棟分）を保存しました。')

      this.$emit('modalClose')
    },

    /**
     * 初期化
     */
    closed() {
      // 表示データ
      this.list = {}
      this.$emit('modalClose')
    },
  }
}
</script>
<style scoped>
.content-container-app {
  width: 400px;
  max-height: calc(100vh - 130px);
}

.height-full {
  height: 300px;
}

@media screen and (max-width: 910px) {
  .content-container-app {
    width: 900px !important;
    max-height: calc(100vh - 90px);
  }

}
</style>