<template>
  <Popper
    ref="popper"
    trigger="clickToToggle"
    :append-to-body="true"
    :options="popperOptions"
    class="inline-block z-10"
    :disabled="disabled || menus.length === 0"
  >
    <button 
      ref="button"
      slot="reference"
      type="button"
      :disabled="disabled"
      class="w-full"
      :class="size"
    >
      <div class="flex items-center justify-center w-full">
        <div class="-ml-0.5 mr-2">
          <slot name="before" />
        </div>
        {{ text }}
        <div class="-mr-0.5 ml-2" >
          <slot name="after" />
        </div>
      </div>
    </button>
    <ul class="z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
      <template v-for="menu in menus">
        <li 
          :key="menu.value" 
          class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
          @click="select(menu)"
        >
          <span class="font-normal block truncate">
            {{ menu.text }}
          </span>
        </li>
      </template>
    </ul>
  </Popper>      
</template>
<script>
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  fn: (data) => {
    data.instance.popper.style.width = data.offsets.reference.width + 'px'
    return data
  }
}

const popperOptions = {
  placement: 'bottom-start',
  modifiers: {
    sameWidth
  }
}

export default {
  components: {
    Popper
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    menus: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popperOptions,
    }
  },
  methods: {
    select(selectedItem) {
      this.$refs.popper.doClose()
      // this.showPopup = false
      this.$emit('select', selectedItem)
    },
  }
}
</script>
<style scoped>
.xs {
  @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.sm {
  @apply inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.normal {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.lg {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.xl {
  @apply inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
button:disabled {
  @apply bg-indigo-600 hover:bg-indigo-600 cursor-not-allowed;
}
</style>
