<template>
  <WmsSelectionButton
    v-bind="$attrs"
    :selections="cleanCountSelections"
    :multiSelect="false"
    :value="internalValue"
    @change="onChange"
  />
</template>
<script>
import WmsSelectionButton from '@wmscomponents/WmsSelectionButton.vue'

export default {
  inheritAttrs: false,

  components: {
    WmsSelectionButton
  },
  
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    house: {
      type: String,
      default: ''
    },

    category2: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    /**
     * 選択肢
     */
    cleanCountSelections() {

      if (this.category2[0] && this.category2[0] == 'お客様直') {
        
        if (this.house != '') {
          let h = Number(this.house)

          // 20戸以下
          if (h <= 20) {
            return [
              {
                value: '月 1 回',
                text: '月 1 回'
              },
              {
                value: '週 1 回',
                text: '週 1 回'
              },
              {
                value: '週 2 回',
                text: '週 2 回'
              },
              {
                value: '週 3 回',
                text: '週 3 回'
              },
              {
                value: '週 3.5 回',
                text: '週 3.5 回'
              },
              {
                value: '週 4 回',
                text: '週 4 回'
              },
              {
                value: '週 4.5 回',
                text: '週 4.5 回'
              },
              {
                value: '週 5 回',
                text: '週 5 回'
              },
              {
                value: '週 6 回',
                text: '週 6 回'
              }
            ]
          } else if (h >= 21 && h <= 25) {
            return [
              {
                value: '週 3 回',
                text: '週 3 回'
              },
              {
                value: '週 3.5 回',
                text: '週 3.5 回'
              },
              {
                value: '週 4 回',
                text: '週 4 回'
              },
              {
                value: '週 4.5 回',
                text: '週 4.5 回'
              },
              {
                value: '週 5 回',
                text: '週 5 回'
              },
              {
                value: '週 6 回',
                text: '週 6 回'
              }
            ]
          } else {
            return [
              {
                value: '週 3.5 回',
                text: '週 3.5 回'
              },
              {
                value: '週 4 回',
                text: '週 4 回'
              },
              {
                value: '週 4.5 回',
                text: '週 4.5 回'
              },
              {
                value: '週 5 回',
                text: '週 5 回'
              },
              {
                value: '週 6 回',
                text: '週 6 回'
              }
            ]
          }
        } else {
          return [
            {
              value: '週 3.5 回',
              text: '週 3.5 回'
            },
            {
              value: '週 4 回',
              text: '週 4 回'
            },
            {
              value: '週 4.5 回',
              text: '週 4.5 回'
            },
            {
              value: '週 5 回',
              text: '週 5 回'
            },
            {
              value: '週 6 回',
              text: '週 6 回'
            }
          ]
        }
      } else {
        return [
          {
            value: '月 1 回',
            text: '月 1 回'
          },
          {
            value: '月 2 回',
            text: '月 2 回'
          },
          {
            value: '週 1 回',
            text: '週 1 回'
          },
          {
            value: '週 2 回',
            text: '週 2 回'
          },
          {
            value: '週 3 回',
            text: '週 3 回'
          },
          {
            value: '週 3.5 回',
            text: '週 3.5 回'
          },
          {
            value: '週 4 回',
            text: '週 4 回'
          },
          {
            value: '週 4.5 回',
            text: '週 4.5 回'
          },
          {
            value: '週 5 回',
            text: '週 5 回'
          },
          {
            value: '週 6 回',
            text: '週 6 回'
          }
        ]
      }
    },

    internalValue() {
      if (!this.cleanCountSelections.length) {
        return []
      }
      const value = this.value.map((v) => {
        return this.cleanCountSelections.find((selection) => {
          return selection.value === v
        })
      })
      return value
    }
  },

  data() {
    return {
      // cleanCountSelections
    }
  },

  methods: {
    onChange(selectionItems) {
      const value = selectionItems.map((item) => {
        return item.value
      })
      this.$emit('change', value)
    }
  }
}
</script>