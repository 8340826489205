<template>
  <div>
    <ProgressTracker 
      :steps="steps"
      :showCheckMarkForCompletedStep="true"
      class="task-progress-tracker"
      @change="onChange" 
    />
  </div>
</template>
<script>
import ProgressTracker from '@components/ProgressTracker.vue'
import * as taskRequestManager from '@managers/taskRequestManager'
import * as estimateManager from '@managers/estimateManager'
import * as dialogs from '@libs/dialogs'
import * as requestPostManager from '@managers/requestPostManager'
import * as utils from '@libs/utils'
import * as orderManager from '@managers/orderManager'
import * as logManager from '@managers/logManager'

export default {
  components: {
    ProgressTracker
  },

  props: ['record'],

  computed: {
    steps() {
      let steps = []

      // 普通のサブの場合
      if (this.record.taskRequest && this.record.taskRequest.length > 0) {
        steps = this.setStep(this.record.taskRequest, this.record)

      // 受注のサブの場合
      } else if (this.record.taskRequestOrder && this.record.taskRequestOrder.length > 0) {
        steps = this.setStep(this.record.taskRequestOrder, this.record)
      }

      // タスクを表示順に並び替え
      if (steps.length > 0 && steps[0].data.displayOrder && steps[0].data.displayOrder != '') {
        steps.sort(function(a, b) {
          if (a.data.displayOrder > b.data.displayOrder) {
            return 1
          } else {
            return -1
          }
        })
      }

      return steps
    }
  },

  methods: {
    /**
     * 編集・完了できるか確認
     */
    async checkCanEdit(data) {
      // メインが完了していたら編集不可
      if (this.record.status == 'done') {
        await dialogs.showErrorDialog('変更できません', 'メインが完了しているため、変更できません。')
        return false
      } else if (this.record.status == 'remove') {
        await dialogs.showErrorDialog('変更できません', 'メインが取消されているため、変更できません。')
        return false
      }

      // ログインユーザー名
      let name = this.$store.getters.user.user_name
      name = utils.deleteSpace(utils.deleteKana(name))

      // 管理者権限はOK
      const auth = this.$store.getters.auth
      if (auth.adminAuth) {
        return true
      }
      
      // ログインユーザーがメイン担当者の場合、OK
      let main = utils.deleteSpace(utils.deleteKana(this.record.toStaffName))
      if (name == main) {
        return true
      }
      
      // ログインユーザーがメイン依頼者の場合、OK
      let irai = utils.deleteSpace(utils.deleteKana(this.record.byUser))
      if (name == irai) {
        return true
      }
      
      // 担当者が未定の場合、同じ部署の人はOK
      if (main == '未定') {
        let d = this.$store.getters.dualPosition
        for (let i = 0; i < d.length; i++) {
          const tar = d[i]
          if (tar.departmentName == this.record.toDepartment || tar.departmentId == this.record.toDepartmentId) {
            return true
          }
        }
        // デンタツの場合
        // デンタツの全データ取得
        let resDT = this.$store.getters.transDepartmentList
        // 該当のデンタツ部署のデータを取得
        let dentatsu = resDT.find((r) => {
          return (r.name == this.record.toDepartment || r.departmentId == this.record.toDepartmentId)
        })
        // 該当のデンタツに紐づく部署の人はOK（RLS_デンタツならRLS全員OK）
        if (dentatsu && dentatsu.transDepartment) {
          for (let i = 0; i < d.length; i++) {
            const tar = d[i]
            if (tar.departmentId == dentatsu.transDepartment) {
              return true
            }
          }
        }
      }

      // ログインユーザーがサブ担当者の場合、OK
      let tanto = utils.deleteSpace(utils.deleteKana(data.data.toStaffName))
      if (name == tanto) {
        return true
      }

      // サブ担当者に関係なく、同じ部署の人はOK
      let d = this.$store.getters.dualPosition
      for (let i = 0; i < d.length; i++) {
        const tar = d[i]
        if (tar.departmentName == data.data.departmentName || tar.departmentId == data.data.departmentId) {
          return true
        }
      }
      await dialogs.showErrorDialog('変更できません', '担当者と同じ部署でないため、変更できません。')
      return false
    },

    /**
     * メニュ－リスト クリックイベント
     */
    async onChange(selectedValue, step) {
      if (selectedValue == '完了にする' || selectedValue == '未完了に戻す') {
        // 編集権限がなければ編集不可
        const auth = await this.checkCanEdit(step)
        if (!auth) {
          return
        }
      }

      if (selectedValue === '完了にする') {
        if (step.data.type == 'task_request') {
          if (step.data.status == 'done') {
            await dialogs.showErrorDialog('サブタスクのステータス', 'すでに完了しています。')
            return
          }
          
          let result
          let refresh = false
          
          // 最終完了タスクが「1」の場合
          if (step.data.finalCompletionTask == "1") {
            // リクエストポストIDを元にリクエストポストに紐づく、サブタスクを検索
            let subTasks = await taskRequestManager.getByRequestPostId(this, step.data.requestPostId, null)

            // 完了するサブタスク以外のサブタスクがdoneになっているか
            let filterData = subTasks.filter(s => s._id != step.data._id)
            let result2 = filterData.every(function(val){
              return val.status == 'done'
            })

            // trueの場合、サブタスクのステータスをdoneにする
            if (result2) {
              step.data.status = 'done'
              result = await taskRequestManager.update(this, step.data)

              // postgresに登録
              await taskRequestManager.saveEstimateSubmission(step.data, this.$store.getters.user.id)
            } else {
              await dialogs.showErrorDialog('サブタスクのステータス', '他のタスクを完了してから、再度行ってください。')
              return
            }
          } else {
            // statusを完了にする(画面の色が即変わるようにここでステータスを変更)
            step.data.status = 'done'
            result = await taskRequestManager.update(this, step.data)
            // postgresに登録
            await taskRequestManager.saveEstimateSubmission(step.data, this.$store.getters.user.id)
          }

          // サブタスクのステータスが全てdoneの場合、リクエストポストのステータスをdoneにする
          let subTasks = await taskRequestManager.getByRequestPostId(this, step.data.requestPostId, null)
          let subTasksStatusResult = subTasks.every(function(val){
            return val.status == 'done'
          })
          if (subTasksStatusResult) {
            const result3 = requestPostManager.markAsDone(this, step.data.requestPostId)
            if (result3) {
              const requestPost = await requestPostManager.getRequestPost(this, step.data.requestPostId)
              requestPost.status = 'done'
              logManager.recording(this, logManager.Loglevel.INFO, 'メインタスク', 'ステータス完了', 'メインタスク（お問い合わせ番号：' + requestPost.requestNo + '）のステータスを完了しました。')
              refresh = true
            }
          }

          if (result) {
            await dialogs.showSuccessDialog('サブタスクのステータス変更', 'ステータスを完了にしました。')
            if (step.data.subTaskNo) {
              logManager.recording(this, logManager.Loglevel.INFO, 'サブタスク', 'ステータス完了', 'サブタスク（お問い合わせ番号：' + step.data.subTaskNo + '）のステータスを完了しました。')
            } else {
              logManager.recording(this, logManager.Loglevel.INFO, 'サブタスク', 'ステータス完了', 'サブタスクのステータスを完了しました。')
            }
          } else {
            await dialogs.showErrorDialog('サブタスクのステータス変更', 'ステータスの変更ができませんでした。')
          }

          if (refresh) this.$emit("toParent", "refresh", null)
        } else if (step.data.type == 'task_request_order') {
          if (step.data.status == 'done') {
            await dialogs.showErrorDialog('受注サブタスクのステータス', 'すでに完了しています。')
            return
          }
          
          let result
          let refresh = false

          // 最終完了タスクが「1」の場合
          if (step.data.finalCompletionTask == "1") {
            // 受注IDを元に受注に紐づく、サブタスクを検索
            let subTasks = await taskRequestManager.getByOrderId(this, step.data.orderId, null)

            // 完了するサブタスク以外のサブタスクがdoneになっているか
            let filterData = subTasks.filter(s => s._id != step.data._id)
            let result2 = filterData.every(function(val){
              return val.status == 'done'
            })

            // trueの場合、サブタスクのステータスをdoneにする
            if (result2) {
              step.data.status = 'done'
              result = await taskRequestManager.orderTaskRequestUpdate(this, step.data)
            } else {
              await dialogs.showErrorDialog('受注サブタスクのステータス', '他のタスクを完了してから、再度行ってください。')
              return
            }
          } else {
            // statusを完了にする(画面の色が即変わるようにここでステータスを変更)
            step.data.status = 'done'
            result = await taskRequestManager.orderTaskRequestUpdate(this, step.data)
          }

          // サブタスクのステータスが全てdoneの場合、リクエストポストのステータスをdoneにする
          let subTasks = await taskRequestManager.getByOrderId(this, step.data.orderId, null)
          let subTasksStatusResult = subTasks.every(function(val){
            return val.status == 'done'
          })
          if (subTasksStatusResult) {
            const result3 = orderManager.markAsDone(this, step.data.orderId)
            if (result3) {
              const order = await orderManager.getOrderData(this, step.data.orderId)
              order.status = 'done'

              // 見積りのデータの受注ステータス更新、完了にした人を保存
              // ログインユーザー名
              let name = this.$store.getters.user.user_name
              name = utils.deleteKana(name)
              await estimateManager.changeOrderStatus(this, order.estimateNo, '受注完了', name)
              
              logManager.recording(this, logManager.Loglevel.INFO, '受注タスク', 'ステータス完了', '受注タスク（お問い合わせ番号：' + order.requestNo + '）のステータスを完了しました。')
              refresh = true
            }
          }

          if (result) {
            await dialogs.showSuccessDialog('受注サブタスクのステータス', 'ステータスを完了にしました。')
            if (step.data.subTaskNo) {
              logManager.recording(this, logManager.Loglevel.INFO, '受注サブタスク', 'ステータス完了', '受注サブタスク（お問い合わせ番号：' + step.data.subTaskNo + '）のステータスを完了しました。')
            } else {
              logManager.recording(this, logManager.Loglevel.INFO, '受注サブタスク', 'ステータス完了', '受注サブタスクのステータスを完了しました。')
            }
          } else {
            await dialogs.showErrorDialog('受注サブタスクのステータス', 'ステータスの変更ができませんでした。')
          }

          if (refresh) this.$emit("toParent", "refresh", null)
        }
      }

      else if (selectedValue === '未完了に戻す') {
        if (step.data.type == 'task_request') {
          if (step.data.status == 'open') {
            await dialogs.showErrorDialog('サブタスクのステータス', 'まだ未完了です。')
            return
          }
          // statusを未完了にする(画面の色が即変わるようにここでステータスを変更)
          step.data.status = 'open'
          let result = await taskRequestManager.update(this, step.data)
          if (result) {
            await dialogs.showSuccessDialog('サブタスクのステータス変更', 'ステータス未完了に戻しました。')
            // postgresに登録
            await taskRequestManager.saveEstimateSubmission(step.data, this.$store.getters.user.id)
            if (step.data.subTaskNo) {
              logManager.recording(this, logManager.Loglevel.INFO, 'サブタスク', 'ステータス未完了', 'サブタスク（お問い合わせ番号：' + step.data.subTaskNo + '）のステータスを未完了に戻しました。')
            } else {
              logManager.recording(this, logManager.Loglevel.INFO, 'サブタスク', 'ステータス未完了', 'サブタスクのステータスを未完了に戻しました。')
            }
          } else {
            await dialogs.showErrorDialog('サブタスクのステータス変更', 'ステータスの変更ができませんでした。')
          }
        } else if (step.data.type == 'task_request_order') {
          if (step.data.status == 'open') {
            await dialogs.showErrorDialog('受注サブタスクのステータス', 'まだ未完了です。')
            return
          }
          // statusを未完了にする(画面の色が即変わるようにここでステータスを変更)
          step.data.status = 'open'
          let result = await taskRequestManager.orderTaskRequestUpdate(this, step.data)
          if (result) {
            await dialogs.showSuccessDialog('受注サブタスクのステータス', 'ステータス未完了に戻しました。')
            if (step.data.subTaskNo) {
              logManager.recording(this, logManager.Loglevel.INFO, '受注サブタスク', 'ステータス未完了', '受注サブタスク（お問い合わせ番号：' + step.data.subTaskNo + '）のステータスを未完了に戻しました。')
            } else {
              logManager.recording(this, logManager.Loglevel.INFO, '受注サブタスク', 'ステータス未完了', '受注サブタスクのステータスを未完了に戻しました。')
            }
          } else {
            await dialogs.showErrorDialog('受注サブタスクのステータス', 'ステータスの変更ができませんでした。')
          }
        }
      }

      else if (selectedValue === '見積画面を開く') {
        // 「見積作成」が含まれていたら、見積り画面に遷移
        if (step.value.match(/見積作成/)) {
          const estimates = await estimateManager.getEstimateByTaskRequestId(this, step.data._id)
          if (estimates.length > 1) {
            // 対象の見積を取得、複数あれば選択
            this.$emit("toParent", 'showEstimateSelector', step.data)
          } else if (estimates.length == 1) {
            // 見積１件 ⇒ 詳細画面へ遷移
            this.$router.push({ 
              name: 'EstimateForm',
              query: {
                estimateNo: estimates[0].estimateNo
              }
            })
          } else {
            // 見積無し ⇒ 新規作成
            this.$router.push({ 
              name: 'EstimateForm',
              query: {
                taskRequestId: step.data._id,
                requestPostId: step.data.requestPostId
              }
            })
          }
        }
      }

      else if (selectedValue === '見積参照画面を開く') {
        if (!step.data.estimateNo) {
          await dialogs.showErrorDialog('見積りNo取得失敗', '見積り参照画面が開けません。')
        } else {
          const route = this.$router.resolve({ 
            name: 'EstimateView',
            query: {
              estimateNo: step.data.estimateNo
            }
          })
          window.open(route.href, '_brank')
        }
      }

      else if (selectedValue === '受注画面（配信テンプレート）を開く') {
        if (!step.data.orderId) {
          await dialogs.showErrorDialog('受注No取得失敗', '受注画面が開けません。')
        } else {
          const route = this.$router.resolve({ 
            name: 'RequestPost',
            query: {
              mode: 6,
              orderId: step.data.orderId,
              position: 'template'
            }
          })
          window.open(route.href, '_brank')
        }
      }

      else if (selectedValue === '受注画面を開く') {
        if (!step.data.orderId) {
          await dialogs.showErrorDialog('受注No取得失敗', '受注画面が開けません。')
        } else {
          const route = this.$router.resolve({ 
            name: 'RequestPost',
            query: {
              mode: 6,
              orderId: step.data.orderId
            }
          })
          window.open(route.href, '_brank')
        }
      }
    },

    /**
     * サブタスクの表示データを成形
     * @param tasks サブタスクデータ
     * @param main メインデータ含む
     */
    setStep(tasks, main) {
      let steps = []
      if (tasks && tasks.length > 0) {
        steps = tasks.map((r) => {
          // ステータスが未完了
          if (r.status == 'open') {
            // 期限が今日のものは色をオレンジに変更するため、ステータス名変更
            if (r.limitDate == utils.getToday()) {
              r.status = 'limitToday'

            // 期限が過ぎているもの（期限が昨日以降）
            } else if (new Date(r.limitDate) < new Date(utils.getToday())) {
              r.status = 'limitOut'
            }
          }

          let v =  ''
          if (r.toStaffName == '未定') {
            v = r.requestTask + '\r\n' + r.departmentName
          } else {
            v = r.requestTask + '\r\n' + utils.deleteKana(r.toStaffName)
          }
          
          const val = {
            value: v,
            selection: ['完了にする', '未完了に戻す'],
            status: r.status,
            data: r
          }

          if (main.status == 'remove') {
            val.selection = []
            return val
          } else if (r.requestTask.match(/見積作成/)) {
            val.selection = ['完了にする', '未完了に戻す', '見積画面を開く']
            return val
          } else if (r.requestTask.match(/契約書/)) {
            val.selection = ['完了にする', '未完了に戻す', '見積参照画面を開く']
            if (main.estimateNo) {
              val.data = { ...val.data, estimateNo: main.estimateNo }
            }
            return val
          } else if (r.requestTask.match(/配信/)) {
            val.selection = ['完了にする', '未完了に戻す', '受注画面（配信テンプレート）を開く']
            return val
          } else if (r.requestTask.match(/発注書/)) {
            val.selection = ['完了にする', '未完了に戻す', '受注画面を開く']
            return val
          } else {
            return val
          }
        })
      }
      return steps
    }
  }
}
</script>