<template>
  <div class="main">
    <div>
      ラウンドサービス、日常清掃、定期清掃 それぞれの単価表をご参照ください。
    </div>
    <div class="mt-5">
      ●ラウンドサービスはシングル単価を使用しています。（ファミリー単価は使用していません）
    </div>
    <div class="mt-5">
      ●日常清掃は
    </div>
    <div class="ml-3">
      清掃回数が週1回もしくは週2回<br>時間指定なし<br>清掃時間3時間<br>の単価を使用しています。
    </div>
    <div class="mt-5">
      ●定期清掃は、wash追加（300㎡越えは6回/年）の単価を使用しています。
    </div>
  </div>
</template>
<script>

/**
 * ラウンドプラス
 */
// const CATEGORY = '2'

export default {
  props: {
    code: {
      type: String
    }
  },
}
</script>
<style scoped>

</style>
