<template>
  <Menu :menuItems="menuItems" @select="menuSelect" :strongColor="strongColor">
    <template #header>
      <div>
        <p class="text-xs text-gray-500">
          Signed in as
        </p>
        <div class="flex items-center  mt-1">
          <Icon iconName="UserCircle" />
          <p class="text-sm font-medium text-gray-900 truncate flex-1 ml-1">
            {{ user ? user.user_name : 'Not Signed in.' }}
          </p>
        </div>
      </div>
    </template>
  </Menu>
</template>
<script>
import Menu from '@components/Menu.vue'
import Icon from '@components/Icon.vue'
import * as dialogs from '@libs/dialogs'

const menuItems = [
  {
    id: 'signout',
    icon: 'Logout',
    text: 'サインアウト'
  }
]

export default {
  components: {
    Menu,
    Icon
  },

  props: {
    strongColor: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    user() {
      return this.$store.getters.user
    },

    menuItems() {
      return menuItems 
    }
  },

  methods: {
    async menuSelect(menuItem) {
      console.log(menuItem)
      if (menuItem.id === 'signout') {
        const dialogResult = await dialogs.showConfirmDialog('サインアウト', 'サインアウトしますか？')
        if (dialogResult === 'YES') {
          this.$pigeon.signout()
          this.$store.dispatch('terminate')
          window.document.location.href = './signin'
          // window.document.location.reload()
        }
      }
    }
  }
}
</script>