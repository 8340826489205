import { render, staticRenderFns } from "./EstimatePurchase.vue?vue&type=template&id=66059498&scoped=true&"
import script from "./EstimatePurchase.vue?vue&type=script&lang=js&"
export * from "./EstimatePurchase.vue?vue&type=script&lang=js&"
import style0 from "./EstimatePurchase.vue?vue&type=style&index=0&id=66059498&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66059498",
  null
  
)

export default component.exports