<template>
  <div>
    <label 
      :for="name" 
      class="block text-xs font-bold text-gray-700 relative"
    >
      {{ caption }} <span v-if="required === true" class="absolute -top-0.5 ml-1 text-red-500"> * </span>
    </label>
    <div class="mt-1 flex relative">
      <InputIcon v-if="error" iconName="ExclamationCircle" :iconColor="errorColor || 'red'" :message="errorMessage" />
      <input 
        :key="renderKey"
        ref="numinput"
        :name="name" 
        type="text" 
        class="py-1.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
        :class="[disabled ? ['bg-gray-200'] : [], error ? 'error' : '', error ? 'NumericTextInput2Display' : '']"
        autocomplete="off"
        :value="formattedValue"
        :disabled="disabled ? 'disabled' : false"
        @change="onChange($event.target.value)"
        @keydown.enter="handleEnterKey"
      />
    </div>
  </div>
</template>

<script>
import * as utils from '@libs/utils'
import InputIcon from '@components/InputIcon.vue'
export default {
  components: {
    InputIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    decimalPlace: {
      type: Number,
      default: 0
    },

    suffix: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    caption: {
      type: String,
      default: ''
    },

    error: {
      type: Boolean,
      default: false
    },
    
    errorMessage: {
      type: String,
      default: ''
    },

    errorColor: {
      type: String,
      default: ''
    },
    
    format: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      text: '',
      renderKey: 0,
    }
  },

  created() {
    this.setValue(this.value)
  },

  watch: {
    value(newValue) {
      this.setValue(newValue)
    }
  },

  computed: {
    formattedValue() {
      if (this.text === '') {
        return this.text
      } else {
        if (this.format) {
          return `${utils.format(this.text, this.decimalPlace)}${this.suffix}`
        } else {
          return this.text
        }
      }
    }
  },

  methods: {
    setValue(value) {
      const hankaku = utils.hankaku(value || '')
      this.text = utils.sanitizeStringForNumber(hankaku)
      this.renderKey++
    },
    
    onChange(value) {
      console.log(`onChange : ${value}`)
      this.setValue(value)
      this.$emit('change', this.text)
    },

    handleEnterKey() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit('onEnterKey', { target: this.$refs.numinput })
        })
      })
    }
  }
}
</script>
<style scoped>
input:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
  opacity: 1;
  cursor: not-allowed;
}
</style>