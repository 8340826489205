import * as utils from '@libs/utils'
// import * as taskRequestManager from '@managers/taskRequestManager'

// 受注メイン
const ORDERS_COLLECTION_NAME = 'order'

export const OrderSchema = {
  // 受注番号
  orderNo: '',
  // 見積り番号
  estimateNo: '',
  // 案件名
  caseName: '',
  // 物件名
  buildingName: '',
  // メモ
  orderMemo: '',
  // 予定送付日（新規の得意先の場合）
  orderSendingDate: [],
  // 予定送付日備考（予定送付日が変更有の場合）
  orderSendingDateMemo: '',
  // 締日（新規の得意先の場合）
  orderClosingDate: [],
  // 締日備考（締日が変更有の場合）
  orderClosingDateMemo: '',
  // 請求送付日（新規の得意先の場合）
  orderBillingDate: [],
  // 請求送付日備考（請求送付日が変更有の場合）
  orderBillingDateMemo: '',
  // 請求宛先名（新規の得意先の場合）
  orderBillingName: [],
  // 請求宛先名備考（請求宛先名が変更有の場合）
  orderBillingNameMemo: '',
  // 登録内容
  orderSummary: '',
  // 契約開始日
  contractDate: '',
  // 契約終了日
  contractEndDate: '',
  // 見積り明細カテゴリー
  estimationDetailsCategory: [],
  // 見積り明細カテゴリーコード
  estimationDetailsCategoryCode: [],
  // 見積明細カテゴリー名
  categoryName: [],
  // 区分
  classification: [],

  // ラウンド
  orderRls: [],
  estimationRoundCommonFile: {},

  // ラウンドプラス
  orderRlsPlus: [],
  estimationRoundPlusCommonFile: {},

  // 日常清掃
  orderEverydays: [],
  estimationEverydayCommonFile: {},

  // 管理
  orderManagerWorks: [],
  managerWorkCommonFile: {},

  // 定期
  orderRegular: [],
  regularCleanCommonFile: {},

  // フロントサポート
  orderFront: [],
  frontSupportCommonFile: {},

  // フロントサポートプラス
  orderFrontPlus: [],
  frontSupportPlusCommonFile: {},

  // ランドトラッシュ
  orderRlsTrash: [],
  roundTrashCommonFile: {},

  // クオリティーレポート
  orderQuality: [],
  qualityReportCommonFile: {},

  // ガラス清掃
  orderGlass: [],
  glassCleanCommonFile: {},

  // 排水管清掃
  orderDrain: [],
  estimationDorainPipeCommonFile: {},

  // 貯水槽清掃
  orderWaterTank: [],
  estimationWaterTankCommonFile: {},

  // 植栽剪定
  orderPlanting: [],
  plantingCommonFile: {},

  // 特別清掃
  orderSpecialCleans: [],
  specialCleanCommonFile: {},

  // 消防設備点検
  orderFire: [],
  fireInspectCommonFile: {},

  // 建築設備定期検査
  orderConstruction: [],
  constructionEquipmentInspectCommonFile: {},

  // 特定建築物定期調査
  orderSpConstruction: [],
  specificInspectCommonFile: {},

  // 共用部設備点検
  orderCommon: [],
  commonAreaFacilityInspectionCommonFile: {},

  // エレベータ保守点検
  orderEv: [],
  evInspectCommonFile: {},

  // 増圧ポンプ点検
  orderPress: [],
  pressPumpInspectCommonFile: {},

  // 給排水ポンプ点検
  orderWaterSupply: [],
  waterSupplyDrainagePumpInspectionCommonFile: {},

  // 簡易専用水道
  orderSimpleWater: [],
  simpleWaterSupplyCommonFile: {},

  // 管球対応
  orderTube: [],
  tubeBulbSupportCommonFile: {},

  // 粗大ごみ
  orderBulkyTrash: [],
  bulkyWasteSupportCommonFile: {},

  // 物品販売
  orderSale: [],
  merchandiseSalesCommonFile: {},

  // 緊急対応
  orderEmergency: [],
  emergencyResponseCommonFile: {},

  // 防火対象物点検
  orderFireObj: [],
  fireProtectionInspectionCommonFile: {},

  // 汚水槽清掃
  orderSewage: [],
  sewageTankCleaningCommonFile: {},

  // 防火設備定期検査
  orderFireFixed: [],
  firePreventionObjectInspectionCommonFile: {},

  // 連結送水管耐圧性能点検
  orderConnented: [],
  connectingWaterPipePressureInspectionCommonFile: {},

  // スポット清掃
  orderSpot: [],
  // とりあえず、スポット清掃は10個定義する
  spotCommonFile1: {},
  spotCommonFile2: {},
  spotCommonFile3: {},
  spotCommonFile4: {},
  spotCommonFile5: {},
  spotCommonFile6: {},
  spotCommonFile7: {},
  spotCommonFile8: {},
  spotCommonFile9: {},
  spotCommonFile10: {},

  // MRS
  orderMRS: [],
  mrsCommonFile: {},

  // カテゴリコードがないもの
  orderOthers: [],
  // とりあえず、カテゴリがないものは10個定義する
  otherCommonFile1: {},
  otherCommonFile2: {},
  otherCommonFile3: {},
  otherCommonFile4: {},
  otherCommonFile5: {},
  otherCommonFile6: {},
  otherCommonFile7: {},
  otherCommonFile8: {},
  otherCommonFile9: {},
  otherCommonFile10: {},

  // 配信テンプレート
  template: '',
}

/**
 * 新規受注データ 生成
 * @returns 
 */
export function createNewOrderModel() {
    const newOrder = { ...utils.clone(OrderSchema) }

    newOrder.estimationRoundCommonFile = createEmptyAttachment()
    newOrder.estimationRoundPlusCommonFile = createEmptyAttachment()
    newOrder.estimationEverydayCommonFile = createEmptyAttachment()
    newOrder.managerWorkCommonFile = createEmptyAttachment()
    newOrder.regularCleanCommonFile = createEmptyAttachment()
    newOrder.frontSupportCommonFile = createEmptyAttachment()
    newOrder.frontSupportPlusCommonFile = createEmptyAttachment()
    newOrder.roundTrashCommonFile = createEmptyAttachment()
    newOrder.qualityReportCommonFile = createEmptyAttachment()
    newOrder.glassCleanCommonFile = createEmptyAttachment()
    newOrder.estimationDorainPipeCommonFile = createEmptyAttachment()
    newOrder.estimationWaterTankCommonFile = createEmptyAttachment()
    newOrder.plantingCommonFile = createEmptyAttachment()
    newOrder.specialCleanCommonFile = createEmptyAttachment()
    newOrder.fireInspectCommonFile = createEmptyAttachment()
    newOrder.constructionEquipmentInspectCommonFile = createEmptyAttachment()
    newOrder.specificInspectCommonFile = createEmptyAttachment()
    newOrder.commonAreaFacilityInspectionCommonFile = createEmptyAttachment()
    newOrder.evInspectCommonFile = createEmptyAttachment()
    newOrder.pressPumpInspectCommonFile = createEmptyAttachment()
    newOrder.waterSupplyDrainagePumpInspectionCommonFile = createEmptyAttachment()
    newOrder.simpleWaterSupplyCommonFile = createEmptyAttachment()
    newOrder.tubeBulbSupportCommonFile = createEmptyAttachment()
    newOrder.bulkyWasteSupportCommonFile = createEmptyAttachment()
    newOrder.merchandiseSalesCommonFile = createEmptyAttachment()
    newOrder.emergencyResponseCommonFile = createEmptyAttachment()
    newOrder.sewageTankCleaningCommonFile = createEmptyAttachment()
    newOrder.fireProtectionInspectionCommonFile = createEmptyAttachment()
    newOrder.connectingWaterPipePressureInspectionCommonFile = createEmptyAttachment()
    newOrder.firePreventionObjectInspectionCommonFile = createEmptyAttachment()
    newOrder.spotCommonFile1 = createEmptyAttachment()
    newOrder.spotCommonFile2 = createEmptyAttachment()
    newOrder.spotCommonFile3 = createEmptyAttachment()
    newOrder.spotCommonFile4 = createEmptyAttachment()
    newOrder.spotCommonFile5 = createEmptyAttachment()
    newOrder.spotCommonFile6 = createEmptyAttachment()
    newOrder.spotCommonFile7 = createEmptyAttachment()
    newOrder.spotCommonFile8 = createEmptyAttachment()
    newOrder.spotCommonFile9 = createEmptyAttachment()
    newOrder.spotCommonFile10 = createEmptyAttachment()
    newOrder.mrsCommonFile = createEmptyAttachment()
    newOrder.otherCommonFile1 = createEmptyAttachment()
    newOrder.otherCommonFile2 = createEmptyAttachment()
    newOrder.otherCommonFile3 = createEmptyAttachment()
    newOrder.otherCommonFile4 = createEmptyAttachment()
    newOrder.otherCommonFile5 = createEmptyAttachment()
    newOrder.otherCommonFile6 = createEmptyAttachment()
    newOrder.otherCommonFile7 = createEmptyAttachment()
    newOrder.otherCommonFile8 = createEmptyAttachment()
    newOrder.otherCommonFile9 = createEmptyAttachment()
    newOrder.otherCommonFile10 = createEmptyAttachment()

    return newOrder
}

/**
 * 空の添付ファイル項目 生成
 * @returns 
 */
export function createEmptyAttachment() {
  const emptyAttachmen = utils.clone(AttachmentSchema)
  return emptyAttachmen
}

/** 
 * 添付ファイル項目の初期値
 */
export const AttachmentSchema = {
  originalName: '',
  url: '',
  content: ''
}

/**
 * 登録データ 生成
 */
function createRegistrationData(target) {
    const registrationData = utils.clone(target)

    // 添付ファイル項目設定
    createAttachmentRegistrationData(registrationData, 'estimationRoundCommonFile')
    createAttachmentRegistrationData(registrationData, 'estimationRoundPlusCommonFile')
    createAttachmentRegistrationData(registrationData, 'estimationEverydayCommonFile')
    createAttachmentRegistrationData(registrationData, 'managerWorkCommonFile')
    createAttachmentRegistrationData(registrationData, 'regularCleanCommonFile')
    createAttachmentRegistrationData(registrationData, 'frontSupportCommonFile')
    createAttachmentRegistrationData(registrationData, 'frontSupportPlusCommonFile')
    createAttachmentRegistrationData(registrationData, 'roundTrashCommonFile')
    createAttachmentRegistrationData(registrationData, 'qualityReportCommonFile')
    createAttachmentRegistrationData(registrationData, 'glassCleanCommonFile')
    createAttachmentRegistrationData(registrationData, 'estimationDorainPipeCommonFile')
    createAttachmentRegistrationData(registrationData, 'estimationWaterTankCommonFile')
    createAttachmentRegistrationData(registrationData, 'plantingCommonFile')
    createAttachmentRegistrationData(registrationData, 'specialCleanCommonFile')
    createAttachmentRegistrationData(registrationData, 'fireInspectCommonFile')
    createAttachmentRegistrationData(registrationData, 'constructionEquipmentInspectCommonFile')
    createAttachmentRegistrationData(registrationData, 'specificInspectCommonFile')
    createAttachmentRegistrationData(registrationData, 'commonAreaFacilityInspectionCommonFile')
    createAttachmentRegistrationData(registrationData, 'evInspectCommonFile')
    createAttachmentRegistrationData(registrationData, 'pressPumpInspectCommonFile')
    createAttachmentRegistrationData(registrationData, 'waterSupplyDrainagePumpInspectionCommonFile')
    createAttachmentRegistrationData(registrationData, 'simpleWaterSupplyCommonFile')
    createAttachmentRegistrationData(registrationData, 'tubeBulbSupportCommonFile')
    createAttachmentRegistrationData(registrationData, 'bulkyWasteSupportCommonFile')
    createAttachmentRegistrationData(registrationData, 'merchandiseSalesCommonFile')
    createAttachmentRegistrationData(registrationData, 'emergencyResponseCommonFile')
    createAttachmentRegistrationData(registrationData, 'sewageTankCleaningCommonFile')
    createAttachmentRegistrationData(registrationData, 'fireProtectionInspectionCommonFile')
    createAttachmentRegistrationData(registrationData, 'connectingWaterPipePressureInspectionCommonFile')
    createAttachmentRegistrationData(registrationData, 'firePreventionObjectInspectionCommonFile')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile1')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile2')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile3')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile4')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile5')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile6')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile7')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile8')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile9')
    createAttachmentRegistrationData(registrationData, 'spotCommonFile10')
    createAttachmentRegistrationData(registrationData, 'mrsCommonFile')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile1')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile2')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile3')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile4')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile5')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile6')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile7')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile8')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile9')
    createAttachmentRegistrationData(registrationData, 'otherCommonFile10')

    return registrationData
}

/**
 * 添付ファイルデータ 生成
 */
function createAttachmentRegistrationData(registrationData, targetAttachmentName) {
  if (registrationData[targetAttachmentName]) {
    registrationData[`${targetAttachmentName}_file_content`] = registrationData[targetAttachmentName].content || ''
    registrationData[`${targetAttachmentName}_file_name`] = registrationData[targetAttachmentName].originalName || ''
    registrationData[`${targetAttachmentName}_display_file_name`] = registrationData[targetAttachmentName].originalName || ''
  } else {
    registrationData[`${targetAttachmentName}_file_content`] = ''
    registrationData[`${targetAttachmentName}_file_name`] = ''
    registrationData[`${targetAttachmentName}_display_file_name`] = ''
  }
  delete registrationData[targetAttachmentName]
}

/** 
 * 受注 登録
 */
export async function registerOrder(context, order) {
    try {
      const no = await createNo(context, order)
      order.orderNo = no

      const registrationData = createRegistrationData(order)
      console.log('registrationData : ')
      console.log(registrationData)

      const result = await context.$pigeon.registerTask(ORDERS_COLLECTION_NAME, registrationData, {})

      return result._id
    } catch (error) {
        console.log('register error !!!')
        console.log(error)
        return false
    }
}

/**
 * No 生成
 */
 async function createNo(context, order) {
  const res = await context.$pigeon.getAutomaticNumber(
    ORDERS_COLLECTION_NAME,
    'orderNo',
    'order_no_setting',
    order
  )
  return res
}

/**
 * 受注データ 更新
 * @param {*} context 
 * @param {*} order 受注データ
 * @returns 
 */
export async function updateOrderData(context, order) {
    try {
        const registrationData = createRegistrationData(order)
        registrationData.type = ORDERS_COLLECTION_NAME
        console.log('registrationData : ')
        console.log(registrationData)

        await context.$pigeon.updateTask(registrationData._id, registrationData, {})

        return registrationData._id
    } catch (error) {
        console.log('update error !!!')
        console.log(error)
        return false
    }
}

/** 
 *  受注データ 取得
 */
export async function getOrderData(context, _id) {
    const searchCondition = {
        searchCondition: {
        type: ORDERS_COLLECTION_NAME,
        _id
        }
    }
    try {
        const responseData = await context.$pigeon.searchTasks(searchCondition)
        const allocation = responseData[0] || null
        return allocation
    } catch (error) {
        console.log('getOrderData error !!!')
        console.log(error)
        return null
    }
}

/**
 * 受注データ（statusがremove以外）
 * @param {*} context 
 * @param {*} searchKeys 
 * @param {*} keyword 
 * @param {*} status 
 * @param {*} periodKey 
 * @param {*} period 
 * @returns 
 */
export async function searchOrderData(context, searchKeys, keyword, status, periodKey, period) {
    const searchCondition = {
      searchCondition: {
        type: ORDERS_COLLECTION_NAME,
        status: { $ne: "remove" }
      },
      sort: {
        createDate: -1
      }
    }
  
    if (periodKey && period) {
      searchCondition.searchCondition[periodKey] = {}
      if (period.start) {
        searchCondition.searchCondition[periodKey].$gte = period.start + ' 00:00:00'
      }
      if (period.end) {
        searchCondition.searchCondition[periodKey].$lte = period.end + ' 23:59:59'
      }
      if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
        delete searchCondition.searchCondition[periodKey]
      }
    }
  
    const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
    if (keywordCondition) {
      searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
    }
  
    try {
        const responseData = await context.$pigeon.searchTasks(searchCondition)
        const searchOrderData = responseData || []
        return searchOrderData
    } catch (error) {
      console.log('searchOrderData error !!!')
      console.log(error)
      return []
    }
}

function buildMultipleKeywordSearchCondition(keywordText, searchKeys) {
    keywordText = keywordText.trim()
    if (keywordText === '') {
      return null
    } else {
      const keywords = keywordText.split(/\s+/g)
      const condition = { $and: [] }
      keywords.forEach(function(keyword) {
        const query = { $or: [] }
        searchKeys.forEach(function(searchKey) {
          const expression = {}
          expression[searchKey] = { $regex: keyword, $options: 'i'}
          query.$or.push(expression)
        });
        condition.$and.push(query);
      });
      return condition;
    }
}

export async function searchOrderAndTaskRequestOrderData(context, searchKeys, keyword, status, periodKey, period, withTask = false) {
  const searchCondition = {
    searchCondition: {
      type: ORDERS_COLLECTION_NAME,
      status
    },
    sort: {
      createDate: -1
    }
  }

  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start + ' 00:00:00'
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end + ' 23:59:59'
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
  }

  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
  if (keywordCondition) {
    searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
  }

  try {
    var responseData = []
    if (withTask) {
      const pipeline = [
        {
          $match: {
            ...searchCondition.searchCondition
          }
        },
        {
          $lookup: {
            from: 'Tasks',
            localField: 'orderNo',
            foreignField: 'orderTaskRequestOrderNo',
            as: 'taskRequestOrder'
          }
        },
        {
          $match: {
            ...keywordCondition
          }
        },
        {
          $sort: { createDate: -1 }
        }
      ]
      responseData = await context.$pigeon.aggregateTasks(pipeline)
      responseData = removeStatusRemoveTask(responseData)
    } else {
      responseData = await context.$pigeon.searchTasks(searchCondition)
    }
    const searchOrderData = responseData || []
    return searchOrderData
  } catch (error) {
    console.log('searchOrderData error !!!')
    console.log(error)
    return []
  }
}

/**
 * 受注リスト内 削除されたタスクを削除して返却
 * @param {*} target 受注リスト
 * @returns 
 */
 function removeStatusRemoveTask(target) {
  target.forEach(function(data) {
    data.taskRequestOrder = data.taskRequestOrder.filter(task => task.status != 'remove');
  });
  return target
}

/** 
 *  受注データ取得(見積Noを条件に取得)
 */
export async function getByEstimateNo(context, estimateNo) {
  const searchCondition = {
    searchCondition: {
      type: ORDERS_COLLECTION_NAME,
      status: { $ne: "remove" },
      estimateNo,
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getByEstimateNo error !!!')
    console.log(error)
    return null
  }
}

/**
 * 受注データのステータス変更
 * @param {*} context 
 * @param {*} allocationId 
 * @returns 
 */
export async function markAsDone(context, allocationId) {
  try {
    await context.$pigeon.completeTask(allocationId)
    return true
  } catch (error) {
    console.log('markAsDone error !!!')
    console.log(error)
    return false
  }
}

/** 
 *  受注データ取得(リクエストポストNoを条件に取得)
 */
export async function getByRequestNo(context, requestNo) {
  const searchCondition = {
    searchCondition: {
      type: ORDERS_COLLECTION_NAME,
      status: { $ne: "remove" },
      requestNo,
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getByRequestNo error !!!')
    console.log(error)
    return null
  }
}

/** 
 * 見積Noに紐づく受注データを削除（受注メインタスク、サブタスク）
 * 削除フラグを立てる
 */
export async function removeOrder(context, estimateNo) {
  const searchCondition = {
    searchCondition: {
      type: ORDERS_COLLECTION_NAME,
      status: { $ne: "remove" },
      estimateNo,
    }
  }
  try {
    const orderMain = await context.$pigeon.searchTasks(searchCondition)
    let orderId = ''
    if (orderMain && orderMain.length == 1) {
      // 受注メインID取得
      orderId = orderMain[0]._id
      // 受注メイン削除
      await context.$pigeon.deleteTask(orderId)

      // 受注サブ取得➡のちにどこまで完了していたか確認するためにステータスは変更しない
      // const sub = await taskRequestManager.getByOrderId(context, orderId)
      // if (sub && sub.length) {
      //   for (let i = 0; i < sub.length; i++) {
      //     const s = sub[i]
      //     // 受注サブ削除
      //     await context.$pigeon.deleteTask(s._id)
      //   }
      // }
    // 受注メインが1件取得できなければエラー
    } else {
      return false
    }
    return true
  } catch (error) {
    console.log('removeOrder error !!!')
    console.log(error)
    return false
  }
}
